import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import Page from '../../atoms/Page';
import DeviceCard from '../../organisms/DeviceCard/DeviceCard';

const DEVICE_GQL = gql`
  query DeviceQuery($barnId: uuid!) {
    device(
      where: { device_assignments: { ended_at: { _is_null: true }, feed_line: { farm_id: { _eq: $barnId } } } }
      order_by: { serial: desc }
    ) {
      id
      serial
    }
  }
`;

function DeviceTab({ titleSegments = [], barnId = null }) {
  const { loading, error, data } = useQuery(DEVICE_GQL, {
    variables: { barnId },
  });
  const pageTitleSegments = useMemo(() => ['Devices', ...titleSegments], []);

  if (error) return <span>Error {JSON.stringify(error)}</span>;
  if (loading) return <span>Loading...</span>;

  const cards = data.device.map((device) => <DeviceCard key={device.id} serial={device.serial} deviceId={device.id} />);

  return (
    <Page className="DevicePage" titleSegments={pageTitleSegments}>
      <div className="DeviceCardHolder">{cards}</div>
    </Page>
  );
}

DeviceTab.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
  barnId: PropTypes.string,
};

export default DeviceTab;
