import React, { useMemo } from 'react';
import { Line, Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip';
import PropTypes from 'prop-types';

// Function to calculate histogram bins
const calculateHistogram = (data, binCount, min, max) => {
  const binSize = (max - min) / binCount;

  const bins = Array(binCount)
    .fill(0)
    .map((_, i) => ({
      bin: (min + i * binSize).toFixed(2),
      frequency: 0,
    }));

  data.forEach((value) => {
    let binIndex = Math.floor((value - min) / binSize);
    binIndex = Math.max(binIndex, 0);
    binIndex = Math.min(binIndex, binCount - 1);

    if (binIndex >= 0 && binIndex < binCount) {
      bins[binIndex].frequency += 1;
    }
  });

  return bins;
};

const ErrorChart = ({ width, height, errors = [], binCount = 30, events = false }) => {
  if (binCount <= 0) {
    throw new Error('Histograms must have more than 0 Bins');
  }

  const verticalMargin = 0;
  const maxRange = 0.15;
  const minRange = -maxRange;

  const maxSafeRange = 0.05;
  const minSafeRange = -maxSafeRange;
  const binSize = (maxRange - minRange) / binCount;

  const data = useMemo(() => calculateHistogram(errors, binCount, minRange, maxRange), [errors, binCount]);

  // accessors
  const getBin = (d) => d.bin;
  const getFrequency = (d) => d.frequency;

  // bounds
  const xMax = width;
  const yMax = height - verticalMargin;

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        round: true,
        domain: data.map(getBin),
        padding: 0.3,
      }),
    [xMax, data],
  );
  const xScaleLin = useMemo(
    () =>
      scaleLinear({
        range: [0, xMax],
        round: true,
        domain: [minRange, maxRange],
      }),
    [xMax, data],
  );
  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...data.map(getFrequency))],
      }),
    [yMax, data],
  );

  const verticalLines = useMemo(() => {
    const lines = [];
    for (let i = minRange; i <= maxRange; i += 0.05) {
      lines.push(i);
    }
    return lines;
  }, []);

  // Tooltip setup
  const { tooltipOpen, tooltipTop, tooltipLeft, tooltipData, showTooltip, hideTooltip } = useTooltip();

  // Tooltip styles
  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    color: 'white',
  };

  let tooltipBinText = '';
  if (tooltipOpen) {
    tooltipBinText = `${(tooltipData.bin * 100).toFixed(0)}% to ${(tooltipData.bin * 100 + 1).toFixed(0)}%`;
    if (tooltipData.bin <= minRange) tooltipBinText = `-♾️% to ${(tooltipData.bin * 100 + 1).toFixed(0)}%`;
    if (Number(tooltipData.bin) + binSize >= maxRange - 0.0001)
      tooltipBinText = `${(tooltipData.bin * 100).toFixed(0)}% to ♾️%`;
  }
  return width < 10 ? null : (
    <div>
      <svg width={width} height={height}>
        <rect width={width} height={height} fill="url(#teal)" rx={14} />
        <Group top={verticalMargin / 2}>
          {verticalLines.map((line, index) => {
            const xPos = xScaleLin(line.toFixed(2).toString());
            const isZero = line.toFixed(2) == 0;
            return (
              <Line
                key={`line-${index}`}
                from={{ x: xPos, y: 0 }}
                to={{ x: xPos, y: yMax }}
                stroke={isZero ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.2)'}
                strokeWidth={1}
              />
            );
          })}
          {data.map((d, i) => {
            const bin = getBin(d);
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - (yScale(getFrequency(d)) ?? 0);
            const barX = xScale(bin);
            const barY = yMax - barHeight;

            let fill = 'var(--red-900)';
            if (Number(bin) >= minSafeRange && Number(bin) + binSize <= maxSafeRange) fill = 'var(--green-900)';
            return (
              <Bar
                key={`bar-${i}`}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill={fill}
                onClick={() => {
                  if (events) alert(`clicked: ${JSON.stringify(Object.values(d))}`);
                }}
                onMouseLeave={hideTooltip}
                onMouseMove={(event) =>
                  showTooltip({
                    tooltipData: d,
                    tooltipTop: event.clientY,
                    tooltipLeft: event.clientX,
                  })
                }
              />
            );
          })}
        </Group>
      </svg>
      {tooltipOpen && (
        <TooltipWithBounds top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div>
            {tooltipBinText} x {tooltipData.frequency}
          </div>
        </TooltipWithBounds>
      )}
    </div>
  );
};

ErrorChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  errors: PropTypes.arrayOf(PropTypes.number),
  binCount: PropTypes.number,
  events: PropTypes.bool,
};

export default ErrorChart;
