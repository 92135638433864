import { gql } from '@apollo/client';

export const GetFarmDataQuery = gql`
  query ExportFactory_GetFarmData($farmIDs: [uuid!]!) {
    farm(where: { id: { _in: $farmIDs } }, order_by: { name: asc }) {
      id
      name
      feed_lines {
        name
        id
      }
    }
  }
`;

export const GetFeedEventCountQuery = gql`
  query ExportFactory_GetFeedEventCount($feedLineIDs: [uuid!]!, $startedAt: bigint!, $endedAt: bigint!) {
    feed_frame_aggregate(
      where: {
        feed_line_id: { _in: $feedLineIDs }
        started_at: { _gte: $startedAt }
        ended_at: { _lte: $endedAt }
        deleted_at: { _is_null: true }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GetFaultCountQuery = gql`
  query ExportFactory_GetFaultCount(
    $feedLineIDs: [uuid!]!
    $startedAt: bigint!
    $endedAt: bigint!
    $faultCodes: [Int!]!
  ) {
    fault_aggregate(
      where: {
        device: { device_assignments: { feed_line_id: { _in: $feedLineIDs } } }
        started_at: { _gte: $startedAt }
        _or: [{ ended_at: { _lte: $endedAt } }, { ended_at: { _is_null: true } }]
        deleted_at: { _is_null: true }
        code: { _in: $faultCodes }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GetFeedFrameExportQuery = gql`
  query ExportFactory_GetFeedFrameExport(
    $feedLineID: uuid!
    $startedAt: bigint!
    $endedAt: bigint!
    $limit: Int!
    $offset: Int!
    $feedFrameAnalysisWhere: feed_frame_analysis_bool_exp
  ) {
    feed_frame(
      where: {
        feed_line_id: { _eq: $feedLineID }
        started_at: { _gte: $startedAt }
        ended_at: { _lte: $endedAt }
        deleted_at: { _is_null: true }
      }
      order_by: [{ started_at: asc }, { id: asc }]
      limit: $limit
      offset: $offset
    ) {
      id
      ended_at
      started_at
      feed_frame_analyses(where: $feedFrameAnalysisWhere, order_by: { created_at: desc_nulls_last }, limit: 1) {
        latest_estimated_mass_moved_in_grams
      }
    }
  }
`;

export const GetFaultCodeExportQuery = gql`
  query ExportFactory_FaultCodeExport(
    $feedLineID: uuid!
    $startedAt: bigint!
    $endedAt: bigint!
    $faultCodes: [Int!]!
    $limit: Int!
    $offset: Int!
  ) {
    fault(
      where: {
        device: { device_assignments: { feed_line_id: { _eq: $feedLineID } } }
        started_at: { _gte: $startedAt }
        _or: [{ ended_at: { _lte: $endedAt } }, { ended_at: { _is_null: true } }]
        deleted_at: { _is_null: true }
        code: { _in: $faultCodes }
      }
      order_by: [{ started_at: asc }, { id: asc }]
      limit: $limit
      offset: $offset
    ) {
      code
      ended_at
      started_at
    }
  }
`;
