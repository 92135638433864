import { gql } from '@apollo/client';

export const ANIMAL_GROUP_CREATOR_INSERT = gql`
  mutation AnimalGroupCreator_InsertAnimalGroup($new_group: animal_group_insert_input!) {
    insert_animal_group_one(object: $new_group) {
      id
      approximate_birthdate
      ended_at
      expected_ended_at
      external_id
      started_at
    }
  }
`;
