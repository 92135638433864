import React from 'react';
import PropTypes from 'prop-types';
import './FeedFloPercent.scss';

const PERCENT_RANGE_MIN = 0;
const PERCENT_RANGE_MAX = 10;

/***
 * ? DEPRECATED ?
 */
function FeedFloPercent({
  number = PERCENT_RANGE_MIN,
  min = PERCENT_RANGE_MIN,
  max = PERCENT_RANGE_MAX,
  toFixed = 1,
} = {}) {
  const getColor = (number) => {
    let color = null;

    if (number > max) {
      color = 'aboveRange';
    } else if (number <= max && number >= min) {
      color = 'withinRange';
    } else if (number < min) {
      color = 'belowRange';
    }

    return color;
  };

  return <div className={`FeedFloPercent ${getColor(number)}`}>{number.toFixed(toFixed)}%</div>;
}

FeedFloPercent.propTypes = {
  number: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  toFixed: PropTypes.number,
};

export default FeedFloPercent;
