import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT_SHORT } from '../../utils/dates';
import BinScaleChart from './BinScaleChart';
import WebAppContext from '../../utils/webAppContext';

import { convertGramsToLargeUnits, weightLargeUnitLabel } from '../../utils/unitConversion';
import './BinCard.scss';
import { useCensor, censorTypes } from '../../utils/hooks/useCensor';

const BIN_GQL = gql`
  query BinScaleQuery($binIds: [uuid!]) {
    bin(where: { id: { _in: $binIds } }, order_by: { name: asc_nulls_last }) {
      name
      id
      bin_feed_lines(where: { deleted_at: { _is_null: true } }) {
        feed_line {
          name
        }
      }
      farm {
        name
      }
      known_bin_levels(limit: 1, order_by: { valid_at: desc_nulls_last }, where: { deleted_at: { _is_null: true } }) {
        level_in_grams
        valid_at
      }
    }
  }
`;

export default function BinCard({ binIds = [], to, from, toFocus, fromFocus }) {
  const { isMetric } = useContext(WebAppContext);

  const { loading, error, data } = useQuery(BIN_GQL, {
    variables: {
      binIds,
    },
  });
  const { censor } = useCensor();

  if (loading) return <span>Loading...</span>;
  if (error) return <span>Error...</span>;

  const barnName = censor(data?.bin?.[0]?.farm?.name, censorTypes.barn) || '';
  const feedLineNames = data?.bin?.[0]?.bin_feed_lines
    ?.map((binFeedLine) => censor(binFeedLine?.feed_line?.name, censorTypes.feedline) || '')
    ?.join(', ');

  return (
    <div className="BinCard">
      <div className="title">
        <h5>
          Bin{data?.bin?.length > 1 ? 's' : ''} {data?.bin?.map((bin) => bin.name).join(', ')}
        </h5>
      </div>
      <div>
        <b>{barnName}</b> - {feedLineNames}
      </div>
      <div>
        {convertGramsToLargeUnits(
          isMetric,
          data.bin.reduce((sum, curr) => {
            return sum + (curr?.known_bin_levels?.[0]?.level_in_grams || 0);
          }, 0),
        )}{' '}
        {weightLargeUnitLabel(isMetric)}
        {' @ '}
        {dayjs.tz(1000 * data?.bin?.[0]?.known_bin_levels?.[0]?.valid_at).format(DATE_TIME_FORMAT_SHORT)}
      </div>
      <BinScaleChart binIds={binIds} to={to} from={from} toFocus={toFocus} fromFocus={fromFocus} autoScale={true} />
    </div>
  );
}

BinCard.propTypes = {
  binIds: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  to: PropTypes.number,
  from: PropTypes.number,
  toFocus: PropTypes.number,
  fromFocus: PropTypes.number,
};
