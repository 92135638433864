import { gql } from '@apollo/client';

// Query for the most recent 5 animal groups with external_id matching given text in a given barn.
export const ANIMAL_GROUP_QUERY_SEARCH = gql`
  query AnimalGroupSelector_QuerySearch($barnId: uuid!, $text: String!) {
    animal_group(
      where: { deleted_at: { _is_null: true }, barn_id: { _eq: $barnId }, external_id: { _ilike: $text } }
      order_by: { ended_at: desc_nulls_first }
      limit: 5
    ) {
      external_id
      id
    }
  }
`;

// Query for the most recent 5 animal groups in a given barn.
export const ANIMAL_GROUP_QUERY_SELECTOR_SUGGESTIONS = gql`
  query AnimalGroupSelector_QuerySuggestions($barnId: uuid!) {
    animal_group(
      where: { deleted_at: { _is_null: true }, barn_id: { _eq: $barnId } }
      order_by: { ended_at: desc_nulls_first }
      limit: 5
    ) {
      external_id
      id
    }
  }
`;

// Query for details about a specific animal group by ID.
export const ANIMAL_GROUP_QUERY_SELECTION = gql`
  query AnimalGroupSelector_QuerySelection($id: uuid!) {
    animal_group_by_pk(id: $id) {
      approximate_birthdate
      ended_at
      expected_ended_at
      external_id
      started_at
    }
  }
`;
