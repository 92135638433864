import { gql } from '@apollo/client';

export const ANIMAL_GROUP_ENDED_AT_UPDATE = gql`
  mutation AnimalGroupDetails_UpdateAnimalGroupEndedAt($id: uuid!, $endedAt: bigint!) {
    update_animal_group_by_pk(pk_columns: { id: $id }, _set: { ended_at: $endedAt }) {
      ended_at
    }
  }
`;

export const ANIMAL_GROUP_EDIT_UPDATE = gql`
  mutation AnimalGroupDetails_Edit(
    $id: uuid!
    $birthDate: bigint
    $endedAt: bigint
    $expectedEndedAt: bigint
    $externalId: String
    $startedAt: bigint
  ) {
    update_animal_group_by_pk(
      pk_columns: { id: $id }
      _set: {
        approximate_birthdate: $birthDate
        ended_at: $endedAt
        expected_ended_at: $expectedEndedAt
        external_id: $externalId
        started_at: $startedAt
      }
    ) {
      approximate_birthdate
      ended_at
      expected_ended_at
      external_id
      started_at
    }
  }
`;
