import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { DATE_FORMAT_MONTH_DAY, DATE_FORMAT_MONTH_DAY_YEAR } from '../../dates';
import WebAppContext from '../../webAppContext';
import { useFeedFrameFilter, useAnalysisFilter } from '../../useFeedFrameFilter';
import { convertGramsToLargeUnits, weightLargeUnitLabel } from '../../unitConversion';
import useKnownBinLevelSeries from '../useKnownBinLevelSeries';
//import useBinInventoryChartSeries from '../useBinInventoryChartSeries';

const BIN_SCALE_DATA_GQL = gql`
  query BinScaleDataQuery(
    $binIds: [uuid!]
    $from: bigint!
    $to: bigint!
    $feedFrameWhere: feed_frame_bool_exp
    $feedFrameAnalysisWhere: feed_frame_analysis_bool_exp
  ) {
    known_bin_level(
      order_by: { valid_at: asc }
      where: { deleted_at: { _is_null: true }, bin_id: { _in: $binIds }, valid_at: { _gte: $from, _lte: $to } }
    ) {
      valid_at
      level_in_grams
      bin_id
    }
    bin(where: { id: { _in: $binIds } }, order_by: { name: asc }) {
      id
      name
      capacity_in_grams
    }
    bin_set(where: { bins: { id: { _in: $binIds } } }) {
      feed_lines {
        feed_frames(where: $feedFrameWhere, order_by: { started_at: asc }) {
          started_at
          ended_at
          feed_frame_analyses(where: $feedFrameAnalysisWhere, order_by: { created_at: desc }, limit: 1) {
            latest_estimated_mass_moved_in_grams
          }
        }
      }
    }
  }
`;

export default function useBinScaleSeries({ sha = '', binIds = [], to = '', from = '', autoScale = false }) {
  const { isMetric } = useContext(WebAppContext);
  const { calculateKnownBinLevelSeries } = useKnownBinLevelSeries();
  //const { buildFeedEventSeries } = useBinInventoryChartSeries();
  const feedFrameWhere = useFeedFrameFilter({
    started_at: { _gte: from },
    ended_at: { _lte: to },
    deleted_at: { _is_null: true },
  });
  const feedFrameAnalysisWhere = useAnalysisFilter({
    algorithm_version: { _eq: sha },
  });
  const { loading, error, data } = useQuery(BIN_SCALE_DATA_GQL, {
    variables: {
      binIds,
      from,
      to,
      feedFrameWhere,
      feedFrameAnalysisWhere,
    },
    skip: !feedFrameAnalysisWhere,
  });
  const maxMass = data?.bin?.reduce((sum, b) => (sum += b.capacity_in_grams), 0) || 0;
  const series = calculateKnownBinLevelSeries(data?.known_bin_level || [], false);

  return {
    loading,
    error,
    data: [
      {
        type: 'scatter',
        name: 'Weight',
        ...series,
      },
      // {
      //   ...buildFeedEventSeries(data?.bin_set?.[0]?.feed_lines || [], true),
      //   name: 'Feed Events',
      //   visible: true,
      // },
    ],
    layout: {
      title: {
        text: `<b>Weight Data</b> ${weightLargeUnitLabel(isMetric)} <br>${dayjs
          .unix(from)
          .format(DATE_FORMAT_MONTH_DAY)} - ${dayjs.tz(1000 * to).format(DATE_FORMAT_MONTH_DAY_YEAR)}`,
      },
      xaxis: { title: 'Occurred At', range: [new Date(from * 1000), new Date(to * 1000)], autorange: true },
      yaxis: {
        title: `Weight (${weightLargeUnitLabel(isMetric)})`,
        range: autoScale ? [] : [0, convertGramsToLargeUnits(isMetric, maxMass)],
        autorange: autoScale,
      },
      margin: { t: 60, b: 30, l: 50, r: 0 },
    },
  };
}
