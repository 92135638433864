import PropTypes from 'prop-types';

export function SingleBinIcon({ className = '', fillPercent = 0, fillColour = '', onClick = () => null }) {
  // The pixel conversion is between pixel height 113 for 0% fill and pixel height 15 for 100% fill
  const fillY = 113 - (fillPercent * 98) / 100;
  const fillHeight = 114 - fillY;
  return (
    <svg
      className={`${className}`}
      onClick={onClick}
      width="63"
      height="114"
      viewBox="0 0 63 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="single-bin-fill">
          <path d="M61.6484 80.3739L46.5541 112.526H16.2573L1.05469 80.3739V14.5397H61.6484V80.3739Z" />
          <path d="M39.9691 3.8222H22.734L1.05469 14.5276H61.6484L39.9691 3.8222Z" />
          <path d="M38.1861 0.926056H24.5161C23.5164 0.926056 22.7095 1.73288 22.7095 2.73237V3.86433H39.9927V2.73237C39.9927 1.73288 39.1858 0.926056 38.1861 0.926056Z" />
          <path d="M1.5603 80.5906L61.1548 80.5906" />
        </clipPath>
      </defs>
      <path
        // Bin body fill background.
        d="M61.6484 80.3739L46.5541 112.526H16.2573L1.05469 80.3739V14.5397H61.6484V80.3739Z"
        fill="#E6E6E6"
      />
      <rect
        // Feed value rectangle.
        x="0"
        y={fillY}
        width="100%"
        height={fillHeight}
        stroke="initial"
        fill={fillColour}
        clipPath="url(#single-bin-fill)"
      />
      <path
        // Bin body stroke outline.
        d="M61.6484 80.3739L46.5541 112.526H16.2573L1.05469 80.3739V14.5397H61.6484V80.3739Z"
        stroke="#999999"
        strokeMiterlimit="10"
      />
      <path
        // Bin top.
        d="M39.9691 3.8222H22.734L1.05469 14.5276H61.6484L39.9691 3.8222Z"
        fill="#F1F1F1"
        stroke="#999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        // Bin cap.
        d="M38.1861 0.926056H24.5161C23.5164 0.926056 22.7095 1.73288 22.7095 2.73237V3.86433H39.9927V2.73237C39.9927 1.73288 39.1858 0.926056 38.1861 0.926056Z"
        fill="#F1F1F1"
        stroke="#999999"
        strokeMiterlimit="10"
      />
      <path d="M2.64429 61.239H33.2422" stroke="#CFCFCF" strokeMiterlimit="10" />
      <path d="M2.64429 66.0739H33.2422" stroke="#CFCFCF" strokeMiterlimit="10" />
      <path d="M2.64429 70.668H33.2422" stroke="#CFCFCF" strokeMiterlimit="10" />
      <path d="M2.64429 75.262H39.3425" stroke="#CFCFCF" strokeMiterlimit="10" />
      <path d="M1.5603 80.5906L61.1548 80.5906" stroke="#CFCFCF" strokeMiterlimit="10" />
    </svg>
  );
}

SingleBinIcon.propTypes = {
  className: PropTypes.string,
  fillPercent: PropTypes.number,
  fillColour: PropTypes.string,
  onClick: PropTypes.func,
};
