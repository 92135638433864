import PropTypes from 'prop-types';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';
import './LabelledColumn.scss';

function LabelledColumn({
  loading = false,
  className = '',
  headingClassName = '',
  style = {},
  heading = '',
  children = '',
}) {
  return (
    <div className={`LabelledColumn ${className}`} style={style}>
      <div className={`LabelledColumn-heading ${headingClassName}`}>{heading}</div>
      <div className="LabelledColumn-content">
        {loading ? <LoadingSkeleton className="LabelledColumn--loading" /> : children}
      </div>
    </div>
  );
}

LabelledColumn.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  headingClassName: PropTypes.string,
  style: PropTypes.object,
  heading: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default LabelledColumn;
