import { useState } from 'react';
import { Link } from 'react-router-dom/dist';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import FeedFloButton from '../../atoms/FeedFloButton';
import { ArrowIcon } from '../../atoms/Icons';
import TextArea from '../../atoms/TextArea';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';

import './EventAccordionPanel.scss';

function EventAccordionPanel({
  className = '',
  content = null,
  left = null,
  right = null,

  expanded = false,
  details = null,
  linkText,
  linkTo,
  notes = '',

  onLinkClick = () => null,
  onPanelClick = () => null,
  saveNote = () => null,

  loading = false,
}) {
  const [isNoteEditable, setIsNoteEditable] = useState(false);

  const [lastSavedNote, setLastSavedNote] = useState(notes);
  const [currentNote, setCurrentNote] = useState(notes);

  const [indicateSaveIsComplete, setIndicateSaveIsComplete] = useState(false);

  const onClickEditNote = () => {
    setIsNoteEditable(true);
  };

  const onClickSaveNote = () => {
    // don't save empty notes
    if (0 === currentNote.length) return;

    saveNote(currentNote);
    setLastSavedNote(currentNote);

    setIndicateSaveIsComplete(true);

    setTimeout(() => {
      setIndicateSaveIsComplete(false);
      setIsNoteEditable(false);
    }, 500);
  };

  const onClickSaveNoteDebounce = debounce(onClickSaveNote, 100);

  const onClickCancelEditingNote = () => {
    setCurrentNote(lastSavedNote);
    setIsNoteEditable(false);
  };

  const onNoteChange = (e) => {
    setCurrentNote(e.target.value);
  };

  // ctrl/cmd + Enter will save the note
  const onTextKeyDown = (e) => {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      // Prevent the trigger key from being added to the input
      e.preventDefault();
      onClickSaveNote();
    }
  };

  if (loading) {
    return (
      <div className={`EventAccordionPanel ${className}`}>
        <div className="EventAccordionPanel-headerContent EventAccordionPanel-loading">
          <LoadingSkeleton className="EventAccordionPanel-loading--circle" />
          <div className="EventAccordionPanel-loading--centre">
            <LoadingSkeleton className="EventAccordionPanel-loading--long" />
            <LoadingSkeleton className="EventAccordionPanel-loading--short" />
            <LoadingSkeleton className="EventAccordionPanel-loading--long" />
          </div>
          <LoadingSkeleton className="EventAccordionPanel-loading--box" />
        </div>
      </div>
    );
  }

  return (
    <div className={`EventAccordionPanel ${className}`}>
      <div
        className={`EventAccordionPanel-header ${
          expanded ? 'EventAccordionPanel--panelIsOpen' : 'EventAccordionPanel--panelIsClosed'
        }`}
        onClick={onPanelClick}
      >
        <div className="EventAccordionPanel-headerContent">
          {left}
          {content}
          {right}
        </div>
        <ArrowIcon className={`EventAccordionPanel-arrow ${expanded ? 'EventAccordionPanel--openArrow' : ''}`} />
      </div>
      {expanded ? (
        <>
          <div className="EventAccordionPanel-content">
            <div className="EventAccordionPanel-detailsContainer">
              {details ?? <p className="EventAccordionPanel-detail">{details}</p>}
            </div>
          </div>
          <TextArea
            disabled={!isNoteEditable}
            label="Notes"
            value={currentNote}
            onChange={onNoteChange}
            onKeyDown={onTextKeyDown}
          />
          <div className="EventAccordionPanel-linksContainer">
            {linkText && (
              <Link to={linkTo} target="_blank" className="EventAccordionPanel-link" onClick={onLinkClick}>
                {linkText}
              </Link>
            )}
            {isNoteEditable ? (
              <div className="EventAccordionPanel-noteButtonsContainer">
                {indicateSaveIsComplete ? null : (
                  <FeedFloButton className="EventAccordionPanel-cancelButton" onClick={onClickCancelEditingNote}>
                    Cancel
                  </FeedFloButton>
                )}
                <FeedFloButton
                  className="EventAccordionPanel-saveButton"
                  onClick={onClickSaveNoteDebounce}
                  disabled={indicateSaveIsComplete || 0 === currentNote.length}
                  type={indicateSaveIsComplete ? 'secondary' : 'primary'}
                >
                  {indicateSaveIsComplete ? 'Saved!' : 'Save'}
                </FeedFloButton>
              </div>
            ) : (
              <p className="EventAccordionPanel-link" onClick={onClickEditNote}>
                {/* Todo replace with a "text button" later */}
                Edit
              </p>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
}

EventAccordionPanel.propTypes = {
  className: PropTypes.string,
  content: PropTypes.element,
  left: PropTypes.element,
  right: PropTypes.element,

  details: PropTypes.string,
  expanded: PropTypes.bool,
  linkText: PropTypes.string,
  linkTo: PropTypes.string,
  notes: PropTypes.string,

  onLinkClick: PropTypes.func,
  onPanelClick: PropTypes.func,
  saveNote: PropTypes.func,

  loading: PropTypes.bool,
};

export default EventAccordionPanel;
