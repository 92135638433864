import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { DATE_FORMAT_DASH } from '../../utils/dates';
import FeedFloButton from '../../atoms/FeedFloButton';
import useUser from '../../utils/hooks/useUser';
import './react-datepicker.scss';
import './FeedFloDatePicker.scss';

/***
 * ? DEPRECATED ?
 *
 * Props:
 * [from]             - will enforce time to the start of the day, a.k.a [00:00:00]
 *                    - type: Day js object
 *                    - default: beginning of current day
 *
 * [to]               - will enforce time to the end of the day, a.k.a [23:59:999]
 *                    - type: Day js object
 *                    - default: end of current day
 *
 * [onChange]         - callback function when the dateRange is changed
 * [onClickedOutside] - callback function to notify the caller that the user clicked outside of the component
 *
 */
function FeedFloDatePicker({
  className = '',
  to = dayjs.tz(),
  from = dayjs.tz(),
  isPanel = true,
  onChange = () => {},
  onClickedOutside = () => {},
  maxDate = dayjs.tz(),
}) {
  const [startDate, setStartDate] = useState(dayjs.tz(from).startOf('day'));
  const [endDate, setEndDate] = useState(dayjs.tz(to).endOf('day'));
  const { user } = useUser();
  // the string inputed in the text box in the header of date picker
  const [startText, setStartText] = useState('');
  const [endText, setEndText] = useState('');
  const fromPlaceHolder = dayjs(from).format(DATE_FORMAT_DASH);
  const toPlaceHolder = dayjs(to).format(DATE_FORMAT_DASH);

  /**
   * For detection if outside is clicked
   */
  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClickedOutside();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    setStartText(dayjs.tz(startDate).format(DATE_FORMAT_DASH));

    if (!endDate) {
      setEndText('');
    } else {
      setEndText(dayjs.tz(endDate).format(DATE_FORMAT_DASH));
    }
  }, [startDate, endDate]);

  /**
   * input from the Calendar
   * uses date-picker library
   */
  function onDateSelectedChange(dates) {
    const [start, end] = dates;

    setStartDate(dayjs.tz(dayjs(start).tz(user.timezone, true).startOf('day')));

    if (!end) {
      // restart range
      setEndDate(null);
    } else {
      setEndDate(dayjs.tz(dayjs(end).tz(user.timezone, true).endOf('day')));
    }
  }

  /**
   * input from the text input boxes
   */

  function onStartingInputChange(e) {
    setStartText(e.target.value);
    const validDate = dayjs(e.target.value, DATE_FORMAT_DASH, true).isValid();
    if (validDate) {
      setStartDate(dayjs.tz(e.target.value).startOf('day'));
    }
  }

  function onEndingInputChange(e) {
    setEndText(e.target.value);
    const validDate = dayjs(e.target.value, DATE_FORMAT_DASH, true).isValid();
    if (validDate) {
      setEndDate(dayjs.tz(e.target.value).endOf('day'));
    }
  }

  // Finalize the Date Range and sends the date up to the parent using callback function
  function onApplyClick(e) {
    // enable slection for single day range
    if (endDate === null) {
      const endOfStartDate = startDate.endOf('day');
      onChange({
        ...e,
        dateRange: {
          from: startDate,
          to: endOfStartDate,
        },
      });
    } else {
      onChange({
        ...e,
        dateRange: { from: startDate, to: endDate },
      });
    }
    e.stopPropagation();
  }

  function onCancelClick(e) {
    onChange(e);
  }

  const header = (
    <div className="header">
      <div>
        <div>Starting</div>
        <input
          onChange={(e) => {
            onStartingInputChange(e);
          }}
          value={startText}
          type="text"
          name="starting"
          placeholder={fromPlaceHolder}
          autoComplete="off"
        />
      </div>
      <div>
        <div>Ending</div>
        <input
          onChange={(e) => onEndingInputChange(e)}
          value={endText}
          type="text"
          name="ending"
          placeholder={toPlaceHolder}
          autoComplete="off"
        />
      </div>
    </div>
  );
  const footer = (
    <div className="footer">
      <FeedFloButton onClick={(e) => onCancelClick(e)}>Cancel</FeedFloButton>
      <FeedFloButton
        onClick={(e) => {
          onApplyClick(e);
        }}
      >
        Apply
      </FeedFloButton>
    </div>
  );
  return (
    <div className={`FeedFloDatePicker ${isPanel ? 'FeedFloDatePicker--panel' : ''} ${className}`} ref={wrapperRef}>
      {header}
      <DatePicker
        className="Calendar"
        onChange={(dates) => onDateSelectedChange(dates)}
        startDate={startDate.tz(dayjs.tz.guess(), true).toDate()}
        endDate={endDate ? endDate.tz(dayjs.tz.guess(), true).toDate() : null}
        maxDate={maxDate ? maxDate.tz(dayjs.tz.guess(), true).toDate() : null} // to disable selection of future dates
        selectsRange
        inline
        disabledKeyboardNavigation
      />
      {footer}
    </div>
  );
}

FeedFloDatePicker.propTypes = {
  className: PropTypes.string,
  from: PropTypes.object,
  to: PropTypes.object,
  isPanel: PropTypes.bool,
  onChange: PropTypes.func,
  onClickedOutside: PropTypes.func,
  maxDate: PropTypes.object,
};

export default FeedFloDatePicker;
