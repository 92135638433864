import { useMemo, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Fzf } from 'fzf';
import useUser from '../../utils/hooks/useUser';
import useFaultCodeFilter from '../../utils/useFaultCodeFilter';
import BarnListPageView from './BarnListPageView';
import { StatusEnum } from '../../utils/enums';
import { useAtom, useAtomValue } from 'jotai';
import { algorithmVersionAtom, listFormatPrefAtom } from '../../utils/jotaiAtoms';
import { convertGramsToLargeUnits, weightLargeUnitLabel } from '../../utils/unitConversion';
import WebAppContext from '../../utils/webAppContext';
import { SECONDS_IN_WEEK } from '../../utils/dates';

import './BarnListPage.scss';

const BARN_LIST_GET_BARN_LIST_DATA_QUERY = gql`
  query BarnList_GetBarnListData(
    $limit: Int!
    $offset: Int
    $farmFilterWhere: farm_bool_exp!
    $activeFaultWhere: fault_bool_exp! = {}
  ) {
    search_result_count: farm_aggregate(where: { _and: [{ deleted_at: { _is_null: true } }, $farmFilterWhere] }) {
      aggregate {
        count
      }
    }
    barns: farm(
      where: { _and: [{ deleted_at: { _is_null: true } }, $farmFilterWhere] }
      order_by: { name: asc }
      limit: $limit
      offset: $offset
    ) {
      address
      name
      id
      feed_lines(where: { deleted_at: { _is_null: true } }) {
        device_assignments(
          where: { deleted_at: { _is_null: true }, ended_at: { _is_null: true }, status: { _eq: "active" } }
        ) {
          active_fault_codes: faults(where: { _and: [$activeFaultWhere], feedflo_staff_only: { _eq: false } }) {
            id
            code
            started_at
            nofeed_seconds
          }
          anomaly_fault_codes: faults(
            where: { deleted_at: { _is_null: true }, ended_at: { _is_null: true }, code: { _in: [6000, 6001] } }
          ) {
            code
            started_at
            nofeed_seconds
          }
        }
      }
      user_pinned_barns(where: { deleted_at: { _is_null: true } }) {
        created_at
        deleted_at
      }
      organization {
        name
      }
      animal_groups(order_by: { started_at: desc }, limit: 1, where: { deleted_at: { _is_null: true } }) {
        started_at
        ended_at
        approximate_birthdate
        expected_ended_at
        created_at
        external_id
      }
    }
  }
`;

const BARN_LIST_MASS_QUERY = gql`
  query BarnList_GetBarnListData(
    $limit: Int!
    $offset: Int
    $mass_start_time: numeric
    $sha: String!
    $farmFilterWhere: farm_bool_exp!
    $activeFaultWhere: fault_bool_exp! = {}
  ) {
    barns: farm(
      where: { _and: [{ deleted_at: { _is_null: true } }, $farmFilterWhere] }
      order_by: { name: asc }
      limit: $limit
      offset: $offset
    ) {
      id
      mass_moved_since(args: { started_at: $mass_start_time, algorithm_version: $sha })
    }
  }
`;

const BARN_LIST_SAVE_BARN_PINNED_STATE = gql`
  mutation BarnList_SaveBarnPinnedState($objects: [user_pinned_barn_insert_input!]!) {
    insert_user_pinned_barn(
      objects: $objects
      on_conflict: { constraint: user_pinned_barn_user_id_barn_id_unique, update_columns: [deleted_at] }
    ) {
      affected_rows
    }
  }
`;

const VIEW_MODE_LOCALSTORAGE_KEY = 'FeedFlo:BarnList:viewMode'; // The key used to persist the view mode in localStorage

const ALL_BARNS_PAGE_SIZE = 500;
const BARNS_PAGE_SIZE = 5;

function BarnListPage({ titleSegments = [] }) {
  const { isMetric } = useContext(WebAppContext);

  const pageTitleSegments = useMemo(() => ['My Barns', ...titleSegments], []);
  const { user } = useUser();
  const navigate = useNavigate();
  const [searchFilter, setSearchFilter] = useState('');
  const [viewMode, setViewMode] = useAtom(listFormatPrefAtom);
  const [currentPinnedBarnsPage, setCurrentPinnedBarnsPage] = useState(0);
  const [currentBarnAlertsPage, setCurrentBarnAlertsPage] = useState(0);
  const [currentAllBarnsPage, setCurrentAllBarnsPage] = useState(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const faultFilter = { _and: [{ _or: useFaultCodeFilter() }, { ended_at: { _is_null: true } }] };
  const algorithmVersion = useAtomValue(algorithmVersionAtom);

  const nowUnix = useMemo(() => dayjs.tz().unix(), []);
  const mass_days = 3;
  const mass_start_time = useMemo(() => dayjs.tz().subtract(mass_days, 'day').unix(), []);

  const {
    data: barnsData,
    loading: barnsLoading,
    error: barnsError,
  } = useQuery(BARN_LIST_GET_BARN_LIST_DATA_QUERY, {
    variables: {
      offset: 0,
      limit: ALL_BARNS_PAGE_SIZE,
      farmFilterWhere: {},
      activeFaultWhere: faultFilter, // filters the active faults does not filter the barns
    },
    skip: !user?.id,
    fetchPolicy: 'no-cache',
  });

  const { data: barnsMassData } = useQuery(BARN_LIST_MASS_QUERY, {
    variables: {
      offset: 0,
      limit: ALL_BARNS_PAGE_SIZE,
      mass_start_time,
      sha: algorithmVersion,
      farmFilterWhere: {},
      activeFaultWhere: faultFilter, // filters the active faults does not filter the barns
    },
    skip: !user?.id,
    fetchPolicy: 'no-cache',
  });

  const extraBarnDataForView = (barn) => {
    const active_faults = barn.feed_lines
      .reduce((arr, fl) => arr.concat(fl.device_assignments), [])
      .reduce((arr, assignment) => arr.concat(assignment.active_fault_codes), []);
    let nofeed_seconds = active_faults
      .filter((fl) => fl.nofeed_seconds != null)
      .reduce((max, fl) => Math.max(max, fl.nofeed_seconds), Number.MIN_SAFE_INTEGER);
    if (nofeed_seconds === Number.MIN_SAFE_INTEGER) nofeed_seconds = null;

    const active_fault_codes = Array.from(new Set(active_faults.map((f) => f.code)).values());

    let status = StatusEnum.Success;
    if (nofeed_seconds !== null && nofeed_seconds < 0) status = StatusEnum.Warning;

    if (nofeed_seconds !== null && nofeed_seconds > 0) status = StatusEnum.Error;

    if (nofeed_seconds === null && active_faults.length > 0) status = StatusEnum.Warning;

    let statValue = '--';
    let statUnit = `${weightLargeUnitLabel(isMetric)}/day`;

    let active_animal_group = barn.animal_groups?.[0]?.ended_at === null || barn.animal_groups?.[0]?.ended_at > nowUnix;

    let current_week = '?';
    let total_weeks = '?';
    let statLabel = ``;

    if (barn.animal_groups?.[0]?.started_at) {
      const animal_group_age = nowUnix - barn.animal_groups?.[0]?.started_at;
      current_week = Math.floor(animal_group_age / SECONDS_IN_WEEK) + 1;
      statLabel = `Week ${current_week}`;
    }
    if (barn.animal_groups?.[0]?.expected_ended_at) {
      const animal_group_length = barn.animal_groups?.[0]?.expected_ended_at - barn.animal_groups?.[0]?.started_at;
      total_weeks = Math.floor(animal_group_length / SECONDS_IN_WEEK);
      statLabel = `Week ${current_week} of ${total_weeks}`;
    }

    if (!active_animal_group) {
      status = StatusEnum.Inactive;
    }

    if (barnsMassData?.barns) {
      const matchingBarnMass = barnsMassData?.barns.filter((bm) => bm.id === barn.id);
      if (matchingBarnMass.length === 1) {
        const weightInUnits = convertGramsToLargeUnits(isMetric, matchingBarnMass[0].mass_moved_since / mass_days, 1);

        statValue = weightInUnits.toString();
      }
    }
    return {
      ...barn,
      nofeed_seconds: nofeed_seconds,
      barn_name: barn.name,
      barn_id: barn.id,
      is_pinned: barn.user_pinned_barns.length > 0,
      active_fault_codes: active_fault_codes,
      active_animal_group,
      status,
      statValue,
      statUnit,
      statLabel,
      // basically show the org name if you have access to multiple orgs via any method
      showOrgName: user?.isStaff || user?.organizations?.length > 1 || user?.barns?.length > 1,
    };
  };

  const [saveBarnPinnedState] = useMutation(BARN_LIST_SAVE_BARN_PINNED_STATE);

  // post-query processing
  let barnsDataExtras = barnsData?.barns.map(extraBarnDataForView);

  const fzf = useMemo(() => {
    return new Fzf(barnsDataExtras || [], {
      selector: (item) => `${item.name} ${item.organization?.name} ${item.address}`,
    });
  }, [barnsDataExtras]);

  if (barnsDataExtras?.length > 0 && searchFilter && searchFilter != '' && searchFilter != null) {
    const entries = fzf.find(searchFilter);
    barnsDataExtras = entries.map((entry) => entry.item);
  }

  let barnAlertsList = barnsDataExtras?.filter((b) => b.active_fault_codes.length > 0) || [];
  barnAlertsList = barnAlertsList.sort((a, b) => {
    const aNoFeed = a.nofeed_seconds > 0;
    const bNoFeed = b.nofeed_seconds > 0;
    const aGroup = a.active_animal_group;
    const bGroup = b.active_animal_group;

    if (aGroup != bGroup) {
      return bGroup - aGroup;
    }
    if (aNoFeed != bNoFeed) {
      return bNoFeed - aNoFeed;
    }
    return a.name.localeCompare(b.name) > 0 ? 1 : -1;
  });
  const maxBarnAlertsPage = Math.floor((barnAlertsList.length - 1) / BARNS_PAGE_SIZE);
  barnAlertsList = barnAlertsList.slice(
    currentBarnAlertsPage * BARNS_PAGE_SIZE,
    (currentBarnAlertsPage + 1) * BARNS_PAGE_SIZE + 1,
  );

  let pinnedBarnsList = barnsDataExtras?.filter((b) => b.is_pinned) || [];
  const maxPinnedBarnsPage = Math.floor((pinnedBarnsList.length - 1) / BARNS_PAGE_SIZE);
  pinnedBarnsList = pinnedBarnsList.slice(
    currentPinnedBarnsPage * BARNS_PAGE_SIZE,
    (currentPinnedBarnsPage + 1) * BARNS_PAGE_SIZE + 1,
  );

  const maxAllBarnsPage = 0;

  const onChangeSearchFilter = (text) => {
    setSearchFilter(text);
    setCurrentAllBarnsPage(0);
    setSelectedRowIndex(-1);
  };

  const onSwitchViewMode = () => {
    const newViewMode = viewMode === 'card' ? 'list' : 'card';
    setViewMode(newViewMode);
    localStorage.setItem(VIEW_MODE_LOCALSTORAGE_KEY, newViewMode);
  };

  const getNextPinnedBarnsPage = () => {
    if (currentPinnedBarnsPage >= maxPinnedBarnsPage) {
      return;
    }

    setCurrentPinnedBarnsPage(currentPinnedBarnsPage + 1);
  };

  const getPreviousPinnedBarnsPage = () => {
    if (currentPinnedBarnsPage <= 0) {
      return;
    }

    setCurrentPinnedBarnsPage(currentPinnedBarnsPage - 1);
  };

  const getNextBarnAlertsPage = () => {
    if (currentBarnAlertsPage >= maxBarnAlertsPage) {
      return;
    }

    setCurrentBarnAlertsPage(currentBarnAlertsPage + 1);
  };

  const getPreviousBarnAlertsPage = () => {
    if (currentBarnAlertsPage <= 0) {
      return;
    }

    setCurrentBarnAlertsPage(currentBarnAlertsPage - 1);
  };

  const getNextAllBarnsPage = () => {
    if (currentAllBarnsPage >= maxAllBarnsPage) {
      return;
    }

    const newAllBarnsPage = currentAllBarnsPage + 1;
    setCurrentAllBarnsPage(newAllBarnsPage);
    setSelectedRowIndex(-1);
  };

  const getPreviousAllBarnsPage = () => {
    if (currentAllBarnsPage <= 0) {
      return;
    }

    const newAllBarnsPage = currentAllBarnsPage - 1;
    setCurrentAllBarnsPage(newAllBarnsPage);
    setSelectedRowIndex(-1);
  };

  const onKeyDown = (e) => {
    const allBarnsList = barnsDataExtras;
    const allBarnsListLength = allBarnsList?.length;

    // If the list hasn't loaded or there is no search filter entered, do nothing
    if (allBarnsListLength === undefined || searchFilter.length === 0) {
      return;
    }

    if (e.key === 'ArrowDown') {
      const newSelectedRowIndex = (selectedRowIndex + 1) % allBarnsListLength;
      setSelectedRowIndex(newSelectedRowIndex);
    } else if (e.key === 'ArrowUp') {
      const newSelectedRowIndex = (selectedRowIndex - 1 + allBarnsListLength) % allBarnsListLength;
      setSelectedRowIndex(newSelectedRowIndex);
    } else if (e.key === 'Enter' && selectedRowIndex >= 0) {
      const pathname = `/b/${allBarnsList[selectedRowIndex].id}`;

      // If the ctrl or cmd key is held down, open in a new tab instead
      if (e.ctrlKey || e.metaKey) {
        window.open(pathname, '_blank');
      } else {
        navigate(pathname);
      }
    }
  };

  const onBlurSearchFilter = () => {
    setSelectedRowIndex(-1);
  };

  const onPinBarnClick = (barnID, isPinned) => {
    saveBarnPinnedState({
      variables: {
        objects: [
          {
            user_id: user?.id,
            barn_id: barnID,
            deleted_at: isPinned ? dayjs.tz().unix() : null,
          },
        ],
      },
      skip: !user?.id,
      refetchQueries: ['BarnList_GetBarnListData'],
    });
  };

  if (barnsError) {
    return 'Unknown Error: please reload the page';
  }

  return (
    <BarnListPageView
      pageTitleSegments={pageTitleSegments}
      searchFilter={searchFilter}
      viewMode={viewMode}
      pinnedBarnsListLoading={barnsLoading}
      pinnedBarnsList={pinnedBarnsList}
      currentPinnedBarnsPage={currentPinnedBarnsPage}
      maxPinnedBarnsPage={maxPinnedBarnsPage}
      barnAlertsListLoading={barnsLoading}
      barnAlertsList={barnAlertsList}
      currentBarnAlertsPage={currentBarnAlertsPage}
      maxBarnAlertsPage={maxBarnAlertsPage}
      allBarnsSelectedRowIndex={selectedRowIndex}
      allBarnsListLoading={barnsLoading}
      allBarnsList={barnsDataExtras || []}
      currentAllBarnsPage={currentAllBarnsPage}
      maxAllBarnsPage={maxAllBarnsPage}
      onKeyDown={onKeyDown}
      onChangeSearchFilter={onChangeSearchFilter}
      onBlurSearchFilter={onBlurSearchFilter}
      onSwitchViewMode={onSwitchViewMode}
      onNextPinnedBarnsPageClick={getNextPinnedBarnsPage}
      onPreviousPinnedBarnsPageClick={getPreviousPinnedBarnsPage}
      onNextBarnAlertsPageClick={getNextBarnAlertsPage}
      onPreviousBarnAlertsPageClick={getPreviousBarnAlertsPage}
      onNextAllBarnsPageClick={getNextAllBarnsPage}
      onPreviousAllBarnsPageClick={getPreviousAllBarnsPage}
      onPinBarnClick={onPinBarnClick}
    />
  );
}

BarnListPage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
};

export default BarnListPage;
