/** MS Clarity Docs:
 * https://learn.microsoft.com/en-us/clarity/setup-and-installation/clarity-api
 **/

// This comes from the "Install Manually" script call
// Also found in the URL https://clarity.microsoft.com/projects/view/$PROJECTID
const CLARITY_PROJECT_ID = import.meta.env.VITE_CLARITY_PROJECT_ID;

// This is the function provided by MS to "install manually" minus the auto-call
function msClarityInit(c, l, a, r, i, t, y) {
  c[a] =
    c[a] ||
    function () {
      (c[a].q = c[a].q || []).push(arguments);
    };
  t = l.createElement(r);
  t.async = 1;
  t.src = 'https://www.clarity.ms/tag/' + i;
  y = l.getElementsByTagName(r)[0];
  y.parentNode.insertBefore(t, y);
}

/**
 * should be called for each page per the docs
 **/
export async function identify(userId, pageId) {
  if (!userId || !userId.trim()) {
    throw new Error('userId not passed to identify');
  }
  if (typeof window.clarity !== 'function' && CLARITY_PROJECT_ID) {
    console.warn('Clarity NOT initialized yet');
    return;
  }

  return window.clarity?.('identify', userId, null, pageId);
}

/**
  initialize clarity but only do it once. Also tell Devs if a variable is missing on production
**/
export function initClarity() {
  if (typeof window.clarity !== 'function' && CLARITY_PROJECT_ID) {
    msClarityInit(window, document, 'clarity', 'script', CLARITY_PROJECT_ID);
    console.log('initialized MS Clarity');
  }

  if (!CLARITY_PROJECT_ID && location.host === 'my.feedflo.com') {
    console.warn('No Clarity Project ID Set.');
  }
}
