import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import GeneralSettings from './GeneralSettings';
import NotificationSettings from './NotificationSettings';
import Page from '../../atoms/Page';
import UnsavedChangesBar from '../../molecules/UnsavedChangesBar';
import useUser from '../../utils/hooks/useUser';
import FeatureFlagManager from '../../organisms/FeatureFlagManager';
import './SettingsPage.scss';
import FeedDeskSettings from './FeedDeskSettings';

function SettingsPage({ titleSegments = [] }) {
  const { user } = useUser();
  const [hasChanges, setHasChanges] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const generalSettingsRef = useRef();
  const notificationSettingsRef = useRef();
  const feedDeskSettingsRef = useRef();
  const pageTitleSegments = useMemo(() => ['Settings', ...titleSegments], []);

  const onGeneralChange = (changed) => {
    setHasChanges(changed);
  };

  const onNotificationChange = (changed) => {
    setHasChanges(changed);
  };

  const handleSaveClicked = () => {
    generalSettingsRef.current.save();
    feedDeskSettingsRef.current.save();
    notificationSettingsRef.current.save();
    setHasChanges(false);
  };

  return (
    <Page className="SettingsPage" heading="Settings" titleSegments={pageTitleSegments}>
      {hasChanges && <UnsavedChangesBar isValid={isValid} onSaveClick={handleSaveClicked} />}
      <div className="row">
        <div className="subHeading">General Settings</div>
        <div className="section">
          <GeneralSettings ref={generalSettingsRef} onChange={(changed) => onGeneralChange(changed)} />
        </div>
      </div>
      <div className="row">
        <div className="subHeading">Notifications</div>
        <div className="section">
          <NotificationSettings
            onChange={(changed, valid) => {
              if (typeof valid === 'boolean') {
                setIsValid(valid);
              }
              onNotificationChange(changed);
            }}
            ref={notificationSettingsRef}
          />
        </div>
      </div>
      <div className="row">
        <div className="subHeading">Feed Desk Settings</div>
        <div className="section">
          <FeedDeskSettings
            onChange={(changed, valid) => {
              if (typeof valid === 'boolean') {
                setIsValid(valid);
              }
              setHasChanges(changed);
            }}
            ref={feedDeskSettingsRef}
          />
        </div>
      </div>

      {user?.isStaff && (
        <div className="row">
          <div className="subHeading">Staff Only</div>
          <div className="section">
            <FeatureFlagManager />
          </div>
        </div>
      )}
    </Page>
  );
}

SettingsPage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
};

export default SettingsPage;
