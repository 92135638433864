import React, { useEffect, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { useQuery, useMutation } from '@apollo/client';
import { FaultCodes } from '@norimaconsulting/fault-codes';
import useUser from '../../../utils/hooks/useUser';
import { TransportTypes } from '../../../utils/enums';
import NotificationSettingsView from './NotificationSettingsView';
import {
  FARM_GQL,
  GET_ALERT_POLICIES_GQL,
  UPSERT_CUSTOMIZED_BARN_ALERT_POLICIES_GQL,
  UPSERT_DEFAULT_ALERT_POLICIES_GQL,
} from './queries';

const DEFAULT_NOTIFICATION_SETTINGS = {
  [FaultCodes.EMPTY_PIPE]: {
    sms: { initial: -1, reminder: -1 },
    email: { initial: -1, reminder: -1 },
  },
  [FaultCodes.INACTIVE_AUGER]: {
    sms: { initial: -1, reminder: -1 },
    email: { initial: -1, reminder: -1 },
  },
  [FaultCodes.SUDDEN_CONSUMPTION_DROP]: {
    sms: { initial: -1, reminder: -1 },
    email: { initial: -1, reminder: -1 },
  },
  [FaultCodes.CONSUMPTION_TRENDING_DOWN]: {
    sms: { initial: -1, reminder: -1 },
    email: { initial: -1, reminder: -1 },
  },
};

const NotificationSettings = forwardRef(({ onChange }, ref) => {
  const { user, loading: userLoading } = useUser();
  const { loading: farmLoading, data: farmData } = useQuery(FARM_GQL, { fetchPolicy: 'no-cache' });
  const { loading: policyLoading, data: policyData } = useQuery(GET_ALERT_POLICIES_GQL, {
    fetchPolicy: 'no-cache',
    variables: {
      user_id: user?.id,
      fault_codes: [
        FaultCodes.EMPTY_PIPE,
        FaultCodes.INACTIVE_AUGER,
        FaultCodes.SUDDEN_CONSUMPTION_DROP,
        FaultCodes.CONSUMPTION_TRENDING_DOWN,
      ],
    },
    skip: !user?.id,
  });
  const [upsertDefaultAlertPolicies, { loading: upsertDefaultLoading }] = useMutation(
    UPSERT_DEFAULT_ALERT_POLICIES_GQL,
  );
  const [upsertCustomizedBarnAlertPolicies, { loading: upsertCustomizedLoading }] = useMutation(
    UPSERT_CUSTOMIZED_BARN_ALERT_POLICIES_GQL,
  );
  const [globalNotificationSettings, setGlobalNotificationSettings] = useState(null);
  const [customizedBarns, setCustomizedBarns] = useState([]);
  const [mutedBarns, setMutedBarns] = useState([]);
  const allBarns = farmData?.farm || [];
  const allPolicies = policyData?.alert_policy || [];
  const organizationID = policyData?.user_organization?.[0]?.organization_id || null;
  const loading = farmLoading || policyLoading || upsertDefaultLoading || upsertCustomizedLoading;

  useEffect(() => {
    if (policyLoading) {
      return;
    }

    const globalSettingsRows = [];
    const barnRows = [];

    // Separate the policies as either global or barn-scoped
    allPolicies.forEach((policy) => {
      if (policy?.organization_id) {
        globalSettingsRows.push(policy);
      } else {
        barnRows.push(policy);
      }
    });

    // Convert the alert_policy rows for the global settings into an object for convenience
    const globalSettings = cloneDeep(DEFAULT_NOTIFICATION_SETTINGS);
    globalSettingsRows.forEach((row) => {
      globalSettings[row.alert_for][row.alert_transport].initial = row.alert_threshold_in_seconds;
      globalSettings[row.alert_for][row.alert_transport].reminder = row.renotify_interval_in_seconds;
    });

    setGlobalNotificationSettings(globalSettings);

    // Loop through the policy rows group them together based on their alert and transport types
    const barnRowMap = {};
    barnRows
      .filter((row) => row.farm !== null)
      .forEach((row) => {
        if (!barnRowMap[row.farm_id]) {
          barnRowMap[row.farm_id] = cloneDeep(DEFAULT_NOTIFICATION_SETTINGS);
        }

        barnRowMap[row.farm_id].id = row.farm_id;
        barnRowMap[row.farm_id].name = row.farm.name;
        barnRowMap[row.farm_id][row.alert_for][row.alert_transport].initial = row.alert_threshold_in_seconds;
        barnRowMap[row.farm_id][row.alert_for][row.alert_transport].reminder = row.renotify_interval_in_seconds;
      });

    // Look at each entry in the map and categorize them as either customized or muted
    const customizedBarnRows = [];
    const mutedBarnRows = [];
    Object.values(barnRowMap).forEach((row) => {
      // If notifications are disabled for all web-configurable transport types for each alert type, the barn is muted
      if (
        row[FaultCodes.EMPTY_PIPE][TransportTypes.SMS].initial === -1 &&
        row[FaultCodes.EMPTY_PIPE][TransportTypes.Email].initial === -1 &&
        row[FaultCodes.INACTIVE_AUGER][TransportTypes.SMS].initial === -1 &&
        row[FaultCodes.INACTIVE_AUGER][TransportTypes.Email].initial === -1 &&
        row[FaultCodes.SUDDEN_CONSUMPTION_DROP][TransportTypes.SMS].initial === -1 &&
        row[FaultCodes.SUDDEN_CONSUMPTION_DROP][TransportTypes.Email].initial === -1 &&
        row[FaultCodes.CONSUMPTION_TRENDING_DOWN][TransportTypes.SMS].initial === -1 &&
        row[FaultCodes.CONSUMPTION_TRENDING_DOWN][TransportTypes.Email].initial === -1
      ) {
        mutedBarnRows.push(row);
      } else {
        customizedBarnRows.push(row);
      }
    });

    setCustomizedBarns(customizedBarnRows);
    setMutedBarns(mutedBarnRows);
  }, [allPolicies]);

  async function mutateDefaultAlertPolicies() {
    if (!organizationID) {
      return;
    }

    const policies = [];
    Object.keys(globalNotificationSettings).forEach((faultCode) => {
      // Get the transport types under each fault code
      Object.keys(globalNotificationSettings[faultCode]).forEach((transportType) => {
        policies.push({
          alert_transport: transportType,
          status: 'active',
          alert_for: faultCode,
          alert_threshold_in_seconds: globalNotificationSettings[faultCode][transportType].initial,
          renotify_interval_in_seconds: globalNotificationSettings[faultCode][transportType].reminder,
          organization_id: organizationID,
        });
      });
    });

    upsertDefaultAlertPolicies({ variables: { policies }, refetchQueries: ['GetAlertPolicies'] });
  }

  const mutateCustomizedBarnAlertPolicies = useCallback(async () => {
    const policies = [];
    const faultCodes = [
      FaultCodes.EMPTY_PIPE,
      FaultCodes.INACTIVE_AUGER,
      FaultCodes.SUDDEN_CONSUMPTION_DROP,
      FaultCodes.CONSUMPTION_TRENDING_DOWN,
    ]; // List of fault codes we care about alerts for
    [...customizedBarns, ...mutedBarns].forEach((barn) => {
      faultCodes.forEach((faultCode) => {
        // Get the transport types under each fault code
        Object.keys(barn[faultCode]).forEach((transportType) => {
          policies.push({
            alert_transport: transportType,
            status: 'active',
            alert_for: faultCode,
            alert_threshold_in_seconds: barn[faultCode][transportType].initial,
            renotify_interval_in_seconds: barn[faultCode][transportType].reminder,
            farm_id: barn.id,
          });
        });
      });
    });

    upsertCustomizedBarnAlertPolicies({
      variables: { user_id: user?.id, policies },
      refetchQueries: ['GetAlertPolicies'],
    });
  }, [user, userLoading, customizedBarns, mutedBarns]);

  useImperativeHandle(ref, () => ({
    save: async () => {
      const savePromises = [mutateDefaultAlertPolicies(), mutateCustomizedBarnAlertPolicies()];
      return Promise.allSettled(savePromises);
    },
  }));

  const onUpdateDefaultNotificationSettings = (faultCode, transportType, newValues, isValid) => {
    const settingsCopy = cloneDeep(globalNotificationSettings);
    settingsCopy[faultCode][transportType] = newValues;
    setGlobalNotificationSettings(settingsCopy);

    const changed = !isEqual(globalNotificationSettings, settingsCopy);
    onChange(changed, isValid);
  };

  const onAddCustomizedBarn = (_, id) => {
    const newBarn = allBarns.find((barn) => barn.id === id);
    setCustomizedBarns([
      ...customizedBarns,
      {
        id: newBarn.id,
        name: newBarn.name,
        ...cloneDeep(globalNotificationSettings), // Use the defaults for all barns as the initial values for new barns
      },
    ]);
    onChange(true, true);
  };

  const onUpdateCustomizedBarn = (id, faultCode, transportType, newValues, isValid) => {
    const index = customizedBarns.findIndex((barn) => barn.id === id);
    setCustomizedBarns([
      ...customizedBarns.slice(0, index),
      {
        ...customizedBarns[index],
        [faultCode]: {
          ...customizedBarns[index][faultCode],
          [transportType]: newValues,
        },
      },
      ...customizedBarns.slice(index + 1),
    ]);

    onChange(true, isValid);
  };

  const onRemoveCustomizedBarn = (id) => {
    const index = customizedBarns.findIndex((barn) => barn.id === id);
    setCustomizedBarns([...customizedBarns.slice(0, index), ...customizedBarns.slice(index + 1)]);
    onChange(true, true);
  };

  const onAddMutedBarn = (_, id) => {
    const newBarn = allBarns.find((barn) => barn.id === id);
    setMutedBarns([
      ...mutedBarns,
      {
        id: newBarn.id,
        name: newBarn.name,
        ...cloneDeep(DEFAULT_NOTIFICATION_SETTINGS),
      },
    ]);
    onChange(true, true);
  };

  const onRemoveMutedBarn = (id) => {
    const index = mutedBarns.findIndex((barn) => barn.id === id);
    setMutedBarns([...mutedBarns.slice(0, index), ...mutedBarns.slice(index + 1)]);
    onChange(true, true);
  };

  return (
    <NotificationSettingsView
      loading={loading}
      organizationID={organizationID}
      defaultNotificationSettings={globalNotificationSettings || DEFAULT_NOTIFICATION_SETTINGS}
      allBarns={allBarns}
      customizedBarns={customizedBarns}
      mutedBarns={mutedBarns}
      onUpdateDefaultNotificationSettings={onUpdateDefaultNotificationSettings}
      onAddCustomizedBarn={onAddCustomizedBarn}
      onAddMutedBarn={onAddMutedBarn}
      onUpdateCustomizedBarn={onUpdateCustomizedBarn}
      onRemoveCustomizedBarn={onRemoveCustomizedBarn}
      onRemoveMutedBarn={onRemoveMutedBarn}
    />
  );
});

NotificationSettings.displayName = 'NotificationSettings';

NotificationSettings.propTypes = {
  onChange: PropTypes.func,
};

export default NotificationSettings;
