import { FaultCodes } from '@norimaconsulting/fault-codes';

export const addComma = (num) => {
  if (num === undefined) return '';
  return num.toLocaleString();
};

export const ALERTABLE_FAULT_CODES = [FaultCodes.EMPTY_PIPE, FaultCodes.INACTIVE_AUGER];

export default [addComma, ALERTABLE_FAULT_CODES];
