import PropTypes from 'prop-types';
import './Pill.scss';

function Pill({ className = '', label = '', value = '', onRemove = () => {} }) {
  const onClick = () => {
    onRemove(value);
  };

  return (
    <div className={`Pill ${className}`}>
      <div className="Pill-text">{label ? label : value}</div>
      <div className="Pill-removeButton" onClick={onClick}>
        <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 0.5L4 3.5M1 6.5L4 3.5M4 3.5L1 0.5M4 3.5L7 6.5" stroke="#1A1F36" />
        </svg>
      </div>
    </div>
  );
}

Pill.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onRemove: PropTypes.func,
};

export default Pill;
