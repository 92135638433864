import PropTypes from 'prop-types';

function BarnBlockStat({ value = '', unit = '', label = '' }) {
  return (
    <div className="BarnBlock-stat">
      <div className="BarnBlock-valueWrapper">
        <div className="BarnBlock-value">{value}</div>
        <div className="BarnBlock-unit">{unit}</div>
      </div>
      <div className="BarnBlock-label">{label}</div>
    </div>
  );
}

BarnBlockStat.propTypes = {
  value: PropTypes.string,
  unit: PropTypes.string,
  label: PropTypes.string,
};

export default BarnBlockStat;
