import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { featureFlagsLocalStorageKey } from '../../utils/jotaiAtoms';
import useUser from '../../utils/hooks/useUser';
import { useCensor, censorTypes } from '../../utils/hooks/useCensor/useCensor';
import { UserIcon, MenuIcon, EmailIcon, PhoneIcon } from '../../atoms/Icons';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';
import UserDropDown from '../../molecules/UserDropDown';
import './TopBar.scss';
import { TIME_FORMAT_UTC_OFFSET_TIMEZONE } from '../../utils/dates';

const ORG_GQL = gql`
  query OrgListQuery {
    organization {
      name
      id
    }
  }
`;
function TopBar({ onMenuButton = () => {} }) {
  const { logout } = useAuth0();
  const { user, loading: loadingUser } = useUser();
  const [isMenuShown, setMenuShown] = useState(false);
  const navigate = useNavigate();
  const { censor } = useCensor();

  const helpRef = useRef(null);
  const menuRef = useRef(null);
  const { data } = useQuery(ORG_GQL);
  const orgList = data?.organization;

  const toggleMenu = () => {
    setMenuShown(!isMenuShown);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuShown(false);
    }
  };

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  let orgName = 'Multiple';
  if (orgList?.length === 0) orgName = '';
  else if (orgList?.length === 1) orgName = orgList[0].name;
  else orgName = 'Multiple Orgs';

  let userAvatar = <UserIcon className="TopBar-defaultUserIcon" />;

  return (
    <>
      <div className="TopBar">
        <button
          className="buttons left menu"
          type="button"
          onClick={() => {
            onMenuButton();
          }}
        >
          <div ref={helpRef}>
            <MenuIcon />
          </div>
        </button>
        <img className="ff_logo" src="/images/FeedFlo_text_logo.svg" alt="FeedFlow Logo" />
        <button
          className="buttons right"
          type="button"
          ref={menuRef}
          onClick={() => {
            toggleMenu();
          }}
        >
          <div className="userButton">
            <div className="userInfo">
              <div>{loadingUser ? <LoadingSkeleton /> : censor(user.email, censorTypes.pii)}</div>
              <div>
                <span className="orgName">{censor(orgName, censorTypes.organization)}</span>
                <span className="timezone">{dayjs.tz().format(TIME_FORMAT_UTC_OFFSET_TIMEZONE)}</span>
              </div>
            </div>
          </div>
          {loadingUser ? <LoadingSkeleton /> : userAvatar}
        </button>
      </div>
      {isMenuShown && (
        <UserDropDown
          items={[
            {
              title: 'Settings',
              onClick: () => {
                navigate('/settings');
              },
            },
            {
              icon: <EmailIcon />,
              title: 'Email: support@feedflo.com',
              onClick: () => {
                window.location = 'mailto:support@feedflo.com';
              },
            },
            {
              icon: <PhoneIcon />,
              title: 'Phone: +1(431) 803-0831',
              onClick: () => {
                window.location = 'tel:+1(431) 803-0831';
              },
            },
            {
              title: 'Log Out',
              onClick: () => {
                // Wipe the feature flags in case next user has different flags
                localStorage.clear(featureFlagsLocalStorageKey);
                logout({ returnTo: window.location.origin });
              },
            },
          ]}
        />
      )}
    </>
  );
}

TopBar.propTypes = {
  onMenuButton: PropTypes.func,
};

export default TopBar;
