import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { censorTypes, useCensor } from '../../utils/hooks/useCensor/useCensor';
import LoadingSkeleton from '../LoadingSkeleton';
import 'react-phone-input-2/lib/plain.css';
import './FeedFloTextInput.scss';

/***
 * ? DEPRECATED ?
 */
function FeedFloTextInput({
  loading = false,
  isCensored = false,
  className = '',
  labelClassName = '',
  inputClassName = '',
  label = '',
  type = 'text',
  disabled = false,
  autoFocus = false,
  text = '',
  icon = null,
  defaultText = '',
  readOnly = false,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onKeyDown = () => {},
}) {
  const { censor } = useCensor();
  const [inputText, setInputText] = useState('');

  useEffect(() => {
    setInputText(text);
  }, [text]);

  function onTextInputChange(e) {
    onChange(e.target.value);
  }

  const onTextInputKeyDown = (e) => {
    onKeyDown(e);
  };

  const renderInput = () => {
    if (isCensored) {
      return <div>{censor(inputText, censorTypes.pii)}</div>;
    }

    return (
      <div className="input-icons ">
        {icon && <i className="user icon">{icon}</i>}
        <input
          readOnly={readOnly}
          className={`FeedFloTextInput-input ${icon ? 'moveText' : ''} ${disabled ? 'disabled' : ''} ${inputClassName}`}
          type={type}
          placeholder={defaultText}
          value={inputText}
          disabled={disabled}
          autoFocus={autoFocus}
          onChange={(e) => {
            setInputText(e.target.value);
            onTextInputChange(e);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onTextInputKeyDown}
        />
      </div>
    );
  };

  if (loading) {
    return (
      <div className="FeedFloTextInput">
        <LoadingSkeleton className="FeedFloTextInput-label--loader" />
        <LoadingSkeleton className="FeedFloTextInput-input--loader" />
      </div>
    );
  }

  return (
    <div className={`FeedFloTextInput ${className}`}>
      {label ? (
        <label className={`FeedFloTextInput--label ${disabled ? 'disabled' : ''} ${labelClassName}`}>{label}</label>
      ) : null}
      {renderInput()}
    </div>
  );
}

FeedFloTextInput.propTypes = {
  loading: PropTypes.bool,
  isCensored: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  text: PropTypes.string,
  defaultText: PropTypes.string,
  readOnly: PropTypes.bool,
  icon: PropTypes.element,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
};

export default FeedFloTextInput;
