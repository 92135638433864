import PropTypes from 'prop-types';
import './ChangePercentageBadge.scss';

function ChangePercentageBadge({ value = 0, previousValue = 0 }) {
  let change;
  let className;
  let isNotApplicable = false;

  if (previousValue === null) {
    change = 0;
    isNotApplicable = true;
  } else if (previousValue === 0) {
    change = 0;
  } else {
    change = ((previousValue - value) / previousValue) * 100;
  }

  if (change === 0) {
    className = 'neutral';
  } else if (change < 0) {
    className = 'negative';
  } else {
    className = 'positive';
  }

  const renderChangeSymbol = () => {
    if (isNotApplicable || change === 0) {
      return null;
    }

    return <div className={`ChangePercentageBadge-symbol ChangePercentageBadge-symbol--${className}`} />;
  };

  const renderChangeText = () => {
    if (isNotApplicable) {
      return <div className="ChangePercentageBadge-text">N/A</div>;
    }

    return <div className="ChangePercentageBadge-text">{`${Math.abs(change).toFixed(0)}%`}</div>;
  };

  return (
    <div className={`ChangePercentageBadge ChangePercentageBadge--${className}`}>
      {renderChangeSymbol()}
      {renderChangeText()}
    </div>
  );
}

ChangePercentageBadge.propTypes = {
  value: PropTypes.number,
  previousValue: PropTypes.number,
};

export default ChangePercentageBadge;
