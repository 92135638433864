/* eslint-disable react/jsx-no-bind */

import React, { useState, useEffect, useRef } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { useAtom, useSetAtom } from 'jotai';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import usePageTracking from './utils/usePageTracking';

import WebAppContext from './utils/webAppContext';
import SideBar from './organisms/SideBar';
import TopBar from './organisms/TopBar';
import AlertDashboardPage from './pages/AlertDashboardPage';
import ApiAccessPage from './pages/APIAccessPage';
import BarnListPage from './pages/BarnListPage';
import BarnPage from './pages/BarnPage';
import MapPage from './pages/MapPage';
import NotFoundPage from './pages/NotFoundPage';
import SettingsPage from './pages/SettingsPage';
import ExportsPage from './pages/ExportsPage';
import FirmwarePage from './pages/FirmwarePage';
import DevicePage from './pages/DevicePage';
import TLVPage from './pages/TLVPage';
import RawDataPage from './pages/RawDataPage';
import AccuracyScorePage from './pages/AccuracyScorePage';
import FaultCodePage from './pages/FaultCodePage';
import FeedFramePage from './pages/FeedFramePage';
import DeviceStatusPage from './pages/DeviceStatusPage';
import FeedOrdersPage from './pages/FeedOrdersPage';
import CommandPalette from './organisms/CommandPalette';
import { algorithmOverriddenAtom, algorithmVersionAtom, featureFlagsAtom } from './utils/jotaiAtoms';
import useAppBanner from './utils/hooks/useAppBanner/useAppBanner';
import { identify, initClarity } from './utils/clarityHelpers';
import useUser from './utils/hooks/useUser';
import Banner from './molecules/Banner';
import { useFakeQuery } from './organisms/FeatureFlagManager/FeatureFlagManager';
import { setupSentryRoutes, setSentryUser } from './utils/setupSentry';
import './App.scss';

const SentryRoutes = setupSentryRoutes();

const ALGORITHM_OVERRIDE = import.meta.env.VITE_ALGORITHM_OVERRIDE;

const APP_GQL = gql`
  query AppGlobalInfoQuery($userID: uuid!) {
    user: user_by_pk(id: $userID) {
      is_metric
    }

    algorithm(order_by: { published_at: desc_nulls_last }, where: { published_at: { _is_null: false } }, limit: 1) {
      published_at
      version
    }
  }
`;

export default function App() {
  let location = useLocation();
  const { user, loading: userLoading } = useUser();
  const { data: featuresData, loading: featuresLoading } = useFakeQuery(user?.isStaff, {
    skip: userLoading,
  }); // faked
  const [featureFlags, setFeatureFlags] = useAtom(featureFlagsAtom); // the array is an atom
  const [visibleSideBar, setVisibleSideBar] = useState(false);
  const [isMetric, setIsMetric] = useState(false);
  const [algorithmVersion, setAlgorithmVersion] = useAtom(algorithmVersionAtom);
  const setAlgorithmOverridden = useSetAtom(algorithmOverriddenAtom);
  const viewportRef = useRef();
  const { show: showBanner, message: bannerMessage } = useAppBanner();

  useEffect(() => {
    dayjs.tz.setDefault(user.timezone);
  }, [user.timezone]);

  const { loading, data, error } = useQuery(APP_GQL, {
    fetchPolicy: 'no-cache',
    variables: {
      userID: user?.id,
    },
    skip: !user?.id,
  });

  const nonDefaultAlg =
    (data?.algorithm?.[0]?.version !== algorithmVersion && data?.algorithm?.[0]?.version !== undefined) ||
    ALGORITHM_OVERRIDE;

  useEffect(() => {
    // Only set the alg version if we're done loading and a dev hasn't overridden the live version
    if (!loading && !ALGORITHM_OVERRIDE && !algorithmVersion) {
      setAlgorithmVersion(data?.algorithm?.[0]?.version);
    }
    // handle errors
    if (error) {
      console.error(error);
    }
    // If the env var has been used override it
    if (ALGORITHM_OVERRIDE) {
      setAlgorithmVersion(ALGORITHM_OVERRIDE);
      setAlgorithmOverridden(true);
    } else {
      setAlgorithmOverridden(false);
    }

    if (!loading) {
      const isMetric = data?.user?.is_metric;
      setIsMetric(isMetric || false);
    }
  }, [loading, data, error]);

  useEffect(() => {
    // call identify every page change but only with a logged in user
    if (user) {
      // use the document title since that's a nice clean string to identify
      // pages without including search params or UUIDs
      identify(user.id, document.title);
      setSentryUser(user.id, user.isStaff, user.organizations);
    }
  }, [user, location]);

  useEffect(() => {
    // Set up Feature Flags
    // TODO: Make this a util function/hook/component and actually pull from the DB?

    // If the lengths are the same we're going to assume with our fakeResponse that they're the same
    // No need to re-fetch and cause an infinite re-render loop
    // Replace with async code if moved to the DB though!
    // Only update if the user is fully loaded and if we have more features now than locally.
    // I could improve this logic in the future
    if (user && !userLoading && !featuresLoading && featuresData && featuresData?.length > featureFlags?.length) {
      setFeatureFlags(featuresData);
    }
  }, [featuresData, featuresLoading, user]);

  useEffect(() => {
    // One-Time Setup Calls
    initClarity();

    // provide a way to change the alg version when live! With some minor checks
    window.setAlgorithmVersion = (version) => {
      if (!version) {
        console.error('You must pass an algorithm version');
      }
      if (typeof version !== 'string') {
        console.error('Algorithm version must be a string');
      }
      setAlgorithmVersion(version);
      setAlgorithmOverridden(true);
    };
  }, []);

  function toggleSideBar() {
    setVisibleSideBar(!visibleSideBar);
  }
  function closeSideBar() {
    setVisibleSideBar(false);
  }
  usePageTracking();

  return (
    <div className="App">
      {showBanner && <Banner className="App-notification" message={bannerMessage} />}
      <CommandPalette />
      <WebAppContext.Provider value={{ isMetric, setIsMetric }}>
        <TopBar onMenuButton={toggleSideBar} />
        <SideBar
          open={visibleSideBar}
          onClickOutside={closeSideBar}
          devInfoClassName={nonDefaultAlg && 'SideBarView-devInfo--updated'}
        />
        <div className="rightSide">
          <div className="pageSpace customScroll" ref={viewportRef}>
            <SentryRoutes>
              <Route exact path="/" element={<BarnListPage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/b/:barnId/*" element={<BarnPage />} />
              <Route path="/barns/:barnId/*" element={<BarnPage />} />
              <Route path="/alerts" element={<AlertDashboardPage viewportRef={viewportRef} />} />
              <Route path="/map" element={<MapPage />} />
              <Route path="/exports" element={<ExportsPage />} />
              <Route path="/firmware" element={<FirmwarePage />} />
              <Route path="/apiAccess" element={<ApiAccessPage />} />
              <Route path="/devices" element={<DevicePage />} />
              <Route path="/deviceStatus" element={<DeviceStatusPage />} />
              <Route path="/FeedDesk" element={<FeedOrdersPage />} />
              <Route path="/rawData/:deviceId" element={<RawDataPage />} />
              <Route path="/tlv/:dataId" element={<TLVPage />} />
              <Route path="/accuracyScorePage" element={<AccuracyScorePage />} />
              <Route path="/fault/:faultId/*" element={<FaultCodePage />} />
              <Route path="/ff/:id" element={<FeedFramePage />} />
              <Route path="*" element={<NotFoundPage />} />
            </SentryRoutes>
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          enableMultiContainer
        />
      </WebAppContext.Provider>
    </div>
  );
}
