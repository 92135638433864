import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './Card.scss';

function Card({
  loading = false,
  className = '',
  contentClassName = '',
  tabIndex = 0,
  accentLocation = 'left',
  status = 'none',
  linkTo = '',
  children = [],
  onKeyDown = () => {},
  onClick = () => {},
}) {
  const getCardAccentClassName = () => {
    const classes = ['Card-accent', `Card-accent--${accentLocation}`];

    if (loading) {
      classes.push('Card-accent--loading');
    } else {
      classes.push(`Card-accent--${status}`);
    }

    if (contentClassName) {
      classes.push(contentClassName);
    }

    return classes.join(' ');
  };

  const cardJSX = (
    <div className={`Card ${className}`} tabIndex={tabIndex} onKeyDown={onKeyDown} onClick={onClick}>
      <div className={getCardAccentClassName()}>{children}</div>
    </div>
  );

  return linkTo ? <Link to={linkTo}>{cardJSX}</Link> : cardJSX;
}

Card.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  tabIndex: PropTypes.number,
  accentLocation: PropTypes.oneOf(['none', 'top', 'right', 'bottom', 'left']),
  status: PropTypes.oneOf([
    'none',
    'success',
    'warning',
    'error',
    'investigate',
    'recommended',
    'scheduled',
    'delivered',
    'shipped',
    'cancelled',
    'mixed',
    'inactive',
  ]),
  linkTo: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

export default Card;
