import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import FeedFloButton from '../../atoms/FeedFloButton';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';
import './CommentBlock.scss';
import Avatar from '../../atoms/Avatar';

function CommentBlock({
  loading = false,
  id = '',
  avatar = '',
  name = '',
  comment = '',
  createdAt = 0,
  updatedAt = 0,
  isAuthor = false,
  allowEdit = false,
  allowDelete = false,
  onEdit = () => {},
  onDelete = () => {},
}) {
  const [editedComment, setEditedComment] = useState(comment);
  const [isEditing, setIsEditing] = useState(false);
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 60000);
    return () => clearInterval(interval);
  }, []);

  const onEditClick = () => {
    setIsEditing(true);
  };

  const onEditCommentText = (text) => {
    setEditedComment(text);
  };

  const onCancelClick = () => {
    setIsEditing(false);
    setEditedComment(comment);
  };

  const onSaveClick = () => {
    setIsEditing(false);
    onEdit(id, editedComment);
  };

  const onDeleteClick = () => {
    onDelete(id);
  };

  const renderControls = () => {
    if (!isAuthor) {
      return null;
    }

    if (isEditing) {
      return (
        <div className="CommentBlock-controls">
          {allowEdit && (
            <FeedFloButton className="CommentBlock-controlButton" onClick={onCancelClick}>
              Cancel
            </FeedFloButton>
          )}
          <FeedFloButton className="CommentBlock-controlButton" onClick={onSaveClick}>
            Save
          </FeedFloButton>
        </div>
      );
    }

    return (
      <div className="CommentBlock-controls">
        {allowEdit && (
          <FeedFloButton className="CommentBlock-controlButton" onClick={onEditClick}>
            Edit
          </FeedFloButton>
        )}
        {allowDelete && (
          <FeedFloButton className="CommentBlock-controlButton" onClick={onDeleteClick}>
            Delete
          </FeedFloButton>
        )}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="CommentBlock">
        <LoadingSkeleton className="CommentBlock-avatar--loader" />
        <div className="CommentBlock-textContent">
          <div className="CommentBlock-header">
            <LoadingSkeleton className="CommentBlock-name--loader" />
            <LoadingSkeleton className="CommentBlock-createdAt--loader" />
          </div>
          <div className="CommentBlock-comment">
            <LoadingSkeleton className="CommentBlock-comment--fullLoader" />
            <LoadingSkeleton className="CommentBlock-comment--fullLoader" />
            <LoadingSkeleton className="CommentBlock-comment--fullLoader" />
            <LoadingSkeleton className="CommentBlock-comment--halfLoader" />
          </div>
          <div className="CommentBlock-footer" />
        </div>
      </div>
    );
  }

  return (
    <div className="CommentBlock">
      <Avatar src={avatar} className="CommentBlock-avatar" shape="circle" />
      <div className="CommentBlock-textContent">
        <div className="CommentBlock-header">
          <div className="CommentBlock-name">{name}</div>
          <div className="CommentBlock-createdAt">{dayjs.tz(createdAt * 1000).from(now)}</div>
        </div>
        <div className="CommentBlock-comment">
          <MDEditor
            height={isEditing ? 200 : 'auto'}
            className={isEditing ? 'CommentBlock-editor' : 'CommentBlock-editor--preview'}
            value={editedComment}
            hideToolbar={!isEditing}
            visibleDragbar={isEditing}
            enableScroll={isEditing}
            preview={isEditing ? 'live' : 'preview'}
            previewOptions={{
              rehypePlugins: [[rehypeSanitize]],
            }}
            onChange={onEditCommentText}
          />
        </div>
        <div className="CommentBlock-footer">
          <div className="CommentBlock-updatedAt">
            {createdAt !== updatedAt ? `Last edited ${dayjs.tz(updatedAt * 1000).from(now)}` : null}
          </div>
          {renderControls()}
        </div>
      </div>
    </div>
  );
}

CommentBlock.propTypes = {
  loading: PropTypes.bool,
  id: PropTypes.string,
  avatar: PropTypes.string,
  name: PropTypes.string,
  comment: PropTypes.string,
  createdAt: PropTypes.number,
  updatedAt: PropTypes.number,
  isAuthor: PropTypes.bool,
  allowEdit: PropTypes.bool,
  allowDelete: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default CommentBlock;
