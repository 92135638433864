import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { weightLargeUnitLabel } from '../../../../utils/unitConversion';
import Button from '../../../../atoms/Button';
import CollapsibleSection from '../../../../atoms/CollapsibleSection';
import { NumberInputField } from '../../../../atoms/NumberInput';
import Select from '../../../../atoms/Select';
import Swatch from '../../../../atoms/Swatch';
import { TextAreaField } from '../../../../atoms/TextArea';
import { TextInputField } from '../../../../atoms/TextInput';
import { TripStatusColor } from '../../enums';
import { orderStatusList } from '../../lists';

function DeliverySection({
  deliveryIndex = 0,
  isMetric = false,
  barnID = '',
  barnToBinsMap = null,
  deliveryID = '',
  isStatusVisible = false,
  status = '',
  binID = '',
  isRemoveDeliveryVisible = false,
  errors = null,
  onFieldChange = () => {},
  onRemoveDelivery = () => {},
}) {
  const [isExpanded, setIsExpanded] = useState(deliveryID.length === 0);
  const binName = useMemo(() => {
    const selectedBin = barnToBinsMap?.[barnID]?.find((bin) => bin?.value === binID);

    // If the selected bin was found was found, return it's name
    return selectedBin?.name || '';
  }, [barnToBinsMap, barnID, binID]);

  const unitLabel = <span className="RightSidebar-unitLabel">{weightLargeUnitLabel(isMetric).toLowerCase()}s</span>;

  const onToggleSection = () => {
    setIsExpanded(!isExpanded);
  };

  const onStatusChange = (e) => {
    onFieldChange('status', e.target.value);
  };

  const onBinChange = (e) => {
    onFieldChange('binID', e.target.value);
  };

  const onRemoveDeliveryClick = () => {
    onRemoveDelivery(deliveryIndex);
  };

  return (
    <CollapsibleSection
      titleClassName={`RightSidebar-deliveryTitle ${errors ? 'RightSidebar-deliveryTitle--error' : ''}`}
      bodyClassName="RightSidebar-deliverySection"
      title={`Delivery ${binName ? `(Bin ${binName})` : ''}`}
      isExpanded={isExpanded}
      onExpandCollapse={onToggleSection}
    >
      {isStatusVisible ? (
        <Select
          inputClassName="RightSidebar-statusSelect"
          isValid={!errors?.status}
          label="Status"
          left={<Swatch className="RightSidebar-statusSwatch" colour={TripStatusColor[status]} />}
          itemList={orderStatusList}
          value={status}
          description={errors?.status}
          onChange={onStatusChange}
        />
      ) : null}
      <div className="RightSidebar-twoColumns">
        <Select
          isValid={!errors?.binID}
          disabled={barnID === ''}
          label="Bin"
          defaultText="Select a bin"
          itemList={barnToBinsMap?.[barnID] || []}
          value={binID}
          description={errors?.binID}
          onChange={onBinChange}
        />
        <NumberInputField
          name={`deliveries[${deliveryIndex}].orderedMassInLargeUnits`}
          label="Ordered Weight"
          inputClassName="RightSidebar-massInput"
          right={unitLabel}
        />
      </div>
      <div className="RightSidebar-twoColumns">
        <TextInputField label="Delivery Number" name={`deliveries[${deliveryIndex}].externalID`} />
        <TextInputField label="Feed Type" name={`deliveries[${deliveryIndex}].feedProfileName`} />
      </div>
      <NumberInputField
        name={`deliveries[${deliveryIndex}].deliveredMassInLargeUnits`}
        inputClassName="RightSidebar-massInput"
        label="Delivered Weight"
        right={unitLabel}
      />
      <TextAreaField name={`deliveries[${deliveryIndex}].comment`} label="Delivery Notes" />
      {isRemoveDeliveryVisible ? (
        <Button content="Remove Delivery" variant="pastel" color="danger" onClick={onRemoveDeliveryClick} />
      ) : null}
    </CollapsibleSection>
  );
}

DeliverySection.propTypes = {
  deliveryIndex: PropTypes.number,
  isMetric: PropTypes.bool,
  barnID: PropTypes.string,
  barnToBinsMap: PropTypes.object,
  deliveryID: PropTypes.string,
  isStatusVisible: PropTypes.bool,
  status: PropTypes.string,
  feedProfileName: PropTypes.string,
  binID: PropTypes.string,
  orderedMassInLargeUnits: PropTypes.string,
  deliveredMassInLargeUnits: PropTypes.string,
  externalID: PropTypes.string,
  isRemoveDeliveryVisible: PropTypes.bool,
  errors: PropTypes.object,
  onFieldChange: PropTypes.func,
  onRemoveDelivery: PropTypes.func,
};

export default DeliverySection;
