import { useMemo } from 'react';
import { GridColumns } from '@visx/grid';
import { Group } from '@visx/group';
import PropTypes from 'prop-types';

import EventChartBar from './EventChartBar';
import { ArrowIcon } from '../../../atoms/Icons';
import Swatch from '../../../atoms/Swatch';

import { rowHeight, borderWidth } from './EventChartBottom.module.scss';
import './EventChartBottom.scss';

const ROW_HEIGHT_PIXELS = Number(rowHeight);
const SUBROW_HEIGHT_PIXELS = 24;
const TOTAL_BORDER_PIXELS = borderWidth * 2; // Assuming equal borders on two sides

function eventCollides(event1, event2) {
  const e1Start = event1.points[0];
  const e1End = event1.points[event1.points.length - 1];
  const e2Start = event2.points[0];
  const e2End = event2.points[event2.points.length - 1];
  return !(
    (e1Start > e2Start && e1Start > e2End && e1End > e2Start && e1End > e2End) ||
    (e1Start < e2Start && e1Start < e2End && e1End < e2Start && e1End < e2End)
  );
}

export default function EventChartBottomRowExpanded({ id, events, label, colour, xMax, xScale, collapse, xAxisTicks }) {
  const clipPath = `eventChartBottomRowExpandedBoundary-${id}`;

  // Categorize events in this row into non-overlapping subrows.
  const subrows = useMemo(
    () =>
      events.reduce((subrows, event) => {
        let placed = false;
        // Check if this event fits into any existing subrow without collision.
        for (const subrow of subrows) {
          if (!subrow.some((subrowEvent) => eventCollides(event, subrowEvent))) {
            subrow.push(event);
            placed = true;
            break;
          }
        }

        // If this event hasn't fit into any existing subrows, create a new subrow for it.
        if (!placed) {
          subrows.push([event]);
        }
        return subrows;
      }, []),
    [events],
  );

  const eventJSX = useMemo(
    () =>
      subrows.reduce((events, subrow, subrowIndex) => {
        subrow.forEach(({ colour, points, selected, onClick }, eventIndex) => {
          const startX = xScale(points[0]);
          const endX = xScale(points[points.length - 1]);
          const subrowOffset = subrowIndex * SUBROW_HEIGHT_PIXELS;

          events.push(
            <EventChartBar
              className="EventChartBottomRow-event"
              x={startX}
              y={ROW_HEIGHT_PIXELS / 2 + subrowOffset}
              width={endX - startX}
              stroke={colour.stroke}
              fill={colour.fill}
              clipPath={`url(#${clipPath})`}
              selected={selected}
              onClick={onClick}
              key={`${subrowIndex}-${eventIndex}`}
            />,
          );
        });
        return events;
      }, []),
    [subrows],
  );

  const totalHeight = ROW_HEIGHT_PIXELS + (subrows.length - 1) * SUBROW_HEIGHT_PIXELS;

  return (
    <div className="EventChartBottomRow">
      <Swatch className="EventChartBottomRow-swatch" colour={colour} label={label} />
      <div className="EventChartBottomRow-trackWrapper">
        <div className="EventChartBottomRow-arrowWrapper" onClick={collapse}>
          <ArrowIcon className="EventChartBottomRow-upArrow" />
        </div>
        <svg
          style={{
            height: totalHeight + TOTAL_BORDER_PIXELS,
            width: '100%',
          }}
        >
          <Group transform={`translate(${borderWidth}, ${borderWidth})`}>
            <defs>
              <clipPath id={clipPath}>
                <rect x={0} y={0} width={xMax} height={totalHeight} />
              </clipPath>
            </defs>
            <GridColumns scale={xScale} width={xMax} height={totalHeight} stroke="#D9DCE1" tickValues={xAxisTicks} />
            <rect
              // The border around the row.
              x={0}
              y={0}
              width={xMax}
              height={totalHeight}
              rx={5}
              stroke="#EAECF0"
              strokeWidth={1}
              fillOpacity={0}
            />
            {eventJSX}
          </Group>
        </svg>
      </div>
    </div>
  );
}

EventChartBottomRowExpanded.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  events: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  colour: PropTypes.string.isRequired,
  xAxisTicks: PropTypes.array,
  xMax: PropTypes.number.isRequired,
  xScale: PropTypes.func.isRequired,
  collapse: PropTypes.func.isRequired,
};
