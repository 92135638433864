import PropTypes from 'prop-types';
import LoadingSkeleton from '../LoadingSkeleton';
import './Checkbox.scss';

function Checkbox({
  loading = false,
  className = '',
  labelClassName = '',
  inputClassName = '',
  style = {},
  name = '',
  label = '',
  checked = false,
  onChange = () => {},
}) {
  if (loading) {
    return (
      <div className={`Checkbox ${className}`}>
        <LoadingSkeleton className="Checkbox-input--loader" />
        <LoadingSkeleton className="Checkbox-labelText Checkbox-labelText--loader" />
      </div>
    );
  }

  const renderInput = () => {
    return (
      <input
        className={`Checkbox-input ${inputClassName}`}
        style={style}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
    );
  };

  return (
    <div className={`Checkbox ${className}`}>
      {label ? (
        <label className="Checkbox-label">
          {renderInput()}
          <div className={`Checkbox-labelText ${labelClassName}`}>{label}</div>
        </label>
      ) : (
        renderInput()
      )}
    </div>
  );
}

Checkbox.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
