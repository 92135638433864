import PropTypes from 'prop-types';

function SearchSuggestion({ id = '', label = '', onClick = () => {} }) {
  const onSuggestionClick = (event) => {
    onClick(event, id, label);
  };

  return (
    <div className="SearchBar-suggestion" onClick={onSuggestionClick}>
      {label}
    </div>
  );
}

SearchSuggestion.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default SearchSuggestion;
