import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { SECONDS_IN_HOUR } from '../../utils/dates';
import { timeParse, timeFormat } from 'd3-time-format';
import debounce from 'lodash/debounce';
import { gql, useQuery } from '@apollo/client';
import { ParentSize } from '@visx/responsive';
import { scaleOrdinal } from '@visx/scale';
import { format } from 'd3-format';
import { AlertDashboardDataTypes } from '../../utils/enums';
import useFormattedInterval from '../../utils/hooks/useFormattedInterval';
import useSearchParamsState, { useMultipleSearchParamsState } from '../../utils/hooks/useSearchParamsState';
import AlertDashboardTile from '../../atoms/AlertDashboardTile';
import Page from '../../atoms/Page';
import Heading from '../../atoms/Heading';
import StatTab from '../../atoms/StatTab';
import ChartLegend from '../../molecules/ChartLegend';
import FilterPopoverButton from '../../organisms/FilterPopoverButton';
import AlertTable from '../../organisms/AlertTable';
import BarnRankingTable from '../../organisms/BarnRankingTable';
import BarStackChart from '../../organisms/BarStackChart';
import DatePickerPopover from '../../organisms/DatePickerPopover';
import DonutChart from '../../organisms/DonutChart';
import {
  statusOptions,
  typeOptions,
  durationOptions,
  noFeedTimeOptions,
  rootCauseOptions,
  dateOptions,
} from './filterValues';
import './AlertDashboardPage.scss';
import { censorTypes, useCensor } from '../../utils/hooks/useCensor';
import useUser from '../../utils/hooks/useUser';

const AlertsTitle = 'Live Alert Hub';
const NoFeedTimeTitle = 'Management Report';

const ALERT_DASHBOARD_LOCATION_NAME_FILTER = gql`
  query AlertDashboardLocationNameFilter {
    farm(where: { deleted_at: { _is_null: true }, organization: { deleted_at: { _is_null: true } } }) {
      id
      text: name
    }
  }
`;

const ALERT_DASHBOARD_ROOT_CAUSE_FILTER = gql`
  query AlertDashboardRootCauseFilter {
    fault_root_cause(where: { deleted_at: { _is_null: true } }) {
      id
      text: name
    }
  }
`;

const ALERT_DASHBOARD_CHART_DATA_GQL = gql`
  query AlertDashboardChartQuery(
    $from: bigint!
    $to: bigint!
    $timezone: String!
    $statuses: [String!]!
    $types: [String!]!
    $durations: [String!]!
    $locations: [String!]!
    $nofeed_times: [String!]!
    $root_causes: [String!]!
  ) {
    alert_dashboard_chart_data(
      from: $from
      to: $to
      timezone: $timezone
      statuses: $statuses
      types: $types
      durations: $durations
      locations: $locations
      nofeed_times: $nofeed_times
      root_causes: $root_causes
    )
  }
`;

const ALERT_DASHBOARD_TABLE_DATA_GQL = gql`
  query AlertDashboardTableQuery(
    $from: bigint!
    $to: bigint!
    $timezone: String!
    $statuses: [String!]!
    $types: [String!]!
    $durations: [String!]!
    $locations: [String!]!
    $nofeed_times: [String!]!
    $root_causes: [String!]!
    $page: Int!
    $sort_column: String!
    $sort_level: Int!
  ) {
    alert_dashboard_table_data(
      from: $from
      to: $to
      timezone: $timezone
      statuses: $statuses
      types: $types
      durations: $durations
      locations: $locations
      nofeed_times: $nofeed_times
      root_causes: $root_causes
      page: $page
      sort_column: $sort_column
      sort_level: $sort_level
    )
  }
`;

function AlertDashboardPage({ titleSegments = [], viewportRef = null }) {
  const { formatInterval } = useFormattedInterval();

  const [, setSearchParams] = useSearchParams();
  const setMultipleSearchParams = useMultipleSearchParamsState();
  const { censor } = useCensor();
  const [dataType, setDataType] = useSearchParamsState('dataType', AlertDashboardDataTypes.Alerts);
  const [startTime] = useSearchParamsState('start');
  const [endTime] = useSearchParamsState('end');
  const [presetTimeInDays, setPresetTimeInDays] = useSearchParamsState('p');
  const [sortColumn] = useSearchParamsState('sortCol', 'date');
  const [sortLevel] = useSearchParamsState('sortLevel', 0);
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [hideCharts] = useSearchParamsState('hideCharts', false);
  const [selectedStatuses, setSelectedStatuses] = useSearchParamsState('s', []);
  const [selectedTypes, setSelectedTypes] = useSearchParamsState('t', []);
  const [selectedDurations, setSelectedDurations] = useSearchParamsState('d', []);
  const [selectedLocations, setSelectedLocations] = useSearchParamsState('l', []);
  const [selectedNoFeedTimes, setSelectedNoFeedTimes] = useSearchParamsState('n', []);
  const [selectedRootCauses, setSelectedRootCauses] = useSearchParamsState('r', []);

  const statusPopoverValues = useMemo(() => statusOptions, []);
  const typePopoverValues = useMemo(() => typeOptions, []);
  const durationPopoverValues = useMemo(() => durationOptions, []);
  const noFeedTimePopoverValues = useMemo(() => noFeedTimeOptions, []);
  const datePopoverValues = useMemo(() => dateOptions, []);
  const { user } = useUser();
  const timezone = user?.timezone;

  const dateRange = useMemo(() => {
    // If "from" and "to" query string params exist, use them to display a fixed interval in time
    if (startTime && endTime) {
      return {
        from: dayjs.tz(1000 * startTime),
        to: dayjs.tz(1000 * endTime),
      };
    } else if (presetTimeInDays) {
      return {
        from: dayjs
          .tz()
          .subtract(presetTimeInDays - 1, 'days')
          .startOf('day'),
        to: dayjs.tz().endOf('day'),
      };
    } else {
      return {
        from: dayjs.tz().subtract(30, 'days').startOf('day'),
        to: dayjs.tz().endOf('day'),
      };
    }
  }, [startTime, endTime, presetTimeInDays]);

  const { data: locationData } = useQuery(ALERT_DASHBOARD_LOCATION_NAME_FILTER);
  const { data: rootCauseData } = useQuery(ALERT_DASHBOARD_ROOT_CAUSE_FILTER);
  const { data: chartData } = useQuery(ALERT_DASHBOARD_CHART_DATA_GQL, {
    variables: {
      from: dateRange.from.unix(),
      to: dateRange.to.unix(),
      timezone,
      statuses: selectedStatuses,
      types: selectedTypes,
      durations: selectedDurations,
      locations: selectedLocations,
      nofeed_times: selectedNoFeedTimes,
      root_causes: selectedRootCauses,
    },
    skip: hideCharts,
  });

  const {
    data: tableData,
    loading: tableLoading,
    fetchMore,
  } = useQuery(ALERT_DASHBOARD_TABLE_DATA_GQL, {
    variables: {
      from: dateRange.from.unix(),
      to: dateRange.to.unix(),
      timezone,
      statuses: selectedStatuses,
      types: selectedTypes,
      durations: selectedDurations,
      locations: selectedLocations,
      nofeed_times: selectedNoFeedTimes,
      root_causes: selectedRootCauses,
      page: currentPage,
      sort_column: sortColumn,
      sort_level: sortLevel,
    },
    skip: !viewportRef,
  });

  const pageTitleSegments = useMemo(() => ['Alerts', ...titleSegments], []);
  const { charts, stats, maps, lists, formats } = chartData?.alert_dashboard_chart_data || {};
  const colorScale = scaleOrdinal({
    domain: lists?.barn_ids?.[dataType],
    range: ['#7dabf8', '#e4db84', '#d17b7b', '#97c58b'],
  });

  const loadTableRows = debounce((page) => {
    setCurrentPage(page);
  }, 250);

  useEffect(() => {
    fetchMore({
      variables: {
        from: dateRange.from.unix(),
        to: dateRange.to.unix(),
        timezone,
        statuses: selectedStatuses,
        types: selectedTypes,
        durations: selectedDurations,
        locations: selectedLocations,
        nofeed_times: selectedNoFeedTimes,
        root_causes: selectedRootCauses,
        page: currentPage,
        sort_column: sortColumn,
        sort_level: sortLevel,
      },
    });
  }, [
    dateRange.from,
    dateRange.to,
    timezone,
    selectedStatuses,
    selectedTypes,
    selectedDurations,
    selectedLocations,
    selectedNoFeedTimes,
    selectedRootCauses,
    currentPage,
    sortColumn,
    sortLevel,
  ]);

  useEffect(() => {
    const { max_page: maxPage } = tableData?.alert_dashboard_table_data || {};
    if (maxPage === undefined) {
      return;
    }

    setMaxPage(maxPage);
  }, [tableData]);

  useEffect(() => {
    if (!viewportRef?.current) {
      return;
    }

    const scrollHandler = () => {
      if (!viewportRef?.current) {
        return;
      }

      // See https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#determine_if_an_element_has_been_totally_scrolled for more info.
      const { scrollHeight, scrollTop, clientHeight } = viewportRef.current;
      const loadMore = Math.abs(scrollHeight - scrollTop - clientHeight) < 1;

      if (loadMore && currentPage < maxPage) {
        loadTableRows(currentPage + 1);
      }
    };

    // Add scroll handler to viewport
    viewportRef.current.addEventListener('scroll', scrollHandler);

    // Remove scroll handler when component unmounts
    return () => viewportRef?.current?.removeEventListener('scroll', scrollHandler);
  }, [viewportRef, currentPage, maxPage]);

  const onUpdateSelectedStatuses = (updatedSelectedStatuses) => {
    setSelectedStatuses(updatedSelectedStatuses.map((x) => x.id));
    setCurrentPage(0);
  };

  const onUpdateSelectedTypes = (updatedSelectedTypes) => {
    setSelectedTypes(updatedSelectedTypes.map((x) => x.id));
    setCurrentPage(0);
  };

  const onUpdateSelectedDurations = (updatedSelectedDurations) => {
    setSelectedDurations(updatedSelectedDurations.map((x) => x.id));
    setCurrentPage(0);
  };

  const onUpdateSelectedLocations = (updatedSelectedLocations) => {
    setSelectedLocations(updatedSelectedLocations.map((x) => x.id));
    setCurrentPage(0);
  };

  const onUpdateSelectedNoFeedTimes = (updatedSelectedNoFeedTimes) => {
    setSelectedNoFeedTimes(updatedSelectedNoFeedTimes.map((x) => x.id));
    setCurrentPage(0);
  };

  const onUpdateSelectedRootCauses = (updatedSelectedRootCauses) => {
    setSelectedRootCauses(updatedSelectedRootCauses.map((x) => x.id));
    setCurrentPage(0);
  };

  const onChartClick = (key) => {
    onUpdateSelectedLocations((locationData?.farm || []).filter((location) => location.id === key));
  };

  // Handler for selecting a radio button in the date popover
  const onSelectDateOption = (value) => {
    setPresetTimeInDays(value);
    setCurrentPage(0);
  };

  const onSelectDateRange = (e) => {
    if (e.dateRange) {
      setMultipleSearchParams({ start: dayjs.tz(e.dateRange.from).unix(), end: dayjs.tz(e.dateRange.to).unix(), p: 0 });
    }
  };

  const onClearFilters = () => {
    setSearchParams({});
  };

  const onClickStatTab = (newDataType) => {
    setDataType(newDataType);
  };

  const onColumnSort = (newSortColumn) => {
    const newSortLevel = sortColumn === newSortColumn ? (parseInt(sortLevel) + 1) % 2 : 0;

    setMultipleSearchParams({
      sortCol: newSortColumn,
      sortLevel: newSortLevel,
    });
    setCurrentPage(0);
  };

  const onManualLoadRows = () => {
    loadTableRows(currentPage + 1);
  };

  const formatAlertsValue = (alertTotal) => {
    return `${alertTotal}`;
  };

  const formatNoFeedValue = (nofeedSeconds) => {
    const intervalStrings = formatInterval(0, nofeedSeconds, true);
    if (intervalStrings.length === 0) {
      return '0';
    }

    return intervalStrings.join(' ');
  };

  const formatNoFeedValueDays = (nofeedSeconds) => {
    const nofeedHours = nofeedSeconds / SECONDS_IN_HOUR;
    const totalDays = nofeedHours / 24;

    if (totalDays.toFixed(1) === '1.0') {
      return '1 day';
    } else if (totalDays > 1) {
      return `${totalDays.toFixed()} days`;
    } else {
      return `${(nofeedHours / 24).toFixed(1)} days`;
    }
  };

  const xAxisValueAccessor = (data) => {
    return data.date;
  };

  const xAxisValues = () => {
    return (charts?.bar_stack?.[dataType] || []).map(xAxisValueAccessor);
  };

  const xAxisValueFormatter = (tickValue) => {
    const parseTime = timeParse('%Y-%m-%d');

    if (formats?.granularity === 'decade') {
      const formatTime = timeFormat('%Y');
      return `${formatTime(parseTime(tickValue))}s`;
    } else if (formats?.granularity === 'year') {
      const formatTime = timeFormat('%Y');
      return formatTime(parseTime(tickValue));
    } else if (formats?.granularity === 'month') {
      const formatTime = timeFormat('%b %y');
      return formatTime(parseTime(tickValue));
    } else {
      const formatTime = timeFormat('%b %e');
      return formatTime(parseTime(tickValue));
    }
  };

  const yAxisValueFormatter = (tickValue) => {
    const formatter = format('.2~s');

    if (dataType === AlertDashboardDataTypes.NoFeedTime) {
      return formatter(tickValue / SECONDS_IN_HOUR);
    } else {
      return tickValue > 0 && tickValue < 1
        ? Number.parseFloat(tickValue.toFixed(2)) // The Number.parseFloat call removes trailing zeroes added by .toFixed()
        : formatter(tickValue);
    }
  };

  const tooltipTitleFormatter = (date) => {
    const parseTime = timeParse('%Y-%m-%d');

    if (formats?.granularity === 'decade') {
      const formatTime = timeFormat('%Y');
      return `${formatTime(parseTime(date))}s`;
    } else if (formats?.granularity === 'year') {
      const formatTime = timeFormat('%Y');
      return formatTime(parseTime(date));
    } else if (formats?.granularity === 'month') {
      const formatTime = timeFormat('%b %Y');
      return formatTime(parseTime(date));
    } else if (formats?.granularity === 'week') {
      const formatTime = timeFormat('%b %e');
      return `Week of ${formatTime(parseTime(date))}`;
    } else {
      const formatTime = timeFormat('%b %e, %Y');
      return formatTime(parseTime(date));
    }
  };
  let barnNameMap = {};
  if (maps?.barn_names)
    Object.keys(maps?.barn_names).forEach(function (key) {
      barnNameMap[key] = censor(maps?.barn_names[key], censorTypes.barn);
    });

  return (
    <Page className="AlertDashboardPage" titleSegments={pageTitleSegments}>
      <div className="AlertDashboardPage-headerRow">
        <Heading text={hideCharts ? AlertsTitle : NoFeedTimeTitle} />
        <div className="AlertDashboardPage-headerControlBlock">
          <DatePickerPopover
            options={datePopoverValues}
            defaultValue={parseInt(presetTimeInDays)}
            dateRange={{ to: dateRange.to, from: dateRange.from }}
            onSelectDateOption={onSelectDateOption}
            onSelectDateRange={onSelectDateRange}
          />
        </div>
      </div>
      <div className="AlertDashboardPage-filterRow">
        <div className="AlertDashboardPage-filterButtons">
          <div className="AlertDashboardPage-filterLabel">Filters:</div>
          <FilterPopoverButton
            text="Status"
            placeholder="Search statuses"
            values={statusPopoverValues}
            selectedValues={statusPopoverValues.filter((v) => selectedStatuses.includes(v.id))}
            onUpdateSelectedValues={onUpdateSelectedStatuses}
            enableSearch={false}
          />
          <FilterPopoverButton
            text="Type"
            placeholder="Search types"
            values={typePopoverValues}
            selectedValues={typePopoverValues.filter((v) => selectedTypes.includes(v.id))}
            onUpdateSelectedValues={onUpdateSelectedTypes}
            enableSearch={false}
          />
          <FilterPopoverButton
            text="Duration"
            placeholder="Search durations"
            values={durationPopoverValues}
            selectedValues={durationPopoverValues.filter((v) => selectedDurations.includes(v.id))}
            onUpdateSelectedValues={onUpdateSelectedDurations}
            enableSearch={false}
          />
          <FilterPopoverButton
            text="Location"
            placeholder="Search locations"
            values={
              locationData?.farm.map((l) => {
                return { ...l, text: censor(l.text, censorTypes.barn) };
              }) || []
            }
            selectedValues={
              locationData?.farm
                .filter((v) => selectedLocations.includes(v.id))
                .map((barn) => {
                  return { ...barn, text: censor(barn.text, censorTypes.barn) };
                }) || []
            }
            onUpdateSelectedValues={onUpdateSelectedLocations}
          />
          <FilterPopoverButton
            text="NoFeed Time"
            placeholder="Search NoFeed times"
            values={noFeedTimePopoverValues}
            selectedValues={noFeedTimePopoverValues.filter((v) => selectedNoFeedTimes.includes(v.id))}
            onUpdateSelectedValues={onUpdateSelectedNoFeedTimes}
            enableSearch={false}
          />
          <FilterPopoverButton
            text="Root Cause"
            placeholder="Search root causes"
            values={[...(rootCauseData?.fault_root_cause || []), ...rootCauseOptions]} // Combines the root causes found in the database with an 'unidentified' option
            selectedValues={[...(rootCauseData?.fault_root_cause || []), ...rootCauseOptions].filter((v) =>
              selectedRootCauses.includes(v.id),
            )}
            maxVisibleValues={20}
            onUpdateSelectedValues={onUpdateSelectedRootCauses}
          />
        </div>
        <div className="AlertDashboardPage-filterControls">
          <button className="AlertDashboardPage-clearFilters" type="button" onClick={onClearFilters}>
            Clear Filters
          </button>
        </div>
      </div>

      <div className="AlertDashboardPage-content">
        {!hideCharts && (
          <>
            <AlertDashboardTile className="AlertDashboardPage-mainSection">
              <div className="AlertDashboardPage-headingRow">
                <h4 className="AlertDashboardPage-heading">Alerts by Location</h4>
              </div>
              <div className="AlertDashboardPage-statRow">
                <StatTab
                  title="Total Alerts"
                  tabKey={AlertDashboardDataTypes.Alerts}
                  formattedValue={formatAlertsValue(stats?.totals?.current?.alerts || 0)}
                  value={stats?.totals?.current?.alerts}
                  previousValue={stats?.totals?.previous?.alerts}
                  isSelected={dataType === AlertDashboardDataTypes.Alerts}
                  onClick={onClickStatTab}
                />
                <StatTab
                  title="NoFeed&trade; Time"
                  tabKey={AlertDashboardDataTypes.NoFeedTime}
                  formattedValue={formatNoFeedValueDays(stats?.totals?.current?.nofeed_time || 0)}
                  value={stats?.totals?.current?.nofeed_time}
                  previousValue={stats?.totals?.previous?.nofeed_time}
                  isSelected={dataType === AlertDashboardDataTypes.NoFeedTime}
                  onClick={onClickStatTab}
                />
              </div>
              <div className="AlertDashboardPage-barStackChartContainer">
                <ParentSize debounceTime={10}>
                  {({ width, height }) => (
                    <BarStackChart
                      width={width}
                      height={height}
                      data={charts?.bar_stack?.[dataType]}
                      keys={lists?.barn_ids?.[dataType]}
                      keyNameMap={barnNameMap}
                      xAxisValueAccessor={xAxisValueAccessor}
                      xAxisValues={xAxisValues()}
                      xAxisValueFormatter={xAxisValueFormatter}
                      yAxisValues={lists?.date_totals?.[dataType]}
                      yAxisValueFormatter={yAxisValueFormatter}
                      tooltipTitleAccessor={xAxisValueAccessor}
                      tooltipTitleFormatter={tooltipTitleFormatter}
                      previousAverage={stats?.averages?.previous?.[dataType] || 0}
                      formattedPreviousAverage={
                        dataType === AlertDashboardDataTypes.Alerts
                          ? (stats?.averages?.previous?.[dataType] || 0).toFixed(2)
                          : formatInterval(0, stats?.averages?.previous?.[dataType] || 0, true)[0]
                      }
                      colorScale={colorScale}
                      valueFormatter={
                        dataType === AlertDashboardDataTypes.Alerts ? formatAlertsValue : formatNoFeedValue
                      }
                      onChartClick={onChartClick}
                    />
                  )}
                </ParentSize>
              </div>
            </AlertDashboardTile>
            <AlertDashboardTile>
              <BarnRankingTable
                title="Rankings"
                countColumnTitle={dataType === AlertDashboardDataTypes.Alerts ? AlertsTitle : NoFeedTimeTitle}
                barnComparisonMap={maps?.barn_ranking?.[dataType]}
                barnNameMap={barnNameMap}
                valueFormatter={dataType === AlertDashboardDataTypes.Alerts ? formatAlertsValue : formatNoFeedValue}
              />
            </AlertDashboardTile>
            <AlertDashboardTile className="AlertDashboardPage-detailSection">
              <div className="AlertDashboardPage-donutSectionContent">
                <div className="AlertDashboardPage-donutSectionLeftContent">
                  <h6 className="AlertDashboardPage-tileHeading">Totals By Location</h6>
                  <ChartLegend
                    keys={lists?.barn_ids?.[dataType]}
                    keyNameMap={barnNameMap}
                    valueMap={maps?.legend?.[dataType]}
                    colorScale={colorScale}
                    valueFormatter={dataType === AlertDashboardDataTypes.Alerts ? formatAlertsValue : formatNoFeedValue}
                  />
                </div>
                <div className="AlertDashboardPage-donutChartContainer">
                  <ParentSize debounceTime={10}>
                    {({ width, height }) => (
                      <DonutChart
                        width={width}
                        height={height}
                        thickness={50}
                        data={charts?.donut?.[dataType]}
                        keyNameMap={barnNameMap}
                        centerValue={stats?.totals?.current?.[dataType]}
                        centerLabel={dataType === AlertDashboardDataTypes.Alerts ? AlertsTitle : NoFeedTimeTitle}
                        colorScale={colorScale}
                        valueFormatter={
                          dataType === AlertDashboardDataTypes.Alerts ? formatAlertsValue : formatNoFeedValue
                        }
                        onChartClick={onChartClick}
                      />
                    )}
                  </ParentSize>
                </div>
              </div>
            </AlertDashboardTile>
          </>
        )}
        <div className="AlertDashboardPage-alertTable">
          <AlertTable
            data={tableData?.alert_dashboard_table_data?.rows}
            loading={tableLoading}
            allContentLoaded={currentPage === maxPage}
            sortColumn={sortColumn}
            sortLevel={parseInt(sortLevel)}
            onManualLoadRows={onManualLoadRows}
            onColumnSort={onColumnSort}
          />
        </div>
      </div>
    </Page>
  );
}

AlertDashboardPage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
  viewportRef: PropTypes.object,
};

export default AlertDashboardPage;
