import { gql } from '@apollo/client';

export const SET_FAULT_ENDED_AT_BY_PK_GQL = gql`
  mutation FaultCodeDetails_EndFaultByPK($endedAt: bigint!, $faultId: uuid!) {
    update_fault_by_pk(pk_columns: { id: $faultId }, _set: { ended_at: $endedAt }) {
      id
      ended_at
    }
  }
`;

export const FAULT_TIME = gql`
  query FaultTime($fcId: uuid!) {
    fault: fault_by_pk(id: $fcId) {
      started_at
      ended_at
    }
  }
`;

export const FAULT_CODE_GQL = gql`
  query FaultDetailQuery($fcId: uuid!, $deviceAssignmentWhere: device_assignment_bool_exp!) {
    fault: fault_by_pk(id: $fcId) {
      id
      created_at
      deleted_at
      updated_at
      code
      details
      id_from_device
      started_at
      recently_at
      ended_at
      rootCauseComment: fault_comments(order_by: { created_at: desc_nulls_last }, limit: 1) {
        fault_root_cause {
          name
          id
        }
      }
      fault_comments(order_by: { created_at: desc_nulls_last }) {
        created_at
        updated_at
        comment
        user_id
        id
        fault_root_cause {
          name
        }
      }
      fault_messages(order_by: { created_at: desc_nulls_last }) {
        message {
          id
          sent_at
          sent_to
          transported_as
          user_id
        }
      }

      device {
        serial
        id
        device_assignments(where: $deviceAssignmentWhere, order_by: { updated_at: desc }, limit: 1) {
          feed_line {
            id
            name
            farm {
              id
              name
            }
          }
        }
      }
    }
    fault_root_cause(order_by: { name: asc_nulls_last }) {
      name
      description
      id
    }
  }
`;

// TODO: OPTIMIZE - Do we need to fetch _all_ users here?
export const GET_USER_DATA_GQL = gql`
  query GetUserData {
    user(where: { deleted_at: { _is_null: true } }) {
      id
      name
      avatar
    }
  }
`;

export const SAVE_FAULT_COMMENT_GQL = gql`
  mutation SaveFaultCommentMutation($objects: [fault_comment_insert_input!]!) {
    insert_fault_comment(objects: $objects) {
      returning {
        id
      }
    }
  }
`;
