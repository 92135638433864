import React from 'react';
import PropTypes from 'prop-types';
import './Icons.scss';

function HomeIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/home.svg"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

HomeIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function MapIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/map.svg"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

MapIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function StoreIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/store.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

StoreIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function AlertIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/alert.svg"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

AlertIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function MoneyIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/money.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

MoneyIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function EKGIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/ekg.svg"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

EKGIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function ReportIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/report.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

ReportIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function UserIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/user.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

UserIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function GearIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/gear.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

GearIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function BellIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/bell.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

BellIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function EllipsisIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/ellipsis.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

EllipsisIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function CalendarIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/calendar.svg"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

CalendarIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function HelpIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/help.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

HelpIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function PhoneIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/phone.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

PhoneIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function EmailIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/email.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

EmailIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function ChatIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/chat.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

ChatIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function InfoIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/info.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

InfoIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function FilterIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/filter.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

FilterIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function ExportIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/export.svg"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

ExportIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function ArrowIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/arrow.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

ArrowIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function GreenArrowIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/arrow-green.svg"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

GreenArrowIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};
function MenuIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/menu.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

MenuIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function ComingSoonIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`comingSoonIcon ${className}`}
      src="/icons/coming-soon.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

ComingSoonIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function Refresh({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/refresh.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

Refresh.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function SearchIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/search.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

SearchIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function EyeIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/eye.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

EyeIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function KeyIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/key.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

KeyIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function RedXIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/red_x.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

RedXIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function CopyIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/copy.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

CopyIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function PackageIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/package.png"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

PackageIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function SortArrowIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/sort-arrow.svg"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

SortArrowIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function CalendarIconSVG({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <span className={`CalendarIconSVG ${className}`} tabIndex={tabIndex} onKeyDown={onKeyDown} onClick={onClick}>
      <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_197_3751)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 3.6167C1 2.78827 1.67157 2.1167 2.5 2.1167H9.75C10.5784 2.1167 11.25 2.78827 11.25 3.6167V9.50003C11.25 10.3285 10.5784 11 9.75 11H2.5C1.67157 11 1 10.3285 1 9.50003V3.6167Z"
            stroke="#A3ACB9"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M1 4.8501H11.25" stroke="#A3ACB9" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M3.7334 3.14167V0.75" stroke="#A3ACB9" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M8.5166 3.14167V0.75" stroke="#A3ACB9" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_197_3751">
            <rect width="12" height="12" fill="white" transform="translate(0 0.25)" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}

CalendarIconSVG.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function CircledCheckmarkIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/circled-checkmark.svg"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

CircledCheckmarkIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function InterventionIconSVG({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`feedFloIcon ${className}`}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <path
        className={`feedFloIconPath ${className}Path`}
        d="M6.5465 0.900024H11.4533V6.54662H17.0999V11.4534H11.4533V17.1H6.5465L6.5465 11.4534H0.899902V6.54662H6.5465V0.900024Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

InterventionIconSVG.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function ObservationIconSVG({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`feedFloIcon ${className}`}
      width="44"
      height="30"
      viewBox="0 0 44 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <path
        className={`feedFloIconPath ${className}Path ${className}Fill`}
        d="M22.0059 23.5C24.3686 23.5 26.375 22.673 28.025 21.0191C29.675 19.3652 30.5 17.3569 30.5 14.9941C30.5 12.6314 29.673 10.625 28.0191 8.975C26.3652 7.325 24.3569 6.5 21.9941 6.5C19.6314 6.5 17.625 7.32697 15.975 8.9809C14.325 10.6348 13.5 12.6431 13.5 15.0059C13.5 17.3686 14.327 19.375 15.9809 21.025C17.6348 22.675 19.6431 23.5 22.0059 23.5ZM21.9883 20.6C20.4294 20.6 19.1083 20.0544 18.025 18.9633C16.9417 17.8721 16.4 16.5471 16.4 14.9883C16.4 13.4294 16.9456 12.1083 18.0367 11.025C19.1279 9.94167 20.4529 9.4 22.0117 9.4C23.5706 9.4 24.8917 9.94558 25.975 11.0367C27.0583 12.1279 27.6 13.4529 27.6 15.0117C27.6 16.5706 27.0544 17.8917 25.9633 18.975C24.8721 20.0583 23.5471 20.6 21.9883 20.6ZM22 30C17.1333 30 12.7333 28.6167 8.8 25.85C4.86667 23.0833 1.93333 19.4667 0 15C1.93333 10.5333 4.86667 6.91667 8.8 4.15C12.7333 1.38333 17.1333 0 22 0C26.8667 0 31.2667 1.38333 35.2 4.15C39.1333 6.91667 42.0667 10.5333 44 15C42.0667 19.4667 39.1333 23.0833 35.2 25.85C31.2667 28.6167 26.8667 30 22 30ZM21.9916 27C26.0305 27 29.7417 25.9083 33.125 23.725C36.5083 21.5417 39.0833 18.6333 40.85 15C39.0833 11.3667 36.5112 8.45833 33.1334 6.275C29.7557 4.09167 26.0474 3 22.0085 3C17.9695 3 14.2583 4.09167 10.875 6.275C7.49167 8.45833 4.9 11.3667 3.1 15C4.9 18.6333 7.48885 21.5417 10.8665 23.725C14.2443 25.9083 17.9526 27 21.9916 27Z"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

ObservationIconSVG.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function PigIconSVG({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`feedFloIcon ${className}`}
      width="40"
      height="38"
      viewBox="0 0 40 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <path
        className={`feedFloIconPath ${className}Path  ${className}Fill`}
        d="M28 18C28.5667 18 29.0417 17.8083 29.425 17.425C29.8083 17.0417 30 16.5667 30 16C30 15.4333 29.8083 14.9583 29.425 14.575C29.0417 14.1917 28.5667 14 28 14C27.4333 14 26.9583 14.1917 26.575 14.575C26.1917 14.9583 26 15.4333 26 16C26 16.5667 26.1917 17.0417 26.575 17.425C26.9583 17.8083 27.4333 18 28 18ZM5 38C3.86667 34.2 2.75 30.4083 1.65 26.625C0.55 22.8417 0 18.9667 0 15C0 11.9333 1.06667 9.33333 3.2 7.2C5.33333 5.06667 7.93333 4 11 4H21C21.9667 2.73333 23.1417 1.75 24.525 1.05C25.9083 0.35 27.4 0 29 0C29.8333 0 30.5417 0.291667 31.125 0.875C31.7083 1.45833 32 2.16667 32 3C32 3.2 31.975 3.4 31.925 3.6C31.875 3.8 31.8167 3.98333 31.75 4.15C31.6167 4.51667 31.4917 4.89167 31.375 5.275C31.2583 5.65833 31.1667 6.05 31.1 6.45L35.65 11H40V24.95L34.35 26.8L31 38H20V34H16V38H5ZM7.25 35H13V31H23V35H28.75L31.9 24.5L37 22.75V14H34.4L28 7.6C28.0333 6.76667 28.1417 5.95833 28.325 5.175C28.5083 4.39167 28.7 3.6 28.9 2.8C27.6333 3.13333 26.4333 3.625 25.3 4.275C24.1667 4.925 23.3 5.83333 22.7 7H11C8.79047 7 6.90475 7.78095 5.34285 9.34285C3.78095 10.9047 3 12.7905 3 15C3 18.4386 3.48333 21.7983 4.45 25.079C5.41667 28.3597 6.35 31.6667 7.25 35Z"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

PigIconSVG.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function StockChangeIconSVG({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`feedFloIcon ${className}`}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <g clipPath="url(#clip0_216_20127)">
        <path
          className={`feedFloIconPath ${className}Path`}
          d="M5.83333 17.5V11.0845H14.1667V17.5M2.5 7.26928L2.5 15.8333C2.5 16.2753 2.67559 16.6992 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6992 17.5 16.2753 17.5 15.8333V7.26928L14.5833 3.51928L10 1.66663L5.41667 3.51928L2.5 7.26928Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          className={`feedFloIconPath ${className}Line`}
          x1="2.25522"
          y1="7.58816"
          x2="1.18614"
          y2="9.02723"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          className={`feedFloIconPath ${className}Line`}
          x1="1"
          y1="-1"
          x2="2.79272"
          y2="-1"
          transform="matrix(0.596342 0.802731 0.802731 -0.596342 17.9509 6.18909)"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <rect x="7.99731" y="5.62744" width="4.00508" height="2.56995" rx="0.25" stroke="#186977" strokeWidth="2" />
      </g>
      <defs>
        <clipPath id="clip0_216_20127">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

StockChangeIconSVG.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function FeedChangeIconSVG({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`feedFloIcon ${className}`}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <g clipPath="url(#clip0_215_20123)">
        <path
          className={`feedFloIcon ${className}Path`}
          d="M0.833252 3.33331V8.33332M0.833252 8.33332H3.949M0.833252 8.33332L4.69992 4.69998C5.59554 3.80391 6.70356 3.14932 7.92059 2.79729C9.13762 2.44527 10.424 2.40727 11.6597 2.68686C12.8954 2.96645 14.0401 3.55451 14.987 4.39616C15.934 5.23782 16.6523 6.30564 17.0749 7.49998M19.1666 18.2237V13.2237M19.1666 13.2237H15.8481M19.1666 13.2237L15.2999 16.8571C14.4043 17.7531 13.2963 18.4077 12.0792 18.7598C10.8622 19.1118 9.57584 19.1498 8.34016 18.8702C7.10447 18.5906 5.95975 18.0025 5.01281 17.1609C4.06586 16.3192 3.34756 15.2514 2.92492 14.0571"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={`feedFloIconPath ${className}Path`}
          d="M11.7933 7.44104L11.7933 7.44937M8.45996 7.44104L8.45996 7.44937M10.1266 10.7744L10.1266 10.7827M6.79329 10.7744L6.79329 10.7827M11.7933 14.1077L11.7933 14.116M8.45996 14.1077L8.45996 14.116M13.46 10.7744L13.46 10.7827"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_215_20123">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

FeedChangeIconSVG.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function BarChartIconSVG({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`feedFloIcon ${className}`}
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <path
        className={`feedFloIconPath ${className}Path`}
        d="M13 17V7M7 17V1M1 17V11"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

BarChartIconSVG.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function PinIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`PinIcon ${className}`}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <g clipPath="url(#clip0_76_779)">
        <path
          d="M16.6531 6.83531L12.438 2.63037L5.28562 9.76541L9.50078 13.9704L16.6531 6.83531Z"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path d="M11.1721 1.36646L17.8766 8.05466" strokeWidth="1.5" strokeMiterlimit="10" />
        <path d="M3.45471 7.93115L11.398 15.8625" strokeWidth="1.5" strokeMiterlimit="10" />
      </g>
      <path
        d="M1.85875 18.276L0.911377 18.3342L0.969677 17.3891L6.82879 11.5442L7.71785 12.4311L1.85875 18.276Z"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <defs>
        <clipPath id="clip0_76_779">
          <rect width="18" height="18" fill="white" transform="translate(0.517822 0.726807)" />
        </clipPath>
      </defs>
    </svg>
  );
}

PinIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function WarningIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`WarningIcon ${className}`}
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.056 18.9628L18.8832 4.9913C18.0626 3.56888 16.5333 2.68602 14.8795 2.68409C13.2254 2.68215 11.6955 3.56136 10.8648 4.99136L2.68891 18.968C1.84112 20.3935 1.82194 22.1797 2.64312 23.6365C3.46498 25.0945 5.00385 26.0018 6.67771 26.0154L23.0552 26.0155C24.7465 25.999 26.2824 25.0945 27.1042 23.6378C27.9249 22.1828 27.9066 20.4006 27.056 18.9628ZM4.69868 20.1534L12.8807 6.16647C13.295 5.45321 14.055 5.01646 14.8767 5.01742C15.6984 5.01838 16.4581 5.45694 16.8656 6.16334L25.0449 20.1459C25.4736 20.8707 25.4828 21.7629 25.0719 22.4914C24.6604 23.2209 23.891 23.6739 23.0438 23.6822L6.6872 23.6821C5.85816 23.6753 5.08734 23.2209 4.67576 22.4907C4.26462 21.7613 4.27422 20.8671 4.69868 20.1534ZM14.8746 21.3488C15.5192 21.3488 16.0417 20.8265 16.0417 20.1822C16.0417 19.5378 15.5192 19.0155 14.8746 19.0155C14.2301 19.0155 13.7076 19.5378 13.7076 20.1822C13.7076 20.8265 14.2301 21.3488 14.8746 21.3488ZM16.0451 9.68215H13.711V17.8488H16.0451V9.68215Z"
      />
    </svg>
  );
}

WarningIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function FlagIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`FlagIcon ${className}`}
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.27248 24H1.09066L-0.000244141 24V21.8182H1.09066V12V3.27273V0H3.27248V1.09091H18.6813L15.4099 7.63627L18.6827 14.1818H3.27248V21.8182H4.36339V24L3.27248 24ZM12.9707 7.63647L15.1524 12H3.27248V3.27273H15.1517L12.9707 7.63647Z"
      />
    </svg>
  );
}

FlagIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function EKGIconSVG({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`EKGIconSVG ${className}`}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <path
        d="M15.8021 8.20161H13.0021L10.9021 14.5016L6.70206 1.90161L4.60206 8.20161H1.80206"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

EKGIconSVG.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function ClockIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`ClockIcon ${className}`}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <g clipPath="url(#clip0_76_376)">
        <path
          d="M8.48191 4.00004V8.00004L11.1486 9.33337M15.1486 8.00004C15.1486 11.6819 12.1638 14.6667 8.48191 14.6667C4.80001 14.6667 1.81525 11.6819 1.81525 8.00004C1.81525 4.31814 4.80001 1.33337 8.48191 1.33337C12.1638 1.33337 15.1486 4.31814 15.1486 8.00004Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_76_376">
          <rect width="16" height="16" fill="white" transform="translate(0.481934)" />
        </clipPath>
      </defs>
    </svg>
  );
}

ClockIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function LogoIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`LogoIcon ${className}`}
      width="27"
      height="14"
      viewBox="0 0 27 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <path d="M4.15668 5.14988C4.15668 5.24307 5.19787 4.65285 6.77006 4.37327C7.19683 4.29922 7.62819 4.25425 8.06113 4.23866C8.06113 4.39398 7.94659 4.55965 7.89454 4.73568C7.79935 5.04846 7.72287 5.36656 7.66548 5.68833L6.49935 5.81258C5.99958 5.90578 5.45816 6.01968 4.93757 6.14394C3.95885 6.37174 3.04261 6.63061 2.19925 6.91019C1.88034 7.01246 1.57041 7.1406 1.27259 7.29332C1.04301 7.42128 0.823703 7.56668 0.616636 7.72822L0.387573 7.92496L1.06435 7.6868C2.02795 7.37084 3.01733 7.13871 4.02132 6.99303C4.92716 6.84806 6.1037 6.69274 7.49889 6.59955C7.49889 6.79629 7.49889 6.99303 7.55095 7.20013C7.60301 7.40722 7.55095 7.55219 7.61342 7.71786L6.49935 7.88354C5.3804 7.99425 4.28687 8.28449 3.26125 8.74299C2.87075 8.94063 2.50103 9.1765 2.1576 9.44711L2.74066 9.24002C4.36719 8.72028 6.07294 8.48895 7.78001 8.5566C7.78001 8.64979 7.84248 8.79476 7.89454 8.95008C8.24093 9.99244 8.83364 10.937 9.62291 11.7045C10.6304 12.6529 11.9128 13.2616 13.2879 13.4441C14.5882 13.6328 15.9156 13.4421 17.1091 12.8953C17.8646 12.5465 18.5465 12.0581 19.1185 11.4559L17.4526 11.0521C17.3423 11.1341 17.2276 11.2101 17.1091 11.2799C16.5349 11.6319 15.9047 11.8841 15.2453 12.0255C14.3917 12.1844 13.5119 12.1311 12.684 11.8701C11.7144 11.567 10.8514 10.996 10.1956 10.2237C9.37239 9.21987 8.9367 7.95668 8.96696 6.66168C9.01266 5.89979 9.21557 5.15541 9.56308 4.4749C9.91059 3.79438 10.3952 3.1923 10.9869 2.70615C11.8816 2.01043 12.9753 1.61522 14.1104 1.57747C15.12 1.54864 16.1166 1.80842 16.9822 2.326C17.8477 2.84358 18.5458 3.59723 18.9936 4.49753L20.7428 5.1913C20.6841 4.93209 20.6111 4.67628 20.5241 4.42504C20.2469 3.68998 19.8297 3.01496 19.2955 2.43692C18.4522 1.51246 17.3728 0.832563 16.172 0.469513C14.7181 0.0336014 13.1581 0.103834 11.7497 0.668615C10.3414 1.23339 9.16876 2.25895 8.42554 3.57595L7.69671 3.70021C6.72949 3.83294 5.79255 4.1307 4.92715 4.58036C4.54191 4.7771 4.12544 5.0981 4.15668 5.14988Z" />
      <path d="M11.3553 3.21606C11.1874 3.33995 11.034 3.48201 10.8978 3.63962C10.7756 3.77532 10.6644 3.9204 10.5652 4.0735L11.2617 4.13548C12.1252 4.2412 12.9823 4.39294 13.8294 4.59003C14.0893 4.65201 14.3492 4.714 15.4511 5.01358C17.1248 5.46813 18.2163 5.78837 19.7237 6.1706C20.0667 6.25325 20.6905 6.40821 21.5221 6.5425C21.8548 6.5425 22.2186 6.65614 22.6864 6.68713C23.3297 6.74397 23.9767 6.74397 24.62 6.68713L23.6428 6.51151C23.2582 6.4392 22.8111 6.35656 22.2602 6.22226C21.3662 5.99499 20.7009 5.76771 20.4514 5.67474L19.1311 5.15821L17.5926 4.54871C17.3951 4.47639 16.8025 4.25945 16.0125 4.02185C15.4051 3.82631 14.7874 3.66422 14.1621 3.53631C13.5887 3.42028 13.009 3.33749 12.426 3.28838C11.9998 3.23673 11.6567 3.21606 11.3553 3.21606Z" />
      <path d="M10.058 5.30814C9.99867 5.43265 9.95041 5.56231 9.91384 5.69558C9.85317 5.92404 9.82883 6.16089 9.84174 6.39717H10.2537C12.1461 6.456 14.0183 6.80914 15.8055 7.44431L17.5565 8.02023L18.8028 8.43909C19.5135 8.66946 20.2448 8.9103 21.007 9.07784C21.9735 9.35842 22.9828 9.45428 23.9837 9.36057C24.7807 9.24734 25.5597 9.02909 26.3012 8.71134L26.5587 8.59616H26.3012L25.6626 8.70087C25.149 8.75386 24.6313 8.75386 24.1176 8.70087C23.5595 8.63406 23.0082 8.51845 22.4696 8.35532C21.8586 8.17735 21.257 7.96765 20.6671 7.72703C18.7925 7.00451 18.3805 6.76367 16.9488 6.2401C16.4251 6.05755 15.8922 5.90375 15.3523 5.77936C14.4421 5.54012 13.5102 5.39626 12.5713 5.35003C11.736 5.26802 10.8955 5.25401 10.058 5.30814Z" />
      <path d="M9.84872 7.58824C9.84872 7.6711 9.84872 7.75397 9.84872 7.84719C9.91066 8.11602 10.0117 8.37428 10.1485 8.61369L10.7482 8.71727C11.6166 8.883 12.1749 9.03838 12.8158 9.19375C13.4568 9.34912 14.2632 9.56664 15.4108 9.80488C15.9484 9.90846 16.4446 10.012 17.1063 10.1053C18.2345 10.2846 19.3756 10.3712 20.5179 10.3642C21.2819 10.3498 22.0446 10.2945 22.8027 10.1985L22.2341 10.1053L19.8459 9.73237C18.6984 9.54593 17.7783 9.3284 16.9512 9.12124C15.4625 8.76907 13.6119 8.25116 13.3741 8.17865C12.2269 7.82402 11.0381 7.62204 9.83838 7.57788L9.84872 7.58824Z" />
    </svg>
  );
}

LogoIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function LeftChevronIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`LeftChevronIcon ${className}`}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <path d="M18.75 22.5L11.25 15L18.75 7.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

LeftChevronIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function RightChevronIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`RightChevronIcon ${className}`}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <path d="M11.25 22.5L18.75 15L11.25 7.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

RightChevronIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function SearchIconSVG({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`SearchIconSVG ${className}`}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <path
        d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SearchIconSVG.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function CardIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`CardIcon ${className}`}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <path d="M8 1H1V8H8V1Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 1H12V8H19V1Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 12H12V19H19V12Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 12H1V19H8V12Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

CardIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function ListIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`ListIcon ${className}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <path
        d="M8 6H21M8 12H21M8 18H21M3 6H3.01M3 12H3.01M3 18H3.01"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ListIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function InfoIconSVG({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <svg
      className={`InfoIconSVG ${className}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <path
        d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

InfoIconSVG.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function ClosedEyeIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/eye-closed.svg"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

ClosedEyeIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function OpenEyeIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/eye-open.svg"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

OpenEyeIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function XIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => null }) {
  return (
    <svg
      className={`XIconSVG ${className}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <path d="M18 6L6 18M6 6L18 18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

XIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function DownChevronIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => null }) {
  return (
    <svg
      className={`DownChevronIcon ${className}`}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <path d="M7.5 11.25L15 18.75L22.5 11.25" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

DownChevronIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function QuestionIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => null }) {
  return (
    <svg
      className={`QuestionIcon ${className}`}
      fill="#000000"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 973.1 973.1"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <g>
        <path
          d="M502.29,788.199h-47c-33.1,0-60,26.9-60,60v64.9c0,33.1,26.9,60,60,60h47c33.101,0,60-26.9,60-60v-64.9
		C562.29,815,535.391,788.199,502.29,788.199z"
        />
        <path
          d="M170.89,285.8l86.7,10.8c27.5,3.4,53.6-12.4,63.5-38.3c12.5-32.7,29.9-58.5,52.2-77.3c31.601-26.6,70.9-40,117.9-40
		c48.7,0,87.5,12.8,116.3,38.3c28.8,25.6,43.1,56.2,43.1,92.1c0,25.8-8.1,49.4-24.3,70.8c-10.5,13.6-42.8,42.2-96.7,85.9
		c-54,43.7-89.899,83.099-107.899,118.099c-18.4,35.801-24.8,75.5-26.4,115.301c-1.399,34.1,25.8,62.5,60,62.5h49
		c31.2,0,57-23.9,59.8-54.9c2-22.299,5.7-39.199,11.301-50.699c9.399-19.701,33.699-45.701,72.699-78.1
		C723.59,477.8,772.79,428.4,795.891,392c23-36.3,34.6-74.8,34.6-115.5c0-73.5-31.3-138-94-193.4c-62.6-55.4-147-83.1-253-83.1
		c-100.8,0-182.1,27.3-244.1,82c-52.8,46.6-84.9,101.8-96.2,165.5C139.69,266.1,152.39,283.5,170.89,285.8z"
        />
      </g>
    </svg>
  );
}

QuestionIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

function TruckIcon({ className = '', tabIndex = -1, onKeyDown = () => {}, onClick = () => {} }) {
  return (
    <img
      className={`feedFloIcon ${className}`}
      src="/icons/truck.svg"
      alt=""
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
}

TruckIcon.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

export {
  HomeIcon,
  MapIcon,
  StoreIcon,
  AlertIcon,
  MoneyIcon,
  EKGIcon,
  ReportIcon,
  UserIcon,
  GearIcon,
  BellIcon,
  EllipsisIcon,
  CalendarIcon,
  HelpIcon,
  PhoneIcon,
  EmailIcon,
  ChatIcon,
  InfoIcon,
  FilterIcon,
  ExportIcon,
  ArrowIcon,
  GreenArrowIcon,
  MenuIcon,
  ComingSoonIcon,
  Refresh,
  SearchIcon,
  EyeIcon,
  KeyIcon,
  RedXIcon,
  PackageIcon,
  SortArrowIcon,
  CircledCheckmarkIcon,

  // SVGs
  CalendarIconSVG,
  PinIcon,
  WarningIcon,
  FlagIcon,
  EKGIconSVG,
  ClockIcon,
  LogoIcon,
  LeftChevronIcon,
  RightChevronIcon,
  SearchIconSVG,
  CardIcon,
  ListIcon,
  ClosedEyeIcon,
  OpenEyeIcon,
  InterventionIconSVG,
  ObservationIconSVG,
  PigIconSVG,
  StockChangeIconSVG,
  FeedChangeIconSVG,
  BarChartIconSVG,
  InfoIconSVG,
  XIcon,
  DownChevronIcon,
  QuestionIcon,
  TruckIcon,
};
