import React, { useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import FeedFloTextInput from '../../atoms/FeedFloTextInput';
import PillGroup from '../../molecules/PillGroup';
import './DeviceSerialNumberInput.scss';

const ENTER_KEYCODE = 13;
const SPACE_KEYCODE = 32;
const COMMA_KEYCODE = 188;
const keyCodeTriggers = new Set([ENTER_KEYCODE, SPACE_KEYCODE, COMMA_KEYCODE]);

const DeviceSerialNumberInput = ({ serials = [], onChange = () => {} }) => {
  const [rawText, setRawText] = useState('');

  const onTextChange = (text) => {
    setRawText(text);
  };

  const onTextKeyDown = (e) => {
    if (!keyCodeTriggers.has(e.keyCode)) {
      return;
    }

    // Prevent the trigger key from being added to the input
    e.preventDefault();

    const newSerialTokens = (rawText.toUpperCase()?.match(/[A-Z0-9]{5}/gim) || []).map((serial) => ({
      id: serial,
      text: serial,
    }));
    const newSerials = uniqBy([...serials, ...newSerialTokens], 'text'); // Ensure all serials are unique
    onChange(newSerials);
    setRawText('');
  };

  return (
    <div className="DeviceSerialNumberInput">
      <FeedFloTextInput
        className="DeviceSerialNumberInput-input"
        text={rawText}
        onChange={onTextChange}
        onKeyDown={onTextKeyDown}
      />
      <PillGroup values={serials} onUpdateValues={onChange} />
    </div>
  );
};

DeviceSerialNumberInput.propTypes = {
  serials: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
};

export default DeviceSerialNumberInput;
