import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { weightLargeUnitLabel, convertLargeUnitsToGrams, convertGramsToLargeUnits } from '../../utils/unitConversion';
import { DATE_TIME_DASH } from '../../utils/dates';
import useUser from '../../utils/hooks/useUser';
import { XIcon } from '../../atoms/Icons';
import Select from '../../atoms/Select';
import FeedFloTextInput from '../../atoms/FeedFloTextInput';
import Checkbox from '../../atoms/Checkbox';
import NumberInput from '../../atoms/NumberInput';
import TextArea from '../../atoms/TextArea';
import { PurposeEnum, SourceEnum, MethodEnum } from './enums';
import { purposeList, sourceList, methodList } from './lists';
import './BinCheckDialog.scss';

function BinCheckDialogView({
  dialogRef = null,
  firstFieldRef = null,
  open = false,
  title = '',
  binSets = [],
  binSetLevelID = '',
  validAt: validAtProp = null,
  levelInGrams: levelInGramsProp = 0,
  method: methodProp = '',
  purpose: purposeProp = PurposeEnum.Test,
  comment: commentProp = '',
  newEntry = true,
  onInsertBinSetLevel = () => {},
  onUpdateBinSetLevel = () => {},
}) {
  const { user } = useUser();
  const isMetric = user?.isMetric || false;
  const feedLargeUnitsString = convertGramsToLargeUnits(isMetric, levelInGramsProp).toString();

  const [binSet, setBinSet] = useState();
  const [purpose, setPurpose] = useState(purposeProp);
  const [validAt, setValidAt] = useState(validAtProp ? dayjs.tz(1000 * validAtProp).format(DATE_TIME_DASH) : '');
  const [feedLargeUnits, setFeedLargeUnits] = useState(feedLargeUnitsString);
  const [source] = useState(SourceEnum.Farmer);
  const [method, setMethod] = useState(methodProp);
  const [notes, setNotes] = useState(commentProp);
  const [createAnother, setCreateAnother] = useState(false);

  const binSetID = useMemo(() => binSet?.id || '', [binSet]);

  const binSetList = useMemo(
    () =>
      binSets.map((binSet) => ({
        name: (binSet?.bins || []).map((bin) => bin?.name || '').join(', '),
        value: binSet?.id,
      })),
    [binSets],
  );

  const binCheckNumericRegex = /^([1-9]+[0-9]*(\.[0-9]{1,2})?|0(\.[0-9]{1,2})?)$/;
  const isEditing = binSetLevelID?.length > 0;

  const reloadForm = () => {
    // Convert from unix seconds into a datetime string
    const validAtString = validAtProp ? dayjs.unix(validAtProp).format(DATE_TIME_DASH) : '';

    // Convert amount of feed from grams into a large units string
    const feedLargeUnitsString = convertGramsToLargeUnits(isMetric, levelInGramsProp).toString();

    // Load the current state from props into the form
    setBinSet(binSets[0]);
    setPurpose(purposeProp);
    setValidAt(validAtString);
    setFeedLargeUnits(feedLargeUnitsString);
    // source is static for now
    setMethod(methodProp);
    setNotes(commentProp);
    setCreateAnother(false);
  };

  // Select the first bin set when input list updates.
  useEffect(() => {
    setBinSet(binSets[0]);
  }, [binSets]);

  useEffect(() => {
    if (!isEditing) {
      return;
    }

    // Convert from unix seconds into a datetime string
    const validAtString = validAtProp ? dayjs.tz(1000 * validAtProp).format(DATE_TIME_DASH) : '';

    // Convert amount of feed from grams into a large units string
    const feedLargeUnitsString = convertGramsToLargeUnits(isMetric, levelInGramsProp).toString();

    // Load the current state from props into the form
    setValidAt(validAtString);
    setFeedLargeUnits(feedLargeUnitsString);
    setMethod(methodProp);
    setNotes(commentProp);

    reloadForm();
  }, [binSetLevelID, validAtProp, levelInGramsProp, methodProp, commentProp, isMetric]);

  const calculateTotalError = () => {
    if (!method || '0' === feedLargeUnits) {
      return 0;
    }

    const numBinsInBinSet = (binSet?.bins || []).length;
    const errorRate = 1.5; // Every method other than empty has a hardcoded rate of 1.5 for now

    switch (method) {
      case MethodEnum.Empty:
        return 0;
      default:
        return numBinsInBinSet * errorRate;
    }
  };

  const totalError = calculateTotalError();
  const unitLabel = weightLargeUnitLabel(isMetric).toLowerCase();
  const unitLabelSuffix = totalError !== 1 ? 's' : '';

  const validateForm = () => {
    return (
      validAt !== null &&
      validAt !== '' &&
      feedLargeUnits !== '' &&
      binCheckNumericRegex.test(feedLargeUnits) &&
      binSetID !== '' &&
      source !== '' &&
      method !== '' &&
      purpose !== '' &&
      calculateTotalError() >= 0
    );
  };

  const closeDialog = () => {
    if (!dialogRef?.current) {
      return;
    }

    dialogRef.current.close();
  };

  const onPurposeChange = (e) => {
    setPurpose(e.target.value);
  };

  const onFeedLargeUnitsChange = (e) => {
    setFeedLargeUnits(e.target.value);
  };

  const onBinSetChange = (e) => {
    const binSet = structuredClone(binSets[e.target.selectedIndex]);
    setBinSet(binSet);
  };

  const onMethodChange = (e) => {
    setMethod(e.target.value);
  };

  const onNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const onCreateAnotherChange = (e) => {
    setCreateAnother(e.target.checked);
  };

  const onResetForm = () => {
    setPurpose(PurposeEnum.Test);
    setValidAt('');
    setFeedLargeUnits('0');
    setMethod('');
    setNotes('');

    if (firstFieldRef?.current) {
      firstFieldRef.current.focus();
    }
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    const validAtInSeconds = dayjs.tz(validAt).unix() || null;
    const levelInGrams = Math.round(convertLargeUnitsToGrams(isMetric, feedLargeUnits));
    const totalErrorInGrams = Math.round(convertLargeUnitsToGrams(isMetric, calculateTotalError()));

    if (isEditing) {
      onUpdateBinSetLevel(
        binSetLevelID,
        validAtInSeconds,
        levelInGrams,
        binSetID,
        source,
        method,
        purpose,
        totalErrorInGrams,
        notes,
      );
    } else {
      onInsertBinSetLevel(
        validAtInSeconds,
        levelInGrams,
        binSetID,
        source,
        method,
        purpose,
        totalErrorInGrams,
        notes,
        !createAnother,
      );
    }

    // Once the form has been submitted, reset it for the next submission
    if (newEntry) {
      onResetForm();
    }
    if (!createAnother || isEditing) {
      closeDialog();
    }
  };

  const isFormValid = validateForm();
  return (
    <dialog ref={dialogRef} className="BinCheckDialog" open={open} onClose={reloadForm}>
      <div className="BinCheckDialog-header">
        <h6 className="BinCheckDialog-title">{title}</h6>
        <XIcon className="BinCheckDialog-closeButton" onClick={closeDialog} />
      </div>
      <div className="BinCheckDialog-body">
        <form className="BinCheckDialog-form" onSubmit={onSubmitForm}>
          <Select
            inputRef={firstFieldRef}
            label="Purpose"
            itemList={purposeList}
            value={purpose}
            onChange={onPurposeChange}
          />
          <FeedFloTextInput
            labelClassName="BinCheckDialog-datetimeInputLabel"
            inputClassName="BinCheckDialog-datetimeInput"
            type="datetime-local"
            label="Checked At"
            text={validAt}
            onChange={setValidAt}
          />
          <NumberInput
            label={`Feed Level (in ${unitLabel}s)`}
            value={feedLargeUnits}
            min={0.0}
            max={250}
            step={0.01}
            onChange={onFeedLargeUnitsChange}
          />
          <Select
            label="Bin Set"
            disabled={1 === binSetList.length}
            itemList={binSetList}
            value={binSetID}
            tabIndex={1 === binSetList.length ? -1 : 0}
            onChange={onBinSetChange}
          />
          <Select label="Source" disabled={true} itemList={sourceList} value={source} tabIndex={-1} />
          <Select
            label="Method"
            defaultText="Select a method"
            itemList={methodList}
            value={method}
            description={`Total error: ${totalError} ${unitLabel}${unitLabelSuffix}`}
            onChange={onMethodChange}
          />
          <TextArea label="Notes" value={notes} onChange={onNotesChange} />
          {!isEditing && (
            <Checkbox label="Create another Bin Check" checked={createAnother} onChange={onCreateAnotherChange} />
          )}
          <div className="BinCheckDialog-footer">
            <button
              className={`BinCheckDialog-submit ${!isFormValid ? 'BinCheckDialog-submit--disabled' : ''}`}
              type="submit"
              disabled={!isFormValid}
              tabIndex={0}
            >
              {isEditing ? 'Update' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </dialog>
  );
}

BinCheckDialogView.propTypes = {
  dialogRef: PropTypes.object,
  firstFieldRef: PropTypes.object,
  open: PropTypes.bool,
  title: PropTypes.string,
  binSets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      bins: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
  ),
  binSetLevelID: PropTypes.string,
  validAt: PropTypes.number,
  levelInGrams: PropTypes.number,
  method: PropTypes.string,
  purpose: PropTypes.string,
  comment: PropTypes.string,
  newEntry: PropTypes.bool,
  onInsertBinSetLevel: PropTypes.func,
  onUpdateBinSetLevel: PropTypes.func,
};

export default BinCheckDialogView;
