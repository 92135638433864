import PropTypes from 'prop-types';
import Calendar, { CalendarMode, GridDateBlock, numDaysInWeek, RowDateBlock } from '../../../../organisms/Calendar';
import OrderList from '../TripList';
import './MainCalendar.scss';

function MainCalendar({
  loading = false,
  isMetric = false,
  showWeekends = false,
  mode = CalendarMode.Month,
  today = null,
  currentDate = null,
  filters = [],
  dateBlockRefs = {},
  dateToTripsMap = {},
  onCurrentDateChange = () => {},
  onDateRangeChange = () => {},
  onTripCardClick = () => {},
  onSeeAllTripsClick = () => {},
}) {
  const numDaysInShortWeek = 5;
  const sizeOfWeek = showWeekends ? numDaysInWeek : numDaysInShortWeek;

  let rowClassName = '';
  let tripsToDisplay = null;
  if (mode === CalendarMode.OneWeek) {
    rowClassName = 'MainCalendar--oneWeek';
    tripsToDisplay = 6;
  } else if (mode === CalendarMode.TwoWeeks) {
    rowClassName = 'MainCalendar--twoWeeks';
    tripsToDisplay = 2;
  }

  const renderDate = (date, i, dateList) => {
    const trips = dateToTripsMap[date.format('YYYY-MM-DD')] || [];

    const orderList = (
      <OrderList
        loading={loading}
        date={date}
        isMetric={isMetric}
        trips={trips}
        tripsToDisplay={tripsToDisplay}
        chevronVisible={mode === CalendarMode.Agenda}
        onTripCardClick={onTripCardClick}
        onSeeAllTripsClick={onSeeAllTripsClick}
      />
    );

    const dateBlockRef = (ref) => {
      dateBlockRefs.current[date] = ref;
    };

    if (mode === CalendarMode.Agenda) {
      return (
        <RowDateBlock
          key={`date:${date.format('YYYY-MM-DD')}`}
          dateBlockRef={dateBlockRef}
          isDateHighlighted={date.isSame(today, 'day')}
          date={date}
        >
          {orderList}
        </RowDateBlock>
      );
    }

    return (
      <GridDateBlock
        key={`date:${date.format('YYYY-MM-DD')}`}
        dateBlockRef={dateBlockRef}
        isFirstRow={i < sizeOfWeek}
        isLastColumn={i % sizeOfWeek === sizeOfWeek - 1}
        isLastRow={i >= dateList.length - sizeOfWeek}
        isDateHighlighted={date.isSame(today, 'day')}
        date={date}
      >
        {orderList}
      </GridDateBlock>
    );
  };

  return (
    <Calendar
      className={`MainCalendar ${rowClassName} ${!showWeekends ? 'MainCalendar--noWeekends' : ''}`}
      mode={mode}
      currentDate={currentDate}
      renderDate={renderDate}
      dayOfWeekFilters={filters}
      dateFilters={filters}
      onCurrentDateChange={onCurrentDateChange}
      onDateRangeChange={onDateRangeChange}
    />
  );
}

MainCalendar.propTypes = {
  loading: PropTypes.bool,
  isMetric: PropTypes.bool,
  showWeekends: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(CalendarMode)),
  today: PropTypes.object,
  currentDate: PropTypes.object,
  filters: PropTypes.arrayOf(PropTypes.func),
  dateBlockRefs: PropTypes.object,
  dateToTripsMap: PropTypes.object,
  onCurrentDateChange: PropTypes.func,
  onDateRangeChange: PropTypes.func,
  onTripCardClick: PropTypes.func,
  onSeeAllTripsClick: PropTypes.func,
};

export default MainCalendar;
