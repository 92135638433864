import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Page from '../../atoms/Page';
import './NotFoundPage.scss';

function NotFoundPage({ titleSegments = [] }) {
  const pageTitleSegments = useMemo(() => ['Not Found', ...titleSegments], []);

  return (
    <Page className="TestPage" heading="404 - Not Found" titleSegments={pageTitleSegments}>
      <h2>This little piggy went to the market</h2>
    </Page>
  );
}

NotFoundPage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
};

export default NotFoundPage;
