import PropTypes from 'prop-types';
import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import './DonutChart.scss';

function DonutChart({
  width = 0,
  height = 0,
  thickness = 100,
  data = [],
  centerValue = 0,
  centerLabel = '',
  colorScale = () => {},
  valueFormatter = () => {},
  onChartClick = () => {},
}) {
  const margin = { top: 10, right: 10, bottom: 10, left: 10 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerX = innerWidth / 2;
  const centerY = innerHeight / 2;

  const getPieValue = (data) => {
    return data.usage;
  };

  const onClick = (e) => {
    const label = e.currentTarget.getAttribute('data-label');
    label === 'Remaining Locations' ? null : onChartClick(label);
  };

  return (
    <div className="DonutChart">
      {centerValue && centerLabel ? (
        <div className="DonutChart-centerContainer">
          <div className="DonutChart-centerValue">{valueFormatter(centerValue)}</div>
          <div className="DonutChart-centerLabel">{centerLabel}</div>
        </div>
      ) : null}
      <svg width={width} height={height}>
        <Group top={centerY + margin.top} left={centerX + margin.left}>
          <Pie data={data} pieValue={getPieValue} outerRadius={radius} innerRadius={radius - thickness}>
            {({ arcs, path }) => {
              return (
                <g>
                  {arcs.map((arc, i) => {
                    return (
                      <g
                        className={arc.data.label === 'Remaining Locations' ? '' : 'DonutChart-donutSegment--clickable'}
                        key={`arc${i}`}
                        data-label={arc.data.label}
                        onClick={onClick}
                      >
                        <path d={path(arc)} fill={colorScale(arc.data.label)} />
                      </g>
                    );
                  })}
                </g>
              );
            }}
          </Pie>
        </Group>
      </svg>
    </div>
  );
}

DonutChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  thickness: PropTypes.number,
  data: PropTypes.array,
  centerValue: PropTypes.number,
  centerLabel: PropTypes.string,
  colorScale: PropTypes.func,
  valueFormatter: PropTypes.func,
  onChartClick: PropTypes.func,
};

export default DonutChart;
