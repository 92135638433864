// Global Enums
export const StatusEnum = {
  Error: 'error',
  Warning: 'warning',
  Investigate: 'investigate',
  Success: 'success',
  Inactive: 'inactive',
};

export const IconEnum = {
  Clock: 'clock',
  EKG: 'ekg',
  Logo: 'logo',
  Warning: 'warning',
};

// Bin Inventory
export const DeliveryStatus = {
  Ordered: 'ordered',
  Delivered: 'delivered',
  Cancelled: 'cancelled',
  Recommended: 'recommended',
  Unknown: 'unknown',
};

// Transport Types
export const TransportTypes = {
  SMS: 'sms',
  Email: 'email',
};

// Alert Dashboard Data Types
export const AlertDashboardDataTypes = {
  Alerts: 'alerts',
  NoFeedTime: 'nofeed_time',
};

export const DisplayedCalibrationStep = {
  EmptyBinForTest: 'empty_bin_for_test',
  DeliverFeed: 'deliver_feed',
  RecordStartTime: 'record_start_time',
  RunBinUntilEmpty: 'run_bin_until_empty',
  CalculateResults: 'calculate_results',
};

export const CalibrationStatus = {
  Errored: 'errored',
  FormStarted: 'form_started',
  FormSubmitted: 'form_submitted',
  Calculating: 'calculating',
  Complete: 'complete',
};

export const CalibrationType = {
  Test: 'test',
  Calibration: 'calibration',
};

export const ConsumptionTabEventCategory = {
  CustomEvent: 'custom_event',
  ChangeInConsumption: 'change_in_consumption',
  EmptyPipe: 'empty_pipe',
  InactiveAuger: 'inactive_auger',
};

export const ConsumptionTabCustomEventType = {
  Observation: 'observation',
  Intervention: 'intervention',
  FeedChange: 'feed_change',
  StockChange: 'stock_change',
  Other: 'other',
};
