import { useMemo } from 'react';
import { MIN_EMPTY_PIPE_DURATION_IN_SECONDS, MIN_AUGER_DURATION_IN_SECONDS } from './constants';

export default function useFaultCodeFilter() {
  const now = useMemo(() => new Date(), []);
  if (!now) return [];
  return [
    {
      code: { _eq: 2001 },
      started_at: { _lte: Math.floor(now?.getTime() / 1000) - MIN_EMPTY_PIPE_DURATION_IN_SECONDS },
      _or: [{ ended_at: { _is_null: true } }, { duration: { _gte: MIN_EMPTY_PIPE_DURATION_IN_SECONDS } }],
      deleted_at: { _is_null: true },
    },
    {
      code: { _eq: 2004 },
      started_at: { _lte: Math.floor(now?.getTime() / 1000) - MIN_AUGER_DURATION_IN_SECONDS },
      _or: [{ ended_at: { _is_null: true } }, { duration: { _gte: MIN_AUGER_DURATION_IN_SECONDS } }],
      deleted_at: { _is_null: true },
    },
  ];
}
