import PropTypes from 'prop-types';
import AlertTableRow from './AlertTableRow';
import FeedFloButton from '../../atoms/FeedFloButton';
import './AlertTable.scss';

function AlertTable({
  data = [],
  loading = false,
  allContentLoaded = false,
  sortColumn = '',
  sortLevel = 0,
  onManualLoadRows = () => {},
  onColumnSort = () => {},
}) {
  const renderFooterContent = () => {
    if (loading) {
      return 'Loading...';
    } else if (allContentLoaded) {
      return "You've reached the end!";
    } else {
      return <FeedFloButton onClick={onManualLoadRows}>Load More</FeedFloButton>;
    }
  };

  return (
    <div className="AlertTable">
      <AlertTableRow isHeader={true} sortColumn={sortColumn} sortLevel={sortLevel} onColumnClick={onColumnSort} />
      {data.map((row) => (
        <AlertTableRow
          key={row.id}
          status={row.status}
          type={row.type}
          faultId={row.id}
          faultDate={row.fault_date}
          faultTime={row.fault_time}
          durationStartTime={row.duration_start_time}
          durationEndTime={row.duration_end_time}
          locationBarnId={row.location_barn_id}
          locationBarnName={row.location_barn_name}
          locationFeedLine={row.location_feed_line}
          noFeedTime={row.nofeed_time}
          rootCause={row.root_cause}
        />
      ))}
      <div className="AlertTable-footer">{renderFooterContent()}</div>
    </div>
  );
}

AlertTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  allContentLoaded: PropTypes.bool,
  sortColumn: PropTypes.string,
  sortLevel: PropTypes.number,
  onManualLoadRows: PropTypes.func,
  onColumnSort: PropTypes.func,
};

export default AlertTable;
