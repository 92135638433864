import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SearchIconSVG, CardIcon, ListIcon } from '../../atoms/Icons';
import FeedFloTextInput from '../../atoms/FeedFloTextInput';
import FeedFloButton from '../../atoms/FeedFloButton';
import BarnListSection from '../../molecules/BarnListSection';
import Page from '../../atoms/Page';
import './BarnListPage.scss';

function BarnListPageView({
  titleSegments = [],
  searchFilter = '',
  viewMode = 'card',
  pinnedBarnsListLoading = false,
  pinnedBarnsList = [],
  pinnedBarnsListPreviewItem = null,
  currentPinnedBarnsPage = 0,
  maxPinnedBarnsPage = 0,
  barnAlertsListLoading = false,
  barnAlertsList = [],
  barnAlertsListPreviewItem = null,
  currentBarnAlertsPage = 0,
  maxBarnAlertsPage = 0,
  allBarnsSelectedRowIndex = 0,
  allBarnsListLoading = false,
  allBarnsList = [],
  allBarnsListPreviewItem = null,
  currentAllBarnsPage = 0,
  maxAllBarnsPage = 0,
  onKeyDown = () => {},
  onChangeSearchFilter = () => {},
  onBlurSearchFilter = () => {},
  onSwitchViewMode = () => {},
  onNextPinnedBarnsPageClick = () => {},
  onPreviousPinnedBarnsPageClick = () => {},
  onNextBarnAlertsPageClick = () => {},
  onPreviousBarnAlertsPageClick = () => {},
  onNextAllBarnsPageClick = () => {},
  onPreviousAllBarnsPageClick = () => {},
  onPinBarnClick = () => {},
}) {
  const pageTitleSegments = useMemo(() => ['My Barns', ...titleSegments], []);

  return (
    <Page className="BarnListPage" heading="My Barns" titleSegments={pageTitleSegments} onKeyDown={onKeyDown}>
      <div className="BarnListPage-searchContainer">
        <FeedFloTextInput
          className="BarnListPage-searchBarWrapper"
          inputClassName="BarnListPage-searchBar"
          defaultText="Search by Barn, Location, or Organization"
          autoFocus={true}
          text={searchFilter}
          onChange={onChangeSearchFilter}
          onBlur={onBlurSearchFilter}
        />
        <SearchIconSVG className="BarnListPage-searchIcon" />
        <FeedFloButton className="BarnListPage-switchView" onClick={onSwitchViewMode}>
          {viewMode === 'card' ? (
            <CardIcon className="BarnListPage-cardViewIcon" />
          ) : (
            <ListIcon className="BarnListPage-listViewIcon" />
          )}
          Switch View
        </FeedFloButton>
      </div>
      {!searchFilter.length ? (
        <>
          <BarnListSection
            loading={pinnedBarnsListLoading}
            heading="Pinned Barns"
            viewMode={viewMode}
            barnList={pinnedBarnsList}
            previewItem={pinnedBarnsListPreviewItem}
            currentPage={currentPinnedBarnsPage}
            maxPage={maxPinnedBarnsPage}
            emptyTitle="No Pinned Barns"
            emptyImage="/images/pin.svg"
            emptyText="Pin a barn from the list below for it to appear here"
            onNextPageClick={onNextPinnedBarnsPageClick}
            onPreviousPageClick={onPreviousPinnedBarnsPageClick}
            onPinBarnClick={onPinBarnClick}
          />
          <BarnListSection
            loading={barnAlertsListLoading}
            heading="Barn Alerts"
            viewMode={viewMode}
            barnList={barnAlertsList}
            previewItem={barnAlertsListPreviewItem}
            currentPage={currentBarnAlertsPage}
            maxPage={maxBarnAlertsPage}
            emptyTitle="Flowing Fine!"
            emptyImage="/images/pig.svg"
            emptyText="You don't have any barn alerts"
            onNextPageClick={onNextBarnAlertsPageClick}
            onPreviousPageClick={onPreviousBarnAlertsPageClick}
            onPinBarnClick={onPinBarnClick}
          />
        </>
      ) : null}
      <BarnListSection
        loading={allBarnsListLoading}
        heading="All Barns"
        viewMode="list"
        selectedRowIndex={allBarnsSelectedRowIndex}
        barnList={allBarnsList}
        previewItem={allBarnsListPreviewItem}
        currentPage={currentAllBarnsPage}
        maxPage={maxAllBarnsPage}
        emptyTitle={searchFilter.length ? 'No Barns' : 'No Barns set up in FeedFlo'}
        emptyImage="/images/barn.svg"
        emptyText={
          searchFilter.length
            ? 'No Barns matched your search criteria'
            : 'Please contact your administrator to set up your first Barn'
        }
        onNextPageClick={onNextAllBarnsPageClick}
        onPreviousPageClick={onPreviousAllBarnsPageClick}
        onPinBarnClick={onPinBarnClick}
      />
    </Page>
  );
}

BarnListPageView.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
  searchFilter: PropTypes.string,
  viewMode: PropTypes.oneOf(['card', 'list']),
  pinnedBarnsListLoading: PropTypes.bool,
  pinnedBarnsList: PropTypes.array,
  pinnedBarnsListPreviewItem: PropTypes.object,
  currentPinnedBarnsPage: PropTypes.number,
  maxPinnedBarnsPage: PropTypes.number,
  barnAlertsListLoading: PropTypes.bool,
  barnAlertsList: PropTypes.array,
  barnAlertsListPreviewItem: PropTypes.object,
  currentBarnAlertsPage: PropTypes.number,
  maxBarnAlertsPage: PropTypes.number,
  allBarnsSelectedRowIndex: PropTypes.number,
  allBarnsListLoading: PropTypes.bool,
  allBarnsList: PropTypes.array,
  allBarnsListPreviewItem: PropTypes.object,
  currentAllBarnsPage: PropTypes.number,
  maxAllBarnsPage: PropTypes.number,
  onKeyDown: PropTypes.func,
  onChangeSearchFilter: PropTypes.func,
  onBlurSearchFilter: PropTypes.func,
  onSwitchViewMode: PropTypes.func,
  onNextPinnedBarnsPageClick: PropTypes.func,
  onPreviousPinnedBarnsPageClick: PropTypes.func,
  onNextBarnAlertsPageClick: PropTypes.func,
  onPreviousBarnAlertsPageClick: PropTypes.func,
  onNextAllBarnsPageClick: PropTypes.func,
  onPreviousAllBarnsPageClick: PropTypes.func,
  onPinBarnClick: PropTypes.func,
};

export default BarnListPageView;
