import { gql } from '@apollo/client';

export const GetBarnsQuery = gql`
  query FeedOrdersPage_GetBarns {
    barn: farm(
      where: { deleted_at: { _is_null: true }, organization: { deleted_at: { _is_null: true } } }
      order_by: [{ name: asc_nulls_last }, { id: asc }]
    ) {
      name
      value: id
      organization {
        id
        name
      }
    }
  }
`;

export const GetTripsQuery = gql`
  query FeedOrdersPage_GetTrips($lowerBound: bigint!, $upperBound: bigint!) {
    trip(
      where: {
        deleted_at: { _is_null: true }
        deliveries: {
          _or: [
            { _and: [{ delivered_at: { _gte: $lowerBound } }, { delivered_at: { _lte: $upperBound } }] }
            { _and: [{ ordered_at: { _gte: $lowerBound } }, { ordered_at: { _lte: $upperBound } }] }
          ]
          deleted_at: { _is_null: true }
          bin: {
            deleted_at: { _is_null: true }
            farm: { deleted_at: { _is_null: true }, organization: { deleted_at: { _is_null: true } } }
          }
        }
      }
    ) {
      id
      external_id
      comment
      status
      barn {
        id
        name
      }
      deliveries(
        where: {
          _or: [
            { _and: [{ delivered_at: { _gte: $lowerBound } }, { delivered_at: { _lte: $upperBound } }] }
            { _and: [{ ordered_at: { _gte: $lowerBound } }, { ordered_at: { _lte: $upperBound } }] }
          ]
          deleted_at: { _is_null: true }
          bin: {
            deleted_at: { _is_null: true }
            farm: { deleted_at: { _is_null: true }, organization: { deleted_at: { _is_null: true } } }
          }
        }
        order_by: [{ delivered_at: asc_nulls_last }, { ordered_at: asc_nulls_last }, { id: asc }]
      ) {
        id
        ordered_at
        ordered_mass_in_grams
        delivered_at
        delivered_mass_in_grams
        status
        comment
        external_id
        bin {
          id
          name
          barn: farm {
            id
            name
            organization {
              id
              name
            }
          }
        }
        feed_profile {
          name
        }
      }
    }
  }
`;

export const GetTripQuery = gql`
  query FeedOrdersPage_GetTrip($tripId: uuid!) {
    trip(where: { deleted_at: { _is_null: true }, id: { _eq: $tripId } }) {
      id
      deliveries(
        where: {
          deleted_at: { _is_null: true }
          bin: {
            deleted_at: { _is_null: true }
            farm: { deleted_at: { _is_null: true }, organization: { deleted_at: { _is_null: true } } }
          }
        }
        order_by: [{ delivered_at: asc_nulls_last }, { ordered_at: asc_nulls_last }, { id: asc }]
      ) {
        id
        ordered_at
        delivered_at
      }
    }
  }
`;

export const GetBarnsAndBinsQuery = gql`
  query FeedOrdersPage_GetBarnsAndBins {
    barn: farm(
      where: { deleted_at: { _is_null: true }, organization: { deleted_at: { _is_null: true } } }
      order_by: { name: asc }
    ) {
      name
      value: id
      organization {
        id
        name
      }
      bins(where: { deleted_at: { _is_null: true } }, order_by: { sort_order: asc }) {
        name
        value: id
      }
    }
  }
`;

export const InsertTrip = gql`
  mutation FeedDesk_InsertTrip($object: trip_insert_input!) {
    insert_trip_one(object: $object) {
      id
    }
  }
`;

export const UpdateTrip = gql`
  mutation FeedDesk_UpdateTrip($tripID: uuid!, $barnID: uuid!, $externalID: String, $comment: String!) {
    update_trip_by_pk(
      pk_columns: { id: $tripID }
      _set: { barn_id: $barnID, external_id: $externalID, comment: $comment }
    ) {
      id
    }
  }
`;

export const InsertDeliveries = gql`
  mutation FeedDesk_InsertDeliveries($objects: [delivery_insert_input!]!) {
    insert_delivery(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const UpdateDeliveries = gql`
  mutation FeedDesk_UpdateDeliveries($updates: [delivery_updates!]!) {
    update_delivery_many(updates: $updates) {
      returning {
        id
      }
    }
  }
`;
