import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import FeedFloCheckBox from '../../atoms/FeedFloCheckBox';
import { ArrowIcon } from '../../atoms/Icons';
import './FeedFloDropCheckBox.scss';

/***
 * ? DEPRECATED / NEEDS REVIEW ?
 */
function FeedFloDropCheckBox({
  className = '',
  label = '',
  list = [],
  defaultTitle = '',
  disabled = false,
  allSelectedByDefault = false,
  showSelectAllOptions = true,
  onChange = () => {},
}) {
  const [listItems, setDropDownList] = useState(list);
  const [allSelected, setAllSelected] = useState(allSelectedByDefault);
  const [open, setOpen] = useState(false);
  const dropCheckRef = useRef(null);

  useEffect(() => {
    setDropDownList(list);
  }, [list]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (dropCheckRef.current && !dropCheckRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropCheckRef]);

  function selectAllClicked(newState) {
    const updatedList = listItems.map((l) => {
      return {
        ...l,
        selected: newState,
      };
    });
    setAllSelected(newState);
    setDropDownList(updatedList);
    onChange(updatedList);
  }

  function handleItemClicked(id, newState) {
    const updatedList = listItems.map((l) => {
      return {
        ...l,
        selected: id === l.id ? newState : l.selected,
      };
    });
    if (!newState) setAllSelected(false);
    setDropDownList(updatedList);
    onChange(updatedList);
  }

  function handleHeaderClicked() {
    if (!disabled) {
      setOpen(!open);
    }
  }

  const selectAllCheckBox = (
    <div className="sideBySide selectAll" onClick={() => selectAllClicked(!allSelected)}>
      <div className="checkbox">
        <FeedFloCheckBox
          key={`${Math.random()}row`}
          defaultChecked={allSelected}
          onChange={() => selectAllClicked(!allSelected)}
        />
      </div>
      Select All
    </div>
  );

  const listItemsElements = listItems.map((item) => {
    return (
      <div key={item.id} className="sideBySide" onClick={() => handleItemClicked(item.id, !item.selected)}>
        <div className="checkbox">
          <FeedFloCheckBox
            key={`${Math.random()}row`}
            defaultChecked={item.selected}
            onChange={() => handleItemClicked(item.id, !item.selected)}
          />
        </div>
        {item.name}
      </div>
    );
  });

  return (
    <div ref={dropCheckRef} className={`DropCheckBox noselect ${className}`}>
      <div className={` ${disabled || list.length === 0 ? 'disabled' : ''}`} onClick={() => handleHeaderClicked()}>
        {label && <label className={` ${disabled || list.length === 0 ? 'disabled' : ''}`}>{label}</label>}
        <div className={`dropCheckBoxHeader ${disabled || list.length === 0 ? 'disabled' : ''}`}>
          <div className={`noselect title ${disabled || list.length === 0 ? 'disabled' : ''}`}>{defaultTitle}</div>
          <div className={`icon noselect ${open ? 'showList' : ''} ${disabled || list.length === 0 ? 'disabled' : ''}`}>
            <ArrowIcon />
          </div>
        </div>
      </div>
      <div className="DropCheckBox-listContainer">
        {open && (
          <div className="list">
            {showSelectAllOptions ? selectAllCheckBox : null}
            {listItemsElements}
          </div>
        )}
      </div>
    </div>
  );
}

FeedFloDropCheckBox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  list: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  defaultTitle: PropTypes.string,
  allSelectedByDefault: PropTypes.bool,
  showSelectAllOptions: PropTypes.bool,
};

export default FeedFloDropCheckBox;
