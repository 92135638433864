import PropTypes from 'prop-types';
import TextInput from './TextInput';
import { useField } from 'formik';

export default function TextInputField({ label, ...props }) {
  const [field, meta] = useField(props);

  return (
    <TextInput
      isValid={!meta.touched || (meta.touched && !meta.error)}
      error={meta.error}
      label={label}
      {...props}
      {...field}
    />
  );
}

TextInputField.propTypes = {
  label: PropTypes.string,
};
