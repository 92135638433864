import { UintBuffer } from 'uint-buffer';

const uint8 = new UintBuffer(8);
const uint16 = new UintBuffer(16);
const uint32 = new UintBuffer(32);
const uint64 = new UintBuffer(64);

const TAGS = {
  ROOT: 0x00,
  DEVICE_UPLOAD: 0x01,

  EVENTS: 0x30,
  SIGNAL_DATA_BLOCKS: 0x31,

  SIGNAL_DATA_BLOCK: 0x20,
  SIGNAL_UUID: 0x11,
  TIME_OF_FLIGHT_DATA: 0x12,
  AUGER_ROTATION_DATA: 0x13,
  FAN_SPEED_DATA: 0x14,
  AUGER_LENGTH_DATA: 0x15,
  ACCELEROMETER_DATA: 0x16,
  ORIENTATION_DATA: 0x17,
  ACCELEROMETER_TEMPERATURE_DATA: 0x18,
  ACCELEROMETER_FFT: 0x19,

  START_TIME: 0x21,
  END_TIME: 0x22,
  TEMPORAL_CONSISTENCY_SCORE: 0x23,
  DATA_UINT8: 0x24,
  DATA_UINT16: 0x25,
  DATA_UINT32: 0x29,
  DATA_UINT64: 0x28,
  SIGNAL_MESSAGE: 0x26,

  EVENT: 0x40,
  EVENT_UUID: 0x41,
  EVENT_STATUS: 0x42,
  EVENT_TIME: 0x43,
  EVENT_CODE: 0x44,
  EVENTS_MESSAGE: 0x45,

  ROLL: 0x51,
  PITCH: 0x52,
  ORIENTATION_SCORE: 0x53,
  FFT_TERMS_KEPT: 0x54,
  FFT_REAL_TERMS: 0x55,
  FFT_IMAGINARY_TERMS: 0x56,
  FFT_TERM_BINS: 0x57,
  FFT_SIZE: 0x58,
  WINDOW_FUNCTION_ID: 0x59,

  ACCELEROMETER_FFT_X: 0x61,
  ACCELEROMETER_FFT_Y: 0x62,
  ACCELEROMETER_FFT_Z: 0x63,

  SENSOR_SAMPLE_RATE: 0x71,

  DEBUG_CHANNEL_1_DATA: 0x91,
  DEBUG_CHANNEL_2_DATA: 0x92,
  DEBUG_CHANNEL_3_DATA: 0x93,
  DEBUG_CHANNEL_4_DATA: 0x94,
};

const PARSE_FUNCTIONS = {};
function parseHelper(type, buffer, parent, options) {
  let i = 0;

  if (options?.addBufferToJsonList.includes(type)) {
    parent.buffer = buffer;
  }

  while (i < buffer.length) {
    let curIdx = i;
    const typeByte = uint16.unpack(buffer, curIdx);
    curIdx += 2;
    const length = uint16.unpack(buffer, curIdx);
    curIdx += 2;
    const endIdx = curIdx + length;
    const value = buffer.slice(curIdx, endIdx);
    const func = PARSE_FUNCTIONS[typeByte];
    if (typeof func === 'function') {
      parent = func(typeByte, value, parent, options);
    } else {
      if (!Array.isArray(parent.unknownTags)) parent.unknownTags = [];
      parent.unknownTags.push(buffer.slice(i, endIdx));
    }

    i = endIdx;
  }
  return parent;
}

function TLVtoJSON(buffer, { addBufferToJsonList = [], rootTag = TAGS.ROOT } = {}) {
  const result = parseHelper(rootTag, buffer, {}, { addBufferToJsonList });
  return result;
}

function getDouble(array) {
  // Convert uint8 array to double:
  // https://stackoverflow.com/questions/46153073/convert-uint8array-to-double-in-javascript

  const view = new DataView(new ArrayBuffer(8));
  array.forEach(function (b, i) {
    view.setUint8(i, b);
  });

  return view.getFloat64(0, true);
}

function getFloat(array) {
  const view = new DataView(new ArrayBuffer(4));
  array.forEach(function (b, i) {
    view.setUint8(i, b);
  });

  return view.getFloat32(0, true);
}

PARSE_FUNCTIONS[TAGS.DEVICE_UPLOAD] = (type, val, parent, options) => {
  const result = parseHelper(type, val, {}, options);
  return result;
};
PARSE_FUNCTIONS[TAGS.SIGNAL_DATA_BLOCKS] = (type, val, parent, options) => {
  const result = parseHelper(type, val, [], options);
  parent.signalDataBlocks = result;
  return parent;
};
PARSE_FUNCTIONS[TAGS.SIGNAL_DATA_BLOCK] = (type, val, parent, options) => {
  const result = parseHelper(type, val, {}, options);

  if (Array.isArray(parent)) {
    parent.push(result);
  }
  return parent;
};

/// ///////////////   //
//    SIGNAL DATA     //
/// ///////////////   //

PARSE_FUNCTIONS[TAGS.START_TIME] = (type, val, parent) => {
  parent.startTime = uint64.unpack(val, 0);
  return parent;
};
PARSE_FUNCTIONS[TAGS.END_TIME] = (type, val, parent) => {
  parent.endTime = uint64.unpack(val, 0);
  return parent;
};
PARSE_FUNCTIONS[TAGS.TEMPORAL_CONSISTENCY_SCORE] = (type, val, parent) => {
  parent.temporalConsistencyScore = val.readDoubleLE(0);
  return parent;
};
PARSE_FUNCTIONS[TAGS.SIGNAL_MESSAGE] = (type, val, parent) => {
  parent.message = val;
  return parent;
};
PARSE_FUNCTIONS[TAGS.SIGNAL_UUID] = (type, val, parent) => {
  parent.uuid = uint64.unpack(val, 0);
  return parent;
};
PARSE_FUNCTIONS[TAGS.SENSOR_SAMPLE_RATE] = (type, val, parent) => {
  parent.sensorSampleRate = uint16.unpack(val, 0);
  return parent;
};
PARSE_FUNCTIONS[TAGS.FFT_TERMS_KEPT] = (type, val, parent) => {
  parent.savedTermsPerFFT = uint16.unpack(val, 0);
  return parent;
};
PARSE_FUNCTIONS[TAGS.FFT_SIZE] = (type, val, parent) => {
  parent.orignalFFTSize = uint16.unpack(val, 0);
  return parent;
};
PARSE_FUNCTIONS[TAGS.WINDOW_FUNCTION_ID] = (type, val, parent) => {
  parent.windowFunctionId = uint8.unpack(val, 0);
  return parent;
};

// Parse: DataContainers
PARSE_FUNCTIONS[TAGS.TIME_OF_FLIGHT_DATA] = (type, val, parent, options) => {
  parent.timeOfFlight = parseHelper(type, val, {}, options);
  return parent;
};
PARSE_FUNCTIONS[TAGS.AUGER_ROTATION_DATA] = (type, val, parent, options) => {
  parent.augerRotation = parseHelper(type, val, {}, options);
  return parent;
};
PARSE_FUNCTIONS[TAGS.ORIENTATION_DATA] = (type, val, parent, options) => {
  parent.orientation = parseHelper(type, val, {}, options);
  return parent;
};

PARSE_FUNCTIONS[TAGS.ACCELEROMETER_TEMPERATURE_DATA] = (type, val, parent, options) => {
  parent.accelerometerTemperature = parseHelper(type, val, {}, options);
  return parent;
};

PARSE_FUNCTIONS[TAGS.FAN_SPEED_DATA] = (type, val, parent, options) => {
  parent.fanSpeed = parseHelper(type, val, {}, options);
  return parent;
};
PARSE_FUNCTIONS[TAGS.AUGER_LENGTH_DATA] = (type, val, parent, options) => {
  parent.augerLength = parseHelper(type, val, {}, options);
  return parent;
};
PARSE_FUNCTIONS[TAGS.ACCELEROMETER_DATA] = (type, val, parent, options) => {
  parent.accelerometerLength = parseHelper(type, val, {}, options);
  return parent;
};
PARSE_FUNCTIONS[TAGS.ACCELEROMETER_FFT] = (type, val, parent, options) => {
  parent.accelerometerFFT = parseHelper(type, val, {}, options);
  return parent;
};
PARSE_FUNCTIONS[TAGS.ACCELEROMETER_FFT_X] = (type, val, parent, options) => {
  parent.xFFT = parseHelper(type, val, {}, options);
  return parent;
};
PARSE_FUNCTIONS[TAGS.ACCELEROMETER_FFT_Y] = (type, val, parent, options) => {
  parent.yFFT = parseHelper(type, val, {}, options);
  return parent;
};
PARSE_FUNCTIONS[TAGS.ACCELEROMETER_FFT_Z] = (type, val, parent, options) => {
  parent.zFFT = parseHelper(type, val, {}, options);
  return parent;
};
PARSE_FUNCTIONS[TAGS.DEBUG_CHANNEL_1_DATA] = (type, val, parent, options) => {
  parent.debug1 = parseHelper(type, val, {}, options);
  return parent;
};
PARSE_FUNCTIONS[TAGS.DEBUG_CHANNEL_2_DATA] = (type, val, parent, options) => {
  parent.debug2 = parseHelper(type, val, {}, options);
  return parent;
};
PARSE_FUNCTIONS[TAGS.DEBUG_CHANNEL_3_DATA] = (type, val, parent, options) => {
  parent.debug3 = parseHelper(type, val, {}, options);
  return parent;
};
PARSE_FUNCTIONS[TAGS.DEBUG_CHANNEL_4_DATA] = (type, val, parent, options) => {
  parent.debug4 = parseHelper(type, val, {}, options);
  return parent;
};
PARSE_FUNCTIONS[TAGS.ROLL] = (type, val, parent) => {
  let result = [];
  for (let i = 0; i < val.length; i += 8) {
    result.push(getDouble(val.slice(i, i + 8)));
  }
  parent.roll = result;
  return parent;
};
PARSE_FUNCTIONS[TAGS.PITCH] = (type, val, parent) => {
  let result = [];
  for (let i = 0; i < val.length; i += 8) {
    result.push(getDouble(val.slice(i, i + 8)));
  }
  parent.pitch = result;
  return parent;
};
PARSE_FUNCTIONS[TAGS.ORIENTATION_SCORE] = (type, val, parent) => {
  let result = [];
  for (let i = 0; i < val.length; i += 8) {
    result.push(getDouble(val.slice(i, i + 8)));
  }
  parent.movementNoise = result;
  return parent;
};

/// ///////////////
//    EVENTS    //
/// ///////////////

PARSE_FUNCTIONS[TAGS.EVENTS] = (type, val, parent, options) => {
  parent.events = parseHelper(type, val, [], options);
  return parent;
};
PARSE_FUNCTIONS[TAGS.EVENT] = (type, val, parent, options) => {
  parent.push(parseHelper(type, val, {}, options));
  return parent;
};
PARSE_FUNCTIONS[TAGS.EVENT_UUID] = (type, val, parent) => {
  parent.uuid = uint64.unpack(val, 0);
  return parent;
};
PARSE_FUNCTIONS[TAGS.EVENT_STATUS] = (type, val, parent) => {
  parent.status = uint8.unpack(val, 0);
  return parent;
};
PARSE_FUNCTIONS[TAGS.EVENT_TIME] = (type, val, parent) => {
  parent.time = uint64.unpack(val, 0);
  return parent;
};
PARSE_FUNCTIONS[TAGS.EVENT_CODE] = (type, val, parent) => {
  parent.eventCode = uint16.unpack(val, 0);
  return parent;
};
PARSE_FUNCTIONS[TAGS.EVENTS_MESSAGE] = (type, val, parent) => {
  parent.message = val;
  return parent;
};

/// //////////////////////
//     Data Buffers    //
/// //////////////////////

PARSE_FUNCTIONS[TAGS.DATA_UINT8] = (type, val, parent) => {
  const out = [];
  for (let i = 0; i < val.length; i += 1) {
    out.push(uint8.unpack(val, i));
  }
  parent.uint8Data = out;
  return parent;
};
PARSE_FUNCTIONS[TAGS.DATA_UINT16] = (type, val, parent) => {
  const out = [];
  for (let i = 0; i < val.length; i += 2) {
    out.push(uint16.unpack(val, i));
  }
  parent.uint16Data = out;
  return parent;
};

PARSE_FUNCTIONS[TAGS.DATA_UINT32] = (type, val, parent) => {
  const out = [];
  for (let i = 0; i < val.length; i += 4) {
    out.push(uint32.unpack(val, i));
  }
  parent.uint32Data = out;
  return parent;
};

PARSE_FUNCTIONS[TAGS.DATA_UINT64] = (type, val, parent) => {
  const out = [];
  for (let i = 0; i < val.length; i += 8) {
    out.push(uint64.unpack(val, i));
  }
  parent.uint64Data = out;
  return parent;
};

PARSE_FUNCTIONS[TAGS.FFT_REAL_TERMS] = (type, val, parent) => {
  let result = [];
  for (let i = 0; i < val.length; i += 4) {
    result.push(getFloat(val.slice(i, i + 4)));
  }
  parent.realTerms = result;
  return parent;
};

PARSE_FUNCTIONS[TAGS.FFT_IMAGINARY_TERMS] = (type, val, parent) => {
  let result = [];
  for (let i = 0; i < val.length; i += 4) {
    result.push(getFloat(val.slice(i, i + 4)));
  }
  parent.imaginaryTerms = result;
  return parent;
};

PARSE_FUNCTIONS[TAGS.FFT_TERM_BINS] = (type, val, parent) => {
  let result = [];
  for (let i = 0; i < val.length; i += 2) {
    result.push(uint16.unpack(val, i));
  }
  parent.binIndices = result;
  return parent;
};

export { TLVtoJSON, TAGS };
