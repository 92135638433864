import React, { useState, useMemo } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Page from '../../atoms/Page';
import BarnPage from '../BarnPage';
import FaultCodeDetails from '../../organisms/FaultCodeDetails';
import './FaultCodePage.scss';

function FaultCodePage({ titleSegments = [] }) {
  const { faultId } = useParams();
  const [barnSummaryData, setBarnSummaryData] = useState(null);

  const pageTitleSegments = useMemo(() => ['Fault Code', ...titleSegments], []);

  const updateStatesFromFault = (data) => {
    const fault = data?.fault;
    const feedLine = fault?.device?.device_assignments?.[0]?.feed_line;
    const farm = feedLine?.farm;

    if (fault && feedLine && farm) {
      const faultEnd = fault.ended_at ? dayjs.tz(1000 * fault.ended_at) : null;
      const dateRange = {
        from: dayjs
          .tz(1000 * fault.started_at)
          .startOf('day')
          .subtract(1, 'week'),
        to: faultEnd ? faultEnd.endOf('day').add(1, 'week') : dayjs.tz().endOf('day'),
      };
      setBarnSummaryData({
        farmId: farm.id,
        faultId: [fault.id],
        feedLineId: [feedLine.id],
        dateRange,
      });
    }
  };

  return (
    <Page className="FaultCodePage" titleSegments={pageTitleSegments}>
      <div className="FaultCodePage-centerContent">
        {barnSummaryData && (
          <BarnPage
            className="FaultCodePage-barnPage"
            tabsClassName="FeedFloTabs--split"
            barnIdOverride={barnSummaryData?.farmId}
            openFaultIds={barnSummaryData?.faultId}
            visibleFeedLineIds={barnSummaryData?.feedLineId}
            dateRangeOverride={barnSummaryData?.dateRange}
          />
        )}
      </div>
      <div className={`FaultCodePage-rightSidebar`}>
        <FaultCodeDetails faultId={faultId} onFaultLoad={updateStatesFromFault} />
      </div>
    </Page>
  );
}

FaultCodePage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
};

export default FaultCodePage;
