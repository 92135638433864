import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DATE_FORMAT_MONTH_DAY_YEAR } from '../../utils/dates';
import Button from '../../atoms/Button';
import FeedFloTextInput from '../../atoms/FeedFloTextInput';
import FeedFloDropDown from '../../atoms/FeedFloDropDown';
import FeedFloScopesList from '../../molecules/FeedFloScopesList';
import Heading from '../../atoms/Heading';
import './FeedFloNewAccessKey.scss';

/***
 * ? NEEDS REVIEW ?
 */
function FeedFloNewAccessKey({ onGenerate = () => {}, onCancel = () => {} }) {
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [label, setLabel] = useState('');
  const [expiryPeriodInDays, setExpiryPeriodDays] = useState(30);
  const questionTag = `What's this key for?`;
  let expiryTag = `The key will never expire`;
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const [hasPressedSaveOnce, setHasPressedSaveOnce] = useState('');

  if (expiryPeriodInDays !== null) {
    expiryTag = `The key will expire on ${dayjs
      .tz()
      .add(expiryPeriodInDays, 'days')
      .format(DATE_FORMAT_MONTH_DAY_YEAR)}`;
  }

  return (
    <>
      <div className="FeedFloNewAccessKeyBlur" />
      <div className="FeedFloNewAccessKey">
        <Heading text="New Access Key" />
        <div className={`note ${hasPressedSaveOnce && label.length < 1 ? 'error' : ''} `}>
          <FeedFloTextInput
            onChange={(v) => {
              setLabel(v);
            }}
            label="Note"
            text={label}
          />
          <div className="explanation question">{questionTag} </div>
        </div>
        <div className="expiry">
          <FeedFloDropDown
            className="FeedFloNewAccessKey-expiryDropdown"
            label="Expiration"
            defaultTitle="30 days"
            list={[
              { id: 0, name: '30 days', selected: expiryPeriodInDays === 30, days: 30 },
              { id: 1, name: '60 days', selected: expiryPeriodInDays === 60, days: 60 },
              { id: 2, name: '90 days', selected: expiryPeriodInDays === 90, days: 90 },
              { id: 3, name: 'none', selected: expiryPeriodInDays === null, days: null },
            ]}
            onChange={(item) => {
              setExpiryPeriodDays(item.days);
            }}
          />
          <div className="explanation--expiry">{expiryTag}</div>
        </div>
        <div className="scopes">
          <label>Select Scopes</label>
          <div className="scopeDescription">Scopes define the access for personal keys.</div>
          <FeedFloScopesList
            className={`${hasPressedSaveOnce && selectedRoles.length < 1 ? 'error' : ''} `}
            list={[
              {
                id: 'api-data-science',
                name: 'Data Scientist',
                description: 'Access animal consumption data',
                selected: false,
              },
              {
                id: 'api-feed-manager',
                name: 'Feed Manager',
                description: 'Gain Read and Write access to Bin and Feed data',
                selected: false,
              },
            ]}
            onChange={(listOfRoles) => setSelectedRoles(listOfRoles.filter((role) => role.selected))}
          />
        </div>
        <div className="buttons">
          <Button
            disabled={buttonsDisabled}
            variant="vivid"
            color="success"
            content="Generate Key"
            onClick={() => {
              setHasPressedSaveOnce(true);
              if (label.length > 0) {
                setButtonsDisabled(true);
                onGenerate({ label, expiryPeriodInDays, roles: selectedRoles.map((r) => r.id) });
              }
            }}
          />
          <Button
            disabled={buttonsDisabled}
            content="Cancel"
            onClick={() => {
              setButtonsDisabled(true);
              onCancel();
            }}
          />
        </div>
      </div>
    </>
  );
}

FeedFloNewAccessKey.propTypes = {
  onGenerate: PropTypes.func,
  onCancel: PropTypes.func,
};

export default FeedFloNewAccessKey;
