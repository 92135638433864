import PropTypes from 'prop-types';
import ChangePercentageBadge from '../../atoms/ChangePercentageBadge';

function BarnRankingTableRow({ index = 0, name = '', current = 0, previous = 0, valueFormatter = () => {} }) {
  return (
    <>
      <div className="BarnRankingTable-rowIndex">{index}</div>
      <div className="BarnRankingTable-rowName">{name}</div>
      <div className="BarnRankingTable-rowValue">{valueFormatter(current)}</div>
      <div className="BarnRankingTable-rowChange">
        <ChangePercentageBadge value={current} previousValue={previous} />
      </div>
    </>
  );
}

BarnRankingTableRow.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  current: PropTypes.number,
  previous: PropTypes.number,
  valueFormatter: PropTypes.func,
};

export default BarnRankingTableRow;
