import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Routes, Route, Navigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Dayjs } from 'dayjs';
import PropTypes from 'prop-types';

import FeedFloTabs from '../../atoms/FeedFloTabs';
import Page from '../../atoms/Page';
import BarnSummaryTab from '../BarnSummaryTab';
import BarnConsumptionTab from '../BarnConsumptionTab';
import EquipmentTab from '../EquipmentTab';
import BarnSettingsTab from '../BarnSettingsTab';
import DeliveryTab from './DeliveryTab';
import DeviceTab from './DeviceTab';
import BinTab from './BinTab';
import InventoryTab from '../InventoryTab';
import CommentsTab from '../CommentsTab';
import CalibrationTab from '../CalibrationTab';
import CreateCalibration from '../../organisms/CreateCalibration';
import CalibrationResultView from '../../organisms/CalibrationResultView';
import StatusBadge from '../../atoms/StatusBadge';
import { censorTypes, useCensor } from '../../utils/hooks/useCensor';
import FeedLinePage from '../FeedLinePage';
import useUser from '../../utils/hooks/useUser';
import './BarnPage.scss';
import withStaffOnlyIdWrapper from '../../utils/withStaffOnlyIdWrapper';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';

const BARN_SUMMARY_GQL = gql`
  query BarnSummaryQuery($barn_id: uuid!) {
    barn: farm_by_pk(id: $barn_id) {
      name
      organization_id
      organization {
        name
      }
      feed_lines(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
        id
      }
    }
  }
`;

function BarnPage({
  className = '',
  tabsClassName = '',
  barnIdOverride,
  openFaultIds = null,
  visibleFeedLineIds = null,
  dateRangeOverride = null,
}) {
  const { user } = useUser();
  const { censor } = useCensor();

  const [animalGroupId, setAnimalGroupId] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const { barnId: paramsBarnId } = useParams();
  const barnId = barnIdOverride || paramsBarnId;

  const { loading, data } = useQuery(BARN_SUMMARY_GQL, {
    variables: {
      barn_id: barnId,
    },
  });
  // basically show the org name if you have access to multiple orgs via any method
  const showOrgName = user?.isStaff || user?.organizations?.length > 1 || user?.barns?.length > 1;

  const barn = data?.barn;
  const isStaff = user?.isStaff;
  const tabs = [
    {
      title: 'Summary',
      link: 'summary',
    },
    {
      title: 'Consumption',
      link: 'consumption',
    },
    ...(isStaff ? [{ title: 'Devices', link: 'devices' }] : []),
    ...(isStaff ? [{ title: 'Load Cells', link: 'bins' }] : []),
    ...(isStaff ? [{ title: 'Deliveries', link: 'deliveries' }] : []),
    ...(isStaff ? [{ title: 'Staff Notes', link: 'comments' }] : []),
    {
      title: 'Calibrations',
      link: 'calibrations',
      contentRight: <StatusBadge text="New" status="success" />,
    },

    {
      title: 'Inventory',
      link: 'inventory',
      contentRight: <StatusBadge text="New" status="success" />,
    },

    { title: 'Settings', link: 'settings' },
  ];

  const pageTitleSegments = useMemo(() => {
    const segments = [];

    if (barn?.name) {
      segments.push(censor(barn?.name, censorTypes.barn), 'Barns');
    }

    return segments;
  }, [barn]);

  useEffect(() => {
    let index = tabs.findIndex((tab) => window.location.pathname.includes(tab.link));
    index = index === -1 ? 0 : index;
    setSelectedTab(index);
  }, []);

  const orgDisplayName =
    showOrgName && barn?.organization?.name ? censor(barn?.organization?.name, censorTypes.organization) : '';
  const barnDisplayName = barn?.name ? censor(barn?.name, censorTypes.barn) : '';

  const IdWrappedBarnName = withStaffOnlyIdWrapper(barn?.name ? barnDisplayName : <LoadingSkeleton />);
  const IdWrapperOrgName = withStaffOnlyIdWrapper(barn?.organization?.name ? orgDisplayName : <LoadingSkeleton />);

  const pageDisplayHeader = (
    <>
      <IdWrappedBarnName data={barnId} hoverText="Copy Barn ID" />
      {showOrgName && <span>{' ('}</span>}
      <IdWrapperOrgName data={barn?.organization_id} hoverText="Copy Org ID" />
      {showOrgName && <span>{')'}</span>}
    </>
  );
  return (
    <Page className={`BarnPage ${className}`} headerComponent={pageDisplayHeader}>
      <FeedFloTabs tabData={tabs} defaultSelected={selectedTab} className={tabsClassName} />
      <Routes>
        <Route
          path="summary"
          element={
            <BarnSummaryTab
              loading={loading}
              barnId={barnId}
              organizationId={barn?.organization_id}
              animalGroupId={animalGroupId}
              setAnimalGroupId={setAnimalGroupId}
            />
          }
        />
        <Route
          path="consumption"
          element={
            <BarnConsumptionTab
              loading={loading}
              titleSegments={pageTitleSegments}
              barn={barn}
              barnId={barnId}
              orgId={barn?.organization_id}
              openFaultIds={openFaultIds}
              visibleFeedLineIds={visibleFeedLineIds}
              dateRangeOverride={dateRangeOverride}
              animalGroupId={animalGroupId}
              setAnimalGroupId={setAnimalGroupId}
            />
          }
        />
        <Route
          path="settings"
          element={
            <div>
              <BarnSettingsTab titleSegments={pageTitleSegments} barnId={barnId} />
              <EquipmentTab titleSegments={pageTitleSegments} barnId={barnId} />
            </div>
          }
        />
        <Route path="inventory" element={<InventoryTab titleSegments={pageTitleSegments} barnId={barnId} />} />
        <Route path="deliveries" element={<DeliveryTab titleSegments={pageTitleSegments} farmId={barnId} />} />
        <Route path="devices" element={<DeviceTab titleSegments={pageTitleSegments} barn={barn} barnId={barnId} />} />
        <Route path="bins" element={<BinTab titleSegments={pageTitleSegments} barn={barn} barnId={barnId} />} />
        <Route path="comments" element={<CommentsTab titleSegments={pageTitleSegments} barnId={barnId} />} />
        <Route path="calibrations" element={<CalibrationTab titleSegments={pageTitleSegments} barnId={barnId} />} />

        <Route path="devices" element={<DeviceTab titleSegments={pageTitleSegments} barn={barn} barnId={barnId} />} />
        <Route path="bins" element={<BinTab titleSegments={pageTitleSegments} barn={barn} barnId={barnId} />} />
        <Route path="comments" element={<CommentsTab titleSegments={pageTitleSegments} barnId={barnId} />} />
        <Route
          path="line/:lineId"
          element={<FeedLinePage titleSegments={pageTitleSegments} pageClass="no-margin" hideBarnName={true} />}
        />
        <Route path="calibrations">
          <Route index element={<CalibrationTab titleSegments={pageTitleSegments} barnId={barnId} />} />
          <Route path=":calibrationId">
            <Route index element={<CreateCalibration barnId={barnId} />} />
            <Route path="setup" element={<CreateCalibration barnId={barnId} />} />
            <Route path="test" element={<CalibrationResultView barnId={barnId} />} />
            <Route path="result" element={<CalibrationResultView barnId={barnId} />} />
          </Route>
        </Route>

        <Route path="" element={<Navigate to="summary" replace={true} />} />
      </Routes>
    </Page>
  );
}
BarnPage.propTypes = {
  className: PropTypes.string,
  tabsClassName: PropTypes.string,
  barnIdOverride: PropTypes.string,
  openFaultIds: PropTypes.arrayOf(PropTypes.string),
  visibleFeedLineIds: PropTypes.arrayOf(PropTypes.string),
  dateRangeOverride: PropTypes.shape({
    from: PropTypes.instanceOf(Dayjs).isRequired,
    to: PropTypes.instanceOf(Dayjs).isRequired,
  }),
};
export default BarnPage;
