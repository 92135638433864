/* eslint-disable no-bitwise */
/* eslint-disable no-restricted-syntax */

import XLSX from 'xlsx';
import dayjs from 'dayjs';
import { TIME_FORMAT_UTC_OFFSET_TIMEZONE, DATE_TIME_SLASH } from '../dates';

class XLSXFaultCodeStrategy {
  constructor() {
    this.farmMap = {};
    this.lineMap = {};

    const timeZoneString = dayjs.tz().format(TIME_FORMAT_UTC_OFFSET_TIMEZONE);
    this.sheetRows = [
      [
        'Farm Name',
        'Line Name',
        `Start ${timeZoneString} (${DATE_TIME_SLASH.toLowerCase()})`,
        `End ${timeZoneString} (${DATE_TIME_SLASH.toLowerCase()})`,
        'Start Epoch (s)',
        'End Epoch (s)',
        'Fault Type',
      ],
    ];
  }

  setFarmObjects = (farms) => {
    farms.forEach((f) => {
      this.farmMap[f.id] = f.name;
      f.feed_lines.forEach((l) => {
        this.lineMap[l.id] = l.name;
      });
    });
  };

  processFaultCodes = (farmId, lineId, dataArray) => {
    const farmName = this.farmMap[farmId];
    const lineName = this.lineMap[lineId];
    dataArray.forEach((d) => {
      this.sheetRows.push([
        farmName,
        lineName,
        dayjs.tz(1000 * d.s).format(DATE_TIME_SLASH),
        d.e !== null ? dayjs.tz(1000 * d.e).format(DATE_TIME_SLASH) : '',
        d.s,
        d.e !== null ? d.e : '',
        d.faultText,
      ]);
    });
  };

  createFile = () => {
    const s2ab = (s) => {
      if (typeof ArrayBuffer !== 'undefined') {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      const buf = new Array(s.length);
      for (let i = 0; i !== s.length; i += 1) buf[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };

    const workbook = XLSX.utils.book_new();
    const sheetData = XLSX.utils.aoa_to_sheet(this.sheetRows);
    XLSX.utils.book_append_sheet(workbook, sheetData, 'FaultCodes');

    const wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' };
    const wbout = XLSX.write(workbook, wopts);
    return new Blob([s2ab(wbout)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  };
}
export default XLSXFaultCodeStrategy;
