import { CalendarMode } from '../../organisms/Calendar';
import { TripStatus } from './enums';
import {
  orderRecommended,
  orderScheduled,
  orderShipped,
  orderDelivered,
  orderCancelled,
  tripMixed,
} from '../../colors.module.scss';

export const modeList = [
  {
    name: 'One Week',
    value: CalendarMode.OneWeek,
  },
  {
    name: 'Two Weeks',
    value: CalendarMode.TwoWeeks,
  },
  {
    name: 'Agenda',
    value: CalendarMode.Agenda,
  },
];

export const orderStatusList = [
  {
    name: 'Recommended',
    value: TripStatus.Recommended,
    color: orderRecommended,
  },
  {
    name: 'Scheduled',
    value: TripStatus.Scheduled,
    color: orderScheduled,
  },
  {
    name: 'Shipped',
    value: TripStatus.Shipped,
    color: orderShipped,
  },
  {
    name: 'Delivered',
    value: TripStatus.Delivered,
    color: orderDelivered,
  },
  {
    name: 'Cancelled',
    value: TripStatus.Cancelled,
    color: orderCancelled,
  },
];

export const tripStatusList = [
  ...orderStatusList,
  {
    name: 'Mixed',
    value: TripStatus.Mixed,
    color: tripMixed,
  },
];
