import PropTypes from 'prop-types';
import './AlertStatusIndicator.scss';

function AlertStatusIndicator({ status = '' }) {
  return (
    <div className="AlertStatusIndicator">
      <div className={`AlertStatusIndicator-symbol AlertStatusIndicator-symbol--${status}`} />
      <div className="AlertStatusIndicator-text">{status}</div>
    </div>
  );
}

AlertStatusIndicator.propTypes = {
  status: PropTypes.string,
};

export default AlertStatusIndicator;
