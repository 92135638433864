import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ConditionalWrapper from '../ConditionalWrapper';

function Tab({
  index = 0,
  title = '',
  link = null,
  selected = false,
  contentLeft = null,
  contentRight = null,
  content = null,
  onClick = () => {},
}) {
  const linkClass = link ? 'FeedFloTabs-tabLink' : '';
  const selectedClass = selected ? 'selected' : '';
  const className = `FeedFloTabs-tab noselect ${linkClass} ${selectedClass}`;

  const onTabClick = () => {
    onClick(index);
  };

  const renderTabContent = () => {
    return (
      <ConditionalWrapper
        condition={link}
        conditionalWrapper={(children) => (
          <Link to={link} className={className} onClick={onTabClick}>
            {children}
          </Link>
        )}
        defaultWrapper={(children) => (
          <div role="button" tabIndex={100 + index} className={className} onClick={onTabClick}>
            {children}
          </div>
        )}
      >
        <div className="FeedFloTabs-tab-contentLeft">{contentLeft}</div>
        {content ? content : <span className="FeedFloTabs-tab-">{title}</span>}
        <div className="FeedFloTabs-tab-contentRight">{contentRight}</div>
      </ConditionalWrapper>
    );
  };

  return (
    <>
      {renderTabContent()}
      <div className="spacer" />
    </>
  );
}

Tab.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  link: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  content: PropTypes.any,
  contentLeft: PropTypes.any,
  contentRight: PropTypes.any,
};

export default Tab;
