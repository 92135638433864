import { useRef } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE } from '../../utils/dates';

import { convertGramsToLargeUnits, convertLargeUnitsToGrams, weightLargeUnitLabel } from '../../utils/unitConversion';
import useUser from '../../utils/hooks/useUser';
import Button from '../../atoms/Button';
import FeedFloTextInput from '../../atoms/FeedFloTextInput';
import NumberInput from '../../atoms/NumberInput';
import { Formik } from 'formik';
import {
  DeleteBinSetCalibrationMutation,
  InsertBinSetCalibrationMutation,
  UpdateBinSetCalibrationMutation,
} from './queries';
import { toast } from 'react-toastify';
import ConfirmationDialog from '../ConfirmationDialog';
import { exactCalibrationSchema } from './validation';

function BinSetCalibrationDataPane({ binSetCalibrationId, binSetId, isEditing = true, onClose = () => {} }) {
  const { user } = useUser();
  const isMetric = user?.isMetric || false;
  const deleteConfirmationDialogRef = useRef(null);

  const [insertBinSetCalibration] = useMutation(InsertBinSetCalibrationMutation);
  const [updateBinSetCalibration] = useMutation(UpdateBinSetCalibrationMutation);
  const [deleteBinSetCalibration] = useMutation(DeleteBinSetCalibrationMutation);

  const { loading, data } = useQuery(
    gql`
      query BinSetCalibrationDataPane_BinSetCalibration($id: uuid!) {
        bin_set_calibration_by_pk(id: $id) {
          id
          started_at
          ended_at
          provided_mass_in_grams
          status
          displayed_calibration_step
          bin_set {
            id
            bins {
              id
              name
            }
          }
        }
      }
    `,
    { variables: { id: binSetCalibrationId, binSetId: binSetId }, fetchPolicy: 'network-only' },
  );

  const onDeleteConfirmationCancelClick = () => {
    if (!deleteConfirmationDialogRef?.current) {
      return;
    }

    deleteConfirmationDialogRef.current.close();
  };

  const onDeleteConfirmationConfirmClick = () => {
    deleteBinSetCalibration({
      variables: {
        id: binSetCalibrationId,
        deleted_at: dayjs().unix(),
      },
      refetchQueries: ['CalibrationCard_GetBinSetCalibrations'],
      onCompleted: () => {
        toast('Bin Check Deleted', {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
        });
        onClose();
      },
    });
  };

  const onDelete = () => {
    if (!deleteConfirmationDialogRef?.current) {
      return;
    }

    deleteConfirmationDialogRef.current.showModal();
  };
  const onSubmit = (values) => {
    if (isEditing) {
      updateBinSetCalibration({
        variables: {
          id: binSetCalibrationId,
          started_at: dayjs(values.startedAtText).unix(),
          ended_at: dayjs(values.endedAtText).unix(),
          provided_mass_in_grams: Math.round(convertLargeUnitsToGrams(isMetric, values.providedMassInLargeUnits)),
        },
        refetchQueries: ['CalibrationCard_GetBinSetCalibrations'],
        onCompleted: () => {
          toast('Bin Set Calibration Updated', {
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
          });
          onClose();
        },
      });
    } else {
      insertBinSetCalibration({
        variables: {
          object: {
            started_at: dayjs(values.startedAtText).unix(),
            ended_at: dayjs(values.endedAtText).unix(),
            provided_mass_in_grams: Math.round(convertLargeUnitsToGrams(isMetric, values.providedMassInLargeUnits)),
            bin_set_id: binSetId,
            status: 'calculating',
            type: 'calibration',
            displayed_calibration_step: 'empty_bin_for_test',
          },
        },
        refetchQueries: ['CalibrationCard_GetBinSetCalibrations'],
        onCompleted: () => {
          toast('Bin Set Calibration Created', {
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
          });
          onClose();
        },
      });
    }
  };

  const binSetCalibration = data?.bin_set_calibration_by_pk;
  return (
    <>
      <div className="BinSetCalibrationDataPane-body">
        <Formik
          enableReinitialize={true}
          initialValues={{
            startedAtText: binSetCalibration?.started_at
              ? dayjs(binSetCalibration?.started_at * 1000).format(DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE)
              : dayjs().format(DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE),
            endedAtText: binSetCalibration?.ended_at
              ? dayjs(binSetCalibration?.ended_at * 1000).format(DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE)
              : dayjs().format(DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE),
            providedMassInLargeUnits: convertGramsToLargeUnits(isMetric, binSetCalibration?.provided_mass_in_grams),
            source: binSetCalibration?.source,
            method: binSetCalibration?.method,
            comment: binSetCalibration?.comment,
          }}
          validationSchema={exactCalibrationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, setFieldValue, values, errors }) => {
            const onStartedAtChanged = (e) => {
              setFieldValue('startedAtText', e);
            };
            const onEndedAtChanged = (e) => {
              setFieldValue('endedAtText', e);
            };

            const onProvidedMassChanged = (e) => {
              setFieldValue('providedMassInLargeUnits', e.target.value);
            };

            return (
              <>
                <FeedFloTextInput
                  type="datetime-local"
                  label="Started At"
                  disabled={loading}
                  text={values.startedAtText}
                  onChange={onStartedAtChanged}
                  isValid={!errors?.startedAtText}
                  description={errors?.startedAtText}
                />
                <FeedFloTextInput
                  type="datetime-local"
                  label="Ended At"
                  disabled={loading}
                  text={values.endedAtText}
                  onChange={onEndedAtChanged}
                  isValid={!errors?.endedAtText}
                  description={errors?.endedAtText}
                />
                <NumberInput
                  label={`Feed Moved (in ${weightLargeUnitLabel(isMetric)}s)`}
                  value={values.providedMassInLargeUnits}
                  min={0.0}
                  max={250}
                  step={0.01}
                  onChange={onProvidedMassChanged}
                  disabled={loading}
                  isValid={!errors?.providedMassInLargeUnits}
                  description={errors?.providedMassInLargeUnits}
                />

                <div className="RightSidebar-footer">
                  <Button
                    content="Cancel"
                    onClick={() => {
                      onClose();
                    }}
                  />
                  <Button
                    loading={loading}
                    disabled={loading}
                    variant="vivid"
                    color="success"
                    content={isEditing ? 'Update' : 'Create'}
                    onClick={handleSubmit}
                  />
                  {isEditing && (
                    <Button
                      loading={loading}
                      disabled={loading}
                      variant="pastel"
                      color="danger"
                      content={'Delete'}
                      onClick={onDelete}
                    />
                  )}
                </div>
                <ConfirmationDialog
                  dialogRef={deleteConfirmationDialogRef}
                  message={'Are you sure you want to delete\nthis Calibration?'}
                  onCancel={onDeleteConfirmationCancelClick}
                  onConfirm={onDeleteConfirmationConfirmClick}
                />
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
}
BinSetCalibrationDataPane.propTypes = {
  binSetCalibrationId: PropTypes.string,
  binSetId: PropTypes.string,
  onClose: PropTypes.func,
  isEditing: PropTypes.bool,
};

export default BinSetCalibrationDataPane;
