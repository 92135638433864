import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { CalibrationStatus, DisplayedCalibrationStep } from '../../utils/enums';
import CreateCalibrationView from './CreateCalibrationView';
import LoadingView from '../../atoms/LoadingView/LoadingView';

const INSERT_CALIBRATION_GQL = gql`
  mutation AddBinSetCalibration($objects: [bin_set_calibration_insert_input!]!) {
    insert_bin_set_calibration(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

const GET_CALIBRATIONS_GQL = gql`
  query CalibrationQuery($calibrationId: uuid!) {
    bin_set_calibration(where: { deleted_at: { _is_null: true }, id: { _eq: $calibrationId } }) {
      displayed_calibration_step
      bin_set_id
      provided_mass_in_grams
      started_at
      ended_at
      status
    }
  }
`;

const GET_UNIQUE_BIN_SETS_GQL = gql`
  query BinSetQuery($barnId: uuid!) {
    bin_set(distinct_on: id, where: { deleted_at: { _is_null: true }, farm_id: { _eq: $barnId } }) {
      id
      bins {
        name
      }
    }
  }
`;

const SAVE_CALIBRATION_GQL = gql`
  mutation SaveCalibrationMutation(
    $calibrationId: uuid!
    $binSetId: uuid!
    $selectedStep: String!
    $startedAt: bigint!
    $endedAt: bigint!
    $knownWeight: bigint!
    $status: String!
  ) {
    update_bin_set_calibration(
      where: { id: { _eq: $calibrationId } }
      _set: {
        displayed_calibration_step: $selectedStep
        bin_set_id: $binSetId
        provided_mass_in_grams: $knownWeight
        started_at: $startedAt
        ended_at: $endedAt
        status: $status
      }
    ) {
      affected_rows
    }
  }
`;

function CreateCalibration({ barnId = '' }) {
  const navigate = useNavigate();
  const { calibrationId: calibrationIdParam } = useParams();
  const [calibrationId, setCalibrationId] = useState(calibrationIdParam || '');
  const [showLoader, setShowLoader] = useState(true);
  const isNewCalibration = calibrationId.toString() === 'new';
  const [getBinSets, { data: binSetData, loading: binSetLoading }] = useLazyQuery(GET_UNIQUE_BIN_SETS_GQL);
  const [insertCalibration] = useMutation(INSERT_CALIBRATION_GQL);
  const [saveCalibration] = useMutation(SAVE_CALIBRATION_GQL);
  const getCalibrationQueryResponse = useQuery(GET_CALIBRATIONS_GQL, {
    variables: {
      calibrationId,
    },
    skip: isNewCalibration,
    onError: (error) => console.error(error),
  });
  const steps = [
    DisplayedCalibrationStep.EmptyBinForTest,
    DisplayedCalibrationStep.DeliverFeed,
    DisplayedCalibrationStep.RecordStartTime,
    DisplayedCalibrationStep.RunBinUntilEmpty,
    DisplayedCalibrationStep.CalculateResults,
  ];
  let initialStepDisplayed = 1;

  if (!isNewCalibration) {
    initialStepDisplayed =
      steps.indexOf(getCalibrationQueryResponse?.data?.bin_set_calibration[0]?.displayed_calibration_step) + 1;
  }

  useEffect(() => {
    // Get the bin sets only when the component mounts
    getBinSets({
      variables: {
        barnId,
      },
      onError: (error) => console.error(error),
    });

    // To show the loading screen for at least 2 seconds on mount so it does not look jumpy
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  // If the page is finished loading AND we have fetched calibration information then check if we need to redirect
  // need to check both conditions to avoid memory leaks
  useEffect(() => {
    if (getCalibrationQueryResponse) {
      redirectBasedOnStatus();
    }
  }, [showLoader]);

  useEffect(() => {
    if (!showLoader) {
      redirectBasedOnStatus();
    }
  }, [getCalibrationQueryResponse]);

  // If the status of this calibration is not form_started then redirect to the appropriate location
  const redirectBasedOnStatus = () => {
    if (
      getCalibrationQueryResponse?.data?.bin_set_calibration?.[0].status === CalibrationStatus.FormSubmitted ||
      getCalibrationQueryResponse?.data?.bin_set_calibration?.[0].status === CalibrationStatus.Calculating
    ) {
      navigate(`/b/${barnId}/calibrations/${calibrationId}/test`);
    } else if (getCalibrationQueryResponse?.data?.bin_set_calibration?.[0].status === CalibrationStatus.Complete) {
      navigate(`/b/${barnId}/calibrations/${calibrationId}/result`);
    }
  };

  const onSave = (step, binSetId, knownWeight, startedAt, endedAt, status) => {
    if (binSetId && isNewCalibration) {
      insertCalibration({
        variables: {
          objects: [
            {
              bin_set_id: binSetId,
              started_at: startedAt,
              ended_at: endedAt,
              provided_mass_in_grams: knownWeight,
              status,
            },
          ],
        },
        refetchQueries: ['CalibrationsTab_GetBinSetCalibrationsQuery'],
        onError: (error) => console.error(error),
        onCompleted: (data) => {
          setCalibrationId(data?.insert_bin_set_calibration?.returning?.[0]?.id);
        },
      });
    } else if (!isNewCalibration) {
      saveCalibration({
        variables: {
          selectedStep: steps[step],
          calibrationId,
          binSetId,
          knownWeight,
          startedAt,
          endedAt,
          status,
        },
        refetchQueries: ['CalibrationsTab_GetBinSetCalibrationsQuery'],
        onError: (error) => console.error(error),
      }).then(() => {
        if (status === CalibrationStatus.FormSubmitted) {
          navigate(`/b/${barnId}/calibrations/${calibrationId}/test`);
        }
      });
    }
  };

  if (getCalibrationQueryResponse?.loading || binSetLoading || showLoader) {
    return (
      <div className="CreateCalibrationView--loader">
        <LoadingView />
      </div>
    );
  }

  return (
    <CreateCalibrationView
      barnId={barnId}
      initialStep={initialStepDisplayed}
      calibrationInformation={getCalibrationQueryResponse?.data?.bin_set_calibration?.[0]}
      uniqueBinSets={binSetData?.bin_set}
      onSave={onSave}
    />
  );
}

CreateCalibration.propTypes = {
  barnId: PropTypes.string,
};

export default CreateCalibration;
