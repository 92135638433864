import { useAtomValue } from 'jotai';
import { algorithmVersionAtom } from './jotaiAtoms';

function useFeedFrameFilter(overrides = {}) {
  const defaultFilter = {
    deleted_at: { _is_null: true },
  };

  return { ...defaultFilter, ...overrides };
}

function useAnalysisFilter(overrides = {}) {
  const algorithmVersion = useAtomValue(algorithmVersionAtom);

  if (!algorithmVersion) {
    // If we're not ready yet return null.
    // All of the places that use this were updated to skip if null
    return null;
  }

  const defaultFilter = {
    deleted_at: { _is_null: true },
    algorithm_version: { _eq: algorithmVersion },
  };

  return { ...defaultFilter, ...overrides };
}

export { useFeedFrameFilter, useAnalysisFilter };
