export const PurposeEnum = {
  Test: 'test',
  Reset: 'reset',
  Calibrate: 'calibrate',
  Other: 'other',
};

export const SourceEnum = {
  Farmer: 'farmer',
};

export const MethodEnum = {
  BangCheck: 'bang_check',
  BinClimb: 'bin_climb',
  Empty: 'empty',
  Overfill: 'overfill',
  Other: 'other',
};

export const MethodEnumConverter = {
  [MethodEnum.BangCheck]: 'Bang Check',
  [MethodEnum.BinClimb]: 'Bin Climb',
  [MethodEnum.Empty]: 'Empty',
  [MethodEnum.Overfill]: 'Overfill',
  [MethodEnum.Other]: 'Other',
};

export const PurposeConverter = {
  [PurposeEnum.Test]: 'Test',
  [PurposeEnum.Reset]: 'Reset',
  [PurposeEnum.Calibrate]: 'Calibrate',
  [PurposeEnum.Other]: 'Other',
};
