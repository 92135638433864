import { gql } from '@apollo/client';

export const InsertBinSetLevelMutation = gql`
  mutation BinCalibration_InsertBinSetLevel($object: bin_set_level_insert_input!) {
    insert_bin_set_level_one(object: $object) {
      id
    }
  }
`;

export const UpdateBinSetLevelMutation = gql`
  mutation BinCalibration_UpdateBinSetLevel(
    $id: uuid!
    $valid_at: bigint!
    $level_in_grams: Int!
    $source: String!
    $method: String!
    $purpose: bin_check_purpose_enum!
    $expected_deviation_in_grams: Int!
    $comment: String!
  ) {
    update_bin_set_level_by_pk(
      pk_columns: { id: $id }
      _set: {
        valid_at: $valid_at
        level_in_grams: $level_in_grams
        source: $source
        method: $method
        purpose: $purpose
        expected_deviation_in_grams: $expected_deviation_in_grams
        comment: $comment
      }
    ) {
      id
    }
  }
`;

export const DeleteBinSetLevelMutation = gql`
  mutation BinCalibration_DeleteBinSetLevel($id: uuid!, $deleted_at: bigint!) {
    update_bin_set_level_by_pk(pk_columns: { id: $id }, _set: { deleted_at: $deleted_at }) {
      id
    }
  }
`;

export const InsertBinSetCalibrationMutation = gql`
  mutation BinCalibration_InsertBinSetCalibration($object: bin_set_calibration_insert_input!) {
    insert_bin_set_calibration_one(object: $object) {
      id
    }
  }
`;

export const UpdateBinSetCalibrationMutation = gql`
  mutation BinCalibration_UpdateBinSetCalibration(
    $id: uuid!
    $started_at: bigint!
    $ended_at: bigint!
    $provided_mass_in_grams: bigint!
  ) {
    update_bin_set_calibration_by_pk(
      pk_columns: { id: $id }
      _set: {
        started_at: $started_at
        ended_at: $ended_at
        provided_mass_in_grams: $provided_mass_in_grams
        type: "calibration"
      }
    ) {
      id
    }
  }
`;

export const DeleteBinSetCalibrationMutation = gql`
  mutation BinCalibration_DeleteBinSetCalibration($id: uuid!, $deleted_at: bigint!) {
    update_bin_set_calibration_by_pk(pk_columns: { id: $id }, _set: { deleted_at: $deleted_at }) {
      id
    }
  }
`;
