import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT_HUGE } from '../../utils/dates';
import useSignalDataSeries from '../../utils/hooks/useSignalDataSeries';
import FeedFloLabChart from '../../molecules/FeedFloLabsChart';
import { useFeedFrameFilter } from '../../utils/useFeedFrameFilter';
import { getFaultName } from '@norimaconsulting/fault-codes';

const EVENT_HEIGHT = -10;

const DEVICE_INFO_GQL = gql`
  query DeviceInfoQuery($deviceId: uuid) {
    device(where: { id: { _eq: $deviceId } }) {
      serial
    }
  }
`;
const FEED_FRAME_GQL = gql`
  query FeedFrameQuery($feedFrameWhere: feed_frame_bool_exp) {
    feed_frame(where: $feedFrameWhere, order_by: { started_at: asc }) {
      started_at
      ended_at
    }
  }
`;

const EVENTS_GQL = gql`
  query EventQuery($deviceId: uuid, $start: bigint, $end: bigint) {
    fault(
      where: {
        device_id: { _eq: $deviceId }
        started_at: { _lte: $end }
        _or: [{ ended_at: { _gte: $start } }, { ended_at: { _is_null: true }, started_at: { _gte: $start } }]
        code: { _nin: [2002] }
      }
      order_by: { started_at: asc }
    ) {
      code
      started_at
      ended_at
    }
  }
`;
export default function SensorChart({
  deviceId,
  to,
  from,
  data = [],
  showEvents = true,
  showFeedFrames = true,
  showSensorData = true,
}) {
  const { loading: deviceDataLoading, data: deviceData } = useQuery(DEVICE_INFO_GQL, {
    variables: {
      deviceId,
    },
  });

  ///////////////////////////////////
  ///         Signal Data         ///
  ///////////////////////////////////
  const {
    loading: signalLoading,
    data: signalData,
    error: errorSignal,
  } = useSignalDataSeries({
    deviceId,
    to: to,
    from: from,
    skip: !showSensorData,
  });
  if (signalData?.length > 0) data.push(...signalData);

  ///////////////////////////////////
  ///         Feed Frames         ///
  ///////////////////////////////////
  const feedFrameFilter = useFeedFrameFilter({
    device_id: { _eq: deviceId },
    started_at: { _lte: to.unix() },
    ended_at: { _gte: from.unix() },
  });

  const { loading: framesLoadings, data: feedFrameData } = useQuery(FEED_FRAME_GQL, {
    skip: !showFeedFrames,
    variables: {
      feedFrameWhere: feedFrameFilter,
      deviceId,
      end: to.unix(),
      start: from.unix(),
    },
  });

  const frameSeries = useMemo(() => {
    if (!feedFrameData?.feed_frame) return null;
    const series = {
      type: 'scatter',
      name: 'Feed Frame',
      mode: 'lines+markers',
      visible: true,
      line: {
        width: 2,
      },
      marker: { size: 8 },
      y: [],
      x: [],
    };
    feedFrameData?.feed_frame.forEach((ff) => {
      series.x.push(new Date(ff?.started_at * 1000));
      series.x.push(new Date(ff?.ended_at * 1000));
      series.x.push(null);

      series.y.push(EVENT_HEIGHT, EVENT_HEIGHT, EVENT_HEIGHT);
    });

    return series;
  }, [feedFrameData?.feed_frame]);

  if (frameSeries) data.push(frameSeries);

  ///////////////////////////////////
  ///         Device EVENTS       ///
  ///////////////////////////////////
  const { loading: eventsLoading, data: eventData } = useQuery(EVENTS_GQL, {
    skip: !showEvents,
    variables: showEvents
      ? {
          deviceId,
          start: from.unix(),
          end: to.unix(),
        }
      : null,
  });

  const safeGetFaultName = (code) => {
    try {
      return getFaultName(code);
    } catch (error) {
      return code;
    }
  };

  const eventSeries = useMemo(() => {
    const eventSeriesMap = {};
    eventData?.fault.forEach((fault) => {
      if (!eventSeriesMap[fault.code])
        eventSeriesMap[fault.code] = {
          type: 'scatter',
          name: safeGetFaultName(fault.code),
          mode: 'lines+markers',
          marker: { size: 8 },
          visible: 'legendonly',
          line: {
            width: 2,
          },
          y: [],
          x: [],
        };
      eventSeriesMap[fault.code].x.push(new Date(fault?.started_at * 1000));
      if (fault?.ended_at) eventSeriesMap[fault.code].x.push(new Date(fault?.ended_at * 1000));
      eventSeriesMap[fault.code].x.push(null);

      eventSeriesMap[fault.code].y.push(EVENT_HEIGHT, EVENT_HEIGHT);
      if (fault?.ended_at) eventSeriesMap[fault.code].y.push(EVENT_HEIGHT);
    });

    return Object.values(eventSeriesMap);
  }, [eventData?.fault]);

  if (eventSeries?.length > 0) data.push(...eventSeries);

  const layout = useMemo(() => {
    return {
      title: {
        text: `<b>Sensor Data</b> ${deviceData?.device?.[0]?.serial} <br>${dayjs(from).format(
          DATE_TIME_FORMAT_HUGE,
        )}<br>${dayjs(to).format(DATE_TIME_FORMAT_HUGE)}`,
      },
      xaxis: { range: [new Date(from), new Date(to)] },
      yaxis: { range: [-15, 150] },
      yaxis2: {
        title: '',
        overlaying: 'y',
        side: 'right',
        range: [12_000_000, 28_000_000],
      },
    };
  }, [deviceData, to, from]);

  if (showSensorData && errorSignal) {
    return <div>Error {JSON.stringify(errorSignal)}</div>;
  }

  return (
    <FeedFloLabChart
      loading={deviceDataLoading || signalLoading || framesLoadings || eventsLoading}
      data={data}
      layout={layout}
    />
  );
}

SensorChart.propTypes = {
  deviceId: PropTypes.string,
  to: PropTypes.object,
  from: PropTypes.object,
  data: PropTypes.array,
  showEvents: PropTypes.bool,
  showSensorData: PropTypes.bool,
  showFeedFrames: PropTypes.bool,
};
