import { useRef } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE } from '../../utils/dates';

import { convertGramsToLargeUnits, convertLargeUnitsToGrams, weightLargeUnitLabel } from '../../utils/unitConversion';
import useUser from '../../utils/hooks/useUser';
import Button from '../../atoms/Button';
import Select from '../../atoms/Select';
import FeedFloTextInput from '../../atoms/FeedFloTextInput';
import NumberInput from '../../atoms/NumberInput';
import TextArea from '../../atoms/TextArea';
import { SourceEnum, MethodEnum } from '../../organisms/BinCheckDialog/enums';
import { purposeList, methodList } from '../../organisms/BinCheckDialog/lists';
import { Formik } from 'formik';
import { DeleteBinSetLevelMutation, InsertBinSetLevelMutation, UpdateBinSetLevelMutation } from './queries';
import { toast } from 'react-toastify';
import ConfirmationDialog from '../ConfirmationDialog';
import { binCheckSchema } from './validation';

function BinCheckDataPane({ binSetLevelId, binSetId, isEditing = true, onClose = () => {}, numBins = 2 }) {
  const { user } = useUser();
  const isMetric = user?.isMetric || false;
  const deleteConfirmationDialogRef = useRef(null);

  const [insertBinSetLevel] = useMutation(InsertBinSetLevelMutation);
  const [updateBinSetLevel] = useMutation(UpdateBinSetLevelMutation, {
    refetchQueries: ['BinCheckDataPane_BinLevel', 'BinCalibration_BinSetLevelQuery'],
  });
  const [deleteBinSetLevel] = useMutation(DeleteBinSetLevelMutation);

  const { loading, data } = useQuery(
    gql`
      query BinCheckDataPane_BinLevel($id: uuid!) {
        bin_set_level_by_pk(id: $id) {
          id
          bin_set_id
          valid_at
          level_in_grams
          source
          method
          purpose
          comment
          bin_set {
            id
            bins {
              id
              name
            }
          }
        }
      }
    `,
    { variables: { id: binSetLevelId, binSetId: binSetId }, fetchPolicy: 'network-only' },
  );

  const calculateErrorInGrams = (method) => {
    const numBinsInBinSet = numBins;
    const errorRate = convertLargeUnitsToGrams(isMetric, 1.5); // Every method other than empty has a hardcoded rate of 1.5 for now

    switch (method) {
      case MethodEnum.Empty:
        return 0;
      default:
        return numBinsInBinSet * errorRate;
    }
  };

  const onDeleteConfirmationCancelClick = () => {
    if (!deleteConfirmationDialogRef?.current) {
      return;
    }

    deleteConfirmationDialogRef.current.close();
  };

  const onDeleteConfirmationConfirmClick = () => {
    deleteBinSetLevel({
      variables: {
        id: data.bin_set_level_by_pk.id,
        deleted_at: dayjs().unix(),
      },
      refetchQueries: [
        'BinCalibration_BinChecks',
        'BinCalibration_BinSetLevelQuery',
        'CalibrationCard_GetBinSetCalibrations',
        'BinInventory_BinSetLevelQuery',
      ],
      onCompleted: () => {
        toast('Bin Check Deleted', {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
        });
        onClose();
      },
    });
  };

  const onDelete = () => {
    if (!deleteConfirmationDialogRef?.current) {
      return;
    }

    deleteConfirmationDialogRef.current.showModal();
  };
  const onSubmit = (values) => {
    if (isEditing) {
      updateBinSetLevel({
        variables: {
          id: data.bin_set_level_by_pk.id,
          valid_at: dayjs(values.validAtText).unix(),
          level_in_grams: Math.round(convertLargeUnitsToGrams(isMetric, values.levelInLargeUnits)),
          bin_set_id: data.bin_set_level_by_pk.bin_set_id,
          source: SourceEnum.Farmer,
          method: values.method,
          purpose: values.purpose,
          expected_deviation_in_grams: calculateErrorInGrams(values.method),
          comment: values.comment,
        },
        refetchQueries: [
          'BinCalibration_BinChecks',
          'BinCalibration_BinSetLevelQuery',
          'CalibrationCard_GetBinSetCalibrations',
          'BinInventory_BinSetLevelQuery',
        ],
        onCompleted: () => {
          toast('Bin Check Updated', {
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
          });
          onClose();
        },
      });
    } else {
      insertBinSetLevel({
        variables: {
          object: {
            valid_at: dayjs(values.validAtText).unix(),
            level_in_grams: Math.round(convertLargeUnitsToGrams(isMetric, values.levelInLargeUnits)),
            bin_set_id: binSetId,
            source: SourceEnum.Farmer,
            method: values.method,
            purpose: values.purpose,
            expected_deviation_in_grams: calculateErrorInGrams(values.method),
            comment: values.comment,
          },
        },
        refetchQueries: [
          'BinCalibration_BinChecks',
          'BinCalibration_BinSetLevelQuery',
          'CalibrationCard_GetBinSetCalibrations',
          'BinInventory_BinSetLevelQuery',
        ],
        onCompleted: () => {
          toast('Bin Check Inserted', {
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
          });
          onClose();
        },
      });
    }
  };

  const binSet = data?.bin_set_level_by_pk;
  return (
    <>
      <div className="BinCheckDataPane-body">
        <Formik
          enableReinitialize={true}
          validationSchema={binCheckSchema}
          initialValues={{
            purpose: binSet?.purpose,
            validAtText: binSet?.valid_at
              ? dayjs(binSet?.valid_at * 1000).format(DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE)
              : dayjs().format(DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE),
            levelInLargeUnits: convertGramsToLargeUnits(isMetric, binSet?.level_in_grams),
            source: binSet?.source,
            method: binSet?.method,
            comment: binSet?.comment,
          }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, setFieldValue, values, errors }) => {
            const onPurposeChange = (e) => {
              setFieldValue('purpose', e.target.value);
            };

            const onValidAtChanged = (e) => {
              setFieldValue('validAtText', e);
            };

            const onLevelInLargeUnitsChanged = (e) => {
              setFieldValue('levelInLargeUnits', e.target.value);
            };

            const onMethodChange = (e) => {
              setFieldValue('method', e.target.value);
            };

            const onCommentChange = (e) => {
              setFieldValue('comment', e.target.value);
            };

            return (
              <>
                <Select
                  inputRef={null}
                  disabled={loading}
                  label="Purpose"
                  defaultText="Select a purpose"
                  itemList={purposeList}
                  value={values.purpose}
                  onChange={onPurposeChange}
                  isValid={!errors?.purpose}
                  description={errors?.purpose}
                />
                <FeedFloTextInput
                  type="datetime-local"
                  label="Checked At"
                  disabled={loading}
                  text={values.validAtText}
                  onChange={onValidAtChanged}
                  isValid={!errors?.validAtText}
                  description={errors?.validAtText}
                />
                <NumberInput
                  label={`Feed Level (in ${weightLargeUnitLabel(isMetric)}s)`}
                  value={values.levelInLargeUnits}
                  min={0.0}
                  max={250}
                  step={0.01}
                  onChange={onLevelInLargeUnitsChanged}
                  disabled={loading}
                  isValid={!errors?.levelInLargeUnits}
                  description={errors?.levelInLargeUnits}
                />
                <Select
                  label="Method"
                  defaultText="Select a method"
                  itemList={methodList}
                  disabled={loading}
                  value={values?.method}
                  onChange={onMethodChange}
                  isValid={!errors?.method}
                  description={errors?.method}
                />
                <TextArea label="Notes" value={values?.comment} onChange={onCommentChange} disabled={loading} />

                <div className="RightSidebar-footer">
                  <Button
                    content="Cancel"
                    onClick={() => {
                      onClose();
                    }}
                  />
                  <Button
                    loading={loading}
                    disabled={loading}
                    variant="vivid"
                    color="success"
                    content={isEditing ? 'Update' : 'Create'}
                    onClick={handleSubmit}
                  />
                  {isEditing && (
                    <Button
                      loading={loading}
                      disabled={loading}
                      variant="pastel"
                      color="danger"
                      content={'Delete'}
                      onClick={onDelete}
                    />
                  )}
                </div>
                <ConfirmationDialog
                  dialogRef={deleteConfirmationDialogRef}
                  message={'Are you sure you want to delete\nthis Bin Set Level?'}
                  onCancel={onDeleteConfirmationCancelClick}
                  onConfirm={onDeleteConfirmationConfirmClick}
                />
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
}
BinCheckDataPane.propTypes = {
  binSetLevelId: PropTypes.string,
  binSetId: PropTypes.string,
  onClose: PropTypes.func,
  isEditing: PropTypes.bool,
  numBins: PropTypes.number,
};

export default BinCheckDataPane;
