import PropTypes from 'prop-types';
import ChangePercentageBadge from '../ChangePercentageBadge';
import './StatTab.scss';

function StatTab({
  title = '',
  tabKey = '',
  formattedValue = '',
  value = 0,
  previousValue = 0,
  isSelected = false,
  onClick = () => {},
}) {
  const onTabClick = () => {
    onClick(tabKey);
  };

  return (
    <div className={`StatTab ${isSelected ? 'StatTab--selected' : ''}`} onClick={onTabClick}>
      <div className="StatTab-title">{title}</div>
      <div className="StatTab-content">
        <div className="StatTab-value">{formattedValue}</div>
        <ChangePercentageBadge value={value} previousValue={previousValue} />
      </div>
    </div>
  );
}

StatTab.propTypes = {
  title: PropTypes.string,
  tabKey: PropTypes.string,
  formattedValue: PropTypes.string,
  value: PropTypes.number,
  previousValue: PropTypes.number,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default StatTab;
