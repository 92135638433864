import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';
import PropTypes from 'prop-types';

import Button from '../../../atoms/Button';
import { XIcon } from '../../../atoms/Icons';
import useUser from '../../../utils/hooks/useUser';

import { DATE_FORMAT_DASH } from '../../../utils/dates';
import { ANIMAL_GROUP_EDIT_UPDATE, ANIMAL_GROUP_ENDED_AT_UPDATE } from './queries.js';
import './AnimalGroupDetails.scss';

const END_DATE_INPUT = 'AnimalGroupDetails:endDate';

const TOAST_CONTAINER_ID = 'AnimalGroupDetailsToastContainer';

function AnimalGroupDetails({
  birthDateSeconds = null,
  endedAtSeconds = null,
  expectedEndedAtSeconds = null,
  externalId = null,
  id = null,
  startedAtSeconds = null,
  loadAnimalGroup = () => null,
  close,
}) {
  const { user } = useUser();

  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const [birthDateInput, setBirthDateInput] = useState(
    birthDateSeconds ? dayjs.tz(1000 * birthDateSeconds).format(DATE_FORMAT_DASH) : '',
  );
  const [endDateInput, setEndDateInput] = useState(
    endedAtSeconds ? dayjs.tz(1000 * endedAtSeconds).format(DATE_FORMAT_DASH) : '',
  );
  const [expectedEndDateInput, setExpectedEndDateInput] = useState(
    expectedEndedAtSeconds ? dayjs.tz(1000 * expectedEndedAtSeconds).format(DATE_FORMAT_DASH) : '',
  );
  const [externalIdInput, setExternalIdInput] = useState(externalId || '');
  const [startDateInput, setStartDateInput] = useState(
    startedAtSeconds ? dayjs.tz(1000 * startedAtSeconds).format(DATE_FORMAT_DASH) : '',
  );

  const [endDateValue, setEndDateValue] = useState(dayjs.tz().format(DATE_FORMAT_DASH));

  useEffect(() => {
    // Dialog components are created along with the parent, and stay open for its lifetime.
    // Because this dialog is meant to serve multiple animal groups, this effect resets state when group changes.

    setEditing(false);
    setSaving(false);

    setBirthDateInput(birthDateSeconds ? dayjs.tz(1000 * birthDateSeconds).format(DATE_FORMAT_DASH) : '');
    setEndDateInput(endedAtSeconds ? dayjs.tz(1000 * endedAtSeconds).format(DATE_FORMAT_DASH) : '');
    setExpectedEndDateInput(
      expectedEndedAtSeconds ? dayjs.tz(1000 * expectedEndedAtSeconds).format(DATE_FORMAT_DASH) : '',
    );
    setExternalIdInput(externalId || '');
    setStartDateInput(startedAtSeconds ? dayjs.tz(1000 * startedAtSeconds).format(DATE_FORMAT_DASH) : '');

    setEndDateValue(dayjs.tz().format(DATE_FORMAT_DASH));
  }, [birthDateSeconds, endedAtSeconds, expectedEndedAtSeconds, externalId, startedAtSeconds]);

  const changeBirthDateInput = useCallback((event) => setBirthDateInput(event.target.value), []);
  const changeEndDateInput = useCallback((event) => setEndDateInput(event.target.value), []);
  const changeExpectedEndDateInput = useCallback((event) => setExpectedEndDateInput(event.target.value), []);
  const changeExternalIdInput = useCallback((event) => setExternalIdInput(event.target.value), []);
  const changeStartDateInput = useCallback((event) => setStartDateInput(event.target.value), []);

  const changeEndDate = useCallback((event) => setEndDateValue(event.target.value), []);

  const [updateAnimalGroupEdit] = useMutation(ANIMAL_GROUP_EDIT_UPDATE, {
    onCompleted: (response) => {
      if (response?.update_animal_group_by_pk) {
        const {
          approximate_birthdate: birthDate,
          ended_at: endedAt,
          expected_ended_at: expectedEndedAt,
          external_id: externalId,
          started_at: startedAt,
        } = response.update_animal_group_by_pk;

        // Update the loaded animal group with the new data.
        loadAnimalGroup(id, birthDate, endedAt, expectedEndedAt, externalId, startedAt);
      }
      setEditing(false);
      setSaving(false);
    },
    onError: () => {
      const errorMessage =
        'A network error was encountered. Please check your internet connection before trying again.';

      toast.error(errorMessage, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

        containerId: TOAST_CONTAINER_ID,
      });

      setEditing(false);
      setSaving(false);
    },
  });

  const [updateAnimalGroupEndedAt] = useMutation(ANIMAL_GROUP_ENDED_AT_UPDATE, {
    onCompleted: (response) => {
      if (response?.update_animal_group_by_pk) {
        const { ended_at: endedAt } = response.update_animal_group_by_pk;

        // Update the loaded animal group with the new endedAt time.
        loadAnimalGroup(id, birthDateSeconds, endedAt, expectedEndedAtSeconds, externalId, startedAtSeconds);
      }

      setSaving(false);
      close();
    },
    onError: () => {
      const errorMessage =
        'A network error was encountered. Please check your internet connection before trying again.';

      toast.error(errorMessage, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

        containerId: TOAST_CONTAINER_ID,
      });

      setSaving(false);
    },

    refetchQueries: ['AnimalGroupHeader_Query'],
  });

  // Convert timestamps into human-readable dates.
  const { birthDate, birthDateRelative } = useMemo(() => {
    if (!birthDateSeconds) return {};
    const birthDateDayjs = dayjs.tz(1000 * birthDateSeconds);
    return { birthDate: birthDateDayjs.format(DATE_FORMAT_DASH), birthDateRelative: birthDateDayjs.fromNow(false) };
  }, [birthDateSeconds]);

  const endDate = useMemo(
    () => endedAtSeconds && dayjs.tz(1000 * endedAtSeconds).format(DATE_FORMAT_DASH),
    [endedAtSeconds],
  );

  const expectedEndDate = useMemo(
    () => expectedEndedAtSeconds && dayjs.tz(1000 * expectedEndedAtSeconds).format(DATE_FORMAT_DASH),
    [expectedEndedAtSeconds],
  );

  const startDate = useMemo(
    () => startedAtSeconds && dayjs.tz(1000 * startedAtSeconds).format(DATE_FORMAT_DASH),
    [startedAtSeconds],
  );

  const edit = useCallback((event) => {
    // Forms normally force a page redirect on submit. Prevent this.
    event.preventDefault();

    setEditing(true);
  }, []);

  const cancelEdit = useCallback(
    (event) => {
      event.preventDefault();

      //Reset all input states to their initial values.
      setBirthDateInput(birthDateSeconds ? dayjs.tz(1000 * birthDateSeconds).format(DATE_FORMAT_DASH) : '');
      setEndDateInput(endedAtSeconds ? dayjs.tz(1000 * endedAtSeconds).format(DATE_FORMAT_DASH) : '');
      setExpectedEndDateInput(
        expectedEndedAtSeconds ? dayjs.tz(1000 * expectedEndedAtSeconds).format(DATE_FORMAT_DASH) : '',
      );
      setExternalIdInput(externalId || '');
      setStartDateInput(startedAtSeconds ? dayjs.tz(1000 * startedAtSeconds).format(DATE_FORMAT_DASH) : '');

      setEditing(false);
    },
    [birthDateSeconds, endedAtSeconds, expectedEndedAtSeconds, externalId, startedAtSeconds],
  );

  const saveEdit = useCallback(
    (event) => {
      // Forms normally force a page redirect on submit. Prevent this.
      event.preventDefault();

      const birthDateEdit =
        birthDateInput === '' ? birthDateSeconds : dayjs.tz(birthDateInput, DATE_FORMAT_DASH, user.timezone).unix();
      let endedAtEdit;
      if (null === endedAtSeconds) {
        endedAtEdit = null;
      } else if (endDateInput === '') {
        endedAtEdit = endedAtSeconds;
      } else {
        endedAtEdit = dayjs.tz(endDateInput, DATE_FORMAT_DASH, user.timezone).unix();
      }
      const expectedEndedAtEdit =
        expectedEndDateInput === ''
          ? expectedEndedAtSeconds
          : dayjs.tz(expectedEndDateInput, DATE_FORMAT_DASH, user.timezone).unix();
      const startedAtEdit =
        startDateInput === '' ? startedAtSeconds : dayjs.tz(startDateInput, DATE_FORMAT_DASH, user.timezone).unix();

      updateAnimalGroupEdit({
        variables: {
          id,
          birthDate: birthDateEdit,
          endedAt: endedAtEdit,
          expectedEndedAt: expectedEndedAtEdit,
          externalId: externalIdInput,
          startedAt: startedAtEdit,
        },
      });

      setSaving(true);
    },
    [birthDateInput, externalIdInput, endDateInput, expectedEndDateInput, startDateInput],
  );

  const handleSubmit = useCallback((event) => {
    // Forms normally force a page redirect on submit. Prevent this.
    event.preventDefault();

    const endDate = event.target[END_DATE_INPUT].value;

    const endedAtInSeconds = dayjs.tz(endDate, DATE_FORMAT_DASH, user.timezone).unix();

    updateAnimalGroupEndedAt({ variables: { id, endedAt: endedAtInSeconds } });

    setSaving(true);
  });

  return (
    <>
      <form className="AnimalGroupDetails" onSubmit={handleSubmit}>
        <div className="AnimalGroupDetails-top">
          <span className="AnimalGroupDetails-titleText">Group Details</span>
          <XIcon className="AnimalGroupDetails-exit" onClick={close} />
        </div>
        <div className="AnimalGroupDetails-body">
          <div className="AnimalGroupDetails-externalId">
            <span className="AnimalGroupDetails-labelText">Group ID</span>
            <input
              className={`AnimalGroupDetails-editInput AnimalGroupDetails-editInput--${
                editing ? 'edit' : 'readonly'
              } AnimalGroupDetails-contentText`}
              value={editing ? externalIdInput : externalId || '-'}
              disabled={!editing}
              type="text"
              onChange={changeExternalIdInput}
            />
          </div>
          <div className="AnimalGroupDetails-startDate">
            <span className="AnimalGroupDetails-labelText">Start Date</span>
            <input
              className={`AnimalGroupDetails-editInput AnimalGroupDetails-editInput--${
                editing ? 'edit' : 'readonly'
              } AnimalGroupDetails-contentText`}
              value={editing ? startDateInput : startDate || '-'}
              disabled={!editing}
              type="date"
              onChange={changeStartDateInput}
            />
          </div>
          <div className="AnimalGroupDetails-endDate">
            <span className="AnimalGroupDetails-labelText">End Date</span>
            <input
              // Only allow editing if an endDate exists (is not null).
              // If one doesn't, the right way to end a group is with the 'Close Animal Group' interface.
              className={`AnimalGroupDetails-editInput AnimalGroupDetails-editInput--${
                editing && endDate ? 'edit' : 'readonly'
              } AnimalGroupDetails-contentText`}
              value={editing && endDate ? endDateInput : endDate || '-'}
              disabled={!(editing && endDate)}
              type={editing && endDate ? 'date' : 'text'}
              onChange={changeEndDateInput}
            />
          </div>
          <div className="AnimalGroupDetails-birthDate">
            <span className="AnimalGroupDetails-labelText">Approx. Birth Date</span>
            <input
              className={`AnimalGroupDetails-editInput AnimalGroupDetails-editInput--${
                editing ? 'edit' : 'readonly'
              } AnimalGroupDetails-contentText`}
              value={editing ? birthDateInput : birthDate || '-'}
              disabled={!editing}
              type={editing || birthDate ? 'date' : 'text'}
              onChange={changeBirthDateInput}
            />
            {birthDateRelative && <span className="AnimalGroupDetails-labelText">{birthDateRelative}</span>}
          </div>
          <div className="AnimalGroupDetails-expectedEndDate">
            <span className="AnimalGroupDetails-labelText">Expected End Date</span>
            <input
              className={`AnimalGroupDetails-editInput AnimalGroupDetails-editInput--${
                editing ? 'edit' : 'readonly'
              } AnimalGroupDetails-contentText`}
              value={editing ? expectedEndDateInput : expectedEndDate || '-'}
              disabled={!editing}
              type={editing || expectedEndDate ? 'date' : 'text'}
              onChange={changeExpectedEndDateInput}
            />
          </div>
          {editing ? (
            <div className="AnimalGroupDetails-editControls">
              <Button
                className="AnimalGroupDetails-editButton"
                variant="text"
                color="success"
                content="Cancel"
                disabled={saving}
                onClick={cancelEdit}
              />
              <Button
                className="AnimalGroupDetails-editButton"
                variant="text"
                color="success"
                content="Save"
                disabled={saving}
                onClick={saveEdit}
              />
            </div>
          ) : (
            <Button
              className="AnimalGroupDetails-editButton"
              variant="text"
              color="success"
              content="Edit"
              disabled={saving}
              onClick={edit}
            />
          )}
        </div>
        {
          // Show interface to close the animal group if this group is still open.
          !endDate && (
            <div className="AnimalGroupDetails-close">
              <span className="AnimalGroupDetails-titleText AnimalGroupDetails-closeTitle">Close Animal Group</span>
              <label className="AnimalGroupDetails-headerText" htmlFor={END_DATE_INPUT}>
                Actual End Date
              </label>
              <div className="AnimalGroupDetails-closeSection">
                <input
                  className="AnimalGroupDetails-closeInput AnimalGroupDetails-closeInput--date AnimalGroupDetails-inputText--date"
                  id={END_DATE_INPUT}
                  name={END_DATE_INPUT}
                  type="date"
                  value={endDateValue}
                  onChange={changeEndDate}
                  required
                />
                <Button
                  loading={saving}
                  type="submit"
                  className="AnimalGroupDetails-button"
                  variant="vivid"
                  color="success"
                  content="Confirm Close"
                />
              </div>
            </div>
          )
        }
      </form>
      <ToastContainer enableMultiContainer containerId={TOAST_CONTAINER_ID} />
    </>
  );
}

AnimalGroupDetails.propTypes = {
  birthDateSeconds: PropTypes.number,
  endedAtSeconds: PropTypes.number,
  expectedEndedAtSeconds: PropTypes.number,
  externalId: PropTypes.string,
  id: PropTypes.string,
  startedAtSeconds: PropTypes.number,
  loadAnimalGroup: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default AnimalGroupDetails;
