import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { DATE_FORMAT_DASH, DATE_TIME_FORMAT_SHORT, DATE_TIME_FORMAT_FULL } from '../../utils/dates';
import FeedFloPercent from '../../atoms/FeedFloPercent/FeedFloPercent';
import DeviceCardView from './DeviceCardView';
import TransactionChart from './TransactionChart';
import StatusBadge from '../../atoms/StatusBadge/StatusBadge';
import SensorChart from './SensorDataChart';
import { TLVtoJSON } from '../../tlv3';
import { censorTypes, useCensor } from '../../utils/hooks/useCensor';
import CopyButton from '../../atoms/CopyButton';

const SHORT_WINDOW = 60 * 60;
const LONG_WINDOW_DAYS = 5;
const LONG_WINDOW = LONG_WINDOW_DAYS * 24 * 60 * 60;

const DEVICE_GQL = gql`
  query DeviceDetailsQuery($deviceId: uuid!, $now: bigint = "", $shortTimeWindow: bigint!, $longTimeWindow: bigint!) {
    device(where: { id: { _eq: $deviceId } }) {
      id
      serial
      hw_version
      mac_address
      device_data(
        where: {
          device_id: { _eq: $deviceId }
          _or: { ended_at: { _gte: $shortTimeWindow }, created_at: { _gte: $shortTimeWindow } }
        }
        order_by: { started_at: desc }
      ) {
        id
        id_from_device
        started_at
        ended_at
        created_at
      }
      qa_reports(where: { device_id: { _eq: $deviceId } }, order_by: { created_at: desc }) {
        body
        status
        id
        created_at
      }
      device_inductive_sensor_tests(where: { device_id: { _eq: $deviceId } }, order_by: { created_at: desc }) {
        started_at
        ended_at
        inductive_sensor_test_analyses(order_by: { created_at: desc }) {
          json_body
          status
          version
        }
      }
      factory_calibrations(where: { device_id: { _eq: $deviceId } }, order_by: { created_at: desc }) {
        started_at
        ended_at
        id
        factory_calibration_analyses(order_by: { created_at: desc }) {
          binary_body
          json_body
          status
          version
        }
      }
      lens_calibrations(
        where: { _and: [{ device_id: { _eq: $deviceId } }, { deleted_at: { _is_null: true } }] }
        order_by: { created_at: desc }
      ) {
        id
        started_at
        ended_at
      }
      device_assignments(where: { device_id: { _eq: $deviceId } }, order_by: { started_at: desc }, limit: 1) {
        started_at
        feed_line {
          name
          farm {
            name
          }
        }
      }
      firmware_branch {
        name
        firmwares(order_by: { created_at: desc }, limit: 1) {
          tag
        }
      }
      fwFaults: faults(
        where: { _and: [{ device_id: { _eq: $deviceId } }, { code: { _eq: 1001 } }] }
        order_by: { started_at: desc }
        limit: 1
      ) {
        created_at
        details
      }
      lastCompletedAuger: faults(
        where: { _and: [{ device_id: { _eq: $deviceId } }, { code: { _eq: 2002 } }, { ended_at: { _is_null: false } }] }
        order_by: { started_at: desc }
        limit: 1
      ) {
        started_at
      }
      augerRunning: faults(
        where: { _and: [{ device_id: { _eq: $deviceId } }, { code: { _eq: 2002 } }] }
        order_by: { started_at: desc }
        limit: 1
      ) {
        ended_at
      }
      lastReset: faults(
        where: {
          _and: [{ device_id: { _eq: $deviceId } }, { code: { _eq: 1001 } }, { started_at: { _gte: $longTimeWindow } }]
        }
        order_by: { started_at: desc }
        limit: 1
      ) {
        started_at
      }
      firstTransaction: transactions(
        where: { device_id: { _eq: $deviceId }, occured_at: { _gte: $longTimeWindow } }
        order_by: { occured_at: asc }
        limit: 1
      ) {
        occured_at
      }
      lastTransaction: transactions(
        where: { device_id: { _eq: $deviceId }, occured_at: { _gte: $longTimeWindow } }
        order_by: { occured_at: desc }
        limit: 1
      ) {
        occured_at
      }
      sampleTransaction: transactions(
        where: { device_id: { _eq: $deviceId }, occured_at: { _gte: $longTimeWindow } }
        order_by: { occured_at: desc }
        limit: 20
      ) {
        id
        occured_at
        type
        sub_type
        status
        external_id
        data
      }
      short_data_window_aggregate: device_data_aggregate(
        where: {
          _and: [
            { device_id: { _eq: $deviceId } }
            { started_at: { _gte: $shortTimeWindow, _lte: $now } }
            { duration: { _lte: 62, _gte: 58 } }
          ]
        }
      ) {
        aggregate {
          sum {
            started_at
            ended_at
          }
          count
        }
      }
      long_data_window_aggregate: device_data_aggregate(
        where: {
          _and: [
            { device_id: { _eq: $deviceId } }
            { started_at: { _gte: $longTimeWindow, _lte: $now } }
            { duration: { _lte: 62, _gte: 58 } }
          ]
        }
      ) {
        aggregate {
          sum {
            started_at
            ended_at
          }
          count
        }
      }
      recent_data: device_data(
        where: { _and: [{ device_id: { _eq: $deviceId } }, { started_at: { _gte: $shortTimeWindow } }] }
        order_by: { started_at: desc }
        limit: 3
      ) {
        data
        id_from_device
        ended_at
        created_at
      }
    }
  }
`;

export default function DeviceCard({ deviceId, serial = '' }) {
  const now = useMemo(() => dayjs.tz(), []);
  const { censor } = useCensor();

  let runTimeError = null;
  const {
    loading,
    error: gqlError,
    data,
  } = useQuery(DEVICE_GQL, {
    variables: {
      deviceId,
      now: now.subtract(5, 'minutes').unix(),
      shortTimeWindow: now.subtract(5, 'minutes').subtract(SHORT_WINDOW, 'seconds').unix(),
      longTimeWindow: now.subtract(5, 'minutes').subtract(LONG_WINDOW, 'seconds').unix(),
    },
  });

  const device = data?.device[0];
  const lastUploadTimestamp = device?.lastTransaction?.[0]?.occured_at || null;
  const firstUploadTimestamp = device?.firstTransaction?.[0]?.occured_at || null;
  const installedTimestamp = device?.device_assignments?.[0]?.started_at || null;
  const orientation = {};

  const mostRecentDataEndedAt = device?.recent_data?.[0]?.ended_at || null;
  const mostRecentDataCreatedAt = device?.recent_data?.[0]?.created_at || null;
  const mostRecentResetTimestamp = device?.lastReset?.[0]?.started_at || null;

  // check for orientation signal block in most recent signal data
  for (let i = 0; i < device?.recent_data?.length; i++) {
    const hex = device.recent_data[i].data.substring(2);
    const uint16Array = [];
    for (let c = 0; c < hex.length; c += 2) {
      const currInt = parseInt(hex.substr(c, 2), 16);
      uint16Array.push(currInt);
    }
    const json = TLVtoJSON(uint16Array);
    if (json.orientation) {
      Object.assign(orientation, json.orientation);
      break;
    }
  }

  const quickFacts = useMemo(
    () => [
      {
        title: 'Last Upload',
        value: `${lastUploadTimestamp !== null ? dayjs.tz(1000 * lastUploadTimestamp).fromNow() : 'Unknown'}`,
        detail: `${
          lastUploadTimestamp !== null ? dayjs.tz(1000 * lastUploadTimestamp).format(DATE_TIME_FORMAT_FULL) : 'Unknown'
        }`,
      },
      {
        title: 'First Upload',
        value: `${
          firstUploadTimestamp !== null ? dayjs.tz(1000 * firstUploadTimestamp).format(DATE_FORMAT_DASH) : 'Unknown'
        }`,
        detail: `${
          firstUploadTimestamp !== null
            ? dayjs.tz(1000 * firstUploadTimestamp).format(DATE_TIME_FORMAT_SHORT)
            : 'Unknown'
        }`,
      },
      {
        title: 'Newest Data',
        value: `${mostRecentDataEndedAt !== null ? dayjs.tz(1000 * mostRecentDataEndedAt).fromNow() : 'Unknown'}`,
        detail: `Captured At: ${
          mostRecentDataEndedAt !== null
            ? dayjs.tz(1000 * mostRecentDataEndedAt).format(DATE_TIME_FORMAT_SHORT)
            : 'Unknown'
        }\nUploaded At: ${
          mostRecentDataCreatedAt !== null
            ? dayjs.tz(1000 * mostRecentDataCreatedAt).format(DATE_TIME_FORMAT_SHORT)
            : 'Unknown'
        }`,
      },
      {
        title: 'Newest Reset',
        value: `${mostRecentResetTimestamp !== null ? dayjs.tz(1000 * mostRecentResetTimestamp).fromNow() : 'Unknown'}`,
        detail: `${
          mostRecentResetTimestamp !== null
            ? dayjs.tz(1000 * mostRecentResetTimestamp).format(DATE_TIME_FORMAT_SHORT)
            : 'Unknown'
        }`,
      },
      {
        title: `${LONG_WINDOW_DAYS} Day Uptime`,
        value: (
          <FeedFloPercent
            min={98}
            max={102}
            toFixed={3}
            number={
              ((device?.long_data_window_aggregate?.aggregate?.sum?.ended_at -
                device?.long_data_window_aggregate?.aggregate?.sum?.started_at) /
                LONG_WINDOW) *
              100
            }
          />
        ),
      },
      {
        title: '1 Hour Uptime',
        value: (
          <FeedFloPercent
            min={98}
            max={102}
            toFixed={3}
            number={
              ((device?.short_data_window_aggregate?.aggregate?.sum?.ended_at -
                device?.short_data_window_aggregate?.aggregate?.sum?.started_at) /
                SHORT_WINDOW) *
              100
            }
          />
        ),
      },
      {
        title: 'Last Run',
        value: `${
          device?.lastCompletedAuger?.[0]?.started_at > 0
            ? dayjs.tz(1000 * device?.lastCompletedAuger[0]?.started_at).format(DATE_TIME_FORMAT_SHORT)
            : 'Unknown'
        }`,
      },
      { title: 'Auger Running', value: `${device?.augerRunning?.[0]?.ended_at === null}` || 'Unknown' },
      {
        title: 'FW Target',
        value: `${device?.firmware_branch?.name} [${device?.firmware_branch?.firmwares?.[0]?.tag}]`,
      },
      {
        title: 'FW Reported',
        value: `${device?.fwFaults[0]?.details && atob(device?.fwFaults[0]?.details)}`,
        detail: 'The current firmware version as reported by the most recent SYSTEM_RESET event.',
      },
      { title: 'HW Version', value: device?.hw_version || 'unknown' },
      { title: 'MAC', value: device?.mac_address || 'unknown' },
      {
        title: 'Pitch',
        value: Number(orientation?.pitch).toFixed(1) + '°' || 'unknown',
        detail: 'The degree of vertical device tilt. Upwards nose tilt is positive.',
      },
      {
        title: 'Roll',
        value: Number(orientation?.roll).toFixed(1) + '°' || 'unknown',
        detail: 'The degree of horizontal device tilt. Left tilt is positive.',
      },
      {
        title: 'Movement',
        value: Number(orientation?.movementNoise).toFixed(1) || 'unknown',
        detail: `A high Movement score indicates that roll and pitch values are likely inaccurate. An ideal score is less than 1.0.`,
      },
      {
        title: 'Installed',
        value: lastUploadTimestamp !== null ? dayjs.tz(1000 * installedTimestamp).format(DATE_FORMAT_DASH) : 'Unknown',
        detail: 'The approximate time this device was installed.',
      },
    ],
    [device],
  );
  const viewProps = {
    barnTitle: censor(device?.device_assignments?.[0]?.feed_line?.farm?.name, censorTypes.barn) || 'Unknown',
    feedLineTitle: censor(device?.device_assignments?.[0]?.feed_line?.name, censorTypes.feedline) || 'Unknown',
    quickFacts,
  };
  const calibrationAnalyses = [];
  device?.factory_calibrations?.forEach((calibration) => {
    calibration?.factory_calibration_analyses?.forEach((analysis) => {
      calibrationAnalyses.push({
        date: calibration.started_at,
        started_at: calibration.started_at,
        ended_at: calibration.ended_at,
        status: analysis.status,
        version: analysis.version,
      });
    });
  });

  return (
    <div>
      <DeviceCardView
        deviceId={deviceId}
        serial={serial}
        loading={loading}
        error={gqlError || runTimeError}
        feedLineTitle="Unknown"
        quickFacts={[]}
        qaReports={device?.qa_reports?.map((report) => {
          let reportObject = {};

          // The QA system was accidentally changed to store QA reports as JSON-encoded strings instead of JSON,
          // so we need handle both cases here
          if (typeof report?.body === 'string') {
            reportObject = JSON.parse(report?.body);
          } else if (typeof report?.body === 'object') {
            reportObject = report?.body;
          }

          return {
            id: report.id,
            date: report.created_at,
            status: report.status,
            tests: reportObject?.Report,
          };
        })}
        calibrations={calibrationAnalyses}
        inductiveSensorTestViews={device?.device_inductive_sensor_tests.map((calibration, i) => (
          <div key={i} className="DeviceCard-lensCalibrationSection">
            {calibration?.inductive_sensor_test_analyses.map((analysis) => {
              let jsonDataToCopy = 'Error';
              try {
                jsonDataToCopy = `${JSON.stringify(JSON.parse(analysis?.json_body), null, 2)}`;
              } catch (error) {
                console.error(error);
              }

              return (
                <>
                  <StatusBadge
                    key={analysis.id}
                    status={analysis.status === 'pass' ? 'success' : 'error'}
                    text={analysis.status || 'blank'}
                  />{' '}
                  <CopyButton data={jsonDataToCopy} />{' '}
                </>
              );
            })}
            <br />
            <Link to={`/rawData/${deviceId}?from=${calibration.started_at}&to=${calibration.ended_at}`}>
              📈 Full Screen
            </Link>
            <SensorChart
              deviceId={deviceId}
              from={dayjs.tz(calibration.started_at * 1000)}
              to={dayjs.tz(calibration.ended_at * 1000)}
            />
          </div>
        ))}
        faultCodes={device?.faultCodes?.map((fcode) => {
          return {
            code: fcode.code,
            started_at: fcode.started_at * 1000, // converting to milliseconds
            ended_at: fcode.ended_at * 1000, // converting to milliseconds
            id: fcode.id,
          };
        })}
        deviceData={device?.device_data?.map((data) => {
          return {
            id: data.id,
            id_from_device: data.id_from_device,
            started_at: data.started_at * 1000, // converting to milliseconds
            ended_at: data.ended_at * 1000, // converting to milliseconds
            created_at: data.created_at * 1000, // converting to milliseconds
          };
        })}
        lensCalibrations={device?.lens_calibrations.map((calibration) => (
          <div key={calibration.id} className="DeviceCard-lensCalibrationSection">
            <Link to={`/rawData/${deviceId}?from=${calibration.started_at}&to=${calibration.ended_at}`}>
              Full Screen
            </Link>
            <SensorChart
              deviceId={deviceId}
              from={dayjs.tz(calibration.started_at * 1000)}
              to={dayjs.tz(calibration.ended_at * 1000)}
            />
          </div>
        ))}
        transactionChart={
          <>
            <div className="transactionHolder">
              {device?.sampleTransaction?.map((transaction) => {
                const icon = transaction.status === '200' ? '👍' : '❌';
                const shortDate = dayjs.tz(1000 * transaction?.occured_at || 0).fromNow();
                const longDate = dayjs.tz(1000 * transaction?.occured_at || 0).format(DATE_TIME_FORMAT_FULL);
                return (
                  <div className="transactions" key={transaction.id}>
                    <div title={longDate}>{shortDate}</div>
                    <div title={transaction.status}>
                      {icon}
                      {transaction.sub_type.toUpperCase()}: {transaction.data}
                    </div>
                  </div>
                );
              })}
            </div>
            <TransactionChart deviceId={deviceId} from={now.subtract(2, 'week')} to={now} />
          </>
        }
        rawDataChart={<SensorChart deviceId={deviceId} from={now.subtract(6, 'hour')} to={now} />}
        {...viewProps}
      />
    </div>
  );
}

DeviceCard.propTypes = {
  deviceId: PropTypes.string,
  serial: PropTypes.string,
};
