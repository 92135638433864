import PropTypes from 'prop-types';
import Heading from '../../atoms/Heading';
import './TabSection.scss';

function TabSection({ heading = '', children = [], className }) {
  return (
    <div className={['TabSection', className].join(' ')}>
      <Heading className="TabSection-heading" text={heading} underline={true} />
      <div className="TabSection-content">{children}</div>
    </div>
  );
}

TabSection.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string,
};

export default TabSection;
