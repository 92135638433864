import { atom } from 'jotai';
import { atomWithStorage, splitAtom } from 'jotai/utils';

// Feature flags. Using storage
export const featureFlagsLocalStorageKey = 'FeedFlo:FeatureFlagManager:featureFlags';
export const algorithmVersionAtom = atom(null);
export const algorithmOverriddenAtom = atom(false);
export const featureFlagsAtom = atomWithStorage(featureFlagsLocalStorageKey, []);

/***
 * For use when we want each item to be an atom
 */
export const featureFlagAtomsAtom = splitAtom(featureFlagsAtom);

// User Preferences
export const listFormatPrefAtom = atomWithStorage('FeedFlo:UserPrefs:ListFormat', 'card');
