import PropTypes from 'prop-types';

export default function EventChartBar({
  className,
  x,
  y,
  width,
  stroke,
  fill,
  clipPath,
  selected = false,
  onClick = () => null,
}) {
  const { height, radius, strokeWidth } = selected
    ? { height: 12, radius: 6, strokeWidth: 3 }
    : { height: 12, radius: 7, strokeWidth: 2 };

  return (
    <g className={className} onClick={onClick} opacity={selected ? 1 : 0.7} clipPath={clipPath}>
      <pattern
        id="eventChartBarDiagonalHatching"
        width="12"
        height="12"
        patternTransform="rotate(45 0 0)"
        patternUnits="userSpaceOnUse"
      >
        <rect x="0" y="0" width="6" height="12" fill="white" opacity={0.5} />
      </pattern>
      <rect
        x={x}
        y={y - height / 2}
        width={width}
        height={height}
        rx={radius}
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill={fill}
      />
      {selected && (
        <rect
          x={x}
          y={y - height / 2}
          width={width}
          height={height}
          rx={radius}
          stroke={stroke}
          strokeWidth={strokeWidth}
          fill="url(#eventChartBarDiagonalHatching)"
        />
      )}
    </g>
  );
}

EventChartBar.propTypes = {
  className: PropTypes.string,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  stroke: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
  clipPath: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};
