import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './UserDropDown.scss';

function UserDropDown({ items = [] }) {
  const [menuItems, setItems] = useState(0);
  useEffect(() => {
    const menuItems = items.map((item, i) => {
      return (
        <div
          role="button"
          tabIndex={i}
          className="button"
          key={`${item.title}`}
          onClick={item.onClick}
          onKeyDown={item.onClick}
        >
          {item.icon ? <span className="UserDropDown-buttonIcon">{item.icon}</span> : null}
          {item.title}
        </div>
      );
    });
    setItems(menuItems);
  }, []);

  return <div className="UserDropDown">{menuItems}</div>;
}

UserDropDown.propTypes = {
  items: PropTypes.array,
};

export default UserDropDown;
