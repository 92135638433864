import PropTypes from 'prop-types';

import { Group } from '@visx/group';

function BrushHandle({ x, height, isBrushActive = false, opacity = 1 }) {
  const pathWidth = 8;
  const pathHeight = 15;
  if (!isBrushActive) {
    return null;
  }
  return (
    <Group left={x + pathWidth / 2} top={(height - pathHeight) / 2} style={{ opacity }}>
      <line x1="0" y1={-(height - pathHeight) / 2} x2="0" y2={(height + pathHeight) / 2} stroke="green" />
      <rect x="-4.5" y="-19.5" width="9" height="39" rx="4.5" fill="white" fillOpacity="0.78" stroke="#A3ACB9" />
      <Group left={-4.96826 - 10 / 2} top={-4.58105 - 60 / 2} filter="url(#filter0_d_236_3036)">
        <rect x="4.96826" y="4.58105" width="10" height="60" rx="5" fill="#F1F1F1" />
      </Group>
      <defs>
        <filter
          id="filter0_d_236_3036"
          x="0.968262"
          y="0.581055"
          width="18"
          height="68"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_236_3036" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_236_3036" result="shape" />
        </filter>
      </defs>
    </Group>
  );
}

BrushHandle.propTypes = {
  x: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isBrushActive: PropTypes.bool,
  opacity: PropTypes.number,
};

export default BrushHandle;
