import PropTypes from 'prop-types';
import useTitle from '../../utils/hooks/useTitle';
import Heading from '../Heading';
import './Page.scss';

function Page({
  className = '',
  children = [],
  heading = '',
  headerComponent = null,
  titleSegments = [],
  onKeyDown = () => {},
}) {
  const baseTitleSegments = ['FeedFlo'];
  const segments = [...titleSegments, ...baseTitleSegments];
  const { setTitle } = useTitle();

  if (segments.length) {
    setTitle({ segments, separator: ' - ' });
  }

  return (
    <div className={`Page ${className}`} onKeyDown={onKeyDown}>
      {heading || headerComponent ? <Heading text={heading}>{headerComponent}</Heading> : null}
      {children}
    </div>
  );
}

Page.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  heading: PropTypes.string,
  headerComponent: PropTypes.node,
  titleSegments: PropTypes.arrayOf(PropTypes.string),
  onKeyDown: PropTypes.func,
};

export default Page;
