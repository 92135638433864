import PropTypes from 'prop-types';
import Chevron from '../Chevron';
import LoadingSkeleton from '../LoadingSkeleton';
import './CollapsibleSection.scss';

function CollapsibleSection({
  loading = false,
  className = '',
  headerClassName = '',
  titleClassName = '',
  bodyClassName = '',
  style = null,
  isExpanded = false,
  title = '',
  leftHeaderContent = null,
  rightHeaderContent = null,
  children = [],
  tabIndex = 0,
  onExpandCollapse = () => {},
}) {
  if (loading) {
    return (
      <div className={`CollapsibleSection ${className}`} style={style}>
        <div
          className={`CollapsibleSection-header CollapsibleSection-header--loading ${headerClassName}`}
          tabIndex={tabIndex}
        >
          <div className={`CollapsibleSection-leftHeaderContent`}>
            <LoadingSkeleton className="CollapsibleSection-title--loading" />
          </div>
          <div className={`CollapsibleSection-rightHeaderContent`} />
        </div>
        {isExpanded ? <div className={`CollapsibleSection-body ${bodyClassName}`}>{children}</div> : null}
      </div>
    );
  }

  const onKeyboardToggle = (e) => {
    if (e.key !== 'Enter' && e.code !== 'Space') {
      return;
    }

    e.preventDefault();
    onExpandCollapse();
  };

  return (
    <div className={`CollapsibleSection ${className}`} style={style}>
      <div
        className={`CollapsibleSection-header ${headerClassName}`}
        tabIndex={tabIndex}
        onKeyDown={onKeyboardToggle}
        onClick={onExpandCollapse}
      >
        <div className={`CollapsibleSection-leftHeaderContent`}>
          {title ? <div className={`CollapsibleSection-title ${titleClassName}`}>{title}</div> : null}
          {leftHeaderContent ? leftHeaderContent : null}
        </div>
        <div className={`CollapsibleSection-rightHeaderContent`}>
          {rightHeaderContent ? rightHeaderContent : null}
          <Chevron className="CollapsibleSection-chevron" open={isExpanded} />
        </div>
      </div>
      {isExpanded ? <div className={`CollapsibleSection-body ${bodyClassName}`}>{children}</div> : null}
    </div>
  );
}

CollapsibleSection.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  style: PropTypes.object,
  isExpanded: PropTypes.bool,
  title: PropTypes.string,
  leftHeaderContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
  rightHeaderContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
  tabIndex: PropTypes.number,
  onExpandCollapse: PropTypes.func,
};

export default CollapsibleSection;
