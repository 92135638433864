import PropTypes from 'prop-types';
import { InfoIconSVG } from '../../atoms/Icons';
import './InfoToolTip.scss';

function InfoToolTip({ className = '', text = '' }) {
  return (
    <div className={`InfoToolTip ${className}`}>
      <InfoIconSVG className="InfoToolTip-icon" />
      <span className="InfoToolTip-text">{text}</span>
    </div>
  );
}

InfoToolTip.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default InfoToolTip;
