import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import FeedFloTextInput from '../../atoms/FeedFloTextInput';
import { SearchIcon } from '../../atoms/Icons';
import SearchSuggestion from './SearchSuggestion';

import './SearchBar.scss';

const MAX_SUGGESTIONS = 8;

function SearchBar({
  className = '',
  inputClassName = '',
  iconClassName = '',
  suggestionClassName = '',

  items = [],
  placeholder = '',
  clearOnSelection = false,

  onSubmit = () => {},
  onType = () => {},
}) {
  const [inputText, setInputText] = useState('');
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);
  const suggestionsRef = useRef(null);
  const lowercaseSearchTerm = useMemo(() => inputText.toLowerCase(), [inputText]);
  const filteredItems = useMemo(() => {
    return items.filter((item) => item.label.toLowerCase().indexOf(lowercaseSearchTerm) > -1).slice(0, MAX_SUGGESTIONS);
  }, [items, lowercaseSearchTerm]);

  const onFocus = useCallback(() => {
    setSuggestionsVisible(true);
  }, []);

  const onChangeText = useCallback(
    (text) => {
      onType(text);
      setInputText(text || '');
      setSuggestionsVisible(true);
    },
    [onType],
  );

  const onSelectSuggestion = useCallback(
    (event, id = '', label = '') => {
      onSubmit(event, id, label);
      setInputText(clearOnSelection ? '' : label);
      setSuggestionsVisible(false);
    },
    [clearOnSelection, onSubmit],
  );

  // Set up click handlers.
  useEffect(() => {
    function onClickOutside(e) {
      if (suggestionsRef.current && !suggestionsRef.current.contains(e.target)) {
        setSuggestionsVisible(false);
      }
    }
    // Add event listener for click events when component mounts.
    document.addEventListener('mousedown', onClickOutside);
    // Remove event listener when component unmounts.
    return () => {
      document.removeEventListener('mousedown', onClickOutside);
    };
  }, [suggestionsRef]);

  // Set up keyboard handlers.
  useEffect(() => {
    function keyHandler(e) {
      switch (e.key) {
        case 'Enter':
          // Check if suggestions are visible and if there's at least one suggested item matching input.
          // If so, set the top item as selected when 'enter' is pressed.
          if (suggestionsVisible && filteredItems?.[0]?.id && filteredItems?.[0]?.label) {
            e.preventDefault();
            const { id, label } = filteredItems[0];
            onSelectSuggestion(e, id, label);
          }
          break;
      }
    }
    // Add event listener for keyboard events when component mounts.
    document.addEventListener('keypress', keyHandler);
    // Remove event listener when component unmounts.
    return () => {
      document.removeEventListener('keypress', keyHandler);
    };
  }, [filteredItems, suggestionsVisible, onSelectSuggestion]);

  return (
    <div ref={suggestionsRef} className={`SearchBar ${className}`}>
      <FeedFloTextInput
        inputClassName={`SearchBar-input ${inputClassName}`}
        defaultText={placeholder}
        text={inputText}
        onChange={onChangeText}
        onFocus={onFocus}
      />
      <SearchIcon className={`SearchBar-searchIcon ${iconClassName}`} />
      {suggestionsVisible && (
        <div className={`SearchBar-searchSuggestions ${suggestionClassName}`}>
          {filteredItems.map((item) => (
            <SearchSuggestion key={item.id} id={item.id} label={item.label} onClick={onSelectSuggestion} />
          ))}
        </div>
      )}
    </div>
  );
}

SearchBar.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  suggestionClassName: PropTypes.string,

  items: PropTypes.array,
  placeholder: PropTypes.string,
  clearOnSelection: PropTypes.bool,

  onSubmit: PropTypes.func,
  onType: PropTypes.func,
};

export default SearchBar;
