import React from 'react';
import { gql, useQuery, useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT_MONTH_DAY_YEAR_HOUR_MINUTE_SECOND } from '../../utils/dates';

const FW_BRANCHES_GQL = gql`
  query FWBranchQuery {
    firmware_branch(where: { deleted_at: { _is_null: true } }) {
      name
      id
      devices_aggregate {
        aggregate {
          count
        }
      }
      firmwares(where: { deleted_at: { _is_null: true } }, order_by: { created_at: desc }) {
        tag
        name
        created_at
        id
      }
    }
  }
`;

export default function FirmwareBranchController() {
  const client = useApolloClient();
  const { loading, data, error: fetchError } = useQuery(FW_BRANCHES_GQL);
  const firmwareBranches = data?.firmware_branch;

  const ErrorOccurred = (errorText = 'Error Occurred') =>
    toast.warn(errorText, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const downloadFWFile = async (fw) => {
    const { error: downloadError, data } = await client.query({
      query: gql`
        query DownloadFW($fw_id: uuid!) {
          firmware(where: { id: { _eq: $fw_id } }) {
            firmware_file
          }
        }
      `,
      variables: { fw_id: fw.id },
    });

    if (downloadError) ErrorOccurred('Error Downloading Firmware');

    const binary = [];
    const string = data.firmware[0].firmware_file.substring(2);
    for (let i = 0; i < string.length / 2; i += 1) {
      const h = string.substr(i * 2, 2);
      binary[i] = parseInt(h, 16);
    }

    const byteArray = new Uint8Array(binary);

    const blob = new Blob([byteArray], {
      type: 'application/octet-stream',
    });
    saveAs(blob, `${fw.name}-${fw.tag}.bin`);
  };

  const buildBranchRow = (branch) => {
    return (
      <div className="fw" key={branch.id}>
        <span>
          <h4>{branch.name}: </h4>
          <p>Devices on Branch: {branch.devices_aggregate?.aggregate?.count || '0'}</p>
        </span>

        {branch.firmwares.length > 0 && (
          <>
            <div className="spacer">
              <div className="fwHistoryWindow customScroll">
                {branch.firmwares.map((fw, i) => {
                  return (
                    <div className="fwHistory" key={fw.id}>
                      {i === 0 && <span className="live"> - Live - </span>}
                      <b> Name: </b>
                      {fw.name}
                      <b> Date Created: </b>
                      {dayjs.tz(1000 * fw.created_at).format(DATE_TIME_FORMAT_MONTH_DAY_YEAR_HOUR_MINUTE_SECOND)}
                      <b> Tag: </b>
                      <span className="tag">{fw.tag} </span>
                      <a
                        className="download"
                        onClick={() => {
                          downloadFWFile(fw);
                        }}
                      >
                        Download
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>{}</div>
          </>
        )}
      </div>
    );
  };

  if (loading) return <span>Loading</span>;
  if (fetchError) return <span>{JSON.stringify(fetchError, null, 2)}</span>;
  return (
    <div className="FirmwareBranchController">
      <div>{firmwareBranches?.map(buildBranchRow)}</div>
    </div>
  );
}
