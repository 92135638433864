import React from 'react';
import PropTypes from 'prop-types';
import { KeyIcon } from '../../atoms/Icons';
import FeedFloToolTip from '../../atoms/FeedFloToolTip';
import Button from '../../atoms/Button';
import CopyButton from '../../atoms/CopyButton';
import './APIInfoTile.scss';

function APIInfoTile({ title = '', permissions = '', date = '', secretToken = '', onDelete = () => {} }) {
  return (
    <>
      {secretToken?.length > 0 && (
        <div className="ApiGeneratedKey">
          <div className="topLine">
            <div className="keyColor">
              <KeyIcon />
            </div>
            <div className="keyText">{secretToken}</div>
            <CopyButton data={secretToken} className="APIInfoTile-copyTokenButton" iconStyle={{ color: 'white' }} />
          </div>
        </div>
      )}
      <div className={`APIInfoTile ${secretToken?.length > 0 && 'changeBorder'}`}>
        <div className="APIInfoTile-leftContent">
          <div className="topLine">
            <div className="type">{title}</div> <div className="permissions"> - {permissions}</div>
          </div>
          <div className="bottomLine">
            Expires on <div className="date"> {date}</div>
          </div>
        </div>
        <FeedFloToolTip description="Click To Delete" className="APIInfoTile-FeedFloToolTip APIInfoTile-rightContent">
          <Button
            className="APIInfoTile-DeleteTokenButton"
            variant="vivid"
            color="error"
            content="x"
            onClick={onDelete}
            style={{
              padding: '0.5rem 1.25rem',
            }}
          />
        </FeedFloToolTip>
      </div>
    </>
  );
}

APIInfoTile.propTypes = {
  title: PropTypes.string,
  permissions: PropTypes.string,
  date: PropTypes.string,
  onDelete: PropTypes.func,
  secretToken: PropTypes.string,
};

export default APIInfoTile;
