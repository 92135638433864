import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import MDEditor from '@uiw/react-md-editor';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { getFaultName } from '@norimaconsulting/fault-codes';

import { DATE_TIME_FORMAT_MONTH_DAY_YEAR_HOUR_MINUTE_SECOND } from '../../utils/dates';
import useUser from '../../utils/hooks/useUser';
import { censorTypes, useCensor } from '../../utils/hooks/useCensor';
import AlertDashboardBadge from '../../atoms/AlertDashboardBadge';
import CommentBlock from '../../molecules/CommentBlock';
import FeedFloButton from '../../atoms/FeedFloButton';
import StatusBadge from '../../atoms/StatusBadge';
import FeedFloDropDown from '../../atoms/FeedFloDropDown';
import { UNKNOWN_USER_NAME, UNKNOWN_USER_AVATAR } from '../../utils/constants';
import CopyButton from '../../atoms/CopyButton';
import {
  FAULT_CODE_GQL,
  FAULT_TIME,
  GET_USER_DATA_GQL,
  SAVE_FAULT_COMMENT_GQL,
  SET_FAULT_ENDED_AT_BY_PK_GQL,
} from './queries';
import './FaultCodeDetails.scss';

const onCompletedSaveCommentQuery = () => {
  toast('Saved Comment', {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export default function FaultCodeDetails({ faultId, onFaultLoad = () => {} }) {
  const { user, loading: userLoading } = useUser();
  const [userData, setUserData] = useState({});
  const { censor } = useCensor();

  const safeGetFaultName = (code) => {
    try {
      return getFaultName(code);
    } catch (error) {
      return code;
    }
  };

  // Using this to ensure we are getting the correct assignment for the fault
  const { loading: faultTimeLoading, data: faultInfo } = useQuery(FAULT_TIME, {
    variables: { fcId: faultId },
  });

  const deviceAssignmentWhere = {
    deleted_at: { _is_null: true },
    status: { _eq: 'active' },
    started_at: { _lte: faultInfo?.fault?.started_at },
  };

  if (faultInfo?.fault?.ended_at) {
    deviceAssignmentWhere._or = [
      {
        ended_at: {
          _is_null: true,
        },
      },
      {
        ended_at: {
          _gte: faultInfo?.fault?.started_at,
        },
      },
    ];
  } else {
    deviceAssignmentWhere.ended_at = { _is_null: true };
  }

  const {
    loading,
    error: gqlError,
    data,
  } = useQuery(FAULT_CODE_GQL, {
    variables: {
      fcId: faultId,
      deviceAssignmentWhere,
    },
    skip: !faultInfo?.fault || faultTimeLoading,
  });
  const { loading: getUserLoading, error: getUserError, data: getUserData } = useQuery(GET_USER_DATA_GQL);

  const [saveComment, { error: saveCommentError, loading: saveCommentLoading }] = useMutation(SAVE_FAULT_COMMENT_GQL, {
    onCompleted: onCompletedSaveCommentQuery,
  });

  const [endFault] = useMutation(SET_FAULT_ENDED_AT_BY_PK_GQL);

  const [newRootCause, setNewRootCause] = useState();
  const [newComment, setNewComment] = useState('');

  const [now] = useState(Date.now());

  useEffect(() => {
    if (data) {
      onFaultLoad(data);
    }
  }, [data]);

  const onChangeRootCause = (e) => {
    setNewRootCause(e.id);
  };
  const onChangeNewComment = (text) => {
    setNewComment(text);
  };
  const onSubmitNewComment = () => {
    setNewComment('');
    saveComment({
      variables: {
        objects: [
          {
            fault_id: faultId,
            fault_root_cause_id: newRootCause,
            comment: newComment,
          },
        ],
      },
      refetchQueries: ['FaultDetailQuery'],
    });
  };

  if (saveCommentError) {
    console.log(JSON.stringify(saveCommentError));
    toast.warning('Error Saving Comment', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const fault = data?.fault || {};
  const feedLine = fault?.device?.device_assignments?.[0]?.feed_line || {};
  const farm = feedLine?.farm || {};
  const comments = fault?.fault_comments || [];
  const currentRootCauseName = fault?.rootCauseComment?.[0]?.fault_root_cause?.name || 'unidentified';
  const rootCauses = [{ id: null, name: 'Not set for this comment' }, ...(data?.fault_root_cause || [])];

  useEffect(() => {
    const selectedRootCause = rootCauses.find((rootCause) => rootCause.name === currentRootCauseName) || null;

    if (newRootCause === undefined) {
      setNewRootCause(selectedRootCause?.id || undefined);
    }
  }, [rootCauses]);

  useEffect(() => {
    if (getUserData?.user) {
      const newData = {};
      getUserData.user.forEach((user) => {
        newData[user.id] = { name: user.name, avatar: user.avatar };
      });
      setUserData(newData);
    }
  }, [getUserData]);

  if (loading || faultTimeLoading || saveCommentLoading || getUserLoading || userLoading) {
    return <div>Loading</div>;
  }
  if (gqlError || getUserError) {
    return `${gqlError} ${getUserError}`;
  }

  return (
    <div className="FaultCodeDetails">
      <h3>
        {safeGetFaultName(fault.code)} @ {censor(farm?.name, censorTypes.barn)}{' '}
        {censor(feedLine?.name, censorTypes.feedline)}
      </h3>
      <AlertDashboardBadge type={currentRootCauseName} />
      {fault.deleted_at && (
        <StatusBadge
          className="FaultCodeDetails-statusbadge--squircle"
          status="warning"
          text={`Soft Deleted ${dayjs
            .unix(fault.deleted_at)
            .format(DATE_TIME_FORMAT_MONTH_DAY_YEAR_HOUR_MINUTE_SECOND)}`}
        />
      )}
      <div className="FaultCodeDetails-infoTable">
        <b className="FaultCodeDetails-infoTableRowHeader">Fault Id: </b>
        <div>
          <span>{faultId}</span>
          <CopyButton data={faultId} className="FaultCodeDetails-copy--inline" />
        </div>
        <b className="FaultCodeDetails-infoTableRowHeader">Fault Device Id: </b>
        <p>{fault.id_from_device}</p>

        <b className="FaultCodeDetails-infoTableRowHeader">Device Serial: </b>
        <p>{fault.device.serial}</p>
        <b className="FaultCodeDetails-infoTableRowHeader">Started At: </b>
        <div>
          <span>{dayjs.tz(1000 * fault.started_at).format(DATE_TIME_FORMAT_MONTH_DAY_YEAR_HOUR_MINUTE_SECOND)} </span>
          <CopyButton data={fault.started_at.toString()} className="FaultCodeDetails-copy--inline" />
        </div>
        <b className="FaultCodeDetails-infoTableRowHeader">Ended At: </b>
        <div>
          <span>
            {fault.ended_at ? (
              <span>{dayjs.tz(1000 * fault.ended_at).format(DATE_TIME_FORMAT_MONTH_DAY_YEAR_HOUR_MINUTE_SECOND)}</span>
            ) : (
              'Ongoing'
            )}
          </span>
          {fault.ended_at && <CopyButton data={fault.ended_at.toString()} className="FaultCodeDetails-copy--inline" />}
        </div>
        <b className="FaultCodeDetails-infoTableRowHeader">Duration: </b>
        <p>{dayjs.tz(fault.ended_at * 1000 || now).to(fault.started_at * 1000, true)}</p>
        <b className="FaultCodeDetails-infoTableRowHeader">Base64 Message (decoded): </b>
        {fault.details ? (
          <pre>{atob(fault.details)}</pre>
        ) : (
          <pre>
            <i>&lt;No Message&gt;</i>
          </pre>
        )}
      </div>

      <div className="FaultCodeDetails-editing">
        {!fault.ended_at && (
          <div className="FaultCodeDetails-editFormWrapper">
            <h4>End Ongoing Event</h4>
            <Formik
              initialValues={{ endedAt: '' }}
              validate={(values) => {
                const errors = {};

                if (!values.endedAt) {
                  errors.endedAt = '* Required';
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                // if we take a long time, let's tell someone
                const errorTimeout = setTimeout(() => {
                  toast.error('This is took too long', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });

                  setSubmitting(false);
                }, 10_000); // 10 seconds

                // Preprocess
                const datetime = dayjs.tz(values.endedAt).unix();

                // make request
                const res = await endFault({
                  variables: {
                    faultId: faultId,
                    endedAt: datetime,
                  },
                });

                clearTimeout(errorTimeout);

                // check if we got an error bac or not
                if (res?.errors) {
                  console.error(res.errors);
                  toast.error('An Error Occurred. Check console for details', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                } else {
                  toast.success('Fault Ended', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="FaultCodeDetails-editForm">
                  <Field type="datetime-local" name="endedAt" />

                  <ErrorMessage name="endedAt" component="div" className="FaultCodeDetails-editFormErrorMessage" />

                  {/* TODO: Use Matthew's Button */}
                  <button className="FaultCodeDetails-editFormSubmit" type="submit" disabled={isSubmitting}>
                    End Fault
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        )}

        <h4>Add Comment</h4>
        <MDEditor value={newComment} onChange={onChangeNewComment} />
        <FeedFloDropDown
          label="Root Cause"
          defaultTitle="Select a Root Cause"
          defaultSelected={newRootCause || ''}
          list={rootCauses}
          onChange={onChangeRootCause}
        />
        <FeedFloButton
          className="CommentsTab-button"
          type="secondary"
          disabled={newComment.length === 0}
          onClick={onSubmitNewComment}
        >
          Submit
        </FeedFloButton>
      </div>
      <h4>Comments:</h4>
      <div className="CommentsTab-commentContainer">
        {comments.map((comment) => (
          <CommentBlock
            key={comment.id}
            id={comment.id}
            avatar={userData?.[comment.user_id]?.avatar || UNKNOWN_USER_AVATAR}
            name={userData?.[comment.user_id]?.name || `${UNKNOWN_USER_NAME} (${comment.user_id})`}
            comment={`${comment.comment}\n\n RootCause: ${comment.fault_root_cause?.name || 'Not Set'}`}
            createdAt={comment.created_at}
            updatedAt={comment.updated_at}
            isAuthor={comment.user_id === user?.id}
          />
        ))}
      </div>

      <h4>Notifications:</h4>
      <div className="FaultCodeDetails-messages">
        {fault?.fault_messages?.map((faultMessage) => {
          const {
            transported_as: transportedAs,
            id: id,
            sent_at: sentAt,
            user_id: userId,
            sent_to,
          } = faultMessage.message;

          let sentTo = '???';
          try {
            // client-side redacting: not great, but an internal-only page
            // avoiding extra variables nonetheless
            sentTo = JSON.stringify({
              ...JSON.parse(sent_to),
              token: `REDACTED...${JSON.parse(sent_to).token?.slice(-6, -1)}`,
            });
          } catch (e) {
            if (sent_to?.[0] === '+') {
              sentTo = sent_to; // probably a phone number
            }
          }

          let msgType = '';
          if (transportedAs === 'email') msgType = '📧';
          if (transportedAs === 'sms') msgType = '📱';
          return (
            <div key={id}>
              {msgType} | {dayjs.tz(1000 * sentAt).format(DATE_TIME_FORMAT_MONTH_DAY_YEAR_HOUR_MINUTE_SECOND)} |{' '}
              {sentTo} | {userData?.[userId]?.name}
            </div>
          );
        })}
      </div>
      <h4>
        Device Data:{' '}
        <Link
          target="_blank"
          to={`/rawData/${fault.device.id}?to=${fault.ended_at || Math.floor(now / 1000)}&from=${fault.started_at}`}
        >
          📈
        </Link>
      </h4>

      <div className="FaultCodeDetails-sourceData">
        <h4>Source Data:</h4>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    </div>
  );
}

FaultCodeDetails.propTypes = {
  faultId: PropTypes.string,
  onFaultLoad: PropTypes.func,
};
