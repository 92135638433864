import { useState } from 'react';
import PropTypes from 'prop-types';
import { StatusEnum } from '../../utils/enums';
import useAlertStatusBadges from '../../utils/hooks/useAlertStatusBadges';
import { censorTypes, useCensor } from '../../utils/hooks/useCensor/useCensor';
import Button from '../../atoms/Button';
import Card from '../../atoms/Card';
import CollapsibleSection from '../../atoms/CollapsibleSection';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';
import StatusBadge from '../../atoms/StatusBadge';
import StatusIcon from '../../atoms/StatusIcon';
import FeedLineStatusHeader from '../../molecules/FeedLineStatusHeader';
import DeviceStatusCard from '../DeviceStatusCard';
import './FeedLineStatusCard.scss';
import withStaffOnlyIdWrapper from '../../utils/withStaffOnlyIdWrapper';

function FeedLineStatusCardView({
  loading = false,
  className = '',
  isMetric = false,
  nofeedTimeInSeconds,
  activeFaultCodes = [],
  feedLineName = '',
  feedLineLink = '',
  isFeedLineFull = false,
  isAugerRunning = false,
  isCalculating = false,
  lastFeedingEventStartInSeconds = 0,
  weightInGrams = 0,
  durationInSeconds = 0,
  deviceList = [],
  calibrationStatus,
  feedLineId = '',
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { censor } = useCensor();
  const { getAlertStatusBadges } = useAlertStatusBadges();
  const { badges: alertStatusBadges } = getAlertStatusBadges({
    nofeedTimeInSeconds,
    activeFaultCodes: Array.from(new Set(activeFaultCodes.map((f) => f.code)).values()),
  });

  if (loading) {
    return (
      <Card
        loading={loading}
        className={`FeedLineStatusCard ${className}`}
        contentClassName="FeedLineStatusCard-content"
        accentLocation="left"
      >
        <div className="FeedLineStatusCard-feedLineInfo">
          <div className="FeedLineStatusCard-header">
            <LoadingSkeleton className="FeedLineStatusCard-name--loading" />
            <Button
              className="FeedLineStatusCard-viewDetails"
              disabled={loading}
              variant="text"
              color="success"
              content="View Details"
            />
          </div>
          <FeedLineStatusHeader loading={loading} />
        </div>
        <CollapsibleSection
          loading={loading}
          headerClassName="FeedLineStatusCard-deviceSection FeedLineStatusCard-deviceSection--loading"
        />
      </Card>
    );
  }

  const onToggleDeviceSection = () => {
    setIsExpanded(!isExpanded);
  };

  const renderAlertStatusBadges = () => {
    return (
      <div className="FeedLineStatusCard-statusBadges">
        {alertStatusBadges.map((badge) => (
          <StatusBadge key={badge.text} status={badge.status} icon={badge.icon} text={badge.text} />
        ))}
      </div>
    );
  };

  const renderDeviceHeaderBadges = () => {
    if (isExpanded) {
      return null;
    }

    return (
      <div className="FeedLineStatusCard-statusBadges">
        {deviceList.map((device) => {
          if (device?.isOnline) {
            return <StatusBadge key={device.id} status="success" icon="logo" text="Online" />;
          }

          return <StatusBadge key={device.id} status="error" icon="warning" text="OFFLINE" />;
        })}
      </div>
    );
  };

  let status = StatusEnum.Success;
  if (nofeedTimeInSeconds !== null) {
    if (nofeedTimeInSeconds < 0) {
      status = StatusEnum.Warning;
    } else if (nofeedTimeInSeconds > 0) {
      status = StatusEnum.Error;
    }
  }
  const IdWrappedName = withStaffOnlyIdWrapper(censor(feedLineName, censorTypes.feedline));
  return (
    <Card
      className={`FeedLineStatusCard ${className}`}
      contentClassName="FeedLineStatusCard-content"
      accentLocation="left"
      status={status}
    >
      <div className="FeedLineStatusCard-feedLineInfo">
        <div className="FeedLineStatusCard-header">
          <div className="FeedLineStatusCard-name">
            {status !== StatusEnum.Success ? <StatusIcon status={status} /> : null}
            <IdWrappedName data={feedLineId} hoverText="Copy Feed Line ID" />
            {renderAlertStatusBadges()}
          </div>
          <Button
            className="FeedLineStatusCard-viewDetails"
            variant="text"
            color="success"
            content="View Details"
            linkTo={feedLineLink}
          />
        </div>
        <FeedLineStatusHeader
          isMetric={isMetric}
          isFeedLineFull={isFeedLineFull}
          isAugerRunning={isAugerRunning}
          isCalculating={isCalculating}
          lastFeedingEventStartInSeconds={lastFeedingEventStartInSeconds}
          weightInGrams={weightInGrams}
          durationInSeconds={durationInSeconds}
          calibrationStatus={calibrationStatus}
        />
      </div>
      <CollapsibleSection
        headerClassName="FeedLineStatusCard-deviceSection"
        title="Sensors"
        leftHeaderContent={renderDeviceHeaderBadges()}
        isExpanded={isExpanded}
        onExpandCollapse={onToggleDeviceSection}
      >
        <div className="FeedLineStatusCard-deviceList">
          {deviceList.map((device) => (
            <DeviceStatusCard
              key={device.id}
              ffId={device.id}
              isOnline={device.isOnline}
              serial={device.serial}
              feedLineName={device.feedLineName}
              lastUploadInSeconds={device.lastUploadInSeconds}
            />
          ))}
        </div>
      </CollapsibleSection>
    </Card>
  );
}

FeedLineStatusCardView.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  isMetric: PropTypes.bool,
  nofeedTimeInSeconds: PropTypes.number,
  activeFaultCodes: PropTypes.array,
  feedLineName: PropTypes.string,
  feedLineLink: PropTypes.string,
  isFeedLineFull: PropTypes.bool,
  isAugerRunning: PropTypes.bool,
  isCalculating: PropTypes.bool,
  lastFeedingEventStartInSeconds: PropTypes.number,
  weightInGrams: PropTypes.number,
  durationInSeconds: PropTypes.number,
  deviceList: PropTypes.array,
  calibrationStatus: PropTypes.string,
  feedLineId: PropTypes.string,
};

export default FeedLineStatusCardView;
