import PropTypes from 'prop-types';
import { weightLargeUnitLabel } from '../../../../utils/unitConversion';
import Card from '../../../../atoms/Card';
import TripCard from './TripCard';
import './TripList.scss';

function TripList({
  loading = false,
  date = null,
  isMetric = false,
  trips = [],
  tripsToDisplay = null,
  chevronVisible = false,
  onTripCardClick = () => {},
  onSeeAllTripsClick = () => {},
}) {
  // By default, don't limit the number of visible trips
  const numTripsToDisplay = tripsToDisplay || trips.length;
  const numOtherTrips = Math.max(trips.length - numTripsToDisplay, 0);
  const visibleTrips = trips.slice(0, numTripsToDisplay);

  const onSeeAllTripsCardClick = () => {
    onSeeAllTripsClick(date.clone());
  };

  const onSeeAllTripsCardKeyDown = (e) => {
    if (e.key !== 'Enter' && e.code !== 'Space') {
      return;
    }

    e.preventDefault();
    onSeeAllTripsCardClick();
  };

  const renderSeeAllTripsCard = () => {
    if (visibleTrips.length >= trips.length) {
      return null;
    }

    return (
      <Card
        className="TripList-card TripList-seeAllTrips"
        contentClassName="TripList-seeAllTripsContent"
        onClick={onSeeAllTripsCardClick}
        onKeyDown={onSeeAllTripsCardKeyDown}
      >
        <div className="TripList-numOtherTrips">{`+${numOtherTrips} Orders`}</div>
        <div className="TripList-seeAllTripsLabel">See all orders</div>
      </Card>
    );
  };

  if (loading) {
    return (
      <div className="TripList">
        {Array(numTripsToDisplay)
          .fill()
          .map((_, i) => (
            <TripCard key={i} loading={true} />
          ))}
      </div>
    );
  }

  return (
    <div className="TripList">
      {visibleTrips.map((trip) => {
        const deliveryDetailRows = [];
        let tripMassInLargeUnits = 0;

        trip.deliveries.forEach((delivery) => {
          const deliveryMassInLargeUnits = delivery.deliveredMassInLargeUnits || delivery.orderedMassInLargeUnits;
          const deliveryUnitLabel = `${weightLargeUnitLabel(isMetric)}${deliveryMassInLargeUnits === 1 ? '' : 's'}`;

          deliveryDetailRows.push(`Bin ${delivery?.binName} - ${deliveryMassInLargeUnits} ${deliveryUnitLabel}`);
          tripMassInLargeUnits += deliveryMassInLargeUnits;
        });

        tripMassInLargeUnits = Math.round(tripMassInLargeUnits);
        const tripUnitLabel = `${weightLargeUnitLabel(isMetric)}${tripMassInLargeUnits === 1 ? '' : 's'}`;

        return (
          <TripCard
            key={trip.id}
            id={trip.id}
            date={date}
            status={trip.status}
            barnName={trip.barnName}
            mass={`${tripMassInLargeUnits} ${tripUnitLabel}`.toLowerCase()}
            deliveryDetailRows={deliveryDetailRows}
            chevronVisible={chevronVisible}
            onClick={onTripCardClick}
          />
        );
      })}
      {renderSeeAllTripsCard()}
    </div>
  );
}

TripList.propTypes = {
  loading: PropTypes.bool,
  date: PropTypes.object,
  isMetric: PropTypes.bool,
  trips: PropTypes.array,
  tripsToDisplay: PropTypes.number,
  chevronVisible: PropTypes.bool,
  onTripCardClick: PropTypes.func,
  onSeeAllTripsClick: PropTypes.func,
};

export default TripList;
