import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { convertGramsToSmallUnits, convertSmallUnitsToGrams } from '../../utils/unitConversion';
import FeedFloButton from '../../atoms/FeedFloButton';
import FeedFloDropDown from '../../atoms/FeedFloDropDown';
import FeedFloNumInput from '../../atoms/FeedFloNumInput';
import Shroud from '../../atoms/Shroud';
import './FeederEditorDialog.scss';

const FIVE_HUNDRED_LBS_IN_GRAMS = 226796;
const TWO_HUNDRED_FIFTY_LBS_IN_GRAMS = 113398;
const CUSTOM_CAPACITY = -1;
const imperialCapacities = new Set([FIVE_HUNDRED_LBS_IN_GRAMS, TWO_HUNDRED_FIFTY_LBS_IN_GRAMS]);

function FeederEditorDialogView({ feedLines = [], editFeeder = null, onCancel = () => {}, onSave = () => {} }) {
  const [feedLineID, setFeedLineID] = useState(editFeeder?.feed_line?.id || '');
  const [capacity, setCapacity] = useState(editFeeder?.capacity_in_grams || '');
  const [customCapacity, setCustomCapacity] = useState('');
  const [quantity, setQuantity] = useState(`${editFeeder?.quantity || ''}`);
  const feedLineList = useMemo(() => {
    return feedLines.map((feedLine) => ({
      id: feedLine.id,
      name: feedLine.name,
      selected: feedLine.id === editFeeder?.feed_line?.id,
    }));
  }, [feedLines]);
  const capacityList = useMemo(() => {
    return [
      {
        id: FIVE_HUNDRED_LBS_IN_GRAMS,
        name: '500 lbs',
        selected: editFeeder?.capacity_in_grams === FIVE_HUNDRED_LBS_IN_GRAMS,
      },
      {
        id: TWO_HUNDRED_FIFTY_LBS_IN_GRAMS,
        name: '250 lbs',
        selected: editFeeder?.capacity_in_grams === TWO_HUNDRED_FIFTY_LBS_IN_GRAMS,
      },
      {
        id: CUSTOM_CAPACITY,
        name: 'Custom',
        selected: editFeeder !== null && !imperialCapacities.has(editFeeder?.capacity_in_grams),
      },
    ];
  }, [editFeeder]);
  const feedLineValid = feedLineID !== '';
  const capacityValid = capacity !== '';
  const customCapacityValid = (capacity === CUSTOM_CAPACITY && customCapacity !== '') || capacity !== CUSTOM_CAPACITY;
  const quantityValid = quantity !== '' && parseInt(quantity, 10) > 0;
  const isFormValid = feedLineValid && capacityValid && customCapacityValid && quantityValid;

  // If we're editing a feeder, load the configurations from that feeder into the dialog
  useEffect(() => {
    if (editFeeder === null) {
      return;
    }

    if (editFeeder?.capacity_in_grams === FIVE_HUNDRED_LBS_IN_GRAMS) {
      setCapacity(FIVE_HUNDRED_LBS_IN_GRAMS);
    } else if (editFeeder?.capacity_in_grams === TWO_HUNDRED_FIFTY_LBS_IN_GRAMS) {
      setCapacity(TWO_HUNDRED_FIFTY_LBS_IN_GRAMS);
    } else {
      const convertedCustomCapacity = convertGramsToSmallUnits(false, editFeeder?.capacity_in_grams); // This will need to be updated if we ever support metric values in this UI
      setCapacity(CUSTOM_CAPACITY);
      setCustomCapacity(`${convertedCustomCapacity}`);
    }
  }, [editFeeder]);

  const onChangeFeedLine = (e) => {
    setFeedLineID(e.id);
  };

  const onChangeCapacity = (e) => {
    setCapacity(e.id);
  };

  const onChangeCustomCapacity = (e) => {
    setCustomCapacity(`${e}`);
  };

  const onChangeQuantity = (e) => {
    setQuantity(`${e}`);
  };

  const onCancelClick = () => {
    onCancel();
  };

  const onSaveClick = () => {
    const quantityAmount = parseFloat(quantity, 10);
    let capacityInGrams;

    if (capacity === CUSTOM_CAPACITY) {
      const capacityAmount = parseFloat(customCapacity, 10);
      capacityInGrams = convertSmallUnitsToGrams(false, capacityAmount); // This will need to be updated if we ever support metric values in this UI
    } else {
      capacityInGrams = capacity;
    }

    onSave(editFeeder?.id, feedLineID, Math.round(capacityInGrams), quantityAmount);
  };

  return (
    <div className="FeederEditorDialog">
      <div className="FeederEditorDialog-content">
        <div className="FeederEditorDialog-form">
          <FeedFloDropDown
            className="FeederEditorDialog-feedLine"
            label="Feed Line"
            defaultTitle="Select a line"
            list={feedLineList}
            onChange={onChangeFeedLine}
          />
          <FeedFloDropDown
            label="Capacity"
            defaultTitle="Select a capacity"
            list={capacityList}
            onChange={onChangeCapacity}
          />
          <div
            className={`FeederEditorDialog-customCapacityWrapper ${
              capacity === CUSTOM_CAPACITY ? '' : 'FeederEditorDialog-customCapacityWrapper--hidden'
            }`}
          >
            <FeedFloNumInput
              label="Custom Capacity"
              inputClassName="FeederEditorDialog-customCapacity"
              placeholder="Custom Capacity"
              value={customCapacity}
              onChange={onChangeCustomCapacity}
            />
            <span className="FeederEditorDialog-customCapacityUnits">lbs</span>
          </div>
          <FeedFloNumInput
            inputClassName="FeederEditorDialog-quantityInput"
            label="Quantity"
            value={quantity}
            onChange={onChangeQuantity}
          />
        </div>
        <div className="FeederEditorDialog-buttonRow">
          <FeedFloButton type="primary" onClick={onCancelClick}>
            Cancel
          </FeedFloButton>
          <FeedFloButton type="secondary" disabled={!isFormValid} onClick={onSaveClick}>
            Save
          </FeedFloButton>
        </div>
      </div>
      <Shroud />
    </div>
  );
}

FeederEditorDialogView.propTypes = {
  feedLines: PropTypes.array,
  editFeeder: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default FeederEditorDialogView;
