import { gql } from '@apollo/client';

export const ANIMAL_GROUP_HEADER_QUERY = gql`
  query AnimalGroupHeader_Query($barnId: uuid!, $cutoff: bigint) {
    animal_group(
      where: {
        deleted_at: { _is_null: true }
        barn_id: { _eq: $barnId }
        _or: [{ ended_at: { _is_null: true } }, { ended_at: { _gte: $cutoff } }]
      }
      order_by: { ended_at: desc_nulls_first }
      limit: 1
    ) {
      approximate_birthdate
      ended_at
      expected_ended_at
      external_id
      id
      started_at
    }
  }
`;

export const ANIMAL_GROUP_FOR_ID_QUERY = gql`
  query AnimalGroupForId_Query($animalGroupId: uuid!) {
    animal_group(where: { id: { _eq: $animalGroupId } }) {
      approximate_birthdate
      ended_at
      expected_ended_at
      external_id
      id
      started_at
    }
  }
`;
