import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'react-phone-input-2/lib/plain.css';
import './FeedFloNumInput.scss';

/***
 * ? DEPRECATED ?
 */
function FeedFloNumInput({
  className = '',
  inputClassName = '',
  label = '',
  placeholder = '',
  disabled = false,
  maxLength,
  allowSingleAndLeadingZeros = true,
  value: valueProp = '',
  onChange = () => {},
}) {
  const [valueString, setValueString] = useState('');

  useEffect(() => {
    setValueString(valueProp);
  }, [valueProp]);

  return (
    <div className={`FeedFloNumInput ${disabled ? 'disabled' : ''} ${className}`}>
      <label className="FeedFloNumInput-label">{label}</label>
      <input
        onChange={(e) => {
          let newInputValue = e.target.value.replace(/[^0-9]/g, '');
          if (!allowSingleAndLeadingZeros) {
            newInputValue = newInputValue.replace(/^[^1-9]\d*$/g, '');
          }

          setValueString(newInputValue);
          onChange(newInputValue);
        }}
        placeholder={placeholder}
        className={`FeedFloNumInput-input ${inputClassName}`}
        type="text"
        value={typeof valueString === 'string' ? valueString : ''}
        disabled={disabled}
        maxLength={maxLength}
      />
    </div>
  );
}

FeedFloNumInput.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  value: PropTypes.string,
  allowSingleAndLeadingZeros: PropTypes.bool,
  onChange: PropTypes.func,
};

export default FeedFloNumInput;
