import { timeFormat } from 'd3-time-format';
import dayjs from 'dayjs';

const SEC_PER_DAY = 24 * 60 * 60;

// accessors
export const getX = (point) => point.timestamp;
export const getY = (point) => point.value;

export function tickFormatFactory(start, end) {
  const daysInRange = (end - start) / SEC_PER_DAY;

  // MultiDay charts only have 1 tick per day
  if (daysInRange > 1) {
    return (value) => timeFormat('%b %d')(value * 1000);
  }

  // Single Day Charts show the Date on the edges and the time on the ticks.
  return (value, index) => {
    if (index === 0 || index % 24 === 0) {
      return timeFormat('%b %d')(value * 1000); // abbreviated month name + Day of Month ex:Jan 15
    }
    return timeFormat('%I%p')(value * 1000); // Hour & AM/PM ex: 12am
  };
}

export function calculateXAxisTickValues(start, end) {
  const startDate = dayjs(1000 * start);
  const endDate = dayjs(1000 * end);

  const daysInRange = endDate.diff(startDate, 'days');

  let interval = 'day';
  if (daysInRange > 120) {
    interval = 'month';
  } else if (daysInRange > 30) {
    interval = 'week';
  } else if (daysInRange > 0) {
    interval = 'day';
  } else {
    interval = 'hour';
  }
  const ticks = [];
  let current = startDate;

  current = startDate.startOf('day');
  ticks.push(current.unix());
  current = current.startOf(interval).add(1, interval);

  while (current.isBefore(endDate)) {
    ticks.push(current.unix());
    current = current.add(1, interval);
  }
  ticks.push(endDate.startOf('day').add(1, 'day').unix());

  return ticks;
}

/**
 * Calculate the consumption rate (value per millisecond) across a consumption frame.
 *
 * @param {{value: number, start: number, end: number}} frame
 * @returns {number}
 */
function getConsumptionRate(frame) {
  return frame.value / (frame.end - frame.start);
}

/**
 * Trims a consumption frame extending beyond the bounds of our date range.
 *
 * @param {{value: number, start: number, end: number}} frame
 * @param {number} start the start date timestamp in milliseconds.
 * @param {number} end the end date timestamp in milliseconds.
 * @returns {{value: number, start: number, end: number}} the given consumption frame within the given bounds.
 */
function trimConsumptionFrame(frame, start, end) {
  if (frame.start < start && frame.end > start) {
    // Overlaps over start
    //     start |---------... end
    // e.s |---------... e.e
    frame.start = start;
    frame.value *= (frame.end - start) / (frame.end - frame.start); // Finds Value for the remaining time.
  }
  if (frame.end > end && frame.start < end) {
    // start ...---------| end
    //        e.s ...---------| e.e
    frame.end = end;
    frame.value *= (end - frame.start) / (frame.end - frame.start); // Finds Value for the remaining time.
  }
  return frame;
}

/**
 * Given a pair of potentially overlapping frames, generate new frames without any overlap.
 * Overlapping frames will be trimmed and have their values reduced proportionally,
 *  and a new frame with the trimmed values will be inserted in between.
 *
 * @param {{value: number, start: number, end: number}} frame1
 * @param {{value: number, start: number, end: number}} frame2
 * @returns {Array<{value: number, start: number, end: number}>} an array of non-overlapping consumption frames.
 */
function breakDownPair(frame1, frame2) {
  if (frame1.start > frame2.start) {
    // Frame 1 and frame 2 are out of order, so swap them.
    [frame1, frame2] = [frame2, frame1];
  }

  if (frame1.end < frame2.start) {
    // Frame 1 ends before frame 2 begins, so there is no overlap.
    // Return the unaltered pair.
    return [frame1, frame2];
  }

  if (frame1.start === frame2.start && frame1.end === frame2.end) {
    // Frame 1 and frame 2 perfectly overlap.
    // Return a single frame that combines both values.
    return [
      {
        end: frame1.end,
        start: frame1.start,
        value: frame1.value + frame2.value,
      },
    ];
  }

  // There is imperfect overlap in the remaining cases.

  // Calculate the consumption rate (value per millisecond) across both consumption frames.
  // Used for calculating remaining value after shortening a frame, and for calculating the
  //  appropriate value for newly generated frames.
  const consumptionRate1 = getConsumptionRate(frame1);
  const consumptionRate2 = getConsumptionRate(frame2);

  let output = [];

  // Trim the end of frame 1 to exclude the overlap.
  output.push({ start: frame1.start, end: frame2.start, value: consumptionRate1 * (frame2.start - frame1.start) });

  if (frame1.end < frame2.end) {
    // Frame 1 and frame 2 partially overlap.
    // Generate a new frame that represents the overlap, with a value calculated using both consumption rates.
    output.push({
      start: frame2.start,
      end: frame1.end,
      value: (consumptionRate1 + consumptionRate2) * (frame1.end - frame2.start),
    });
    // Trim the start of frame 2 to exclude the overlap.
    output.push({ start: frame1.end, end: frame2.end, value: consumptionRate2 * (frame2.end - frame1.end) });
  } else {
    // Frame 2 is entirely contained within frame 1.
    // Generate a new frame that represents the overlap, with a value calculated using both consumption rates.
    output.push({
      start: frame2.start,
      end: frame2.end,
      value: (consumptionRate1 + consumptionRate2) * (frame2.end - frame2.start),
    });
    // Generate a new frame from the tail of frame 1 after frame 2 has ended.
    output.push({ start: frame2.end, end: frame1.end, value: consumptionRate1 * (frame1.end - frame2.end) });
  }
  // Filters out frames with invalid values, or frames that end before they begin.
  output = output.filter((frame) => typeof frame.value === 'number' && frame.value > 0 && frame.start < frame.end);

  const frameSummarizer = (sum, frame) => sum + frame.value;
  const ogSum = [frame1, frame2].reduce(frameSummarizer, 0);
  const newSum = output.reduce(frameSummarizer, 0);

  if (Math.abs(ogSum - newSum) > 1) {
    console.error(`Error simplifying consumption frames: (Original sum: ${ogSum}, new sum: ${newSum})`);
  }

  return output;
}

/**
 * Removes overlap between frames, trimming some frames and generating new ones.
 *
 * @param {Array<{value: number, start: number, end: number}>} consumptionFrames an array of consumption frames.
 * @returns {Array<{value: number, start: number, end: number}>} an array of non-overlapping consumption frames.
 */
function frameOverlapRemover(consumptionFrames) {
  let trimmedFrames = structuredClone(consumptionFrames);
  let i = 0;
  // Compare all adjacent consumption frames.
  while (i < trimmedFrames.length - 1) {
    const frame1 = trimmedFrames[i];
    const frame2 = trimmedFrames[i + 1];

    if (frame1.end > frame2.start) {
      // These frames overlap.
      // Generate equivalent non-overlapping frames.
      const splitFrames = breakDownPair(frame1, frame2);
      // Replace the overlapping frames with the newly generated frames.
      trimmedFrames.splice(i, 2, ...splitFrames);
      trimmedFrames = trimmedFrames.sort((frame1, frame2) => frame1.start - frame2.start);

      i = 0;
    } else {
      i += 1;
    }
  }

  return trimmedFrames;
}

/**
 * Calculates the cumulative line plot for a list of consumption frames.
 *
 * @param {Array<{value: number, start: number, end: number}>} consumptionFrames
 * @param {number} start the chart start date timestamp in milliseconds.
 * @param {number} end the chart end date timestamp in milliseconds.
 *
 * @returns {Array<{timestamp: number, value: number}>}
 */
export function createCumulativePlot(consumptionFrames, start, end) {
  // Break apart overlapping consumption frames.
  consumptionFrames = frameOverlapRemover(consumptionFrames);
  let total = 0;

  const cumulativePlot = consumptionFrames.reduce((cumulativePlot, frame) => {
    // Trim this frame to fit within the start and end timestamp bounds.
    frame = trimConsumptionFrame(frame, start, end);

    // Include two points to make a line out of this consumption frame.
    // The first point is the running total not including this frame's value.
    // But test for cases where one frame ends and another begins on the same timestamp to avoid duplicating points.
    if (frame.start !== cumulativePlot[cumulativePlot.length - 1]?.timestamp) {
      cumulativePlot.push({ timestamp: frame.start, value: total });
    }
    // Include this frame's value in the cumulative total.
    total += frame.value;
    // The second point is the running total including this frame's value.
    cumulativePlot.push({ timestamp: frame.end, value: total });

    return cumulativePlot;
  }, []);

  // Include an initial point at the start timestamp.
  cumulativePlot.unshift({ timestamp: start, value: 0 });

  return cumulativePlot;
}

/**
 * Calculates the daily line plot for a list of consumption frames.
 *
 * @param {Array<{value: number, start: number, end: number}>} consumptionFrames
 * @param {number} start the chart start date timestamp in milliseconds.
 * @param {number} end the chart end date timestamp in milliseconds.
 *
 * @returns {Array<{timestamp: number, value: number}>}
 */
export function createDailyPlot(consumptionFrames, start, end) {
  // Create a map of all days within the bounds of start and end, set to 0 for each day.
  const dailyMap = new Map();
  const endDay = dayjs.tz(1000 * end).startOf('day');
  let newDay = dayjs.tz(1000 * start).startOf('day');
  while (newDay <= endDay) {
    dailyMap.set(newDay.unix(), 0);
    newDay = newDay.add(1, 'day');
  }

  // Put each frame into the daily map.
  consumptionFrames.forEach((frame) => {
    const frameStart = dayjs.tz(1000 * frame.start);
    const frameEnd = dayjs.tz(1000 * frame.end);
    const frameStartDay = dayjs.tz(frameStart).startOf('day');
    const frameEndDay = dayjs.tz(frameEnd).startOf('day');

    if (frameStartDay.unix() === frameEndDay.unix()) {
      // This entire consumption frame is completely contained in a single day.
      const newValue = dailyMap.get(frameStartDay.unix()) + frame.value;
      dailyMap.set(frameStartDay.unix()).set(frameStartDay.unix(), newValue);
    } else {
      // This consumption frame spans at least two days.
      const consumptionRate = getConsumptionRate(frame);

      // Calculate how duration is split across days.
      let currDay = dayjs.tz(frameStartDay);
      while (currDay < frameEnd) {
        const nextDay = currDay.add(1, 'day');

        // Select the start of the day or the start of the frame, whichever happened later.
        const dailyStart = Math.max(currDay, frameStart);
        // Select the end of the day or the end of the frame, whichever happened earlier.
        const dailyEnd = Math.min(nextDay, frameEnd);

        // Calculate the frame duration within this day.
        const duration = dayjs.tz(dailyEnd).subtract(dailyStart).unix();
        // Calculate the value over that duration.
        const dailyValue = duration * consumptionRate;

        // Add new value to daily map.
        const newValue = dailyMap.get(currDay.unix()) + dailyValue;
        dailyMap.set(currDay.unix(), newValue);

        currDay = currDay.add(1, 'day');
      }
    }
  });

  const dailyPlot = Array.from(dailyMap.entries()).map(([key, value]) => {
    return { timestamp: key, value };
  });

  return dailyPlot;
}

/**
 * Takes an input of line data and returns a rolling average.
 *
 * @param {Array<{value: number, timestamp: number}>} lineDataInput
 * @param {number} window How many points backwards to average.
 * @returns {Array<{value: number, timestamp: number}>}
 */
export function calculateRollingAverage(lineDataInput, window) {
  return lineDataInput.map(({ value, timestamp }, index) => {
    let sum = value;
    let denominator = 1;
    for (let i = 1; i <= window; i++) {
      const pastDay = lineData[index - i];
      // When looking back to a previous day, make sure that we're not overrunning the plot boundary.
      if (pastDay) {
        sum += pastDay.value;
        denominator += 1;
      }
    }
    const average = sum / denominator;

    return { value: average, timestamp };
  });
}

/****** from BinLevelPrediction Module START If you change these Consider updating in BinLevelPrediction too **** */
/**
 * Exports Functions to create TimeSeries
 *
 * Includes internal utility functions
 */
/*
 * Sort in ascending order
 */
export function sortFuncByTime({ occurredAt: lAt, value: lVal }, { occurredAt: rAt, value: rVal }) {
  let timeIsValid = false;
  let valueIsValid = false;

  timeIsValid = timeIsValid || (Number.isFinite(lAt) && Number.isFinite(rAt));
  timeIsValid = timeIsValid || (lAt instanceof Date && rAt instanceof Date);
  valueIsValid = timeIsValid || (Number.isFinite(lVal) && Number.isFinite(rVal));

  if (!timeIsValid) {
    throw new InvalidInput('time values to sort are not valid types');
  }
  if (!valueIsValid) {
    throw new InvalidInput("field 'value; to sort are not valid types");
  }

  const tDelta = lAt - rAt;
  const vDelta = lVal - rVal;

  if (tDelta !== 0) return tDelta;

  return vDelta;
}

/*
 * Based on:
 *     https://stackoverflow.com/a/55261098
 *
 * But we modify the post to work on an array of objects rather
 * than an array of numbers.
 * We also make it a bit more verbose so that it easier to understand.
 */
export function cumulativeDataPoints(array, { initialValue = 0 } = {}) {
  const cumulativeSumFactory = (initValue) => {
    let sum = initValue;
    const adder = (item) => {
      sum += item.value;
      return {
        ...item,
        value: sum,
      };
    };
    return adder;
  };

  return array.map(cumulativeSumFactory(initialValue));
}

/**
 *
 *
 * @param {{value: number}} array Array of items with values to sum
 * @param {{initialValue: number, maxValue: number}} options change the initial and max values
 * @returns
 */
export function clampedCumulativeDataPoints(array, { initialValue = 0, maxValue = Number.MAX_SAFE_INTEGER } = {}) {
  const cumulativeSumFactory = (initValue) => {
    let sum = initValue;
    const adder = (item) => {
      if (item.value > 0 && sum + item.value >= maxValue) {
        /* Delivery: Overfill, so clamp.
              Can happen when device is offline and there's deliveries
              of when the device is under-predicting
        */
        sum = maxValue;
      } else if (sum + item.value < 0) {
        /* Consumption: Goes negative, so clamp.
            Can happen when we over-predict or are missing deliveries.
        */
        sum = 0;
      } else if (!Number.isNaN(item.value)) {
        // In most cases just add it up!
        // * note: item.value may be negative
        sum += item.value;
      }

      return {
        ...item,
        value: sum,
      };
    };
    return adder;
  };

  return array.map(cumulativeSumFactory(initialValue));
}

export function convertToBinLevelTimeSeries({
  feedEvents,
  deliveries,
  binOverrides,
  feedEventReason = 'Feed Event',
  binOverrideReason = 'Override',
  deliveryReason = 'Delivery',
  debugMode = false,
  binSetCapacityInGrams,
} = {}) {
  // collect all values that affect the bin level
  const massEvents = [
    // we negate the feed events as they remove from the bin level
    ...feedEvents.map((item) => ({ ...item, value: -item.value, reason: feedEventReason })),
    // we leave the deliveries as positive values as they add to the bin level
    // also note that we add "pivot" values so that a delivery jumps verticaly
    ...deliveries.map((item) => ({ ...item, value: 0, reason: deliveryReason })), // this adds the pivot
    ...deliveries.map((item) => ({ ...item, reason: deliveryReason })), // this addsd the actual delivery value
  ];

  // sort these events by time
  // note that if the time values match in the sort, then the smaller value if placed
  // this allows for pivot data points to happen before the delivery value
  massEvents.sort(sortFuncByTime);

  const massEventsBeforeEarliestOverride = massEvents.filter(
    (massEvent) => massEvent.occurredAt < binOverrides[0].occurredAt,
  );
  const tempEarlyBinLevels = cumulativeDataPoints(massEventsBeforeEarliestOverride.reverse());

  // Note: Didn't have an example to test this against. Seems like it would
  // HOWEVER, I think there's some edge cases where this might not work
  // then again we're not tracking sums so maybe it's fine?
  const binLevelBeforeEarliestOverride = tempEarlyBinLevels.map((binLevel) => {
    if (-binLevel.value + binOverrides[0].value < 0 && !debugMode) {
      // clamp to min 0 if it would be below
      return {
        ...binLevel,
        value: 0,
      };
    }
    if (-binLevel.value + binOverrides[0].value > binSetCapacityInGrams && !debugMode) {
      // clamp to max capacity
      return {
        ...binLevel,
        value: binSetCapacityInGrams,
      };
    }
    return {
      ...binLevel,
      value: -binLevel.value + binOverrides[0].value,
    };
  });
  binLevelBeforeEarliestOverride.reverse();

  const binLevelDuringOverrides = binOverrides
    .map((binOverride, index, array) => {
      const { occurredAt: startedAt, value: binLevelValue } = binOverride;
      const endedAt = array?.[index + 1]?.occurredAt;
      const relatedMassEvents = massEvents.filter((massEvent) => {
        let isRelated = startedAt < massEvent.occurredAt;
        if (Number.isFinite(endedAt)) {
          isRelated = isRelated && massEvent.occurredAt <= endedAt;
        }
        return isRelated;
      });

      // Use different function if in debug, otherwise we want clamping
      let calculateCumulativeDataPoints = clampedCumulativeDataPoints;
      if (debugMode) {
        calculateCumulativeDataPoints = cumulativeDataPoints;
      }
      const cumulativeMassEvents = calculateCumulativeDataPoints(relatedMassEvents, {
        initialValue: binLevelValue,
        debugMode,
        maxValue: binSetCapacityInGrams,
      });

      // create final time series that start with the override
      return [{ ...binOverride, reason: binOverrideReason }, ...cumulativeMassEvents];
    })
    .flat();

  const binLevelTimeSeries = [...binLevelBeforeEarliestOverride, ...binLevelDuringOverrides];

  // OPT: We can optimize this for space or speed. Going for speed
  return binLevelTimeSeries.map((dataPoint) => ({
    ...dataPoint,
    occurredAt: new Date(dataPoint.occurredAt * 1000),
    rawOccurredAt: dataPoint.occurredAt,
  }));
}

/****** from BinLevelPrediction Module END If you change these Consider updating in  BinLevelPrediction too **** */
