import PropTypes from 'prop-types';
import { useMemo } from 'react';
import ComboboxField from './ComboboxField';
import { gql, useQuery } from '@apollo/client';

/**
 * A simple wrapper component if you just want to load a list of all the (non-deleted)
 * items a user can see in a table. Also handles hasura enum tables
 */
function ComboboxSelfLoadingField({
  fieldHasuraName = 'name', // name of the column in hasura
  fieldTableName, // name of the table in hasura
  isEnumTable = false,
  label,
  ...props
}) {
  // What are we showing people?
  const displayName = label || fieldHasuraName;

  // Set up a dynamic query
  const tableQuery = gql`
    query ComboboxSelfLoadingField_${fieldTableName} {
        ${fieldTableName}(where: { deleted_at: {_is_null: true}}) {
            id
            ${fieldHasuraName}
        }
    }
    `;

  const enumQuery = gql`
    query ComboboxSelfLoadingField_Enum_${fieldTableName} {
        ${fieldTableName} {
            value
            description
        }
    }
    `;

  // get us the initial values to display
  const { data, loading } = useQuery(isEnumTable ? enumQuery : tableQuery);

  // extract value from data if it applies
  const items = useMemo(
    () =>
      isEnumTable
        ? data?.[fieldTableName]?.map((x) => ({ ...x, name: x.value }))
        : data?.[fieldTableName]?.map((x) => ({ id: x.id, name: x[fieldHasuraName] })),
    [data],
  );

  return <ComboboxField label={displayName} loading={loading} items={items || []} {...props} />;
}
ComboboxSelfLoadingField.propTypes = {
  fieldHasuraName: PropTypes.string,
  fieldTableName: PropTypes.string.isRequired,
  isEnumTable: PropTypes.bool,
  label: PropTypes.string,
};
export default ComboboxSelfLoadingField;
