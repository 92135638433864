import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';
import './FeedFloLabsChart.scss';

/***
 * ? NEEDS REVIEW ?
 */
function FeedFloLabChart({ loading = false, layout, data, revision = 0 }) {
  if (loading) {
    return (
      <div className="FeedFloLabsChart">
        <LoadingSkeleton className="FeedFloLabsChart-loader FeedFloLabsChart-loader--large" />
      </div>
    );
  }

  return (
    <div className="FeedFloLabsChart">
      <Plot
        className="chart"
        revision={revision}
        data={data}
        layout={Object.assign(layout, {
          title: {
            text: '',
            font: {
              family: 'Inter, sans-serif',
              size: 12,
            },

            x: 0.05,
            xanchor: 'left',
            yanchor: 'top',
            y: 0.9,
            ...layout?.title,
          },
          margin: layout?.margin || { t: 40, b: 30, l: 30, r: 0 },
          legend: {
            x: 1,
            xanchor: 'left',
            y: 1,
          },
          xaxis: layout?.xaxis || {},
          yaxis: layout?.yaxis || {},
          yaxis2: layout?.yaxis2 || {},
        })}
        useResizeHandler
      />
    </div>
  );
}

FeedFloLabChart.propTypes = {
  loading: PropTypes.bool,
  layout: PropTypes.object,
  data: PropTypes.array,
  revision: PropTypes.number,
};

export default FeedFloLabChart;
