import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useSearchParamsState from '../../utils/hooks/useSearchParamsState';
import PropTypes from 'prop-types';
import { useMutation, useQuery, useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';
import uniqBy from 'lodash/uniqBy';
import { useAtom } from 'jotai';
import { convertLargeUnitsToGrams } from '../../utils/unitConversion';
import useUser from '../../utils/hooks/useUser';
import { CalendarMode, removeWeekends } from '../../organisms/Calendar';
import Button from '../../atoms/Button';
import Page from '../../atoms/Page';
import { LeftChevronIcon, RightChevronIcon } from '../../atoms/Icons';
import Select from '../../atoms/Select';
import ToggleSwitch from '../../atoms/ToggleSwitch';
import LeftSidebar from './components/LeftSidebar';
import MainCalendar from './components/MainCalendar';
import RightSidebar from './components/RightSidebar';
import { TripStatusNameConverter } from './enums';
import {
  dbTripToUITrip,
  formatAsFilterList,
  getBinMapFromList,
  getCalendarTitle,
  getTripMapFromList,
  onNetworkRequestError,
} from './helpers';
import { modeList, tripStatusList } from './lists';
import {
  InsertDeliveries,
  InsertTrip,
  GetBarnsQuery,
  GetTripsQuery,
  GetBarnsAndBinsQuery,
  UpdateDeliveries,
  UpdateTrip,
  GetTripQuery,
} from './queries';
import { barnFiltersAtom, calendarModeAtom, tripStatusFiltersAtom, showWeekendsAtom } from './storage';
import './FeedOrdersPage.scss';
import { censorTypes, useCensor } from '../../utils/hooks/useCensor/useCensor';

function FeedOrdersPage({ titleSegments = [] }) {
  const pageTitleSegments = useMemo(() => ['Feed Desk', ...titleSegments], []);
  const [focusTripId, setFocusTripId] = useSearchParamsState('trip', null);
  const { data: focusTripData } = useQuery(GetTripQuery, {
    variables: {
      tripId: focusTripId,
    },
    onError: (err) => {
      onNetworkRequestError(err);
    },
    skip: !focusTripId,
  });

  const client = useApolloClient();
  const { censor } = useCensor();

  const [calendarMode, setCalendarMode] = useAtom(calendarModeAtom);
  const [showWeekends, setShowWeekends] = useAtom(showWeekendsAtom);
  const today = useMemo(() => dayjs.tz().startOf('day'), []);
  const [leftSidebarVisible, setLeftSidebarVisible] = useState(true);
  const [rightSidebarVisible, setRightSidebarVisible] = useState(false);
  const [mainCalendarCurrentDate, setMainCalendarCurrentDate] = useState(today.clone());
  const [miniCalendarCurrentDate, setMiniCalendarCurrentDate] = useState(today.clone().startOf('month'));
  const [tripStatusFilters, setTripStatusFilters] = useAtom(tripStatusFiltersAtom);
  const [barnFilters, setBarnFilters] = useAtom(barnFiltersAtom);
  const [visibleDates, setVisibleDates] = useState([]);
  const [visibleDateSet, setVisibleDateSet] = useState(new Set());
  const { loading: barnFiltersLoading, data: barnData } = useQuery(GetBarnsQuery, {
    onCompleted: (data) => {
      // pre-process barns to be censored
      const barnData = data?.barn?.map((barn) => {
        const newBarn = {
          ...barn,
          organization: {
            ...(barn?.organization || {}),
            name: censor(barn?.organization?.name, censorTypes.organization),
          },
          name: censor(barn.name, censorTypes.barn),
        };
        return newBarn;
      });

      // Create a Set consisting of the active barn IDs we have locally (if any) for fast lookups
      const activeLocalBarnFilterSet = new Set();
      (barnFilters || []).forEach((barnFilter) => {
        if (barnFilter.enabled) {
          activeLocalBarnFilterSet.add(barnFilter.value);
        }
      });

      // Iterate through the list of barn filters from the server, enabling each one if:
      // 1. That barn filter also exists locally and is active, or
      // 2. No barn filters exist locally, so enable everything by default
      const remoteBarnFilters = formatAsFilterList(barnData || []);
      setBarnFilters(
        remoteBarnFilters.map((remoteBarnFilter) => {
          return {
            ...remoteBarnFilter,
            enabled: barnFilters.length === 0 || activeLocalBarnFilterSet.has(remoteBarnFilter.value),
          };
        }),
      );
    },
    onError: onNetworkRequestError,
  });

  const lowerBound = miniCalendarCurrentDate
    .subtract(1, 'month')
    .startOf('month')
    .startOf('day')
    .startOf('week')
    .unix();
  const upperBound = miniCalendarCurrentDate.add(1, 'month').startOf('month').endOf('day').endOf('week').unix();

  const { loading: tripsLoading, data: tripDataResult } = useQuery(GetTripsQuery, {
    variables: {
      lowerBound,
      upperBound,
    },
    onError: onNetworkRequestError,
  });

  const tripData = tripDataResult?.trip?.map((trip) => {
    return {
      ...trip,
      barn: {
        ...trip.barn,
        name: censor(trip.barn?.name, censorTypes.barn),
      },
      deliveries: trip?.deliveries.map((d) => {
        return {
          ...d,
          bin: {
            ...d.bin,
            name: censor(d.bin.name, censorTypes.bin),
            barn: {
              ...d.bin.barn,
              name: censor(d.bin.barn.name, censorTypes.barn),
              organization: {
                ...(d.bin.barn?.organization || {}),
                name: censor(d.bin.barn?.organization, censorTypes.organization),
              },
            },
          },
        };
      }),
    };
  });

  useEffect(() => {
    if (tripsLoading) return; // exit early

    const newTrips = tripData || [];
    const mergedTrips = [...newTrips, ...trips];

    // Clean up the trips, removing duplicates using the delivery id to determine uniqueness
    const uniqueTrips = uniqBy(mergedTrips, 'id');

    setTrips(uniqueTrips);

    if (!initialTripsLoaded) {
      setInitialTripsLoaded(true);
    }
  }, [tripsLoading, tripDataResult]);

  const [trips, setTrips] = useState([]);
  const [activeTrip, setActiveTrip] = useState(null);
  const { user } = useUser();
  const mainCalendarDateBlockRefs = useRef({});
  const isMetric = user?.isMetric || false;

  // Determine which trips are included in the map when updating filters
  const dateToTripsMap = useMemo(() => {
    const includedTripStatuses = new Set();
    const includedBarns = new Set();

    tripStatusFilters.forEach((tripStatusFilter) => {
      if (tripStatusFilter?.enabled) {
        includedTripStatuses.add(TripStatusNameConverter[tripStatusFilter.value]);
      }
    });

    barnFilters.forEach((barnFilter) => {
      if (barnFilter?.enabled) {
        includedBarns.add(barnFilter.value);
      }
    });

    const includedTrips = trips.filter((trip) => {
      return (
        includedTripStatuses.has(trip.status) &&
        trip.deliveries.some((delivery) => includedBarns.has(delivery.bin.barn.id))
      );
    });

    return getTripMapFromList(isMetric, includedTrips);
  }, [trips, tripStatusFilters, barnFilters, isMetric]);

  useEffect(() => {
    const delivery = focusTripData?.trip?.[0]?.deliveries?.[0];
    if (delivery && focusTripId) {
      const targetDate = dayjs.tz(1000 * (delivery.delivered_at || delivery.ordered_at)).startOf('day');
      setScrollingToDate(targetDate);
      setMainCalendarCurrentDate(targetDate);
      setMiniCalendarCurrentDate(targetDate);
      setTripStatusFilters(formatAsFilterList(tripStatusList));
      resetBarnFilter();

      const trip = trips.find((trip) => trip.id === focusTripId);

      if (trip) {
        setActiveTrip(dbTripToUITrip(trip, isMetric));
        onShowRightSideBar();
      }
    }
  }, [focusTripData, trips]);

  const removeWeekendsFilter = useCallback(removeWeekends, []);
  const removeEmptyDaysFilter = useCallback(
    (date) => dateToTripsMap?.[date.format('YYYY-MM-DD')]?.length > 0 || date.isSame(today, 'day'),
    [dateToTripsMap, today],
  );

  const resetBarnFilter = () => {
    const remoteBarnFilters = formatAsFilterList(barnData?.barn || []);
    setBarnFilters(
      remoteBarnFilters.map((remoteBarnFilter) => {
        return {
          ...remoteBarnFilter,
          enabled: true,
        };
      }),
    );
  };

  // Update the calendar filters when toggling "show weekends"
  const calendarFilters = useMemo(() => {
    const filters = [];

    // Add the date filter to remove weekends if the option to show weekends is toggled off
    if (!showWeekends) {
      filters.push(removeWeekendsFilter);
    }

    // When in agenda mode, hide all empty days (excluding today)
    if (calendarMode === CalendarMode.Agenda) {
      filters.push(removeEmptyDaysFilter);
    }

    return filters;
  }, [showWeekends, removeWeekendsFilter, calendarMode, removeEmptyDaysFilter]);

  // Other than the initial load, the delivery data being fetched is always outside of the visible window. This means
  // that relying solely on tripsLoading to tell us when to display the loading skeletons would cause us to show
  // them more than necessary. As such, we only need to show them when loading the first set of delivery data.
  const [initialTripsLoaded, setInitialTripsLoaded] = useState(false);

  // Since the date block containing the date to scroll to might not be visible within the current date range, we need
  // to save the date for that block to indicate scrolling is desired.
  const [scrollingToDate, setScrollingToDate] = useState(null);

  // The list of available barns with their associated bins
  const { data: barnAndBinData } = useQuery(GetBarnsAndBinsQuery, {
    onError: onNetworkRequestError,
  });

  const barnsAndBins =
    barnAndBinData?.barn?.map((barn) => {
      return {
        ...barn,
        name: censor(barn.name, censorTypes.barn),
        organization: {
          ...barn?.organization,
          name: censor(barn?.organization, censorTypes.organization),
        },
        bins: barn?.bins?.map((bin) => {
          return {
            ...bin,
            name: censor(bin.name, censorTypes.bin),
          };
        }),
      };
    }) || [];

  // A map of barn.id -> bins
  const barnToBinsMap = useMemo(() => {
    return getBinMapFromList(barnsAndBins);
  }, [barnsAndBins]);

  const [insertTrip, { loading: insertTripLoading }] = useMutation(InsertTrip, {});
  const [updateTrip, { loading: updateTripLoading }] = useMutation(UpdateTrip, {});
  const [insertDeliveries, { loading: insertDeliveriesLoading }] = useMutation(InsertDeliveries, {});
  const [updateDeliveries, { loading: updateDeliveriesLoading }] = useMutation(UpdateDeliveries, {});

  // Since the date block we want to scroll to might not be the range of days currently visible, wait for the range of
  // visible dates to update. When that happens and the scrollingToDate flag is set, scroll the target date block into
  // view.
  useEffect(() => {
    const scrollingToDateString = scrollingToDate?.startOf('day')?.toString();
    if (!scrollingToDate || !visibleDateSet.has(scrollingToDateString)) {
      return;
    }

    // Attempt to scroll the target date block into view. This is most useful in agenda mode.
    const scrollDateBlockRef = mainCalendarDateBlockRefs?.current?.[scrollingToDateString];
    if (!scrollDateBlockRef || !scrollDateBlockRef?.parentNode) {
      return;
    }

    scrollDateBlockRef.parentNode.scrollTo({
      top: scrollDateBlockRef.offsetTop - scrollDateBlockRef.parentNode.offsetTop,
      behavior: 'smooth',
    });

    setScrollingToDate(null);
  }, [scrollingToDate?.toString(), visibleDateSet]);

  const onToggleShowWeekends = () => {
    setShowWeekends(!showWeekends);
  };

  const onMainCalendarPreviousClick = () => {
    if (calendarMode === CalendarMode.OneWeek) {
      setMainCalendarCurrentDate(mainCalendarCurrentDate.subtract(1, 'week'));
    } else if (calendarMode === CalendarMode.TwoWeeks) {
      setMainCalendarCurrentDate(mainCalendarCurrentDate.subtract(2, 'week'));
    } else if (calendarMode === CalendarMode.Agenda) {
      setMainCalendarCurrentDate(mainCalendarCurrentDate.subtract(1, 'month'));
    }
  };

  const onMainCalendarPreviousKeyDown = (e) => {
    if (e.key !== 'Enter' && e.code !== 'Space') {
      return;
    }

    e.preventDefault();
    onMainCalendarPreviousClick();
  };

  const onMainCalendarNextClick = () => {
    if (calendarMode === CalendarMode.OneWeek) {
      setMainCalendarCurrentDate(mainCalendarCurrentDate.add(1, 'week'));
    } else if (calendarMode === CalendarMode.TwoWeeks) {
      setMainCalendarCurrentDate(mainCalendarCurrentDate.add(2, 'week'));
    } else if (calendarMode === CalendarMode.Agenda) {
      setMainCalendarCurrentDate(mainCalendarCurrentDate.add(1, 'month'));
    }
  };

  const onMainCalendarNextKeyDown = (e) => {
    if (e.key !== 'Enter' && e.code !== 'Space') {
      return;
    }

    e.preventDefault();
    onMainCalendarNextClick();
  };

  const onChangeMainCalendarMode = (e) => {
    setCalendarMode(e.target.value);
  };

  const onTodayClick = () => {
    setMainCalendarCurrentDate(today.clone());
    setScrollingToDate(today.clone());
  };

  const onMiniDateClick = (date) => {
    setMainCalendarCurrentDate(date);
    setScrollingToDate(date);
  };

  const onShowRightSideBar = () => {
    setLeftSidebarVisible(false);
    setRightSidebarVisible(true);
  };

  const onHideRightSideBar = () => {
    setActiveTrip(null);
    setRightSidebarVisible(false);
    setLeftSidebarVisible(true);
    setFocusTripId();
  };

  const onSaveTrip = async (trip) => {
    // If trip.tripID exists, it means we are updating an existing trip - otherwise, we're creating a new one
    let tripResult;
    let tripID;
    if (trip?.tripID) {
      tripResult = await updateTrip({
        variables: {
          tripID: trip.tripID,
          barnID: trip.barnID,
          externalID: trip.externalID !== '' ? trip.externalID : undefined, // If externalID wasn't explicitly provided, don't accidentally save the empty string as the external ID
          comment: trip.comment,
        },
        onError: onNetworkRequestError,
      });
      tripID = tripResult?.data?.update_trip_by_pk?.id;
    } else {
      tripResult = await insertTrip({
        variables: {
          object: {
            barn_id: trip.barnID,
            external_id: trip.externalID !== '' ? trip.externalID : undefined, // If externalID wasn't explicitly provided, don't accidentally save the empty string as the external ID
            comment: trip.comment,
          },
        },
        onError: onNetworkRequestError,
      });
      tripID = tripResult?.data?.insert_trip_one?.id;
    }
    if (tripResult?.errors) {
      console.error('error saving trip', tripResult);
      return;
    }

    // We need the id for the newly created / updated trip to continue, so confirm we have it
    if (!tripID) {
      console.error(`invalid trip ID: ${tripID}`);
      return;
    }

    const newDeliveries = [];
    const existingDeliveries = [];
    (trip?.deliveries || []).forEach((delivery) => {
      const deliveryRow = {
        status: TripStatusNameConverter[delivery.status],
        bin_id: delivery.binID,
        feed_profile_id: null, // TODO: Include this
        ordered_at: dayjs(trip.orderedAt).unix(),
        ordered_mass_in_grams: Math.ceil(convertLargeUnitsToGrams(isMetric, delivery.orderedMassInLargeUnits)),
        delivered_at: trip.deliveredAt ? dayjs(trip.deliveredAt).unix() : null,
        delivered_mass_in_grams: delivery.deliveredMassInLargeUnits
          ? Math.ceil(convertLargeUnitsToGrams(isMetric, delivery.deliveredMassInLargeUnits))
          : null,
        external_id: delivery.externalID,
        comment: delivery.comment,
        trip_id: trip.tripID || tripID,
      };

      if (delivery?.id) {
        existingDeliveries.push({
          where: {
            id: { _eq: delivery.id },
          },
          _set: deliveryRow,
        });
      } else {
        newDeliveries.push(deliveryRow);
      }
    });

    // Now that we have the trip ID, save the list of new deliveries
    const { error } = await insertDeliveries({
      variables: {
        objects: newDeliveries,
      },
      onError: onNetworkRequestError,
    });
    if (error) {
      console.error('error saving new deliveries', tripResult);
      return;
    }

    // Save any updates to existing deliveries (i.e. deliveries that already have a row in the database)
    const updateDeliveriesResult = await updateDeliveries({
      variables: {
        updates: existingDeliveries,
      },
      onError: onNetworkRequestError,
    });
    if (updateDeliveriesResult?.errors) {
      console.error('error updating existing deliveries', updateDeliveriesResult);
      return;
    }

    await client.refetchQueries({
      include: [GetTripsQuery],
    });
  };

  const onTripCardClick = (date, id) => {
    setActiveTrip(dateToTripsMap[date.format('YYYY-MM-DD')].find((trip) => trip.id === id));
    onShowRightSideBar();
  };

  const onNewTripClick = () => {
    setActiveTrip(null);
    onShowRightSideBar();
  };

  const onSeeAllTripsClick = (date) => {
    if (!date) {
      return;
    }

    setMainCalendarCurrentDate(date);
    setCalendarMode(CalendarMode.Agenda);
    setScrollingToDate(date);
  };

  const onMainCalendarCurrentDateChange = (currentDate) => {
    setMiniCalendarCurrentDate(currentDate);
  };

  const onMainCalendarDateRangeChange = (dates, unfilteredDates) => {
    // When the visible date range changes, reset the refs as those elements might have been unmounted
    mainCalendarDateBlockRefs.current = {};

    setVisibleDates(dates);
    setVisibleDateSet(new Set(dates.map((date) => date.toString())));

    // Determine if we need to page the mini calendar to ensure the date range on the main calendar remains visible.
    // Note that the following logic relies on the fact that the mini calendar rounds to the beginning and end of a
    // week, which means that it will include some dates from outside of the current month if necessary. This means we
    // should be able to handle all cases except when there is an even split in the visible days. For example, when in
    // TwoWeeks mode and half of the days fall in to one month and the other half fall into another month. In this case,
    // paging the mini calendar won't help since all days won't be visible even if we do. In this circumstance, whatever
    // dates happen to be visible on the mini calendar will still be highlighted as expected.
    if (!dates?.length) {
      return;
    }

    // Loop through the range of dates, categorizing each by which year and month they fall in to
    const dateKeyToTotalMap = {};
    unfilteredDates.forEach((date) => {
      const dateKey = `${date.year()}:${date.month()}`;

      if (!dateKeyToTotalMap[dateKey]) {
        dateKeyToTotalMap[dateKey] = 0;
      }

      dateKeyToTotalMap[dateKey]++;
    });

    // Sort the categorized year:month pairs by how common they are in descending order
    const sortedDateKeys = Object.keys(dateKeyToTotalMap).sort((a, b) => dateKeyToTotalMap[b] - dateKeyToTotalMap[a]);
    const sortedDateKeyTokens = sortedDateKeys[0].split(':');

    // Retrieve the most common year and month from the sorted date keys and convert them back into numbers
    const mostCommonYear = Number.parseInt(sortedDateKeyTokens[0]);
    const mostCommonMonth = Number.parseInt(sortedDateKeyTokens[1]);

    // If the current month and year match the most common month and year, there's nothing to do
    if (miniCalendarCurrentDate.month() === mostCommonMonth && miniCalendarCurrentDate.year() === mostCommonYear) {
      return;
    }

    // Update the current date on the mini calendar to be the most common month and year in range
    setMiniCalendarCurrentDate(miniCalendarCurrentDate.month(mostCommonMonth).year(mostCommonYear));
  };

  return (
    <Page className="FeedOrdersPage" titleSegments={pageTitleSegments}>
      <div className="FeedOrdersPage-feedDeskHeader">
        <div className="FeedOrdersPage-showWeekendsContainer">
          Show Weekends
          <ToggleSwitch isActive={showWeekends} onToggle={onToggleShowWeekends} />
        </div>
        <h3 className="FeedOrdersPage-calendarHeading">{getCalendarTitle(visibleDates, mainCalendarCurrentDate)}</h3>
        <div className="FeedOrdersPage-pagingButtons">
          <LeftChevronIcon
            className="FeedOrdersPage-pagingButton"
            tabIndex={0}
            onKeyDown={onMainCalendarPreviousKeyDown}
            onClick={onMainCalendarPreviousClick}
          />
          <RightChevronIcon
            className="FeedOrdersPage-pagingButton"
            tabIndex={0}
            onKeyDown={onMainCalendarNextKeyDown}
            onClick={onMainCalendarNextClick}
          />
        </div>
        <Select
          className="FeedOrdersPage-modeSelect"
          itemList={modeList}
          value={calendarMode}
          onChange={onChangeMainCalendarMode}
        />
        <div className="FeedOrdersPage-calendarOptions">
          <Button color="success" variant="vivid" content="New Order" onClick={onNewTripClick} />
          <Button color="success" variant="pastel" content="Today" onClick={onTodayClick} />
        </div>
      </div>
      <div className="FeedOrdersPage-feedDesk">
        <LeftSidebar
          dataLoading={tripsLoading && !initialTripsLoaded}
          filtersLoading={barnFiltersLoading}
          visible={leftSidebarVisible}
          visibleDateSet={visibleDateSet}
          today={today}
          currentDate={miniCalendarCurrentDate}
          showWeekends={showWeekends}
          dateToTripsMap={dateToTripsMap}
          tripStatusFilters={tripStatusFilters}
          barnFilters={barnFilters}
          onDateClick={onMiniDateClick}
          onShowWeekendsUpdate={setShowWeekends}
          onMiniCalendarCurrentDateUpdate={setMiniCalendarCurrentDate}
          onTripStatusFiltersUpdate={setTripStatusFilters}
          onBarnFiltersUpdate={setBarnFilters}
        />
        <div className="FeedOrdersPage-mainCalendarContainer">
          <MainCalendar
            loading={tripsLoading && !initialTripsLoaded}
            isMetric={isMetric}
            showWeekends={showWeekends}
            mode={calendarMode}
            today={today}
            currentDate={mainCalendarCurrentDate}
            filters={calendarFilters}
            dateBlockRefs={mainCalendarDateBlockRefs}
            dateToTripsMap={dateToTripsMap}
            onCurrentDateChange={onMainCalendarCurrentDateChange}
            onDateRangeChange={onMainCalendarDateRangeChange}
            onTripCardClick={onTripCardClick}
            onSeeAllTripsClick={onSeeAllTripsClick}
          />
        </div>
        <RightSidebar
          visible={rightSidebarVisible}
          loading={insertTripLoading || updateTripLoading || insertDeliveriesLoading || updateDeliveriesLoading}
          tripID={activeTrip?.id}
          isMetric={isMetric}
          barnsAndBins={barnsAndBins}
          barnToBinsMap={barnToBinsMap}
          status={activeTrip?.status}
          orderedAt={activeTrip?.deliveries?.[0]?.orderedAt}
          barnID={activeTrip?.barnID}
          deliveredAt={activeTrip?.deliveries?.[0]?.deliveredAt}
          deliveries={activeTrip?.deliveries}
          externalID={activeTrip?.externalID}
          comment={activeTrip?.comment}
          onHideRightSideBar={onHideRightSideBar}
          onSaveTrip={onSaveTrip}
        />
      </div>
    </Page>
  );
}

FeedOrdersPage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
};

export default FeedOrdersPage;
