import PropTypes from 'prop-types';

const AlertTableSortLevels = {
  0: 'descending',
  1: 'ascending',
};

function AlertTableRowHeader({ text = '', sortKey = '', sortColumn = '', sortLevel = 0, onClick = () => {} }) {
  const onHeaderClick = () => {
    onClick(sortKey);
  };

  const renderSortSymbol = () => {
    if (sortColumn !== sortKey) {
      return;
    }

    return <div className={`AlertTable-sortSymbol AlertTable-sortSymbol--${AlertTableSortLevels[sortLevel]}`} />;
  };

  return (
    <div className="AlertTable-header" onClick={onHeaderClick}>
      <div className="AlertTable-rowText">{text}</div>
      {renderSortSymbol()}
    </div>
  );
}

AlertTableRowHeader.propTypes = {
  text: PropTypes.string,
  sortKey: PropTypes.string,
  sortColumn: PropTypes.string,
  sortLevel: PropTypes.number,
  onClick: PropTypes.func,
};

export default AlertTableRowHeader;
