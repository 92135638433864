import { atomWithStorage } from 'jotai/utils';
import { CalendarMode } from '../../organisms/Calendar';
import { formatAsFilterList } from './helpers';
import { tripStatusList } from './lists';

export const calendarModeKey = 'FeedFlo:FeedDesk:calendarMode';
export const showWeekendsKey = 'FeedFlo:FeedDesk:showWeekends';
export const tripStatusFiltersKey = 'FeedFlo:FeedDesk:tripStatusFilters';
export const barnFiltersKey = 'FeedFlo:FeedDesk:barnFilters';

export const calendarModeAtom = atomWithStorage(calendarModeKey, CalendarMode.TwoWeeks);
export const showWeekendsAtom = atomWithStorage(showWeekendsKey, true);
export const tripStatusFiltersAtom = atomWithStorage(tripStatusFiltersKey, formatAsFilterList(tripStatusList));
export const barnFiltersAtom = atomWithStorage(barnFiltersKey, []);
