import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { cssTransition, ToastContainer, toast } from 'react-toastify';
import PropTypes from 'prop-types';

import Button from '../../../atoms/Button';
import { XIcon } from '../../../atoms/Icons';
import SearchBar from '../../SearchBar';

import {
  ANIMAL_GROUP_QUERY_SEARCH,
  ANIMAL_GROUP_QUERY_SELECTOR_SUGGESTIONS,
  ANIMAL_GROUP_QUERY_SELECTION,
} from './queries';

import './AnimalGroupSelector.scss';

const TOAST_CONTAINER_ID = 'AnimalGroupSelectorToastContainer';

// A custom transition animation has to be applied because React Toastify does not work well with html dialogs.
// Any toast present when the dialog is closed will be re-presented when the dialog is next opened,
//  even if that toast was dismissed before closing the dialog. React Toastify toasts cannot be dismissed without an animation.
// This replacement animation simply sets opacity to 0, so the animation restart bug is not visible.
const TOAST_ANIM = cssTransition({
  exit: 'AnimalGroupSelector-toastExitAnim',
});

function AnimalGroupSelector({ barnId, loadAnimalGroup, dialogRef }) {
  // A boolean used to track if input confirmation is waiting on a query.
  const [confirming, setConfirming] = useState(false);
  // An array of search suggestions populated as the user types into the search bar.
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  // An array of suggestions taken from the most recent animal groups for this barn.
  const [defaultSuggestions, setDefaultSuggestions] = useState([]);
  // Tracks the selection made by the user.
  const [selection, setSelection] = useState(null);
  const [selectionLabel, setSelectionLabel] = useState(null);

  const close = useCallback(() => {
    setSelection(null);
    setSelectionLabel(null);
    dialogRef.current.close();
  }, []);

  // Query the starting suggestion list. Skip setting ensures this only happens once.
  useQuery(ANIMAL_GROUP_QUERY_SELECTOR_SUGGESTIONS, {
    variables: { barnId },
    skip: !barnId || defaultSuggestions.length > 0,
    onCompleted: (response) => {
      if (!Array.isArray(response?.animal_group)) return;
      const data = response.animal_group;

      const defaultSuggestions = data.map(({ external_id, id }) => {
        return { label: external_id, id };
      });

      setDefaultSuggestions(defaultSuggestions);
    },
    onError: (error) => console.error('Failed to fetch suggestion list', error),
  });

  const [querySearch] = useLazyQuery(ANIMAL_GROUP_QUERY_SEARCH, {
    onCompleted: (response) => {
      if (!Array.isArray(response?.animal_group)) return;
      const data = response.animal_group;

      const searchSuggestions = data.map(({ external_id, id }) => {
        return { label: external_id, id };
      });

      setSearchSuggestions(searchSuggestions);
    },
    onError: (error) => {
      console.error('Failed to fetch input-filtered suggestions', error);
      const errorMessage = 'Network error encountered. Please check your internet connection.';
      toast.error(errorMessage, {
        position: 'top-center',
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

        transition: TOAST_ANIM,

        containerId: TOAST_CONTAINER_ID,
      });
    },
  });

  const [querySelection] = useLazyQuery(ANIMAL_GROUP_QUERY_SELECTION, {
    onCompleted: (response) => {
      if (!response?.animal_group_by_pk) return;
      const {
        approximate_birthdate: birthdate,
        ended_at,
        expected_ended_at,
        external_id,
        started_at,
      } = response.animal_group_by_pk;

      loadAnimalGroup(selection, birthdate, ended_at, expected_ended_at, external_id, started_at);
      setConfirming(false);
      close();
    },

    onError: (error) => {
      setConfirming(false);
      console.error(error);
      const errorMessage =
        'Network error encountered while fetching group data. Please check your internet connection and try again.';
      toast.error(errorMessage, {
        position: 'top-center',
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

        transition: TOAST_ANIM,

        containerId: TOAST_CONTAINER_ID,
      });
    },
  });

  // Millisecond debounce applied to typing-driven calls to hedge against query spamming.
  const search = useCallback(
    debounce((text) => querySearch({ variables: { barnId, text: `%${text}%` } }), 150),
    [],
  );

  const confirm = useCallback(() => {
    querySelection({ variables: { id: selection } });
    setConfirming(true);
  }, [selection]);

  const submitSelection = useCallback((_, id, label) => {
    setSelection(id);
    setSelectionLabel(label);
  }, []);

  // Set up keyboard handlers.
  useEffect(() => {
    function keyHandler(e) {
      // Disregard all keystrokes when this dialog is closed.
      if (dialogRef.current?.open) {
        switch (e.key) {
          case 'Enter':
            // If the submit button is enabled, pressing 'enter' will act as if it were pressed.
            if (selection) {
              e.preventDefault();
              confirm();
            }
            break;
        }
      }
    }
    // Add event listener for keyboard events when component mounts.
    document.addEventListener('keypress', keyHandler);
    // Remove event listener when component unmounts.
    return () => {
      document.removeEventListener('keypress', keyHandler);
    };
  }, [confirm]);

  return (
    <>
      <div className="AnimalGroupSelector">
        <div className="AnimalGroupSelector-top">
          <span className="AnimalGroupSelector-titleText">Find Animal Group</span>
          <XIcon className="AnimalGroupSelector-exit" onClick={close} />
        </div>
        <div className="AnimalGroupSelector-content">
          <span className="AnimalGroupSelector-labelText">Group ID</span>
          <SearchBar
            className="AnimalGroupSelector-searchBar"
            inputClassName="AnimalGroupSelector-searchBarInput AnimalGroupSelector-searchBarText"
            suggestionClassName="AnimalGroupSelector-searchBarText"
            // Prioritise showing search suggestions if there are any matching the user input.
            // Otherwise, show default suggestions.
            // Note: SearchBar has built-in filtering, so the search bar will appear empty if input has no matches.
            items={searchSuggestions.length > 0 ? searchSuggestions : defaultSuggestions}
            onSubmit={submitSelection}
            placeholder={selectionLabel}
            onType={search}
            clearOnSelection
          />
        </div>
        <div className="AnimalGroupSelector-bottom">
          <Button className="AnimalGroupSelector-closeButton" content="Close" onClick={close} />
          <Button
            loading={confirming}
            type="submit"
            className="AnimalGroupSelector-submitButton"
            variant="vivid"
            color="success"
            disabled={!selection}
            content="Select This Group"
            onClick={confirm}
          />
        </div>
      </div>
      <ToastContainer closeOnClick enableMultiContainer containerId={TOAST_CONTAINER_ID} />
    </>
  );
}

AnimalGroupSelector.propTypes = {
  barnId: PropTypes.string.isRequired,
  loadAnimalGroup: PropTypes.func.isRequired,
  dialogRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
    .isRequired,
};

export default AnimalGroupSelector;
