import PropTypes from 'prop-types';
import ChartLegendRow from './ChartLegendRow';
import './ChartLegend.scss';

function ChartLegend({ keys = [], keyNameMap = {}, valueMap = {}, colorScale = () => {}, valueFormatter = () => {} }) {
  const reversedKeys = [...keys].reverse();

  return (
    <div className="ChartLegend">
      {reversedKeys.map((key) => {
        return (
          <ChartLegendRow
            key={key}
            color={colorScale(key)}
            name={keyNameMap?.[key]}
            count={valueMap?.[key]?.current || 0}
            valueFormatter={valueFormatter}
          />
        );
      })}
    </div>
  );
}

ChartLegend.propTypes = {
  keys: PropTypes.array,
  keyNameMap: PropTypes.object,
  valueMap: PropTypes.object,
  colorScale: PropTypes.func,
  valueFormatter: PropTypes.func,
};

export default ChartLegend;
