import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import Heading from '../../atoms/Heading';
import { TLVtoJSON } from '../../tlv3';

import './TLVPage.scss';

const DEVICE_DATA_GQL = gql`
  query DeviceDataQuery($dataId: uuid!) {
    device_data(where: { id: { _eq: $dataId } }, order_by: { started_at: asc }) {
      id
      started_at
      ended_at
      data
      device {
        serial
        device_assignments(where: { deleted_at: { _is_null: true }, status: { _eq: "active" } }) {
          feed_line {
            name
            farm {
              name
            }
          }
        }
      }
    }
  }
`;

export default function NotFoundPage() {
  const { dataId } = useParams();
  const { loading, error, data } = useQuery(DEVICE_DATA_GQL, {
    variables: {
      dataId,
    },
  });

  if (loading) return <span>Loading</span>;
  if (error) return <span>{JSON.stringify(error, null, 2)}</span>;

  return (
    <div className="TLVPage">
      <Heading text="TLV Data" />
      <p>
        <b>Device Serial : </b>
        {data.device_data[0]?.device?.serial || 'unknown'}
      </p>
      <p>
        <b>Location : </b>
        {data.device_data[0]?.device?.device_assignments[0]?.feed_line?.farm?.name || 'unknown'} {' | '}
        {data.device_data[0]?.device?.device_assignments[0]?.feed_line?.name || 'unknown'}
      </p>
      {}

      <>
        {data.device_data.map((device_data) => {
          const hex = device_data.data.substring(2);
          const buff = [];
          for (let c = 0; c < hex.length; c += 2) {
            const currInt = parseInt(hex.substr(c, 2), 16);
            buff.push(currInt);
          }

          return (
            <>
              <b>TLV Buffer:</b>
              <pre>{device_data.data}</pre>
              <b>TLV JSON Data:</b>
              <pre>
                {JSON.stringify(
                  TLVtoJSON(buff),
                  function (k, v) {
                    if (v instanceof Array) return JSON.stringify(v);
                    return v;
                  },
                  2,
                )}
              </pre>
              <hr />
            </>
          );
        })}
      </>
    </div>
  );
}
