import PropTypes from 'prop-types';
import RadioButton from '../../atoms/RadioButton';
import FeedFloDatePicker from '../../molecules/FeedFloDatePicker';

function DatePickerPopoverRow({
  text = '',
  value = 0,
  isSelected = false,
  isDatePickerVisible = false,
  dateRange = {},
  onClick = () => {},
  onSelectDateRange = () => {},
}) {
  const onRowClick = (e) => {
    e.stopPropagation();
    onClick(value);
  };

  return (
    <div className="DatePickerPopover-row" onClick={onRowClick}>
      <div className="DatePickerPopover-rowText">
        <RadioButton defaultChecked={isSelected} />
        {text}
      </div>
      {isDatePickerVisible && (
        <FeedFloDatePicker
          className="DatePickerPopover-datePicker"
          from={dateRange?.from?.toDate() || new Date()}
          to={dateRange?.to?.toDate() || new Date()}
          isPanel={false}
          onChange={onSelectDateRange}
        />
      )}
    </div>
  );
}

DatePickerPopoverRow.propTypes = {
  text: PropTypes.string,
  value: PropTypes.number,
  isSelected: PropTypes.bool,
  isDatePickerVisible: PropTypes.bool,
  dateRange: PropTypes.object,
  onClick: PropTypes.func,
  onSelectDateRange: PropTypes.func,
};

export default DatePickerPopoverRow;
