import PropTypes from 'prop-types';
import useUser from '../../utils/hooks/useUser';
import FeedLineStatusCardView from './FeedLineStatusCardView';

function FeedLineStatusCard({
  loading = false,
  className = '',
  tabIndex = 0,
  nofeedTimeInSeconds,
  activeFaultCodes = [],
  feedLineName = '',
  feedLineLink = '',
  isFeedLineFull = false,
  isAugerRunning = false,
  isCalculating = false,
  lastFeedingEventStartInSeconds = 0,
  weightInGrams = 0,
  durationInSeconds = 0,
  deviceList = [],
  calibrationStatus,
  feedLineId,
}) {
  const { user } = useUser();
  const isMetric = user?.isMetric || false;

  return (
    <FeedLineStatusCardView
      loading={loading}
      className={className}
      tabIndex={tabIndex}
      isMetric={isMetric}
      nofeedTimeInSeconds={nofeedTimeInSeconds}
      activeFaultCodes={activeFaultCodes}
      feedLineName={feedLineName}
      feedLineLink={feedLineLink}
      isFeedLineFull={isFeedLineFull}
      isAugerRunning={isAugerRunning}
      isCalculating={isCalculating}
      lastFeedingEventStartInSeconds={lastFeedingEventStartInSeconds}
      weightInGrams={weightInGrams}
      durationInSeconds={durationInSeconds}
      deviceList={deviceList}
      calibrationStatus={calibrationStatus}
      feedLineId={feedLineId}
    />
  );
}

FeedLineStatusCard.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  nofeedTimeInSeconds: PropTypes.number,
  activeFaultCodes: PropTypes.array,
  feedLineName: PropTypes.string,
  feedLineLink: PropTypes.string,
  isFeedLineFull: PropTypes.bool,
  isAugerRunning: PropTypes.bool,
  isCalculating: PropTypes.bool,
  lastFeedingEventStartInSeconds: PropTypes.number,
  weightInGrams: PropTypes.number,
  durationInSeconds: PropTypes.number,
  deviceList: PropTypes.array,
  calibrationStatus: PropTypes.string,
  feedLineId: PropTypes.string,
};

export default FeedLineStatusCard;
