import { gql } from '@apollo/client';

export const ADD_FAULT_COMMENT_GQL = gql`
  mutation ConsumptionDisplay_InsertFaultComment($object: fault_comment_insert_input!) {
    insert_fault_comment_one(object: $object) {
      id
    }
  }
`;

export const CONSUMPTION_TAB_GQL = gql`
  query ConsumptionDisplay_Query(
    $barnId: uuid!
    $endRangeTimestamp: bigint!
    $faultCodes: [Int!]!
    $maxDuration: bigint!
    $minDuration: bigint!
    $startRangeTimestamp: bigint!
  ) {
    feed_line(where: { deleted_at: { _is_null: true }, farm_id: { _eq: $barnId } }) {
      id
      name
      device_assignments(
        where: {
          deleted_at: { _is_null: true }
          _or: [
            { _and: { status: { _eq: "active" }, ended_at: { _is_null: true } } }
            { _and: { started_at: { _lte: $endRangeTimestamp }, ended_at: { _gte: $startRangeTimestamp } } }
          ]
        }
        order_by: [{ started_at: desc_nulls_last }, { id: desc }]
      ) {
        started_at
        ended_at
        device {
          faults(
            where: {
              code: { _in: $faultCodes }
              duration: { _gte: $minDuration }
              deleted_at: { _is_null: true }
              feedflo_staff_only: { _eq: false }
              started_at: { _lte: $endRangeTimestamp }
              _or: [
                { ended_at: { _is_null: true }, duration: { _lte: $maxDuration } }
                { ended_at: { _gte: $startRangeTimestamp } }
              ]
            }
            order_by: { started_at: desc_nulls_last }
          ) {
            id
            code
            duration
            ended_at
            started_at
            nofeed_seconds
            fault_comments(
              where: { deleted_at: { _is_null: true } }
              order_by: { created_at: desc_nulls_last }
              limit: 1
            ) {
              comment
              fault_root_cause_id
            }
          }
          last_transaction: transactions(
            where: { deleted_at: { _is_null: true } }
            order_by: { occured_at: desc_nulls_last }
            limit: 1
          ) {
            occured_at
          }
        }
      }
    }
    farm(where: { id: { _eq: $barnId } }) {
      name
    }
    barn_feed_frames(args: { barn_id: $barnId, end_at: $endRangeTimestamp, start_at: $startRangeTimestamp }) {
      total_consumption
      end_timestamp
      start_timestamp
      feed_line_id
    }
  }
`;

export const CONSUMPTION_TAB_ALG_GQL = gql`
  query ConsumptionDisplay_Query(
    $algorithmVersion: String!
    $barnId: uuid!
    $endRangeTimestamp: bigint!
    $faultCodes: [Int!]!
    $maxDuration: bigint!
    $minDuration: bigint!
    $startRangeTimestamp: bigint!
  ) {
    feed_line(where: { deleted_at: { _is_null: true }, farm_id: { _eq: $barnId } }) {
      id
      name
      device_assignments(
        where: {
          deleted_at: { _is_null: true }
          _or: [
            { _and: { status: { _eq: "active" }, ended_at: { _is_null: true } } }
            { started_at: { _gte: $startRangeTimestamp } }
            { ended_at: { _lte: $endRangeTimestamp } }
          ]
        }
        order_by: [{ started_at: desc_nulls_last }, { id: desc }]
      ) {
        started_at
        ended_at
        device {
          faults(
            where: {
              code: { _in: $faultCodes }
              duration: { _gte: $minDuration }
              deleted_at: { _is_null: true }
              feedflo_staff_only: { _eq: false }
              started_at: { _lte: $endRangeTimestamp }
              _or: [
                { ended_at: { _is_null: true }, duration: { _lte: $maxDuration } }
                { ended_at: { _gte: $startRangeTimestamp } }
              ]
            }
            order_by: { started_at: desc_nulls_last }
          ) {
            id
            code
            duration
            ended_at
            started_at
            nofeed_seconds
            fault_comments(
              where: { deleted_at: { _is_null: true } }
              order_by: { created_at: desc_nulls_last }
              limit: 1
            ) {
              comment
              fault_root_cause_id
            }
          }
          last_transaction: transactions(
            where: { deleted_at: { _is_null: true } }
            order_by: { occured_at: desc_nulls_last }
            limit: 1
          ) {
            occured_at
          }
        }
      }
    }
    farm(where: { id: { _eq: $barnId } }) {
      name
    }
    barn_feed_frames: barn_feed_frames_for_algorithm(
      args: {
        algorithm_version: $algorithmVersion
        barn_id: $barnId
        end_at: $endRangeTimestamp
        start_at: $startRangeTimestamp
      }
    ) {
      total_consumption
      end_timestamp
      start_timestamp
      feed_line_id
    }
  }
`;
