import React, { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE_SECOND } from '../../utils/dates';

const DEVICE_GQL = gql`
  query DeviceDataQuery($deviceId: uuid!, $start: bigint!) {
    device_data(
      where: { device_id: { _eq: $deviceId }, _or: { ended_at: { _gte: $start }, started_at: { _gte: $start } } }
      order_by: { started_at: desc }
    ) {
      id
      id_from_device
      started_at
      ended_at
      created_at
    }
  }
`;

export default function DeviceDataTable({ deviceId }) {
  const now = useMemo(() => {
    return Math.floor(Date.now() / 1000);
  }, []);

  const {
    loading,
    error,
    data: deviceData,
  } = useQuery(DEVICE_GQL, {
    variables: {
      deviceId,
      start: now - 4 * 60 * 60,
    },
  });

  if (loading) return <span>Loading</span>;
  if (error) return <span>{JSON.stringify(error, null, 2)}</span>;

  return (
    <div className="deviceDataHolder">
      <table>
        <thead>
          <tr>
            <th>Device UUID</th>
            <th>Created At (DB)</th>
            <th>Started</th>
            <th>Ended</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          {deviceData?.device_data?.map((data) => {
            return (
              <tr key={data.id}>
                <td>{data.id_from_device}</td>
                <td>{dayjs.tz(1000 * data.created_at).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE_SECOND)}</td>
                <td>{dayjs.tz(1000 * data.started_at).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE_SECOND)}</td>
                <td>
                  {data.ended_at
                    ? dayjs.tz(1000 * data.ended_at).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE_SECOND)
                    : 'None'}
                </td>
                <td>{dayjs.tz(1000 * data.ended_at).from(data.started_at * 1000, true)}</td>
                <td>
                  <Link target="_blank" to={`/tlv/${data.id}`}>
                    🔎
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

DeviceDataTable.propTypes = {
  deviceId: PropTypes.string,
};
