import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import GridDateBlock from './components/GridDateBlock';
import RowDateBlock from './components/RowDateBlock';
import { CalendarMode, DayOfWeek } from './enums';
import './Calendar.scss';

export const numDaysInWeek = 7;

function Calendar({
  className = '',
  dateBlockClassName = '',
  style = {},
  isCompact = false,
  mode = CalendarMode.Month,
  currentDate: currentDateProp = null,
  highlightToday = true,
  dayOfWeekFilters = null,
  dateFilters = null,
  renderDayOfWeek = null,
  renderDate = null,
  onCurrentDateChange = () => {},
  onDateRangeChange = () => {},
  onDateBlockClick = () => {},
}) {
  const [currentDate, setCurrentDate] = useState(currentDateProp || dayjs.tz().startOf('day'));
  const [unfilteredDates, setUnfilteredDates] = useState([]);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [dates, setDates] = useState([]);
  const today = useMemo(() => dayjs.tz().startOf('day'), []);

  useEffect(() => {
    setCurrentDate(currentDateProp || dayjs.tz().startOf('day'));
  }, [currentDateProp?.toString()]);

  useEffect(() => {
    onCurrentDateChange(currentDate);
  }, [currentDate.toString()]);

  useEffect(() => {
    // Default the week to the first 7 days from the date list
    let dateList = unfilteredDates.slice();
    let dayOfWeekList = dateList.slice(0, numDaysInWeek);

    // Loop through the provided list of filter functions, applying each to the list of days of the week
    (dayOfWeekFilters || []).forEach((filter) => {
      dayOfWeekList = dayOfWeekList.filter(filter);
    });

    // Loop through the provided list of filter functions, applying each to the list of dates
    (dateFilters || []).forEach((filter) => {
      dateList = dateList.filter(filter);
    });

    if (dateList.toString() !== dates.toString()) {
      setDaysOfWeek(dayOfWeekList);
      setDates(dateList);
      onDateRangeChange(dateList, unfilteredDates);
    }
  }, [unfilteredDates.toString(), dayOfWeekFilters, dateFilters]);

  const generateDates = (startOfPeriod, numberOfDays) => {
    let dateList = [];

    // Generate the list of requested dates
    for (let i = 0; i <= numberOfDays; i++) {
      dateList.push(startOfPeriod.add(i, 'day'));
    }

    setUnfilteredDates(dateList);
  };

  const getWeekViewDates = (numberOfWeeks) => {
    const adjustedNumberOfWeeks = numberOfWeeks - 1; // Subtract one since the current week is implicitly included
    const startOfPeriod = currentDate.day(DayOfWeek.Sunday);
    const endOfPeriod = startOfPeriod.add(adjustedNumberOfWeeks, 'weeks').day(DayOfWeek.Saturday);
    const numberOfDays = endOfPeriod.diff(startOfPeriod, 'day');
    generateDates(startOfPeriod, numberOfDays);
  };

  const getMonthViewDates = (includeFullWeek) => {
    let startOfPeriod = currentDate.startOf('month');
    let endOfPeriod = currentDate.endOf('month');
    if (includeFullWeek) {
      startOfPeriod = startOfPeriod.day(DayOfWeek.Sunday);
      endOfPeriod = endOfPeriod.day(DayOfWeek.Saturday);
    }

    const numberOfDays = endOfPeriod.diff(startOfPeriod, 'day');
    generateDates(startOfPeriod, numberOfDays);
  };

  useEffect(() => {
    if (!currentDate) {
      return;
    }

    if (mode === CalendarMode.OneWeek) {
      getWeekViewDates(1);
    } else if (mode === CalendarMode.TwoWeeks) {
      getWeekViewDates(2);
    } else if (mode === CalendarMode.Month) {
      getMonthViewDates(true);
    } else if (mode === CalendarMode.Agenda) {
      getMonthViewDates(false);
    }
  }, [mode, currentDate?.toString()]);

  const defaultRenderDayOfWeek = (dayOfWeekDate, i) => {
    return (
      <GridDateBlock
        key={`dayofweek:${dayOfWeekDate.toString()}`}
        className={dateBlockClassName}
        isCompact={isCompact}
        isDayOfWeekLabel={true}
        isLastColumn={i % daysOfWeek.length === daysOfWeek.length - 1}
        isLastRow={i >= daysOfWeek.length - daysOfWeek.length}
        date={dayOfWeekDate}
      />
    );
  };

  const defaultRenderDate = (date, i) => {
    if (mode === CalendarMode.Agenda) {
      return (
        <RowDateBlock
          key={`date:${date.toString()}`}
          className={dateBlockClassName}
          isDateHighlighted={highlightToday && date.isSame(today, 'day')}
          date={date}
          onClick={onDateBlockClick}
        />
      );
    }

    return (
      <GridDateBlock
        key={`date:${date.toString()}`}
        className={dateBlockClassName}
        isCompact={isCompact}
        isFirstRow={daysOfWeek.length > 0 && i < daysOfWeek.length}
        isLastColumn={i % daysOfWeek.length === daysOfWeek.length - 1}
        isLastRow={i >= dates.length - daysOfWeek.length}
        isDeemphasized={mode === CalendarMode.Month && !date.isSame(currentDate, 'month')}
        isDateHighlighted={highlightToday && date.isSame(today, 'day')}
        date={date}
        onClick={onDateBlockClick}
      />
    );
  };

  const renderDaysOfWeek = () => {
    if (mode === CalendarMode.Agenda) {
      return null;
    }

    const dayOfWeekRenderFunction = renderDayOfWeek || defaultRenderDayOfWeek;
    return daysOfWeek.map(dayOfWeekRenderFunction);
  };

  const renderDates = () => {
    if (!dates.length) {
      return (
        <div className="Calendar-noDates">
          <div>No dates to display.</div>
          <div>Try changing views or updating your filters.</div>
        </div>
      );
    }

    const dateRenderFunction = renderDate || defaultRenderDate;
    return dates.map(dateRenderFunction);
  };

  return (
    <div
      className={`Calendar ${mode !== CalendarMode.Agenda ? 'Calendar--grid' : 'Calendar--rows'} ${className}`}
      style={style}
    >
      {renderDaysOfWeek()}
      {renderDates()}
    </div>
  );
}

Calendar.propTypes = {
  className: PropTypes.string,
  dateBlockClassName: PropTypes.string,
  style: PropTypes.object,
  isCompact: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(CalendarMode)),
  currentDate: PropTypes.object,
  highlightToday: PropTypes.bool,
  dayOfWeekFilters: PropTypes.arrayOf(PropTypes.func),
  dateFilters: PropTypes.arrayOf(PropTypes.func),
  renderDayOfWeek: PropTypes.func,
  renderDate: PropTypes.func,
  onCurrentDateChange: PropTypes.func,
  onDateRangeChange: PropTypes.func,
  onDateBlockClick: PropTypes.func,
};

export default Calendar;
