import PropTypes from 'prop-types';
import './AlertDashboardTile.scss';

function AlertDashboardTile({ className = '', children = [] }) {
  return <div className={`AlertDashboardTile ${className}`}>{children}</div>;
}

AlertDashboardTile.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default AlertDashboardTile;
