import { gql } from '@apollo/client';

export const USER_INFO_GQL = gql`
  query AppGlobalInfoQuery($userID: uuid!) {
    user: user_by_pk(id: $userID) {
      name
      email
      avatar
      phoneNumber: phone_number
      externalId: external_id
      isMetric: is_metric
      timezone

      user_barns {
        barn_id
      }
      user_organizations {
        organization_id
      }
    }
  }
`;
