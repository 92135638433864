import { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery, useMutation } from '@apollo/client';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import Page from '../../atoms/Page';
import ConfirmationDialog from '../../organisms/ConfirmationDialog';
import CommentBlock from '../../molecules/CommentBlock';
import FeedFloButton from '../../atoms/FeedFloButton';
import { UNKNOWN_USER_NAME, UNKNOWN_USER_AVATAR } from '../../utils/constants';
import useUser from '../../utils/hooks/useUser';
import './CommentsTab.scss';

const GET_BARN_COMMENTS_GQL = gql`
  query GetComments($barn_id: uuid!) {
    barn_comment(
      where: { barn_id: { _eq: $barn_id }, deleted_at: { _is_null: true } }
      order_by: { created_at: desc }
    ) {
      id
      created_at
      updated_at
      comment
      user_id
      user {
        name
        avatar
      }
    }
  }
`;

const SAVE_BARN_COMMENT_GQL = gql`
  mutation SaveComment($object: barn_comment_insert_input!) {
    insert_barn_comment_one(
      object: $object
      on_conflict: { constraint: barn_comment_pkey, update_columns: [deleted_at, comment] }
    ) {
      id
    }
  }
`;

const commentTemplate = `## Amenities
- [ ] Pass through
- [ ] Showers
- [ ] Coveralls
- [ ] Booties
- [ ] Ladder

## Biosecurity Rules
1. 
2. 
3. 

## Travel Directions
1. 
2. 
3. 

## Miscellaneous
- Where are the spare keys?

- What is the internet connectivity like?

- What is the status of the showers?
`;

function CommentsTab({ titleSegments = [], barnId = '' }) {
  const [commentList, setCommentList] = useState([]);
  const [comment, setComment] = useState('');
  const [commentIDToDelete, setCommentIDToDelete] = useState(null);
  const dialogRef = useRef(null);
  const { user } = useUser();
  const {
    loading: getCommentsLoading,
    error: getCommentsError,
    data: getCommentsData,
  } = useQuery(GET_BARN_COMMENTS_GQL, {
    variables: {
      barn_id: barnId,
    },
  });
  const [saveComment, { error: saveCommentError, loading: saveCommentLoading }] = useMutation(SAVE_BARN_COMMENT_GQL);
  const pageTitleSegments = useMemo(() => ['Comments', ...titleSegments], []);
  const loading = getCommentsLoading || saveCommentLoading;

  useEffect(() => {
    if (getCommentsData?.barn_comment) {
      setCommentList(getCommentsData.barn_comment);
    }
  }, [getCommentsData]);

  const onSaveComment = (comment) => {
    saveComment({
      variables: {
        object: {
          id: comment?.id,
          deleted_at: comment?.deleted_at,
          comment: comment.comment,
          user_id: comment.user_id,
          barn_id: barnId,
        },
      },
      refetchQueries: ['GetComments'],
    });
  };

  const onChangeNewComment = (text) => {
    setComment(text);
  };

  const onSubmitNewComment = () => {
    setComment('');
    onSaveComment({
      comment,
      user_id: user?.id,
      barn_id: barnId,
    });
  };

  const onLoadTemplate = () => {
    const updatedComment = comment.length === 0 ? commentTemplate : `${comment}\n\n${commentTemplate}`;
    setComment(updatedComment);
  };

  const onEdit = (id, text) => {
    const index = commentList.findIndex((comment) => comment.id === id);
    const updatedComment = {
      ...commentList[index],
      comment: text,
    };
    onSaveComment(updatedComment);
    setCommentList([...commentList.slice(0, index), updatedComment, ...commentList.slice(index + 1)]);
  };

  const onShowConfirmationDialog = () => {
    if (!dialogRef.current) {
      return;
    }

    dialogRef.current.showModal();
  };

  const onDelete = (id) => {
    setCommentIDToDelete(id);
    onShowConfirmationDialog();
  };

  const onConfirmDelete = () => {
    const index = commentList.findIndex((comment) => comment.id === commentIDToDelete);
    onSaveComment({
      ...commentList[index],
      deleted_at: Math.floor(Date.now() / 1000),
    });
  };

  if (getCommentsError) {
    return <div>{JSON.stringify(getCommentsError)}</div>;
  }

  if (saveCommentError) {
    return <div>{JSON.stringify(saveCommentError)}</div>;
  }

  if (loading) {
    return (
      <div className="CommentsTab">
        <div className="CommentsTab-editorContainer--loader">
          <MDEditor />
          <div className="CommentsTab-buttonContainer">
            <FeedFloButton
              className="CommentsTab-button"
              type="secondary"
              disabled={comment.length === 0}
              onClick={onSubmitNewComment}
            >
              Submit
            </FeedFloButton>
          </div>
        </div>
        <div className="CommentsTab-commentContainer">
          <CommentBlock loading={true} />
          <CommentBlock loading={true} />
          <CommentBlock loading={true} />
        </div>
      </div>
    );
  }

  return (
    <Page className="CommentsTab" titleSegments={pageTitleSegments}>
      <ConfirmationDialog
        dialogRef={dialogRef}
        message={'Are you sure you want to delete\nthis comment?'}
        onConfirm={onConfirmDelete}
      />
      <div className="CommentsTab-editorContainer">
        <MDEditor
          value={comment}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
          onChange={onChangeNewComment}
        />
        <div className="CommentsTab-buttonContainer">
          <FeedFloButton
            className="CommentsTab-button"
            type="secondary"
            disabled={comment.length === 0}
            onClick={onSubmitNewComment}
          >
            Submit
          </FeedFloButton>
          <FeedFloButton className="CommentsTab-button" type="primary" onClick={onLoadTemplate}>
            Load Template
          </FeedFloButton>
        </div>
      </div>
      <div className="CommentsTab-commentContainer">
        {commentList.map((comment) => (
          <CommentBlock
            key={comment.id}
            id={comment.id}
            avatar={comment.user.avatar || UNKNOWN_USER_AVATAR}
            name={comment.user.name || `${UNKNOWN_USER_NAME} (${comment.user_id})`}
            comment={comment.comment}
            createdAt={comment.created_at}
            updatedAt={comment.updated_at}
            isAuthor={comment.user_id === user?.id}
            allowEdit={true}
            allowDelete={true}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))}
      </div>
    </Page>
  );
}

CommentsTab.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
  barnId: PropTypes.string,
};

export default CommentsTab;
