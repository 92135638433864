import PropTypes from 'prop-types';
import BarnRankingTableRow from './BarnRankingTableRow';
import './BarnRankingTable.scss';

function BarnRankingTable({
  title = '',
  countColumnTitle = '',
  barnComparisonMap = {},
  barnNameMap = {},
  count = 5,
  valueFormatter = () => {},
}) {
  const sortedRows = Object.entries(barnComparisonMap)
    .sort(([, aValues], [, bValues]) => bValues.current - aValues.current)
    .slice(0, count);

  return (
    <div className="BarnRankingTable">
      <h6 className="BarnRankingTable-title">{title}</h6>
      <div className="BarnRankingTable-countColumnTitle">{countColumnTitle}</div>
      <div className="BarnRankingTable-titleSpacer" />
      {sortedRows.map(([id, values], i) => (
        <BarnRankingTableRow
          key={id}
          index={i + 1}
          name={barnNameMap[id]}
          previous={values.previous}
          current={values.current}
          valueFormatter={valueFormatter}
        />
      ))}
    </div>
  );
}

BarnRankingTable.propTypes = {
  title: PropTypes.string,
  countColumnTitle: PropTypes.string,
  barnComparisonMap: PropTypes.object,
  barnNameMap: PropTypes.object,
  count: PropTypes.number,
  valueFormatter: PropTypes.func,
};

export default BarnRankingTable;
