import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { TIME_FORMAT_HOURS_MINUTES, DATE_TIME_FORMAT_MED } from '../../utils/dates';
import Chevron from '../../atoms/Chevron';
import StatusBadge from '../../atoms/StatusBadge/StatusBadge';
import DeviceDataTable from './DeviceDataTable';
import DeviceFaultTable from './DeviceFaultTable';
import './DeviceCard.scss';

function CollapsingSection({ title, children, open = false, onOpen = () => {} }) {
  const [isOpen, setOpen] = useState(open);

  return (
    <div className="section">
      <div
        className="clickArea noselect"
        onClick={() => {
          setOpen(!isOpen);
          onOpen(isOpen);
        }}
      >
        <Chevron className="DeviceCard-collapsingSectionChevron" open={isOpen} />
        {title}
      </div>
      <div className="childHolder">{isOpen ? children : null}</div>
    </div>
  );
}
CollapsingSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
};

export default function DeviceCardView({
  deviceId,
  error,
  serial,
  barnTitle,
  onBarnClick,
  feedLineTitle,
  onFeedLineClick,
  quickFacts,
  qaReports,
  calibrations,
  lensCalibrations = [],
  inductiveSensorTestViews = [],
  transactionChart,
  rawDataChart,
}) {
  const quickFactsCards = quickFacts.map((q, i) => (
    <div key={i}>
      <div className="title">{q.title}</div>
      <div className="value" title={q.detail}>
        {q.value}
      </div>
    </div>
  ));
  let qaReport = qaReports?.map((qa) => {
    if (!qa?.tests) return null;
    const testIcons = Object.keys(qa.tests)?.map((key, i) => {
      return (
        <span
          key={i}
          title={`${JSON.stringify(qa.tests[key])}`}
          className={`dot ${qa.tests[key].error.toString() === 'true' ? 'fail' : 'pass'}`}
        />
      );
    });

    const dateInMilliseconds = qa.date * 1000;
    return (
      <div key={qa.id} className="qaReport DeviceCard-row">
        <span className="date" title={dayjs.tz(dateInMilliseconds).format(TIME_FORMAT_HOURS_MINUTES)}>
          {dayjs.tz(dateInMilliseconds).format(DATE_TIME_FORMAT_MED)}
        </span>
        {testIcons}
        <span className="status">
          <StatusBadge status={qa.status === 'pass' ? 'success' : 'error'} text={qa.status || 'blank'} />
        </span>
      </div>
    );
  });

  const calibrationViews = calibrations?.map((cal, i) => {
    const dateInMilliseconds = cal.date * 1000;
    return (
      <div key={i} className="calibration DeviceCard-row">
        <span className="date" title={dayjs.tz(dateInMilliseconds).format(TIME_FORMAT_HOURS_MINUTES)}>
          {dayjs.tz(dateInMilliseconds).format(DATE_TIME_FORMAT_MED)}
        </span>
        <span className="status">
          <StatusBadge status={cal.status === 'pass' ? 'success' : 'error'} text={cal.status || 'blank'} />
        </span>
        <span className="text">
          <p>{cal.version}</p>
        </span>
        <span className="DeviceCard-rawDataLink">
          <Link to={`/rawData/${deviceId}?from=${cal.started_at}&to=${cal.ended_at}`}>📈</Link>
        </span>
      </div>
    );
  });

  qaReport = qaReport || <span>No Reports</span>;

  if (error) return <div className="DeviceCard">{JSON.stringify(error)}</div>;

  return (
    <div className="DeviceCard">
      <div className="title">
        <span title={deviceId} className="serial">
          {serial}{' '}
        </span>
        <span className="barn" onClick={onBarnClick}>
          {barnTitle}
        </span>
        <span className="feed_line_name" onClick={onFeedLineClick}>
          - {feedLineTitle}
        </span>
      </div>
      <CollapsingSection title="Quick Facts" open>
        <div className="quick_facts">{quickFactsCards}</div>
      </CollapsingSection>
      <CollapsingSection title="QA Reports">
        <div className="DeviceCard-rows">{qaReport}</div>
      </CollapsingSection>
      <CollapsingSection title="Calibrations">
        <div className="DeviceCard-rows">{calibrationViews}</div>
      </CollapsingSection>
      <CollapsingSection title="Lens Calibrations">
        {lensCalibrations.length > 0 ? lensCalibrations : 'No lens calibrations available'}
      </CollapsingSection>
      <CollapsingSection title="Inductive Sensor Tests">
        {inductiveSensorTestViews.length > 0 ? inductiveSensorTestViews : 'No inductive sensor tests available'}
      </CollapsingSection>
      <CollapsingSection title="HTTP Trace">{transactionChart}</CollapsingSection>
      <CollapsingSection title="Sensor Data">
        <Link to={`/rawData/${deviceId}?to=${Math.floor(new Date().getTime() / 1000)}`}>📈24h</Link>
        {rawDataChart}
      </CollapsingSection>
      <CollapsingSection title="Fault Codes/Events">
        <DeviceFaultTable deviceId={deviceId} />
      </CollapsingSection>
      <CollapsingSection title="Device Data Blocks">
        <DeviceDataTable deviceId={deviceId} />
      </CollapsingSection>
    </div>
  );
}

DeviceCardView.propTypes = {
  deviceId: PropTypes.string,
  error: PropTypes.object,
  serial: PropTypes.string,
  barnTitle: PropTypes.string,
  onBarnClick: PropTypes.func,
  feedLineTitle: PropTypes.string,
  onFeedLineClick: PropTypes.func,
  quickFacts: PropTypes.array,
  qaReports: PropTypes.array,
  calibrations: PropTypes.array,
  lensCalibrations: PropTypes.array,
  inductiveSensorTestViews: PropTypes.array,
  transactionChart: PropTypes.element,
  rawDataChart: PropTypes.element,
};
