import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TransportTypes } from '../../utils/enums';
import usePrevious from '../../utils/hooks/usePrevious';
import { ChatIcon, EmailIcon } from '../../atoms/Icons';
import NotificationTimeSetting from './NotificationTimeSetting';
import './NotificationOptions.scss';

const currentTimePeriodDefaults = {
  initial: -1,
  reminder: -1,
};

function NotificationOptions({
  className = '',
  transportType = '',
  config = {},
  currentTimePeriods: currTimeProps = currentTimePeriodDefaults,
  onChange = () => {},
}) {
  const [currentTimePeriods, setCurrentTimePeriods] = useState(currTimeProps);
  const [open, setOpen] = useState(false);
  const prevTimes = usePrevious(currentTimePeriods);
  const OFF_STATE = -1;
  const MIN_TIME_FOR_CUSTOM = 20 * 60; // minimum 20 minutes

  useEffect(() => {
    setCurrentTimePeriods(currTimeProps);
  }, [currTimeProps]);

  const hasError = [currentTimePeriods.initial, currentTimePeriods.reminder].reduce((prev, curr) => {
    return (curr !== OFF_STATE && curr < MIN_TIME_FOR_CUSTOM) || prev;
  }, false);
  const hasBeenSet = currentTimePeriods.initial > 0;

  useEffect(() => {
    const compareTimes = typeof prevTimes === 'undefined' ? currTimeProps : prevTimes;
    const sameAsPrev = JSON.stringify(compareTimes) === JSON.stringify(currentTimePeriods);

    if (!sameAsPrev) {
      onChange(transportType, currentTimePeriods, !hasError);
    }
  }, [currentTimePeriods]);

  const dropCheckRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropCheckRef.current && !dropCheckRef.current.contains(event.target) && !hasError) {
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropCheckRef, hasError]);

  function getNotificationTimeSetting(type) {
    const usingOff = config[type].off === currentTimePeriods[type];
    const disabled = type === 'reminder' && currentTimePeriods.initial === OFF_STATE;
    const params = {
      usingOff,
      title: config[type].title,
      defaultSet: config[type].defaultSet,
      offState: config[type].off,
      customTime: currentTimePeriods[type],
      disabled,
      onChange: (time) =>
        setCurrentTimePeriods((prev) => {
          return {
            ...prev,
            [type]: time,
          };
        }),
    };
    return <NotificationTimeSetting key={type} title="New Notification" {...params} />;
  }

  function getNotificationTimeSettings() {
    const output = [];
    for (const type in config) {
      output.push(getNotificationTimeSetting(type));
    }
    return output;
  }

  const onIconClick = () => {
    setOpen(!hasError ? !open : true);
  };

  const renderIcon = () => {
    switch (transportType) {
      case TransportTypes.SMS:
        return <ChatIcon className="NotificationOptions-chatIcon" onClick={onIconClick} />;
      case TransportTypes.Email:
      default:
        return <EmailIcon onClick={onIconClick} />;
    }
  };

  return (
    <div ref={dropCheckRef} className={`NotificationOptions ${className}`}>
      <div className="optionsheader">
        <div className={`icon ${hasBeenSet ? 'isSet' : ''} ${hasError ? 'error' : ''} `}>{renderIcon()}</div>
      </div>
      {open && <div className="list">{getNotificationTimeSettings()}</div>}
    </div>
  );
}

NotificationOptions.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  transportType: PropTypes.string,
  config: PropTypes.object,
  currentTimePeriods: PropTypes.object,
};

export default NotificationOptions;
