import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import useSearchParamsState from '../../utils/hooks/useSearchParamsState';
import DeviceCard from '../../organisms/DeviceCard';
import DeviceSerialNumberInput from '../../organisms/DeviceSerialNumberInput';
import FeedFloTextInput from '../../atoms/FeedFloTextInput';
import FeedFloDropDown from '../../atoms/FeedFloDropDown';
import Heading from '../../atoms/Heading';
import Page from '../../atoms/Page';
import { FilterIcon } from '../../atoms/Icons';
import './DevicePage.scss';

const DEVICE_GQL = gql`
  query DeviceQuery(
    $hwCompare: String_comparison_exp
    $serialCompare: String_comparison_exp
    $limit: Int = 35
    $offset: Int = 0
    $deviceOrderBy: [device_order_by!] = { serial: asc }
  ) {
    device(
      limit: $limit
      offset: $offset
      where: { hw_version: $hwCompare, serial: $serialCompare }
      order_by: $deviceOrderBy
    ) {
      id
      serial
    }
  }
`;

function DevicePage({ titleSegments = [] }) {
  const [limit, setLimit] = useState(35);
  const [editingMode, setEditingMode] = useState(false);

  const [name, setName] = useSearchParamsState('name', 'Unnamed Device List');
  const [sort, setSort] = useSearchParamsState('sort', 'nameAsc');
  const [deviceSerialNumbers, setDeviceSerialNumbers] = useSearchParamsState('d', []);

  const serialCompare = { _in: deviceSerialNumbers };

  const pageTitleSegments = useMemo(() => ['Devices', ...titleSegments], []);

  useEffect(() => {
    if (!deviceSerialNumbers?.length > 0) setEditingMode(true);
  }, []);

  let sortObj = {};
  switch (sort) {
    case 'nameAsc':
      sortObj = { serial: 'asc' };
      break;
    case 'recent':
      sortObj = { transactions_aggregate: { max: { created_at: 'desc_nulls_last' } } };
      break;
    case 'oldest':
      sortObj = { transactions_aggregate: { max: { created_at: 'asc_nulls_first' } } };
      break;
    default:
      break;
  }
  const { loading, error, data } = useQuery(DEVICE_GQL, {
    variables: {
      serialCompare,
      hwCompare: {},
      limit,
      deviceOrderBy: sortObj,
    },
  });

  if (error) return <span>Error {JSON.stringify(error)}</span>;

  const cards = data?.device?.map((device) => (
    <DeviceCard serial={device.serial} key={device.id} deviceId={device.id} />
  ));

  if (cards?.length >= limit) cards.push(<div onClick={() => setLimit((prev) => prev + 25)}>Load More Click Here</div>);

  return (
    <Page className="DevicePage" titleSegments={pageTitleSegments}>
      <div className="header">
        <Heading text={name?.length > 0 ? name : 'unknown'} />
        <div className="filterIcon" onClick={() => setEditingMode((x) => !x)}>
          <FilterIcon />
        </div>
      </div>
      {editingMode && (
        <>
          <div className="inputBar">
            <FeedFloTextInput
              text={name?.length > 0 ? name : 'unknown'}
              onChange={(str) => {
                setName(str);
              }}
            />

            <FeedFloDropDown
              list={[
                {
                  id: 'nameAsc',
                  name: 'Serial: Alphabetical ',
                  selected: sort === 'nameAsc',
                },
                {
                  id: 'recent',
                  name: 'HTTP: Most Recent Request',
                  selected: sort === 'recent',
                },
                {
                  id: 'oldest',
                  name: 'HTTP: Least Recent Request',
                  selected: sort === 'oldest',
                },
              ]}
              onChange={(item) => {
                setSort(item.id);
              }}
            />
          </div>

          <div className="searchBar">
            <DeviceSerialNumberInput
              serials={deviceSerialNumbers.map((s) => ({ id: s, text: s }))}
              onChange={(serials) => {
                setDeviceSerialNumbers(serials.map((s) => s.text));
              }}
            />
          </div>
        </>
      )}

      <div className="DeviceCardHolder">{!loading && cards}</div>
    </Page>
  );
}

DevicePage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
};

export default DevicePage;
