import PropTypes from 'prop-types';
import LoadingSkeleton from '../LoadingSkeleton';
import './TextInput.scss';

function TextInput({
  loading = false,
  className = '',
  labelClassName = '',
  inputClassName = '',
  descriptionClassName = '',
  errorClassName = '',
  tabIndex = 0,
  isValid = true,
  disabled = false,
  name = '',
  label = '',
  placeholder = '',
  value = '',
  description = '',
  error = '',
  onChange = () => {},
  onBlur = () => {},
  left = null,
  right = null,
}) {
  const isInvalid = !isValid || error;

  if (loading) {
    return (
      <div className={`TextInput ${className}`}>
        {label && <LoadingSkeleton className="TextInput-labelText--loader" />}
        <LoadingSkeleton className="TextInput-input--loader" />
        {description && <LoadingSkeleton className="TextInput-description--loader" />}
        {error && <LoadingSkeleton className="TextInput-description--loader" />}
      </div>
    );
  }

  return (
    <div className={`TextInput ${disabled ? 'TextInput--disabled' : ''} ${className}`}>
      {label && (
        <label className={`TextInput-labelText ${isInvalid ? 'TextInput-labelText--error' : ''} ${labelClassName}`}>
          {label}
        </label>
      )}
      <div className="TextInput-inputWrapper">
        {left}
        <input
          type="text"
          className={`TextInput-input ${isInvalid ? 'TextInput-input--error' : ''} ${inputClassName}`}
          tabIndex={tabIndex}
          disabled={disabled}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        {right}
      </div>

      {description && <div className={`TextInput-description ${descriptionClassName}`}>{description}</div>}

      {isInvalid && <div className={`TextInput-errors ${errorClassName}`}>{error}</div>}
    </div>
  );
}

TextInput.propTypes = {
  loading: PropTypes.bool,
  inputRef: PropTypes.object,
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
  errorsClassName: PropTypes.string,
  tabIndex: PropTypes.number,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  left: PropTypes.node,
  right: PropTypes.node,
  error: PropTypes.string,
};

export default TextInput;
