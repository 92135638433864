import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { atom, useAtom, useAtomValue } from 'jotai';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import { FaultCodes, getFaultName } from '@norimaconsulting/fault-codes';
import PropTypes from 'prop-types';

import QuickExportButton from '../../pages/BarnConsumptionTab/QuickExportButton';
import DateRangePicker from '../../organisms/DateRangePicker';
import EventAccordion from '../../molecules/EventAccordion';
import EventChart from '../../molecules/EventChart';
import FeedFloDropDown from '../../atoms/FeedFloDropDown';
import Button from '../../atoms/Button';
import SectionalButton from '../../atoms/SectionalButton/SectionalButton';
import {
  BarChartIconSVG,
  ClockIcon,
  FeedChangeIconSVG,
  InterventionIconSVG,
  ObservationIconSVG,
  PigIconSVG,
  StockChangeIconSVG,
  WarningIcon,
} from '../../atoms/Icons';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';

import { ADD_FAULT_COMMENT_GQL, CONSUMPTION_TAB_GQL, CONSUMPTION_TAB_ALG_GQL } from './queries';
import { addComma } from '../../utils';
import WebAppContext from '../../utils/webAppContext';
import useDefaultDateRange from '../../utils/hooks/useDefaultDateRange';
import { createCumulativePlot, createDailyPlot } from '../../utils/chartHelpers';
import { convertGramsToSmallUnits, weightSmallUnitLabel } from '../../utils/unitConversion';
import { ConsumptionTabCustomEventType, ConsumptionTabEventCategory } from '../../utils/enums';
import { DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE } from '../../utils/dates';
import useFeature from '../../utils/hooks/useFeature';
import useUser from '../../utils/hooks/useUser';
import { algorithmOverriddenAtom, algorithmVersionAtom } from '../../utils/jotaiAtoms';
import { censorTypes, useCensor } from '../../utils/hooks/useCensor';

import iconColours from './ConsumptionColours.module.scss';
import './ConsumptionDisplay.scss';

// The default font size is 16 pixels
const DEFAULT_FONT_SIZE = 16;
const CHART_MARGIN = { top: 20, right: 25, bottom: 65, left: 35 };

// The list of event categories for the event accordion filter to offer.
const EVENT_CATEGORIES = [
  { id: ConsumptionTabEventCategory.ChangeInConsumption, name: 'Health Flag' },
  { id: ConsumptionTabEventCategory.CustomEvent, name: 'Custom Event' },
  { id: ConsumptionTabEventCategory.EmptyPipe, name: 'Empty Pipe' },
  { id: ConsumptionTabEventCategory.InactiveAuger, name: 'Inactive Auger' },
];

// Fill and stroke colours for each event category.
const EVENT_COLOURS = Object.freeze({
  [ConsumptionTabEventCategory.CustomEvent]: {
    fill: iconColours.customEventFill,
    stroke: iconColours.customEventStroke,
  },
  [ConsumptionTabEventCategory.ChangeInConsumption]: {
    fill: iconColours.changeInConsumptionFill,
    stroke: iconColours.changeInConsumptionStroke,
  },
  [ConsumptionTabEventCategory.InactiveAuger]: {
    fill: iconColours.inactiveAugerFill,
    stroke: iconColours.inactiveAugerStroke,
  },
  [ConsumptionTabEventCategory.EmptyPipe]: { fill: iconColours.emptyPipeFill, stroke: iconColours.emptyPipeStroke },
});

// All potential viewing options.
const VIEW_OPTIONS = Object.freeze({
  FullBarn: 'Full Barn',
  FeedLines: 'Feed Lines',
  Rooms: 'Rooms',
});

// The order that viewing options should appear in the drop-down list.
const VIEW_OPTION_ORDER = Object.freeze([
  VIEW_OPTIONS.FullBarn,
  VIEW_OPTIONS.FeedLines,
  // TODO: Uncomment the following once we know what to do about rooms.
  // VIEW_OPTIONS.Rooms,
]);

const VIEW_OPTION_LIST = Object.freeze(
  VIEW_OPTION_ORDER.map((viewOption, index) => {
    return { name: viewOption, id: index };
  }),
);

const DEFAULT_VIEW_OPTION = VIEW_OPTIONS.FeedLines;

// The colours that line plots will be assigned in order.
// If there are more line plots than colours then colours will repeat from the beginning.
const LINE_COLOUR_ROTATION = Object.freeze(['#26AF5F', '#6AE09B', '#65C9DA', '#CFD74D']);

// A max fault duration used to filter out invalid faults that never ended or got cleaned up.
const MAX_DURATION_IN_SECONDS = 1209600; // 2 weeks in seconds
// A minimum fault duration used to filter out quickly-resolved faults that wouldn't be notified for.
const MIN_DURATION_IN_SECONDS = 43200; // 12 hours in seconds
const ALL_FAULTS_MIN_DURATION_IN_SECONDS = 300; // 5 minutes in seconds

export const inspectionWindowActiveAtom = atom(false);

/**
 * Given an event category, determine the appropriate JSX to represent it.
 *
 * @param {string} category The category as a member of the ConsumptionTabEventCategory enum.
 * @param {string} customEventType If the category is 'CustomEvent', also pass a custom event category.
 
 * @returns The appropriate icon as JSX.
 */
function makeEventIcon(category, customEventType, dashed = false) {
  let icon;
  let containerClass;
  if (category === ConsumptionTabEventCategory.EmptyPipe) {
    containerClass = 'ConsumptionDisplay-eventIconContainer--emptyPipe';
    icon = <WarningIcon className="ConsumptionDisplay-eventIcon ConsumptionDisplay-emptyPipeIcon" />;
  } else if (category === ConsumptionTabEventCategory.InactiveAuger) {
    containerClass = 'ConsumptionDisplay-eventIconContainer--inactiveAuger';
    icon = <ClockIcon className="ConsumptionDisplay-eventIcon ConsumptionDisplay-inactiveAugerIcon" />;
  } else if (category === ConsumptionTabEventCategory.ChangeInConsumption) {
    containerClass = 'ConsumptionDisplay-eventIconContainer--changeInConsumption';
    icon = <BarChartIconSVG className="ConsumptionDisplay-eventIcon ConsumptionDisplay-changeInConsumptionIcon" />;
  } else if (category === ConsumptionTabEventCategory.CustomEvent) {
    containerClass = 'ConsumptionDisplay-eventIconContainer--customEvent';
    if (customEventType === ConsumptionTabCustomEventType.FeedChange) {
      icon = <FeedChangeIconSVG className="ConsumptionDisplay-eventIcon ConsumptionDisplay-customEventIcon" />;
    } else if (customEventType === ConsumptionTabCustomEventType.Intervention) {
      icon = <InterventionIconSVG className="ConsumptionDisplay-eventIcon ConsumptionDisplay-customEventIcon" />;
    } else if (customEventType === ConsumptionTabCustomEventType.Observation) {
      icon = (
        <ObservationIconSVG className="ConsumptionDisplay-eventIcon ConsumptionDisplay-customEventIcon--observation" />
      );
    } else if (customEventType === ConsumptionTabCustomEventType.Other) {
      icon = <PigIconSVG className="ConsumptionDisplay-eventIcon ConsumptionDisplay-customEventIcon--other" />;
    } else if (customEventType === ConsumptionTabCustomEventType.StockChange) {
      icon = <StockChangeIconSVG className="ConsumptionDisplay-eventIcon ConsumptionDisplay-customEventIcon" />;
    }
  }

  return (
    icon && (
      <div
        className={`ConsumptionDisplay-eventIconContainer ${containerClass} ${
          dashed ? 'ConsumptionDisplay-eventIconContainer--dashed' : ''
        }`}
      >
        {icon}
      </div>
    )
  );
}

/**
 * Make the content component of an event panel.
 *
 * @param {string} title The title for this event.
 * @param {number} startedAt The start timestamp in seconds.
 * @param {number} endedAt The end timestamp in seconds. Null if ongoing.
 * @param {number} nofeedSeconds Seconds of nofeed. 0 values will not be shown.
 * @param {boolean} expanded Whether the panel is expanded.
 *
 * @returns JSX.
 */
function makeEventContent(title = '', startedAt = 0, endedAt = null, nofeedSeconds = 0, expanded = false) {
  const duration = endedAt
    ? dayjs.duration(dayjs.tz(1000 * endedAt).diff(dayjs.tz(1000 * startedAt))).humanize()
    : 'ongoing';
  const nofeedHours = nofeedSeconds > 0 && Math.round(dayjs.duration(nofeedSeconds, 'seconds').asHours());
  return (
    <div className="ConsumptionDisplay-eventContent">
      <p className="ConsumptionDisplay-eventTitleText">{title}</p>

      {expanded ? (
        <span className="ConsumptionDisplay-eventDateText">
          {dayjs.tz(1000 * startedAt).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE)}
          {endedAt && <br />}
          {endedAt && dayjs.tz(1000 * endedAt).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE)}
        </span>
      ) : (
        <span className="ConsumptionDisplay-eventDateText">
          {dayjs.tz(1000 * startedAt).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE)}
        </span>
      )}
      {duration}
      {nofeedHours ? (
        <span className="ConsumptionDisplay-eventTitleText">
          {nofeedHours === 0 ? '<1' : nofeedHours}
          {nofeedHours <= 1 ? ' hr of ' : ' hrs of '}
          NoFeed
        </span>
      ) : null}
    </div>
  );
}

function ConsumptionDisplay({ barnId, animalGroupDateRange = null, openFaultIds = null, visibleFeedLineIds = null }) {
  const algorithmVersion = useAtomValue(algorithmVersionAtom);
  const algorithmOverridden = useAtomValue(algorithmOverriddenAtom);
  const now = useMemo(() => dayjs(), []);

  const { user } = useUser();
  const { active: showAllFaultsFlag } = useFeature('SHOW_ALL_FAULTS');

  const { isMetric } = useContext(WebAppContext);
  const { censor } = useCensor();
  const defaultDateRange = useDefaultDateRange(barnId);

  const [barnName, setBarnName] = useState(null);

  const [dateRange, setDateRange] = useState(animalGroupDateRange || defaultDateRange);
  const [dateRangePickerVisible, setDateRangePickerVisible] = useState(false);

  const [selectedView, setSelectedView] = useState(DEFAULT_VIEW_OPTION);
  const [cumulative, setCumulative] = useState(true);

  const [chartLinePlots, setChartLinePlots] = useState([]);
  const [chartLineDataByFeedLine, setChartLineDataByFeedLine] = useState({});
  // A dictionary to track which feed lines are hidden from the chart.
  const [hiddenChartLines, setHiddenChartLines] = useState({});

  const [events, setEvents] = useState([]);
  // A dictionary to track which events are expanded in the event accordion.
  const [expandedEvents, setExpandedEvents] = useState({});

  // A dictionary mapping feed line IDs to feed line names.
  const [feedLineNamesById, setFeedLineNamesById] = useState({});
  // A dictionary mapping feed line IDs to lexicographical name order, starting from 0.
  //  ie. { 'East North' : 0, 'East South' : 1, 'West North' : 2, 'West South': 3 }
  const [feedLineOrder, setFeedLineOrder] = useState({});
  // A dictionary tracking consumption totals by feed line ID.
  const [usageInGramsByFeedLine, setUsageInGramsByFeedLine] = useState(0);

  const [remPixels, setRemPixels] = useState(DEFAULT_FONT_SIZE);

  const [projectionMode, setProjectionMode] = useAtom(inspectionWindowActiveAtom);

  // State used for the event accordion
  // A dictionary of selected categories.
  // Initialized to 'true' for each of the category ids passed to the 'categories' prop.
  const [visibleCategories, setVisibleCategories] = useState(
    EVENT_CATEGORIES.reduce((filters, category) => {
      filters[category.id] = true;
      return filters;
    }, {}),
  );
  const [scrollToEventId, setScrollToEventId] = useState(null);
  const [saveFaultComment] = useMutation(ADD_FAULT_COMMENT_GQL);

  const startInSeconds = dateRange.from.unix() || 0;
  const endInSeconds = dateRange.to.unix() || 0;

  const chartHeight = 56 * remPixels;

  useEffect(() => {
    // Load the computed value for rems once the page has fully loaded
    setRemPixels(parseFloat(getComputedStyle(document.documentElement).fontSize));
  }, []);

  // controlled value
  useEffect(() => {
    const hiddenChartLinesUpdated = Object.keys(chartLineDataByFeedLine).reduce((hiddenChartLines, feedLineId) => {
      if (Array.isArray(visibleFeedLineIds)) {
        // if explicitly passed an array...
        hiddenChartLines[feedLineId] = !visibleFeedLineIds.includes(feedLineId);
      } else {
        // show everything
        hiddenChartLines[feedLineId] = false;
      }
      return hiddenChartLines;
    }, {});

    setHiddenChartLines(hiddenChartLinesUpdated);
  }, [visibleFeedLineIds]);

  // This effect updates the date picker and chart range when the animal group loads or switches.
  useEffect(() => {
    if (animalGroupDateRange) {
      setDateRange(animalGroupDateRange);
    }
  }, [animalGroupDateRange]);

  const { error: errorDefaultQuery, loading: loadingDefaultQuery } = useQuery(CONSUMPTION_TAB_GQL, {
    variables: {
      barnId,
      endRangeTimestamp: now.unix() < endInSeconds ? now.unix() : endInSeconds,
      faultCodes: [
        FaultCodes.EMPTY_PIPE,
        FaultCodes.INACTIVE_AUGER,
        // Hide until we fix the thresholds
        // FaultCodes.SUDDEN_CONSUMPTION_DROP,
        // FaultCodes.CONSUMPTION_TRENDING_DOWN,
      ],
      maxDuration: MAX_DURATION_IN_SECONDS,
      minDuration: showAllFaultsFlag ? ALL_FAULTS_MIN_DURATION_IN_SECONDS : MIN_DURATION_IN_SECONDS,
      startRangeTimestamp: startInSeconds,
    },
    onCompleted: feedFrameQueryOnCompleted,
    onError: (error) => console.error(`Error querying page data: ${error}`),
    skip: algorithmOverridden,
    // These seem to help with the lag bug. Probably caused by using onCompleted
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const { error: errorAlgQuery, loading: loadingAlgQuery } = useQuery(CONSUMPTION_TAB_ALG_GQL, {
    variables: {
      algorithmVersion,
      barnId,
      endRangeTimestamp: endInSeconds,
      faultCodes: [
        FaultCodes.EMPTY_PIPE,
        FaultCodes.INACTIVE_AUGER,
        // Hide until we fix the thresholds
        // FaultCodes.SUDDEN_CONSUMPTION_DROP,
        // FaultCodes.CONSUMPTION_TRENDING_DOWN,
      ],
      maxDuration: MAX_DURATION_IN_SECONDS,
      minDuration: showAllFaultsFlag ? ALL_FAULTS_MIN_DURATION_IN_SECONDS : MIN_DURATION_IN_SECONDS,
      startRangeTimestamp: startInSeconds,
    },
    onCompleted: feedFrameQueryOnCompleted,
    onError: (error) => console.error(`Error querying page data: ${error}`),
    skip: !algorithmOverridden,
  });

  const { error, loading } = algorithmOverridden
    ? { error: errorAlgQuery, loading: loadingAlgQuery }
    : { error: errorDefaultQuery, loading: loadingDefaultQuery };

  function feedFrameQueryOnCompleted(response) {
    // Create a dictionary of feed line names for this barn.
    // Also creates a seed dictionary for the next reducer to build 'consumptionFramesByFeedLine' from.
    //  This seed dictionary contains an empty array for each feed line ID.
    //  It ensures that feed lines will be included in the chart even without consumption data.
    // Also create a list of fault events associated with feed line IDs, sorted by startedAt time.

    // A fault can be duplicated across multiple device assignments, so we keep track of what fault ids have been processed.
    const trackedEvents = {};

    const {
      feedLineNamesById = {},
      consumptionFramesByFeedLineSeed = {},
      usageByFeedLineSeed = {},
      events = [],
      expandedEvents = {},
      latestDeviceTransactionTimestampByFeedLine = {},
    } = response.feed_line?.reduce(
      (
        {
          feedLineNamesById,
          consumptionFramesByFeedLineSeed,
          usageByFeedLineSeed,
          events,
          expandedEvents,
          latestDeviceTransactionTimestampByFeedLine,
        },
        { id: feedLineId, name, device_assignments },
      ) => {
        // Adds this feed line's 'id : name' as a 'key : value' pair to this dictionary.
        feedLineNamesById[feedLineId] = censor(name, censorTypes.feedline);
        // Adds this feed line's 'id : [empty array]' as a 'key : value' pair to the seed dictionary.
        consumptionFramesByFeedLineSeed[feedLineId] = [];
        // Adds this feed line's 'id : 0' as a 'key : value' pair for initializing usage totals.
        usageByFeedLineSeed[feedLineId] = 0;
        // Adds all events for this feed line to this array, then sorts the array by 'startedAt'.
        events = (
          device_assignments?.reduce((events, deviceAssignment) => {
            deviceAssignment?.device?.faults
              ?.filter((fault) => {
                // Filtering here instead of a 2nd query due to simplicity.
                // Faults belong to devices, device are assigned. We were simply taking the faults that _existed_ during
                // the specified time range though they may have been assigned elsewhere during the time range.

                // This now filters to make sure that the fault occurred during this assignment's active period
                return (
                  // fault must have started between the device start and end OR the device is still assigned to this pipe
                  // The <= is on both for edge cases of an instantaneous event
                  deviceAssignment.started_at <= fault.started_at &&
                  (!deviceAssignment.ended_at || fault.started_at <= deviceAssignment.ended_at) &&
                  // and either the fault hasn't ended yet or it ended before the device assignment ended (if it's ended)
                  (!fault.ended_at || !deviceAssignment.ended_at || fault.ended_at <= deviceAssignment.ended_at)
                );
              })
              ?.reduce((events, { code, ended_at, fault_comments, id, nofeed_seconds, started_at }) => {
                const { comment: notes = '', fault_root_cause_id: rootCauseId = null } = fault_comments?.[0] ?? {};
                if (!trackedEvents[id]) {
                  // Ongoing (unended) events will be selected and expanded by default.
                  if (null === ended_at) {
                    expandedEvents[id] = true;
                  }
                  events.push({
                    barnId,
                    code,
                    endedAt: ended_at,
                    feedLineId,
                    id,
                    lotId: '',
                    nofeedSeconds: nofeed_seconds,
                    notes,
                    room: '',
                    rootCauseId,
                    startedAt: started_at,
                  });
                  trackedEvents[id] = true;
                }
                return events;
              }, events);
            return events;
          }, events) || []
        ).sort((a, b) => {
          if (a.endedAt === null && b.endedAt !== null) {
            return -1;
          } else if (a.endedAt !== null && b.endedAt === null) {
            return 1;
          }
          return b.startedAt - a.startedAt;
        });

        // Multiple devices may be assigned to this feed line over the chart window, so we reduce
        //  to the most recent transaction among all assignments.
        const latestDeviceTransactionTimestamp =
          device_assignments?.reduce((latestTransactionTimestamp, deviceAssignment) => {
            const timestamp = deviceAssignment?.device?.last_transaction?.[0]?.occured_at || 0;
            return Math.max(latestTransactionTimestamp, timestamp);
          }, 0) || 0;

        latestDeviceTransactionTimestampByFeedLine[feedLineId] = latestDeviceTransactionTimestamp;

        return {
          feedLineNamesById,
          consumptionFramesByFeedLineSeed,
          usageByFeedLineSeed,
          events,
          expandedEvents,
          latestDeviceTransactionTimestampByFeedLine,
        };
      },
      {
        feedLineNamesById: {},
        consumptionFramesByFeedLineSeed: {},
        usageByFeedLineSeed: {},
        events: [],
        expandedEvents: {},
        latestDeviceTransactionTimestampByFeedLine: {},
      },
    ) || {};

    // Maps feed line IDs to the numerical order they would appear if sorted by name, ascending from 0.
    const feedLineOrder = Object.keys(feedLineNamesById)
      .sort((a, b) => feedLineNamesById[a].localeCompare(feedLineNamesById[b]))
      .reduce((feedLineOrder, feedLineId, index) => {
        feedLineOrder[feedLineId] = index;
        return feedLineOrder;
      }, {});

    const {
      consumptionFrames = [],
      consumptionFramesByFeedLine = {},
      usageInGramsByFeedLine = {},
    } = response.barn_feed_frames?.reduce(
      (
        { consumptionFrames, consumptionFramesByFeedLine, usageInGramsByFeedLine },
        { total_consumption, start_timestamp, end_timestamp, feed_line_id },
      ) => {
        const massInSmallUnits = convertGramsToSmallUnits(isMetric, total_consumption);

        if (massInSmallUnits > 0) {
          const frame = {
            value: massInSmallUnits,
            start: start_timestamp,
            end: end_timestamp,
          };
          // All frames are tracked together for the full barn view.
          consumptionFrames.push(frame);
          // Frames are also tracked according to their feed line for the feed line view.
          consumptionFramesByFeedLine[feed_line_id].push(frame);

          usageInGramsByFeedLine[feed_line_id] += total_consumption;
        }
        return { consumptionFrames, consumptionFramesByFeedLine, usageInGramsByFeedLine };
      },
      {
        consumptionFrames: [],
        consumptionFramesByFeedLine: consumptionFramesByFeedLineSeed,
        usageInGramsByFeedLine: usageByFeedLineSeed,
      },
    ) || {};

    // Add filler feed frames that extend lines to the most recent device transaction.
    Object.entries(consumptionFramesByFeedLine).forEach(([feedLineId, consumptionFramesFeedLine]) => {
      // The end timestamp for our filler feed frame is the earlier timestamp of:
      //  1. the latest device transaction
      //  2. the end of the window.
      const fillerEnd = Math.min(latestDeviceTransactionTimestampByFeedLine[feedLineId], endInSeconds);

      // If consumptionFramesFeedLine is somehow an empty array the start time will be undefined which
      // appears to be fine with visx, it covers the whole bottom of the chart then.
      const fillerFeedFrame = { start: consumptionFramesFeedLine.at(-1)?.end, end: fillerEnd, value: 0 };
      // Add filler point to the feed line plot and the barn summary plot.
      if (fillerFeedFrame.start < fillerFeedFrame.end) {
        consumptionFramesFeedLine.push(fillerFeedFrame);
        consumptionFrames.push(fillerFeedFrame);
      }
    });

    // Convert consumption data into chart line data for the entire barn.
    // Due to overlapping feed frames, this is not a superset of all feed line chart data.
    const cumulativePlot = createCumulativePlot(consumptionFrames, startInSeconds, endInSeconds);
    const dailyPlot = createDailyPlot(consumptionFrames, startInSeconds, endInSeconds);
    const chartLinePlots = { cumulative: cumulativePlot, daily: dailyPlot };

    // Convert consumption data into chart line data for each feed line.
    // Sorted lexicographically by feed line name after transforming the dictionary into an array.
    const chartLineDataByFeedLine = Object.entries(consumptionFramesByFeedLine).reduce(
      (chartLineDataByFeedLine, [feedLineId, consumptionFrames]) => {
        const cumulativePlot = createCumulativePlot(consumptionFrames, startInSeconds, endInSeconds);
        const dailyPlot = createDailyPlot(consumptionFrames, startInSeconds, endInSeconds);
        chartLineDataByFeedLine[feedLineId] = { cumulative: cumulativePlot, daily: dailyPlot };
        return chartLineDataByFeedLine;
      },
      {},
    );

    // Initialize the dictionary of hidden chart lines to 'false' for each feed line ID.
    const hiddenChartLines = Object.keys(feedLineNamesById).reduce((hiddenChartLines, feedLineId) => {
      if (Array.isArray(visibleFeedLineIds)) {
        hiddenChartLines[feedLineId] = !visibleFeedLineIds.includes(feedLineId);
      } else {
        hiddenChartLines[feedLineId] = false;
      }
      return hiddenChartLines;
    }, {});

    // Set events in 'openFaultIds' as expanded by default.
    openFaultIds?.forEach((id) => (expandedEvents[id] = true));

    setChartLinePlots(chartLinePlots);
    setChartLineDataByFeedLine(chartLineDataByFeedLine);
    setEvents(events);
    setExpandedEvents(expandedEvents);
    setFeedLineNamesById(feedLineNamesById);
    setFeedLineOrder(feedLineOrder);
    setHiddenChartLines(hiddenChartLines);
    setUsageInGramsByFeedLine(usageInGramsByFeedLine);
    setBarnName(response?.farm?.[0]?.name || 'FeedFlo Data');
  }

  /**
   * Deselects the event with given 'id', and selects the next expanded event, if any.
   *
   * @param id The id of the event we want to deselect.
   */
  const deselectEvent = useCallback(
    /**
     * @param {string} id
     */
    (id) => {
      // Remove the id from the expanded events dictionary.
      const updatedExpandedEvents = cloneDeep(expandedEvents);
      delete updatedExpandedEvents[id];
      setExpandedEvents(updatedExpandedEvents);
    },
    [expandedEvents],
  );

  /**
   * Deselects all events.
   */
  const deselectAllEvents = useCallback(
    /**
     * @param {string} id
     */
    () => setExpandedEvents({}),
    [],
  );

  /**
   * Selects the event with given 'id', and deselects the previously selected event, if any.
   *
   * @param id The id of the event we want to select.
   */
  const selectEvent = useCallback(
    /**
     * @param {string} id
     */
    (id) => {
      // Add the id to the expanded events dictionary.
      const updatedExpandedEvents = cloneDeep(expandedEvents);
      updatedExpandedEvents[id] = true;
      setExpandedEvents(updatedExpandedEvents);
    },
    [expandedEvents],
  );

  /**
   * Updates 'hiddenChartLines' state for a given feed line ID with a given value.
   *
   * @param feedLineId The feed line ID of the line chart we want to hide or reveal.
   * @param hidden The new value. 'true' for hidden, 'false' for revealed.
   */
  const hideChartLine = useCallback(
    /**
     * @param {number} feedLineId
     * @param {boolean} hidden
     */
    (feedLineId, hidden) => {
      const hiddenChartLinesUpdated = cloneDeep(hiddenChartLines);
      hiddenChartLinesUpdated[feedLineId] = hidden;
      setHiddenChartLines(hiddenChartLinesUpdated);
    },
    [hiddenChartLines],
  );

  /**
   * Updates 'hiddenChartLines' state to 'false' for all feed line IDs.
   * Useful for resetting hidden status when changing view selection.
   */
  const revealAllChartLines = useCallback(() => {
    const hiddenChartLinesUpdated = Object.keys(hiddenChartLines).reduce((hiddenChartLines, feedLineId) => {
      hiddenChartLines[feedLineId] = false;
      return hiddenChartLines;
    }, {});
    setHiddenChartLines(hiddenChartLinesUpdated);
  }, [hiddenChartLines]);

  /**
   * Toggles 'projectionMode' between boolean states.
   *
   * When enabling projection mode when on feed line view, we want to hide every chart line but one.
   */
  const toggleChartProjectionMode = useCallback(() => {
    setProjectionMode(!projectionMode);
  }, [projectionMode]);

  const showCumulative = useCallback(() => {
    // Disable the projection mode if switching to daily view.
    setCumulative(true);
  }, [cumulative]);

  const showDaily = useCallback(() => {
    setProjectionMode(false);
    setCumulative(false);
  }, [cumulative]);

  /**
   * Callback function passed to view selection drop-down.
   *
   * When enabling feed line view when chart projections are enabled, we want to hide every chart line but one.
   */
  const toggleSelectedView = useCallback(
    (value) => {
      setSelectedView(value.name);
      // Turn off hiding of all chart lines and event points.
      revealAllChartLines();
    },
    [revealAllChartLines],
  );

  /**
   * Given an event/fault code, determine the appropriate event category.
   *
   * @param eventCode The event/fault code in question.
   *
   * @returns The event category from the ConsumpionTabEventCategory enum.
   */
  const getEventCategory = useCallback(
    /**
     * @param {number} eventCode
     */
    (eventCode) => {
      if (eventCode === FaultCodes.EMPTY_PIPE) {
        return ConsumptionTabEventCategory.EmptyPipe;
      }

      if (eventCode === FaultCodes.INACTIVE_AUGER) {
        return ConsumptionTabEventCategory.InactiveAuger;
      }

      if (eventCode === FaultCodes.SUDDEN_CONSUMPTION_DROP || eventCode === FaultCodes.CONSUMPTION_TRENDING_DOWN) {
        return ConsumptionTabEventCategory.ChangeInConsumption;
      }

      // TODO: when implementing custom events. If the event is a custom event return ConsumptionTabEventCategory.CustomEvent.
      return null;
    },
    [],
  );

  const getCustomEventType = useCallback(() => {
    // TODO: when implementing custom events. If the event is a custom event include the ConsumptionTabCustomEventType enum in this file and return the corresponding custom type.
    return null;
  }, []);

  /**
   * Callback passed to EventAccordion events used to insert 'note' as a fault_comment.
   */
  const saveEventNote = useCallback((faultId, rootCauseId, note) => {
    saveFaultComment({
      variables: {
        object: {
          fault_id: faultId,
          fault_root_cause_id: rootCauseId,
          comment: note,
        },
      },
      onError: (error) => console.error(`Error saving note: ${error}`),
    });
  }, []);

  // A simplification of chart lines containing info that the chart needs for display and interaction.
  // Used for displaying the unified barn chart.
  const chartLines = useMemo(() => {
    const linePlot = cumulative ? chartLinePlots.cumulative : chartLinePlots.daily;
    return {
      linePlot,
      colour: LINE_COLOUR_ROTATION[0],
      hidden: false,
      label: VIEW_OPTIONS.FullBarn,
      onClick: () => null,
    };
  }, [cumulative, chartLinePlots]);

  // A simplification of chart lines split apart by feed line ID.
  // Used for displaying split feed line charts.
  const chartLinesByFeedLine = useMemo(
    () =>
      Object.entries(chartLineDataByFeedLine)
        .sort((a, b) => feedLineNamesById[a[0]]?.localeCompare(feedLineNamesById[b[0]] || 0))
        .map(([feedLineId, linePlots], index) => {
          const linePlot = cumulative ? linePlots.cumulative : linePlots.daily;
          return {
            linePlot,
            label: feedLineNamesById[feedLineId],
            colour: LINE_COLOUR_ROTATION[index % LINE_COLOUR_ROTATION.length],
            link: {
              pathname: `/b/${barnId}/line/${feedLineId}`,
              state: { start: startInSeconds, end: endInSeconds },
            },
            hidden: hiddenChartLines[feedLineId],
            onHideClick: (hidden) => hideChartLine(feedLineId, hidden),
          };
        }),
    [
      cumulative,
      startInSeconds,
      endInSeconds,
      chartLineDataByFeedLine,
      feedLineNamesById,
      hiddenChartLines,
      hideChartLine,
    ],
  );

  const feedLineRowData = useMemo(
    () =>
      Object.entries(feedLineOrder).reduce((feedLineData, [id, row], index) => {
        feedLineData[row] = {
          id,
          label: feedLineNamesById[id],
          colour: LINE_COLOUR_ROTATION[index % LINE_COLOUR_ROTATION.length],
          hidden: hiddenChartLines[id],
          reveal: () => hideChartLine(id, false),
        };
        return feedLineData;
      }, []),
    [feedLineNamesById, feedLineOrder, hiddenChartLines, hideChartLine],
  );

  // Collect all relevant state data into two lists.
  // 'accordionEvents' contains all the info needed to display and control the accordion list.
  // 'chartEvents' contains all the info needed to display and control events on the chart.
  const { accordionEvents, chartEvents } = useMemo(() => {
    const accordionEvents = events.reduce((accordionEvents, event) => {
      const category = getEventCategory(event.code);
      const customEventType = getCustomEventType(event.code);
      const expanded = true === expandedEvents[event.id];

      accordionEvents.push({
        id: event.id,

        left: makeEventIcon(category, customEventType, false),
        content: makeEventContent(
          getFaultName(event.code),
          event.startedAt,
          event.endedAt,
          event.nofeedSeconds,
          expanded,
        ),

        category,
        details: feedLineNamesById[event.feedLineId],
        expanded,
        hidden: hiddenChartLines[event.feedLineId],

        linkText: user?.isStaff ? 'View Fault' : null,
        linkTo: user?.isStaff ? `/fault/${event.id}` : null,
        notes: event.notes,

        onPanelClick: () => {
          const isExpanded = expandedEvents[event.id] ?? false;
          if (isExpanded) {
            deselectEvent(event.id);
          } else {
            selectEvent(event.id);
          }
        },
        saveNote: (note) => {
          saveEventNote(event.id, event.rootCauseId, note);
        },
      });
      return accordionEvents;
    }, []);

    const chartEvents = events.reduce((eventPoints, { code, endedAt, feedLineId, id, startedAt }) => {
      const category = getEventCategory(code);
      const hidden = !visibleCategories[category];
      if (!hidden) {
        const selected = true === expandedEvents[id];
        eventPoints.push({
          colour: EVENT_COLOURS[getEventCategory(code)],
          // 'startedAt' and 'endedAt' timestamps in seconds.
          //   If 'endedAt' is null, we use the current timestamp instead.
          points: [startedAt, endedAt ? endedAt : dayjs.tz().unix()],
          row: feedLineOrder[feedLineId] || 0,
          selected,
          onClick: () => {
            if (selected) {
              deselectEvent(id);
            } else {
              selectEvent(id);
              setScrollToEventId(id);
            }
          },
        });
      }
      return eventPoints;
    }, []);

    return { accordionEvents, chartEvents };
  }, [events, expandedEvents, hiddenChartLines, visibleCategories, deselectEvent, selectEvent]);

  // Sums up all usage on visible feed lines.
  const usageInSmallUnits = useMemo(
    () =>
      convertGramsToSmallUnits(
        isMetric,
        Object.entries(usageInGramsByFeedLine).reduce(
          (usageInGrams, [feedLineId, feedLineUsageInGrams]) =>
            !hiddenChartLines[feedLineId] ? (usageInGrams += feedLineUsageInGrams) : usageInGrams,
          0,
        ),
      ),
    [hiddenChartLines, usageInGramsByFeedLine],
  );

  if (error) return <span>{JSON.stringify(error, null, 2)}</span>;

  return (
    <div className="ConsumptionDisplay">
      <div className="ConsumptionDisplay-chartContainer">
        <div className="ConsumptionDisplay-topBar">
          <div className="ConsumptionDisplay-dateAndDropdownContainer">
            <DateRangePicker
              className="dateRangeButton"
              visible={dateRangePickerVisible}
              dateRange={dateRange}
              setVisible={setDateRangePickerVisible}
              setDateRange={setDateRange}
            />
            <FeedFloDropDown
              className="feedFloDropDown"
              defaultTitle={DEFAULT_VIEW_OPTION}
              list={VIEW_OPTION_LIST}
              onChange={toggleSelectedView}
            />
            <SectionalButton
              sections={[
                { content: 'Cumulative', active: cumulative, onClick: showCumulative },
                { content: 'Daily', active: !cumulative, onClick: showDaily },
              ]}
            />
            <QuickExportButton barnId={barnId} barnName={barnName} dateRange={dateRange} />
          </div>
          <div className="ConsumptionDisplay-chartButtonsContainer">
            {/** TODO: When implementing custom event creation, add functionality to this button */}
            <Button
              className="ConsumptionDisplay-chartOptionButton--disabled ConsumptionDisplay-chartOptionButton"
              content="Add Event"
            />

            <Button
              className={`ConsumptionDisplay-chartOptionButton ${
                projectionMode ? 'ConsumptionDisplay-chartOptionButton--selected' : ''
              }`}
              content="Projection Range"
              disabled={!cumulative}
              onClick={toggleChartProjectionMode}
            />
          </div>
        </div>
        <div className="ConsumptionDisplay-body">
          <div className="charts">
            <div className="title">Feed Usage</div>
            {loading ? (
              <LoadingSkeleton className="ConsumptionDisplay-loading" />
            ) : (
              <div>
                {addComma(usageInSmallUnits)} <span>{weightSmallUnitLabel(isMetric)}</span>
              </div>
            )}
            <EventChart
              start={startInSeconds}
              end={endInSeconds}
              chartLines={VIEW_OPTIONS.FullBarn === selectedView ? chartLines : chartLinesByFeedLine}
              events={chartEvents}
              rows={feedLineRowData}
              inspectionWindow={projectionMode ? { lineOfBestFit: true } : null}
              snapToPoints={!cumulative}
              isMetric={isMetric}
              chartHeight={chartHeight}
              chartMargin={CHART_MARGIN}
              loading={loading}
            />
          </div>
          <div
            className="ConsumptionDisplay-chartOptionButton ConsumptionDisplay-deselectAllEventsButton"
            style={{
              right: CHART_MARGIN.right,
              top: chartHeight,
            }}
            onClick={deselectAllEvents}
          >
            Deselect All Events
          </div>
        </div>
      </div>
      <div className="ConsumptionDisplay-eventAccordionContainer">
        <EventAccordion
          events={accordionEvents}
          categories={EVENT_CATEGORIES}
          visibleCategories={visibleCategories}
          setVisibleCategories={setVisibleCategories}
          scrollToEventId={scrollToEventId}
          selectedEventIds={openFaultIds}
          loading={loading}
        />
      </div>
    </div>
  );
}

ConsumptionDisplay.propTypes = {
  barnId: PropTypes.string,
  animalGroupDateRange: PropTypes.object,
  openFaultIds: PropTypes.arrayOf(PropTypes.string),
  visibleFeedLineIds: PropTypes.arrayOf(PropTypes.string),
};

export default ConsumptionDisplay;
