import { useAtom } from 'jotai';
import PropTypes from 'prop-types';

import LoadingSkeleton from '../../atoms/LoadingSkeleton';
import ToggleSwitch from '../../atoms/ToggleSwitch';

export default function FeatureFlagRow({ featureAtom }) {
  const [feature, setFeature] = useAtom(featureAtom);

  if (!feature) {
    return <LoadingSkeleton />;
  }
  return (
    <>
      <span className="FeatureFlagManager-label">{feature.name}</span>
      <ToggleSwitch
        className="FeatureFlageManager-toggle"
        isActive={feature.active}
        onToggle={(active) => {
          setFeature((oldValue) => ({ ...oldValue, active }));
        }}
      />
    </>
  );
}

FeatureFlagRow.propTypes = {
  featureAtom: PropTypes.object,
};
