import PropTypes from 'prop-types';
import { FaultCodes } from '@norimaconsulting/fault-codes';
import { TransportTypes } from '../../utils/enums';
import { RedXIcon } from '../../atoms/Icons';
import AlertTypeRow from '../../molecules/AlertTypeRow';
import './BarnAlertSection.scss';
import { censorTypes, useCensor } from '../../utils/hooks/useCensor/useCensor';

function BarnAlertSection({
  loading = false,
  id = '',
  name = '',
  columns = [],
  notificationSettings = {},
  onUpdate = () => {},
  onRemove = () => {},
}) {
  const { censor } = useCensor();
  const willBeMuted =
    notificationSettings[FaultCodes.EMPTY_PIPE][TransportTypes.SMS].initial === -1 &&
    notificationSettings[FaultCodes.EMPTY_PIPE][TransportTypes.Email].initial === -1 &&
    notificationSettings[FaultCodes.INACTIVE_AUGER][TransportTypes.SMS].initial === -1 &&
    notificationSettings[FaultCodes.INACTIVE_AUGER][TransportTypes.Email].initial === -1 &&
    notificationSettings[FaultCodes.SUDDEN_CONSUMPTION_DROP][TransportTypes.SMS].initial === -1 &&
    notificationSettings[FaultCodes.SUDDEN_CONSUMPTION_DROP][TransportTypes.Email].initial === -1 &&
    notificationSettings[FaultCodes.CONSUMPTION_TRENDING_DOWN][TransportTypes.SMS].initial === -1 &&
    notificationSettings[FaultCodes.CONSUMPTION_TRENDING_DOWN][TransportTypes.Email].initial === -1;

  const onUpdateSettings = (faultCode, transportType, newValues, isValid) => {
    onUpdate(id, faultCode, transportType, newValues, isValid);
  };

  const onRemoveButtonClick = () => {
    onRemove(id);
  };

  const renderColumns = () => {
    if (!columns.length) {
      return null;
    }

    return (
      <div className="BarnAlertSection-columnContainer">
        {columns.map((column) => (
          <div key={column} className="BarnAlertSection-transportTypeColumn">
            {column}
          </div>
        ))}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="BarnAlertSection-container">
        <div className="BarnAlertSection-header">
          <div className="BarnAlertSection-headerContent">
            <div className="BarnAlertSection-name BarnAlertSection-name--loading" />
          </div>
        </div>
        <AlertTypeRow loading={loading} />
        <AlertTypeRow loading={loading} />
        <AlertTypeRow loading={loading} />
        <AlertTypeRow loading={loading} />
      </div>
    );
  }

  return (
    <div className={`BarnAlertSection-container ${willBeMuted ? 'BarnAlertSection-container--warning' : ''}`}>
      <div className={`BarnAlertSection-header ${willBeMuted ? 'BarnAlertSection-header--warning' : ''}`}>
        {willBeMuted && (
          <div className="BarnAlertSection-warning">
            <img src="/icons/warning.png" className="BarnAlertSection-warningIcon" />
            When you save, all notifications for this barn will be muted.
          </div>
        )}
        <div className="BarnAlertSection-headerContent">
          <div className="BarnAlertSection-name">{censor(name, censorTypes.barn)}</div>
          {renderColumns()}
          <RedXIcon className="BarnAlertSection-remove" onClick={onRemoveButtonClick} />
        </div>
      </div>
      <AlertTypeRow
        name="Empty Pipe Alerts"
        faultCode={FaultCodes.EMPTY_PIPE}
        notificationSettings={notificationSettings?.[FaultCodes.EMPTY_PIPE]}
        onChange={onUpdateSettings}
      />
      <AlertTypeRow
        name="Inactive Auger Alerts"
        faultCode={FaultCodes.INACTIVE_AUGER}
        notificationSettings={notificationSettings?.[FaultCodes.INACTIVE_AUGER]}
        onChange={onUpdateSettings}
      />
      <AlertTypeRow
        name="Sudden Consumption Drop Alerts"
        faultCode={FaultCodes.SUDDEN_CONSUMPTION_DROP}
        notificationSettings={notificationSettings?.[FaultCodes.SUDDEN_CONSUMPTION_DROP]}
        onChange={onUpdateSettings}
      />
      <AlertTypeRow
        name="Consumption Trending Down Alerts"
        faultCode={FaultCodes.CONSUMPTION_TRENDING_DOWN}
        notificationSettings={notificationSettings?.[FaultCodes.CONSUMPTION_TRENDING_DOWN]}
        onChange={onUpdateSettings}
      />
    </div>
  );
}

BarnAlertSection.propTypes = {
  loading: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  columns: PropTypes.array,
  notificationSettings: PropTypes.object,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
};

export default BarnAlertSection;
