export const VariantEnum = {
  Vivid: 'vivid',
  Pastel: 'pastel',
  Text: 'text',
};

/**
 * Error is deprecated in favour of Danger
 */
export const ColorEnum = {
  Success: 'success',
  Warning: 'warning',
  Error: 'error', // deprecated?
  Danger: 'danger',
};
