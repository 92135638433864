export default function useFormattedBinSetName() {
  const formatBinSetName = (binNames) => {
    let binPrefix = '';
    let binList = '';

    if (!binNames) {
      return;
    }

    if (binNames.length === 1) {
      binPrefix = 'Bin';
      binList = [binNames];
    } else if (binNames.length === 2) {
      binPrefix = 'Bins';
      binList = binNames.join(' & ');
    } else {
      binPrefix = 'Bins';
      // Take all bins excluding the last one, join them with commas, then append the last one with &
      binList = `${binNames.slice(0, binNames.length - 1).join(', ')} & ${binNames[binNames.length - 1]}`;
    }

    return `${binPrefix} ${binList}`;
  };

  return {
    formatBinSetName,
  };
}
