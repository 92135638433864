import PropTypes from 'prop-types';

/**
 * Simple Wrapper to avoid duplicating code.
 *
 * @component
 */
function ConditionalWrapper({ condition = false, defaultWrapper, conditionalWrapper, children }) {
  return <>{condition ? conditionalWrapper(children) : defaultWrapper(children)}</>;
}

ConditionalWrapper.propTypes = {
  /**
   * A 'truthy' value to select between defaultWrapper or conditionalWrapper.
   */
  condition: PropTypes.any,
  /**
   * The default wrapper for 'falsy' conditions.
   */
  defaultWrapper: PropTypes.func,
  /**
   * The alternative wrapper for 'truthy' conditions.
   */
  conditionalWrapper: PropTypes.func,
  /**
   * The children components to be wrapped.
   */
  children: PropTypes.element,
};

export default ConditionalWrapper;
