// Basis for code from
// https://github.com/iamkun/dayjs/issues/1271#issuecomment-1686532292

const plugin = (_, dayjsClass, d) => {
  dayjsClass.prototype._add = dayjsClass.prototype.add;
  // eslint-disable-next-line no-param-reassign, func-names
  dayjsClass.prototype.add = function (...args) {
    const timezone = this.$x.$timezone;
    if (!timezone) {
      // continue with normal behaviour
      return this._add(...args);
    }

    const postAdd = this._add(...args);
    const formatted = postAdd.format('YYYY-MM-DD HH:mm:ss');
    const tzAgain = d.tz(formatted, timezone);
    return tzAgain;
  };

  // eslint-disable-next-line no-param-reassign, func-names
  dayjsClass.prototype.subtractInTz = function (...args) {
    const timezone = this.$x.$timezone;
    if (!timezone) {
      throw new Error('No timezone set');
    }
    return d.tz(
      d(this.toDate())
        .tz(timezone)
        .subtract(...args)
        .format('YYYY-MM-DD HH:mm:ss'),
      timezone,
    );
  };
};

export default plugin;
