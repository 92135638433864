import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchPopover from '../SearchPopover';
import './FilterPopoverButton.scss';

function FilterPopoverButton({
  text = '',
  placeholder = '',
  values = [],
  selectedValues = [],
  maxVisibleValues = 5,
  onUpdateSelectedValues = () => {},
  enableSearch = true,
}) {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const popoverRef = useRef(null);

  useEffect(() => {
    const onClickOutside = (e) => {
      if (popoverRef.current && !popoverRef.current.contains(e.target)) {
        setPopoverVisible(false);
      }
    };

    const onEscapePress = (e) => {
      if (e.keyCode === 27) {
        setPopoverVisible(false);
      }
    };

    document.addEventListener('mousedown', onClickOutside);
    document.addEventListener('keydown', onEscapePress);

    return () => {
      document.removeEventListener('mousedown', onClickOutside);
      document.removeEventListener('keydown', onEscapePress);
    };
  }, [popoverRef]);

  const onButtonClick = () => {
    setPopoverVisible(!popoverVisible);
  };

  const onClosePopover = () => {
    setPopoverVisible(false);
  };

  return (
    <div ref={popoverRef} className="FilterPopoverButton">
      <button
        className={`FilterPopoverButton-button ${
          selectedValues.length > 0 ? 'FilterPopoverButton-button--active' : ''
        }`}
        type="button"
        onClick={onButtonClick}
      >
        {text}
      </button>
      <SearchPopover
        visible={popoverVisible}
        placeholder={placeholder}
        values={values}
        selectedValues={selectedValues}
        onClosePopover={onClosePopover}
        onUpdateSelectedValues={onUpdateSelectedValues}
        enableSearch={enableSearch}
        maxVisibleValues={maxVisibleValues}
      />
    </div>
  );
}

FilterPopoverButton.propTypes = {
  text: PropTypes.string,
  placeholder: PropTypes.string,
  values: PropTypes.array,
  selectedValues: PropTypes.array,
  onUpdateSelectedValues: PropTypes.func,
  enableSearch: PropTypes.bool,
  maxVisibleValues: PropTypes.number,
};

export default FilterPopoverButton;
