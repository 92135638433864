import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { CircledCheckmarkIcon } from '../Icons';
import './CopyButton.scss';

function CopyButton({
  className = '',
  data = null,
  onSuccess = () => {},
  onError = () => {},
  children,
  iconClassName = '',
  iconStyle = {},
  hoverText = 'Copy to Clipboard',
}) {
  const [showSuccess, setShowSuccess] = useState(false);
  const { color: iconColor, roundness: iconRoundness } = {
    color: '#b3b2b3',
    roundness: '1.0',
    ...iconStyle,
  };

  useEffect(() => {
    let timerId = null;

    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
        // switch back to button after 3.5s
      }, 3500);
    }

    // cleanup timer
    return () => clearTimeout(timerId);
  }, [showSuccess]);

  const onCopy = async (e) => {
    e.preventDefault();

    try {
      await navigator.clipboard.writeText(data);
      setShowSuccess(true);
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (e) {
      console.error(`Error copying value ${data} to clipboard`, e);
      if (typeof onError === 'function') {
        onError();
      }
      return;
    }
  };

  return (
    <div className={`CopyButton ${className}`} title={hoverText} onClick={onCopy}>
      {children}

      {showSuccess ? (
        // If we've already copied show the checkmark + tooltip
        <div className="CopyButton-tooltip">
          <CircledCheckmarkIcon className="CopyButton-icon CopyButton-icon--success" />
        </div>
      ) : (
        // otherwise show the copy icon
        <svg
          className={`CopyButton-icon ${iconClassName}`}
          version="1.1"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/*
              Created this icon manually with inkscape then tweaked.
              Kept in this file since it's likely not going to be reused, move if so.
            */}
          <g id="g1">
            <rect
              fill={iconColor}
              fillOpacity={1}
              id="frontRect"
              width="12"
              height="12"
              x="0.041714959"
              y="4"
              ry={iconRoundness}
            />
            <rect
              fill="none"
              stroke={iconColor}
              strokeWidth="1.576"
              strokeDasharray={null}
              strokeOpacity={1}
              id="backRect"
              width="11.0"
              height="11.0"
              x="3.5"
              y="1.0"
              ry={iconRoundness}
            />
          </g>
        </svg>
      )}
    </div>
  );
}

CopyButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  data: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  iconClassName: PropTypes.string,
  iconStyle: PropTypes.shape({
    color: PropTypes.string,
    roundness: PropTypes.string, // But must be parsable as a Float?
  }),
  hoverText: PropTypes.string,
};

export default CopyButton;
