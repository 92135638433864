import PropTypes from 'prop-types';
import { useCensor } from '../../../utils/hooks/useCensor/useCensor';
import FeedFloTextInput from '../../../atoms/FeedFloTextInput';
import FeedFloDropDown from '../../../atoms/FeedFloDropDown';
import PhoneNumberInput from '../../../atoms/PhoneNumberInput';
import TimezoneController from './TimezoneController';
import './GeneralSettings.scss';

function GeneralSettingsView({
  loading = false,
  name = '',
  email = '',
  phoneNumber = '',
  isMetric = false,
  timezone = null,
  onChangeName = () => {},
  onChangePhoneNumber = () => {},
  onChangeIsMetric = () => {},
  onTimezoneChange = () => {},
}) {
  const { isCensorActive } = useCensor();

  return (
    <div className={`GeneralSettings ${loading ? 'GeneralSettings--loading' : null}`}>
      <FeedFloTextInput
        loading={loading}
        isCensored={isCensorActive}
        label="Name"
        text={name}
        onChange={onChangeName}
      />
      <TimezoneController
        loading={loading}
        timezone={timezone}
        onChange={(item) => {
          onTimezoneChange(item.name);
        }}
      />
      <FeedFloDropDown
        loading={loading}
        label="Metric / Imperial"
        defaultTitle="Select measurements"
        list={[
          { id: 0, name: 'Imperial', selected: !isMetric },
          { id: 1, name: 'Metric', selected: isMetric },
        ]}
        onChange={onChangeIsMetric}
      />
      <FeedFloTextInput
        loading={loading}
        isCensored={isCensorActive}
        id="email"
        label="Email"
        text={email}
        disabled={true}
      />
      <PhoneNumberInput loading={loading} label="Phone Number" value={phoneNumber} onChange={onChangePhoneNumber} />
    </div>
  );
}

GeneralSettingsView.propTypes = {
  loading: PropTypes.bool,
  name: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  isMetric: PropTypes.bool,
  timezone: PropTypes.string,
  onChangeName: PropTypes.func,
  onChangePhoneNumber: PropTypes.func,
  onChangeIsMetric: PropTypes.func,
  onTimezoneChange: PropTypes.func,
};

export default GeneralSettingsView;
