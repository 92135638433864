import PropTypes from 'prop-types';
import { DATE_MONTH_YEAR } from '../../../../utils/dates';
import { LeftChevronIcon, RightChevronIcon } from '../../../../atoms/Icons';
import Calendar, { GridDateBlock, DayOfWeek, numDaysInWeek } from '../../../../organisms/Calendar';
import EventIndicator from '../EventIndicator';
import './MiniCalendar.scss';

function MiniCalendar({
  loading = false,
  visibleDateSet = null,
  today = null,
  currentDate = null,
  showWeekends = true,
  dateToTripsMap = {},
  onDateClick = () => {},
  onShowWeekendsUpdate = () => {},
  onMiniCalendarCurrentDateUpdate = () => {},
}) {
  const onPreviousClick = () => {
    onMiniCalendarCurrentDateUpdate(currentDate.subtract(1, 'month'));
  };

  const onPreviousKeyDown = (e) => {
    if (e.key !== 'Enter' && e.code !== 'Space') {
      return;
    }

    e.preventDefault();
    onPreviousClick();
  };

  const onNextClick = () => {
    onMiniCalendarCurrentDateUpdate(currentDate.add(1, 'month'));
  };

  const onNextKeyDown = (e) => {
    if (e.key !== 'Enter' && e.code !== 'Space') {
      return;
    }

    e.preventDefault();
    onNextClick();
  };

  const onDateBlockClick = (date) => {
    // If the clicked on date is a weekend, assume the user wants to see weekends and toggle them back on
    if (!showWeekends && (date.day() === DayOfWeek.Sunday || date.day() === DayOfWeek.Saturday)) {
      onShowWeekendsUpdate(true);
    }

    onDateClick(date);
  };

  const renderDate = (date, i, dateList) => {
    return (
      <GridDateBlock
        key={`date:${date.format('YYYY-MM-DD')}`}
        className="MiniCalendar-dateBlock"
        isCompact={true}
        isLastColumn={i % numDaysInWeek === numDaysInWeek - 1}
        isLastRow={i >= dateList.length - numDaysInWeek}
        isDeemphasized={!date.isSame(currentDate, 'month')}
        isBodyHighlighted={visibleDateSet?.has(date.toString())}
        isDateHighlighted={date.isSame(today, 'day')}
        date={date}
        onClick={onDateBlockClick}
      >
        <EventIndicator loading={loading} visible={dateToTripsMap[date.format('YYYY-MM-DD')]?.length > 0} />
      </GridDateBlock>
    );
  };

  return (
    <div className="MiniCalendar">
      <div className="MiniCalendar-header">
        <LeftChevronIcon
          className="MiniCalendar-pagingButton"
          tabIndex={0}
          onKeyDown={onPreviousKeyDown}
          onClick={onPreviousClick}
        />
        <div className="MiniCalendar-heading">{currentDate?.format(DATE_MONTH_YEAR)}</div>
        <RightChevronIcon
          className="MiniCalendar-pagingButton"
          tabIndex={0}
          onKeyDown={onNextKeyDown}
          onClick={onNextClick}
        />
      </div>
      <Calendar className="MiniCalendar-calendar" isCompact={true} currentDate={currentDate} renderDate={renderDate} />
    </div>
  );
}

MiniCalendar.propTypes = {
  loading: PropTypes.bool,
  visibleDateSet: PropTypes.object,
  today: PropTypes.object,
  currentDate: PropTypes.object,
  showWeekends: PropTypes.bool,
  dateToTripsMap: PropTypes.object,
  onDateClick: PropTypes.func,
  onShowWeekendsUpdate: PropTypes.func,
  onMiniCalendarCurrentDateUpdate: PropTypes.func,
};

export default MiniCalendar;
