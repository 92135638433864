import PropTypes from 'prop-types';
import FeedFloNumInput from '../../atoms/FeedFloNumInput';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';

function UnitInputRow({ loading = false, label = '', value = '', unit = '', onChange = () => {} }) {
  if (loading) {
    return (
      <>
        <LoadingSkeleton className="BarnSettingsTab-loader BarnSettingsTab-loader--medium" />
        <LoadingSkeleton className="BarnSettingsTab-loader BarnSettingsTab-loader--small" />
        <LoadingSkeleton className="BarnSettingsTab-loader BarnSettingsTab-loader--small" />
      </>
    );
  }

  return (
    <>
      <span className="BarnSettingsTab-rowLabel">{label}</span>
      <FeedFloNumInput
        className="BarnSettingsTab-rowField"
        inputClassName="BarnSettingsTab-rowInput"
        type="number"
        value={value}
        onChange={onChange}
      />
      <span className="BarnSettingsTab-rowLabel">{unit}</span>
    </>
  );
}

UnitInputRow.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  unit: PropTypes.string,
  onChange: PropTypes.func,
};

export default UnitInputRow;
