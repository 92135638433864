import dayjs from 'dayjs';

export default function useFormattedHourStringFromSeconds() {
  const formatHourStringFromSeconds = (seconds, maxHours = 99) => {
    let hours = Math.round(dayjs.duration({ seconds }).asHours());

    if (hours === 0) {
      return 'Less than 1 hour';
    } else if (hours === 1) {
      return '1 hour';
    } else if (hours <= maxHours) {
      return `${hours} hours`;
    } else {
      return `${maxHours}+ hours`;
    }
  };

  return {
    formatHourStringFromSeconds,
  };
}
