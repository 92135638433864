import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import EventChartBottomRowCollapsed from './EventChartBottomRowCollapsed';
import EventChartBottomRowExpanded from './EventChartBottomRowExpanded';

export default function EventChartBottomRow({ id, hidden, events, ...props }) {
  const [expanded, setExpanded] = useState(false);

  const collapse = useCallback(() => setExpanded(false), []);
  const expand = useCallback(() => setExpanded(true), []);

  // Hidden or empty rows should never appear expanded.
  const canExpand = useMemo(() => !hidden && events.length > 0, [hidden, events]);

  return canExpand && expanded ? (
    <EventChartBottomRowExpanded {...props} events={events} id={id} collapse={collapse} />
  ) : (
    <EventChartBottomRowCollapsed {...props} events={events} hidden={hidden} expand={expand} />
  );
}

EventChartBottomRow.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  events: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired,
  xAxisNumTicks: PropTypes.array,
  xMax: PropTypes.number.isRequired,
  xScale: PropTypes.func.isRequired,
  reveal: PropTypes.func,
};
