import PropTypes from 'prop-types';
import './LoadingSkeleton.scss';

function LoadingSkeleton({ className = '', style = null }) {
  return <div className={`LoadingSkeleton ${className}`} style={style} />;
}

LoadingSkeleton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default LoadingSkeleton;
