import PropTypes from 'prop-types';
import LoadingSkeleton from '../LoadingSkeleton';
import './TextArea.scss';

function TextArea({
  loading = false,
  inputRef = null,
  className = '',
  labelClassName = '',
  inputClassName = '',
  descriptionClassName = '',
  errorClassName = '',
  tabIndex = 0,
  isValid = true,
  disabled = false,
  name = '',
  label = '',
  value = '',
  error,
  description = '',
  onChange = () => {},
  onKeyDown = () => {},
  onBlur = () => {},
}) {
  if (loading) {
    return (
      <div className={`TextArea ${className}`}>
        {label ? <LoadingSkeleton className="TextArea-labelText--loader" /> : null}
        <LoadingSkeleton className="TextArea-input--loader" />
        {description ? <LoadingSkeleton className="TextArea-description--loader" /> : null}
      </div>
    );
  }

  return (
    <div className={`TextArea ${disabled ? 'TextArea--disabled' : ''} ${className}`}>
      {label && (
        <label className={`TextArea-labelText ${!isValid ? 'TextArea-labelText--error' : ''} ${labelClassName}`}>
          {label}
        </label>
      )}

      <textarea
        ref={inputRef}
        className={`TextArea-input ${!isValid ? 'TextArea-input--error' : ''} ${inputClassName}`}
        tabIndex={tabIndex}
        disabled={disabled}
        name={name}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />
      {description ? <div className={`TextArea-description ${descriptionClassName}`}>{description}</div> : null}
      {!isValid && <div className={`TextArea-error ${errorClassName}`}>{error}</div>}
    </div>
  );
}

TextArea.propTypes = {
  loading: PropTypes.bool,
  inputRef: PropTypes.object,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  tabIndex: PropTypes.number,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  description: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  error: PropTypes.string,
};

export default TextArea;
