import React, { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import DeviceRow from './DeviceRow';
import FeedFloTextInput from '../../atoms/FeedFloTextInput/FeedFloTextInput';

const GET_FIRMWARE_BRANCHES = gql`
  query GetFirmwareBranchQuery {
    firmware_branch(where: { deleted_at: { _is_null: true } }) {
      name
      id
    }
  }
`;

const GET_DEVICES = gql`
  query DeviceQuery {
    device(order_by: { serial: asc }) {
      serial
      label
      id
      firmware_branch {
        id
        name
      }
    }
  }
`;

const SET_DEVICE_FW_BRANCH = gql`
  mutation SetDeviceFWBranch($deviceId: uuid!, $fwBranchId: uuid!) {
    update_device(where: { id: { _eq: $deviceId } }, _set: { firmware_branch_id: $fwBranchId }) {
      affected_rows
    }
  }
`;

export default function DeviceFirmwareController() {
  const [searchString, setSearchString] = useState('');

  const { data: deviceData } = useQuery(GET_DEVICES);

  let devices = deviceData?.device || null;
  devices = devices?.filter((device) => {
    return device.serial.toUpperCase().includes(searchString.toUpperCase());
  });

  const { data: fwData } = useQuery(GET_FIRMWARE_BRANCHES);
  const firmwareBranches = fwData?.firmware_branch || [];

  const [setDeviceFwBranch, { data: setDeviceFwData, error: setDeviceFwError }] = useMutation(SET_DEVICE_FW_BRANCH);

  const SuccessUpdated = () =>
    toast.success('Successfully Updated', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const ErrorOccurred = () =>
    toast.warn('Error Occurred', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    if (setDeviceFwData) SuccessUpdated();
    if (setDeviceFwError) ErrorOccurred();
  }, [setDeviceFwData, setDeviceFwError]);

  const onChangeBranch = (deviceID, firmwareBranchID) => {
    setDeviceFwBranch({ variables: { deviceId: deviceID, fwBranchId: firmwareBranchID } });
  };

  return (
    <div className="DeviceFirmwareController">
      <FeedFloTextInput
        label="Search Serial#"
        onChange={(x) => {
          setSearchString(x);
        }}
      />
      <div className="DeviceRowHolder">
        {devices?.map((device) => {
          return (
            <DeviceRow
              key={device.id}
              deviceID={device.id}
              label={device.label}
              serial={device.serial}
              firmwareBranches={firmwareBranches}
              firmwareBranchID={device.firmware_branch?.id}
              onChange={onChangeBranch}
            />
          );
        })}
      </div>
    </div>
  );
}
