/* eslint-disable no-restricted-syntax */

import dayjs from 'dayjs';
import { TIME_FORMAT_UTC_OFFSET_TIMEZONE, DATE_TIME_SLASH } from '../dates';

class CSVFeedEventStrategy {
  constructor(metric) {
    this.farmMap = {};
    this.lineMap = {};
    this.massRatio = metric ? 0.001 : 0.00220462;

    const massUnit = metric ? 'kg' : 'lbs';
    const timeZoneString = dayjs.tz().format(TIME_FORMAT_UTC_OFFSET_TIMEZONE);
    this.csvLines = [
      [
        'Farm Name',
        'Line Name',
        `Start ${timeZoneString} (${DATE_TIME_SLASH.toLowerCase()})`,
        `End ${timeZoneString} (${DATE_TIME_SLASH.toLowerCase()})`,
        'Start Epoch (s)',
        'End Epoch (s)',
        `Mass Monitored (${massUnit})\n`,
      ].join(', '),
    ];
  }

  setFarmObjects = (farms) => {
    farms.forEach((f) => {
      this.farmMap[f.id] = f.name;
      f.feed_lines.forEach((l) => {
        this.lineMap[l.id] = l.name;
      });
    });
  };

  processFeedFramePage = (farmId, lineId, dataArray) => {
    const farmName = this.farmMap[farmId];
    const lineName = this.lineMap[lineId];
    dataArray.forEach((d) => {
      this.csvLines.push(
        [
          `${farmName}`,
          `${lineName}`,
          `${dayjs.tz(1000 * d.s).format(DATE_TIME_SLASH)}`,
          `${dayjs.tz(1000 * d.e).format(DATE_TIME_SLASH)}`,
          `${d.s}`,
          `${d.e}`,
          `${d.mass * this.massRatio}\n`,
        ].join(', '),
      );
    });
  };

  createFile = () => {
    const toWrite = this.csvLines;
    this.csvLines = null;
    return new Blob(toWrite, { type: 'text/csv' });
  };
}
export default CSVFeedEventStrategy;
