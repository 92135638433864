import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery, useMutation } from '@apollo/client';
import Page from '../../atoms/Page';
import useUser from '../../utils/hooks/useUser';
import TabSection from '../../molecules/TabSection';
import UnsavedChangesBar from '../../molecules/UnsavedChangesBar';
import UnitInputRow from './UnitInputRow';
import './BarnSettingsTab.scss';
import FeedFloDropDown from '../../atoms/FeedFloDropDown';
import { CalibrationStyle, CalibrationStyleToString } from './enum';
import SingleFieldForm from '../../organisms/SingleFieldForm';

const GET_BARN_SETTINGS_GQL = gql`
  query BarnSettingsQuery($barnId: uuid!, $type: String, $version: Int) {
    barn_setting(where: { farm_id: { _eq: $barnId }, type: { _eq: $type }, version: { _eq: $version } }) {
      settings
    }
    barn: farm_by_pk(id: $barnId) {
      external_id
      organization {
        id
      }
      bins(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
        id
        name
        external_id
      }
      feed_lines(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
        id
        name
        reference_id
      }
    }
  }
`;

const UPSERT_BARN_SETTINGS_GQL = gql`
  mutation BarnSettingsMutation($objects: [barn_setting_insert_input!]!) {
    insert_barn_setting(
      objects: $objects
      on_conflict: { constraint: barn_setting_type_version_farm_id_unique, update_columns: [settings] }
    ) {
      affected_rows
    }
  }
`;

const DEFAULT_CALIBRATION_STYLE = CalibrationStyle.Exact;
const DEFAULT_CALIBRATION_SCHEDULES = 'none';
const CALIBRATION_SCHEDULES = [
  {
    key: 'none',
    name: 'None',
    bin_check_reminder_enabled: false,
    bin_check_reminder_in_seconds: 0,
    bin_check_overdue_in_seconds: 0,
  },
  {
    key: 'monthly',
    name: 'Monthly',
    bin_check_reminder_enabled: true,
    bin_check_reminder_in_seconds: 2_592_000,
    bin_check_overdue_in_seconds: 604_800,
  },
];

const CALIBRATION_STYLES = [
  {
    id: CalibrationStyle.ExactAndContinuous,
    name: CalibrationStyleToString[CalibrationStyle.ExactAndContinuous],
  },
  {
    id: CalibrationStyle.Continuous,
    name: CalibrationStyleToString[CalibrationStyle.Continuous],
  },
  {
    id: CalibrationStyle.Exact,
    name: CalibrationStyleToString[CalibrationStyle.Exact],
  },
];
const BIN_CHECK_BARN_SETTING = 'calibration_settings';

function BarnSettingsTab({ titleSegments = [], barnId = '' }) {
  const [initialCalibrationStyle, setInitialCalibrationStyle] = useState('');
  const [initialCalibrationSchedule, setInitialCalibrationSchedule] = useState('');
  const [calibrationStyle, setCalibrationStyle] = useState('');
  const [calibrationSchedule, setCalibrationSchedule] = useState('');
  const { user } = useUser();

  const { loading: settingsLoading, data: calibrationSettingsData } = useQuery(GET_BARN_SETTINGS_GQL, {
    variables: {
      barnId,
      type: BIN_CHECK_BARN_SETTING,
      version: 0,
    },
    notifyOnNetworkStatusChange: true,
  });
  const [saveSettings, { loading: savingSettings }] = useMutation(UPSERT_BARN_SETTINGS_GQL);
  const calibrationSettings = calibrationSettingsData?.barn_setting?.[0]?.settings || {};
  const pageTitleSegments = useMemo(() => ['Barn Settings', ...titleSegments], []);
  const unsavedChanges =
    calibrationStyle !== initialCalibrationStyle || calibrationSchedule !== initialCalibrationSchedule;
  const isValid = true;

  const { calibration_style, bin_check_reminder_enabled, bin_check_reminder_in_seconds } = calibrationSettings;

  useEffect(() => {
    let calibrationScheduleKey = DEFAULT_CALIBRATION_SCHEDULES;
    if (bin_check_reminder_enabled) {
      calibrationScheduleKey = CALIBRATION_SCHEDULES.find(
        (schedule) => schedule.bin_check_reminder_in_seconds === bin_check_reminder_in_seconds,
      )?.key;
    }
    setCalibrationStyle(`${calibration_style || DEFAULT_CALIBRATION_STYLE}`);
    setCalibrationSchedule(`${calibrationScheduleKey || DEFAULT_CALIBRATION_SCHEDULES}`);

    // deliverySize and shippingTime are converted to small units locally before being saved, but the UI displays them
    // as large units. A copy of the amounts in large units needs to be kept client side in order to check against the
    // entered values to determine when the "unsaved changes" bar should be displayed.
    setInitialCalibrationStyle(`${calibration_style || DEFAULT_CALIBRATION_STYLE}`);
    setInitialCalibrationSchedule(`${calibrationScheduleKey || DEFAULT_CALIBRATION_SCHEDULES}`);
  }, [calibration_style, bin_check_reminder_in_seconds]);

  const onSaveClick = () => {
    const { bin_check_reminder_enabled, bin_check_overdue_in_seconds, bin_check_reminder_in_seconds } =
      calibrationStyle != CalibrationStyle.Exact
        ? CALIBRATION_SCHEDULES.find((s) => s.key === calibrationSchedule)
        : {};

    saveSettings({
      variables: {
        objects: [
          {
            type: BIN_CHECK_BARN_SETTING,
            version: 0,
            farm_id: barnId,
            settings: {
              calibration_style: calibrationStyle,
              bin_check_reminder_enabled,
              bin_check_overdue_in_seconds,
              bin_check_reminder_in_seconds,
            },
          },
        ],
      },
      refetchQueries: ['BarnSettingsQuery'],
    });
  };

  const renderCalibrationSettings = () => {
    if (settingsLoading) {
      return (
        <>
          <UnitInputRow loading={true} />
          <UnitInputRow loading={true} />
        </>
      );
    }

    return (
      <>
        <FeedFloDropDown
          label="Calibration Style"
          list={CALIBRATION_STYLES.map((s) => {
            return { ...s, selected: s.id === calibrationStyle };
          })}
          onChange={(item) => {
            setCalibrationStyle(item.id);
          }}
        />
        {(calibrationStyle === CalibrationStyle.Continuous ||
          calibrationStyle === CalibrationStyle.ExactAndContinuous) && (
          <FeedFloDropDown
            label="Calibration Schedule"
            list={CALIBRATION_SCHEDULES.map((s) => {
              return { id: s.key, name: s.name, selected: s.key === calibrationSchedule };
            })}
            onChange={(item) => {
              setCalibrationSchedule(item.id);
            }}
          />
        )}
      </>
    );
  };

  return (
    <Page className="BarnSettingsTab-tabContent" titleSegments={pageTitleSegments}>
      <TabSection heading="Calibration Settings">
        {!settingsLoading && unsavedChanges && (
          <UnsavedChangesBar isValid={isValid} loading={settingsLoading || savingSettings} onSaveClick={onSaveClick} />
        )}
        <div className="BarnSettingsTab-barnSettings">{renderCalibrationSettings()}</div>
      </TabSection>
      {(user?.isStaff || user?.organizations?.includes(calibrationSettingsData?.barn?.organization?.id)) && (
        <TabSection heading="Reference Identifiers" className="BarnSettingsTab-tabsection">
          <section style={{ padding: '3.2rem' }}>
            <h4>Barn</h4>
            <SingleFieldForm
              fieldHasuraName="external_id"
              fieldTableName="farm"
              fieldLabel="Barn Reference ID"
              placeholder=" "
              rowId={barnId}
            />
          </section>
          <section style={{ padding: '3.2rem' }}>
            <h4>Bins</h4>
            <div
              style={
                {
                  // display: 'flex', flexDirection: 'column', flexWrap: 'wrap', maxHeight: '75%'
                }
              }
            >
              {calibrationSettingsData?.barn?.bins?.map((bin) => (
                <SingleFieldForm
                  key={bin.id}
                  fieldHasuraName="external_id"
                  fieldTableName="bin"
                  placeholder=" "
                  fieldLabel={`Reference ID for Bin ${bin.name}`}
                  rowId={bin.id}
                  style={{ marginBottom: '2rem' }}
                />
              ))}
            </div>
          </section>
          <section style={{ padding: '3.2rem' }}>
            <h4>Feed Lines</h4>
            {calibrationSettingsData?.barn?.feed_lines?.map((line) => (
              <SingleFieldForm
                key={line.id}
                fieldHasuraName="reference_id"
                fieldTableName="feed_line"
                placeholder={' '}
                fieldLabel={`Reference ID for Feed Line: ${line.name}`}
                rowId={line.id}
                style={{ marginBottom: '2rem' }}
              />
            ))}
          </section>
        </TabSection>
      )}
    </Page>
  );
}

BarnSettingsTab.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
  barnId: PropTypes.string,
};

export default BarnSettingsTab;
