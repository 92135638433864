import { useContext } from 'react';
import WebAppContext from '../../webAppContext';
import { convertGramsToLargeUnits } from '../../unitConversion';

function useKnownBinLevelSeries() {
  const { isMetric } = useContext(WebAppContext);

  const calculateKnownBinLevelSeries = (knownBinLevels = [], startFromZero = false) => {
    const mapOfBinLevels = knownBinLevels.reduce((map, curr) => {
      if (!map[curr.bin_id]) {
        map[curr.bin_id] = [];
      }

      map[curr.bin_id].push(curr);

      return map;
    }, {});

    const unsortedListOfDeltas = Object.values(mapOfBinLevels).reduce((arr, knowLevels) => {
      let currBinId = null;
      let lastValue = null;

      knowLevels.forEach((level) => {
        if (currBinId !== level.bin_id) {
          currBinId = level.bin_id;
          lastValue = null;
        }

        if (!lastValue && lastValue !== 0) {
          lastValue = level.level_in_grams;
          return;
        }

        arr.push({ delta: -(lastValue - level.level_in_grams), valid_at: level.valid_at });
        lastValue = level.level_in_grams;
      });

      return arr;
    }, []);

    const allSortedDeltas = unsortedListOfDeltas.sort((a, b) => a.valid_at - b.valid_at);
    const start = Object.values(mapOfBinLevels).reduce((sum, list) => sum + list[0]?.level_in_grams, 0);
    const series = { x: [], y: [] };

    let currVal = startFromZero ? 0 : start;
    allSortedDeltas.forEach((x) => {
      currVal += x.delta;
      series.x.push(new Date(x.valid_at * 1000));
      series.y.push(convertGramsToLargeUnits(isMetric, currVal, 4));
    });

    return series;
  };

  return {
    calculateKnownBinLevelSeries,
  };
}

export default useKnownBinLevelSeries;
