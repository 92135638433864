/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import FeedFloButton from '../../atoms/FeedFloButton';
import './FeedFloTable.scss';

const MAX_ROWS_TO_DISPLAY = 12;

/***
 * ? DEPRECATED / NEEDS REVIEW ?
 */
function FeedFloTable({
  className = '',
  headers = [],
  rows = [],
  children = [],
  pageNum = 0,
  loading = false,
  emptyImage = null,
  onClickNext = () => {},
  onClickPrevious = () => {},
  maxRows = MAX_ROWS_TO_DISPLAY,
  itemTotalCount = 0,
}) {
  const prevButtonEnable = pageNum > 0;
  const nextButtonEnable = (pageNum + 1) * maxRows < itemTotalCount;
  const empty = rows.length === 0 && children.length === 0;

  const th = headers.map((h, i) => {
    return (
      <div key={`${i}${h.t}`} className={`cell ${h.s}`}>
        {h.t}
      </div>
    );
  });

  let tr = null;
  if (loading && !children.length) {
    tr = Array(maxRows)
      .fill(0)
      .map((r, i) => {
        const td = headers.map((header, j) => {
          return (
            <div key={`${i}${j}LoadingCell`} className={`cell ${header.s}`}>
              {header.s !== 's' && <div className="skeleton-box" />}
            </div>
          );
        });
        return (
          <div key={`${i}TableRow`} className="row">
            {td}
          </div>
        );
      });
  } else if (rows.length > 0) {
    tr = rows.map((r, i) => {
      const td = r.map((cell, j) => {
        return (
          <div key={`${i}${j}tablecell`} className={`cell ${headers[j].s} ${headers?.[j]?.className || ''}`}>
            <span>{cell}</span>
          </div>
        );
      });
      return (
        <div key={`${i}tableRow`} className="row">
          {td}
        </div>
      );
    });
  } else if (children.length === 0) {
    tr = (
      <div className="emptyImageHolder noselect">
        <div>
          <img src={emptyImage} alt="This table is empty" />
        </div>
      </div>
    );
  }

  return (
    <div className={`FeedFloTable ${className}`}>
      {th.length ? <div className="header noselect">{th}</div> : null}

      <div>
        {tr ? tr : children}
        <div className="footer">
          <div className="results">
            <b>{!loading ? itemTotalCount : '...'}</b> results
          </div>

          <div className="page-number noselect">
            {`Page ${pageNum + 1} of ${!loading ? Math.max(1, Math.ceil(itemTotalCount / maxRows)) : '...'}`}
          </div>

          <div className="FeedFloTable-footerButtonsContainer">
            <div className="previous">
              <FeedFloButton onClick={prevButtonEnable ? onClickPrevious : null} disabled={empty || !prevButtonEnable}>
                Previous
              </FeedFloButton>
            </div>
            <FeedFloButton onClick={nextButtonEnable ? onClickNext : null} disabled={empty || !nextButtonEnable}>
              Next
            </FeedFloButton>
          </div>
        </div>
      </div>
    </div>
  );
}

FeedFloTable.propTypes = {
  className: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.array,
  children: PropTypes.array,
  pageNum: PropTypes.number,
  loading: PropTypes.bool,
  emptyImage: PropTypes.string,
  onClickNext: PropTypes.func,
  onClickPrevious: PropTypes.func,
  maxRows: PropTypes.number,
  itemTotalCount: PropTypes.number,
};

export default FeedFloTable;
