import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { gql, useQuery } from '@apollo/client';
import useFaultCodeFilter from '../../utils/useFaultCodeFilter';
import Page from '../../atoms/Page';
import './MapPage.scss';

const BARN_GQL = gql`
  query BarnDataQuery($codeFilters: [fault_bool_exp!]!) {
    farm {
      lat: latitude
      lon: longitude
      id
      name
      feed_lines {
        id
        device_assignments(
          where: { status: { _eq: "active" }, deleted_at: { _is_null: true }, ended_at: { _is_null: true } }
        ) {
          device {
            faults(where: { ended_at: { _is_null: true }, _or: $codeFilters }) {
              id
            }
          }
        }
      }
    }
  }
`;

const { VITE_GOOGLE_CREDS_API } = import.meta.env;
const containerStyle = {
  width: '100%',
  height: '100%',
};

const center = {
  lat: 0,
  lng: 0,
};

function MapPage({ titleSegments = [] }) {
  // Use Utility
  const navigate = useNavigate();
  const codeFilters = useFaultCodeFilter();
  const { isLoaded: gMapsIsLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: VITE_GOOGLE_CREDS_API,
  });
  const { loading, error, data } = useQuery(BARN_GQL, {
    variables: {
      codeFilters,
    },
  });
  const pageTitleSegments = useMemo(() => ['Barn Map', ...titleSegments], []);
  const barnData = data?.farm || [];
  const [map, setMap] = useState(null);

  // Map Config
  const mapOptions = {
    panControl: true,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    overviewMapControl: false,
    rotateControl: false,
    mapId: 'f0b8a703a088daee',
  };

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      barnData?.forEach((b) => {
        if (typeof b.lat === 'number' && typeof b.lon === 'number') bounds.extend({ lat: b.lat, lng: b.lon });
      });
      map?.fitBounds(bounds);
    }
  }, [barnData, map]);

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  });

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  // Loading Guard
  if (loading || !gMapsIsLoaded) {
    return <></>;
  }
  if (error) return <span>{JSON.stringify(error, null, 2)}</span>;
  return (
    <Page className="MapPage" titleSegments={pageTitleSegments}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        options={mapOptions}
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {barnData.map((b) => {
          const hasFault = b.feed_lines.reduce((hasFault, curr) => {
            const faults = curr?.device_assignments[0]?.device?.faults;
            return hasFault || faults?.length > 0;
          }, false);
          const icon = {
            url: `/icons/map/${!hasFault ? 'onlineBarnMapIcon.png' : 'offlineBarnMapIcon.png'}`,
            scaledSize: new window.google.maps.Size(30, 30),
          };

          return (
            typeof b.lat === 'number' &&
            typeof b.lon === 'number' && (
              <Marker
                key={b.id}
                position={{ lat: b?.lat, lng: b?.lon }}
                icon={icon}
                onClick={() => {
                  navigate(`/b/${b.id}/consumption`);
                }}
              />
            )
          );
        })}
      </GoogleMap>
    </Page>
  );
}

MapPage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
};

export default MapPage;
