import PropTypes from 'prop-types';
import './AlertDashboardBadge.scss';

function AlertDashboardBadge({ type = '' }) {
  const getClassNameAndText = () => {
    switch (type) {
      case 'empty_pipe':
        return ['emptyPipe', 'Empty Pipe'];
      case 'inactive_auger':
        return ['inactiveAuger', 'Inactive Auger'];
      case 'sudden_drop':
        return ['suddenDrop', 'Sudden Drop'];
      case 'trending_down':
        return ['trendingDown', 'Trending Down'];
      case "FeedFlo's Mistake":
        return ['feedflosMistake', type];
      case 'Event Not Ended by Device':
        return ['eventNotEnded', 'Event Not Ended'];
      case 'False Sensor Readings':
        return ['falseSensors', type];
      case 'Feed Bridging':
        return ['feedBridging', type];
      case 'Shutter was Closed':
        return ['shutterClosed', type];
      case 'unidentified':
        return ['unidentified', 'Unidentified'];
      default:
        return ['default', type];
    }
  };

  const [className, text] = getClassNameAndText();

  return <div className={`AlertDashboardBadge AlertDashboardBadge--${className}`}>{text}</div>;
}

AlertDashboardBadge.propTypes = {
  type: PropTypes.string,
};

export default AlertDashboardBadge;
