import PropTypes from 'prop-types';

import Button from '../Button';

import './SectionalButton.scss';

/**
 * A sectional button component.
 *
 * Each section is an object with the following keys:
 * * content: string or JSX for display.
 * * disabled: boolean to disable click interaction.
 * * active: boolean to highlight sections.
 * * onClick: click callback handler.
 * * className: CSS class override for the section.
 * * activeClassName: Additional CSS override for active sections.
 *
 * @component
 */
function SectionalButton({ sections = [], className = '', style = null }) {
  return (
    <div className={`SectionalButton ${className}`}>
      {sections.map(
        (
          { content = '', disabled = false, active = false, onClick = null, className = '', activeClassName = '' },
          index,
        ) => (
          <Button
            className={`SectionalButton-section
              ${
                active
                  ? `SectionalButton-section--active ${className} ${activeClassName}`
                  : `SectionalButton-section--inactive ${className}`
              }`}
            style={style}
            content={content}
            disabled={disabled}
            onClick={onClick}
            key={index}
          />
        ),
      )}
    </div>
  );
}

SectionalButton.propTypes = {
  /**
   * List describing each section.
   */
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * String or JSX content.
       */
      content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      /**
       * Disables click interaction.
       */
      disabled: PropTypes.bool,
      /**
       * Active sections are highlighted.
       */
      active: PropTypes.bool,
      /**
       * Callback to handle onClick.
       */
      onClick: PropTypes.func,
      /**
       * CSS class override.
       */
      className: PropTypes.string,
      /**
       * CSS class override for active sections.
       */
      activeClassName: PropTypes.string,
    }),
  ),
  /**
   * CSS class override.
   */
  className: PropTypes.string,
  /**
   * Style prop override.
   */
  style: PropTypes.object,
};

export default SectionalButton;
