import PropTypes from 'prop-types';

export function DoubleBinIcon({ className = '', fillPercent = 0, fillColour = '', onClick = () => null }) {
  // The pixel conversion is between pixel height 101 for 0% fill and pixel height 14 for 100% fill
  const fillY = 101 - (fillPercent * 87) / 100;
  const fillHeight = 102 - fillY;
  return (
    <svg
      className={`${className}`}
      onClick={onClick}
      width="118"
      height="102"
      viewBox="0 0 118 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="double-bin-fill">
          <rect x="55.3354" y="41.5121" width="6.97688" height="5.17353" />
          <path d="M55.3333 72.2395L41.7986 101.07H14.6319L1 72.2395V13.2071H55.3333V72.2395Z" />
          <path d="M116.692 72.2395L103.157 101.07H75.9908L62.3589 72.2395V13.2071H116.692V72.2395Z" />
          <path d="M35.8938 3.59686H20.4395L1 13.1962H55.3333L35.8938 3.59686Z" />
          <path d="M97.2527 3.59686H81.7983L62.3589 13.1962H116.692L97.2527 3.59686Z" />
          <path d="M34.2949 1H22.0372C21.1408 1 20.4172 1.72346 20.4172 2.61969V3.6347H35.9148V2.61969C35.9148 1.72346 35.1912 1 34.2949 1Z" />
          <path d="M95.6537 1H83.3961C82.4997 1 81.7761 1.72346 81.7761 2.61969V3.6347H97.2737V2.61969C97.2737 1.72346 96.5501 1 95.6537 1Z" />
        </clipPath>
      </defs>
      <rect
        // Bin set connector fill background.
        x="55.3354"
        y="41.5121"
        width="6.97688"
        height="5.17353"
        fill="#CFCFCF"
      />
      <path
        // Left bin body fill background.
        d="M55.3333 72.2395L41.7986 101.07H14.6319L1 72.2395V13.2071H55.3333V72.2395Z"
        fill="#E6E6E6"
      />
      <path
        // Right bin body fill background.
        d="M116.692 72.2395L103.157 101.07H75.9908L62.3589 72.2395V13.2071H116.692V72.2395Z"
        fill="#E6E6E6"
      />
      <rect
        // Feed value rectangle.
        x="0"
        y={fillY}
        width="100%"
        height={fillHeight}
        stroke="initial"
        fill={fillColour}
        clipPath="url(#double-bin-fill)"
      />
      <rect
        // Bin set connector stroke outline.
        x="55.3354"
        y="41.5121"
        width="6.97688"
        height="5.17353"
        stroke="#999999"
      />
      <path
        // Left bin body stroke outline.
        d="M55.3333 72.2395L41.7986 101.07H14.6319L1 72.2395V13.2071H55.3333V72.2395Z"
        stroke="#999999"
        strokeMiterlimit="10"
      />
      <path
        // Right bin body stroke outline.
        d="M116.692 72.2395L103.157 101.07H75.9908L62.3589 72.2395V13.2071H116.692V72.2395Z"
        stroke="#999999"
        strokeMiterlimit="10"
      />
      <path
        // Left bin top.
        d="M35.8938 3.59686H20.4395L1 13.1962H55.3333L35.8938 3.59686Z"
        fill="#F1F1F1"
        stroke="#999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        // Right bin top.
        d="M97.2527 3.59686H81.7983L62.3589 13.1962H116.692L97.2527 3.59686Z"
        fill="#F1F1F1"
        stroke="#999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        // Left bin cap.
        d="M34.2949 1H22.0372C21.1408 1 20.4172 1.72346 20.4172 2.61969V3.6347H35.9148V2.61969C35.9148 1.72346 35.1912 1 34.2949 1Z"
        fill="#F1F1F1"
        stroke="#999999"
        strokeMiterlimit="10"
      />
      <path
        // Right bin cap.
        d="M95.6537 1H83.3961C82.4997 1 81.7761 1.72346 81.7761 2.61969V3.6347H97.2737V2.61969C97.2737 1.72346 96.5501 1 95.6537 1Z"
        fill="#F1F1F1"
        stroke="#999999"
        strokeMiterlimit="10"
      />
      <path d="M63.7844 55.0815H91.2211" stroke="#CFCFCF" strokeMiterlimit="10" />
      <path d="M63.7844 59.4169H91.2211" stroke="#CFCFCF" strokeMiterlimit="10" />
      <path d="M63.7844 63.5364H91.2211" stroke="#CFCFCF" strokeMiterlimit="10" />
      <path d="M63.7844 67.6558H96.6911" stroke="#CFCFCF" strokeMiterlimit="10" />
      <path d="M62.8125 72.4338L116.25 72.4338" stroke="#CFCFCF" strokeMiterlimit="10" />
      <path d="M2.42554 55.0815H29.8622" stroke="#CFCFCF" strokeMiterlimit="10" />
      <path d="M2.42554 59.4169H29.8622" stroke="#CFCFCF" strokeMiterlimit="10" />
      <path d="M2.42554 63.5364H29.8622" stroke="#CFCFCF" strokeMiterlimit="10" />
      <path d="M2.42554 67.6558H35.3322" stroke="#CFCFCF" strokeMiterlimit="10" />
      <path d="M1.45374 72.4338L54.8911 72.4338" stroke="#CFCFCF" strokeMiterlimit="10" />
    </svg>
  );
}

DoubleBinIcon.propTypes = {
  className: PropTypes.string,
  fillPercent: PropTypes.number,
  fillColour: PropTypes.string,
  onClick: PropTypes.func,
};
