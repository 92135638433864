import PropTypes from 'prop-types';
import FeedFloLabChart from '../../molecules/FeedFloLabsChart';
import useBinScaleSeries from '../../utils/hooks/useBinScaleSeries';

export default function BinScaleChart({ binIds, to, from, toFocus, fromFocus, autoScale = false }) {
  const binScaleSeries = useBinScaleSeries({ binIds, to, from, autoScale });

  if (binScaleSeries?.loading) return <span>Loading...</span>;
  if (binScaleSeries?.error) return <span>Error {JSON.stringify(binScaleSeries?.error)}</span>;

  if (toFocus & fromFocus) {
    binScaleSeries.layout = {
      ...binScaleSeries?.layout,
      shapes: [
        {
          type: 'rect',
          xref: 'x',
          yref: 'paper',
          x0: toFocus * 1000,
          y0: 0,
          x1: fromFocus * 1000,
          y1: 1,
          fillcolor: '#d3d3d3',
          opacity: 0.2,
          line: {
            width: 0,
          },
        },
      ],
    };
  }
  return <FeedFloLabChart data={binScaleSeries?.data} layout={binScaleSeries?.layout} />;
}

BinScaleChart.propTypes = {
  binIds: PropTypes.array,
  to: PropTypes.number,
  from: PropTypes.number,
  toFocus: PropTypes.number,
  fromFocus: PropTypes.number,
  autoScale: PropTypes.bool,
};
