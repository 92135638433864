export const CalibrationStyle = {
  Exact: 'exact',
  Continuous: 'continuous',
  ExactAndContinuous: 'exact+continuous',
};

export const CalibrationStyleToString = {
  [CalibrationStyle.Exact]: 'Exact Calibration',
  [CalibrationStyle.Continuous]: 'Continuous Calibration',
  [CalibrationStyle.ExactAndContinuous]: 'Exact & Continuous Calibration',
};
