import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import FeedFloDropDown from '../../../atoms/FeedFloDropDown';

function TimezoneController({ loading = false, timezone = null, disabled = false, onChange = () => {} }) {
  const timezoneDropDownList = useMemo(() => {
    return Intl.supportedValuesOf('timeZone').map((tz) => {
      return {
        id: tz,
        name: tz,
        selected: tz === timezone,
      };
    });
  }, [timezone]);

  function handleTimezoneChange(id, item) {
    onChange(id, item);
  }

  return (
    <FeedFloDropDown
      loading={loading}
      id="timezone"
      label="Timezone"
      list={timezoneDropDownList}
      defaultSelected="Select a timezone"
      onChange={(id, item) => handleTimezoneChange(id, item)}
      disabled={disabled}
    />
  );
}

TimezoneController.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  timezone: PropTypes.string,
};

export default TimezoneController;
