import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import differenceBy from 'lodash/differenceBy';
import { FaultCodes } from '@norimaconsulting/fault-codes';
import { TransportTypes } from '../../../utils/enums';
import LoadingSkeleton from '../../../atoms/LoadingSkeleton';
import AlertTypeRow from '../../../molecules/AlertTypeRow';
import ListRow from '../../../molecules/ListRow';
import SearchBar from '../../../molecules/SearchBar';
import BarnAlertSection from '../../../organisms/BarnAlertSection';
import NotificationSettingsSection from './NotificationSettingsSection';
import { censorTypes, useCensor } from '../../../utils/hooks/useCensor/useCensor';
import './NotificationSettings.scss';

function NotificationSettingsView({
  loading = false,
  organizationID = null,
  defaultNotificationSettings = {},
  allBarns = [],
  customizedBarns = [],
  mutedBarns = [],
  onUpdateDefaultNotificationSettings = () => {},
  onAddCustomizedBarn = () => {},
  onAddMutedBarn = () => {},
  onUpdateCustomizedBarn = () => {},
  onRemoveCustomizedBarn = () => {},
  onRemoveMutedBarn = () => {},
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { censor, isCensorActive } = useCensor();
  const headerColumns = useMemo(() => Object.keys(TransportTypes), []);
  const searchableBarns = useMemo(() => {
    // Remove selected barns from the list of all barns, then format the list as needed by the SearchBar autocomplete
    const filteredBarns = differenceBy(allBarns, [...customizedBarns, ...mutedBarns], 'id');
    return filteredBarns.map((barn) => ({ id: barn.id, label: censor(barn.name, censorTypes.barn) }));
  }, [allBarns, customizedBarns, mutedBarns, isCensorActive]);

  const renderOrganizationIDNotice = () => {
    if (loading || organizationID) {
      return null;
    }

    return (
      <div className="NotificationSettings-notice">
        Your account is not associated with an Organization. Default barn settings are disabled because they will not
        function without an Organization.
      </div>
    );
  };

  const renderCustomizedBarns = () => {
    if (!customizedBarns.length) {
      return (
        <div className="NotificationSettings-emptyListMessage">You currently have no barns with specific settings.</div>
      );
    }

    return customizedBarns.map((barn) => {
      return (
        <BarnAlertSection
          key={barn.id}
          id={barn.id}
          name={barn.name}
          columns={headerColumns}
          notificationSettings={{
            [FaultCodes.EMPTY_PIPE]: barn?.[FaultCodes.EMPTY_PIPE],
            [FaultCodes.INACTIVE_AUGER]: barn?.[FaultCodes.INACTIVE_AUGER],
            [FaultCodes.SUDDEN_CONSUMPTION_DROP]: barn?.[FaultCodes.SUDDEN_CONSUMPTION_DROP],
            [FaultCodes.CONSUMPTION_TRENDING_DOWN]: barn?.[FaultCodes.CONSUMPTION_TRENDING_DOWN],
          }}
          onUpdate={onUpdateCustomizedBarn}
          onRemove={onRemoveCustomizedBarn}
        />
      );
    });
  };

  const renderMutedBarns = () => {
    if (!mutedBarns.length) {
      return <div className="NotificationSettings-emptyListMessage">You currently have no barns muted.</div>;
    }

    return mutedBarns.map((barn) => {
      return (
        <ListRow key={barn.id} id={barn.id} name={censor(barn.name, censorTypes.barn)} onRemove={onRemoveMutedBarn} />
      );
    });
  };

  if (loading) {
    return (
      <div className="NotificationSettings-content">
        <NotificationSettingsSection loading={loading}>
          <div className="NotificationSettings-columnRow">
            <div className="NotificationSettings-sectionDescription">
              <LoadingSkeleton className="NotificationSettings-sectionDescription--loader" />
            </div>
          </div>
          <AlertTypeRow loading={loading} />
          <AlertTypeRow loading={loading} />
          <AlertTypeRow loading={loading} />
          <AlertTypeRow loading={loading} />
        </NotificationSettingsSection>
        <NotificationSettingsSection loading={loading} />
      </div>
    );
  }

  return (
    <div className="NotificationSettings-content">
      {renderOrganizationIDNotice()}
      <NotificationSettingsSection
        className={!loading && organizationID ? '' : 'NotificationSettings-section--disabled'}
        title="Default Alert Settings"
      >
        <div className="NotificationSettings-columnRow">
          <div className="NotificationSettings-sectionDescription">
            These settings will apply to all of your barns. To customize your notifications for specific barns, see
            Advanced Alert Settings.
          </div>
          <div className="NotificationSettings-columnContainer">
            {headerColumns.map((column) => {
              return (
                <div key={column} className="NotificationSettings-transportTypeColumn">
                  {column}
                </div>
              );
            })}
          </div>
        </div>
        <AlertTypeRow
          name="Empty Pipe Alerts"
          faultCode={FaultCodes.EMPTY_PIPE}
          notificationSettings={defaultNotificationSettings?.[FaultCodes.EMPTY_PIPE]}
          onChange={onUpdateDefaultNotificationSettings}
        />
        <AlertTypeRow
          name="Inactive Auger Alerts"
          faultCode={FaultCodes.INACTIVE_AUGER}
          notificationSettings={defaultNotificationSettings?.[FaultCodes.INACTIVE_AUGER]}
          onChange={onUpdateDefaultNotificationSettings}
        />
        <AlertTypeRow
          name="Sudden Consumption Drop Alerts"
          faultCode={FaultCodes.SUDDEN_CONSUMPTION_DROP}
          notificationSettings={defaultNotificationSettings?.[FaultCodes.SUDDEN_CONSUMPTION_DROP]}
          onChange={onUpdateDefaultNotificationSettings}
        />
        <AlertTypeRow
          name="Consumption Trending Down Alerts"
          faultCode={FaultCodes.CONSUMPTION_TRENDING_DOWN}
          notificationSettings={defaultNotificationSettings?.[FaultCodes.CONSUMPTION_TRENDING_DOWN]}
          onChange={onUpdateDefaultNotificationSettings}
        />
      </NotificationSettingsSection>
      <NotificationSettingsSection
        title="Advanced Alert Settings"
        isCollapsible={true}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <div className="NotificationSettings-sectionContent">
          <h6 className="NotificationSettings-sectionSubheading">Per Barn Settings</h6>
          <div className="NotificationSettings-sectionDescription">
            You can set custom notification settings for barns here. Settings selected here will override default alert
            settings.
          </div>
          <div className="NotificationSettings-searchBar">
            <SearchBar
              placeholder="Find barns"
              items={searchableBarns}
              clearOnSelection
              onSubmit={onAddCustomizedBarn}
            />
          </div>
          {renderCustomizedBarns()}
        </div>
        <div className="NotificationSettings-sectionContent">
          <h6 className="NotificationSettings-sectionSubheading">Muted Barns</h6>
          <div className="NotificationSettings-sectionDescription">
            You will not receive any notifications for barns listed here.
          </div>
          <div className="NotificationSettings-searchBar">
            <SearchBar placeholder="Find barns" items={searchableBarns} clearOnSelection onSubmit={onAddMutedBarn} />
          </div>
          {renderMutedBarns()}
        </div>
      </NotificationSettingsSection>
    </div>
  );
}

NotificationSettingsView.propTypes = {
  loading: PropTypes.bool,
  organizationID: PropTypes.string,
  defaultNotificationSettings: PropTypes.object,
  allBarns: PropTypes.array,
  customizedBarns: PropTypes.array,
  mutedBarns: PropTypes.array,
  onUpdateDefaultNotificationSettings: PropTypes.func,
  onAddCustomizedBarn: PropTypes.func,
  onAddMutedBarn: PropTypes.func,
  onUpdateCustomizedBarn: PropTypes.func,
  onRemoveCustomizedBarn: PropTypes.func,
  onRemoveMutedBarn: PropTypes.func,
};

export default NotificationSettingsView;
