const KG_TO_G = 1_000;
const METRIC_TON_TO_G = 1000 * KG_TO_G; // 1000 kg
const G_TO_KG = 1 / KG_TO_G;
const G_TO_METRIC_TON = 1 / METRIC_TON_TO_G;
const LB_TO_G = 0.453592 * 1_000;
const G_TO_LB = 1 / LB_TO_G;
const SHORT_TON_TO_LB = 2_000;
const SHORT_TON_TO_G = SHORT_TON_TO_LB * LB_TO_G;

const SECONDS_TO_HOURS = 3600;
const HOURS_TO_SECONDS = 3600;

export function convertGramsToSmallUnits(metric, grams, decimalPlaces = 2) {
  if (typeof metric !== 'boolean') throw new Error("Param 'metric' needs to be a boolean");
  const makeSmall = metric ? grams * G_TO_KG : grams * G_TO_LB;
  return Number(makeSmall.toFixed(decimalPlaces));
}

export function convertGramsToLargeUnits(metric, grams, decimalPlaces = 2) {
  if (typeof metric !== 'boolean') throw new Error("Param 'metric' needs to be a boolean");
  const makeBig = metric ? grams * G_TO_METRIC_TON : grams / SHORT_TON_TO_G;
  return Number(makeBig.toFixed(decimalPlaces));
}

export function convertSmallUnitsToGrams(metric, smallUnits, decimalPlaces = 2) {
  if (typeof metric !== 'boolean') throw new Error("Param 'metric' needs to be a boolean");
  const grams = metric ? smallUnits * KG_TO_G : smallUnits * LB_TO_G;
  return Number(grams.toFixed(decimalPlaces));
}

export function convertLargeUnitsToGrams(metric, largeUnits, decimalPlaces = 2) {
  if (typeof metric !== 'boolean') throw new Error("Param 'metric' needs to be a boolean");
  const grams = metric ? largeUnits * METRIC_TON_TO_G : largeUnits * SHORT_TON_TO_G;
  return Number(grams.toFixed(decimalPlaces));
}

export function weightSmallUnitLabel(metric) {
  if (typeof metric !== 'boolean') throw new Error("Param 'metric' needs to be a boolean");
  return metric ? 'kg' : 'lbs';
}

export function weightLargeUnitLabel(metric) {
  if (typeof metric !== 'boolean') throw new Error("Param 'metric' needs to be a boolean");
  return metric ? 'Tonne' : 'Ton';
}

export function secondsToHours(seconds) {
  return Math.round(seconds / SECONDS_TO_HOURS);
}

export function hoursToSeconds(hours) {
  return Math.round(hours * HOURS_TO_SECONDS);
}

export default {
  convertGramsToSmallUnits,
  convertGramsToLargeUnits,
  convertLargeUnitsToGrams,
  weightSmallUnitLabel,
  weightLargeUnitLabel,
  secondsToHours,
  hoursToSeconds,
};
