import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import './Dialog.scss';

/**
 * A basic dialog component.
 *
 * className: CSS class name applied to the dialog. Optional.
 *
 * children: JSX children displayed inside the dialog.
 *
 * ref: Ref applied to the inner dialog. Used to show and hide the modal via HTMLDialogElement methods.
 *
 * @param {object} props
 * @param {string=} props.className
 * @param {React.ReactElement=} props.children
 *
 * @param {React.Ref} ref
 */
const Dialog = forwardRef(function Dialog({ className = '', children }, ref) {
  return (
    <dialog className={`Dialog ${className}`} ref={ref}>
      {children}
    </dialog>
  );
});

Dialog.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,
};

export default Dialog;
