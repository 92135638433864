import React, { useEffect, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { DATE_FORMAT_MONTH_DAY, DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE, secondsToDuration } from '../../utils/dates';
import { toast } from 'react-toastify';
import Page from '../../atoms/Page';
import FeedFloDatePicker from '../../molecules/FeedFloDatePicker';
import FeedFloTable from '../../organisms/FeedFloTable';
import FeedFloTooltip from '../../atoms/FeedFloToolTip';
import StatusBadge from '../../atoms/StatusBadge';
import FeedFloButton from '../../atoms/FeedFloButton';
import { ExportIcon, FilterIcon, CalendarIcon, HomeIcon } from '../../atoms/Icons';
import Heading from '../../atoms/Heading';
import EventChart from '../../molecules/EventChart';
import { addComma } from '../../utils';
import { createCumulativePlot } from '../../utils/chartHelpers';
import WebAppContext from '../../utils/webAppContext';
import { convertGramsToSmallUnits, weightSmallUnitLabel } from '../../utils/unitConversion';
import { useFeedFrameFilter, useAnalysisFilter } from '../../utils/useFeedFrameFilter';
import useDefaultDateRange from '../../utils/hooks/useDefaultDateRange';
import useUser from '../../utils/hooks/useUser';
import useFeature from '../../utils/hooks/useFeature';
import { useCensor, censorTypes } from '../../utils/hooks/useCensor';
import './FeedLinePage.scss';

const MAX_ROWS_LINE_SUMMARY = 50;
const CHART_HEIGHT = 560;
const CHART_MARGIN = { top: 20, right: 25, bottom: 65, left: 35 };
const MIN_SIGNAL_DATA_RATIO = 0.9;

const FEED_LINE_GQL = gql`
  query LineQuery(
    $line_id: uuid!
    $feedFrameWhere: feed_frame_bool_exp
    $feedFrameAnalysisWhere: feed_frame_analysis_bool_exp
    $max_rows: Int
    $row_offset: Int
    $min_fault_dur: bigint = 21600
    $fault_whitelist: [Int]
  ) {
    feed_line(where: { id: { _eq: $line_id } }) {
      farm {
        name
        id
      }
      name
      device_assignments(
        where: { status: { _eq: "active" }, deleted_at: { _is_null: true }, ended_at: { _is_null: true } }
      ) {
        device {
          faults(
            where: { started_at: { _is_null: false }, ended_at: { _is_null: true }, code: { _in: $fault_whitelist } }
          ) {
            code
            started_at
            ended_at
          }
          faults_aggregate(where: { started_at: { _is_null: false }, code: { _in: $fault_whitelist } }) {
            aggregate {
              count(distinct: false)
            }
          }
        }
      }
    }
    feed_frame_aggregate(where: $feedFrameWhere) {
      aggregate {
        count
      }
    }
    feed_frame(where: $feedFrameWhere, limit: $max_rows, order_by: { started_at: desc }, offset: $row_offset) {
      feed_frame_analyses(where: $feedFrameAnalysisWhere, limit: 1, order_by: { created_at: desc_nulls_last }) {
        latest_estimated_mass_moved_in_grams
        extra_data
      }
      id
      started_at
      ended_at
      device_id
    }

    chartFrames: feed_frame(where: $feedFrameWhere, order_by: { started_at: asc }) {
      feed_frame_analyses(where: $feedFrameAnalysisWhere, limit: 1, order_by: { created_at: desc_nulls_last }) {
        latest_estimated_mass_moved_in_grams
      }
      ended_at
      started_at
    }
  }
`;

function FeedLinePage({ titleSegments = [], pageClass = '', hideBarnName = false }) {
  const { state } = useLocation();
  const { lineId, barnId } = useParams();
  const navigate = useNavigate();
  const { censor } = useCensor();
  const { isMetric } = useContext(WebAppContext);
  const { active: showCoverage } = useFeature('SHOW_FRAME_DATA_COVERAGE');
  const { user } = useUser();

  const inDevelopment = () =>
    toast.warn('This feature is in development', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const defaultDateRange = useDefaultDateRange(barnId);
  const initialDateRange = state?.start ? { from: dayjs.tz(state?.start), to: dayjs.tz(state?.end) } : defaultDateRange;

  const [pageNum, setPageNum] = useState(0);
  const [dateRange, setDateRange] = useState(initialDateRange);
  useEffect(() => {
    setDateRange(defaultDateRange);
  }, [defaultDateRange]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [lineUsage, setLineUsage] = useState(0);
  const [floEvents, setFloEvents] = useState([]);

  const feedFrameFilter = useFeedFrameFilter({
    started_at: { _lte: dateRange.to.unix() },
    ended_at: { _gte: dateRange.from.unix() },
    feed_line_id: { _eq: lineId },
  });
  const analysisFilter = useAnalysisFilter({ feed_frame: { feed_line_id: { _eq: lineId } } });

  const { loading, error, data } = useQuery(FEED_LINE_GQL, {
    variables: {
      feedFrameWhere: feedFrameFilter,
      feedFrameAnalysisWhere: analysisFilter,
      line_id: lineId,
      max_rows: MAX_ROWS_LINE_SUMMARY,
      row_offset: pageNum * MAX_ROWS_LINE_SUMMARY,
      min_fault_dur: 21600,
      fault_whitelist: [2001],
    },
    skip: !analysisFilter,
  });

  const barnName = censor(data?.feed_line[0].farm.name, censorTypes.barn);
  const lineName = censor(data?.feed_line[0].name, censorTypes.feedline);
  const feedFrameCount = data?.feed_frame_aggregate?.aggregate.count;
  const pageTitleSegments = useMemo(() => {
    const segments = [];

    if (lineName) {
      segments.push(lineName, 'Feed Lines');
    }

    if (barnName) {
      segments.push(barnName, 'Barns');
    }

    segments.push(...titleSegments);

    return segments;
  }, [lineName]);

  const tableHeaders = [
    { t: 'START TIME', s: 'm' },
    { t: '', s: 'm' },
    { t: 'WEIGHT', s: 'g' },
    { t: '', s: 's' },
    { t: 'RUN TIME', s: 'l' },
    { t: '', s: 's' },
  ];

  let totalUsage = 0;
  function extractFeedLineData(data) {
    const feedFrames = data?.chartFrames || [];
    const chartEvents = feedFrames.map((r, i) => {
      const frame = feedFrames[i].feed_frame_analyses[0];
      let mass = 0;
      if (frame) {
        mass = convertGramsToSmallUnits(isMetric, frame.latest_estimated_mass_moved_in_grams);
      }

      const event = {
        start: feedFrames[i].started_at,
        end: feedFrames[i].ended_at,
        value: mass,
      };

      totalUsage += mass;
      return event;
    });
    setLineUsage(totalUsage);
    setFloEvents(chartEvents);
  }

  useEffect(() => {
    if (!loading) extractFeedLineData(data);
  }, [data?.chartFrames]);

  function getTableRows() {
    if (loading) return [];

    const noFeedBadge = <StatusBadge icon="warning" status="error" />;
    const warnBadge = <StatusBadge icon="warning" status="warning" />;
    const completeBadge = <StatusBadge icon="logo" status="success" />;
    const spacer = '';
    const rows = data?.feed_frame?.map((feedFrame) => {
      let actions = null;

      if (user?.isStaff)
        actions = (
          <>
            <Link
              target="_blank"
              to={`/rawData/${feedFrame.device_id}?from=${feedFrame.started_at}&to=${feedFrame.ended_at}`}
            >
              📈
            </Link>
            <Link target="_blank" to={`/ff/${feedFrame.id}`}>
              🔎
            </Link>
          </>
        );

      const date = dayjs.tz(1000 * feedFrame.started_at).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE);

      let weight = 'unknown';
      let status = noFeedBadge;

      const seconds = (feedFrame.ended_at ? feedFrame.ended_at : new Date().getTime() / 1000) - feedFrame.started_at;
      const duration = secondsToDuration(seconds);

      if (feedFrame.feed_frame_analyses[0] !== undefined) {
        const grams = feedFrame.feed_frame_analyses[0].latest_estimated_mass_moved_in_grams;
        weight = `${convertGramsToSmallUnits(isMetric, grams).toFixed(1)} ${weightSmallUnitLabel(isMetric)}`;
        status = completeBadge;
        if (showCoverage) {
          const lidarRatio = feedFrame.feed_frame_analyses[0]?.extra_data?.lidar_data_total_in_seconds / seconds;
          const augerRatio = feedFrame.feed_frame_analyses[0]?.auger_data_total_in_seconds / seconds;

          if (lidarRatio < MIN_SIGNAL_DATA_RATIO || augerRatio < MIN_SIGNAL_DATA_RATIO) {
            status = (
              <FeedFloTooltip
                description={`Lidar Data Ratio: ${lidarRatio}\nAuger Data Ratio: ${augerRatio}`}
                mult={true}
              >
                {warnBadge}
              </FeedFloTooltip>
            );
          }
        }
      }

      return [date, status, weight, spacer, duration, actions];
    });
    return rows;
  }

  const goToPreviousPath = () => {
    navigate(-1);
  };
  function handleClickedOutside() {
    setShowDatePicker(false);
  }

  function onDateChange(e) {
    if (e.dateRange) {
      const newFrom = e.dateRange.from;
      const newTo = e.dateRange.to;
      setDateRange({ ...dateRange, from: newFrom, to: newTo });
    }

    setShowDatePicker(false);
  }

  const fromLabel = dateRange.from.format(DATE_FORMAT_MONTH_DAY);
  const toLabel = dateRange.to.format(DATE_FORMAT_MONTH_DAY);

  const dateRangeInput = (
    <>
      <div
        className="dateRangeButton"
        onClick={() => {
          setShowDatePicker(!showDatePicker);
        }}
      >
        <div className="icon">
          <CalendarIcon />
        </div>
        <div className="text">{`${fromLabel} - ${toLabel}`}</div>
      </div>
      {showDatePicker && (
        <FeedFloDatePicker
          onClickedOutside={() => handleClickedOutside()}
          onChange={(e) => onDateChange(e)}
          from={dateRange.from.toDate()}
          to={dateRange.to.toDate()}
        />
      )}
    </>
  );

  const usageTile = (
    <div className="UsageTile">
      <div className="title">Feed Usage</div>
      <div className="usage value">
        {addComma(Math.round(lineUsage))} <span className="unit">{weightSmallUnitLabel(isMetric)}</span>
      </div>
    </div>
  );

  if (error) return <span>{JSON.stringify(error, null, 2)}</span>;

  let headerText = barnName ? `${barnName} - ${lineName}` : '';
  if (hideBarnName) {
    headerText = lineName;
  }

  const chartLines = {
    linePlot: createCumulativePlot(floEvents, dateRange.from.unix(), dateRange.to.unix()),
    colour: '#26AF5F',
    hidden: false,
    label: lineName,
    onClick: () => null,
  };

  return (
    <Page className={`${pageClass} FeedLinePage`} titleSegments={pageTitleSegments}>
      {hideBarnName ? <h3 className={'FeedLinePage-Header'}>{lineName}</h3> : <Heading text={headerText} />}
      <div className="topHalf">
        <div className="topLeftSide">
          <div className="headers">
            {dateRangeInput}
            <FeedFloButton className="BackToBarn" onClick={goToPreviousPath} leftIcon={<HomeIcon />}>
              <label className="pointer">View Barn</label>
            </FeedFloButton>
          </div>
          {usageTile}
          <div className="FeedLineConsumptionHolder">
            <EventChart
              chartLines={chartLines}
              start={dateRange.from.unix()}
              end={dateRange.to.unix()}
              chartHeight={CHART_HEIGHT}
              chartMargin={CHART_MARGIN}
            />
          </div>
        </div>
      </div>
      <div className="bottomHalf">
        <h1>Feeding Events</h1>
        <div className="bottom-header-buttons">
          <FeedFloButton onClick={inDevelopment}>
            <div className="icon">
              <FilterIcon />
            </div>
            <div className="text">Filter</div>
          </FeedFloButton>
          <FeedFloButton
            onClick={() => {
              navigate('/exports');
            }}
          >
            <div className="icon">
              <ExportIcon />
            </div>
            <div className="text">Export</div>
          </FeedFloButton>
        </div>
      </div>
      <FeedFloTable
        headers={tableHeaders}
        rows={getTableRows()}
        itemTotalCount={loading ? 0 : feedFrameCount}
        loading={loading}
        pageNum={pageNum}
        maxRows={MAX_ROWS_LINE_SUMMARY}
        onClickNext={() => {
          setPageNum(pageNum + 1);
        }}
        onClickPrevious={() => {
          setPageNum(pageNum - 1);
        }}
        emptyImage="/images/empty-table.svg"
      />
    </Page>
  );
}

FeedLinePage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
  pageClass: PropTypes.string,
  hideBarnName: PropTypes.bool,
};

export default FeedLinePage;
