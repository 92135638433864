import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { DATE_FORMAT_MONTH_DAY } from '../../utils/dates';
import { CalendarIcon } from '../../atoms/Icons';
import FeedFloDatePicker from '../../molecules/FeedFloDatePicker';

import './DateRangePicker.scss';

function DateRangePicker({
  visible = false,
  dateRange = null,
  setVisible = () => {},
  setDateRange = () => {},
  maxDate,
}) {
  const fromLabel = dateRange?.from ? dateRange.from.format(DATE_FORMAT_MONTH_DAY) : '__';
  const toLabel = dateRange?.to ? dateRange.to.format(DATE_FORMAT_MONTH_DAY) : '__';

  const onDateChange = (e) => {
    if (e.dateRange) {
      const newFrom = dayjs.tz(e.dateRange.from);
      const newTo = dayjs.tz(e.dateRange.to);
      setDateRange({ ...dateRange, from: newFrom, to: newTo });
    }

    setVisible(false);
  };

  const hideDatePicker = () => {
    setVisible(false);
  };

  const toggleDatePicker = () => {
    setVisible(!visible);
  };

  return (
    <>
      <div className="dateRangeButton" onClick={toggleDatePicker}>
        <div className="icon">
          <CalendarIcon />
        </div>
        <div className="text">{`${fromLabel} - ${toLabel}`}</div>
      </div>
      {visible && (
        <FeedFloDatePicker
          onClickedOutside={hideDatePicker}
          onChange={onDateChange}
          from={dateRange.from.toDate()}
          to={dateRange.to.toDate()}
          maxDate={maxDate}
        />
      )}
    </>
  );
}

DateRangePicker.propTypes = {
  visible: PropTypes.bool,
  dateRange: PropTypes.object,
  setVisible: PropTypes.func,
  setDateRange: PropTypes.func,
  maxDate: PropTypes.object,
};

export default DateRangePicker;
