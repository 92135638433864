import PropTypes from 'prop-types';
import LoadingSkeleton from '../../../../atoms/LoadingSkeleton';
import './EventIndicator.scss';

function EventIndicator({ loading = false, visible = false }) {
  if (loading) {
    return <LoadingSkeleton className="EventIndicator--loader" />;
  }

  if (!visible) {
    return null;
  }

  return <div className="EventIndicator" />;
}

EventIndicator.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
};

export default EventIndicator;
