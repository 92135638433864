import React from 'react';
import Lottie from 'lottie-react';
import loadingAnimation from './logoAnimation.json';
import './LoadingView.scss';

function LoadingView() {
  return <Lottie className="LoadingView" animationData={loadingAnimation} />;
}

LoadingView.propTypes = {};

export default LoadingView;
