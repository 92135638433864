import PropTypes from 'prop-types';
import LoadingSkeleton from '../LoadingSkeleton';
import './Swatch.scss';

function Swatch({ loading = false, className = '', colour = '', label = '' }) {
  if (loading) {
    return (
      <div className={`Swatch Swatch--loading ${className}`}>
        <LoadingSkeleton className="Swatch-colour Swatch-colour--loading" />
        {label ? <LoadingSkeleton className="Swatch-label Swatch-label--loading" /> : null}
      </div>
    );
  }

  return (
    <div className={`Swatch ${className}`}>
      <div className="Swatch-colour" style={{ backgroundColor: colour }} />
      {label ? <div className="Swatch-label">{label}</div> : null}
    </div>
  );
}

Swatch.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  colour: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default Swatch;
