import PropTypes from 'prop-types';
import { convertGramsToSmallUnits } from '../../utils/unitConversion';
import FeedFloButton from '../../atoms/FeedFloButton';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';

function FeederTableRow({
  loading = false,
  isHeader = false,
  feederID = '',
  lineName = '',
  capacity = 0,
  quantity = 0,
  onEdit = () => {},
  onDelete = () => {},
}) {
  const capacityInPounds = convertGramsToSmallUnits(false, capacity); // This will need to be updated if we ever support metric values in this UI

  const onEditClick = () => {
    onEdit(feederID);
  };

  const onDeleteClick = () => {
    onDelete(feederID);
  };

  if (loading) {
    return (
      <>
        <LoadingSkeleton className="FeederTable-cell FeederTable-cell--loader" />
        <LoadingSkeleton className="FeederTable-cell FeederTable-cell--loader" />
        <LoadingSkeleton className="FeederTable-cell FeederTable-cell--loader" />
        <LoadingSkeleton className="FeederTable-cell FeederTable-cell--loader" />
      </>
    );
  }

  if (isHeader) {
    return (
      <>
        <div className="FeederTable-cell FeederTable-cell--header">Feed Line</div>
        <div className="FeederTable-cell FeederTable-cell--header">Capacity</div>
        <div className="FeederTable-cell FeederTable-cell--header">Quantity</div>
        <div className="FeederTable-cell FeederTable-cell--header" />
      </>
    );
  }

  return (
    <>
      <div className="FeederTable-cell">{lineName}</div>
      <div className="FeederTable-cell">{`${capacityInPounds} lbs`}</div>
      <div className="FeederTable-cell">{quantity}</div>
      <div className="FeederTable-cell FeederTable-actionButtonContainer">
        <FeedFloButton className="FeederTable-actionButton" onClick={onEditClick}>
          Edit
        </FeedFloButton>
        <FeedFloButton className="FeederTable-actionButton" onClick={onDeleteClick}>
          Delete
        </FeedFloButton>
      </div>
    </>
  );
}

FeederTableRow.propTypes = {
  loading: PropTypes.bool,
  isHeader: PropTypes.bool,
  feederID: PropTypes.string,
  lineName: PropTypes.string,
  capacity: PropTypes.number,
  quantity: PropTypes.number,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default FeederTableRow;
