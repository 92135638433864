import { useState } from 'react';
import PropTypes from 'prop-types';
import CollapsibleSection from '../../../../atoms/CollapsibleSection';
import TextInput from '../../../../atoms/TextInput';
import ToggleSwitch from '../../../../atoms/ToggleSwitch';
import FilterList from '../FilterList';
import MiniCalendar from '../MiniCalendar';
import './LeftSidebar.scss';

function LeftSidebar({
  dataLoading = false,
  filtersLoading = false,
  visible = true,
  visibleDateSet = null,
  today = null,
  currentDate = null,
  showWeekends = true,
  dateToTripsMap = {},
  tripStatusFilters = [],
  barnFilters = [],
  onDateClick = () => {},
  onMiniCalendarCurrentDateUpdate = () => {},
  onTripStatusFiltersUpdate = () => {},
  onShowWeekendsUpdate = () => {},
  onBarnFiltersUpdate = () => {},
}) {
  const [tripStatusSectionExpanded, setTripStatusSectionExpanded] = useState(true);
  const [barnSectionExpanded, setBarnSectionExpanded] = useState(true);
  const [lastToggledBarnFilterIndex, setLastToggledBarnFilterIndex] = useState(-1);
  const [barnSearchText, setBarnSearchText] = useState('');

  const onTripStatusSectionClick = () => {
    setTripStatusSectionExpanded(!tripStatusSectionExpanded);
  };

  const onBarnsSectionClick = () => {
    setBarnSectionExpanded(!barnSectionExpanded);
  };

  const onToggleAllBarns = () => {
    onBarnFiltersUpdate(
      barnFilters.map((filter) => ({
        ...filter,
        enabled: !barnFilters.every((filter) => filter.enabled),
      })),
    );
  };

  const onChangeBarnSearchText = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setBarnSearchText(e.target.value);
    onBarnFiltersUpdate(
      barnFilters.map((barn) => {
        return {
          ...barn,
          visible:
            barn.label.toLowerCase().includes(searchTerm) ||
            barn.value.toLowerCase().includes(searchTerm) ||
            barn?.organization?.name.toLowerCase().includes(searchTerm) ||
            barn?.organization?.id.toLowerCase().includes(searchTerm),
        };
      }),
    );

    // Since the barn filters can be searched and the filtering is done client side, we need to override the filter
    // index state the FilterList normally manages internally whenever we modify the search term. If not, we could end
    // up in a situation where the first index is set before searching but the second index is set after searching. This
    // could be confusing as more filters would get toggled than intended.
    setLastToggledBarnFilterIndex(-1);
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="LeftSidebar">
      <MiniCalendar
        loading={dataLoading}
        visibleDateSet={visibleDateSet}
        today={today}
        currentDate={currentDate}
        showWeekends={showWeekends}
        dateToTripsMap={dateToTripsMap}
        onDateClick={onDateClick}
        onShowWeekendsUpdate={onShowWeekendsUpdate}
        onMiniCalendarCurrentDateUpdate={onMiniCalendarCurrentDateUpdate}
      />
      <div className="LeftSidebar-filterSectionsContainer">
        <div className="LeftSidebar-filterSections">
          <CollapsibleSection
            title="Trip Status"
            isExpanded={tripStatusSectionExpanded}
            onExpandCollapse={onTripStatusSectionClick}
          >
            <FilterList filters={tripStatusFilters} onUpdateFilters={onTripStatusFiltersUpdate} />
          </CollapsibleSection>
          <CollapsibleSection title="Barns" isExpanded={barnSectionExpanded} onExpandCollapse={onBarnsSectionClick}>
            <div className="LeftSidebar-barnFilters">
              <div className="LeftSidebar-toggleAllBarnsContainer">
                <ToggleSwitch
                  className="LeftSidebar-toggleSwitch"
                  isActive={barnFilters.every((filter) => filter.enabled)}
                  onToggle={onToggleAllBarns}
                />
                View all barns
              </div>
              <TextInput placeholder="Search for barns" value={barnSearchText} onChange={onChangeBarnSearchText} />
              <FilterList
                loading={filtersLoading}
                filters={barnFilters}
                lastToggledIndex={lastToggledBarnFilterIndex}
                onUpdateLastToggledIndex={setLastToggledBarnFilterIndex}
                onUpdateFilters={onBarnFiltersUpdate}
              />
            </div>
          </CollapsibleSection>
        </div>
      </div>
    </div>
  );
}

LeftSidebar.propTypes = {
  dataLoading: PropTypes.bool,
  filtersLoading: PropTypes.bool,
  visible: PropTypes.bool,
  visibleDateSet: PropTypes.object,
  today: PropTypes.object,
  currentDate: PropTypes.object,
  showWeekends: PropTypes.bool,
  dateToTripsMap: PropTypes.object,
  tripStatusFilters: PropTypes.array,
  barnFilters: PropTypes.array,
  onDateClick: PropTypes.func,
  onShowWeekendsUpdate: PropTypes.func,
  onMiniCalendarCurrentDateUpdate: PropTypes.func,
  onTripStatusFiltersUpdate: PropTypes.func,
  onBarnFiltersUpdate: PropTypes.func,
};

export default LeftSidebar;
