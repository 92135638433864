import PropTypes from 'prop-types';
import Checkbox from '../../../../atoms/Checkbox';

function FilterRow({
  loading = false,
  color = '',
  label = '',
  value = '',
  index = -1,
  isEnabled = false,
  isVisible = true,
  onToggleFilter = () => {},
}) {
  const onChange = (e) => {
    onToggleFilter(e, value, index);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Checkbox
      loading={loading}
      style={{ color }}
      labelClassName="FilterList-rowLabel"
      label={label}
      checked={isEnabled}
      onChange={onChange}
    />
  );
}

FilterRow.propTypes = {
  loading: PropTypes.bool,
  color: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  index: PropTypes.number,
  isEnabled: PropTypes.bool,
  isVisible: PropTypes.bool,
  onToggleFilter: PropTypes.func,
};

export default FilterRow;
