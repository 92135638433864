import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import FeedFloButton from '../../atoms/FeedFloButton';
import FeederEditorDialog from '../FeederEditorDialog';
import FeederTableRow from './FeederTableRow';
import ConfirmationDialog from '../ConfirmationDialog';
import './FeederTable.scss';

function FeederTable({ loading = false, feedLines = [], feeders = [], onSave = () => {} }) {
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [editFeeder, setEditFeeder] = useState(null);
  const dialogRef = useRef(null);

  const onAddFeederClick = () => {
    setEditDialogVisible(true);
  };

  const onShowConfirmationDialog = () => {
    if (!dialogRef?.current) {
      return;
    }

    dialogRef.current.showModal();
  };

  const onCancelClick = () => {
    setEditFeeder(null);
    setEditDialogVisible(false);
  };

  const onSaveClick = (feederID, feedLineID, capacityInGrams, quantity) => {
    // When saving a feeder, check if there's a different, existing feeder matching the updated configuration
    const existingFeeder = feeders.find((f) => {
      return f.id !== feederID && f.feed_line.id === feedLineID && f.capacity_in_grams === capacityInGrams;
    });
    let totalQuantity = quantity;

    // If there is an existing feeder, combine the quantities of the two feeders together
    if (existingFeeder) {
      totalQuantity += existingFeeder.quantity;
    }

    // Save the new or updated feeder
    onSave({
      id: existingFeeder?.id || feederID,
      feed_line_id: feedLineID,
      capacity_in_grams: capacityInGrams,
      quantity: totalQuantity,
    });

    // In the case where there was an existing feeder with a matching configuration, call it Feeder A, and we were
    // editing a different feeder that already existed, call it Feeder B, we need to delete Feeder B as it's quantity
    // will have been added to Feeder A.
    if (existingFeeder && feederID) {
      onSave({
        id: feederID,
        deleted_at: Math.floor(Date.now() / 1000),
        feed_line_id: feedLineID,
        capacity_in_grams: capacityInGrams,
        quantity,
      });
    }

    setEditFeeder(null);
    setEditDialogVisible(false);
  };

  const onEditClick = (feederID) => {
    const feeder = feeders.find((feeder) => feeder.id === feederID);
    setEditFeeder(feeder);
    setEditDialogVisible(true);
  };

  const onDeleteClick = (feederID) => {
    const feeder = feeders.find((feeder) => feeder.id === feederID);
    setEditFeeder(feeder);
    onShowConfirmationDialog();
  };

  const onConfirmClick = () => {
    const feeder = feeders.find((feeder) => feeder.id === editFeeder.id);
    onSave({
      id: feeder.id,
      deleted_at: Math.floor(Date.now() / 1000),
      feed_line_id: feeder.feed_line.id,
      capacity_in_grams: feeder.capacity_in_grams,
      quantity: feeder.quantity,
    });
    setEditFeeder(null);
  };

  if (loading) {
    return (
      <div className="FeederTable">
        <div className="FeederTable-content">
          <FeederTableRow loading={loading} />
          <FeederTableRow loading={loading} />
          <FeederTableRow loading={loading} />
          <FeederTableRow loading={loading} />
        </div>
      </div>
    );
  }

  return (
    <div className="FeederTable">
      {editDialogVisible && (
        <FeederEditorDialog
          feedLines={feedLines}
          editFeeder={editFeeder}
          onCancel={onCancelClick}
          onSave={onSaveClick}
        />
      )}
      <ConfirmationDialog
        dialogRef={dialogRef}
        message={'Are you sure you want to delete\nthis feeder?'}
        onCancel={onCancelClick}
        onConfirm={onConfirmClick}
      />
      {feeders.length === 0 ? <div>You currently have no feeders.</div> : null}
      <div className="FeederTable-content">
        {feeders.length > 0 ? <FeederTableRow isHeader={true} /> : null}
        {feeders.map((feeder) => (
          <FeederTableRow
            key={feeder.id}
            feederID={feeder.id}
            lineName={feeder.feed_line.name}
            capacity={feeder.capacity_in_grams}
            quantity={feeder.quantity}
            onEdit={onEditClick}
            onDelete={onDeleteClick}
          />
        ))}
      </div>
      <FeedFloButton className="FeederTable-addFeeder" type="secondary" onClick={onAddFeederClick}>
        Add Feeder
      </FeedFloButton>
    </div>
  );
}

FeederTable.propTypes = {
  loading: PropTypes.bool,
  feedLines: PropTypes.array,
  feeders: PropTypes.array,
  onSave: PropTypes.func,
};

export default FeederTable;
