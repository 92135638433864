export const CalendarMode = {
  OneWeek: 'one_week',
  TwoWeeks: 'two_weeks',
  Month: 'month',
  Agenda: 'agenda',
};

// The day of the week indexes are defined by dayjs
// https://day.js.org/docs/en/get-set/day
export const DayOfWeek = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};
