import PropTypes from 'prop-types';
import Chevron from '../../../atoms/Chevron';
import LoadingSkeleton from '../../../atoms/LoadingSkeleton';

function NotificationSettingsSection({
  loading = false,
  className = '',
  title = '',
  children = [],
  isCollapsible = false,
  isOpen = false,
  setIsOpen = () => {},
}) {
  const onToggleSection = () => {
    setIsOpen(!isOpen);
  };

  const renderContents = () => {
    if (!isCollapsible) {
      return children;
    }

    return isOpen && children;
  };

  if (loading) {
    return (
      <div className="NotificationSettings-section">
        <div className="NotificationSettings-sectionHeader">
          <div className="NotificationSettings-sectionTitle">
            <LoadingSkeleton className="NotificationSettings-sectionTitle--loader" />
          </div>
        </div>
        {renderContents()}
      </div>
    );
  }

  return (
    <div className={`NotificationSettings-section ${className}`}>
      <div className="NotificationSettings-sectionHeader">
        <div className="NotificationSettings-sectionTitle">{title}</div>
        {isCollapsible && <Chevron className="NotificationSettings-chevron" open={isOpen} onClick={onToggleSection} />}
      </div>
      {renderContents()}
    </div>
  );
}

NotificationSettingsSection.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.array,
  isCollapsible: PropTypes.bool,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default NotificationSettingsSection;
