import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import AnimalGroupCreator from './AnimalGroupCreator';
import AnimalGroupCreatorWarning from './AnimalGroupCreatorWarning';
import AnimalGroupDetails from './AnimalGroupDetails';
import AnimalGroupSelector from './AnimalGroupSelector';
import Button from '../../atoms/Button';
import CopyButton from '../../atoms/CopyButton';
import Dialog from '../../atoms/Dialog';
import LabelledColumn from '../../atoms/LabelledColumn';

import useSearchParamsState from '../../utils/hooks/useSearchParamsState';
import { DATE_FORMAT_DASH } from '../../utils/dates';
import { ANIMAL_GROUP_HEADER_QUERY, ANIMAL_GROUP_FOR_ID_QUERY } from './queries';
import './AnimalGroupHeader.scss';

const QueryType = Object.freeze({
  DEFAULT: 'default',
  PARAM: 'param',
  OVERRIDE: 'override',
  NONE: 'none',
});

/**
 * A page header to display, view, and create animal groups at a given barn.
 * Expose the start and end date for the selected group to children through the
 *  Function as Child Component (FaCC) pattern.
 *
 * The child function should return JSX and accept an object with 'from' and 'to' keys
 *  containing the UNIX timestamp representations of the start and end dates for the selected group.
 *  Optional if the header doesn't need to control another component.
 *
 * @component
 */
function AnimalGroupHeader({
  loading = false,
  className = '',
  animalGroupId: animalGroupIdOverride = null,
  setAnimalGroupId: setAnimalGroupIdOverride = undefined,
  barnId = '',
  orgId = '',
  children = () => {},
}) {
  const creatorDialogRef = useRef(null);
  const creatorWarningDialogRef = useRef(null);
  const detailsDialogRef = useRef(null);
  const selectorDialogRef = useRef(null);

  const [animalGroupId, setAnimalGroupId] = useState(null);
  const [animalGroupIdParam] = useSearchParamsState('group', null);
  const [birthDateSeconds, setBirthDate] = useState(null);
  const [endedAtSeconds, setEndedAt] = useState(null);
  const [expectedEndedAtSeconds, setExpectedEndedAt] = useState(null);
  const [externalId, setExternalId] = useState(null);
  const [startedAtSeconds, setStartedAt] = useState(null);

  const [dateRange, setDateRange] = useState(null);

  // Unix timestamp value of two weeks ago.
  // Closed animal groups older than this value will not be loaded automatically.
  const cutoffTimestampSeconds = dayjs.tz().subtract(2, 'week').startOf('day').unix();

  // Update the dateRange when startedAt or endedAt are updated.
  // If startedAtSeconds is 0, it means a group isn't loaded yet, and dateRange should not be set.
  useEffect(() => {
    const dateRange =
      startedAtSeconds > 0
        ? {
            from: dayjs.tz(1000 * startedAtSeconds).startOf('day'),
            to: endedAtSeconds ? dayjs.tz(1000 * endedAtSeconds).endOf('day') : dayjs.tz().endOf('day'),
          }
        : null;
    setDateRange(dateRange);
  }, [animalGroupId, endedAtSeconds, startedAtSeconds]);

  function processQueryResponse(data) {
    if (undefined === data?.animal_group?.[0]) return;

    const birthDateSeconds = data.animal_group[0].approximate_birthdate;
    const endedAtSeconds = data.animal_group[0].ended_at;
    const expectedEndedAtSeconds = data.animal_group[0].expected_ended_at; // Possibly null.
    const externalId = data.animal_group[0].external_id;
    const id = data.animal_group[0].id;
    const startedAtSeconds = data.animal_group[0].started_at;

    setAnimalGroupId(id);
    setAnimalGroupIdOverride?.(id);
    setBirthDate(birthDateSeconds);
    setEndedAt(endedAtSeconds);
    setExpectedEndedAt(expectedEndedAtSeconds);
    setExternalId(externalId);
    setStartedAt(startedAtSeconds);
  }

  function handleQueryError(error) {
    console.error(`Error querying animal group ${animalGroupId}: ${error}`);
  }

  // Determines if an override query will be used.
  // URL param override takes priority over prop override.
  const queryType = useMemo(() => {
    if (animalGroupIdParam) {
      // URL params always take priority.
      return QueryType.PARAM;
    } else if (animalGroupIdOverride) {
      // This extra clause avoids an additional query being made after the default query.
      // animalGroupIdOverride can be set as a result of the default query, which leads here, but
      //  there's no need to perform another query to fetch the animal group we just fetched.
      if (animalGroupIdOverride !== animalGroupId) {
        return QueryType.OVERRIDE;
      } else {
        return QueryType.NONE;
      }
    } else if (barnId) {
      // If no override is selected and we have a barnId, use the default query.
      return QueryType.DEFAULT;
    } else {
      return QueryType.NONE;
    }
  }, [animalGroupIdOverride, animalGroupIdParam]);

  useQuery(ANIMAL_GROUP_HEADER_QUERY, {
    variables: {
      barnId,
      cutoff: cutoffTimestampSeconds,
    },
    skip: QueryType.DEFAULT !== queryType,
    onCompleted: processQueryResponse,
    onError: handleQueryError,
  });

  useQuery(ANIMAL_GROUP_FOR_ID_QUERY, {
    variables: {
      animalGroupId: animalGroupIdParam,
    },
    skip: QueryType.PARAM !== queryType,
    onCompleted: processQueryResponse,
    onError: handleQueryError,
  });

  useQuery(ANIMAL_GROUP_FOR_ID_QUERY, {
    variables: {
      animalGroupId: animalGroupIdOverride,
    },
    skip: QueryType.OVERRIDE !== queryType,
    onCompleted: processQueryResponse,
    onError: handleQueryError,
  });

  const loadAnimalGroup = useCallback((id, birthDate, endedAt, expectedEndedAt, externalId, startedAt) => {
    setAnimalGroupId(id);
    setAnimalGroupIdOverride?.(id);
    setBirthDate(birthDate);
    setEndedAt(endedAt);
    setExpectedEndedAt(expectedEndedAt);
    setExternalId(externalId);
    setStartedAt(startedAt);
  }, []);

  const openCreatorWithoutWarning = useCallback(() => creatorDialogRef.current.showModal(), []);
  const openCreatorWithWarning = useCallback(() => creatorWarningDialogRef.current.showModal(), []);
  // Show a warning if there's an open group that has not ended.
  const openCreator = useCallback(
    () => (animalGroupId && !endedAtSeconds ? openCreatorWithWarning() : openCreatorWithoutWarning()),
    [animalGroupId, endedAtSeconds],
  );
  const closeCreator = useCallback(() => creatorDialogRef.current.close(), []);
  const closeWarning = useCallback(() => creatorWarningDialogRef.current.close(), []);

  const openDetails = useCallback(() => detailsDialogRef.current.showModal(), []);
  const closeDetails = useCallback(() => detailsDialogRef.current.close(), []);

  const openSelector = useCallback(() => selectorDialogRef.current.showModal(), []);

  return (
    <>
      <div className={`AnimalGroupHeader ${className}`}>
        <div className="AnimalGroupHeader-info">
          <LabelledColumn loading={loading} heading="Selected Group">
            <span className="AnimalGroupHeader-copy">
              {externalId || '-'}
              <CopyButton data={`${window.location.origin}${window.location.pathname}?group=${animalGroupId}`} />
            </span>
          </LabelledColumn>
          <LabelledColumn loading={loading} heading="Start Date">
            {(startedAtSeconds && dayjs.tz(1000 * startedAtSeconds).format(DATE_FORMAT_DASH)) || '-'}
          </LabelledColumn>
          <LabelledColumn loading={loading} heading="End Date">
            {(endedAtSeconds && dayjs.tz(1000 * endedAtSeconds).format(DATE_FORMAT_DASH)) || '-'}
          </LabelledColumn>
        </div>
        <div className="AnimalGroupHeader-buttons">
          <Button
            disabled={!animalGroupId}
            variant="pastel"
            color="success"
            content="View Details"
            onClick={openDetails}
          />
          <Button
            disabled={!barnId || !orgId}
            variant="pastel"
            color="success"
            content="New Animal Group"
            onClick={openCreator}
          />
          <Button
            disabled={loading}
            variant="pastel"
            color="success"
            content="Find Animal Group"
            onClick={openSelector}
          />
        </div>
        <Dialog ref={creatorDialogRef}>
          <AnimalGroupCreator barnId={barnId} orgId={orgId} loadAnimalGroup={loadAnimalGroup} close={closeCreator} />
        </Dialog>
        <Dialog ref={creatorWarningDialogRef}>
          <AnimalGroupCreatorWarning close={closeWarning} onConfirm={openCreatorWithoutWarning} />
        </Dialog>
        <Dialog ref={detailsDialogRef}>
          <AnimalGroupDetails
            birthDateSeconds={birthDateSeconds}
            endedAtSeconds={endedAtSeconds}
            expectedEndedAtSeconds={expectedEndedAtSeconds}
            externalId={externalId}
            id={animalGroupId}
            startedAtSeconds={startedAtSeconds}
            loadAnimalGroup={loadAnimalGroup}
            close={closeDetails}
          />
        </Dialog>
        <Dialog className="AnimalGroupHeader-selectorDialog" ref={selectorDialogRef}>
          <AnimalGroupSelector
            barnId={barnId}
            loadAnimalGroup={loadAnimalGroup}
            // AnimalGroupSelector needs the dialog ref to know whether or not it's visible.
            // It can also use this ref to close the dialog, so there is no need for a 'close' callback.
            dialogRef={selectorDialogRef}
          />
        </Dialog>
      </div>
      {children?.(dateRange)}
    </>
  );
}

AnimalGroupHeader.propTypes = {
  /**
   * Determiens whether or not the to display the loading skeletons for this component.
   */
  loading: PropTypes.bool,
  /**
   * CSS class override.
   */
  className: PropTypes.string,
  /**
   * Optional ID of an animal group used to override default behaviour.
   */
  animalGroupId: PropTypes.string,
  /**
   * Optional setter function for overriding the animal group ID.
   */
  setAnimalGroupId: PropTypes.func,
  /**
   * The ID of the barn to view animal groups for.
   */
  barnId: PropTypes.string.isRequired,
  /**
   * The ID of the organization owning barnId. Required for group creation. Optional otherwise.
   */
  orgId: PropTypes.string,
  /**
   * A function returning JSX. Passed an object with 'from' and 'to' keys.
   */
  children: PropTypes.func,
};

export default AnimalGroupHeader;
