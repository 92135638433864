import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import Page from '../../atoms/Page';
import InventoryCard from '../../organisms/InventoryCard/InventoryCard';
import './InventoryTab.scss';

// Ordering could also be done client-side, but given this is only a few items this aggregate is still fairly simple
const BIN_SET_QUERY_GQL = gql`
  query BinSetQuery($barn_id: uuid!) {
    bin_set(where: { farm_id: { _eq: $barn_id } }, order_by: { bins_aggregate: { min: { name: asc } } }) {
      id
      bins {
        name
        bin_feed_lines {
          feed_line {
            name
            device_assignments(where: { ended_at: { _is_null: true } }) {
              device {
                label
              }
            }
          }
        }
      }
    }
  }
`;

function InventoryTab({ titleSegments = [], barnId = '' }) {
  const { error, loading, data } = useQuery(BIN_SET_QUERY_GQL, {
    variables: {
      barn_id: barnId,
    },
  });
  const pageTitleSegments = useMemo(() => ['Inventory', ...titleSegments], []);

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>{JSON.stringify(error, null, 2)}</span>;
  }

  return (
    <Page className="InventoryTab" titleSegments={pageTitleSegments}>
      {data?.bin_set?.map((binSet) => {
        const feedlines = {};

        for (const bin of binSet.bins || []) {
          for (const binFeedLine of bin.bin_feed_lines || []) {
            const feedLineName = binFeedLine.feed_line?.name || '';

            if (!feedlines[feedLineName]) {
              feedlines[feedLineName] = new Set();
            }

            for (const assignment of binFeedLine?.feed_line?.device_assignments || []) {
              feedlines[feedLineName].add(assignment.device?.label);
            }
          }
        }

        return <InventoryCard key={binSet?.id} binSetID={binSet?.id} feedlines={Object.keys(feedlines)} />;
      })}
    </Page>
  );
}

InventoryTab.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
  barnId: PropTypes.string,
};

export default InventoryTab;
