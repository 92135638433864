import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { XIcon } from '../../atoms/Icons';
import './Banner.scss';

export default function Banner({ message = '', variant = 'info', className = '', hidden = false, dismissable = true }) {
  const [isHidden, setHidden] = useState(hidden);

  useEffect(() => {
    if (dismissable) {
      setHidden(hidden);
    }
  }, [dismissable, hidden]);

  let variantClassName = 'Banner--info';

  if (['info', 'warn', 'danger', 'success'].includes(variant)) {
    variantClassName = `Banner--${variant}`;
  }

  const close = () => {
    if (dismissable) {
      setHidden(true);
    }
  };

  return (
    <div className={`Banner ${variantClassName} ${isHidden ? 'Banner--hidden' : ''} ${className}`}>
      <p className="Banner-content">{message}</p>
      {dismissable && (
        <XIcon onClick={close} className={`Banner-closeIcon ${isHidden ? `Banner-closeIcon--hidden` : ''}`} />
      )}
    </div>
  );
}

Banner.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  variant: PropTypes.oneOf(['info', 'warn', 'danger', 'success']),
  hidden: PropTypes.bool,
  dismissable: PropTypes.bool,
};
