import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams, useSearchParams } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { DATE_TIME_DASH } from '../../utils/dates';
import Page from '../../atoms/Page';
import SignalDataChart from '../../organisms/DeviceCard/SensorDataChart';
import useSearchParamsState from '../../utils/hooks/useSearchParamsState';
import './RawDataPage.scss';

const FEED_FRAME_GQL = gql`
  query FeedFrameInfo($ffId: uuid!) {
    feed_frame(where: { id: { _eq: $ffId } }) {
      device_id
      started_at
      ended_at
    }
  }
`;

const FEED_FRAME_TIME_MARGIN = 1 * 60;

function RawDataPage({ titleSegments = [] }) {
  const params = useParams();
  const now = useMemo(() => {
    return Math.floor(new Date().getTime() / 1000);
  }, []);

  const [, setSearchParams] = useSearchParams();

  const [to, setTo] = useSearchParamsState('to', now);
  const [from, setFrom] = useSearchParamsState('from', to - 24 * 60 * 60);
  const [deviceId, setDeviceId] = useState(params.deviceId || null);
  const [feedFrameId] = useSearchParamsState('ff');
  const [getFeedFrame, { loading, error, data }] = useLazyQuery(FEED_FRAME_GQL, { variables: { ffId: feedFrameId } });
  const pageTitleSegments = useMemo(() => ['Raw Data', ...titleSegments], []);

  useEffect(() => {
    if (data) {
      setTo(data.feed_frame[0].ended_at + FEED_FRAME_TIME_MARGIN);
      setFrom(data.feed_frame[0].started_at - FEED_FRAME_TIME_MARGIN);
      setDeviceId(data.feed_frame[0].device_id);
    }
  }, [data]);

  useEffect(() => {
    if (feedFrameId) {
      getFeedFrame();
    }
  }, [feedFrameId]);

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>{JSON.stringify(error, null, 2)}</span>;
  }

  const toDate = new Date(to * 1000);
  const fromDate = new Date(from * 1000);
  const toRef = useRef(null);
  const fromRef = useRef(null);

  return (
    <Page className="RawDataPage" titleSegments={pageTitleSegments}>
      <div className="top-bar">
        <label>Start:</label>
        <input
          id="startTime"
          ref={fromRef}
          type="datetime-local"
          name="startTime"
          defaultValue={dayjs.tz(fromDate).format(DATE_TIME_DASH)}
        />
        <label className="withMargin">End:</label>
        <input
          id="endTime"
          ref={toRef}
          type="datetime-local"
          name="endTime"
          defaultValue={dayjs.tz(toDate).format(DATE_TIME_DASH)}
        />
        <button
          type="submit"
          onClick={() => {
            setSearchParams({ from: dayjs.tz(fromRef.current.value).unix(), to: dayjs.tz(toRef.current.value).unix() });
          }}
        >
          Reload
        </button>
      </div>
      <SignalDataChart deviceId={deviceId} to={dayjs.tz(parseInt(to) * 1000)} from={dayjs.tz(parseInt(from) * 1000)} />
    </Page>
  );
}

RawDataPage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
};

export default RawDataPage;
