import { useAtomValue } from 'jotai';
import { featureFlagsAtom } from '../../jotaiAtoms';

export default function useFeature(featureName) {
  const features = useAtomValue(featureFlagsAtom);

  const feature = features.find((x) => {
    return x.name === featureName;
  });

  return feature || { active: null };
}
