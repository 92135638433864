import { useCallback, useMemo } from 'react';
import { GridColumns } from '@visx/grid';
import { Group } from '@visx/group';
import PropTypes from 'prop-types';

import EventChartBar from './EventChartBar';
import { ArrowIcon } from '../../../atoms/Icons';
import Swatch from '../../../atoms/Swatch';

import { rowHeight, borderWidth } from './EventChartBottom.module.scss';
import './EventChartBottom.scss';

const ROW_HEIGHT_PIXELS = Number(rowHeight);
const TOLERANCE_PIXELS = 15;
const TOTAL_BORDER_PIXELS = borderWidth * 2; // Assuming equal borders on two sides

function eventCompare(event1, event2, xMax, xScale) {
  // Events with different colours will not be grouped together.
  if (event1.colour?.fill !== event2.colour?.fill || event1.colour?.stroke !== event2.colour?.stroke) return false;

  // For comparison, start values that exceed chart bounds will be clamped to 0.
  const start1 = Math.max(xScale(event1.points?.[0] || 0), 0);
  const start2 = Math.max(xScale(event2.points?.[0] || 0), 0);
  // For comparison, end values that exceed chart bounds will be clamped to the max value.
  const end1 = Math.min(xScale(event1.points?.[1] || 0), xMax);
  const end2 = Math.min(xScale(event2.points?.[1] || 0), xMax);

  const startDelta = Math.abs(start1 - start2);
  const endDelta = Math.abs(end1 - end2);

  return startDelta < TOLERANCE_PIXELS && endDelta < TOLERANCE_PIXELS;
}

export default function EventChartBottomRowCollapsed({
  events,
  label,
  colour,
  hidden,
  xAxisTicks,
  xMax,
  xScale,
  expand,
  reveal,
}) {
  const expandAndReveal = useCallback(() => {
    expand();
    reveal?.();
  }, [expand, reveal]);

  /**
   * An array of grouped events.
   * Each group is an array of events of the same type with similar start and end points.
   * The array is sorted by group size so that grouped bars are drawn overtop ungrouped bars.
   */
  const groups = useMemo(
    () =>
      events
        .reduce((groups, event) => {
          let placed = false;
          // Check if this event fits in any existing group.
          for (const group of groups) {
            if (group.some((groupEvent) => eventCompare(event, groupEvent, xMax, xScale))) {
              group.push(event);
              placed = true;
              break;
            }
          }

          // If no fitting group exists, create a new one for this event.
          if (!placed) {
            groups.push([event]);
          }
          return groups;
        }, [])
        .sort((g1, g2) => g1.length - g2.length),
    [events, xMax, xScale],
  );

  const eventJSX = useMemo(
    () =>
      groups.map((group, index) => {
        // Calculate the bounds of the group as the earliest starting value and the latest ending value.
        if (group.length === 1) {
          const { colour, points, selected, onClick: select } = group[0];
          const startX = xScale(points[0]);
          const endX = xScale(points[points.length - 1]);

          const onClick = () => {
            select();
            reveal?.();
          };

          return (
            <EventChartBar
              className="EventChartBottomRow-event"
              x={startX}
              y={ROW_HEIGHT_PIXELS / 2}
              width={endX - startX}
              stroke={colour.stroke}
              fill={colour.fill}
              clipPath="url(#eventChartBottomRowCollapsedBoundary)"
              selected={selected}
              onClick={onClick}
              key={index}
            />
          );
        } else {
          const { start, end, selected } = group.reduce(
            ({ start, end, selected }, event) => {
              const eventStart = event.points?.[0] || 0;
              const eventEnd = event.points?.[1] || 0;
              return {
                start: Math.min(start, eventStart),
                end: Math.max(end, eventEnd),
                selected: selected || event.selected,
              };
            },
            { start: Infinity, end: 0, selected: false },
          );
          const startX = xScale(start);
          const endX = xScale(end);
          const { fill, stroke } = group[0].colour;

          const circleX = (Math.max(startX, 0) + Math.min(endX, xMax)) / 2;
          const circleY = ROW_HEIGHT_PIXELS / 2;

          return (
            <g
              className="EventChartBottomRow-event"
              clipPath="url(#eventChartBottomRowCollapsedBoundary)"
              onClick={expandAndReveal}
              key={index}
            >
              <EventChartBar
                x={startX}
                y={ROW_HEIGHT_PIXELS / 2}
                width={endX - startX}
                stroke={stroke}
                fill={fill}
                selected={selected}
              />
              <circle
                cx={circleX}
                cy={circleY}
                r={selected ? 13 : 10}
                stroke={stroke}
                strokeWidth={selected ? 3 : 2}
                fill="white"
              />
              <text
                className="EventChartBottomRow-eventNumeral"
                x={circleX}
                y={circleY}
                dominantBaseline="central"
                style={{
                  fontSize: selected ? 16 : 12,
                }}
              >
                {group.length}
              </text>
            </g>
          );
        }
      }),
    [groups],
  );

  return (
    <div className={`EventChartBottomRow${hidden ? ' EventChartBottomRow--hidden' : ''}`}>
      <Swatch className="EventChartBottomRow-swatch" colour={colour} label={label} />
      <div className="EventChartBottomRow-trackWrapper">
        <div className="EventChartBottomRow-arrowWrapper" onClick={expandAndReveal}>
          <ArrowIcon className="EventChartBottomRow-downArrow" />
        </div>
        <svg
          style={{
            height: ROW_HEIGHT_PIXELS + TOTAL_BORDER_PIXELS,
            width: '100%',
          }}
        >
          <Group transform={`translate(${borderWidth}, ${borderWidth})`}>
            <defs>
              <clipPath id="eventChartBottomRowCollapsedBoundary">
                <rect x={0} y={0} width={xMax} height={ROW_HEIGHT_PIXELS} />
              </clipPath>
            </defs>
            <GridColumns
              scale={xScale}
              width={xMax}
              height={ROW_HEIGHT_PIXELS}
              stroke="#D9DCE1"
              tickValues={xAxisTicks}
            />
            <rect
              // The border around the row.
              x={0}
              y={0}
              width={xMax}
              height={ROW_HEIGHT_PIXELS}
              rx={5}
              stroke="#EAECF0"
              strokeWidth={1}
              fillOpacity={0}
            />
            {eventJSX}
          </Group>
        </svg>
      </div>
    </div>
  );
}

EventChartBottomRowCollapsed.propTypes = {
  events: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  colour: PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired,
  xMax: PropTypes.number.isRequired,
  xScale: PropTypes.func.isRequired,
  expand: PropTypes.func.isRequired,
  xAxisTicks: PropTypes.array,
  reveal: PropTypes.func,
};
