import React from 'react';
import PropTypes from 'prop-types';
import './FeedFloToolTip.scss';

/***
 * ? DEPRECATED ?
 * Note: Not recommended to use on _large_ children as % gets thrown off
 */
function FeedFloToolTip({
  children,
  description,
  className = '',
  textClassName = '',
  position = 'top',
  multiline = false,
  darkMode = false,
}) {
  let positionClassName = 'FeedFloToolTip-description--top';

  if (['top', 'bottom', 'left', 'right'].includes(position)) {
    positionClassName = `FeedFloToolTip-description--${position}`;
  } else {
    console.warn(`FeedFloTooltip provided with a non-standard position: "${position}", defaulting to top instead`);
  }

  return (
    <div className={`FeedFloToolTip ${darkMode ? 'FeedFloToolTip--dark' : ''} ${className}`}>
      {children}
      <span
        className={`FeedFloToolTip-description noselect ${
          multiline ? 'FeedFloToolTip-description--multiline' : ''
        } ${positionClassName} ${textClassName}`}
      >
        {description}
      </span>
    </div>
  );
}

FeedFloToolTip.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  position: PropTypes.string,
  multiline: PropTypes.bool,
  darkMode: PropTypes.bool,
};

export default FeedFloToolTip;
