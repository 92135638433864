import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import Page from '../../atoms/Page';
import DeliveryRow from '../../organisms/DeliveryRow/DeliveryRow';

const DELIVERY_GQL = gql`
  query DeliveryQuery($farmId: uuid!) {
    delivery(where: { bin: { farm_id: { _eq: $farmId } } }, order_by: { ordered_at: desc_nulls_first }) {
      id
      created_at
      ordered_at
      delivered_at
      external_id
      status
      weight_in_grams
      feed_profile {
        name
      }
      metadata

      bin {
        name
      }
    }
  }
`;

function DeliveryTab({ titleSegments = [], farmId = null }) {
  const { loading, error, data } = useQuery(DELIVERY_GQL, {
    variables: { farmId },
    skip: !farmId,
  });
  const pageTitleSegments = useMemo(() => ['Deliveries', ...titleSegments], []);

  if (error) return <span>Error {JSON.stringify(error)}</span>;
  if (loading) return <span>Loading...</span>;

  const cards = data?.delivery?.map((delivery) => (
    <DeliveryRow
      key={delivery.id}
      createdAt={delivery.created_at && new Date(delivery.created_at * 1000)}
      orderedAt={delivery.ordered_at && new Date(delivery.ordered_at * 1000)}
      deliveredAt={delivery.delivered_at && new Date(delivery.delivered_at * 1000)}
      externalId={delivery.external_id}
      status={delivery.status}
      weightInGrams={delivery.weight_in_grams}
      feedProfileName={delivery.feed_profile?.name}
      metaData={delivery.metadata}
      binName={delivery.bin?.name}
    />
  ));

  return (
    <Page className="DeliveryPage" titleSegments={pageTitleSegments}>
      <div className="DeliveryCardHolder">{cards}</div>
    </Page>
  );
}

DeliveryTab.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
  farmId: PropTypes.string,
};

export default DeliveryTab;
