import PropTypes from 'prop-types';
import Pill from '../../atoms/Pill';
import './PillGroup.scss';

function PillGroup({ values = [], onUpdateValues = () => {} }) {
  const onRemoveItem = (clickedValue) => {
    const index = values.findIndex((value) => value.text === clickedValue);
    onUpdateValues([...values.slice(0, index), ...values.slice(index + 1)]);
  };

  const onClearAllItems = () => {
    onUpdateValues([]);
  };

  return (
    <div className="PillGroup">
      {values.map((value) => (
        <Pill key={value.text} value={value.text} onRemove={onRemoveItem} />
      ))}
      {values.length ? (
        <div className="PillGroup--clear" onClick={onClearAllItems}>
          Clear
        </div>
      ) : null}
    </div>
  );
}

PillGroup.propTypes = {
  values: PropTypes.array,
  onUpdateValues: PropTypes.func,
};

export default PillGroup;
