import dayjs from 'dayjs';
import { intervalToDuration } from '../../dates';

export default function useFormattedInterval() {
  const formatInterval = (startTime, endTime = null, compact = false) => {
    if (null === endTime) {
      endTime = dayjs.tz().unix();
    }

    const interval = intervalToDuration(startTime, endTime);

    // We don't want to display seconds, so include them in the minutes
    if (interval.seconds) {
      interval.minutes++;
      interval.seconds = 0;
    }

    // If we have are going to start displaying months or years, round off the hours and minutes and exclude them
    if (interval.months || interval.years) {
      if (interval.minutes >= 30) {
        interval.hours++;
      }

      if (interval.hours >= 12) {
        interval.days++;
      }

      interval.hours = 0;
      interval.minutes = 0;
    }

    // Remove any 0 values
    Object.keys(interval).forEach((key) => {
      if (interval[key] === 0) {
        delete interval[key];
      }
    });

    // Helper function to format interval values
    const intervalStrings = [];
    const getIntervalString = (key, value) => {
      if (compact) {
        return `${value}${key.slice(0, 1)}`;
      }

      const unit = value === 1 ? `${key}` : `${key}s`;
      return `${value} ${unit}`;
    };

    // Add a string for each interval if necessary
    if (interval.years) {
      intervalStrings.push(getIntervalString('year', interval.years));
    }

    if (interval.months) {
      intervalStrings.push(getIntervalString('month', interval.months));
    }

    if (interval.days) {
      intervalStrings.push(getIntervalString('day', interval.days));
    }

    const hourIntervalString = getIntervalString('hour', interval.hours);
    const minuteIntervalString = getIntervalString('min', interval.minutes);
    if (interval.hours && interval.minutes) {
      intervalStrings.push(`${hourIntervalString} ${minuteIntervalString}`);
    } else if (interval.hours) {
      intervalStrings.push(hourIntervalString);
    } else if (interval.minutes) {
      intervalStrings.push(minuteIntervalString);
    }

    return intervalStrings;
  };

  return {
    formatInterval,
  };
}
