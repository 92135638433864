import { useEffect, useState, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';

import { USER_INFO_GQL } from './queries';

/**
 * useUser - a hook that grabs the user from Auth0 and fills it with more information that is related
 * to that user such as preferences (metric) and is staff from their hasura roles
 *
 * @returns a filled User object including a loading state
 */
export default function useUser() {
  const { user: auth0User, isLoading: auth0Loading } = useAuth0();
  const [isStaff, setIsStaff] = useState(null);
  const [barns, setBarns] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [defaultRole, setDefaultRole] = useState('user');
  const [allowedRoles, setAllowedRoles] = useState(['user']);
  const [graphQlReady, setGraphQlReady] = useState(false);
  const [auth0Ready, setAuth0Ready] = useState(false);

  const feedfloUserId = auth0User?.['https://hasura.io/jwt/claims']?.['x-hasura-user-id'];

  const {
    loading: graphqlLoading,
    data,
    error,
  } = useQuery(USER_INFO_GQL, {
    fetchPolicy: 'no-cache',
    variables: {
      userID: feedfloUserId,
    },
    skip: !feedfloUserId,
  });

  useEffect(() => {
    if (!auth0Loading && auth0User) {
      // temporary more complicated logic until we know everyone has the right role
      const roleIsResearcher = auth0User['https://feedflo.com/roles']?.includes('researcher');
      const roleIsStaff = auth0User['https://feedflo.com/roles']?.includes('feedflo_staff');
      const allowedRoles = auth0User['https://hasura.io/jwt/claims']?.['x-hasura-allowed-roles'] || [];

      // Just some sanity checking
      if (roleIsResearcher && !roleIsStaff) {
        console.log('%c⚠️ Using a Legacy Role: researcher', 'color:#F7CB15; background-color: #D96C06;');
      } else if (roleIsStaff) {
        console.log('%c✅ Using Modern Role: feedflo_staff', 'color:green;');
      }

      setIsStaff(roleIsResearcher || roleIsStaff);
      setDefaultRole(auth0User['https://hasura.io/jwt/claims']?.['x-hasura-default-role']);
      setAllowedRoles([...allowedRoles]);
      setAuth0Ready(true);
    }
  }, [auth0Loading, auth0User]);

  useEffect(() => {
    if (error) {
      console.error(error);
      // Reset everything if there's an error
      setIsStaff(false);
    } else {
      if (!graphqlLoading && data) {
        // Assign data
        const accessibleBarns = data?.user?.user_barns?.map((user_barn) => user_barn.barn_id) || [];
        const accessibleOrgs = data?.user?.user_organizations?.map((user_org) => user_org.organization_id) || [];

        setBarns([...accessibleBarns]);
        setOrganizations([...accessibleOrgs]);
        setGraphQlReady(true);
      }
    }
    // else we're still loading
  }, [graphqlLoading, data, error]);

  const hookResult = useMemo(
    () => {
      const dbUser = data?.user;
      return {
        user: {
          ...auth0User,
          id: feedfloUserId,

          // DB User related
          // NOTE: Most of this is in the auth0User already BUT
          // we currently don't sync that data, we'll let the DB take priority for now
          name: dbUser?.name,
          phoneNumber: dbUser?.phoneNumber,
          avatar: dbUser?.avatar,
          email: dbUser?.email,
          isMetric: dbUser?.isMetric,
          timezone: dbUser?.timezone || dayjs.tz.guess(),

          // permissions stuff
          isStaff,
          barns,
          organizations,
          // Hasura stuff
          defaultRole,
          allowedRoles,
        },
        // lots of loading states to make sure things have settled
        loading: graphqlLoading || auth0Loading || !(auth0Ready && graphQlReady),
      };
    },
    // Depends on pretty much anything changing
    [
      data,
      auth0User,
      isStaff,
      barns,
      organizations,
      defaultRole,
      allowedRoles,
      graphqlLoading,
      auth0Loading,
      graphQlReady,
      auth0Ready,
    ],
  );

  return hookResult;
}
