import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Page from '../../atoms/Page';
import DeviceFirmwareController from './DeviceFirmwareController';
import FirmwareBranchController from './FirmwareBranchController';
import FirmwareUploadController from './FirmwareUploadController';
import './FirmwarePage.scss';

function FirmwarePage({ titleSegments = [] }) {
  const pageTitleSegments = useMemo(() => ['Firmware Control', ...titleSegments], []);

  return (
    <Page className="FirmwarePage" titleSegments={pageTitleSegments}>
      <h3>Flo Devices:</h3>
      <DeviceFirmwareController />
      <h3>Upload New Firmware:</h3>
      <FirmwareUploadController />
      <h3>Firmware Branches:</h3>
      <FirmwareBranchController />
    </Page>
  );
}

FirmwarePage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
};

export default FirmwarePage;
