import dayjs from 'dayjs';
import useUser from '../useUser';
import { useMemo } from 'react';

export default function useDefaultDateRange(barnId, days = 30) {
  const { user } = useUser();
  return useMemo(() => {
    const from = dayjs()
      .tz(user.timezone)
      .subtract(days - 1, 'd')
      .startOf('day');
    const to = dayjs().tz(user.timezone).endOf('day');

    switch (barnId) {
      case '39357f50-4520-4417-ba97-85ae5969d862': // Good Test Barn
        return { from: dayjs.tz('2022/02/13'), to: dayjs.tz('2022/02/27').subtract(1, 'seconds') };
      case 'dd56825e-aed5-4a11-8d85-27fcd3847bd5': // Bad Test Barn
        return { from: dayjs.tz('2021/10/31'), to: dayjs.tz('2021/11/17').subtract(1, 'seconds') };
      default:
        return { from, to };
    }
  }, [user.timezone]);
}
