import PropTypes from 'prop-types';

import EventChartBottomRow from './EventChartBottomRow';

/**
 * The bottom row component for the event chart.
 * Not meant to be imported on its own.
 */
export default function EventChartBottom({ events, rows, ...props }) {
  const eventsByRow = events.reduce((eventsByRow, event) => {
    if (!Array.isArray(eventsByRow[event.row])) {
      eventsByRow[event.row] = [event];
    } else {
      eventsByRow[event.row].push(event);
    }
    return eventsByRow;
  }, []);

  return (
    <div className="EventChartBottom">
      {rows.map((row, index) => {
        return (
          <EventChartBottomRow
            {...props}
            events={eventsByRow[index] || []}
            id={row.id || index}
            label={row.label || ''}
            colour={row.colour || ''}
            tooltip={row.tooltip}
            hidden={row.hidden || false}
            reveal={row.reveal}
            key={index}
          />
        );
      })}
    </div>
  );
}

EventChartBottom.propTypes = {
  events: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  xAxisTicks: PropTypes.array,
  xMax: PropTypes.number.isRequired,
  xScale: PropTypes.func.isRequired,
};
