import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { KeyIcon } from '../../atoms/Icons';
import FeedFloTextInput from '../../atoms/FeedFloTextInput';
import { createGraphiQLFetcher } from '@graphiql/toolkit';
import { getIntrospectionQuery } from 'graphql';
import { GraphiQL } from 'graphiql';
import StatusBadge from '../../atoms/StatusBadge';
import 'graphiql/graphiql.css';

const defaultQuery = `# Getting Started: 
# To use the following examples uncomment
# the queries one at a time and modify as needed. 
#
# To learn more about GraphQL check out:
# https://graphql.org/learn/
# and 
# https://graphql.org/learn/queries/#variablesi
# Note: barns are currently named farms in our system,
#       these example queries alias the results to "barn"

## View Barn Example 
#query viewAllBarnsAndBins {
#  barn: farm {
#    name
#    bins {
#      name
#      capacity_in_grams
#    }
#  }
#}


## View Deliveries
## This example shows how to order items and only return a few.
## reminder: all timestamps (fields ending with _at are in unix timestamps)
#query viewDeliveries {
#  barn: farm {
#    name
#    bins {
#      deliveries(order_by: {delivered_at: desc_nulls_first}, limit:5) {
#        weight_in_grams
#      }
#    }
#  }
#}

## View Feed Consumption
#query viewFeedConsumption {
#  barn: farm {
#    name
#    feed_lines {
#      feed_events {
#        feed_in_grams
#      }
#    }
#  }
#}
`;

export default function APITester() {
  const [apiKey, setApiKey] = useState('');
  const [hasValidToken, setHasValidToken] = useState(false);
  const [tokenDescription, setTokenDescription] = useState('Unknown API Token');
  const fetcher = createGraphiQLFetcher({
    url: 'https://api.internal.feedflo.com/v1/graphql',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiKey}`,
    },
  });

  useEffect(async () => {
    const data = await fetch('https://api.internal.feedflo.com/v1/graphql', {
      method: 'POST',
      body: JSON.stringify({ query: getIntrospectionQuery() }),
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const apiTestData = await data.json();
    if (apiTestData.data) {
      setHasValidToken(true);
      setTokenDescription('Valid Token');
    } else if (apiTestData.errors) {
      setHasValidToken(false);

      if (apiKey.length > 0) {
        if (apiTestData.errors[0].message) setTokenDescription(apiTestData.errors[0].message);
        else setTokenDescription('Unknown Error');
      } else {
        setTokenDescription('Empty API Token');
      }
    }
  }, [apiKey]);

  return (
    <div className="ApiAccessPage-tester">
      <div className="ApiAccessPage-APIKeyField">
        <div className="ApiAccessPage-APIKeyField-input-holder">
          <FeedFloTextInput
            defaultText="Your Access Token here"
            icon={<KeyIcon />}
            onChange={(result) => setApiKey(result)}
          />
        </div>
        <div className="ApiAccessPage-APIKeyField-status-holder">
          <StatusBadge text={tokenDescription} status={hasValidToken ? 'success' : 'error'} />
        </div>
      </div>
      <div className="ApiAccessPage-footer">
        <div className="ApiAccessPage-graphiQL">
          <GraphiQL className="ApiAccessPage-editor" fetcher={fetcher} defaultQuery={defaultQuery} />
        </div>
      </div>
    </div>
  );
}

APITester.propTypes = {
  currentTokenState: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
