import React from 'react';
import * as Sentry from '@sentry/react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes, Routes } from 'react-router-dom';

// Setup Variables
const TRACES_SAMPLE_RATE = Number.parseFloat(import.meta.env.VITE_TRACES_SAMPLE_RATE) || 1.0;
const REPLAYS_SESSION_SAMPLE_RATE = Number.parseFloat(import.meta.env.VITE_REPLAYS_SESSION_SAMPLE_RATE) || 0.1;
const REPLAYS_ON_ERROR_SAMPLE_RATE = Number.parseFloat(import.meta.env.VITE_REPLAYS_ON_ERROR_SAMPLE_RATE) || 1.0;
const DISABLE_SENTRY = 'true' === import.meta.env.VITE_DISABLE_SENTRY || false;
const RELEASE_VERSION = import.meta.env.VITE_GIT_FULL_HASH;

let hasBeenSetup = false;

function determineEnvironment() {
  // hostname gives us localhost. host would give us localhost:3000
  const subDomain = window.location.hostname.split('.')[0]; // subodmain OR localhost

  switch (subDomain) {
    case 'localhost':
      return 'local-dev';
    case 'my':
      return 'production';
    case 'dev':
      return 'development';
    default:
      return 'unknown';
  }
}

export default function setupSentry() {
  if (hasBeenSetup) {
    console.log('Already set up sentry');
    return;
  }
  const subDomain = window.location.hostname.split('.')[0]; // subodmain OR localhost

  if (DISABLE_SENTRY || subDomain === 'localhost') {
    console.log('Senty Disabled!');
    return;
  }
  Sentry.init({
    /* 
    "DSNs are safe to keep public because they only allow submission of new events and related event data;
     they do not allow read access to any information."
    source: https://docs.sentry.io/product/sentry-basics/concepts/dsn-explainer/
    */
    dsn: 'https://f10bed2205f48231f736781a49591628@o4505671570096128.ingest.us.sentry.io/4507053005209600',
    environment: determineEnvironment(),
    release: `web@${RELEASE_VERSION}`,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: TRACES_SAMPLE_RATE,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // Should capture local dev (unless disabled via VITE_DISABLE_SENTRY
    // and both dev and prod websites
    tracePropagationTargets: ['localhost', /^https:\/\/(my|dev)\.feedflo\.com/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: REPLAYS_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate: REPLAYS_ON_ERROR_SAMPLE_RATE,
  });

  console.log(`Sentry initialized for environment ${determineEnvironment()} @ ${RELEASE_VERSION}`);
  // Flip toggle
  hasBeenSetup = true;
}

export function setupSentryRoutes() {
  setupSentry();

  // safe fallback if it hasn't been setup properly
  if (hasBeenSetup) {
    return Sentry.withSentryReactRouterV6Routing(Routes);
  } else {
    return Routes;
  }
}

export function setSentryUser(id, isStaff, orgIds) {
  if (hasBeenSetup) {
    // since sentry has been initialized let's setup the user
    // and throw in some other useful session info
    Sentry.setUser({
      id,
    });
    Sentry.setTags({
      isStaff,
      organizations: orgIds?.toString(),
    });
  }
}

export function clearSentryUser() {
  if (hasBeenSetup) {
    Sentry.setUser(null);
  }
}
