import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import FeederEditorDialogView from './FeederEditorDialogView';

/***
 * ? NEEDS REVIEW ?
 */
function FeederEditorDialog({ feedLines = [], editFeeder = null, onCancel = () => {}, onSave = () => {} }) {
  return ReactDOM.createPortal(
    <FeederEditorDialogView feedLines={feedLines} editFeeder={editFeeder} onCancel={onCancel} onSave={onSave} />,
    document.getElementById('portal-root'),
  );
}

FeederEditorDialog.propTypes = {
  feedLines: PropTypes.array,
  editFeeder: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default FeederEditorDialog;
