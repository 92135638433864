import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import { censorTypes, useCensor } from '../../utils/hooks/useCensor/useCensor';
import LoadingSkeleton from '../LoadingSkeleton';
import './PhoneNumberInput.scss';

// Workaround to fix an export that isn't valid with Vite
// https://github.com/vitejs/vite/issues/2139#issuecomment-1024852072
const PhoneInputComponent = PhoneInput.default || PhoneInput;

function PhoneNumberInput({ loading = false, label = '', placeholder = '', value = '', onChange = () => {} }) {
  const { censor, isCensorActive } = useCensor();

  if (loading) {
    return (
      <div className="PhoneNumberInput">
        <LoadingSkeleton className="PhoneNumberInput-label--loader" />
        <LoadingSkeleton className="PhoneNumberInput-input--loader" />
      </div>
    );
  }

  return (
    <div className="PhoneNumberInput">
      <label className="PhoneNumberInput-label">{label}</label>
      {isCensorActive ? (
        <div>{censor(value, censorTypes.pii)}</div>
      ) : (
        <PhoneInputComponent
          value={value}
          priority={{ ca: 0, us: 1 }}
          placeholder={placeholder}
          isValid={(inputNumber) => {
            const regex = /^(?:[0-9] ?){6,14}[0-9]$/;
            return regex.test(inputNumber);
          }}
          onChange={onChange}
        />
      )}
    </div>
  );
}

PhoneNumberInput.propTypes = {
  loading: PropTypes.bool,
  isCensored: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default PhoneNumberInput;
