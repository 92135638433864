import { PurposeEnum, SourceEnum, MethodEnum, MethodEnumConverter } from './enums';

export const purposeList = [
  {
    name: 'Test',
    value: PurposeEnum.Test,
  },
  {
    name: 'Reset',
    value: PurposeEnum.Reset,
  },
  {
    name: 'Calibrate',
    value: PurposeEnum.Calibrate,
  },
];

export const sourceList = [
  {
    name: 'Farmer',
    value: SourceEnum.Farmer,
  },
];

export const methodList = [
  {
    name: MethodEnumConverter[MethodEnum.BangCheck],
    value: MethodEnum.BangCheck,
  },
  {
    name: MethodEnumConverter[MethodEnum.BinClimb],
    value: MethodEnum.BinClimb,
  },
  {
    name: MethodEnumConverter[MethodEnum.Empty],
    value: MethodEnum.Empty,
  },
  {
    name: MethodEnumConverter[MethodEnum.Overfill],
    value: MethodEnum.Overfill,
  },
  {
    name: MethodEnumConverter[MethodEnum.Other],
    value: MethodEnum.Other,
  },
];
