export const statusOptions = [
  { id: 'active', text: 'Active' },
  { id: 'resolved', text: 'Resolved' },
];
export const typeOptions = [
  { id: 'empty pipe', text: 'Empty Pipe' },
  { id: 'inactive auger', text: 'Inactive Auger' },
  { id: 'sudden drop', text: 'Sudden Drop' },
  { id: 'trending down', text: 'Trending Down' },
];
export const durationOptions = [
  { id: 'minor', text: 'Minor', subtext: '(<12 hours)' },
  { id: 'significant', text: 'Significant', subtext: '(12 - 24 hours)' },
  { id: 'severe', text: 'Severe', subtext: '(24+ hours)' },
];
export const noFeedTimeOptions = [
  { id: 'na', text: 'N/A', subtext: '(not available)' },
  { id: 'none', text: 'None', subtext: '(0 hours)' },
  { id: 'minor', text: 'Minor', subtext: '(0 - 12 hours)' },
  { id: 'significant', text: 'Significant', subtext: '(12 - 24 hours)' },
  { id: 'severe', text: 'Severe', subtext: '(24+ hours)' },
];
export const rootCauseOptions = [{ id: 'unidentified', text: 'Unidentified' }];
export const dateOptions = [
  { text: 'Past 7 days', value: 7 },
  { text: 'Past 30 days', value: 30 },
  { text: 'Past 90 days', value: 90 },
  { text: 'Custom', value: 0 },
];
