import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ListOfButtons from './ListOfButtons';
import { ReportIcon, HomeIcon, AlertIcon, MoneyIcon, EKGIcon, ExportIcon, KeyIcon, TruckIcon } from '../../atoms/Icons';
import { useAtomValue } from 'jotai';
import { algorithmOverriddenAtom, algorithmVersionAtom, featureFlagsAtom } from '../../utils/jotaiAtoms';
import useFeature from '../../utils/hooks/useFeature';
import useUser from '../../utils/hooks/useUser';

import './SideBar.scss';

const BUTTONS = [
  {
    url: '/',
    icon: <HomeIcon />,
    text: 'My Barns',
    // Match either "/" or "/b/[some UUID]/[anything]"
    pathMatch: /^\/$|^\/b\/[0-9a-f]{8}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{12}\/.+$/,
    mobile: true,
  },
  {
    url: '/alerts?p=7&s=active&sortCol=nofeed_time&sortLevel=0&t=empty+pipe&t=inactive+auger&hideCharts=true&r=d906b529-865c-413f-824f-74005c7a0d63&r=01bc6865-8403-4961-8587-1685449e0920&r=09aa8f2a-7272-41c7-bb07-0203fde9e2cb&r=97d7b205-6fba-48a9-a8c2-49629698e92f&r=192c621a-16e4-4e8f-8657-4549c2605643&r=ea7bba00-23e1-40f7-83d6-dacebb5683ad&r=a758a08d-76ec-47f5-93c3-b1b1de495653&r=e91aeeba-1350-4ea4-8d04-81f7d4802a40&r=850b86a5-9e19-4a27-8b4a-3d3dca080e09&r=unidentified',
    icon: <AlertIcon />,
    text: 'Live Alert Hub',
    pathMatch:
      /alerts\?p=7&s=active&sortCol=nofeed_time&sortLevel=0&t=empty\+pipe&t=inactive\+auger&hideCharts=true/gimu,
    mobile: false,
  },
  {
    url: '/FeedDesk',
    icon: <TruckIcon />,
    text: 'Feed Desk',
    pathMatch: /^\/FeedDesk$/,
    mobile: false,
  },
  {
    url: '/alerts?p=90&n=severe&dataType=nofeed_time&t=empty+pipe&t=inactive+auger&r=d906b529-865c-413f-824f-74005c7a0d63&r=01bc6865-8403-4961-8587-1685449e0920&r=09aa8f2a-7272-41c7-bb07-0203fde9e2cb&r=97d7b205-6fba-48a9-a8c2-49629698e92f&r=192c621a-16e4-4e8f-8657-4549c2605643&r=ea7bba00-23e1-40f7-83d6-dacebb5683ad&r=a758a08d-76ec-47f5-93c3-b1b1de495653&r=e91aeeba-1350-4ea4-8d04-81f7d4802a40&r=850b86a5-9e19-4a27-8b4a-3d3dca080e09&r=unidentified',
    icon: <ReportIcon />,
    text: 'Management Report',
    pathMatch: /alerts\?p=90&n=severe&dataType=nofeed_time&t=empty\+pipe&t=inactive\+auger/gimu,
    mobile: false,
  },
  // {
  //   url: '/map',
  //   icon: <MapIcon />,
  //   text: 'Barn Map',
  //   pathMatch: /^\/map$/,
  //   mobile: true,
  // },
  {
    url: '/exports',
    icon: <ExportIcon />,
    text: 'Data Export',
    pathMatch: /^\/exports$/,
    mobile: true,
  },
  {
    url: '/apiAccess',
    icon: <KeyIcon />,
    text: 'Developers',
    pathMatch: /^\/apiAccess$/,
    mobile: true,
  },
];

const STAFF_ONLY_BUTTONS = [
  {
    url: '/deviceStatus',
    icon: <HomeIcon />,
    text: 'IoT Dashboard',
    pathMatch: /^\/deviceStatus$/,
    mobile: true,
  },
  {
    url: '/firmware',
    icon: <MoneyIcon />,
    text: 'Firmware Control',
    pathMatch: /^\/firmware$/,
    mobile: true,
  },
  {
    url: '/devices?name=Default+List&sort=nameAsc',
    icon: <KeyIcon />,
    text: 'Devices',
    pathMatch: /^\/devices$/,
    mobile: true,
  },
  {
    url: '/accuracyScorePage',
    icon: <EKGIcon />,
    text: 'Accuracy Score',
    pathMatch: /^\/accuracyScorePage$/,
    mobile: true,
  },
  {
    url: '/alerts?p=90&dataType=alerts&r=91f44d1c-af6d-491e-a027-fdd000b3676a&r=aedb6c4b-7715-4ef9-96fd-d122cca27fc0&r=01bc6865-8403-4961-8587-1685449e0920&r=ea7bba00-23e1-40f7-83d6-dacebb5683ad',
    icon: <AlertIcon />,
    text: 'Bug Monitor',
    pathMatch:
      /alerts\?p=90&dataType=alerts&r=91f44d1c-af6d-491e-a027-fdd000b3676a&r=aedb6c4b-7715-4ef9-96fd-d122cca27fc0&r=01bc6865-8403-4961-8587-1685449e0920&r=ea7bba00-23e1-40f7-83d6-dacebb5683ad/gimu,
    mobile: false,
  },
];

function SideBarView({ open, onClickOutside, devInfoClassName = '' }) {
  const { user } = useUser();
  const algorithmVersion = useAtomValue(algorithmVersionAtom);
  const featureFlags = useAtomValue(featureFlagsAtom);
  const algorithmOveridden = useAtomValue(algorithmOverriddenAtom);
  const { active: isDeveloper } = useFeature('DEVELOPER_MODE');
  const sideBarRef = useRef(null);

  const activeFlagCount = featureFlags?.filter((x) => x.active)?.length || -1;
  const activeFlagNames = featureFlags?.filter((f) => f.active)?.map((f) => f.name) || [];
  const showStaffButtons = user?.isStaff;

  const algorithmDisplayVersion = algorithmOveridden
    ? algorithmVersion.substring(0, 24)
    : algorithmVersion?.substring(0, 7);
  const appDisplayVersion = import.meta.env.VITE_GIT_SHORT_HASH;

  const handleClickOutside = (event) => {
    if (sideBarRef.current && !sideBarRef.current.contains(event.target)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div ref={sideBarRef} className={`SideBarView ${open ? 'open' : ''}`}>
      <ListOfButtons buttons={BUTTONS} staffOnlybuttons={showStaffButtons ? STAFF_ONLY_BUTTONS : []} />
      <div className={`SideBarView-devInfo ${devInfoClassName}`}>
        <div className="SideBarView-frontendVersion">
          <label>App Version </label>
          <span>{appDisplayVersion}</span>
        </div>
        <div className="SideBarView-algVersion" title={algorithmVersion}>
          <label>Alg Version </label>
          <span>{algorithmDisplayVersion || 'Loading...'}</span>
        </div>
        {isDeveloper && <div className="SideBarView-devModeLabel">DEVELOPER MODE ACTIVE</div>}
        {activeFlagCount > 0 && (
          <Link to="/settings">
            <div className="SideBarView-flagsActiveLabel" title={activeFlagNames.join(', ')}>
              {activeFlagCount} Flag{activeFlagCount > 1 ? 's' : ''} Active
            </div>
          </Link>
        )}
      </div>
    </div>
  );
}

SideBarView.propTypes = {
  open: PropTypes.bool,
  onClickOutside: PropTypes.func,
  devInfoClassName: PropTypes.string,
};

export default SideBarView;
