import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FilterRow from './FilterRow';
import './FilterList.scss';

const NUM_LOADING_ROWS = 10;

function FilterList({
  loading = false,
  filters = [],
  lastToggledIndex: lastToggledIndexProp = null,
  onUpdateLastToggledIndex = () => {},
  onUpdateFilters = () => {},
}) {
  const [lastToggledIndex, setLastToggledIndex] = useState(lastToggledIndexProp === null ? -1 : lastToggledIndexProp);

  useEffect(() => {
    setLastToggledIndex(lastToggledIndexProp);
  }, [lastToggledIndexProp]);

  const onToggleFilter = (e, toggledFilterValue, toggledFilterIndex) => {
    const lowerBound = Math.min(lastToggledIndex, toggledFilterIndex);
    const upperBound = Math.max(lastToggledIndex, toggledFilterIndex);
    const isShiftPressed = e.nativeEvent.shiftKey;

    const updatedFilters = filters.map((filter, i) => {
      const isToggledValue = filter.value === toggledFilterValue;
      const isInRange = lowerBound >= 0 && i >= lowerBound && i <= upperBound;
      let enabled = isToggledValue ? !filter.enabled : filter.enabled;

      // If toggling a range of filters, set their enabled state to the updated value for the toggled filter
      // For example, if the clicked filter is being toggled off, then toggle off all filters in range
      if (filter.visible && isShiftPressed && isInRange) {
        enabled = !filters[toggledFilterIndex].enabled;
      }

      return {
        ...filter,
        enabled,
      };
    });

    setLastToggledIndex(toggledFilterIndex);
    onUpdateLastToggledIndex(toggledFilterIndex);
    onUpdateFilters(updatedFilters);
  };

  if (loading) {
    return Array(NUM_LOADING_ROWS)
      .fill()
      .map((_, i) => <FilterRow key={i} loading={loading} />);
  }

  return (
    <div className="FilterList">
      {filters.map((filter, i) => (
        <FilterRow
          key={filter.value}
          color={filter.color}
          label={filter.label}
          value={filter.value}
          index={i}
          isEnabled={filter.enabled}
          isVisible={filter.visible}
          onToggleFilter={onToggleFilter}
        />
      ))}
    </div>
  );
}

FilterList.propTypes = {
  loading: PropTypes.bool,
  filters: PropTypes.array,
  lastToggledIndex: PropTypes.number,
  onUpdateLastToggledIndex: PropTypes.func,
  onUpdateFilters: PropTypes.func,
};

export default FilterList;
