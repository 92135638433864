import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ArrowIcon } from '../Icons';
import LoadingSkeleton from '../LoadingSkeleton';
import './FeedFloDropDown.scss';

/***
 * ? DEPRECATED ?
 */
function FeedFloDropDown({
  className = '',
  inputClassName = '',
  loading = false,
  label = null,
  list = [],
  defaultTitle = '',
  defaultSelected = null,
  disabled = false,
  onChange = () => {},
}) {
  const [dropDownList, setDropDownList] = useState(list);
  const [currSelected, setCurrSelected] = useState(defaultSelected);
  const [toggleItem, setToggleItem] = useState(false);
  const dropRef = useRef(null);

  if (list.length !== dropDownList.length) {
    setDropDownList(list);
  }

  const dropDownItemMap = {};
  dropDownList.forEach((item) => {
    dropDownItemMap[item.id] = item;
  });

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (dropRef.current && !dropRef.current.contains(event.target)) {
        setToggleItem(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropRef]);

  useEffect(() => {
    list.forEach((item) => {
      if (item.selected || (defaultSelected && item?.id === defaultSelected)) {
        setCurrSelected(item.id);
      }
    });
    setDropDownList(list);
  }, [list, defaultSelected]);

  function resetThenSet(clickedId) {
    const updatedList = list.map((l) => {
      return {
        id: l.id,
        name: l.name,
        selected: clickedId === l.id,
      };
    });

    setDropDownList(updatedList);
  }

  function handleItemClicked(item) {
    resetThenSet(item.id);
    setCurrSelected(item.id);
    onChange(item);
  }

  function handleHeaderClicked() {
    if (!disabled) {
      setToggleItem(!toggleItem);
    }
  }

  const listItem = dropDownList.map((item) => {
    return (
      <div
        key={item.id}
        onClick={() => handleItemClicked(item)}
        className={`listItem ${item.selected ? 'selected' : ''}`}
      >
        {item.name}
      </div>
    );
  });

  if (loading) {
    return (
      <div className="DropDown">
        <LoadingSkeleton className="DropDown-label--loader" />
        <LoadingSkeleton className="DropDown-select--loader" />
      </div>
    );
  }

  return (
    <div ref={dropRef} className={`DropDown noselect ${className}`}>
      <div className={`${disabled || list.length === 0 ? 'disabled' : ''}`} onClick={() => handleHeaderClicked()}>
        {label && <label className={`${disabled || list.length === 0 ? 'disabled' : ''}`}>{label}</label>}
        <div className={`DropDown-header ${disabled || list.length === 0 ? 'disabled' : ''} ${inputClassName}`}>
          <div className={`selected ${disabled || list.length === 0 ? 'disabled' : ''}`}>
            {dropDownItemMap[currSelected]?.name || defaultTitle}
          </div>
          <div className={`icon ${toggleItem ? 'showList' : ''} ${disabled || list.length === 0 ? 'disabled' : ''}`}>
            <ArrowIcon />
          </div>
        </div>
        {toggleItem && <div className={`list ${label ? 'with-label' : ''}`}>{listItem}</div>}
      </div>
    </div>
  );
}

FeedFloDropDown.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  loading: PropTypes.bool,
  label: PropTypes.string,
  list: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  defaultTitle: PropTypes.string,
  defaultSelected: PropTypes.string,
};

export default FeedFloDropDown;
