import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './FeedFloCheckBox.scss';

/***
 * ? DEPRECATED / NEEDS REVIEW ?
 */
function FeedFloCheckBox({ defaultChecked = false, onChange = () => {} }) {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  useEffect(() => {
    setIsChecked(defaultChecked);
  }, [defaultChecked]);

  const onCheck = (e) => {
    onChange(e.target.checked);
  };

  return <input className="FeedFloCheckBox" type="checkbox" checked={isChecked} onChange={(e) => onCheck(e)} />;
}

FeedFloCheckBox.propTypes = {
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default FeedFloCheckBox;
