import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';

// ours
import { CircledCheckmarkIcon } from '../../atoms/Icons';
import LabelledColumn from '../../atoms/LabelledColumn';
import Button from '../../atoms/Button';
import TextInput from '../../atoms/TextInput/TextInput';
import './SingleFieldForm.scss';

const SuccessUpdated = () =>
  toast.success('Successfully Updated', {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const ErrorOccurred = () =>
  toast.warn('Error Occurred', {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

/**
 * A small component that lets you view and save a single column in Hasura by primary key
 */
export default function SingleFieldForm({
  fieldLabel, // If you want to display something different than the hasura name
  placeholder, // Overrides fieldLabel for the inline placeholder if needed
  fieldHasuraName, // name of the column in hasura
  fieldTableName, // name of the table in hasura
  rowId, // since you want to update you MUST provide a primary key, which MUST be the 'id' column (for now)
  onSuccess = () => {},
  onError = () => {},
  // basic/expected stuff
  className = null,
  inputWrapperClassName = '',
  textInputClassName = '',
  buttonClassName = '',
  style = {},
}) {
  // Visually show the
  const [showSuccess, setShowSuccess] = useState(false);
  useEffect(() => {
    let timerId = null;

    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
        // switch back to button after 3.5s
      }, 3500);
    }

    // cleanup timer
    return () => clearTimeout(timerId);
  }, [showSuccess]);
  // What are we showing people?
  const displayName = fieldLabel || fieldHasuraName;

  // Set up a dynamic query
  const fieldQuery = gql`query SingleFieldForm_${fieldHasuraName}($rowId: uuid!) {
	${fieldTableName}_by_pk(id: $rowId) {
		${fieldHasuraName}
	}
  }`;

  // set up a dynamic mutation
  const fieldMutation = gql`
    mutation SingleFieldForm_Update${fieldTableName}_${fieldHasuraName}($pk_columns: ${fieldTableName}_pk_columns_input!, $newFieldValue: String = "") {
      update_${fieldTableName}_by_pk(pk_columns: $pk_columns, _set: { ${fieldHasuraName}: $newFieldValue }) {
        ${fieldHasuraName}
      }
    }
  `;

  // get us the initial values to display
  const { data, loading } = useQuery(fieldQuery, {
    variables: {
      rowId,
    },
  });

  // generate the save function
  const [saveField, { loading: savingField }] = useMutation(fieldMutation);

  // initial values extracted
  const initFieldValue = data?.[`${fieldTableName}_by_pk`]?.[fieldHasuraName];

  // simple loader
  if (loading) return <div>Loading...</div>;

  return (
    <Formik
      initialValues={{
        [fieldHasuraName]: initFieldValue || '',
      }}
      onSubmit={async (values, formikBag) => {
        // save by sending the mutation then fire callbacks
        await saveField({
          variables: {
            pk_columns: { id: rowId },
            newFieldValue: values[fieldHasuraName],
          },
          onCompleted: (data) => {
            if (!data?.[`update_${fieldTableName}_by_pk`]) {
              // if nothing is returned for this request it must have errored
              onError(data); // dev provided callback
              ErrorOccurred(); // toast
            } else {
              // Setting the value for the field in case they differ.
              formikBag.setValues({ [fieldHasuraName]: data?.[`update_${fieldTableName}_by_pk`]?.[fieldHasuraName] });

              // First arg is probably what you want as a dev, but providing whole object just in case
              onSuccess(data?.[`update_${fieldTableName}_by_pk`]?.[fieldHasuraName], data); // dev provided callback
              setShowSuccess(true);
              SuccessUpdated(); // toast
            }
          },
          onError: (error) => {
            onError(error); // dev provided callback
            // TODO: (future, minor). We could display an error below the field but we'll see if that's more useful than the toast
            ErrorOccurred(); // toast
          },
        });
      }}
    >
      {({ isSubmitting, errors }) => (
        <Form className={[`SingleFieldForm`, className].join(' ')} style={style}>
          <LabelledColumn
            heading={displayName}
            loading={loading}
            className={`SingleFieldForm-labelledColumn ${inputWrapperClassName}`}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Field
                as={TextInput}
                errors={errors?.[fieldHasuraName]}
                name={fieldHasuraName}
                placeholder={placeholder || displayName}
                disabled={isSubmitting || savingField}
                inputClassName={`SingleFieldForm-textInput ${textInputClassName}`}
                right={
                  <Button
                    disabled={isSubmitting || showSuccess}
                    type="submit"
                    variant="vivid"
                    color="success"
                    content={showSuccess ? <CircledCheckmarkIcon className="SingleFieldForm-checkmark" /> : 'Save'}
                    className={`SingleFieldForm-saveButton ${buttonClassName}`}
                  />
                }
              />
            </div>
          </LabelledColumn>
        </Form>
      )}
    </Formik>
  );
}

SingleFieldForm.propTypes = {
  fieldLabel: PropTypes.string,
  fieldHasuraName: PropTypes.string.isRequired,
  fieldTableName: PropTypes.string.isRequired,
  rowId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  className: PropTypes.string,
  inputWrapperClassName: PropTypes.string,
  textInputClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};
