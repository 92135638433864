import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import FeedFloButton from '../../atoms/FeedFloButton';
import FeedFloTextInput from '../../atoms/FeedFloTextInput';
import FeedFloDropDown from '../../atoms/FeedFloDropDown';
import { ExportIcon } from '../../atoms/Icons';

const FW_BRANCH_GQL = gql`
  query GetFirmwareBranchQuery {
    firmware_branch(where: { deleted_at: { _is_null: true } }) {
      name
      id
      firmwares(where: { deleted_at: { _is_null: true } }) {
        tag
        name
        created_at
      }
    }
  }
`;

export default function FirmwareUploadController() {
  const [name, setName] = useState(null);
  const [tag, setTag] = useState(null);
  const [firmwareBranchId, setFirmwareBranchId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const { loading, error, data } = useQuery(FW_BRANCH_GQL);
  const firmwareBranches = data?.firmware_branch;

  const SuccessUpdated = () =>
    toast.success('Successfully Uploaded', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const ErrorOccurred = () =>
    toast.warn('Error Occurred', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const fileChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const uploadFW = async () => {
    setUploading(true);
    const formData = new FormData();

    formData.append('fwfile', selectedFile);
    formData.append('tag', tag);
    formData.append('fwName', name);
    formData.append('firmware_branch_id', firmwareBranchId);
    const accessToken = await getAccessTokenSilently();

    fetch('https://feedflo-production-functions.azurewebsites.net/api/FirmwareUpload', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then(() => {
        SuccessUpdated();
      })
      .catch((error) => {
        console.error('Error:', error);
        ErrorOccurred();
      });
  };

  if (loading) return <span>Loading</span>;
  if (error) return <span>Error {error}</span>;

  const list = Object.keys(firmwareBranches).map((key) => {
    const fwBranch = firmwareBranches[key];
    return {
      id: fwBranch.id,
      name: fwBranch.name,
      selected: false,
    };
  });

  return (
    <div className="FirmwareUploadController">
      <div className="row">
        <div className="grow">
          <FeedFloTextInput label="Name" onChange={setName} />
        </div>
        <div className="grow">
          <FeedFloTextInput label="Tag" onChange={setTag} />
        </div>
        <div className="grow">
          <FeedFloDropDown
            list={list}
            defaultTitle="None"
            label="FW Branch"
            onChange={(item) => {
              setFirmwareBranchId(item.id);
            }}
          />
        </div>
        <div>
          <input type="file" name="file" onChange={fileChangeHandler} />
        </div>
        <div>
          <FeedFloButton onClick={uploadFW} disabled={uploading}>
            <div className="FeedFloButton-icon">
              <ExportIcon />
            </div>
            <div className="text">Upload</div>
          </FeedFloButton>
        </div>
      </div>
    </div>
  );
}
