import CopyButton from '../atoms/CopyButton';
import useFeature from './hooks/useFeature';
import useUser from './hooks/useUser';

export default function withStaffOnlyIdWrapper(children) {
  const IdWrappedComponent = (props) => {
    const { user } = useUser();
    const { active: showButtons } = useFeature('DISPLAY_COPY_ID_BUTTONS');

    // Wrapping the string due to issues with Google Translate
    const wrappedChildren = typeof children === 'string' ? <span>{children}</span> : children;

    if (showButtons && user?.isStaff) {
      return <CopyButton {...props}>{wrappedChildren}</CopyButton>;
    } else {
      return wrappedChildren;
    }
  };

  // Name that displays in react devtools and errors
  IdWrappedComponent.displayName = `StaffOnlyIdWrapper--${
    children?.displayName || children?.props?.className || 'text'
  }`;
  return IdWrappedComponent;
}
