import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './ToggleSwitch.scss';

function ToggleSwitch({
  className = '',
  toggleClassName = '',
  tabIndex = 0,
  isActive = false,
  disabled = false,
  onToggle = () => {},
}) {
  const [active, setActive] = useState(isActive);

  const onToggleClick = () => {
    if (!disabled) {
      setActive(!active);
      onToggle(!active);
    }
  };

  const onKeyboardToggle = (e) => {
    if (e.key !== 'Enter' && e.code !== 'Space') {
      return;
    }
    if (!disabled) {
      e.preventDefault();
      onToggleClick();
    }
  };

  useEffect(() => {
    // Allows Toggle Switch to be controlled by external sources
    setActive(isActive);
  }, [isActive]);

  return (
    <div
      className={`ToggleSwitch ${active ? 'ToggleSwitch--active' : ''} ${disabled ? 'disabled' : ''} ${className}`}
      tabIndex={tabIndex}
      onKeyDown={onKeyboardToggle}
      onClick={onToggleClick}
    >
      <div className={`ToggleSwitch-toggle ${toggleClassName}`} />
    </div>
  );
}

ToggleSwitch.propTypes = {
  className: PropTypes.string,
  toggleClassName: PropTypes.string,
  tabIndex: PropTypes.number,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default ToggleSwitch;
