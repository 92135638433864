import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import GeneralSettingsView from './GeneralSettingsView';
import useUser from '../../../utils/hooks/useUser';

const UPDATE_USER_GQL = gql`
  mutation MutateUser($userID: uuid, $isMetric: Boolean, $name: String, $phoneNumber: String, $timezone: String) {
    update_user(
      where: { id: { _eq: $userID } }
      _set: { name: $name, phone_number: $phoneNumber, is_metric: $isMetric, timezone: $timezone }
    ) {
      affected_rows
    }
  }
`;

function cleanUpPhoneNumbers(phoneNumber) {
  if (phoneNumber) {
    return phoneNumber.replace(/[()\s-]/g, '');
  }
  return undefined;
}

const GeneralSettingsController = forwardRef(({ onChange }, ref) => {
  const { user, loading: userLoading } = useUser();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isMetric, setIsMetric] = useState(false);
  const [timezone, setTimezone] = useState();
  const [updateUserData, { loading: updateLoading }] = useMutation(UPDATE_USER_GQL, {
    refetchQueries: ['AppGlobalInfoQuery'],
  });

  useEffect(() => {
    setName(user?.name);
    setEmail(user?.email);
    setPhoneNumber(user?.phoneNumber);
    setIsMetric(user?.isMetric);
    setTimezone(user?.timezone);
  }, [user?.name, user?.email, user?.phoneNumber, user?.isMetric, user?.timezone]);

  async function mutateUser() {
    if (!user?.id) {
      return;
    }
    updateUserData({
      variables: {
        userID: user.id,
        name,
        phoneNumber: cleanUpPhoneNumbers(phoneNumber),
        isMetric,
        timezone,
      },
    });
  }

  const onChangeName = (name) => {
    setName(name);
    onChange(true);
  };

  const onChangePhoneNumber = (_, __, ___, formattedValue) => {
    setPhoneNumber(formattedValue);
    onChange(true);
  };

  const onChangeIsMetric = (item) => {
    setIsMetric(item.id === 1);
    onChange(true);
  };

  const onTimezoneChange = (tz) => {
    setTimezone(tz);
    onChange(true);
  };

  useImperativeHandle(ref, () => ({
    save: async () => {
      await mutateUser();
    },
  }));

  return (
    <GeneralSettingsView
      loading={updateLoading || userLoading}
      name={name}
      email={email}
      phoneNumber={phoneNumber}
      isMetric={isMetric}
      timezone={timezone}
      onChangeName={onChangeName}
      onChangePhoneNumber={onChangePhoneNumber}
      onChangeIsMetric={onChangeIsMetric}
      onTimezoneChange={onTimezoneChange}
    />
  );
});
GeneralSettingsController.displayName = 'GeneralSettingsController';
GeneralSettingsController.propTypes = {
  onChange: PropTypes.func,
};

export default GeneralSettingsController;
