import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DATE_FORMAT_MONTH_DAY } from '../../utils/dates';
import DatePickerPopoverRow from './DatePickerPopoverRow';
import { CalendarIconSVG, ArrowIcon } from '../../atoms/Icons';
import './DatePickerPopover.scss';

const ONE_DAY_IN_SECONDS = 86400;

// October 1, 2022. Chosen as the earliest permissible date to prevent the charts from accessing malformed data
const EARLIEST_ALLOWED_DATE = 1664582400;

function DatePickerPopover({
  options = [],
  defaultValue = 0,
  dateRange = {},
  tooFarBack = EARLIEST_ALLOWED_DATE,
  onSelectDateOption = () => {},
  onSelectDateRange = () => {},
}) {
  const selectedIndex = options.findIndex((o) => defaultValue == o.value);
  const [labelText, setLabelText] = useState('');
  const [popoverVisible, setPopoverVisible] = useState(false);
  const popoverRef = useRef(null);

  useEffect(() => {
    function onClickOutside(e) {
      if (popoverRef.current && !popoverRef.current.contains(e.target)) {
        setPopoverVisible(false);
      }
    }

    document.addEventListener('mousedown', onClickOutside);

    return () => document.removeEventListener('mousedown', onClickOutside);
  }, [popoverRef]);

  useEffect(() => {
    if (options?.[selectedIndex]?.value) {
      setLabelText(options?.[selectedIndex]?.text);
      return;
    }

    setLabelText(`${dateRange.from.format(DATE_FORMAT_MONTH_DAY)} - ${dateRange.to.format(DATE_FORMAT_MONTH_DAY)}`);
  }, [selectedIndex, dateRange.from, dateRange.to]);

  const onToggleOptions = () => {
    setPopoverVisible(!popoverVisible);
  };

  const onOptionClick = (value) => {
    onSelectDateOption(value);
  };

  const to = dateRange.to.toDate().getTime() / 1000;
  const from = dateRange.from.toDate().getTime() / 1000;
  const dateRangeDiff = to - from;
  const now = dayjs.tz().endOf('day').toDate().getTime() / 1000;
  let partialSkipForward = false;
  let partialSkipBackward = false;
  const willSkipTooFarForward = to > now - ONE_DAY_IN_SECONDS;
  const willSkipTooFarBackward = from < tooFarBack + ONE_DAY_IN_SECONDS;

  if (to + dateRangeDiff > now) {
    partialSkipForward = true;
  }

  if (from - dateRangeDiff < tooFarBack) {
    partialSkipBackward = true;
  }

  const handleSkipBackward = () => {
    if (willSkipTooFarBackward) {
      return;
    }

    onSelectDateRange({
      dateRange: {
        from: dayjs.tz(1000 * partialSkipBackward ? tooFarBack : from - dateRangeDiff),
        to: dayjs.tz(1000 * partialSkipBackward ? tooFarBack + dateRangeDiff : to - dateRangeDiff),
      },
    });
  };

  const handleSkipForward = () => {
    if (willSkipTooFarForward) {
      return;
    }
    onSelectDateRange({
      dateRange: {
        from: dayjs.tz(1000 * partialSkipForward ? now - dateRangeDiff : from + dateRangeDiff),
        to: dayjs.tz(1000 * partialSkipForward ? now : to + dateRangeDiff),
      },
    });
  };

  return (
    <div className="DatePickerPopover-group">
      <div className="DatePickerPopover-dateRangeSkip--backward" onClick={handleSkipBackward}>
        <ArrowIcon className={willSkipTooFarBackward ? 'disabled' : ''} />
      </div>
      <div className="DatePickerPopover-dateRangeSkip--forward" onClick={handleSkipForward}>
        <ArrowIcon className={willSkipTooFarForward ? 'disabled' : ''} />
      </div>
      <div ref={popoverRef} className="DatePickerPopover" onClick={onToggleOptions}>
        <div className="DatePickerPopover-label">
          <CalendarIconSVG className="DatePickerPopover-calendarIcon" />
          <div className="DatePickerPopover-labelText">{labelText}</div>
        </div>
        {popoverVisible && (
          <div className="DatePickerPopover-popover">
            {options.map((option, index) => (
              <DatePickerPopoverRow
                key={option.text}
                text={option.text}
                value={option.value}
                isSelected={selectedIndex === index}
                isDatePickerVisible={selectedIndex === index && !option.value}
                dateRange={dateRange}
                onClick={onOptionClick}
                onSelectDateRange={onSelectDateRange}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

DatePickerPopover.propTypes = {
  options: PropTypes.array,
  defaultValue: PropTypes.number,
  dateRange: PropTypes.object,
  tooFarBack: PropTypes.number,
  onSelectDateOption: PropTypes.func,
  onSelectDateRange: PropTypes.func,
};

export default DatePickerPopover;
