import PropTypes from 'prop-types';

function ChartLegendRow({ color = '', name = '', count = 0, valueFormatter = () => {} }) {
  return (
    <>
      <div className="ChartLegend-swatchContainer">
        <div className="ChartLegend-swatch" style={{ backgroundColor: color }} />
      </div>
      <div className="ChartLegend-nameContainer">
        <div>{name}</div>
      </div>
      <div className="ChartLegend-countContainer">
        <div className="ChartLegend-count">{valueFormatter(count)}</div>
      </div>
    </>
  );
}

ChartLegendRow.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  count: PropTypes.number,
  valueFormatter: PropTypes.func,
};

export default ChartLegendRow;
