import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { DATE_TIME_DASH, DATE_FORMAT_MONTH_DAY, DATE_FORMAT_MONTH_DAY_YEAR } from '../../utils/dates';
import FeedFloLabChart from '../../molecules/FeedFloLabsChart';

const timeBinSizeInSeconds = 5 * 60;

const DEVICE_TRANSATION_GQL = gql`
  query DeviceTransactionQuery($deviceId: uuid!, $from: bigint!, $to: bigint!) {
    device(where: { id: { _eq: $deviceId } }) {
      serial
    }
    device_transaction(where: { device_id: { _eq: $deviceId }, occured_at: { _gte: $from, _lte: $to } }) {
      occured_at
      type
      data
    }
  }
`;

export default function TransactionChart({ deviceId, to, from }) {
  const [chartData, setChartData] = useState([]);
  const { error, data } = useQuery(DEVICE_TRANSATION_GQL, {
    variables: {
      deviceId,
      from: from.unix(),
      to: to.unix(),
    },
  });

  // if (loading) return <span>Loading...</span>;
  if (error) return <span>Error {JSON.stringify(error)}</span>;
  const transactions = data?.device_transaction || [];

  useEffect(() => {
    const map = {};
    const max = to.unix();
    const min = from.unix();
    const xTemplate = [];
    const yTemplate = [];
    for (let i = min; i < max; i += timeBinSizeInSeconds) {
      xTemplate.push(dayjs.tz(1000 * i).format(DATE_TIME_DASH));
      yTemplate.push(0);
    } // -min to normalise values for the array
    transactions.forEach((t) => {
      if (!map[t.data]) {
        map[t.data] = {
          type: 'scatter',
          name: t.data,
          x: JSON.parse(JSON.stringify(xTemplate)),
          y: JSON.parse(JSON.stringify(yTemplate)),
        };
      }
      map[t.data].y[Math.floor((t.occured_at - min) / timeBinSizeInSeconds)] += 1;
    });

    setChartData(Object.values(map).sort((a, b) => a.name.localeCompare(b.name)));
  }, [transactions]);

  return (
    <FeedFloLabChart
      data={chartData}
      layout={{
        title: {
          text: `<b>Http Requests</b> ${data?.device?.[0]?.serial}<br>[sum over ${
            timeBinSizeInSeconds / 60
          }min]<br>${from.format(DATE_FORMAT_MONTH_DAY)} - ${to.format(DATE_FORMAT_MONTH_DAY_YEAR)}`,
        },
      }}
    />
  );
}

TransactionChart.propTypes = {
  deviceId: PropTypes.string,
  to: PropTypes.object,
  from: PropTypes.object,
};
