import PropTypes from 'prop-types';
import { StatusEnum } from '../../utils/enums';
import { WarningIcon, FlagIcon } from '../../atoms/Icons';
import LoadingSkeleton from '../LoadingSkeleton';
import './StatusIcon.scss';

const statusValues = Object.values(StatusEnum);

function StatusIcon({ loading = false, className = '', status = '' }) {
  if (loading) {
    return <LoadingSkeleton className={`StatusIcon ${className}`} />;
  } else if (status === StatusEnum.Error) {
    return <WarningIcon className={`StatusIcon StatusIcon--error ${className}`} />;
  } else if (status === StatusEnum.Warning) {
    return <FlagIcon className={`StatusIcon StatusIcon--warning ${className}`} />;
  }

  return null;
}

StatusIcon.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  status: PropTypes.oneOf(statusValues),
};

export default StatusIcon;
