import React, { useCallback } from 'react';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleTime } from '@visx/scale';
import { Group } from '@visx/group';
import { GridColumns } from '@visx/grid';
import { LinePath } from '@visx/shape';
import { max, bisect } from 'd3-array';
import useUser from '../../utils/hooks/useUser';

import { getY, tickFormatFactory, calculateXAxisTickValues } from '../../utils/chartHelpers';

import PropTypes from 'prop-types';
import { TooltipWithBounds, useTooltip, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE } from '../../utils/dates';
import { convertGramsToLargeUnits, weightLargeUnitLabel } from '../../utils/unitConversion';

const TOOLTIP_STYLES = {
  ...defaultStyles,
  borderRadius: '8px',
  backgroundColor: 'rgba(40, 40, 40, 0.8)',
  color: 'white',
  border: '1px solid #E6E6E6',
  padding: '1rem',
};

function InventoryChart({ startDate = 1706625546, endDate = 1722350354, chartSeries, loading, totalCapacityInGrams }) {
  const { user } = useUser();
  const isMetric = user?.isMetric || false;
  const { tooltipData, tooltipLeft = 0, tooltipTop = 0, tooltipOpen, showTooltip, hideTooltip } = useTooltip();
  const start = startDate;
  const end = endDate;
  const chartMargin = { top: 10, bottom: 50, left: 30, right: 30 };

  /**
   * Given two points and a timestamp between them, return the value that would correspond to that timestamp.
   *
   * @param p0 The first point to lerp between.
   * @param p1 The second point to lerp between.
   * @param timestamp The point between p0 and p1 to calculate the value for.
   * @returns The value corresponding to the given timestamp between p0 and p1.
   */
  const lerpToTimestamp = useCallback(
    /**
     * @param {Object} p0
     * @param {number} p0.value
     * @param {number} p0.timestamp
     * @param {Object} p1
     * @param {number} p1.value
     * @param {number} p1.timestamp
     * @param {number} timestamp
     */
    (p0, p1, timestamp) => {
      // Guard against NaN slope/vertical line by returning the initial p0 value.
      if (p0.timestamp === p1.timestamp) return p0.value;
      const slope = (p1.value - p0.value) / (p1.timestamp - p0.timestamp);
      return p0.value + (timestamp - p0.timestamp) * slope;
    },
    [],
  );

  return (
    <ParentSize debounceTime={10}>
      {(parent) => {
        const xMax = parent.width - chartMargin.left - chartMargin.right;
        const yMax = parent.height - chartMargin.top - chartMargin.bottom;

        const xAxisTickFormat = tickFormatFactory(start, end);
        const xAxisTicks = calculateXAxisTickValues(start, end);

        const xScale = useCallback(
          scaleTime({
            domain: [start, end],
            range: [0, xMax],
          }),
          [start, end, xMax],
        );
        const yScale = useCallback(
          scaleLinear({
            domain: [0, (totalCapacityInGrams ? convertGramsToLargeUnits(isMetric, totalCapacityInGrams) : 32) * 1.2], // 20% Extra for headroom.
            range: [yMax, 0],
          }),
          [yMax],
        );

        const formatDateTooltip = useCallback((hoveredDate) => {
          return dayjs.unix(hoveredDate).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE);
        }, []);

        /**
         * Given a timestamp, find points that intersect with each chart line.
         * Points are generated by interpolation.
         *
         * @param timestamp The timestamp to intersect with each chart line.
         *
         * @returns An array of point objects with value, timestamp, and colour keys.
         */
        const getInterceptPointsForTimestamp = useCallback(
          /**
           * @param {number} timestamp
           * @returns {Array<{value: number, timestamp: number, colour: string, hidden: boolean}>}
           */
          (timestamp) =>
            // Note: we are NOT filtering here because we care about maintaining the indexes for parallel arrays when hiding
            // Instead we will simply pass along the hidden property
            chartSeries
              .map((series) => {
                let point = {};
                if (series?.x?.length > 1) {
                  const index = bisect(series.x, new Date(timestamp * 1000));
                  if (series.x[index - 1] && series.x[index]) {
                    const point0 = { timestamp: series.x[index - 1].getTime() / 1000, value: series.y[index - 1] };
                    const point1 = { timestamp: series.x[index].getTime() / 1000, value: series.y[index] };
                    point = point0 || point1;
                    if (point0 && point1) {
                      // If both points exist, lerp between them to estimate the value at the given timestamp
                      const value = lerpToTimestamp(point0, point1, timestamp);
                      point = { timestamp, value };
                    }
                  }
                }
                return { ...point, colour: series?.line?.color };
              })
              .filter((series) => series?.timestamp),
          [chartSeries],
        );

        const handleTooltip = useCallback(
          (mouseEvent) => {
            // Gets the position of the mouse cursor.
            const point = localPoint(mouseEvent);
            const xCoord = point.x - chartMargin.left;

            // If the mouse pointer is off the chart, we don't want to display any tool tips.
            if (xCoord < 0 || xCoord > xMax) return;

            const xCoordTimestamp = xScale.invert(xCoord).valueOf();

            // Gets the nearest intercept point to the current mouse X position for each chartLineData entry.
            // Array of objects like { timestamp, value, colour, hidden }

            const lineIntercepts = getInterceptPointsForTimestamp(xCoordTimestamp);

            if (lineIntercepts) {
              const xCoordDate = xScale.invert(xCoord);
              const maxYIntercept = max(lineIntercepts, getY);
              const formattedDate = formatDateTooltip(xCoordDate);

              showTooltip({
                tooltipLeft: xCoord,
                tooltipTop: yScale(maxYIntercept),
                tooltipData: {
                  lineIntercepts,
                  // projectionLineIntercepts,
                  displayedDate: formattedDate,
                },
              });
            }
          },
          [chartSeries, xMax, xScale, yScale, getInterceptPointsForTimestamp],
        );
        const svgCursor = loading ? 'wait' : 'crosshair';

        return (
          <div className="EventChart">
            <svg
              className="EventChart-chartSvg"
              style={{ height: parent.height, width: parent.width, cursor: svgCursor }}
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={hideTooltip}
            >
              <Group
                left={chartMargin.left}
                top={chartMargin.top}
                bottom={chartMargin.bottom}
                right={chartMargin.right}
              >
                <defs>
                  <clipPath id="eventInnerChartHorizontalBoundary">
                    <rect x={0} y={0} width={xMax} height={yMax} />
                  </clipPath>
                </defs>
                <GridColumns
                  scale={xScale}
                  width={xMax}
                  height={yMax}
                  stroke="#D9DCE1"
                  strokeWidth={2}
                  tickValues={xAxisTicks}
                />

                <AxisBottom
                  top={yMax}
                  scale={xScale}
                  hideTicks
                  stroke="#D9DCE1"
                  tickFormat={xAxisTickFormat}
                  tickValues={xAxisTicks}
                />

                {/* <GridRows scale={yScale} width={xMax} height={yMax} stroke="#D9DCE1" strokeWidth={2} numTicks={3} /> */}

                <AxisLeft
                  top={0}
                  scale={yScale}
                  hideTicks
                  stroke="#D9DCE1"
                  // tickFormat={yAxisTickFormat}
                  // tickValues={xAxisTicks}
                  numTicks={3}
                />

                <line
                  stroke="#000"
                  strokeWidth="1"
                  x1={0}
                  x2={xMax}
                  y1={yScale(11)}
                  y2={yScale(11)}
                  strokeDasharray={(5, 5)}
                />

                {
                  // Create the vertical dashed line and circular intercept markers that accompany the tooltip.
                  // Do not show if someone is interacting with the projection brushes.
                  tooltipOpen && (
                    <g pointerEvents="none">
                      <line
                        stroke="#000"
                        strokeWidth="1"
                        x1={tooltipLeft}
                        x2={tooltipLeft}
                        y1={0}
                        y2={yMax}
                        strokeDasharray={(5, 5)}
                      />
                      {
                        // Place (solid) circular intercept markers for all visible chart lines.
                        tooltipData.lineIntercepts.reduce((lineIntercepts, { value, timestamp, colour }, index) => {
                          if (!timestamp || !value) return lineIntercepts;
                          // The X dot coord should never exceed the rightmost line point.
                          // const xLimit = xScale(chartLines[index].linePlot.at(-1).timestamp);
                          // If not snapping to point data, we use the same X position as the vertical line for a smoother appearance.
                          // This comes at the cost of some visual accuracy compared to using xScale(timestamp).
                          // In either case, compare to the X limit and choose the leftmost value.
                          const x = xScale(timestamp); // Math.min(snapToPoints ? xScale(timestamp) : tooltipLeft, xLimit);
                          const y = yScale(value);
                          lineIntercepts.push(
                            <circle
                              key={index}
                              className="EventChart-chartInterceptCircleSvg"
                              cx={x}
                              cy={y}
                              r={7}
                              stroke={colour}
                            />,
                          );
                          return lineIntercepts;
                        }, [])
                      }
                    </g>
                  )
                }

                {
                  // Chart each of the line paths, filtering out hidden ones.
                  chartSeries
                    .filter(({ type }) => type === 'scatter')
                    .map((chartLineData, index) => {
                      const x = chartLineData.x.filter(
                        (v) => v?.valueOf() / 1000 <= end && v?.valueOf() / 1000 >= start,
                      );
                      const y = chartLineData.y.filter(
                        (v, i) =>
                          chartLineData.x[i]?.valueOf() / 1000 <= end && chartLineData.x[i]?.valueOf() / 1000 >= start,
                      );
                      return (
                        <LinePath
                          data={x}
                          x={(value) => {
                            return xScale(value?.valueOf() / 1000);
                          }}
                          y={(value, index) => {
                            return yScale(y[index]);
                          }}
                          stroke={chartLineData.line.color}
                          strokeWidth={2}
                          strokeOpacity={1}
                          key={index}
                          strokeDasharray={chartLineData?.line?.dash == 'dot' ? '5,5' : null}
                        />
                      );
                    })
                }
              </Group>
            </svg>
            {
              // Create the tooltip unless someone is interacting with the projection brushes.
              tooltipOpen && (
                <TooltipWithBounds top={tooltipTop - 50} left={tooltipLeft + 35} style={TOOLTIP_STYLES}>
                  <div className={`EventChart-tooltipInfo`}>
                    {
                      <>
                        <p className="EventChart-tooltipInfoHeader">{tooltipData.displayedDate}</p>
                        <div className="EventChart-tooltipInfo-dataholder">
                          {tooltipData.lineIntercepts.reduce((tooltipValueRow, { value, colour }, index) => {
                            // Store the projected value if the projection window is enabled.
                            tooltipValueRow.push(
                              <React.Fragment key={index}>
                                <div className="EventChart-tooltipInfo-dataholder-mass">
                                  <div className={`EventChart-tooltipCircle`} style={{ backgroundColor: colour }} />
                                  <span className="EventChart-tooltipInfo-dataholder-text">
                                    {Number.parseFloat(value).toFixed(2)} {weightLargeUnitLabel(isMetric)}
                                  </span>
                                </div>
                              </React.Fragment>,
                            );
                            return tooltipValueRow;
                          }, [])}
                        </div>
                      </>
                    }
                  </div>
                </TooltipWithBounds>
              )
            }
          </div>
        );
      }}
    </ParentSize>
  );
}

InventoryChart.propTypes = {
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  totalCapacityInGrams: PropTypes.number,
  chartSeries: PropTypes.array,
  loading: PropTypes.bool,
};

export default InventoryChart;
