import { array, date, number, object, string } from 'yup';
import { ErrorMessage, TripStatus } from './enums';
import { orderStatusList, tripStatusList } from './lists';

const tripStatusValues = tripStatusList.map((status) => status.value);
const orderStatusValues = orderStatusList.map((status) => status.value);

export const tripSchema = object().shape({
  status: string().oneOf(tripStatusValues).required(ErrorMessage.RequiredField),
  orderedAt: date().required(ErrorMessage.RequiredField),
  barnID: string().uuid().required(ErrorMessage.RequiredField),
  deliveredAt: date().when('status', {
    is: TripStatus.Delivered,
    then: (schema) => schema.required(ErrorMessage.RequiredField),
  }),
  externalID: string().max(255, ErrorMessage.Max255),
  comment: string().max(255, ErrorMessage.Max255),
  deliveries: array()
    .min(1, ErrorMessage.AtLeastOneDelivery)
    .of(
      object().shape({
        status: string().oneOf(orderStatusValues),
        binID: string().uuid().required(ErrorMessage.RequiredField),
        orderedMassInLargeUnits: number(ErrorMessage.MustBeNumber)
          .moreThan(0, ErrorMessage.PositiveNumber)
          .required(`${ErrorMessage.RequiredField}\n${ErrorMessage.PositiveNumber}`),
        externalID: string().max(255, ErrorMessage.Max255),
        feedProfileName: string().max(255, ErrorMessage.Max255),
        deliveredMassInLargeUnits: number(ErrorMessage.MustBeNumber)
          .moreThan(0, ErrorMessage.PositiveNumber)
          .when('status', {
            is: TripStatus.Delivered,
            then: (schema) => schema.required(`${ErrorMessage.RequiredField}\n${ErrorMessage.PositiveNumber}`),
          }),
        comment: string().max(255, ErrorMessage.Max255),
      }),
    )
    .required(ErrorMessage.AtLeastOneDelivery),
});
