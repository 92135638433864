import PropTypes from 'prop-types';
import './Heading.scss';

function Heading({ className = '', text = '', underline = false, children = null }) {
  const underlineClass = underline ? 'Heading--underline' : '';

  return (
    <h1 className={`Heading ${underlineClass} ${className}`}>
      {text}
      {children}
    </h1>
  );
}

Heading.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  underline: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Heading;
