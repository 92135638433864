import { gql } from '@apollo/client';

export const ORG_NAME_QUERY = gql`
  query APIAccessPage_OrgNames {
    orgs: organization {
      name
      id
    }
  }
`;
