import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import differenceBy from 'lodash/differenceBy';
import PillGroup from '../../molecules/PillGroup';
import SearchPopoverValueRow from './SearchPopoverValueRow';
import './SearchPopover.scss';

function SearchPopover({
  visible = false,
  placeholder = '',
  values = [],
  selectedValues = [],
  maxVisibleValues = 5,
  onClosePopover = () => {},
  onUpdateSelectedValues = () => {},
  enableSearch = true,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const lowercaseSearchTerm = useMemo(() => searchTerm.toLowerCase(), [searchTerm]);
  const availableValues = useMemo(() => differenceBy(values, selectedValues, 'id'), [values, selectedValues]);
  const filteredValues = useMemo(
    () => availableValues.filter((value) => value.text.toLowerCase().indexOf(lowercaseSearchTerm) > -1),
    [availableValues, lowercaseSearchTerm],
  );
  const visibleValues = useMemo(() => filteredValues.slice(0, maxVisibleValues), [filteredValues, maxVisibleValues]);

  const onEditSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  const onValueRowClick = (id) => {
    const value = values.find((value) => value.id === id);
    onUpdateSelectedValues([...selectedValues, value]);
  };

  const renderAvailableRows = () => {
    if (!availableValues.length) {
      return <div className="SearchPopover-noOptionsMessage">All options are currently active</div>;
    }

    return visibleValues.map((value) => (
      <SearchPopoverValueRow
        key={value.text}
        id={value.id}
        text={value.text}
        subtext={value?.subtext}
        onClick={onValueRowClick}
      />
    ));
  };

  return (
    <div className={`SearchPopover ${visible ? '' : 'SearchPopover--hidden'}`}>
      <div className="SearchPopover-closeContainer">
        <svg
          className="SearchPopover-close"
          width="8"
          height="7"
          viewBox="0 0 8 7"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onClosePopover}
        >
          <path d="M7 0.5L4 3.5M1 6.5L4 3.5M4 3.5L1 0.5M4 3.5L7 6.5" stroke="#a3acb9" />
        </svg>
      </div>
      <div className="SearchPopover-searchContainer">
        {!enableSearch || (
          <input
            className="SearchPopover-search"
            type="text"
            placeholder={placeholder}
            value={searchTerm}
            onChange={onEditSearchTerm}
          />
        )}
      </div>
      {selectedValues.length > 0 && (
        <div className="SearchPopover-selectedValuesContainer">
          <PillGroup values={selectedValues} onUpdateValues={onUpdateSelectedValues} />
        </div>
      )}
      <div className="SearchPopover-availableValuesContainer">
        <div className="SearchPopover-valueLabel">
          {availableValues.length > visibleValues.length ? 'Suggestions' : 'Options'}
        </div>
        {renderAvailableRows()}
      </div>
    </div>
  );
}

SearchPopover.propTypes = {
  visible: PropTypes.bool,
  placeholder: PropTypes.string,
  values: PropTypes.array,
  selectedValues: PropTypes.array,
  maxVisibleValues: PropTypes.number,
  onClosePopover: PropTypes.func,
  onUpdateSelectedValues: PropTypes.func,
  enableSearch: PropTypes.bool,
};

export default SearchPopover;
