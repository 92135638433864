import PropTypes from 'prop-types';
import Card from '../../../../atoms/Card';
import { RightChevronIcon } from '../../../../atoms/Icons';
import LoadingSkeleton from '../../../../atoms/LoadingSkeleton';

function TripCard({
  loading = false,
  className = '',
  tabIndex = 0,
  id = '',
  date = null,
  status = '',
  barnName = '',
  mass = '',
  deliveryDetailRows = [],
  chevronVisible = false,
  onClick = () => {},
}) {
  const onTripCardKeyDown = (e) => {
    if (e.key !== 'Enter' && e.code !== 'Space') {
      return;
    }

    e.preventDefault();
    onClick(date, id);
  };

  const onOrderClick = () => {
    onClick(date, id);
  };

  if (loading) {
    return <LoadingSkeleton className="TripList-card--loader" />;
  }

  return (
    <Card
      className={`TripList-card ${className}`}
      contentClassName="TripList-cardContent"
      tabIndex={tabIndex}
      status={status}
      onKeyDown={onTripCardKeyDown}
      onClick={onOrderClick}
    >
      <div className="TripList-details">
        <div className="TripList-barnName">{barnName}</div>
        <div className="TripList-status">
          {status}: {mass}
        </div>
        {deliveryDetailRows.map((row, index) => (
          <div key={index + row}>{row}</div>
        ))}
      </div>
      {chevronVisible ? <RightChevronIcon className="TripList-chevron" /> : null}
    </Card>
  );
}

TripCard.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  id: PropTypes.string,
  date: PropTypes.object,
  status: PropTypes.string,
  barnName: PropTypes.string,
  binName: PropTypes.string,
  mass: PropTypes.string,
  deliveryDetailRows: PropTypes.array,
  chevronVisible: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TripCard;
