import { useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT_SHORT } from '../../dates';
import { TLVtoJSON } from '../../../tlv3';

const DEVICE_DATA_GQL = gql`
  query DeviceDataQuery($deviceId: uuid!, $from: bigint!, $to: bigint!) {
    device_data(
      where: { device_id: { _eq: $deviceId }, ended_at: { _gte: $from }, started_at: { _lte: $to } }
      order_by: { ended_at: asc }
    ) {
      id
      data
    }
  }
`;

const DATA_TYPES = [
  'timeOfFlight',
  'augerRotation',
  'accelerometerTemperature',
  'orientation',
  'debug1',
  'debug2',
  'debug3',
  'debug4',
];

export default function useSignalDataSeries({ deviceId = '', to, from, skipParsing = false, skip = false }) {
  const { loading, error, data } = useQuery(DEVICE_DATA_GQL, {
    variables: {
      deviceId,
      from: from.unix(),
      to: to.unix(),
    },
    skip,
  });

  let [tlvDataErrorMessage, setTLVDataErrorMessage] = useState(null);

  const outputChartData = useMemo(() => {
    if (!(deviceId && from && to && data?.device_data?.length > 0)) return [];
    const newChartData = {
      timeOfFlight: {
        name: 'timeOfFlight',
        x: [],
        y: [],
      },
      augerRotation: {
        name: 'augerRotation',
        x: [],
        y: [],
      },
      accelerometerTemperature: {
        name: 'accelerometerTemperature',
        x: [],
        y: [],
      },
      roll: {
        name: 'roll',
        x: [],
        y: [],
        visible: 'legendonly',
      },
      pitch: {
        name: 'pitch',
        x: [],
        y: [],
        visible: 'legendonly',
      },
      movementNoise: {
        name: 'movementNoise',
        x: [],
        y: [],
        visible: 'legendonly',
      },
      debug1: {
        name: 'debug1',
        x: [],
        y: [],
        yaxis: 'y2',
        visible: 'legendonly',
      },
      debug2: {
        name: 'debug2',
        x: [],
        y: [],
        yaxis: 'y2',
        visible: 'legendonly',
      },
      debug3: {
        name: 'debug3',
        x: [],
        y: [],
        yaxis: 'y2',
        visible: 'legendonly',
      },
      debug4: {
        name: 'debug4',
        x: [],
        y: [],
        yaxis: 'y2',
        visible: 'legendonly',
      },
    };

    if (!skipParsing) {
      data?.device_data?.forEach((curr) => {
        // Convert hex string to byte array
        // https://stackoverflow.com/a/34356351
        const hex = curr.data.substring(2);
        const buff = [];
        for (let c = 0; c < hex.length; c += 2) {
          buff.push(parseInt(hex.substr(c, 2), 16));
        }

        const tlv = TLVtoJSON(buff);
        DATA_TYPES.forEach((type) => {
          if (tlv[type]) {
            const { startTime, endTime } = tlv[type];
            const startTimeMilliseconds = startTime / 1_000;
            const endTimeMilliseconds = endTime / 1_000;
            const samplePeriodMilliseconds = endTimeMilliseconds - startTimeMilliseconds;

            // orientation is a special case due to its TLV structure
            if (type === 'orientation') {
              const { roll, pitch, movementNoise } = tlv[type];
              const orientationData = [
                { data: roll, name: 'roll' },
                { data: pitch, name: 'pitch' },
                { data: movementNoise, name: 'movementNoise' },
              ];

              orientationData.forEach((signal) => {
                const newX = [];
                const newY = [];
                if (signal?.data?.length > 0) {
                  const stepSize = samplePeriodMilliseconds / signal.data.length;
                  signal.data.forEach((v, i) => {
                    const time = new Date(Math.floor(startTimeMilliseconds + stepSize * i));
                    newX.push(time);
                    newY.push(v);
                  });

                  newChartData[signal.name].x.push(...newX);
                  newChartData[signal.name].y.push(...newY);
                }
              });
              return;
            }

            const newX = [];
            const newY = [];

            const { uint8Data, uint16Data, uint32Data, uint64Data } = tlv[type];
            const data = uint8Data || uint16Data || uint32Data || uint64Data;

            if (data?.length > 0) {
              const stepSize = samplePeriodMilliseconds / data.length;
              data.forEach((v, i) => {
                const time = new Date(Math.floor(startTimeMilliseconds + stepSize * i));
                newX.push(time);
                newY.push(v);
              });

              newChartData[type].x.push(...newX);
              newChartData[type].y.push(...newY);
            } else {
              try {
                // convert micro to milliseconds and then save as a nicely formatted string
                const startErrorString = dayjs.tz(tlv?.startTime / 1_000)?.format(DATE_TIME_FORMAT_SHORT);
                const endErrorString = dayjs.tz(tlv?.endTime / 1_000)?.format(DATE_TIME_FORMAT_SHORT);
                setTLVDataErrorMessage(
                  `${tlvDataErrorMessage}Corrupt data in TLV ${type} data. ID from device: ${tlv?.uuid}. TLV start: ${startErrorString} (${tlv?.startTime}). TLV end: ${endErrorString} (${tlv?.endTime}).`,
                );
              } catch (e) {
                setTLVDataErrorMessage(
                  `${tlvDataErrorMessage}Corrupt data in TLV ${type} data. ID from device: ${tlv?.uuid}. TLV start: ${tlv?.startTime} TLV end: ${tlv?.endTime}. `,
                );
              }
            }
          }
        });
      });
    }

    return [...Object.values(newChartData)];
  }, [data?.device_data?.length]);

  return {
    loading,
    error: error || tlvDataErrorMessage,
    data: outputChartData,
  };
}
