import PropTypes from 'prop-types';
import { EKGIconSVG, WarningIcon, ClockIcon, LogoIcon, SearchIconSVG } from '../Icons';
import LoadingSkeleton from '../LoadingSkeleton';
import './StatusBadge.scss';

function StatusBadge({ loading = false, className = '', style = {}, status = 'error', icon = '', text = '' }) {
  const renderIcon = () => {
    switch (icon) {
      case 'ekg':
        return <EKGIconSVG className={`StatusBadge-icon StatusBadge-ekgIcon`} />;
      case 'warning':
        return <WarningIcon className={`StatusBadge-icon StatusBadge-warningIcon`} />;
      case 'clock':
        return <ClockIcon className={`StatusBadge-icon StatusBadge-clockIcon`} />;
      case 'logo':
        return <LogoIcon className={`StatusBadge-icon StatusBadge-logoIcon`} />;
      case 'search':
        return <SearchIconSVG className={`StatusBadge-icon StatusBadge-searchIcon`} />;
    }
  };

  if (loading) {
    return <LoadingSkeleton className={`StatusBadge StatusBadge--loading ${className}`} style={style} />;
  }

  return (
    <div
      className={`StatusBadge StatusBadge--${status} StatusBadge--${icon ? 'withIcon' : 'noIcon'} ${className}`}
      style={style}
    >
      {renderIcon()}
      <span className={`StatusBadge-text StatusBadge-text--${status}`}>{text}</span>
    </div>
  );
}

StatusBadge.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  status: PropTypes.oneOf(['error', 'investigate', 'success', 'warning', 'info', 'offline']),
  icon: PropTypes.string,
  text: PropTypes.string,
};

export default StatusBadge;
