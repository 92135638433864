import PropTypes from 'prop-types';
import NotificationOptions from '../../organisms/NotificationOptions';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';
import './AlertTypeRow.scss';

function AlertTypeRow({ loading = false, name = '', faultCode = 0, notificationSettings = {}, onChange = () => {} }) {
  const onChangeNotificationOptions = (transportType, newValues, isValid) => {
    onChange(faultCode, transportType, newValues, isValid);
  };

  if (loading) {
    return (
      <div className="AlertTypeRow">
        <div className="AlertTypeRow-name">
          <LoadingSkeleton className="AlertTypeRow-name--loader" />
        </div>
        <div className="AlertTypeRow-transportTypeColumn">
          <LoadingSkeleton className="AlertTypeRow-transportTypeColumn--loader" />
        </div>
        <div className="AlertTypeRow-transportTypeColumn">
          <LoadingSkeleton className="AlertTypeRow-transportTypeColumn--loader" />
        </div>
      </div>
    );
  }

  return (
    <div className="AlertTypeRow">
      <div className="AlertTypeRow-name">{name}</div>
      <div className="AlertTypeRow-transportTypeColumn">
        <NotificationOptions
          transportType="sms"
          currentTimePeriods={notificationSettings?.sms}
          config={{
            initial: {
              title: 'New Notification',
              defaultSet: 10800,
              off: -1,
            },
          }}
          onChange={onChangeNotificationOptions}
        />
      </div>
      <div className="AlertTypeRow-transportTypeColumn">
        <NotificationOptions
          transportType="email"
          currentTimePeriods={notificationSettings?.email}
          config={{
            initial: {
              title: 'New Notification',
              defaultSet: 10800,
              off: -1,
            },
            reminder: {
              title: 'Reminder Notification',
              defaultSet: 21600,
              off: -1,
            },
          }}
          onChange={onChangeNotificationOptions}
        />
      </div>
    </div>
  );
}

AlertTypeRow.propTypes = {
  loading: PropTypes.bool,
  name: PropTypes.string,
  faultCode: PropTypes.number,
  notificationSettings: PropTypes.object,
  onChange: PropTypes.func,
};

export default AlertTypeRow;
