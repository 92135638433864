export default function BarnListHeader() {
  return (
    <div className="BarnListSection-listHeader">
      <div className="BarnListSection-alertsHeader">Alerts</div>
      <div className="BarnListSection-barnHeader">Name</div>
      <div className="BarnListSection-locationHeader">Location</div>
      <div className="BarnListSection-statusListHeader">Alerts</div>
      <div className="BarnListSection-nofeedTimeHeader">Over 7 Days</div>
      <div className="BarnListSection-pinnedHeader">Pinned</div>
    </div>
  );
}
