import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import WebAppContext from '../../utils/webAppContext';
import { useApolloClient } from '@apollo/client';

import DocumentFactory from '../../utils/DocumentFactory/factory';
import XlsxFeedEventStrategy from '../../utils/DocumentFactory/xlsxFeedEventStrategy';
import { useAnalysisFilter } from '../../utils/useFeedFrameFilter';

import { ExportIcon } from '../../atoms/Icons';
import FeedFloButton from '../../atoms/FeedFloButton';
import { toast } from 'react-toastify';

import './QuickExportButton.scss';

function QuickExportButton({ dateRange, barnId, barnName }) {
  const { isMetric } = useContext(WebAppContext);
  const gqlClient = useApolloClient();
  const analysisFilter = useAnalysisFilter();
  const [progress, setProgress] = useState(-1);

  const factory = new DocumentFactory({
    farmIds: [barnId],
    startTime: dateRange.from.toDate(),
    stopTime: dateRange.to.toDate(),
    strategy: new XlsxFeedEventStrategy(isMetric),
    progressCallback: (x) => {
      setProgress(x);
    },
    gqlClient,
    analysisFilter,
  });

  let buttonText = 'Export';

  if (progress >= 0) buttonText = `${(progress * 100).toFixed(2)}%`;
  return (
    <FeedFloButton
      className="QuickExportButton"
      disabled={!analysisFilter}
      onClick={() => {
        setProgress(0);
        factory.start().then(() => {
          const dateFormat = 'YYYY-MM-DD';
          const datePart = `${dateRange.from.format(dateFormat)}_${dateRange.to.format(dateFormat)}`;
          saveAs(factory.createFile(), `${barnName}_${datePart}.xlsx`);
          setProgress(-1);
          toast.success('Download Ready', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      }}
    >
      <ExportIcon />
      <span className="QuickExportButton-text">{buttonText}</span>
    </FeedFloButton>
  );
}

QuickExportButton.propTypes = {
  dateRange: PropTypes.object,
  barnId: PropTypes.string,
  barnName: PropTypes.string,
};

export default QuickExportButton;
