import PropTypes from 'prop-types';
import FeedFloButton from '../../atoms/FeedFloButton';
import './CalibrationProcessChainNode.scss';

function CalibrationProcessChainNode({
  stepNumber,
  stepHeading = '',
  stepInstructions = '',
  previousButtonEnable = true,
  continueButtonEnable = true,
  currentStep = false,
  stepCompleted = false,
  onClickPrevious = () => {},
  onClickContinue = () => {},
}) {
  return (
    <div className="CalibrationProcessChainNode">
      <div
        className={`CalibrationProcessChainNode-heading ${
          currentStep || stepCompleted ? 'CalibrationProcessChainNode-heading--stepCompleted' : ''
        }`}
      >
        <div
          className={`CalibrationProcessChainNode-heading-circle ${
            currentStep || stepCompleted ? 'CalibrationProcessChainNode-heading-circle--stepCompleted' : ''
          }`}
        >
          {stepNumber}
        </div>
        <h4>{stepHeading}</h4>
      </div>

      <div className="CalibrationProcessChainNode-body">
        {
          // No bar gets added for the last step (this is the top half of the grey bar on the left)
          continueButtonEnable ? (
            <div
              className={`CalibrationProcessChainNode-bar ${
                stepCompleted ? 'CalibrationProcessChainNode-bar--stepCompleted' : ''
              }`}
            />
          ) : null
        }

        {
          // Only show the instructions for the current step.
          currentStep ? (
            <div className="CalibrationProcessChainNode-content">
              <p>{stepInstructions}</p>

              <div className="CalibrationProcessChainNode-content-buttons">
                {
                  // The previous button is hidden when viewing the first step.
                  previousButtonEnable ? (
                    <FeedFloButton
                      className={`CalibrationProcessChainNode-content-buttons-previous ${
                        continueButtonEnable ? '' : 'CalibrationProcessChainNode-content-buttons-lastItem'
                      }`}
                      onClick={onClickPrevious}
                    >
                      Previous
                    </FeedFloButton>
                  ) : null
                }
                {
                  // The continue button is hidden when viewing the last step.
                  continueButtonEnable ? (
                    <FeedFloButton
                      className="CalibrationProcessChainNode-content-buttons-continue"
                      type="secondary"
                      onClick={onClickContinue}
                    >
                      Continue
                    </FeedFloButton>
                  ) : null
                }
              </div>
            </div>
          ) : null
        }
      </div>

      {
        // No bar gets added for the last step (this is the bottom half of the grey bar on the left)
        continueButtonEnable ? (
          <div
            className={`CalibrationProcessChainNode-bar CalibrationProcessChainNode-bar-bottom-bar ${
              stepCompleted ? 'CalibrationProcessChainNode-bar--stepCompleted' : ''
            }`}
          />
        ) : null
      }
    </div>
  );
}

CalibrationProcessChainNode.propTypes = {
  stepNumber: PropTypes.number,
  stepHeading: PropTypes.string,
  stepInstructions: PropTypes.string,
  previousButtonEnable: PropTypes.bool,
  continueButtonEnable: PropTypes.bool,
  currentStep: PropTypes.bool,
  stepCompleted: PropTypes.bool,
  onClickPrevious: PropTypes.func,
  onClickContinue: PropTypes.func,
};

export default CalibrationProcessChainNode;
