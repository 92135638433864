import jsSHA from 'jssha';
import useFeature from '../useFeature';

// Example Usage:
//
// import { useCensor, censorTypes } from '/utils/hooks/useCensor';
// const { censor } = useCensor();
// censor("Barn Name", censorTypes.barn)
//

const censorTypes = {
  barn: 'barn',
  binset: 'binset',
  bin: 'bin',
  feedline: 'feedline',
  organization: 'organization',
  pii: 'pii',
};

const censorFunctions = {
  barn: (input) => {
    const seed = 'Second';
    const shaObj = new jsSHA('SHA-512', 'TEXT', { encoding: 'UTF8' }).update(input).update(seed);
    const hash = shaObj.getHash('HEX').slice(0, 4).toUpperCase();

    return `Farm #${hash}`;
  },
  binset: (input) => {
    const seed = 'Second';
    const shaObj = new jsSHA('SHA-512', 'TEXT', { encoding: 'UTF8' }).update(input).update(seed);
    const hash = shaObj.getHash('HEX').slice(0, 4).toUpperCase();

    return `BinSet #${hash}`;
  },
  bin: (input) => {
    const seed = 'Second';
    const shaObj = new jsSHA('SHA-512', 'TEXT', { encoding: 'UTF8' }).update(input).update(seed);
    const hash = shaObj.getHash('HEX').slice(0, 3).toUpperCase();

    return `${hash}`;
  },
  feedline: (input) => {
    const seed = 'Second';
    const shaObj = new jsSHA('SHA-512', 'TEXT', { encoding: 'UTF8' }).update(input).update(seed);
    const hash = shaObj.getHash('HEX').slice(0, 4).toUpperCase();

    return `FeedLine #${hash}`;
  },
  organization: () => {
    return `Successful Swine Inc.`;
  },
  pii: () => {
    return `#### #### ####`;
  },
};

function useCensor() {
  const censorStates = {};
  //resolve Censor States here
  const { active } = useFeature('CENSOR_SENSITIVE_FIELDS');
  censorStates.barn = active;
  censorStates.binset = active;
  censorStates.bin = active;
  censorStates.feedline = active;
  censorStates.organization = active;
  censorStates.pii = active;

  //Censor Type Functions

  const censor = (input, type) => {
    if (!input) {
      return input;
    }
    const censorFunctionForType = censorFunctions[type];
    const censorThisType = censorStates[type];
    if (!censorFunctionForType) {
      return '███████████';
    }
    if (!censorThisType) {
      return input;
    }
    return censorFunctionForType(input);
  };

  return { censor, isCensorActive: active };
}

export { useCensor, censorTypes };
