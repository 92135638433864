/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tab from './Tab';
import './FeedFloTabs.scss';
/***
 * ? DEPRECATED / NEEDS REVIEW ?
 */
function FeedFloTabs({ className = '', tabData = [], defaultSelected = 0, onClick = () => {} }) {
  const location = useLocation();

  const [currSelectedIndex, setCurrSelectedIndex] = useState(defaultSelected);

  useEffect(() => {
    // Regular expression for capturing the "link" part of the barn URL.
    // With a URL like: {domain}/{b OR barns OR fault}/{uuid}/{route}
    // This regular expression will fetch {route}, ignoring any additional params or routes that come after.
    const regex = /^\/(?:b|barns|fault)\/[0-9a-f]{8}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{12}\/(\w+)/;
    const selectedLink = location.pathname.match(regex)?.[1];

    const searchedIndex = tabData.findIndex(({ link }) => selectedLink === link);
    const selectedIndex = -1 === searchedIndex ? defaultSelected : searchedIndex;

    setCurrSelectedIndex(selectedIndex);
  }, [tabData]);

  const onTabClick = (i) => {
    setCurrSelectedIndex(i);
    onClick(i);
  };

  return (
    <div className={`FeedFloTabs ${className}`}>
      {tabData.map((tab, i) => {
        return (
          <Tab
            key={i}
            index={i}
            title={tab.title}
            link={tab.link}
            selected={currSelectedIndex === i}
            onClick={onTabClick}
            content={tab.content}
            contentLeft={tab.contentLeft}
            contentRight={tab.contentRight}
          />
        );
      })}
      <div className="grower" />
    </div>
  );
}

FeedFloTabs.propTypes = {
  tabData: PropTypes.array,
  className: PropTypes.string,
  onClick: PropTypes.func,
  defaultSelected: PropTypes.number,
};

export default FeedFloTabs;
