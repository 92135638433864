import { useMemo } from 'react';
import PropTypes from 'prop-types';
import FeedFloDropDown from '../../atoms/FeedFloDropDown';

function DeviceRow({
  deviceID = '',
  label = '',
  serial = '',
  firmwareBranches = [],
  firmwareBranchID = '',
  onChange = () => {},
}) {
  const list = useMemo(() => {
    return (firmwareBranches || []).map((branch) => ({
      id: branch.id,
      name: branch.name,
      selected: branch.id === firmwareBranchID,
    }));
  }, [firmwareBranches, firmwareBranchID]);

  const onChangeBranch = (item) => {
    onChange(deviceID, item.id);
  };

  return (
    <div className="FirmwarePage-deviceRow" key={deviceID}>
      <span>
        <b>{label}</b> : {serial}
      </span>
      <FeedFloDropDown key={deviceID} list={list} defaultTitle="None" onChange={onChangeBranch} />
    </div>
  );
}

DeviceRow.propTypes = {
  deviceID: PropTypes.string,
  label: PropTypes.string,
  serial: PropTypes.string,
  firmwareBranches: PropTypes.array,
  firmwareBranchID: PropTypes.string,
  onChange: PropTypes.func,
};

export default DeviceRow;
