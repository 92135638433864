import React from 'react';
import PropTypes from 'prop-types';
import './FeedFloButton.scss';

/***
 * ? DEPRECATED ?
 *
 * FeedFloButton
 *
 * type: 'primary' (default) or 'secondary'
 *     primary: black text (#3c4257) on white background (#fff)
 *     secondary: white text (#fff) on green background (#27ae60)
 */
function FeedFloButton({
  className = '',
  children = [],
  type = 'primary',
  disabled = false,
  tabIndex = 0,
  onClick = () => {},
  leftIcon,
  rightIcon,
}) {
  const typeClass = type === 'primary' ? 'FeedFloButton--primary' : 'FeedFloButton--secondary';

  return (
    <div
      className={`FeedFloButton noselect ${disabled ? 'disabled' : 'enabled'} ${typeClass} ${className}`}
      tabIndex={tabIndex}
      onClick={onClick}
    >
      {leftIcon && <div className="FeedFloButton-icon FeedFloButton-iconLeft">{leftIcon}</div>}
      {children}
      {rightIcon && <div className="FeedFloButton-icon FeedFloButton-iconRight">{rightIcon}</div>}
    </div>
  );
}

FeedFloButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  onClick: PropTypes.func,
  type: PropTypes.string,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
};

export default FeedFloButton;
