import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import Page from '../../atoms/Page';
import FeedFloApiTokenController from '../../organisms/FeedFloAPITokenController';
import CollapsibleSection from '../../atoms/CollapsibleSection';
import StatusBadge from '../../atoms/StatusBadge';
import GraphiQLView from './APITester';
import Heading from '../../atoms/Heading';
import './APIAccessPage.scss';
import { Link } from 'react-router-dom';
import CopyButton from '../../atoms/CopyButton';
import { ORG_NAME_QUERY } from './queries';

function APIAccessPage({ titleSegments = [] }) {
  const pageTitleSegments = useMemo(() => ['API Access', ...titleSegments], []);
  const [orgsExpanded, setOrgsExpanded] = useState(true);
  const { loading: orgLoading, data: orgData } = useQuery(ORG_NAME_QUERY);

  return (
    <Page className="ApiAccessPage" titleSegments={pageTitleSegments}>
      <Heading text="Developers" />
      <FeedFloApiTokenController />
      <hr />
      <h3>REST API Docs</h3>
      <p>
        <StatusBadge text="New" status="success" />
        <Link to={'https://docsend.com/view/tu8jqise359m826g'}>Download REST API V2 Docs (.pdf)</Link>
      </p>
      <p>
        <StatusBadge text="New" status="success" />
        <Link to={'https://docsend.com/view/cx3dmdxys4x6scgd'}>Download Postman Collection</Link>
      </p>
      <br />
      <h4>Identifiers Used By API</h4>
      <br />
      <CollapsibleSection
        title="Organizations"
        isExpanded={orgsExpanded}
        onExpandCollapse={() => setOrgsExpanded(!orgsExpanded)}
        loading={orgLoading}
      >
        <div
          style={{
            marginTop: '3rem',
            display: 'grid',
            gridTemplateColumns: '30rem fit-content(100%)',
            columnGap: '1rem',
            rowGap: '0.5rem',
          }}
        >
          <b>Organization Name</b>
          <b>Organization ID</b>
          {orgData?.orgs?.map(({ name, id }) => (
            <>
              <span>{name}</span>

              <span>
                <CopyButton key={id} data={id} hoverText="Copy Organization ID"></CopyButton>
                {id}
              </span>
            </>
          ))}
        </div>
      </CollapsibleSection>
      <hr />
      <h3>Graph QL API Tester</h3>
      <GraphiQLView />
    </Page>
  );
}

APIAccessPage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
};

export default APIAccessPage;
