import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import BinCheckDialogView from './BinCheckDialogView';
import { PurposeEnum } from './enums';

function BinCheckDialog({
  dialogRef = null,
  firstFieldRef = null,
  title = '',
  binSets: binSetsInput = {},
  binSetLevelID = '',
  validAt = null,
  levelInGrams = 0,
  method = '',
  purpose = PurposeEnum.Test,
  comment = '',
  newEntry,
  onInsertBinSetLevel = () => {},
  onUpdateBinSetLevel = () => {},
}) {
  const binSets = Array.isArray(binSetsInput) ? binSetsInput : [binSetsInput];
  return ReactDOM.createPortal(
    <BinCheckDialogView
      dialogRef={dialogRef}
      firstFieldRef={firstFieldRef}
      title={title}
      binSets={binSets}
      binSetLevelID={binSetLevelID}
      validAt={validAt}
      levelInGrams={levelInGrams}
      method={method}
      purpose={purpose}
      comment={comment}
      newEntry={newEntry}
      onInsertBinSetLevel={onInsertBinSetLevel}
      onUpdateBinSetLevel={onUpdateBinSetLevel}
    />,
    document.getElementById('portal-root'),
  );
}

BinCheckDialog.propTypes = {
  dialogRef: PropTypes.object,
  firstFieldRef: PropTypes.object,
  title: PropTypes.string,
  binSets: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string,
      bins: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        bins: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
        ),
      }),
    ),
  ]),
  binSetLevelID: PropTypes.string,
  validAt: PropTypes.number,
  levelInGrams: PropTypes.number,
  method: PropTypes.string,
  purpose: PropTypes.string,
  comment: PropTypes.string,
  newEntry: PropTypes.bool,
  onInsertBinSetLevel: PropTypes.func,
  onUpdateBinSetLevel: PropTypes.func,
};

export default BinCheckDialog;
