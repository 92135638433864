import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const parseStateValueFromString = (stateValue) => {
  // Check if the provided value is a boolean
  if (stateValue === 'true') {
    return true;
  } else if (stateValue === 'false') {
    return false;
  }

  // Check if the provided value is a number
  if (stateValue?.length > 0 && !Number.isNaN(Number(stateValue))) {
    return parseFloat(stateValue);
  }

  // Otherwise, treat it as a string
  return stateValue;
};

function convertURLSearchParamsToObject(searchParams) {
  // Collapse all of the existing query params down to a single state object representing the current state
  const stateObject = [...searchParams.entries()].reduce((state, [key, value]) => {
    if (state[key] !== undefined) {
      // If the state already has multiple values for this key, merge the value arrays into a new list
      if (Array.isArray(state[key])) {
        return { ...state, [key]: [...state[key], value] };
      }

      // In this case, the old value isn't an array - convert the old and new value into an array
      return { ...state, [key]: [state[key], value] };
    }

    // This is the first value for this key, so don't make it an array
    return { ...state, [key]: value };
  }, {});

  return stateObject;
}

// This hook is inspired by: https://blog.logrocket.com/use-state-url-persist-state-usesearchparams/
export default function useSearchParamsState(searchParamName, defaultValue) {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamResult = searchParams.getAll(searchParamName);
  let acquiredSearchParam = searchParamResult;

  // By default, treat values from the query string as arrays. If the default state value for this search param isn't
  // an array, use null or extract the lone value instead of using an array with 0 or 1 elements
  if (!Array.isArray(defaultValue)) {
    if (searchParamResult.length === 0) {
      acquiredSearchParam = null;
    } else if (searchParamResult.length === 1) {
      acquiredSearchParam = searchParamResult[0];
    }
  }

  const searchParamsState = acquiredSearchParam ?? defaultValue;
  const memoOfSearchParamsState = useMemo(() => parseStateValueFromString(searchParamsState), [searchParams]);

  const setSearchParamsState = (newStateValue) => {
    const currentState = convertURLSearchParamsToObject(searchParams);
    let updatedState;
    if (typeof newStateValue === 'undefined') {
      updatedState = Object.fromEntries(Object.entries(currentState).filter(([key]) => key !== searchParamName));
    } else {
      updatedState = {
        ...currentState,
        [searchParamName]: newStateValue,
      };
    }

    setSearchParams(updatedState);
  };

  return [memoOfSearchParamsState, setSearchParamsState];
}

export function useMultipleSearchParamsState() {
  const [searchParams, setSearchParams] = useSearchParams();

  const setMultipleSearchParams = (newStates) => {
    const currentState = convertURLSearchParamsToObject(searchParams);

    const updatedState = {
      ...currentState,
      ...newStates,
    };

    setSearchParams(updatedState);
  };
  return setMultipleSearchParams;
}
