import React from 'react';
import PropTypes from 'prop-types';
import './FeedFloProgress.scss';

/***
 * ? DEPRECATED ?
 */
function FeedFloProgress({ percent = 0 }) {
  return (
    <div className="FeedFloProgress">
      <div className="inner" style={{ width: `${Math.round(percent * 100)}%` }} />
    </div>
  );
}

FeedFloProgress.propTypes = {
  percent: PropTypes.number,
};

export default FeedFloProgress;
