import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import './ListOfButtons.scss';

function ListOfButtons({ buttons = [], staffOnlybuttons = [], startingIndex = -1 }) {
  const [selectedIndex, setSelectedIndex] = useState(startingIndex || -1);
  const location = useLocation();
  const offset = buttons.length;

  useEffect(() => {
    buttons.forEach((button, i) => {
      if (button.pathMatch.test(location.pathname + decodeURIComponent(location.search))) {
        setSelectedIndex(i);
      }
    });

    staffOnlybuttons?.forEach((button, i) => {
      if (button.pathMatch.test(location.pathname)) {
        setSelectedIndex(i + offset);
      }
    });
  }, [location.pathname, location.search]);

  return (
    <>
      <div className="ListOfButtons-section">
        <ul className="buttonHolder">
          {buttons.map((button, i) => (
            <li
              key={`${button.url}`}
              className={`noselect ${selectedIndex === i ? 'selected' : ''} ${button.mobile ? '' : 'hideMobile'}`}
            >
              <Link className="ListOfButtons-link" to={button.url}>
                {button.icon}
                <div className="buttonText">{button.text}</div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {staffOnlybuttons?.length > 0 ? (
        <div className="ListOfButtons-section staffButtonHolder">
          <p className="staffOnlyTitle">Staff Only</p>
          <ul className="buttonHolder staffButtons">
            {staffOnlybuttons.map((button, i) => (
              <li
                key={`${button.url}`}
                className={`noselect ${selectedIndex === i + offset ? 'selected' : ''} ${
                  button.mobile ? '' : 'hideMobile'
                }`}
              >
                <Link className="ListOfButtons-link" to={button.url}>
                  {button.icon}
                  <div className="buttonText">{button.text}</div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
}

ListOfButtons.propTypes = {
  buttons: PropTypes.array,
  staffOnlybuttons: PropTypes.array,
  startingIndex: PropTypes.number,
};

export default ListOfButtons;
