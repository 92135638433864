import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useAtomValue } from 'jotai';
import WebAppContext from '../../utils/webAppContext';
import { useFeedFrameFilter, useAnalysisFilter } from '../../utils/useFeedFrameFilter';
import { weightSmallUnitLabel, convertGramsToSmallUnits, convertSmallUnitsToGrams } from '../../utils/unitConversion';
import { CalibrationType, CalibrationStatus } from '../../utils/enums';
import { DATE_TIME_DASH } from '../../utils/dates';
import useFormattedBinSetName from '../../utils/hooks/useFormattedBinSetName';
import { algorithmVersionAtom } from '../../utils/jotaiAtoms';
import { CircledCheckmarkIcon } from '../../atoms/Icons';
import InfoToolTip from '../../atoms/InfoToolTip';
import LoadingView from '../../atoms/LoadingView';
import FeedFloNumInput from '../../atoms/FeedFloNumInput';
import FeedFloButton from '../../atoms/FeedFloButton';
import CalibrationHeader from '../../molecules/CalibrationHeader';
import './CalibrationResultView.scss';

const GET_CALIBRATIONS_GQL = gql`
  query CalibrationTab_GetCalibrationByID($calibrationId: uuid!) {
    bin_set_calibration(where: { deleted_at: { _is_null: true }, id: { _eq: $calibrationId } }) {
      provided_mass_in_grams
      status
      type
      bin_set_id
      bin_set {
        bins {
          name
        }
      }
      started_at
      ended_at
    }
  }
`;

const UPDATE_KNOWN_WEIGHT_QUERY_GQL = gql`
  mutation UpdateKnownWeightMutation($id: uuid!, $providedMass: bigint!) {
    update_bin_set_calibration(where: { id: { _eq: $id } }, _set: { provided_mass_in_grams: $providedMass }) {
      affected_rows
    }
  }
`;

const FEED_FRAME_GQL = gql`
  query CalibrationResultViewFeedFrameQuery(
    $feedFrameWhere: feed_frame_bool_exp
    $feedFrameAnalysisWhere: feed_frame_analysis_bool_exp
  ) {
    feed_frame(where: $feedFrameWhere) {
      feed_frame_analyses(where: $feedFrameAnalysisWhere, order_by: { created_at: desc_nulls_last }, limit: 1) {
        latest_estimated_mass_moved_in_grams
      }
    }
  }
`;

const UPDATE_CALIBRATION_GQL = gql`
  mutation UpdateCalibrationMutation($calibrationId: uuid!, $providedMass: bigint!, $calibrationType: String!) {
    update_bin_set_calibration(
      where: { id: { _eq: $calibrationId } }
      _set: { provided_mass_in_grams: $providedMass, type: $calibrationType }
    ) {
      affected_rows
    }
  }
`;

const calculateAccuracy = (actualMass, predictedMass) => {
  let accuracyScore = 'N/A';

  if (actualMass > 0 && predictedMass >= 0) {
    const errorAbsolute = Math.abs(predictedMass - actualMass);
    const errorRatio = errorAbsolute / actualMass;
    accuracyScore = errorAbsolute === 0 ? 100 : Math.abs(Math.ceil((1 - errorRatio) * 100));

    if (accuracyScore < 0 || 100 < accuracyScore) {
      accuracyScore = 0;
    }

    accuracyScore += '%';
  }

  return accuracyScore;
};
function CalibrationResultView({ barnId = '' }) {
  const navigate = useNavigate();
  const { isMetric } = useContext(WebAppContext);
  const algorithmVersion = useAtomValue(algorithmVersionAtom);

  const { calibrationId } = useParams();
  const { formatBinSetName } = useFormattedBinSetName();

  const [calibrationData, setCalibrationData] = useState(null);
  const [knownWeightInputValidationMessage, setKnownWeightInputValidationMessage] = useState('');
  const [calibrationValidationMessage, setCalibrationValidationMessage] = useState('');

  const [status, setStatus] = useState(null);
  const [providedMass, setProvidedMass] = useState(null);
  const [displayAccuracy, setDisplayAccuracy] = useState(null);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  const [updateKnownWeight] = useMutation(UPDATE_KNOWN_WEIGHT_QUERY_GQL);
  const [saveCalibration] = useMutation(UPDATE_CALIBRATION_GQL);

  const binSetName = formatBinSetName(calibrationData?.bin_set?.bins?.map((bin) => bin.name));
  const startedAt = dayjs.tz(1000 * calibrationData?.started_at)?.format(DATE_TIME_DASH);
  const endedAt = dayjs.tz(1000 * calibrationData?.ended_at)?.format(DATE_TIME_DASH);

  const feedFrameFilter = useFeedFrameFilter({
    feed_line: {
      bin_set: {
        id: { _eq: calibrationData?.bin_set_id },
      },
    },
    started_at: { _gte: calibrationData?.started_at },
    ended_at: { _lte: calibrationData?.ended_at },
  });

  const analysisFilter = useAnalysisFilter({
    feed_frame: { feed_line: { bin_set: { id: { _eq: calibrationData?.bin_set_id } } } },
  });

  const { loading, data } = useQuery(FEED_FRAME_GQL, {
    variables: {
      feedFrameWhere: feedFrameFilter,
      feedFrameAnalysisWhere: analysisFilter,
    },
    onError: (error) => console.error(`CalibrationResultViewFeedFrameQuery: ${error}`),
    skip: !calibrationData?.bin_set_id,
  });
  const massMovedSmallUnits = useMemo(() => {
    const massMovedInGrams = (data?.feed_frame || []).reduce(
      (sum, feedFrame) => sum + feedFrame?.feed_frame_analyses?.[0]?.latest_estimated_mass_moved_in_grams || 0,
      0,
    );

    return convertGramsToSmallUnits(isMetric, massMovedInGrams, 0);
  }, [data]);

  useQuery(GET_CALIBRATIONS_GQL, {
    variables: {
      calibrationId,
    },
    onError: (error) => console.error(`calibrationQuery: ${error}`),
    onCompleted: (data) => {
      if (data?.bin_set_calibration?.[0]?.provided_mass_in_grams > 0) {
        setProvidedMass(convertGramsToSmallUnits(isMetric, data?.bin_set_calibration?.[0]?.provided_mass_in_grams, 0));

        if (massMovedSmallUnits) {
          setDisplayAccuracy(
            calculateAccuracy(
              convertGramsToSmallUnits(isMetric, data?.bin_set_calibration?.[0]?.provided_mass_in_grams, 0),
              massMovedSmallUnits,
            ),
          );
        }
      }

      if (data?.bin_set_calibration?.[0]) {
        setStatus(data?.bin_set_calibration?.[0].status);
        setCalibrationData(data?.bin_set_calibration?.[0]);
      }

      // If the page is finished loading AND we have fetched calibration information then check if we need to redirect
      // need to check both conditions to avoid memory leaks
      if (!showLoader) {
        redirectBasedOnStatus();
      }
    },
  });

  // To show the loading screen for at least 2 seconds so it does not look jumpy
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  // If the page is finished loading AND we have fetched calibration information then check if we need to redirect
  // need to check both conditions to avoid memory leaks
  useEffect(() => {
    if (status !== null) {
      redirectBasedOnStatus();
    }
  }, [showLoader]);

  useEffect(() => {
    setDisplayAccuracy(calculateAccuracy(providedMass, massMovedSmallUnits));
  }, [providedMass, massMovedSmallUnits]);

  const onKnownWeightChange = (weight) => {
    setProvidedMass(weight);
    setShowSaveButton(true);
  };

  const onClickSave = () => {
    if (validateKnownWeightInput()) {
      updateKnownWeight({
        variables: {
          id: calibrationId,
          providedMass: convertSmallUnitsToGrams(isMetric, providedMass, 0),
        },
        onError: (error) => console.error(`updateKnownWeight: ${error}`),
      });
    }
    setShowSaveButton(false);
  };

  const onClickCalibrateDebounce = debounce(() => {
    if (!validateCalibrationInput()) {
      return;
    }

    saveCalibration({
      variables: {
        calibrationId,
        providedMass: convertSmallUnitsToGrams(isMetric, providedMass, 0),
        calibrationType: CalibrationType.Calibration,
      },
      refetchQueries: ['CalibrationTab_GetCalibrationByID'],
      onError: (error) => console.error(`saveCalibration: ${error}`),
    });
    setShowSaveButton(false);
  }, 100);

  const validateKnownWeightInput = () => {
    if (!providedMass) {
      setKnownWeightInputValidationMessage('Enter the known weight.');
      return false;
    } else if (providedMass <= 0) {
      setKnownWeightInputValidationMessage('The known weight must be greater than 0.');
      return false;
    } else {
      setKnownWeightInputValidationMessage('');
      return true;
    }
  };

  const validateCalibrationInput = () => {
    if (!providedMass) {
      setCalibrationValidationMessage('The known weight is required for calibration.');
      return false;
    }

    return true;
  };

  const getAccuracyStatus = () => {
    if (displayAccuracy === 'N/A') {
      return 'Unknown';
    }

    const displayAccuracyInt = parseInt(displayAccuracy, 10);
    if (displayAccuracyInt >= 98) {
      return 'Excellent';
    } else if (displayAccuracyInt >= 95) {
      return 'Good';
    } else if (displayAccuracyInt >= 90) {
      return 'Average';
    } else {
      return 'Poor';
    }
  };

  // redirect to the appropriate location if applicable
  const redirectBasedOnStatus = () => {
    if (status === CalibrationStatus.FormStarted) {
      navigate(`/b/${barnId}/calibrations/${calibrationId}/setup`);
    } else if (status === CalibrationStatus.FormSubmitted || status === CalibrationStatus.Calculating) {
      navigate(`/b/${barnId}/calibrations/${calibrationId}/test`);
    } else if (status === CalibrationStatus.Complete) {
      navigate(`/b/${barnId}/calibrations/${calibrationId}/result`);
    }
  };

  if (showLoader || loading || !algorithmVersion) {
    return (
      <div className="CalibrationResultView--loading">
        <LoadingView />
      </div>
    );
  }

  return (
    <div className="CalibrationResultView">
      <div className="CalibrationResultView-header">
        <CalibrationHeader
          barnId={barnId}
          saveButtonVisible={status === CalibrationStatus.FormSubmitted && showSaveButton}
          onClickSave={onClickSave}
        />
      </div>

      <div className="CalibrationResultView-body">
        <div className="CalibrationResultView-leftSide">
          {/* This renders if the status of this calibration is complete and the type has been updated to calibration. */}
          {calibrationData?.type === CalibrationType.Calibration && status === CalibrationStatus.Complete ? (
            <div>
              <div className="CalibrationResultView-calibrationSetTitleContainer">
                <CircledCheckmarkIcon className="CalibrationResultView-calibrationSetIcon" />
                <p>Calibration Set</p>
              </div>
              <p>
                This test has been set as a calibration. FeedFlo has updated to ensure that the accuracy of future runs
                and tests are improved.
              </p>
              <div className="CalibrationResultView-calibrationSetBody">
                <div className="CalibrationResultView-card">
                  <div className="CalibrationResultView-cardTitleContainer">
                    <p className="CalibrationResultView-cardContent CalibrationResultView-cardTitle">
                      Updated Accuracy
                    </p>
                    <InfoToolTip text="This is the accuracy of your FeedFlo sensor. This accuracy value is calculated by comparing the feed weight detected by your FeedFlo sensor against the known weight and start and end times you inputted." />
                  </div>
                  <p className="CalibrationResultView-cardContent CalibrationResultView-cardData CalibrationResultView-updatedAccuracyCard">
                    {displayAccuracy}
                  </p>
                  <p className="CalibrationResultView-cardContent CalibrationResultView-accuracyGradeContainer">
                    <span
                      className={
                        `CalibrationResultView-accuracyGrade CalibrationResultView-accuracyGrade--` +
                        getAccuracyStatus()
                      }
                    >
                      {getAccuracyStatus() + ' '}
                    </span>
                    Accuracy
                  </p>
                </div>
                <p>Until the next calibration, FeedFlo will use this test run as a baseline.</p>
              </div>
            </div>
          ) : (
            /* When this is still a test or the calibration status === calculating */
            <>
              <div className="CalibrationResultView-leftSideTilesContainer">
                <div className="CalibrationResultView-leftSideTileContainer">
                  <p className="CalibrationResultView-leftSideTileLabel">Bin Set</p>
                  <p className="CalibrationResultView-leftSideTileData">{binSetName}</p>
                </div>
                <div className="CalibrationResultView-leftSideTileContainer">
                  <p className="CalibrationResultView-leftSideTileLabel">Start Time</p>
                  <p className="CalibrationResultView-leftSideTileData">{startedAt}</p>
                </div>
                <div className="CalibrationResultView-leftSideTileContainer">
                  <p className="CalibrationResultView-leftSideTileLabel">EndTime</p>
                  <p className="CalibrationResultView-leftSideTileData">{endedAt}</p>
                </div>
              </div>

              <div className="CalibrationResultView-leftSideCardsContainer">
                <div className="CalibrationResultView-card CalibrationResultView-leftSideCard">
                  <p className="CalibrationResultView-cardContent CalibrationResultView-cardTitle">FeedFlo Results</p>
                  <p className="CalibrationResultView-cardContent CalibrationResultView-cardData">
                    {massMovedSmallUnits.toLocaleString()} {weightSmallUnitLabel(isMetric)}
                  </p>
                  <p className="CalibrationResultView-cardContent CalibrationResultView-accuracyGradeContainer">
                    Total weight of feed detected running through system
                  </p>
                </div>
                <div className="CalibrationResultView-card CalibrationResultView-leftSideCard">
                  <div className="CalibrationResultView-cardTitleContainer">
                    <p className="CalibrationResultView-cardContent CalibrationResultView-cardTitle">Accuracy Score</p>
                    <InfoToolTip
                      className="CalibrationResultView-InfoToolTip"
                      text="This is the accuracy of your FeedFlo sensor. This accuracy value is calculated by comparing the feed weight detected by your FeedFlo sensor against the known weight and start and end times you inputted."
                    />
                  </div>

                  <p className="CalibrationResultView-cardContent CalibrationResultView-cardData">{displayAccuracy}</p>
                  <p className="CalibrationResultView-cardContent CalibrationResultView-accuracyGradeContainer">
                    <span
                      className={
                        `CalibrationResultView-accuracyGrade CalibrationResultView-accuracyGrade--` +
                        getAccuracyStatus()
                      }
                    >
                      {getAccuracyStatus() + ' '}
                    </span>
                    Accuracy
                  </p>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Right side */}
        <div className="CalibrationResultView-rightSide">
          {/* Only show the known weight section when testing.*/}
          {calibrationData?.type === CalibrationType.Test ? (
            <div>
              <p className="CalibrationResultView-KnownWeightTitle">Known Weight</p>
              <p className="CalibrationResultView-KnownWeightSubTitle">
                The Accuracy Score is based on the entered Known Weight of the feed moving through the system.
              </p>
              <label>Known Weight</label>
              <div className="CalibrationResultView-knownWeightContainer">
                <FeedFloNumInput
                  inputClassName="CalibrationResultView-knownWeightInput"
                  onChange={onKnownWeightChange}
                  maxLength={6}
                  value={providedMass?.toString()}
                  allowSingleAndLeadingZeros={false}
                  disabled={status === CalibrationStatus.Calculating}
                />
                <span className="CalibrationResultView-knownWeightUnit">{weightSmallUnitLabel(isMetric)}</span>
              </div>
              <p className="CalibrationResultView-validationMessage">{knownWeightInputValidationMessage}</p>
            </div>
          ) : null}

          {/* This renders if the status of this test is form_submitted.
          The user can enter the known weight to test the calibration accuracy. They can also set the test to a calibration. */}
          {calibrationData?.type === CalibrationType.Test && status === CalibrationStatus.FormSubmitted ? (
            <div>
              <p className="CalibrationResultView-KnownWeightTitle">Set Calibration</p>
              <p className="CalibrationResultView-KnownWeightSubTitle">
                To increase FeedFlos accuracy for future runs, set this run as a calibration and FeedFlo will update
                based on the test results and your Known Weight.
              </p>

              <FeedFloButton
                className="CalibrationResultView-button"
                type="secondary"
                onClick={onClickCalibrateDebounce}
              >
                Set as Calibration
              </FeedFloButton>
              {providedMass ? null : (
                <p className="CalibrationResultView-validationMessage">{calibrationValidationMessage}</p>
              )}
            </div>
          ) : null}

          {calibrationData?.type === CalibrationType.Calibration &&
          (status === CalibrationStatus.FormSubmitted || status === CalibrationStatus.Calculating) ? (
            <div className="CalibrationResultView-calibrationInProcessContainer">
              <p className="CalibrationResultView-KnownWeightTitle">FeedFlo Calculating...</p>
              <img className="CalibrationResultView-logo" src="/images/Feedflo-logo.svg" alt="FeedFlo Logo" />

              <p className="CalibrationResultView-calibrationInProcessContainerInformation">
                Our systems are updating the sensor calibrations, please wait.
              </p>
              <p className="CalibrationResultView-calibrationEstimate">This may take up to 72 hours.</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

CalibrationResultView.propTypes = {
  barnId: PropTypes.string,
};

export default CalibrationResultView;
