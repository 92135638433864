import { useEffect, useState } from 'react';

const BANNER_TEXT_URL = import.meta.env.VITE_BANNER_TEXT_URL;
export default function useAppBanner() {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState(null);
  useEffect(() => {
    async function getBannerData() {
      try {
        // using the constructor to validate the URL param
        const bannerTextUrl = new URL(BANNER_TEXT_URL);
        const resRaw = await fetch(bannerTextUrl?.toString());
        const res = (await resRaw.text())?.trim();

        if (!res || res === 'null' || res[0] === '<') {
          // show nothing if this is bad
          setShow(false);
          setMessage(null);
        } else {
          setShow(true);
          setMessage(res);
        }
      } catch (e) {
        console.warn('Banner text URL not valid: ', e);
        setShow(false);
        setMessage(null);
      }
    }
    getBannerData();
  }, []);

  return {
    show,
    message,
  };
}
