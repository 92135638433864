import PropTypes from 'prop-types';
import CalibrationProcessChainNode from './CalibrationProcessChainNode';
import './CalibrationProcessChain.scss';

function CalibrationProcessChain({
  className = '',
  currentStepNumber = 1,
  onClickPrevious = () => {},
  onClickContinue = () => {},
}) {
  return (
    <div className={`CalibrationProcessChain ${className}`}>
      <p className="CalibrationProcessChain-title">FEEDFLO AUTO-TESTING PROCESS</p>
      <div className="CalibrationProcessChain-nodes">
        <CalibrationProcessChainNode
          stepNumber={1}
          stepHeading="Empty a Bin in Your Test Line"
          stepInstructions="FeedFlo runs a test on a full bin turn. To prepare for a test, make sure a bin in the feed line you are
          testing is empty."
          previousButtonEnable={false}
          currentStep={currentStepNumber === 1}
          stepCompleted={currentStepNumber > 1}
          onClickContinue={onClickContinue}
        />

        <CalibrationProcessChainNode
          stepNumber={2}
          stepHeading="Deliver Feed to the Bin"
          stepInstructions="Deliver a known weight of feed to the empty test bin. Record this as the starting known weight. You will be able to input this number later, but make sure to record it. Close the other bin shutter(s) on the line so that feed runs only out of the test bin."
          currentStep={currentStepNumber === 2}
          stepCompleted={currentStepNumber > 2}
          onClickPrevious={onClickPrevious}
          onClickContinue={onClickContinue}
        />

        <CalibrationProcessChainNode
          stepNumber={3}
          stepHeading="Record Start Time"
          stepInstructions="Open the test bin shutter and record the time to the minute. This is the start time of the test."
          currentStep={currentStepNumber === 3}
          stepCompleted={currentStepNumber > 3}
          onClickPrevious={onClickPrevious}
          onClickContinue={onClickContinue}
        />

        <CalibrationProcessChainNode
          stepNumber={4}
          stepHeading="Run Bin Until Empty"
          stepInstructions="You will get a notification of an empty pipe from FeedFlo when the bin is empty. Close the shutter and record the end time."
          currentStep={currentStepNumber === 4}
          stepCompleted={currentStepNumber > 4}
          onClickPrevious={onClickPrevious}
          onClickContinue={onClickContinue}
        />

        <CalibrationProcessChainNode
          stepNumber={5}
          stepHeading="Calculate Results"
          continueButtonEnable={false}
          currentStep={currentStepNumber === 5}
          stepCompleted={currentStepNumber >= 5}
          onClickPrevious={onClickPrevious}
        />
      </div>
    </div>
  );
}

CalibrationProcessChain.propTypes = {
  className: PropTypes.string,
  currentStepNumber: PropTypes.number,
  onClickPrevious: PropTypes.func,
  onClickContinue: PropTypes.func,
};

export default CalibrationProcessChain;
