import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';
import WebAppContext from '../../utils/webAppContext';
import { weightSmallUnitLabel } from '../../utils/unitConversion';
import useFormattedBinSetName from '../../utils/hooks/useFormattedBinSetName';
import { REACT_DATEPICKER_DATE_TIME_MONTH_DAY_YEAR_HOUR_MINUTE } from '../../utils/dates';
import { CalendarIcon } from '../../atoms/Icons';
import FeedFloDropDown from '../../atoms/FeedFloDropDown';
import FeedFloNumInput from '../../atoms/FeedFloNumInput';
import FeedFloButton from '../../atoms/FeedFloButton';
import InfoToolTip from '../../atoms/InfoToolTip';
import './CalibrationForm.scss';

function CalibrationForm({
  className = '',
  binSets = [],
  selectedBinSetId = '',
  knownWeight = '',
  startedAt,
  endedAt,
  formSubmitted = false,
  onStartedAtUpdate = () => {},
  onEndedAtUpdate = () => {},
  onBinSetUpdate = () => {},
  onKnownWeightUpdate = () => {},
  onClickCalculate = () => {},
}) {
  const { isMetric } = useContext(WebAppContext);
  const { formatBinSetName } = useFormattedBinSetName();
  const minStartAt = useMemo(() => dayjs.tz().subtract(3, 'months').toDate(), []);
  const isBinSetSelected = (id) => id === selectedBinSetId;

  const allBinSets = binSets.map((binSet) => ({
    id: binSet?.id,
    name: formatBinSetName(binSet?.bins?.map((bin) => bin.name)),
    selected: isBinSetSelected(binSet.id),
  }));

  const onBinSetSelect = (selectedBinSet) => {
    onBinSetUpdate(selectedBinSet);
  };

  const onKnownWeightChange = (weight) => {
    onKnownWeightUpdate(weight);
  };

  const onStartedAtChange = (date) => {
    onStartedAtUpdate(date);
  };

  const onEndedAtChange = (date) => {
    if (date === null) {
      onEndedAtUpdate(null);
      return;
    }

    // Round up end date for precision difference between datepicker and Unix timestamp, preventing exclusion of feed frames in queries.
    onEndedAtUpdate(dayjs.tz(date).endOf('minute').toDate());
  };

  const onClickCalculateDebounce = debounce(onClickCalculate, 500);

  return (
    <div className={`CalibrationForm ${className} ${formSubmitted ? 'CalibrationForm--readOnly' : ''}`}>
      <div className="CalibrationForm-binSet">
        <label className="CalibrationForm-label">
          <span className="CalibrationForm-required">*</span>Bin Set
        </label>
        <div className="CalibrationForm-binSetInputWrapper">
          <FeedFloDropDown inputClassName="CalibrationForm-binSetInput" list={allBinSets} onChange={onBinSetSelect} />
          <InfoToolTip
            className="CalibrationForm-tooltipIcon"
            text="The feed lines connected to the bin set you are testing."
          />
        </div>
      </div>
      <div className="CalibrationForm-knownWeightSection">
        <label className="CalibrationForm-label">Known Weight</label>
        <div className="CalibrationForm-tooltipIconWrapper">
          <div className="CalibrationForm-inputIconWrapper">
            <FeedFloNumInput
              inputClassName="CalibrationForm-knownWeightInput"
              onChange={onKnownWeightChange}
              maxLength={10}
              value={knownWeight}
              allowSingleAndLeadingZeros={false}
            />
            <span className="CalibrationForm-unitLabel">{weightSmallUnitLabel(isMetric)}</span>
          </div>
          <InfoToolTip className="CalibrationForm-tooltipIcon" text="The amount of feed delivered to the bin." />
        </div>
      </div>
      <div className="CalibrationForm-startTime">
        <label className="CalibrationForm-label">
          <span className="CalibrationForm-required">*</span>Start Time
        </label>
        <div className="CalibrationForm-tooltipIconWrapper">
          <div className="CalibrationForm-inputIconWrapper">
            <DatePicker
              className="CalibrationForm-datepickerInput"
              wrapperClassName="CalibrationForm-datepickerWrapper"
              popperClassName="CalibrationForm-datepickerPopper"
              calendarClassName="CalibrationForm-datepickerCalendar"
              selected={startedAt}
              onChange={onStartedAtChange}
              startDate={startedAt}
              endDate={endedAt}
              minDate={minStartAt}
              showTimeInput
              selectsStart
              shouldCloseOnSelect={false}
              dateFormat={REACT_DATEPICKER_DATE_TIME_MONTH_DAY_YEAR_HOUR_MINUTE}
            />
            <CalendarIcon className="CalibrationForm-datepickerIcon" />
          </div>
          <InfoToolTip className="CalibrationForm-tooltipIcon" text="The time you opened the bin shutter." />
        </div>
      </div>
      <div className="CalibrationForm-endTime">
        <label className="CalibrationForm-label">
          <span className="CalibrationForm-required">*</span>End Time
        </label>
        <div className="CalibrationForm-tooltipIconWrapper">
          <div className="CalibrationForm-inputIconWrapper">
            <DatePicker
              className="CalibrationForm-datepickerInput"
              wrapperClassName="CalibrationForm-datepickerWrapper"
              calendarClassName="CalibrationForm-datepickerCalendar"
              selectsEnd
              selected={endedAt}
              onChange={onEndedAtChange}
              endDate={endedAt}
              startDate={startedAt}
              minDate={startedAt}
              showTimeInput
              shouldCloseOnSelect={false}
              dateFormat={REACT_DATEPICKER_DATE_TIME_MONTH_DAY_YEAR_HOUR_MINUTE}
            />
            <CalendarIcon className="CalibrationForm-datepickerIcon" />
          </div>
          <InfoToolTip className="CalibrationForm-tooltipIcon" text="The time you closed the bin shutter." />
        </div>
      </div>
      <p className="CalibrationForm-label">
        <span className="CalibrationForm-required">*</span>required fields
      </p>
      <FeedFloButton
        className={`CalibrationForm-calculateButton ${
          formSubmitted ? 'CalibrationForm-calculateButton--readOnly' : ''
        }`}
        type="secondary"
        onClick={onClickCalculateDebounce}
        disabled={formSubmitted}
      >
        Calculate Results
      </FeedFloButton>
    </div>
  );
}

CalibrationForm.propTypes = {
  className: PropTypes.string,
  binSets: PropTypes.array,
  selectedBinSetId: PropTypes.string,
  knownWeight: PropTypes.string,
  startedAt: PropTypes.object,
  endedAt: PropTypes.object,
  formSubmitted: PropTypes.bool,
  onStartedAtUpdate: PropTypes.func,
  onEndedAtUpdate: PropTypes.func,
  onBinSetUpdate: PropTypes.func,
  onKnownWeightUpdate: PropTypes.func,
  onClickCalculate: PropTypes.func,
};

export default CalibrationForm;
