import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleTime } from '@visx/scale';
import { Group } from '@visx/group';
import { GridColumns, GridRows } from '@visx/grid';
import { LinePath, Line, Circle } from '@visx/shape';
import { max, bisect } from 'd3-array';
import useUser from '../../utils/hooks/useUser';

import { getY, tickFormatFactory, calculateXAxisTickValues } from '../../utils/chartHelpers';

import PropTypes from 'prop-types';
import { TooltipWithBounds, useTooltip, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE } from '../../utils/dates';
import { convertGramsToLargeUnits, weightLargeUnitLabel } from '../../utils/unitConversion';

const colourPalette = {
  calculated: '#26AF5F',
  projected: '#26AF5F', // same colour as calculated to show it continues
  historicDataColour: '#9be09b', // historic live data aka Calibrated (Live Data)
  deliveries: '#217BCE',
  truthData: '#65C9DA',
  binSetLevel: '#f2c94c', // purpose "Test" (not used for anything but visuals)
  binSetLevelCorrection: '#8B7229', // AKA Reset/Snap
  binSetLevelCalibration: '#4682B4', // purpose "Calibrate"
  calibrations: '#BA6057', // Visualing when a coefficient is used
};

const TOOLTIP_STYLES = {
  ...defaultStyles,
  borderRadius: '8px',
  backgroundColor: 'rgba(40, 40, 40, 0.8)',
  color: 'white',
  border: '1px solid #E6E6E6',
  padding: '1rem',
};

function CalibrationChart({
  startDate = 1706625546,
  endDate = 1722350354,
  chartSeries,
  binSetLevels,
  binSetCalibrations,
  onBinSetLevelClicked = (id) => {
    console.log(id);
  },
  onBinSetCalibrationClicked = (id) => {
    console.log(id);
  },
  selectedBinSetLevel,
  selectedBinSetCalibration,
  loading,
}) {
  const { user } = useUser();
  const isMetric = user?.isMetric || false;
  const { tooltipData, tooltipLeft = 0, tooltipTop = 0, tooltipOpen, showTooltip, hideTooltip } = useTooltip();
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);
  const chartMargin = { top: 10, bottom: 50, left: 30, right: 30 };
  const [zoomStartPoint, setZoomStartPoint] = useState(null);
  const [zoomEndPoint, setZoomEndPoint] = useState(null);

  useEffect(() => {
    setStart(startDate);
    setEnd(endDate);
  }, [startDate, endDate]);

  /**
   * Given two points and a timestamp between them, return the value that would correspond to that timestamp.
   *
   * @param p0 The first point to lerp between.
   * @param p1 The second point to lerp between.
   * @param timestamp The point between p0 and p1 to calculate the value for.
   * @returns The value corresponding to the given timestamp between p0 and p1.
   */
  const lerpToTimestamp = useCallback(
    /**
     * @param {Object} p0
     * @param {number} p0.value
     * @param {number} p0.timestamp
     * @param {Object} p1
     * @param {number} p1.value
     * @param {number} p1.timestamp
     * @param {number} timestamp
     */
    (p0, p1, timestamp) => {
      // Guard against NaN slope/vertical line by returning the initial p0 value.
      if (p0.timestamp === p1.timestamp) return p0.value;
      const slope = (p1.value - p0.value) / (p1.timestamp - p0.timestamp);
      return p0.value + (timestamp - p0.timestamp) * slope;
    },
    [],
  );

  return (
    <ParentSize debounceTime={10}>
      {(parent) => {
        const xMax = parent.width - chartMargin.left - chartMargin.right;
        const yMax = parent.height - chartMargin.top - chartMargin.bottom;

        const xAxisTickFormat = tickFormatFactory(start, end);
        const xAxisTicks = calculateXAxisTickValues(start, end);

        const xScale = useCallback(
          scaleTime({
            domain: [start, end],
            range: [0, xMax],
          }),
          [start, end, xMax],
        );
        const yScale = useCallback(
          scaleLinear({
            domain: [0, 60],
            range: [yMax, 0],
          }),
          [yMax],
        );

        const formatDateTooltip = useCallback((hoveredDate) => {
          return dayjs.unix(hoveredDate).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE);
        }, []);

        /**
         * Given a timestamp, find points that intersect with each chart line.
         * Points are generated by interpolation.
         *
         * @param timestamp The timestamp to intersect with each chart line.
         *
         * @returns An array of point objects with value, timestamp, and colour keys.
         */
        const getInterceptPointsForTimestamp = useCallback(
          /**
           * @param {number} timestamp
           * @returns {Array<{value: number, timestamp: number, colour: string, hidden: boolean}>}
           */
          (timestamp) =>
            // Note: we are NOT filtering here because we care about maintaining the indexes for parallel arrays when hiding
            // Instead we will simply pass along the hidden property
            chartSeries
              .map((series) => {
                let point = {};
                const index = bisect(series.x, new Date(timestamp * 1000));
                if (series.x[index - 1] && series.x[index]) {
                  const point0 = { timestamp: series.x[index - 1].getTime() / 1000, value: series.y[index - 1] };
                  const point1 = { timestamp: series.x[index].getTime() / 1000, value: series.y[index] };
                  point = point0 || point1;
                  if (point0 && point1) {
                    // If both points exist, lerp between them to estimate the value at the given timestamp
                    const value = lerpToTimestamp(point0, point1, timestamp);
                    point = { timestamp, value };
                  }
                }
                return { ...point, colour: series.line.color };
              })
              .filter((series) => series?.timestamp),
          [chartSeries],
        );

        const handleTooltip = useCallback(
          (mouseEvent) => {
            // Gets the position of the mouse cursor.
            const point = localPoint(mouseEvent);
            const xCoord = point.x - chartMargin.left;

            // If the mouse pointer is off the chart, we don't want to display any tool tips.
            if (xCoord < 0 || xCoord > xMax) return;

            const xCoordTimestamp = xScale.invert(xCoord).valueOf();

            // Gets the nearest intercept point to the current mouse X position for each chartLineData entry.
            // Array of objects like { timestamp, value, colour, hidden }

            const lineIntercepts = getInterceptPointsForTimestamp(xCoordTimestamp);

            if (lineIntercepts) {
              const xCoordDate = xScale.invert(xCoord);
              const maxYIntercept = max(lineIntercepts, getY);
              const formattedDate = formatDateTooltip(xCoordDate);

              showTooltip({
                tooltipLeft: xCoord,
                tooltipTop: yScale(maxYIntercept),
                tooltipData: {
                  lineIntercepts,
                  // projectionLineIntercepts,
                  displayedDate: formattedDate,
                },
              });
            }
          },
          [chartSeries, xMax, xScale, yScale, getInterceptPointsForTimestamp],
        );

        const linePaths = useMemo(() => {
          return chartSeries
            .filter(({ type }) => type === 'scatter')
            .map((chartLineData, index) => {
              const x = chartLineData.x.filter((v) => v?.valueOf() / 1000 <= end && v?.valueOf() / 1000 >= start);
              const y = chartLineData.y.filter(
                (v, i) => chartLineData.x[i]?.valueOf() / 1000 <= end && chartLineData.x[i]?.valueOf() / 1000 >= start,
              );
              return (
                <LinePath
                  data={x}
                  x={(value) => {
                    return xScale(value?.valueOf() / 1000);
                  }}
                  y={(value, index) => {
                    return yScale(y[index]);
                  }}
                  stroke={chartLineData.line.color}
                  strokeWidth={2}
                  strokeOpacity={1}
                  key={'ChartLinePath' + index}
                  className={'ChartLinePath' + index}
                />
              );
            });
        }, [xScale, yScale, chartSeries.map((x) => x.y.length).join('-')]);

        const svgCursor = loading ? 'wait' : zoomStartPoint && zoomEndPoint ? 'zoom-in' : 'crosshair';

        return (
          <div className="EventChart" key={'EventChart'}>
            <svg
              className="EventChart-chartSvg"
              style={{
                height: parent.height,
                width: parent.width,
                userSelect: 'none',
                cursor: svgCursor,
              }}
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={(event) => {
                handleTooltip(event);
                if (zoomStartPoint) {
                  setZoomEndPoint(localPoint(event).x);
                }
              }}
              onMouseLeave={hideTooltip}
              onMouseDown={(event) => {
                setZoomStartPoint(localPoint(event).x);
              }}
              onMouseUp={() => {
                const startPoint = zoomStartPoint > zoomEndPoint ? zoomEndPoint : zoomStartPoint;
                const endPoint = zoomStartPoint <= zoomEndPoint ? zoomEndPoint : zoomStartPoint;

                if (startPoint && endPoint && 20 < endPoint - startPoint) {
                  setStart(xScale.invert(startPoint - chartMargin.left));
                  setEnd(xScale.invert(endPoint - chartMargin.left));
                }

                setZoomStartPoint(null);
                setZoomEndPoint(null);
              }}
              onDoubleClick={() => {
                setStart(startDate);
                setEnd(endDate);
              }}
            >
              <Group
                left={chartMargin.left}
                top={chartMargin.top}
                bottom={chartMargin.bottom}
                right={chartMargin.right}
              >
                <defs>
                  <clipPath id="eventInnerChartHorizontalBoundary">
                    <rect x={0} y={0} width={xMax} height={yMax} />
                  </clipPath>
                </defs>
                <GridColumns
                  scale={xScale}
                  width={xMax}
                  height={yMax}
                  stroke="#D9DCE1"
                  strokeWidth={2}
                  tickValues={xAxisTicks}
                />

                <AxisBottom
                  top={yMax}
                  scale={xScale}
                  hideTicks
                  stroke="#D9DCE1"
                  tickFormat={xAxisTickFormat}
                  tickValues={xAxisTicks}
                />

                <GridRows scale={yScale} width={xMax} height={yMax} stroke="#D9DCE1" strokeWidth={2} numTicks={5} />

                <AxisLeft
                  top={0}
                  scale={yScale}
                  hideTicks
                  stroke="#D9DCE1"
                  // tickFormat={yAxisTickFormat}
                  // tickValues={xAxisTicks}
                  numTicks={5}
                />

                {
                  //                   const startPoint = zoomStartPoint > zoomEndPoint ? zoomEndPoint : zoomStartPoint;
                  // const endPoint = zoomStartPoint <= zoomEndPoint ? zoomEndPoint : zoomStartPoint;

                  zoomStartPoint && zoomEndPoint && (
                    <rect
                      height={yMax}
                      y={0}
                      x={(zoomStartPoint < zoomEndPoint ? zoomStartPoint : zoomEndPoint) - chartMargin.left}
                      width={Math.abs(zoomEndPoint - zoomStartPoint)}
                      fill={`#00000022`}
                    ></rect>
                  )
                }

                {
                  // Create the vertical dashed line and circular intercept markers that accompany the tooltip.
                  // Do not show if someone is interacting with the projection brushes.
                  tooltipOpen && (
                    <g pointerEvents="none">
                      <line
                        stroke="#000"
                        strokeWidth="1"
                        x1={tooltipLeft}
                        x2={tooltipLeft}
                        y1={0}
                        y2={yMax}
                        strokeDasharray={(5, 5)}
                      />
                      {
                        // Place (solid) circular intercept markers for all visible chart lines.
                        tooltipData.lineIntercepts.reduce((lineIntercepts, { value, timestamp, colour }, index) => {
                          if (!timestamp || !value) return lineIntercepts;
                          // The X dot coord should never exceed the rightmost line point.
                          // const xLimit = xScale(chartLines[index].linePlot.at(-1).timestamp);
                          // If not snapping to point data, we use the same X position as the vertical line for a smoother appearance.
                          // This comes at the cost of some visual accuracy compared to using xScale(timestamp).
                          // In either case, compare to the X limit and choose the leftmost value.
                          const x = xScale(timestamp); // Math.min(snapToPoints ? xScale(timestamp) : tooltipLeft, xLimit);
                          const y = yScale(value);
                          lineIntercepts.push(
                            <Circle
                              key={index}
                              className="EventChart-chartInterceptCircleSvg"
                              cx={x}
                              cy={y}
                              r={7}
                              stroke={colour}
                            />,
                          );
                          return lineIntercepts;
                        }, [])
                      }
                    </g>
                  )
                }

                {linePaths}
                {binSetCalibrations
                  ?.filter((cal) => {
                    return cal.started_at && cal.started_at != -1 && cal.ended_at && cal.ended_at != -1;
                  })
                  .map((cal, index) => {
                    const height = 50;
                    const barHeight = 25;
                    const start = cal.started_at;
                    const end = cal.ended_at;
                    const top = height + barHeight / 2;
                    const bottom = height - barHeight / 2;
                    let color = colourPalette.calibrations;

                    const onClick = () => {
                      onBinSetCalibrationClicked(cal.id);
                    };

                    return (
                      <>
                        {selectedBinSetCalibration == cal.id && (
                          <rect
                            x={xScale(start) - 6}
                            y={bottom - 3}
                            width={xScale(end) - xScale(start) + 12}
                            height={top - bottom + 6}
                            fill={`${color}44`}
                            rx={3}
                          />
                        )}
                        <Line
                          from={{ x: xScale(start), y: height }}
                          to={{ x: xScale(end), y: height }}
                          stroke={color}
                          strokeWidth={3}
                          strokeOpacity={1}
                          key={`binSetCal-${index}-top`}
                          onClick={onClick}
                        />
                        <Line
                          from={{ x: xScale(start), y: top }}
                          to={{ x: xScale(start), y: bottom }}
                          stroke={color}
                          strokeWidth={3}
                          strokeOpacity={1}
                          key={`binSetCal-${index}-middle`}
                          onClick={onClick}
                        />
                        <Line
                          from={{ x: xScale(end), y: top }}
                          to={{ x: xScale(end), y: bottom }}
                          stroke={color}
                          strokeWidth={3}
                          strokeOpacity={1}
                          key={`binSetCal-${index}-bottom`}
                          onClick={onClick}
                        />
                        <rect
                          x={xScale(start) - 6}
                          y={bottom - 3}
                          width={xScale(end) - xScale(start) + 12}
                          height={top - bottom + 6}
                          fill={`#00000000`}
                          key={`binSetCal-${index}-hitbox`}
                          style={{ cursor: 'pointer' }}
                          onClick={onClick}
                        />
                      </>
                    );
                  })}

                {
                  // Chart each of the line paths, filtering out hidden ones.
                  binSetLevels
                    ?.filter(({ valid_at }) => valid_at >= startDate && valid_at <= endDate)
                    ?.map((binLevel, index) => {
                      const level = convertGramsToLargeUnits(isMetric, binLevel.level_in_grams);
                      const validAt = binLevel.valid_at;
                      const error = binLevel.expected_deviation_in_grams
                        ? convertGramsToLargeUnits(isMetric, binLevel.expected_deviation_in_grams)
                        : 0.5;
                      const top = level + error;
                      const bottom = level - error;
                      const timeMargin = Math.max(xScale(12 * 60 * 60) - xScale(0), 4);
                      let color = '#000000';
                      switch (binLevel.purpose) {
                        case 'reset':
                          color = colourPalette.binSetLevelCorrection;
                          break;
                        case 'calibrate':
                          color = colourPalette.binSetLevelCalibration;
                          break;
                        case 'test':
                          color = colourPalette.binSetLevel;
                          break;

                        default:
                          break;
                      }

                      const onClick = () => {
                        onBinSetLevelClicked(binLevel.id);
                      };

                      const hitboxMargin = 15;

                      return (
                        <>
                          {selectedBinSetLevel == binLevel.id && (
                            <rect
                              x={xScale(validAt) - timeMargin - 3}
                              y={yScale(top) - 6}
                              width={timeMargin * 2 + 6}
                              height={yScale(bottom) - yScale(top) + 12}
                              fill={`${color}44`}
                              rx={3}
                            />
                          )}
                          <Line
                            from={{ x: xScale(validAt) - timeMargin, y: yScale(top) }}
                            to={{ x: xScale(validAt) + timeMargin, y: yScale(top) }}
                            stroke={color}
                            strokeWidth={3}
                            strokeOpacity={1}
                            key={`${index}-top`}
                            onClick={onClick}
                          />
                          <Line
                            from={{ x: xScale(validAt), y: yScale(top) }}
                            to={{ x: xScale(validAt), y: yScale(bottom) }}
                            stroke={color}
                            strokeWidth={3}
                            strokeOpacity={1}
                            key={`${index}-middle`}
                            onClick={onClick}
                          />
                          <Line
                            from={{ x: xScale(validAt) - timeMargin, y: yScale(bottom) }}
                            to={{ x: xScale(validAt) + timeMargin, y: yScale(bottom) }}
                            stroke={color}
                            strokeWidth={3}
                            strokeOpacity={1}
                            key={`${index}-bottom`}
                            onClick={onClick}
                          />
                          <rect
                            x={xScale(validAt) - timeMargin - hitboxMargin / 2}
                            y={yScale(top) - hitboxMargin / 2}
                            width={timeMargin * 2 + hitboxMargin}
                            height={yScale(bottom) - yScale(top) + hitboxMargin}
                            fill={`#00000000`}
                            style={{ cursor: 'pointer' }}
                            onClick={onClick}
                          />
                        </>
                      );
                    })
                }
              </Group>
            </svg>
            {
              // Create the tooltip unless someone is interacting with the projection brushes.
              tooltipOpen && (
                <TooltipWithBounds top={tooltipTop - 50} left={tooltipLeft + 35} style={TOOLTIP_STYLES}>
                  <div className={`EventChart-tooltipInfo`}>
                    {
                      <>
                        <p className="EventChart-tooltipInfoHeader">{tooltipData.displayedDate}</p>
                        <div className="EventChart-tooltipInfo-dataholder">
                          {tooltipData.lineIntercepts.reduce((tooltipValueRow, { value, colour }, index) => {
                            // Store the projected value if the projection window is enabled.
                            tooltipValueRow.push(
                              <React.Fragment key={index}>
                                <div className="EventChart-tooltipInfo-dataholder-mass">
                                  <div className={`EventChart-tooltipCircle`} style={{ backgroundColor: colour }} />
                                  <span className="EventChart-tooltipInfo-dataholder-text">
                                    {Number.parseFloat(value).toFixed(2)} {weightLargeUnitLabel(isMetric)}
                                  </span>
                                </div>
                              </React.Fragment>,
                            );
                            return tooltipValueRow;
                          }, [])}
                        </div>
                      </>
                    }
                  </div>
                </TooltipWithBounds>
              )
            }
          </div>
        );
      }}
    </ParentSize>
  );
}

CalibrationChart.propTypes = {
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  chartSeries: PropTypes.array,
  binSetLevels: PropTypes.array,
  binSetCalibrations: PropTypes.array,
  deliveries: PropTypes.array,
  onBinSetLevelClicked: PropTypes.func,
  onBinSetCalibrationClicked: PropTypes.func,
  selectedBinSetLevel: PropTypes.string,
  selectedBinSetCalibration: PropTypes.string,
  loading: PropTypes.bool,
};

export default CalibrationChart;
