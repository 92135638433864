import { gql } from '@apollo/client';

export const FARM_GQL = gql`
  query Farm {
    farm(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
      name
      id
      organization_id
      organization {
        name
      }
    }
  }
`;

export const GET_ALERT_POLICIES_GQL = gql`
  query GetAlertPolicies($user_id: uuid!, $fault_codes: [Int!]) {
    user_organization(where: { user: { id: { _eq: $user_id } } }) {
      user_id
      organization_id
    }
    alert_policy(
      order_by: { farm: { name: asc } }
      where: {
        user: { id: { _eq: $user_id } }
        alert_for: { _in: $fault_codes }
        status: { _eq: "active" }
        alert_transport: { _in: ["email", "sms"] }
        _or: [{ farm_id: { _is_null: false } }, { organization_id: { _is_null: false } }]
      }
    ) {
      id
      alert_transport
      alert_for
      status
      alert_threshold_in_seconds
      renotify_interval_in_seconds
      organization_id
      farm_id
      farm {
        id
        name
        organization_id
      }
    }
  }
`;

export const UPSERT_DEFAULT_ALERT_POLICIES_GQL = gql`
  mutation SaveDefaultAlertPolicies($policies: [alert_policy_insert_input!]!) {
    insert_alert_policy(
      objects: $policies
      on_conflict: {
        constraint: alert_policy_organization_id_user_id_alert_transport_alert_for
        update_columns: [status, alert_threshold_in_seconds, renotify_interval_in_seconds]
      }
    ) {
      affected_rows
    }
  }
`;

export const UPSERT_CUSTOMIZED_BARN_ALERT_POLICIES_GQL = gql`
  mutation SaveCustomizedBarnAlertPolicies($user_id: uuid!, $policies: [alert_policy_insert_input!]!) {
    update_alert_policy(
      where: {
        user: { id: { _eq: $user_id } }
        farm_id: { _is_null: false }
        alert_transport: { _in: ["email", "sms"] }
      }
      _set: { status: "disabled" }
    ) {
      affected_rows
    }
    insert_alert_policy(
      objects: $policies
      on_conflict: {
        constraint: alert_policy_farm_id_user_id_alert_transport_alert_for
        update_columns: [status, alert_threshold_in_seconds, renotify_interval_in_seconds]
      }
    ) {
      affected_rows
    }
  }
`;
