import PropTypes from 'prop-types';
import { DATE_DAY_OF_WEEK, DATE_CALENDAR_DATE } from '../../../../utils/dates';
import './RowDateBlock.scss';

function RowDateBlock({
  dateBlockRef = null,
  className = '',
  dayOfWeekClassName = '',
  dateClassName = '',
  style = {},
  tabIndex = 0,
  isDateHighlighted = false,
  date = null,
  children = [],
  onClick = () => {},
}) {
  const onDateBlockKeyDown = (e) => {
    if (e.key !== 'Enter' && e.code !== 'Space') {
      return;
    }

    e.preventDefault();
    onClick(date.clone());
  };

  const onDateBlockClick = () => {
    onClick(date.clone());
  };

  return (
    <div
      ref={dateBlockRef}
      className={`RowDateBlock ${className}`}
      style={style}
      tabIndex={tabIndex}
      onKeyDown={onDateBlockKeyDown}
      onClick={onDateBlockClick}
    >
      <div
        className={`RowDateBlock-dateContainer ${isDateHighlighted ? 'RowDateBlock-dateContainer--highlighted' : ''}`}
      >
        <div className={`RowDateBlock-dayOfWeek ${dayOfWeekClassName}`}>{date.format(DATE_DAY_OF_WEEK)}</div>
        <div className={`RowDateBlock-date ${dateClassName}`}>{date.format(DATE_CALENDAR_DATE)}</div>
      </div>
      {children}
    </div>
  );
}

RowDateBlock.propTypes = {
  dateBlockRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  className: PropTypes.string,
  dayOfWeekClassName: PropTypes.string,
  dateClassName: PropTypes.string,
  style: PropTypes.object,
  tabIndex: PropTypes.number,
  isDateHighlighted: PropTypes.bool,
  date: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClick: PropTypes.func,
};

export default RowDateBlock;
