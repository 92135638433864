import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { cssTransition, ToastContainer, toast } from 'react-toastify';
import useUser from '../../../utils/hooks/useUser';
import PropTypes from 'prop-types';

import Button from '../../../atoms/Button';
import { XIcon } from '../../../atoms/Icons';

import { DATE_FORMAT_DASH } from '../../../utils/dates';
import { ANIMAL_GROUP_CREATOR_INSERT } from './queries';
import { TIMEFRAME_ENUM, TIMEFRAME_TO_DAYJS_KEY } from './enums';

import './AnimalGroupCreator.scss';

const GROUP_INPUT = 'AnimalGroupCreator:groupId';

const START_AGE_INPUT = 'AnimalGroupCreator:startAge';
const START_AGE_TIMEFRAME = 'AnimalGroupCreator:startAgeTimeframe';

const START_DATE_INPUT = 'AnimalGroupCreator:startDate';
const EXPECTED_END_DATE_INPUT = 'AnimalGroupCreator:expectedEndDate';

const TOAST_CONTAINER_ID = 'AnimalGroupCreatorToastContainer';

// A custom transition animation has to be applied because React Toastify does not work well with html dialogs.
// Any toast present when the dialog is closed will be re-presented when the dialog is next opened,
//  even if that toast was dismissed before closing the dialog. React Toastify toasts cannot be dismissed without an animation.
// This replacement animation simply sets opacity to 0, so the animation restart bug is not visible.
const TOAST_ANIM = cssTransition({
  exit: 'AnimalGroupCreator-toastExitAnim',
});

function AnimalGroupCreator({ barnId, orgId, loadAnimalGroup, close = () => null }) {
  const { user } = useUser();
  const closeFull = useCallback(() => {
    toast.dismiss();
    close();
  }, [close]);

  const [saving, setSaving] = useState(false);

  const [insertAnimalGroup] = useMutation(ANIMAL_GROUP_CREATOR_INSERT, {
    onCompleted: (response) => {
      if (response?.insert_animal_group_one) {
        const {
          id,
          approximate_birthdate: birthdate,
          ended_at,
          expected_ended_at,
          external_id,
          started_at,
        } = response.insert_animal_group_one;

        loadAnimalGroup(id, birthdate, ended_at, expected_ended_at, external_id, started_at);
      }

      setSaving(false);
      closeFull();
    },
    onError: (error) => {
      const gqlError = error?.graphQLErrors?.[0];
      let errorMessage;
      if ('constraint-violation' === gqlError?.extensions?.code) {
        errorMessage = 'An animal group with that ID already exists. Please enter a different ID.';
      } else {
        console.error(gqlError);
        errorMessage = 'A network error was encountered. Please check your internet connection before trying again.';
      }
      toast.error(errorMessage, {
        position: 'top-center',
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

        transition: TOAST_ANIM,

        containerId: TOAST_CONTAINER_ID,
      });

      setSaving(false);
    },
  });

  const handleSubmit = useCallback(
    (event) => {
      // Forms normally force a page redirect on submit. Prevent this.
      event.preventDefault();

      const groupId = event.target[GROUP_INPUT].value;
      const startAge = event.target[START_AGE_INPUT].value || 0;
      const startAgeTimeframe = event.target[START_AGE_TIMEFRAME].value;
      const startDate = event.target[START_DATE_INPUT].value;
      const expectedEndDate = event.target[EXPECTED_END_DATE_INPUT].value;

      if (Number.isNaN(Number.parseFloat(startAge))) {
        const errorMessage = 'Please enter a number for the starting age.';
        toast.error(errorMessage, {
          position: 'top-center',
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,

          transition: ANIM,

          containerId: TOAST_CONTAINER_ID,
        });
        return;
      }

      const approximateBirthdate = dayjs
        .tz(startDate, DATE_FORMAT_DASH, user.timezone)
        .subtract(startAge, TIMEFRAME_TO_DAYJS_KEY[startAgeTimeframe])
        .startOf('day')
        .unix();
      const startDateTimestamp = dayjs.tz(startDate, DATE_FORMAT_DASH, user.timezone).unix();
      const expectedEndDateTimestamp =
        expectedEndDate !== '' ? dayjs.tz(expectedEndDate, DATE_FORMAT_DASH, user.timezone).unix() : null;

      insertAnimalGroup({
        variables: {
          new_group: {
            barn_id: barnId,
            organization_id: orgId,
            external_id: groupId,
            approximate_birthdate: approximateBirthdate,
            started_at: startDateTimestamp,
            expected_ended_at: expectedEndDateTimestamp,
          },
        },
      });

      setSaving(true);
    },
    [barnId, orgId],
  );

  return (
    <>
      <form className="AnimalGroupCreator" onSubmit={handleSubmit}>
        <div className="AnimalGroupCreator-top">
          <span className="AnimalGroupCreator-titleText">Open New Group</span>
          <XIcon className="AnimalGroupCreator-exit" onClick={closeFull} />
        </div>
        <div className="AnimalGroupCreator-body">
          <div className="AnimalGroupCreator-groupId">
            <label className="AnimalGroupCreator-labelText" htmlFor={GROUP_INPUT}>
              <span className="AnimalGroupCreator-labelText--required">*</span>
              Group ID
            </label>
            <input
              className="AnimalGroupCreator-input AnimalGroupCreator-input--groupId AnimalGroupCreator-inputText"
              id={GROUP_INPUT}
              name={GROUP_INPUT}
              type="text"
              placeholder="1234-ABC"
              required
            />
          </div>
          <div className="AnimalGroupCreator-startAge">
            <label className="AnimalGroupCreator-labelText" htmlFor={START_AGE_INPUT}>
              Starting Age
            </label>
            <div className="AnimalGroupCreator-startAgeSection">
              <input
                className="AnimalGroupCreator-input AnimalGroupCreator-input--startAge AnimalGroupCreator-inputText"
                id={START_AGE_INPUT}
                name={START_AGE_INPUT}
                type="number"
                min="1"
                max="99"
                placeholder="3"
              />
              <select
                className="AnimalGroupCreator-timeframeSelect AnimalGroupCreator-inputText"
                id={START_AGE_TIMEFRAME}
                name={START_AGE_TIMEFRAME}
                required
              >
                <option value="days">{TIMEFRAME_ENUM.Day}</option>
                <option value="weeks">{TIMEFRAME_ENUM.Week}</option>
                <option value="months">{TIMEFRAME_ENUM.Month}</option>
              </select>
            </div>
          </div>
          <div className="AnimalGroupCreator-startDate">
            <label className="AnimalGroupCreator-labelText" htmlFor={START_DATE_INPUT}>
              <span className="AnimalGroupCreator-labelText--required">*</span>
              Start Date
            </label>
            <input
              className="AnimalGroupCreator-input AnimalGroupCreator-inputText"
              id={START_DATE_INPUT}
              name={START_DATE_INPUT}
              type="date"
              required
            />
          </div>
          <div className="AnimalGroupCreator-endDate">
            <label className="AnimalGroupCreator-labelText" htmlFor={EXPECTED_END_DATE_INPUT}>
              Expected End Date
            </label>
            <input
              className="AnimalGroupCreator-input AnimalGroupCreator-input--date AnimalGroupCreator-inputText"
              id={EXPECTED_END_DATE_INPUT}
              name={EXPECTED_END_DATE_INPUT}
              type="date"
            />
          </div>
          <Button disabled={saving} className="AnimalGroupCreator-cancelButton" content="Cancel" onClick={closeFull} />
          <Button
            loading={saving}
            type="submit"
            className="AnimalGroupCreator-savingButton"
            variant="vivid"
            color="success"
            content="Open Group"
          />
        </div>
      </form>
      <ToastContainer closeOnClick enableMultiContainer containerId={TOAST_CONTAINER_ID} />
    </>
  );
}

AnimalGroupCreator.propTypes = {
  barnId: PropTypes.string,
  orgId: PropTypes.string,
  loadAnimalGroup: PropTypes.func,
  close: PropTypes.func,
};

export default AnimalGroupCreator;
