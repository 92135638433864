import { useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../atoms/Button';

import './AnimalGroupCreatorWarning.scss';

function AnimalGroupCreatorWarning({ close, onConfirm }) {
  const confirm = useCallback(() => {
    onConfirm?.();
    close();
  }, []);

  return (
    <div className="AnimalGroupCreatorWarning">
      <span className="AnimalGroupCreatorWarning-titleText">
        Warning: There is already an open animal group on this barn.
      </span>
      <span className="AnimalGroupCreatorWarning-contentText">
        Opening a new animal group will automatically close the existing group.
      </span>

      <div className="AnimalGroupCreatorWarning-bottom">
        <Button className="AnimalGroupCreatorWarning-button" content="Cancel" onClick={close} />
        <Button
          className="AnimalGroupCreatorWarning-button"
          variant="vivid"
          color="success"
          content="Continue"
          onClick={confirm}
        />
      </div>
    </div>
  );
}

AnimalGroupCreatorWarning.propTypes = {
  close: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
};

export default AnimalGroupCreatorWarning;
