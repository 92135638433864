import dayjs from 'dayjs';

// Date Time constants
export const SECONDS_IN_HOUR = 3600;
export const SECONDS_IN_WEEK = 7 * 24 * 60 * 60;

// Date formats for dayjs
export const DATE_FORMAT_DASH = 'YYYY-MM-DD'; // Example: 2023-03-21
export const DATE_FORMAT_SLASH = 'YYYY/MM/DD'; // Example: 2023/03/21
export const DATE_FORMAT_MONTH_DAY = 'MMM DD'; // Example: Jul 01
export const DATE_MONTH = 'MMMM'; // Example: April
export const DATE_MONTH_YEAR = 'MMMM YYYY'; // Example: April 2024
export const DATE_FORMAT_MONTH_DAY_YEAR = 'MMM DD YYYY'; // Example: Jul 01 2023
export const DATE_FORMAT_WEEKDAY_MONTH_DAY = 'dddd, MMM D'; // Example: Sunday, Jul 1
export const DATE_FORMAT_WEEKDAY_SHORT_MONTH_DAY = 'ddd, MMM D'; // Example: Sun, Jul 1
export const DATE_DAY_OF_WEEK = 'ddd'; // Example: Mon
export const DATE_CALENDAR_DATE = 'D'; // Example: 31

// Time formats for dayjs
export const TIME_FORMAT_HOURS_MINUTES = 'HH:mm'; // Example: 23:14
export const TIME_FORMAT_HOURS_MINUTES_SECONDS = 'HH:mm:ss'; // Example: 23:14:55
export const TIME_FORMAT_UTC_OFFSET_TIMEZONE = 'Z z'; // Example: -5:00 CDT

// Date Time formats for dayjs
export const DATE_TIME_DASH = 'YYYY-MM-DD HH:mm:ss'; // Example: 2023-11-14 23:11:35
export const DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE = 'YYYY-MM-DD HH:mm'; // Example: 2023-11-14 23:11
export const DATE_TIME_SLASH = 'YYYY/MM/DD HH:mm:ss'; // Example: 2023/11/14 23:11:35
export const DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE = 'MMM DD HH:mm'; // Example: Jul 01 23:14
export const DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE_SECOND = 'MMM DD HH:mm:ss'; // Example: Jul 01 23:14:55
export const DATE_TIME_FORMAT_MONTH_DAY_YEAR_HOUR_MINUTE_SECOND = 'MMM DD YYYY HH:mm:ss'; // Example: Jul 01 2023 23:14:55

// Localized Date Time formats for dayjs
export const DATE_TIME_FORMAT_SHORT = 'L LT'; // Example: 03/21/2023 7:11 PM
export const DATE_TIME_FORMAT_MED = 'LL'; // Example: July 1, 2023
export const DATE_TIME_FORMAT_HUGE = 'LLL z'; // Example: April 18, 2023 3:25 PM CDT
export const DATE_TIME_FORMAT_FULL = 'LLLL zzz'; // Example: Thursday, July 6, 2023 3:03 PM Central Daylight Time

// Date Time formats for react-datepicker (uses date-fns under the hood)
export const REACT_DATEPICKER_DATE_TIME_MONTH_DAY_YEAR_HOUR_MINUTE = 'MMMM d, yyyy h:mm aa'; // Example: July 19, 2023 12:00 AM

// Take two dates in seconds and returns an object containing the difference between them
export const intervalToDuration = (startTime, endTime) => {
  let duration = dayjs.duration(dayjs.tz(1000 * endTime).diff(dayjs.tz(1000 * startTime)));

  const years = Math.floor(duration.asYears());
  duration = duration.subtract(years, 'years');

  const months = Math.floor(duration.asMonths());
  duration = duration.subtract(months, 'months');

  const days = Math.floor(duration.asDays());
  duration = duration.subtract(days, 'days');

  const hours = Math.floor(duration.asHours());
  duration = duration.subtract(hours, 'hours');

  const minutes = Math.floor(duration.asMinutes());
  duration = duration.subtract(minutes, 'minutes');

  const seconds = Math.floor(duration.asSeconds());
  duration = duration.subtract(seconds, 'seconds');

  return {
    years,
    months,
    days,
    hours,
    minutes,
    seconds,
  };
};

export const secondsToDuration = (seconds) => {
  const duration = dayjs.duration({ seconds });

  // dayjs cannot display durations longer than one day
  // https://github.com/iamkun/dayjs/issues/641#issuecomment-643924034
  if (duration.asDays() < 1) {
    return dayjs.utc(duration.asMilliseconds()).format(TIME_FORMAT_HOURS_MINUTES_SECONDS);
  } else {
    const days = duration.days();
    return `${days} ${days > 1 ? 's' : ''}`;
  }
};
