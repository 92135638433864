import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import useFeature from '../../utils/hooks/useFeature';
import useBinInventory from '../../utils/hooks/useBinInventory';
import useBinInventoryChartSeries from '../../utils/hooks/useBinInventoryChartSeries';
import { DeliveryStatus } from '../../utils/enums';
import InventoryCardView from './InventoryCardView';
import './InventoryCard.scss';

function InventoryCard({ binSetID = '', feedlines = [] }) {
  const now = useMemo(() => dayjs.tz(), []); // Memoized to avoid changing between renders.

  const { active: displayTruthData } = useFeature('DISPLAY_TRUTH_DATA');
  const [rowData, setRowData] = useState({});
  const from = dayjs().subtract(2, 'weeks').startOf('day');
  const to = dayjs().add(2, 'weeks').endOf('day');
  const [dateRange, setDateRange] = useState({ to, from });

  const { fetchInventoryData, getBinLevelPredictionData } = useBinInventory();
  const { chart } = fetchInventoryData(binSetID, now);
  const { loading: inventoryChartLoading, error: inventoryChartError, data: inventoryChartData } = chart;
  const {
    binLevelTimeSeries,
    predictionTimeSeries,
    // earliestOccurredAt, latestOccurredAt
  } = rowData?.debug || {};
  const {
    buildCalculatedBinSetLevelSeries,
    buildProjectedOrderedOrderSeries,
    buildProjectedRecommendedOrderSeries,
    buildProjectedSeries,
    buildTruthDataSeries,
  } = useBinInventoryChartSeries();

  // Categorize graphql response data into deliveries and orders arrays.
  // Deliveries and orders both have the shape: { timestamp: seconds (number), value: grams (number) }
  const orders = [];
  inventoryChartData?.binSetData?.bin_set?.[0].bins?.forEach((bin) => {
    bin?.deliveries?.forEach((delivery) => {
      const value = delivery.weight_in_grams;
      const timestamp = delivery.ordered_at;
      orders.push({ timestamp, value, status: delivery.status });
    });
  });

  const chartSeries = [
    buildCalculatedBinSetLevelSeries(binLevelTimeSeries),
    buildProjectedSeries(predictionTimeSeries),

    buildProjectedRecommendedOrderSeries(
      orders.filter((o) => DeliveryStatus.Ordered === o.status || DeliveryStatus.Recommended === o.status),
      predictionTimeSeries,
      dateRange.to.unix(),
    ),
    buildProjectedOrderedOrderSeries(
      orders.filter((o) => DeliveryStatus.Ordered === o.status),
      predictionTimeSeries,
      dateRange.to.unix(),
    ),
  ];

  if (displayTruthData) {
    chartSeries.push(buildTruthDataSeries(inventoryChartData?.binSetData?.known_bin_level));
  }

  // if (!userLoading && user?.isStaff) {
  //   // only FeedFlo staff get to see coefficients
  //   chartSeries.push(buildCoefficientSeries(rowData?.coefficientTimeSeries));
  // }

  useEffect(() => {
    const getRowData = async () => {
      if (!inventoryChartData?.binSetData?.bin_set?.[0]) {
        return;
      }

      let row;
      try {
        // Fetch the data from hasura and create a rough representation of the row as an object
        row = await getBinLevelPredictionData(
          inventoryChartData?.binSetData.bin_set?.[0],
          inventoryChartData?.earliestBinSetLevel,
          now,
          inventoryChartData?.binSetLevelsForInventory,
        );
        if (row === null) {
          return;
        }

        const orderStats = { totalFeedOrdered: 0, totalOrderedAt: 0, count: 0 };
        row.orders.forEach((order) => {
          orderStats.totalFeedOrdered += order.weight;
          orderStats.totalOrderedAt += order.orderedAt;
          orderStats.count++;
        });

        setRowData({
          name: row.name,
          bins: row.bins.join(', '),
          totalCapacity: row.totalCapacity,
          currentBinLevel: row.currentBinLevel,
          minimumSafe: row.minimumSafe,
          earliestDelivery: row.earliestDelivery,
          totalFeedOrdered: orderStats.totalFeedOrdered,
          orderedAt: Math.round(orderStats.totalOrderedAt / Math.max(orderStats.count, 1)),
          debug: row.debug,
          historicLiveView: row.historicLiveViewSeries,
          coefficientTimeSeries: row.coefficientTimeSeries,
        });
      } catch (e) {
        console.error(e);
      }
    };

    getRowData();
  }, [inventoryChartData?.binSetData]);
  if (inventoryChartError) {
    return <div>Error: {JSON.stringify(inventoryChartError, Object.getOwnPropertyNames(inventoryChartError))}</div>;
  }

  let dataPaneChildren = null;

  let totalCapacity = inventoryChartData?.binSetData?.bin_set?.[0]?.bins?.reduce((sum, b) => {
    return (sum += b.capacity_in_grams);
  }, 0);

  return (
    <InventoryCardView
      chartLoading={inventoryChartLoading}
      dateRange={dateRange}
      totalCapacity={totalCapacity}
      data={rowData}
      chartSeries={chartSeries}
      deliveries={[]}
      dataPaneChildren={dataPaneChildren}
      onDateRangeChange={setDateRange}
      feedlines={feedlines}
    />
  );
}

InventoryCard.propTypes = {
  binSetID: PropTypes.string,
  feedlines: PropTypes.array,
};

export default InventoryCard;
