import PropTypes from 'prop-types';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { BarStack, Line } from '@visx/shape';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { Text } from '@visx/text';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import BarStackChartTooltip from './BarStackChartTooltip';
import './BarStackChart.scss';

function BarStackChart({
  width = 0,
  height = 0,
  data = [],
  keys = [],
  keyNameMap = {},
  xAxisValueAccessor = () => {},
  xAxisValues = [],
  xAxisValueFormatter = () => {},
  yAxisValues = [],
  yAxisValueFormatter = () => {},
  tooltipTitleAccessor = () => {},
  tooltipTitleFormatter = () => {},
  previousAverage = 0,
  formattedPreviousAverage = '',
  colorScale = () => {},
  valueFormatter = () => {},
  onChartClick = () => {},
}) {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip();
  const { containerRef } = useTooltipInPortal({ scroll: true });
  const xAxisScale = scaleBand({
    domain: xAxisValues,
    padding: 0.4,
  });
  const yAxisScale = scaleLinear({
    domain: [0, Math.max(...yAxisValues)],
    nice: true,
  });
  const margin = { top: 20, left: 35, bottom: 20, right: 35 };
  const grid = '#c2c7cf';
  const text = '#697386';
  const backgroundColor = '#fff';

  xAxisScale.rangeRound([0, width - margin.left - margin.right]);
  yAxisScale.range([height - margin.top - margin.bottom, 0]);

  return (
    <>
      <svg ref={containerRef} width={width} height={height}>
        <rect x={0} y={0} width={width} height={height} fill={backgroundColor} />
        <GridRows
          top={margin.top}
          left={margin.left}
          scale={yAxisScale}
          width={width - margin.left - margin.right}
          stroke={grid}
        />
        <Group top={margin.top}>
          <BarStack
            data={data}
            keys={keys}
            x={xAxisValueAccessor}
            xScale={xAxisScale}
            yScale={yAxisScale}
            color={colorScale}
          >
            {(barStacks) => {
              const finalBarMap = [];

              // Determine which is the top-most visible bar for each stack
              barStacks.forEach((barStack) => {
                barStack.bars.forEach((bar) => {
                  if (bar.height > 0) {
                    finalBarMap[bar.index] = barStack.key;
                  }
                });
              });

              return barStacks.map((barStack) =>
                barStack.bars.map((bar) => {
                  if (!bar.height) {
                    return;
                  }

                  const isRemainingLocations = bar.key === 'Remaining Locations';
                  const barSegmentClassName = `BarStackChart-barSegment${isRemainingLocations ? '' : '--clickable'}`;

                  const onMouseLeave = () => {
                    hideTooltip();
                  };

                  const onMouseMove = (e) => {
                    showTooltip({
                      tooltipData: bar,
                      tooltipTop: e.clientY,
                      tooltipLeft: e.clientX,
                    });
                  };

                  const onClick = () => {
                    if (isRemainingLocations) {
                      return;
                    }
                    hideTooltip();
                    onChartClick(bar.key);
                  };

                  if (finalBarMap[bar.index] === barStack.key) {
                    const x = bar.x + margin.left;
                    const { y } = bar;
                    const r = 4;

                    // This path element is used to round the upper left and right corners of the top-most
                    // bar in a bar stack. Visx does not provide a way to do this out of the box, and SVGs
                    // don't provide a way to individually target corners the way border-radius does.
                    // The individual path lines might seem indecipherable at first, but they're actually
                    // very simple:
                    //
                    // M means move to the specified point
                    // L means draw a line from the current point to the specified point
                    // A means draw an arc from the current point using the specified radius to the specified point
                    // Z means complete the path
                    // See https://css-tricks.com/svg-path-syntax-illustrated-guide/ for more information
                    //
                    // In simple terms, this path:
                    // 1. Draws the left side of the bar
                    // 2. Draws the bottom of the bar
                    // 3. Draws the right side of the bar
                    // 4. Draws the upper right corner
                    // 5. Draws the top of the bar
                    // 6. Draws the upper left corner

                    return (
                      <path
                        key={`stack${barStack.index}-bar${bar.index}`}
                        className={barSegmentClassName}
                        d={`
                          M ${x},${y}
                          L ${x},${y + bar.height}
                          L ${x + bar.width},${y + bar.height}
                          L ${x + bar.width},${y + r}
                          A ${r},${r} 0 0,0 ${x + bar.width - r},${y}
                          L ${x + r},${y}
                          A ${r},${r} 0 0,0 ${x},${y + r}
                          Z
                      `}
                        fill={bar.color}
                        onMouseLeave={onMouseLeave}
                        onMouseMove={onMouseMove}
                        onClick={onClick}
                      />
                    );
                  }

                  return (
                    <rect
                      key={`stack${barStack.index}-bar${bar.index}`}
                      className={barSegmentClassName}
                      x={bar.x + margin.left}
                      y={bar.y}
                      height={bar.height}
                      width={bar.width}
                      fill={bar.color}
                      onMouseLeave={onMouseLeave}
                      onMouseMove={onMouseMove}
                      onClick={onClick}
                    />
                  );
                }),
              );
            }}
          </BarStack>
          <Text x={width - margin.right} y={yAxisScale(previousAverage) - 5} fill={text} fontSize="10" textAnchor="end">
            Prev Avg
          </Text>
          {previousAverage ? (
            <Line
              className="BarStackChart-previousAverage"
              from={{ x: margin.left, y: yAxisScale(previousAverage) }}
              to={{ x: width - margin.right, y: yAxisScale(previousAverage) }}
              stroke={text}
              strokeOpacity={0.8}
              strokeDasharray="4"
              strokeWidth="0.1rem"
            />
          ) : null}
          <Text
            x={width - margin.right}
            y={yAxisScale(previousAverage) + 12}
            fill={text}
            fontSize="10"
            textAnchor="end"
          >
            {formattedPreviousAverage}
          </Text>
        </Group>
        <AxisLeft
          top={margin.top}
          left={margin.left}
          scale={yAxisScale}
          tickFormat={yAxisValueFormatter}
          hideTicks={true}
          hideAxisLine={true}
          stroke={text}
          tickStroke={text}
          tickLabelProps={() => ({
            fill: text,
            fontSize: 10,
            textAnchor: 'end',
            verticalAnchor: 'middle',
          })}
        />
        <AxisBottom
          top={height - margin.bottom}
          left={margin.left}
          hideTicks={true}
          scale={xAxisScale}
          tickFormat={xAxisValueFormatter}
          numTicks={data.length / 2}
          stroke={text}
          hideAxisLine={true}
          tickStroke={text}
          tickLabelProps={() => ({
            fill: text,
            fontSize: 10,
            textAnchor: 'middle',
          })}
        />
      </svg>
      {tooltipOpen && tooltipData && (
        <BarStackChartTooltip
          titleAccessor={tooltipTitleAccessor}
          titleFormatter={tooltipTitleFormatter}
          colorScale={colorScale}
          top={tooltipTop}
          left={tooltipLeft}
          keys={keys}
          keyNameMap={keyNameMap}
          data={tooltipData}
          valueFormatter={valueFormatter}
        />
      )}
    </>
  );
}

BarStackChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.array,
  keys: PropTypes.array,
  keyNameMap: PropTypes.object,
  xAxisValueAccessor: PropTypes.func,
  xAxisValues: PropTypes.array,
  xAxisValueFormatter: PropTypes.func,
  yAxisValues: PropTypes.array,
  yAxisValueFormatter: PropTypes.func,
  tooltipTitleAccessor: PropTypes.func,
  tooltipTitleFormatter: PropTypes.func,
  previousAverage: PropTypes.number,
  formattedPreviousAverage: PropTypes.string,
  colorScale: PropTypes.func,
  valueFormatter: PropTypes.func,
  onChartClick: PropTypes.func,
};

export default BarStackChart;
