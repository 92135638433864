import PropTypes from 'prop-types';
import PhoneNumberInput from './PhoneNumberInput';
import { useField } from 'formik';

export default function PhoneNumberInputField({ label, ...props }) {
  const [field, meta] = useField(props);

  return (
    <PhoneNumberInput
      isValid={!meta.touched || (meta.touched && !meta.error)}
      error={meta.error}
      label={label}
      {...props}
      {...field}
    />
  );
}

PhoneNumberInputField.propTypes = {
  label: PropTypes.string,
};
