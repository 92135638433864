import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE, secondsToDuration } from '../../utils/dates';
import { convertGramsToSmallUnits, weightSmallUnitLabel } from '../../utils/unitConversion';
import { censorTypes, useCensor } from '../../utils/hooks/useCensor/useCensor';
import LabelledColumn from '../../atoms/LabelledColumn';
import StatusBadge from '../../atoms/StatusBadge';
import './FeedLineStatusHeader.scss';
import { Link } from 'react-router-dom';
import { BinSetCalibrationStatusEnum } from '../../pages/BarnSummaryTab/enum';

function FeedLineStatusHeader({
  loading = false,
  className = '',
  feedLineName = '',
  isMetric = false,
  isFeedLineFull = false,
  isAugerRunning = false,
  isCalculating = false,
  lastFeedingEventStartInSeconds = 0,
  weightInGrams = 0,
  durationInSeconds = 0,
  calibrationStatus,
}) {
  const { censor } = useCensor();
  const weightInSmallUnits = convertGramsToSmallUnits(isMetric, weightInGrams);
  const unitLabel = weightSmallUnitLabel(isMetric);

  const renderFeedLineStatusBadge = () => {
    if (loading) {
      return <StatusBadge className={loading ? 'FeedLineStatusHeader-statusBadge--loading' : ''} loading={loading} />;
    } else if (isFeedLineFull) {
      return <StatusBadge status="success" icon="logo" text="Full" />;
    }

    return <StatusBadge status="error" icon="warning" text="Empty" />;
  };

  const renderAugerStatusBadge = () => {
    if (loading) {
      return <StatusBadge className={loading ? 'FeedLineStatusHeader-statusBadge--loading' : ''} loading={loading} />;
    } else if (isAugerRunning) {
      return <StatusBadge status="success" icon="logo" text="ON" />;
    }

    return <StatusBadge status="offline" icon="logo" text="OFF" />;
  };

  const renderLastFeedingEventStart = () => {
    if (!lastFeedingEventStartInSeconds) {
      return 'Unknown';
    }

    return dayjs.tz(1000 * lastFeedingEventStartInSeconds).format(DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE);
  };

  const renderWeight = () => {
    if (isCalculating) {
      return 'Calculating';
    }
    if (isAugerRunning) {
      return 'Ongoing';
    }

    return `${weightInSmallUnits} ${unitLabel}`;
  };

  const renderDuration = () => {
    if (isAugerRunning) {
      return 'Ongoing';
    }

    return secondsToDuration(durationInSeconds);
  };

  let calibrationStatusElement;

  switch (calibrationStatus) {
    case BinSetCalibrationStatusEnum.Uncalibrated:
      calibrationStatusElement = (
        <Link to={'../calibrations'}>
          <StatusBadge status="offline" icon="search" text="Uncalibrated" />
        </Link>
      );
      break;
    case BinSetCalibrationStatusEnum.Calibrated:
      calibrationStatusElement = (
        <Link to={'../calibrations'}>
          <StatusBadge status="success" icon="logo" text="Calibrated" />
        </Link>
      );
      break;
    case BinSetCalibrationStatusEnum.Due:
      calibrationStatusElement = (
        <Link to={'../calibrations'}>
          <StatusBadge status="warning" icon="search" text="Bin Check Needed" />
        </Link>
      );
      break;
    case BinSetCalibrationStatusEnum.PastDue:
      calibrationStatusElement = (
        <Link to={'../calibrations'}>
          <StatusBadge status="error" icon="warning" text="Bin Check Needed" />
        </Link>
      );
      break;
  }

  return (
    <div className={`FeedLineStatusHeader ${className}`}>
      {feedLineName ? (
        <LabelledColumn loading={loading} heading="Selected Feed Line">
          {censor(feedLineName, censorTypes.feedline)}
        </LabelledColumn>
      ) : null}
      <LabelledColumn heading="Feed Line Status">{renderFeedLineStatusBadge()}</LabelledColumn>
      <LabelledColumn heading="Auger Status">{renderAugerStatusBadge()}</LabelledColumn>
      <LabelledColumn loading={loading} heading="Last Feeding Event">
        {renderLastFeedingEventStart()}
      </LabelledColumn>
      <LabelledColumn loading={loading} heading="Weight">
        {renderWeight()}
      </LabelledColumn>
      <LabelledColumn loading={loading} heading="Duration">
        {renderDuration()}
      </LabelledColumn>
      <LabelledColumn loading={loading} heading="Calibration">
        {calibrationStatusElement}
      </LabelledColumn>
    </div>
  );
}

FeedLineStatusHeader.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  feedLineName: PropTypes.string,
  isMetric: PropTypes.bool,
  isFeedLineFull: PropTypes.bool,
  isAugerRunning: PropTypes.bool,
  isCalculating: PropTypes.bool,
  lastFeedingEventStartInSeconds: PropTypes.number,
  weightInGrams: PropTypes.number,
  durationInSeconds: PropTypes.number,
  calibrationStatus: PropTypes.string,
};

export default FeedLineStatusHeader;
