import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery, useMutation } from '@apollo/client';
import Page from '../../atoms/Page';
import TabSection from '../../molecules/TabSection';
import FeederTable from '../../organisms/FeederTable';

const GET_EQUIPMENT_GQL = gql`
  query GetEquipment($barn_id: uuid!) {
    feed_line(where: { farm_id: { _eq: $barn_id }, deleted_at: { _is_null: true } }) {
      id
      name
    }
    feeder(where: { feed_line: { farm_id: { _eq: $barn_id } }, deleted_at: { _is_null: true } }) {
      id
      capacity_in_grams
      quantity
      feed_line {
        id
        name
      }
    }
  }
`;

const SAVE_FEEDER_GQL = gql`
  mutation SaveFeeder($object: feeder_insert_input!) {
    insert_feeder_one(
      object: $object
      on_conflict: { constraint: feeder_pkey, update_columns: [deleted_at, feed_line_id, capacity_in_grams, quantity] }
    ) {
      id
    }
  }
`;

function EquipmentTab({ titleSegments = [], barnId = '' }) {
  const [feeders, setFeeders] = useState([]);
  const {
    error: getError,
    loading: getLoading,
    data,
  } = useQuery(GET_EQUIPMENT_GQL, {
    fetchPolicy: 'no-cache',
    variables: { barn_id: barnId },
    notifyOnNetworkStatusChange: true,
  });
  const [saveFeeder, { error: saveError, loading: saveLoading }] = useMutation(SAVE_FEEDER_GQL);
  const pageTitleSegments = useMemo(() => ['Equipment', ...titleSegments], []);
  const loading = getLoading || saveLoading;

  useEffect(() => {
    if (data?.feeder) {
      setFeeders(data.feeder);
    }
  }, [data]);

  const onSave = (updatedFeeder) => {
    saveFeeder({
      variables: {
        object: {
          id: updatedFeeder?.id,
          deleted_at: updatedFeeder?.deleted_at,
          feed_line_id: updatedFeeder?.feed_line_id,
          capacity_in_grams: updatedFeeder?.capacity_in_grams,
          quantity: updatedFeeder?.quantity,
        },
      },
      refetchQueries: ['GetEquipment'],
    });
  };

  if (getError) {
    console.error(getError);
  }

  if (saveError) {
    console.error(saveError);
  }

  return (
    <Page titleSegments={pageTitleSegments}>
      <TabSection heading="Feeder Settings">
        <FeederTable loading={loading} feedLines={data?.feed_line} feeders={feeders} onSave={onSave} />
      </TabSection>
    </Page>
  );
}

EquipmentTab.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
  barnId: PropTypes.string,
};

export default EquipmentTab;
