import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useSetAtom } from 'jotai';
import dayjs from 'dayjs';
import { max, bisector } from 'd3-array';
import { format } from 'd3-format';

import { AxisLeft, AxisBottom } from '@visx/axis';
import { Brush } from '@visx/brush';
import { GridColumns } from '@visx/grid';
import { LinePath } from '@visx/shape';
import { Group } from '@visx/group';
import { TooltipWithBounds, useTooltip, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';

import BrushHandle from './BrushHandle';
import { inspectionWindowActiveAtom } from '../../organisms/ConsumptionDisplay';
import { ClosedEyeIcon, OpenEyeIcon, SearchIconSVG } from '../../atoms/Icons';

import { DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE } from '../../utils/dates';
import { getX, getY, tickFormatFactory } from '../../utils/chartHelpers';
import { weightSmallUnitLabel } from '../../utils/unitConversion';
import { addComma } from '../../utils';

import './EventChart.scss';

// Tooltip styles must be done with the style prop instead of with CSS.
const TOOLTIP_STYLES = {
  ...defaultStyles,
  borderRadius: '8px',
  backgroundColor: 'rgba(40, 40, 40, 0.8)',
  color: 'white',
  border: '1px solid #E6E6E6',
  padding: '1rem',
};

// Projection brush constants.
const SELECTED_BRUSH_STYLE = {
  fill: `black`,
  fillOpacity: '10%',
};

/***
 * The inner child component passed to ParentSize, responsible for creating the chart.
 * Not meant to be imported on its own.
 */
function EventInnerChart({
  chartLines,
  events,
  start,
  end,
  xAxisTicks,
  xMax,
  yMax,
  xScale,
  yScale,
  height,
  width,
  margin,
  inspectionWindow,
  snapToPoints,
  isMetric,
}) {
  // Selected events are displayed differently from unselected events, so we separate them out here for simplicity.
  // This also lets us easily render selected events after unselected ones, so they show up on top.
  const selectedEvents = useMemo(
    () =>
      events.reduce((selectedEvents, event) => {
        if (event.selected && !event.hidden) {
          selectedEvents.push(event);
        }
        return selectedEvents;
      }, []),
    [events],
  );

  // Chart layout variables
  const xAxisTickFormat = tickFormatFactory(start, end);

  // Brush and projection line variables.
  const [projectionLineData, setProjectionLineData] = useState([]);
  const setInspectionWindowActive = useSetAtom(inspectionWindowActiveAtom);
  const [inspectionWindowPosition, setInspectionWindowPosition] = useState({});
  const [inspectionWindowInitialPosition, setInitialProjectionBrushPosition] = useState({ start: 0, end: 0 });

  const [inspectionWindowInteraction, setInspectionWindowInteraction] = useState(false); // Is the user currently interacting (resizing or moving) the brush?
  const inspectionWindowInteractionStart = useCallback(() => setInspectionWindowInteraction(true), []);
  const inspectionWindowInteractionEnd = useCallback(() => setInspectionWindowInteraction(false), []);

  const brushRef = useRef(null);
  const renderBrushHandle = useCallback(
    ({ x, height, isBrushActive }) => (
      <BrushHandle
        x={x}
        height={height}
        isBrushActive={isBrushActive}
        opacity={inspectionWindowInteraction ? 0.33 : 1}
      />
    ),
    [inspectionWindowInteraction],
  );

  // Tooltip variables.
  const { tooltipData, tooltipLeft = 0, tooltipTop = 0, tooltipOpen, showTooltip, hideTooltip } = useTooltip();

  // Accessor functions for plotting chart lines.
  const getLineX = useCallback((point) => xScale(getX(point)) ?? 0, [start, end, xScale]);
  const getLineY = useCallback((point) => yScale(getY(point)) ?? 0, [chartLines, yScale]);

  /**
   * Given two points and a timestamp between them, return the value that would correspond to that timestamp.
   *
   * @param p0 The first point to lerp between.
   * @param p1 The second point to lerp between.
   * @param timestamp The point between p0 and p1 to calculate the value for.
   * @returns The value corresponding to the given timestamp between p0 and p1.
   */
  const lerpToTimestamp = useCallback(
    /**
     * @param {Object} p0
     * @param {number} p0.value
     * @param {number} p0.timestamp
     * @param {Object} p1
     * @param {number} p1.value
     * @param {number} p1.timestamp
     * @param {number} timestamp
     */
    (p0, p1, timestamp) => {
      // Guard against NaN slope/vertical line by returning the initial p0 value.
      if (p0.timestamp === p1.timestamp) return p0.value;
      const slope = (p1.value - p0.value) / (p1.timestamp - p0.timestamp);
      return p0.value + (timestamp - p0.timestamp) * slope;
    },
    [],
  );

  // More information about d3-array bisector:
  // https://stackoverflow.com/questions/26882631/d3-what-is-a-bisector
  const bisectTimestamp = bisector((point) => point.timestamp).right;

  /**
   * Given a timestamp, find points that intersect with each chart line.
   * Points are generated by interpolation.
   *
   * @param timestamp The timestamp to intersect with each chart line.
   *
   * @returns An array of point objects with value, timestamp, and colour keys.
   */
  const getInterceptPointsForTimestamp = useCallback(
    /**
     * @param {number} timestamp
     * @returns {Array<{value: number, timestamp: number, colour: string, hidden: boolean}>}
     */
    (timestamp) =>
      // Note: we are NOT filtering here because we care about maintaining the indexes for parallel arrays when hiding
      // Instead we will simply pass along the hidden property
      chartLines.map(({ linePlot, colour, hidden }) => {
        const index = bisectTimestamp(linePlot, timestamp);
        const point0 = linePlot[index - 1];
        const point1 = linePlot[index];
        let point = point0 || point1;
        if (point0 && point1) {
          if (snapToPoints) {
            // If both points exist, snap to the closer one
            const p0Dist = Math.abs(timestamp - point0.timestamp);
            const p1Dist = Math.abs(timestamp - point1.timestamp);
            point = p0Dist < p1Dist ? point0 : point1;
          } else {
            // If both points exist, lerp between them to estimate the value at the given timestamp
            const value = lerpToTimestamp(point0, point1, timestamp);
            point = { timestamp, value };
          }
        }
        return { ...point, colour, hidden };
      }),
    [chartLines, snapToPoints],
  );

  const formatDateTooltip = useCallback((hoveredDate) => {
    return dayjs.tz(1000 * hoveredDate).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE);
  }, []);

  const handleTooltip = useCallback(
    (mouseEvent) => {
      // Gets the position of the mouse cursor.
      const point = localPoint(mouseEvent);
      const xCoord = point.x - margin.left;

      // If the mouse pointer is off the chart, we don't want to display any tool tips.
      if (xCoord < 0 || xCoord > xMax) return;

      const xCoordTimestamp = xScale.invert(xCoord).valueOf();

      // Gets the nearest intercept point to the current mouse X position for each chartLineData entry.
      // Array of objects like { timestamp, value, colour, hidden }

      const lineIntercepts = getInterceptPointsForTimestamp(xCoordTimestamp);

      if (lineIntercepts) {
        const projectionLineIntercepts = lineIntercepts.map(({ colour, hidden }, index) => {
          return {
            colour,
            hidden,
            hiddenProjection: projectionLineData[index].hidden,
            timestamp: xCoordTimestamp,
            value: lerpToTimestamp(
              projectionLineData[index].linePoints.at(0),
              projectionLineData[index].linePoints.at(-1),
              xCoordTimestamp,
            ),
          };
        });

        const xCoordDate = xScale.invert(xCoord);
        const maxYIntercept = max(lineIntercepts, getY);
        const formattedDate = formatDateTooltip(xCoordDate);

        showTooltip({
          tooltipLeft: xCoord,
          tooltipTop: yScale(maxYIntercept),
          tooltipData: {
            lineIntercepts,
            projectionLineIntercepts,
            displayedDate: formattedDate,
          },
        });
      }
    },
    [chartLines, inspectionWindow, projectionLineData, xMax, xScale, yScale, getInterceptPointsForTimestamp],
  );

  // Callback used to receive brush positions from the Brush component.
  const saveProjectionPoints = useCallback((points) => {
    // If only one brush is placed, 'points' will be null.
    if (!points) return;

    setInspectionWindowPosition({ brushStart: points.x0, brushEnd: points.x1 });
  }, []);

  const calculateBrushBounds = () => {
    // The brush initially starts one third of the way into our date range.
    const brushStart = inspectionWindowPosition.brushStart || start + (end - start) / 3;
    // The brush initially ends two thirds of the way into our date range.
    const brushEnd = inspectionWindowPosition.brushEnd || start + (2 * (end - start)) / 3;
    return { brushStart, brushEnd };
  };
  const resetBrushAndInspectionWindowPosition = (bounds) => {
    let { brushStart, brushEnd } = calculateBrushBounds();
    if (bounds) {
      brushStart = bounds.brushStart;
      brushEnd = bounds.brushEnd;
    }
    // The scaled pixel positions are saved and used to render the initial brush.
    setInitialProjectionBrushPosition({ start: { x: xScale(brushStart) }, end: { x: xScale(brushEnd) } });
    // The unscaled timestamp positions are saved and used to render the initial projection line.
    setInspectionWindowPosition({
      brushStart: brushStart,
      brushEnd: brushEnd,
    });
  };
  // Effect used to calculate and set brush initial positions.
  // Keeps prediction lines consistent with the brushes when toggling projection mode.
  useEffect(() => {
    resetBrushAndInspectionWindowPosition();
  }, [end, start, xScale]);

  // Separate useEffect so we don't hide on browser width changes
  useEffect(() => {
    const { brushStart, brushEnd } = calculateBrushBounds();

    if (!(start < brushStart && brushStart < end) && !(start < brushEnd && brushEnd < end)) {
      // If the brush start and the brush end are both NOT included inside the new bounds
      // UNLESS the view is entirely contained within the brushes...
      // ... In which case that's confusing, reset them!
      resetBrushAndInspectionWindowPosition({
        brushStart: start + (end - start) / 3,
        brushEnd: start + (2 * (end - start)) / 3,
      });
      // Reset the inspection mode if the start/end change
      setInspectionWindowActive(false);
    }
  }, [end, start]);

  // Recalculates projection lines when changing brush positions or chart data.
  useEffect(() => {
    const { brushStart, brushEnd } = inspectionWindowPosition;

    if (!brushStart || !brushEnd) return;

    const projectionLines = chartLines.map((chartLine) => {
      const pointsInRange = chartLine.linePlot.filter((d, i) => {
        return d.timestamp > brushStart && d.timestamp < brushEnd && i % 2 === 1;
      });

      // Calculate the mean of the timestamps and the values
      let sumX = 0;
      let sumY = 0;
      for (let data of pointsInRange) {
        sumX += data.timestamp;
        sumY += data.value;
      }
      const meanX = sumX / pointsInRange.length;
      const meanY = sumY / pointsInRange.length;

      // Calculate the slope and the intercept of the line of best fit
      let numerator = 0;
      let denominator = 0;
      for (let data of pointsInRange) {
        numerator += (data.timestamp - meanX) * (data.value - meanY);
        denominator += (data.timestamp - meanX) ** 2;
      }
      const slope = numerator / denominator;
      const intercept = meanY - slope * meanX;

      const linePoints = [
        { timestamp: start, value: start * slope + intercept },
        { timestamp: end, value: end * slope + intercept },
      ];

      return {
        colour: chartLine.colour,
        hidden: chartLine.hidden || pointsInRange.length <= 2,
        linePoints,
      };
    });

    setProjectionLineData(projectionLines);
  }, [chartLines, inspectionWindow, inspectionWindowPosition]);

  return (
    <div className="EventChart">
      <svg
        className="EventChart-chartSvg"
        style={{ height, width }}
        onTouchStart={handleTooltip}
        onTouchMove={handleTooltip}
        onMouseMove={handleTooltip}
        onMouseLeave={hideTooltip}
      >
        <Group left={margin.left} top={margin.top} bottom={margin.bottom} right={margin.right}>
          <defs>
            <clipPath id="eventInnerChartHorizontalBoundary">
              <rect x={0} y={0} width={xMax} height={height} />
            </clipPath>
          </defs>
          <GridColumns scale={xScale} width={xMax} height={yMax} stroke="#D9DCE1" tickValues={xAxisTicks} />
          <AxisBottom
            top={yMax}
            scale={xScale}
            hideTicks
            stroke="#D9DCE1"
            tickFormat={xAxisTickFormat}
            tickValues={xAxisTicks}
          />
          <AxisLeft scale={yScale} hideTicks stroke="#D9DCE1" numTicks={5} tickFormat={format('.2s')} />
          {
            // Place the background selection highlight boxes.
            selectedEvents.map(({ colour, points }, outerIndex) => {
              const boxWidth = xScale(points[points.length - 1]) - xScale(points[0]);

              return (
                <rect
                  x={xScale(points[0])}
                  y={0}
                  height={yMax}
                  width={boxWidth}
                  fill={colour.fill}
                  opacity={0.5}
                  clipPath="url(#eventInnerChartHorizontalBoundary)"
                  key={outerIndex}
                />
              );
            })
          }

          {
            // Chart each of the line paths, filtering out hidden ones.
            chartLines
              .filter(({ hidden }) => !hidden)
              .map((chartLineData, index) => (
                <LinePath
                  data={chartLineData.linePlot}
                  x={getLineX}
                  y={getLineY}
                  stroke={chartLineData.colour}
                  strokeWidth={3}
                  strokeOpacity={1}
                  key={index}
                />
              ))
          }
          {
            // Chart the prediction line in projection range mode.
            inspectionWindow?.lineOfBestFit &&
              projectionLineData
                .filter(({ hidden }) => !hidden)
                .map((projectionLine, index) => (
                  <LinePath
                    data={projectionLine.linePoints}
                    x={getLineX}
                    y={getLineY}
                    stroke={projectionLine.colour}
                    fill={projectionLine.colour}
                    strokeWidth={2}
                    strokeOpacity={1}
                    strokeDasharray={(5, 5)}
                    key={index}
                  />
                ))
          }
          {
            // Create the vertical dashed line and circular intercept markers that accompany the tooltip.
            // Do not show if someone is interacting with the projection brushes.
            tooltipOpen && !inspectionWindowInteraction && (
              <g pointerEvents="none">
                <line
                  stroke="#000"
                  strokeWidth="1"
                  x1={tooltipLeft}
                  x2={tooltipLeft}
                  y1={0}
                  y2={yMax}
                  strokeDasharray={(5, 5)}
                />
                {
                  // Place (solid) circular intercept markers for all visible chart lines.
                  tooltipData.lineIntercepts.reduce((lineIntercepts, { value, timestamp, colour, hidden }, index) => {
                    if (hidden) return lineIntercepts;
                    // The X dot coord should never exceed the rightmost line point.
                    const xLimit = xScale(chartLines[index].linePlot.at(-1).timestamp);
                    // If not snapping to point data, we use the same X position as the vertical line for a smoother appearance.
                    // This comes at the cost of some visual accuracy compared to using xScale(timestamp).
                    // In either case, compare to the X limit and choose the leftmost value.
                    const x = Math.min(snapToPoints ? xScale(timestamp) : tooltipLeft, xLimit);
                    const y = yScale(value);
                    lineIntercepts.push(
                      <circle
                        key={index}
                        className="EventChart-chartInterceptCircleSvg"
                        cx={x}
                        cy={y}
                        r={7}
                        stroke={colour}
                      />,
                    );
                    return lineIntercepts;
                  }, [])
                }
                {
                  // Place dotted circular intercept markers for all Projected line intercepts
                  // If we've got the inspection window open
                  inspectionWindow?.lineOfBestFit &&
                    tooltipData.projectionLineIntercepts.reduce(
                      (projectionIntercepts, { value, timestamp, colour, hidden, hiddenProjection }, index) => {
                        if (hidden || hiddenProjection) return projectionIntercepts;
                        // We use the same X position as the vertical line for a smoother appearance.
                        // This comes at the cost of some visual accuracy compared to using xScale(timestamp).
                        const x = xScale(timestamp);
                        const y = yScale(value);
                        projectionIntercepts.push(
                          <circle
                            key={index + 'proj'}
                            className="EventChart-chartInterceptCircleSvg"
                            cx={x}
                            cy={y}
                            r={7}
                            stroke={colour}
                            strokeDasharray={2}
                          />,
                        );
                        return projectionIntercepts;
                      },
                      [],
                    )
                }
              </g>
            )
          }
          {inspectionWindow && (
            <Brush
              xScale={xScale}
              yScale={yScale}
              height={yMax}
              width={xMax}
              margin={margin}
              handleSize={8}
              resizeTriggerAreas={['left', 'right']}
              brushDirection="horizontal"
              initialBrushPosition={inspectionWindowInitialPosition}
              key={`${inspectionWindowInitialPosition.end.x} - ${inspectionWindowInitialPosition.start.x}`}
              innerRef={brushRef}
              useWindowMoveEvents={true}
              selectedBoxStyle={SELECTED_BRUSH_STYLE}
              renderBrushHandle={renderBrushHandle}
              onChange={saveProjectionPoints}
              onBrushStart={inspectionWindowInteractionStart}
              onBrushEnd={inspectionWindowInteractionEnd}
            />
          )}
          {
            // Place markers and lines for selected events.
            selectedEvents.map(({ colour, points }, outerIndex) => (
              <g key={outerIndex} clipPath="url(#eventInnerChartHorizontalBoundary)">
                {points.map((timestamp, innerIndex) => {
                  const x = xScale(timestamp);
                  // This point is within chart bounds, so display a vertical bar and chart intersection line.
                  return (
                    <g key={innerIndex}>
                      <line stroke="#000" strokeWidth="1" x1={x} x2={x} y1={0} y2={yMax} strokeDasharray={(5, 5)} />
                      <circle cx={x} cy={yMax} r={8} stroke={colour.stroke} strokeWidth={2} fill={colour.fill} />
                    </g>
                  );
                })}
              </g>
            ))
          }
        </Group>
      </svg>

      {
        // Create the chart legend with colour indicator, label, and button to hide or reveal each line chart.
        <div className="EventChart-legend" style={{ top: margin.top, left: margin.left }}>
          {chartLines.map(({ label, colour, link, hidden, onHideClick }, index) => {
            function toggleHidden() {
              onHideClick(true);
            }
            function toggleVisible() {
              onHideClick(false);
            }
            return (
              <div className="legendItem" key={index}>
                <div className="left">
                  <div className="circle" style={{ backgroundColor: colour }} />
                  {label}
                </div>
                <div className="icons">
                  {
                    // Visibility toggle is only shown when there are multiple lines on the chart.
                    chartLines.length > 1 &&
                      (hidden ? (
                        <ClosedEyeIcon className="eye" onClick={toggleVisible} />
                      ) : (
                        <OpenEyeIcon className="eye" onClick={toggleHidden} />
                      ))
                  }
                  {link && (
                    <Link className="details" to={link}>
                      <SearchIconSVG className="search" />
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      }

      {
        // Create the tooltip unless someone is interacting with the projection brushes.
        tooltipOpen && !inspectionWindowInteraction && (
          <TooltipWithBounds top={tooltipTop - 50} left={tooltipLeft + 35} style={TOOLTIP_STYLES}>
            <div
              className={`EventChart-tooltipInfo ${
                inspectionWindow?.lineOfBestFit ? 'EventChart-tooltipInfo--hasProjection' : ''
              }`}
            >
              {snapToPoints ? (
                // Group line intercepts by timestamp.
                Object.entries(
                  tooltipData.lineIntercepts
                    // Filter out hidden lines.
                    .filter(({ hidden }) => !hidden)
                    // Create a dict of timestamp-intercept(s) k-v pairs.
                    .reduce((dateGroups, lineIntercept) => {
                      if (!dateGroups[lineIntercept.timestamp]) {
                        dateGroups[lineIntercept.timestamp] = [];
                      }
                      dateGroups[lineIntercept.timestamp].push(lineIntercept);
                      return dateGroups;
                    }, {}),
                )
                  // Place more recent timestamps higher in the list.
                  .sort((t1, t2) => t1[0] - t2[0])
                  // Iterate over timestamp-intercept(s) pairs to build tooltip sections.
                  .map(([timestamp, groupedLineIntercepts], outerIndex) => (
                    <React.Fragment key={outerIndex}>
                      <p className="EventChart-tooltipInfoHeader">
                        {dayjs.tz(1000 * Number(timestamp)).format('MMM D, ddd')}
                      </p>
                      <div className="EventChart-toolTopInfo-dataholder">
                        {groupedLineIntercepts.map(({ value, colour }, innerIndex) => (
                          <div className="EventChart-tooltipInfo-dataholder-mass" key={innerIndex}>
                            <div className="EventChart-tooltipCircle" style={{ backgroundColor: colour }} />
                            <span className="EventChart-tooltipInfo-dataholder-text">
                              {addComma(Math.round(value))} {weightSmallUnitLabel(isMetric)}
                            </span>
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  ))
              ) : (
                <>
                  <p className="EventChart-tooltipInfoHeader">{tooltipData.displayedDate}</p>
                  <div className="EventChart-tooltipInfo-dataholder">
                    {tooltipData.lineIntercepts.reduce((tooltipValueRow, { value, colour, hidden }, index) => {
                      if (hidden) return tooltipValueRow;
                      // Store the projected value if the projection window is enabled.
                      const projectedValue = tooltipData.projectionLineIntercepts[index].value;
                      const hiddenProjection = tooltipData.projectionLineIntercepts[index].hiddenProjection;
                      tooltipValueRow.push(
                        <React.Fragment key={index}>
                          <div className="EventChart-tooltipInfo-dataholder-mass">
                            <div className={`EventChart-tooltipCircle`} style={{ backgroundColor: colour }} />
                            <span className="EventChart-tooltipInfo-dataholder-text">
                              {addComma(Math.round(value))} {weightSmallUnitLabel(isMetric)}
                            </span>
                          </div>
                          {inspectionWindow?.lineOfBestFit && (
                            <div className="EventChart-tooltipInfo-dataholder-projectedMass">
                              <div
                                className={`EventChart-tooltipCircle EventChart-tooltipCircle--dotted`}
                                style={{ borderColor: colour }}
                              />
                              <span className="EventChart-tooltipInfo-dataholder-text">
                                {hiddenProjection ? (
                                  '?'
                                ) : (
                                  <>
                                    {projectedValue - value > 0 ? ' +' : ' '}
                                    {addComma(Math.round(projectedValue - value))} {weightSmallUnitLabel(isMetric)}
                                  </>
                                )}
                              </span>
                            </div>
                          )}
                        </React.Fragment>,
                      );
                      return tooltipValueRow;
                    }, [])}
                  </div>
                </>
              )}
            </div>
          </TooltipWithBounds>
        )
      }
    </div>
  );
}

EventInnerChart.propTypes = {
  chartLines: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  xAxisTicks: PropTypes.array,
  xMax: PropTypes.number.isRequired,
  yMax: PropTypes.number.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  margin: PropTypes.object.isRequired,
  inspectionWindow: PropTypes.object,
  snapToPoints: PropTypes.bool.isRequired,
  isMetric: PropTypes.bool.isRequired,
};

export default EventInnerChart;
