import PropTypes from 'prop-types';
import { DATE_DAY_OF_WEEK, DATE_CALENDAR_DATE } from '../../../../utils/dates';
import './GridDateBlock.scss';

function GridDateBlock({
  dateBlockRef = null,
  className = '',
  dateClassName = '',
  style = {},
  tabIndex = 0,
  isCompact = false,
  isDayOfWeekLabel = false,
  isFirstRow = false,
  isLastColumn = false,
  isLastRow = false,
  isDeemphasized = false,
  isBodyHighlighted = false,
  isDateHighlighted = false,
  date = null,
  children = [],
  onClick = () => {},
}) {
  const compactBlockClassName = isCompact ? 'GridDateBlock--compact' : '';
  const highlightedBlockClassName = isBodyHighlighted ? 'GridDateBlock--highlighted' : '';
  const firstRowClassName = isFirstRow ? 'GridDateBlock--firstRow' : '';
  const lastColumnClassName = isLastColumn ? 'GridDateBlock--lastColumn' : '';
  const lastRowClassName = isLastRow ? 'GridDateBlock--lastRow' : '';
  const compactDateClassName = isCompact ? 'GridDateBlock-date--compact' : '';
  const deemphasizedDateClassName = isDeemphasized ? 'GridDateBlock-date--deemphasized' : '';
  const highlightedDateClassName = isDateHighlighted ? 'GridDateBlock-date--highlighted' : '';
  const dayOfWeek = isCompact ? date.format(DATE_DAY_OF_WEEK).charAt(0) : date.format(DATE_DAY_OF_WEEK);

  const onDateBlockKeyDown = (e) => {
    if (e.key !== 'Enter' && e.code !== 'Space') {
      return;
    }

    e.preventDefault();
    onClick(date.clone());
  };

  const onDateBlockClick = () => {
    onClick(date.clone());
  };

  if (isDayOfWeekLabel) {
    return (
      <div
        className={`GridDateBlock GridDateBlock-dayOfWeek ${compactBlockClassName} ${lastColumnClassName} ${lastRowClassName} ${className}`}
        style={style}
        tabIndex={-1}
      >
        {dayOfWeek}
      </div>
    );
  }

  return (
    <div
      ref={dateBlockRef}
      className={`GridDateBlock ${compactBlockClassName} ${highlightedBlockClassName} ${firstRowClassName} ${lastColumnClassName} ${lastRowClassName} ${className}`}
      style={style}
      tabIndex={tabIndex}
      onKeyDown={onDateBlockKeyDown}
      onClick={onDateBlockClick}
    >
      <div
        className={`GridDateBlock-date ${compactDateClassName} ${deemphasizedDateClassName} ${highlightedDateClassName} ${dateClassName}`}
      >
        {date.format(DATE_CALENDAR_DATE)}
      </div>
      {children}
    </div>
  );
}

GridDateBlock.propTypes = {
  dateBlockRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  className: PropTypes.string,
  dateClassName: PropTypes.string,
  style: PropTypes.object,
  tabIndex: PropTypes.number,
  isCompact: PropTypes.bool,
  isDayOfWeekLabel: PropTypes.bool,
  isFirstRow: PropTypes.bool,
  isLastColumn: PropTypes.bool,
  isLastRow: PropTypes.bool,
  isDeemphasized: PropTypes.bool,
  isBodyHighlighted: PropTypes.bool,
  isDateHighlighted: PropTypes.bool,
  date: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClick: PropTypes.func,
};

export default GridDateBlock;
