import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import WebAppContext from '../../utils/webAppContext';
import { convertSmallUnitsToGrams, convertGramsToSmallUnits } from '../../utils/unitConversion';
import { CalibrationStatus } from '../../utils/enums';
import CalibrationHeader from '../../molecules/CalibrationHeader';
import CalibrationProcessChain from '../../molecules/CalibrationProcessChain';
import CalibrationForm from '../CalibrationForm';
import './CreateCalibrationView.scss';

function CreateCalibrationView({
  barnId = '',
  initialStep = 1,
  calibrationInformation,
  uniqueBinSets = [],
  onSave = () => {},
}) {
  const { isMetric } = useContext(WebAppContext);
  const [currentStepNumber, setCurrentStepNumber] = useState(initialStep);
  const [selectedBinSet, setSelectedBinSet] = useState(
    calibrationInformation ? calibrationInformation?.bin_set_id : uniqueBinSets?.[0]?.id,
  );
  const [knownWeight, setKnownWeight] = useState(
    calibrationInformation?.provided_mass_in_grams
      ? convertGramsToSmallUnits(isMetric, calibrationInformation?.provided_mass_in_grams, 0)
      : null,
  );
  const [startedAt, setStartedAt] = useState(
    calibrationInformation?.started_at ? new Date(calibrationInformation?.started_at * 1000) : null,
  );
  const [endedAt, setEndedAt] = useState(
    calibrationInformation?.ended_at ? new Date(calibrationInformation?.ended_at * 1000) : null,
  );
  const [calibrationStatus, setCalibrationStatus] = useState(
    calibrationInformation ? calibrationInformation?.status : CalibrationStatus.FormStarted,
  );
  const [validationMessage, setValidationMessage] = useState('');
  const [formFieldUpdated, setFormFieldUpdated] = useState(true);

  useEffect(() => {
    if (calibrationStatus === CalibrationStatus.FormSubmitted) {
      onClickSave();
    }
  }, [calibrationStatus]);

  const onClickPrevious = () => {
    setFormFieldUpdated(true);
    setCurrentStepNumber(currentStepNumber - 1);
  };

  const onClickContinue = () => {
    setFormFieldUpdated(true);
    setCurrentStepNumber(currentStepNumber + 1);
  };

  const onStartedAtUpdate = (date) => {
    setFormFieldUpdated(true);
    setStartedAt(date);
  };

  const onEndedAtUpdate = (date) => {
    setFormFieldUpdated(true);
    setEndedAt(date);
  };

  const onBinSetUpdate = (newSelection) => {
    setFormFieldUpdated(true);
    setSelectedBinSet(newSelection.id);
  };

  const onKnownWeightUpdate = (weight) => {
    setFormFieldUpdated(true);
    setKnownWeight(weight);
  };

  const onClickCalculate = () => {
    if (validateInput()) {
      setCalibrationStatus(CalibrationStatus.FormSubmitted);
    }
  };

  const onClickSave = () => {
    onSave(
      currentStepNumber - 1,
      selectedBinSet,
      convertSmallUnitsToGrams(isMetric, parseInt(knownWeight, 10), 0),
      Math.floor(startedAt / 1000),
      Math.floor(endedAt / 1000),
      calibrationStatus,
    );
    setFormFieldUpdated(false);
  };

  const validateInput = () => {
    if (selectedBinSet && startedAt && endedAt) {
      if (Math.floor(startedAt / 1000) === Math.floor(endedAt / 1000) && startedAt > 0 && endedAt > 0) {
        setValidationMessage('Start and end time must be different.');
        return false;
      }

      setValidationMessage('');
      return true;
    } else {
      setValidationMessage('Enter all of the required fields.');
      return false;
    }
  };

  return (
    <>
      <CalibrationHeader
        barnId={barnId}
        saveButtonVisible={calibrationStatus === CalibrationStatus.FormStarted && formFieldUpdated}
        onClickSave={onClickSave}
      />
      <div className="CreateCalibrationView">
        <CalibrationProcessChain
          className="CreateCalibrationView-leftSide"
          currentStepNumber={currentStepNumber}
          onClickPrevious={onClickPrevious}
          onClickContinue={onClickContinue}
        />

        <div className="CreateCalibrationView-rightSide">
          <CalibrationForm
            className="CreateCalibrationView-CalibrationForm"
            binSets={uniqueBinSets}
            startedAt={startedAt}
            endedAt={endedAt}
            knownWeight={knownWeight?.toString()}
            selectedBinSetId={selectedBinSet}
            formSubmitted={calibrationStatus === CalibrationStatus.FormSubmitted}
            onStartedAtUpdate={onStartedAtUpdate}
            onEndedAtUpdate={onEndedAtUpdate}
            onBinSetUpdate={onBinSetUpdate}
            onKnownWeightUpdate={onKnownWeightUpdate}
            onClickCalculate={onClickCalculate}
          />

          <p className="CreateCalibrationView-message">{validationMessage}</p>
        </div>
      </div>
    </>
  );
}

CreateCalibrationView.propTypes = {
  barnId: PropTypes.string,
  initialStep: PropTypes.number,
  calibrationInformation: PropTypes.object,
  uniqueBinSets: PropTypes.array,
  onSave: PropTypes.func,
};

export default CreateCalibrationView;
