import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ConfirmationDialogView from './ConfirmationDialogView';

function ConfirmationDialog({
  dialogRef = null,
  open = false,
  message = '',
  onCancel = () => {},
  onConfirm = () => {},
}) {
  return ReactDOM.createPortal(
    <ConfirmationDialogView
      dialogRef={dialogRef}
      open={open}
      message={message}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />,
    document.getElementById('portal-root'),
  );
}

ConfirmationDialog.propTypes = {
  dialogRef: PropTypes.object,
  open: PropTypes.bool,
  message: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ConfirmationDialog;
