import React, { useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE } from '../../utils/dates';
import FeedFloTextInput from '../../atoms/FeedFloTextInput/FeedFloTextInput';
import { getFaultName } from '@norimaconsulting/fault-codes';

const DEVICE_GQL = gql`
  query DeviceFaultQuery($deviceId: uuid!) {
    faultCodes: fault(where: { device_id: { _eq: $deviceId } }, order_by: { started_at: desc_nulls_last }, limit: 200) {
      started_at
      ended_at
      code
      id
      details
    }
  }
`;

export default function DeviceFaultTable({ deviceId }) {
  const now = useMemo(() => {
    return Math.floor(Date.now() / 1000);
  }, []);

  const { loading, error, data } = useQuery(DEVICE_GQL, {
    variables: {
      deviceId,
      start: now - 4 * 60 * 60,
    },
  });

  const safeGetFaultName = (code) => {
    try {
      return getFaultName(code);
    } catch (error) {
      return code;
    }
  };

  const faultCodes = data?.faultCodes;
  const [faultCodeSearchText, setFaultCodeSearchText] = useState('');
  const filteredFaultCodes = useMemo(() => {
    if (faultCodeSearchText === '') {
      // Return all when the search text is empty
      return faultCodes;
    }

    const codeTokens = faultCodeSearchText.split(',').filter((token) => token.length);
    return faultCodes.filter((fault) => codeTokens.some((token) => fault.code.toString().startsWith(token)));
  }, [faultCodes, faultCodeSearchText]);

  if (loading) return <span>Loading</span>;
  if (error) return <span>{JSON.stringify(error, null, 2)}</span>;

  const faultView = (
    <div className="faultCodeHolder">
      <div className="faultLine">
        <table>
          <thead>
            <tr>
              <th>Code</th>
              <th>Started</th>
              <th>Ended</th>
              <th>Duration</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredFaultCodes?.map((fCode) => {
              // Convert date values into seconds
              const from = fCode.started_at;
              const to = fCode.ended_at || from + 24 * 60 * 60; // Use the end date if the fault has ended, otherwise use 24 hours

              let icon = '🔷';
              if (1000 <= fCode.code && fCode.code <= 1999) {
                icon = '✅';
              } else if (fCode.code === 2001 || fCode.code === 2002) {
                icon = fCode.ended_at ? '🏁' : '🏃';
              } else if (3000 <= fCode.code && fCode.code <= 3999) {
                icon = '❌';
              }
              return (
                <tr key={fCode.id}>
                  <td title={fCode.code}>
                    {icon}
                    {safeGetFaultName(fCode.code)}
                  </td>
                  <td>{dayjs.tz(1000 * from).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE)}</td>
                  <td>
                    {fCode.ended_at ? dayjs.tz(1000 * to).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE) : 'None'}
                  </td>
                  <td>{dayjs.tz(1000 * to || Date.now()).from(from * 1000, true)}</td>
                  <td>
                    <Link target="_blank" to={`/rawData/${deviceId}?to=${to}&from=${from}`}>
                      📈
                    </Link>
                    <Link target="_blank" to={`/fault/${fCode.id}`}>
                      🔎
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <>
      <FeedFloTextInput defaultText="Search by Fault Code" onChange={(value) => setFaultCodeSearchText(value.trim())} />
      <div>{faultView}</div>
    </>
  );
}

DeviceFaultTable.propTypes = {
  deviceId: PropTypes.string,
};
