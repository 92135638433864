import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE } from '../../utils/dates';
import Button from '../../atoms/Button';
import Card from '../../atoms/Card';
import LabelledColumn from '../../atoms/LabelledColumn';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';
import { LogoIcon, WarningIcon } from '../../atoms/Icons';
import withStaffOnlyIdWrapper from '../../utils/withStaffOnlyIdWrapper';
import './DeviceStatusCard.scss';

function DeviceStatusCard({
  loading = false,
  className = '',
  isOnline = false,
  serial = '',
  feedLineName = '',
  lastUploadInSeconds = 0,
  feedLineLink = '',
  ffId,
}) {
  if (loading) {
    return (
      <Card
        loading={loading}
        className={`DeviceStatusCard DeviceStatusCard--loading ${className}`}
        contentClassName="DeviceStatusCard-cardContentWrapper"
        status={isOnline ? 'success' : 'error'}
        accentLocation="top"
      >
        <LoadingSkeleton className="DeviceStatusCard-serial DeviceStatusCard-serial--loading" />
        <div className="DeviceStatusCard-content">
          <div className="DeviceStatusCard-statusWrapper">
            <LoadingSkeleton className="DeviceStatusCard-status DeviceStatusCard-status--loading" />
          </div>
          <div className="DeviceStatusCard-details">
            <LabelledColumn loading={loading} className="DeviceStatusCard-feedLine--loading" heading="Feed Line" />
            <LabelledColumn loading={loading} className="DeviceStatusCard-lastUpload--loading" heading="Last Upload" />
            <LoadingSkeleton className="DeviceStatusCard-link DeviceStatusCard-link--loading" />
          </div>
        </div>
      </Card>
    );
  }

  const renderIcon = () => {
    if (isOnline) {
      return <LogoIcon className="DeviceStatusCard-icon DeviceStatusCard-icon--success" />;
    }

    return <WarningIcon className="DeviceStatusCard-icon DeviceStatusCard-icon--error" />;
  };

  const renderStatus = () => {
    if (isOnline) {
      return <div className="DeviceStatusCard-status DeviceStatusCard-status--success">Online</div>;
    }

    return <div className="DeviceStatusCard-status DeviceStatusCard-status--error">Offline</div>;
  };

  const renderLastUpload = () => {
    if (lastUploadInSeconds) {
      return dayjs.tz(1000 * lastUploadInSeconds).format(DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE);
    }

    return 'Unknown';
  };

  const IdWrappedTitle = withStaffOnlyIdWrapper(serial);

  return (
    <Card
      className={`DeviceStatusCard ${className}`}
      contentClassName="DeviceStatusCard-cardContentWrapper"
      status={isOnline ? 'success' : 'error'}
      accentLocation="top"
    >
      <IdWrappedTitle data={ffId} hoverText="Copy Device ID" className="DeviceStatusCard-serial" />
      <div className="DeviceStatusCard-content">
        <div className="DeviceStatusCard-statusWrapper">
          {renderIcon()}
          {renderStatus()}
        </div>
        <div className="DeviceStatusCard-details">
          <LabelledColumn heading="Feed Line">{feedLineName}</LabelledColumn>
          <LabelledColumn heading="Last Upload">{renderLastUpload()}</LabelledColumn>
          {feedLineLink ? (
            <Button
              className="DeviceStatusCard-link"
              variant="text"
              color="success"
              linkTo={feedLineLink}
              content="View Line"
            />
          ) : null}
        </div>
      </div>
    </Card>
  );
}

DeviceStatusCard.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  isOnline: PropTypes.bool,
  serial: PropTypes.string,
  feedLineName: PropTypes.string,
  lastUploadInSeconds: PropTypes.number,
  feedLineLink: PropTypes.string,
  ffId: PropTypes.string,
};

export default DeviceStatusCard;
