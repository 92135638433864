import React from 'react';
import PropTypes from 'prop-types';
import { RedXIcon } from '../../atoms/Icons';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';
import './ListRow.scss';

function ListRow({ loading = false, id = '', name = '', onRemove = () => {} }) {
  if (loading) {
    return (
      <div className="ListRow">
        <div className="ListRow-name">
          <LoadingSkeleton className="ListRow-name--loader" />
        </div>
        <div className="ListRow-removeIcon" />
      </div>
    );
  }

  const onRemoveButtonClick = () => {
    onRemove(id);
  };

  return (
    <div className="ListRow">
      <div className="ListRow-name">{name}</div>
      <div className="ListRow-removeIcon" role="button" tabIndex={0} onClick={onRemoveButtonClick}>
        <RedXIcon />
      </div>
    </div>
  );
}

ListRow.propTypes = {
  loading: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onRemove: PropTypes.func,
};

export default ListRow;
