import PropTypes from 'prop-types';

function SearchPopoverValueRow({ id = '', text = '', subtext = '', onClick = () => {} }) {
  const onRowClick = () => {
    onClick(id);
  };

  return (
    <div className="SearchPopover-valueRow" onClick={onRowClick}>
      {text}
      <span className="SearchPopover-subtext">{subtext}</span>
    </div>
  );
}

SearchPopoverValueRow.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  subtext: PropTypes.string,
  onClick: PropTypes.func,
};

export default SearchPopoverValueRow;
