import PropTypes from 'prop-types';
import { useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import ChartLegend from '../../molecules/ChartLegend';

const styles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: '#3c4257f0',
  color: 'white',
  borderRadius: '0.75rem',
  padding: '1rem',
};

function BarStackChartTooltip({
  top = 0,
  left = 0,
  keys = [],
  keyNameMap = {},
  data = {},
  titleAccessor = () => {},
  titleFormatter = () => {},
  colorScale = () => {},
  valueFormatter = () => {},
}) {
  const { TooltipInPortal } = useTooltipInPortal({ scroll: true });
  const valueMap = keys.reduce((map, key) => {
    if (!map[key]) {
      map[key] = {};
    }

    map[key].current = data.bar.data[key];
    return map;
  }, {});

  return (
    <TooltipInPortal className="BarStackChart-tooltip" top={top} left={left} style={styles}>
      <div className="BarStackChart-tooltipHeader">
        <div>{titleFormatter(titleAccessor(data.bar.data))}</div>
        <div>{valueFormatter(keys.reduce((total, key) => total + data.bar.data[key], 0))}</div>
      </div>
      <ChartLegend
        keys={keys}
        keyNameMap={keyNameMap}
        valueMap={valueMap}
        colorScale={colorScale}
        valueFormatter={valueFormatter}
      />
    </TooltipInPortal>
  );
}

BarStackChartTooltip.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  keys: PropTypes.array,
  keyNameMap: PropTypes.object,
  data: PropTypes.object,
  titleAccessor: PropTypes.func,
  titleFormatter: PropTypes.func,
  colorScale: PropTypes.func,
  valueFormatter: PropTypes.func,
};

export default BarStackChartTooltip;
