import PropTypes from 'prop-types';
import './ConfirmationDialog.scss';
import Button, { Colors, Variants } from '../../atoms/Button';

function ConfirmationDialogView({
  dialogRef = null,
  open = false,
  message = '',
  onCancel = () => {},
  onConfirm = () => {},
}) {
  const onCloseDialog = () => {
    if (!dialogRef?.current) {
      return;
    }

    dialogRef.current.close();
  };

  const onCancelClick = () => {
    onCancel();
    onCloseDialog();
  };

  const onConfirmClick = () => {
    onConfirm();
    onCloseDialog();
  };

  return (
    <dialog ref={dialogRef} className="ConfirmationDialog" open={open}>
      <div className="ConfirmationDialog-message">
        {message.split('\n').map((line, i) => {
          return (
            <div key={i} className="ConfirmationDialog-messageLine">
              {line}
            </div>
          );
        })}
      </div>
      <div className="ConfirmationDialog-buttons">
        <Button onClick={onCancelClick} content="Cancel" />
        <Button onClick={onConfirmClick} variant={Variants.Vivid} color={Colors.Danger} content="Confirm" />
      </div>
    </dialog>
  );
}

ConfirmationDialogView.propTypes = {
  dialogRef: PropTypes.object,
  open: PropTypes.bool,
  message: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ConfirmationDialogView;
