import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { GreenArrowIcon } from '../../atoms/Icons';
import FeedFloButton from '../../atoms/FeedFloButton';
import Heading from '../../atoms/Heading';
import './CalibrationHeader.scss';

function CalibrationHeader({ barnId = '', saveButtonVisible = true, onClickSave = () => {} }) {
  const onClickDebounce = debounce(onClickSave, 100);
  return (
    <>
      <Link className="CalibrationHeader-back-link" to={`/b/${barnId}/calibrations`}>
        <GreenArrowIcon className="CalibrationHeader-back-link-icon" />
        <p>Calibrations</p>
      </Link>

      <div className="CalibrationHeader-header">
        <Heading text="FeedFlo Accuracy Test" />
        {saveButtonVisible ? (
          <FeedFloButton className="CalibrationHeader-header-save-button" type="secondary" onClick={onClickDebounce}>
            Save
          </FeedFloButton>
        ) : null}
      </div>
    </>
  );
}

CalibrationHeader.propTypes = {
  barnId: PropTypes.string,
  saveButtonVisible: PropTypes.bool,
  onClickSave: PropTypes.func,
};

export default CalibrationHeader;
