import PropTypes from 'prop-types';
import { DownChevronIcon } from '../Icons';
import './Chevron.scss';

function Chevron({ className = '', open = false, onClick = () => {} }) {
  return <DownChevronIcon className={`Chevron ${open ? 'Chevron--open' : ''} ${className}`} onClick={onClick} />;
}

Chevron.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Chevron;
