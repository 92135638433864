import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { DATE_FORMAT_MONTH_DAY_YEAR } from '../../utils/dates';
import Button from '../../atoms/Button/Button';
import APIInfoTile from '../../molecules/APIInfoTile';
import FeedFloNewAccessKey from '../FeedFloNewAccessKey';
import ConfirmationDialog from '../ConfirmationDialog';
import './FeedFloApiTokenController.scss';

const GET_TOKENS_GQL = gql`
  query GetUsersTokens {
    api_access_token(
      where: { revoked_at: { _is_null: true }, deleted_at: { _is_null: true } }
      order_by: { created_at: desc }
    ) {
      created_at
      expires_at
      label
      id
      revoked_at
      details
    }
  }
`;

const NEW_TOKEN_GQL = gql`
  mutation NewTokenMutation($roles: [String!]!, $label: String!, $expires_at: Int) {
    api_access_token_creation(roles: $roles, label: $label, expires_at: $expires_at) {
      id
      token
      expires_at
    }
  }
`;

const REVOKE_TOKEN_GQL = gql`
  mutation RevokeTokenMutation($tokenId: uuid!, $now: bigint!) {
    update_api_access_token(where: { id: { _eq: $tokenId } }, _set: { revoked_at: $now }) {
      affected_rows
    }
  }
`;

/***
 * ? NEEDS REVIEW ?
 */
function FeedFloApiTokenController() {
  // Modal States
  const [newTokenModalOpen, setNewTokenModalOpen] = useState(false);
  const [tokenIdToDelete, setTokenIdToDelete] = useState(null);
  const dialogRef = useRef(null);

  const { loading, data } = useQuery(GET_TOKENS_GQL);
  const tokens = data?.api_access_token || null;

  const [newToken, { data: newTokenData }] = useMutation(NEW_TOKEN_GQL);
  const [revokeToken] = useMutation(REVOKE_TOKEN_GQL);

  async function handelGenerateNewToken({ label, expiryPeriodInDays, roles }) {
    const expiry = expiryPeriodInDays ? dayjs.tz().add(expiryPeriodInDays, 'days').unix() : null;
    newToken({
      variables: {
        roles,
        label,
        expires_at: expiry,
      },
      refetchQueries: ['GetUsersTokens'],
    });
  }

  async function handleRevokeToken(tokenId) {
    revokeToken({
      variables: {
        tokenId,
        now: Math.floor(Date.now() / 1000),
      },
      refetchQueries: ['GetUsersTokens'],
    });
  }

  const PERMISSION_TEXT_MAP = {
    'api-data-science': 'read: farms, lines, feed events',
    'api-feed-manager': 'read & write: deliveries. read: bins, bin levels, feed profiles',
  };

  const onShowConfirmationDialog = () => {
    if (!dialogRef.current) {
      return;
    }

    dialogRef.current.showModal();
  };

  function createTokenRow(token) {
    const permissions = PERMISSION_TEXT_MAP[token?.details?.roles?.[0]] || 'None';
    let dateString = 'No Expiry';
    let secret = null;
    if (token.id === newTokenData?.api_access_token_creation?.id) {
      secret = newTokenData?.api_access_token_creation?.token;
    }

    if (token.expires_at) {
      dateString = dayjs.tz(1000 * token.expires_at).format(DATE_FORMAT_MONTH_DAY_YEAR);
    }
    return (
      <APIInfoTile
        key={token.id}
        title={token.label}
        permissions={permissions}
        date={dateString}
        secretToken={secret}
        onDelete={() => {
          setTokenIdToDelete(token.id);
          onShowConfirmationDialog();
        }}
      />
    );
  }

  const newTokenModalView = (
    <FeedFloNewAccessKey
      onCancel={() => {
        setNewTokenModalOpen(false);
      }}
      onGenerate={async (options) => {
        await handelGenerateNewToken(options);
        setNewTokenModalOpen(false);
      }}
    />
  );

  return (
    <div className="FeedFloApiTokenController">
      {newTokenModalOpen ? newTokenModalView : null}
      <ConfirmationDialog
        dialogRef={dialogRef}
        message={'Are you sure you want to revoke\nthis key?'}
        onConfirm={async () => {
          if (tokenIdToDelete) {
            await handleRevokeToken(tokenIdToDelete);
          }
        }}
      />
      <h3>API Tokens</h3>
      <div className="ButtonHolder">
        <Button
          variant="vivid"
          color="success"
          onClick={() => {
            setNewTokenModalOpen(true);
          }}
          content="Generate New Token"
        />
      </div>

      <div className="TokenRowHolder">
        <div className="TokenListMessage">
          {loading && <span>Loading your tokens... </span>}
          {tokens?.length === 0 && <span>No API Access Tokens</span>}
          {tokens?.length > 0 && tokens.map(createTokenRow)}
        </div>
      </div>
    </div>
  );
}

FeedFloApiTokenController.propTypes = {};

export default FeedFloApiTokenController;
