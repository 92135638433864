import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LoadingView from './atoms/LoadingView';

const HASURA_URL = import.meta.env.VITE_HASURA_URL;
const appDisplayVersion = import.meta.env.VITE_GIT_SHORT_HASH;

// C&P'd from setupSentry.js if needed in a 3rd place, let's just put it in utils
function determineEnvironment() {
  // hostname gives us localhost. host would give us localhost:3000
  const subDomain = window.location.hostname.split('.')[0]; // subodmain OR localhost

  switch (subDomain) {
    case 'localhost':
      return 'local-dev';
    case 'my':
      return 'production';
    case 'dev':
      return 'development';
    default:
      return 'unknown';
  }
}

export default function ApolloWrapper({ children }) {
  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, user } = useAuth0();
  const [bearerToken, setBearerToken] = useState(null);
  const [apClient, setApClient] = useState(null);
  if (!isAuthenticated && !isLoading) {
    loginWithRedirect({ appState: { returnTo: window.location.pathname + window.location.search } });
  }

  useEffect(() => {
    async function checkAuthentication() {
      if (isAuthenticated) {
        const token = isAuthenticated ? await getAccessTokenSilently() : null;
        setBearerToken(token);
      }
    }
    checkAuthentication();
  }, [isAuthenticated]);

  const httpLink = createHttpLink({
    uri: HASURA_URL,
  });

  const authLink = setContext(async (_, { headers, ...context }) => {
    if (!bearerToken) return { headers };
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${bearerToken}`,
        'X-Hasura-Role': user?.['https://feedflo.com/roles']?.includes('researcher') ? 'researcher' : 'user',
      },
      ...context,
    };
  });

  if (bearerToken && !apClient) {
    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      name: `web_${determineEnvironment()}`,
      version: appDisplayVersion || 'unknown',
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              alert_dashboard_table_data: {
                keyArgs: false,
                merge: (existing = {}, incoming, { args }) => {
                  const pageSize = 50; // The max number of rows that can be returned for the Alert Table
                  const merged = existing?.rows && args.page > 0 ? existing.rows.slice() : [];

                  for (let i = 0; i < incoming.rows.length; i++) {
                    merged[args.page * pageSize + i] = incoming.rows[i];
                  }

                  return {
                    ...incoming,
                    rows: merged,
                  };
                },
              },
            },
          },
        },
      }),
      connectToDevTools: true,
    });
    setApClient(client);
  }

  if (apClient) {
    return <ApolloProvider client={apClient}>{children}</ApolloProvider>;
  }
  return (
    <div className="loadingPage">
      <LoadingView />
    </div>
  );
}

ApolloWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
