export const TIMEFRAME_ENUM = Object.freeze({
  Day: 'days',
  Week: 'weeks',
  Month: 'months',
});

export const TIMEFRAME_TO_DAYJS_KEY = Object.freeze({
  [TIMEFRAME_ENUM.Day]: 'D',
  [TIMEFRAME_ENUM.Week]: 'w',
  [TIMEFRAME_ENUM.Month]: 'M',
});
