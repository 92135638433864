import 'react-cmdk/dist/cmdk.css';
import * as Sentry from '@sentry/browser';
import CommandPalette, { filterItems, getItemIndex } from 'react-cmdk';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom, useSetAtom } from 'jotai';

import { algorithmOverriddenAtom, algorithmVersionAtom, featureFlagsAtom } from '../../utils/jotaiAtoms';
import useUser from '../../utils/hooks/useUser';
import './CommandPalette.scss';

/**
 * A Command Palette, for STAFF ONLY to invoke custom algs, feature flags, etc.
 * @returns React Component
 */
const FeedFloCommandPalette = () => {
  const { user, loading } = useUser();
  const navigate = useNavigate();
  const [page] = useState('root');
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [algorithmVersion, setAlgorithmVersion] = useAtom(algorithmVersionAtom);
  const setAlgorithmOveridden = useSetAtom(algorithmOverriddenAtom);
  const [originalAlgorithmVersion, setOriginalAlgorithmVersion] = useState(null);
  const [features, setFeatureFlagArray] = useAtom(featureFlagsAtom);

  useEffect(() => {
    // Only set this the first time
    if (!originalAlgorithmVersion) {
      setOriginalAlgorithmVersion(algorithmVersion);
    }
  }, [algorithmVersion]);

  useEffect(() => {
    function handleKeyDown(e) {
      // Note: navigator.platform is deprecated. Some suggest User Agent sniffing as an alternative
      //       however this is the exact check that react-cmdk itself uses for their hook
      if ((navigator?.platform?.toLowerCase().includes('mac') ? e.metaKey : e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        e.stopPropagation();

        setOpen((currentValue) => {
          return !currentValue;
        });
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [user, loading]);

  const STAFF_ONLY_MENU_ITEMS = [
    {
      heading: 'Data Science Stuff',
      id: 'datasci',
      icon: 'BeakerIcon',
      items: [
        {
          id: 'setalg',
          icon: 'BeakerIcon',
          showType: false,
          children: 'Set Algorithm Version',
          onClick: () => {
            const alg = prompt('Enter Algorithm Version to use');
            if (alg) {
              setAlgorithmVersion(alg);
              setAlgorithmOveridden(true);
            }
          },
        },
        {
          id: 'resetAlg',
          icon: 'ArrowPathIcon',
          showType: false,
          children: 'Reset Algorithm',
          onClick: () => {
            setAlgorithmVersion(originalAlgorithmVersion);
            setAlgorithmOveridden(false);
          },
        },
      ],
    },
    {
      heading: 'Misc',
      id: 'dev',
      icon: 'CpuChipIcon',
      items: [
        {
          id: 'gotosettings',
          icon: 'CogIcon',
          showType: false,
          children: 'Go To Settings',
          onClick: () => {
            navigate('/settings');
          },
        },
        {
          id: 'throwerror',
          icon: 'FireIcon',
          showType: false,
          children: 'Throw Error',
          onClick: () => {
            const msg = prompt('Enter An Error Message');
            throw new Error(msg || 'Test Error - No Text Provided');
          },
        },
        {
          id: 'sentrymessage',
          icon: 'FireIcon',
          showType: false,
          children: 'Sentry Capture Message',
          onClick: () => {
            const msg = prompt('Enter Sentry Message');
            Sentry?.captureMessage(msg || 'Test - Capture Message');
          },
        },
        {
          id: 'sentryexception',
          icon: 'FireIcon',
          showType: false,
          children: 'Sentry Capture Exception',
          onClick: () => {
            try {
              const msg = prompt('Enter Sentry Exception');
              throw new Error(msg);
            } catch (e) {
              Sentry?.captureException(e);
            }
          },
        },
      ],
    },
  ];

  const ALL_USER_MENU_ITEMS = [
    {
      heading: 'Feature Flags',
      id: 'featureflags',
      icon: 'FlagIcon',
      items: [
        {
          id: 'resetFlags',
          icon: 'ArrowPathIcon',
          showType: false,
          children: 'Disable All Feature Flags',
          onClick: () => {
            setFeatureFlagArray(features.map((old) => ({ ...old, active: false })));
          },
        },
        ...features.map((feature) => {
          // Dynamically generate the list of feature flags, display them and allow us to easily set them
          return {
            id: feature?.name,
            // showType: false,
            icon: feature?.active ? 'CheckCircleIcon' : 'NoSymbolIcon',
            children: (
              <span className="CommandPaletteListItem-text">
                Set {feature?.name} to {feature?.active ? 'inactive' : 'active'}
              </span>
            ),
            keywords: feature?.name.split('_'),
            onClick: () => {
              // Toggle only the feature that we selected
              const newArray = features.map((f) => {
                if (f.name == feature.name) {
                  return { ...f, active: !feature?.active };
                } else {
                  return f;
                }
              });
              // Note: I wanted to use the FeatureFlagsAtomsAtom but
              // ran into render order issues, this array approach seems
              // to work well and doesn't cause issues so far
              setFeatureFlagArray(newArray);
            },
          };
        }),
      ],
    },
  ];

  let menuItems = ALL_USER_MENU_ITEMS;

  if (user.isStaff) {
    menuItems = menuItems.concat(STAFF_ONLY_MENU_ITEMS);
  }

  const filteredItems = filterItems(menuItems, search);

  return (
    <CommandPalette onChangeSearch={setSearch} onChangeOpen={setOpen} search={search} isOpen={open} page={page}>
      <CommandPalette.Page id="root">
        {filteredItems.length ? (
          filteredItems.map((list) => (
            <CommandPalette.List key={list.id} heading={list.heading}>
              {list.items.map(({ id, ...rest }) => (
                <CommandPalette.ListItem key={id} index={getItemIndex(filteredItems, id)} {...rest} />
              ))}
            </CommandPalette.List>
          ))
        ) : (
          <CommandPalette.FreeSearchAction />
        )}
      </CommandPalette.Page>
    </CommandPalette>
  );
};

export default FeedFloCommandPalette;
