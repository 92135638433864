import PropTypes from 'prop-types';
import { UNKNOWN_USER_AVATAR } from '../../utils/constants';

import './Avatar.scss';

export const VariantEnum = {
  Circle: 'circle',
  Rounded: 'rounded',
};

export default function Avatar({ className = '', src: propSrc, shape = '' }) {
  let imgClassName = '';
  let src = propSrc;

  if (shape === VariantEnum.Rounded) {
    imgClassName = 'Avatar-pic--rounded';
  } else if (shape === VariantEnum.Circle) {
    imgClassName = 'Avatar-pic--circle';
  }

  if (!src) {
    src = UNKNOWN_USER_AVATAR;
  }

  return (
    <div className={`Avatar-wrapper ${className}`}>
      <img src={src} className={`Avatar-pic ${imgClassName}`} />
    </div>
  );
}

Avatar.propTypes = {
  className: PropTypes.string,
  shape: PropTypes.oneOf(Object.values(VariantEnum)),
  src: PropTypes.string,
};
