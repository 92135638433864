import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { DATE_MONTH, DATE_MONTH_YEAR } from '../../utils/dates';
import { convertGramsToLargeUnits } from '../../utils/unitConversion';
import { TripStatusNameConverter } from './enums';

// Formats the provided list to be easier to use as a list of checkboxes for toggling filters
export const formatAsFilterList = (list) => {
  return list.map((item) => ({
    ...item,
    label: item.name,
    value: item.value,
    enabled: true,
    visible: true,
  }));
};

export const dbTripToUITrip = (trip, isMetric) => {
  return {
    id: trip.id,
    status: TripStatusNameConverter[trip.status],
    barnID: trip.barn.id,
    barnName: trip.barn.name,
    externalID: trip?.external_id || '',
    comment: trip.comment,
    deliveries: (trip?.deliveries || []).map((delivery) => {
      return {
        id: delivery?.id,
        status: TripStatusNameConverter[delivery?.status],
        binID: delivery?.bin?.id,
        binName: delivery?.bin?.name,
        orderedAt: delivery?.ordered_at,
        orderedMassInLargeUnits: convertGramsToLargeUnits(isMetric, delivery?.ordered_mass_in_grams),
        externalID: delivery?.external_id || '',
        feedProfileName: delivery?.feed_profile?.name || '',
        deliveredAt: delivery?.delivered_at,
        deliveredMassInLargeUnits:
          delivery?.delivered_mass_in_grams !== null
            ? convertGramsToLargeUnits(isMetric, delivery?.delivered_mass_in_grams)
            : '',
        comment: delivery?.comment,
      };
    }),
  };
};

// Converts the provided list of trips into a map, grouped using the most relevant available date as the key
export const getTripMapFromList = (isMetric, trips) => {
  const tripMap = {};

  trips.forEach((trip) => {
    const tripDay = dayjs
      .tz(1000 * (trip.deliveries[0].delivered_at || trip.deliveries[0].ordered_at))
      .startOf('day')
      .format('YYYY-MM-DD');

    if (!tripMap[tripDay]) {
      tripMap[tripDay] = [];
    }

    tripMap[tripDay].push(dbTripToUITrip(trip, isMetric));
  });

  return tripMap;
};

export const getCalendarTitle = (visibleDates, currentCalendarDate) => {
  const monthYearMap = new Map();

  // If no dates are visible (likely due to filters), use the current date of the calendar instead
  if (!visibleDates?.length) {
    return currentCalendarDate.format(DATE_MONTH_YEAR);
  }

  // Get a list of distinct months and years from the list of visible dates
  visibleDates.forEach((date) => {
    monthYearMap.set(date.month(), date.year());
  });

  // Convert the map into an array of [month, year] pairs
  const monthYearPairs = Array.from(monthYearMap);
  if (!monthYearPairs.length) {
    return;
  }

  // Get the first and last pairs
  const [firstMonth, firstYear] = monthYearPairs.at(0);
  const [lastMonth, lastYear] = monthYearPairs.at(-1);

  // Format the title based on how many unique values there are:
  // 1 month, 1 year   = November 2024
  // 2 months, 1 year  = November - December 2024
  // 2 months, 2 years = December 2024 - January 2025
  const titleSegments = [];
  if (firstMonth === lastMonth && firstYear === lastYear) {
    titleSegments.push(dayjs.tz().month(firstMonth).year(firstYear).format(DATE_MONTH_YEAR));
  } else if (firstMonth !== lastMonth && firstYear === lastYear) {
    titleSegments.push(dayjs.tz().month(firstMonth).year(firstYear).format(DATE_MONTH));
    titleSegments.push(dayjs.tz().month(lastMonth).year(firstYear).format(DATE_MONTH_YEAR));
  } else if (firstMonth !== lastMonth && firstYear !== lastYear) {
    titleSegments.push(dayjs.tz().month(firstMonth).year(firstYear).format(DATE_MONTH_YEAR));
    titleSegments.push(dayjs.tz().month(lastMonth).year(lastYear).format(DATE_MONTH_YEAR));
  }

  return titleSegments.join(' - ');
};

export const onNetworkRequestError = (error) => {
  toast.error('A network error was encountered. Please check your internet connection before trying again.', {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  console.error(error.toString());
};

// Converts the list of barns into a (barn.id -> bins) map
export const getBinMapFromList = (barns) => {
  const binMap = {};

  barns.forEach((barn) => {
    binMap[barn.value] = barn.bins;
  });

  return binMap;
};
